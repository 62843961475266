import ChartTooltipSubList from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipSubList";
import {
  StyledTooltip,
  StyledTooltipTotal,
  StyledTooltipTitle,
  StyledTooltipSubtitle,
  StyledTooltipSettlementPeriod,
  PublishTimeContainer,
  ChartTooltipListContainer,
  TooltipDescription,
} from "components/dataVisualization/chartComponents/ChartTooltip/style";
import { DatasetCategory } from "models/chartConfiguration/chartDatasetModel";
import { ChartDatasetTooltipModel } from "models/chartConfiguration/tooltipModels";
import React from "react";
import { valueWithPrefixAndSuffix } from "utils/ChartDataUtils";

interface Props {
  datasetCategories: DatasetCategory<ChartDatasetTooltipModel>[];
  subtitle?: string;
  total?: number;
  settlementPeriod?: number;
  title: string;
  hasPublishTimes?: boolean;
  totalUnitSuffix?: string;
  selectedDataset?: string;
  showCategoryTitles: boolean;
  description?: string;
  showValues: boolean;
}

export const ChartTooltipContent: React.FC<Props> = ({
  datasetCategories,
  subtitle,
  total,
  settlementPeriod,
  hasPublishTimes,
  title,
  totalUnitSuffix,
  selectedDataset,
  showCategoryTitles,
  description,
  showValues,
}) => (
  <StyledTooltip data-test-id="chart-tooltip">
    <StyledTooltipTitle>{title}</StyledTooltipTitle>
    {subtitle && (
      <StyledTooltipSubtitle>
        <span>{subtitle}</span>
      </StyledTooltipSubtitle>
    )}
    {total !== undefined && (
      <StyledTooltipTotal>
        <span>TOTAL</span>
        <span>{valueWithPrefixAndSuffix(total, "", totalUnitSuffix)}</span>
      </StyledTooltipTotal>
    )}
    {settlementPeriod !== undefined && (
      <StyledTooltipSettlementPeriod data-test-id="chart-tooltip-settlement-period">
        <span>Settlement Period</span>
        <span>{settlementPeriod}</span>
      </StyledTooltipSettlementPeriod>
    )}
    <ChartTooltipListContainer>
      {hasPublishTimes && (
        <PublishTimeContainer>
          <span data-test-id="chart-tooltip-publish-time">Publish Time</span>
        </PublishTimeContainer>
      )}
      {datasetCategories.map((datasetCategory) => (
        <ChartTooltipSubList
          key={datasetCategory.id}
          datasetCategory={datasetCategory}
          selectedDataset={selectedDataset}
          hasPublishTimes={!!hasPublishTimes}
          showCategoryTitles={showCategoryTitles}
          showValues={showValues}
        />
      ))}
    </ChartTooltipListContainer>
    {description && <TooltipDescription>{description}</TooltipDescription>}
  </StyledTooltip>
);

export default ChartTooltipContent;
