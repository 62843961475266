import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "AppInsights";
import GlobalMeta from "components/components/Meta/GlobalMeta";
import SuspenseWrapper from "components/components/SuspenseWrapper/SuspenseWrapper";
import Layout from "components/layout/Layout/Layout";
import ScrollToTop from "components/layout/ScrollToTop/ScrollToTop";
import { CustomerFeedbackModalProvider } from "contexts/CustomerFeedbackModalContext";
import { ErrorProvider } from "contexts/ErrorContext";
import ActualGenerationPerType from "pages/ActualGenerationPerType/ActualGenerationPerType";
import ActualOrEstimatedWindAndSolarPowerGeneration from "pages/ActualOrEstimatedWindAndSolarPowerGeneration/ActualOrEstimatedWindAndSolarPowerGeneration";
import AdjustmentActionsDisbsad from "pages/AdjustmentActionsDisbsad/AdjustmentActionsDisbsad";
import AdjustmentDataNetbsad from "pages/AdjustmentDataNetbsad/AdjustmentDataNetbsad";
import BmuView from "pages/BmuView/BmuView";
import CreditDefaultNotices from "pages/CreditDefaultNotices/CreditDefaultNotices";
import DailyEnergyTransmitted from "pages/DailyEnergyTransmitted/DailyEnergyTransmitted";
import DayAheadAggregatedGeneration from "pages/DayAheadAggregatedGeneration/DayAheadAggregatedGeneration";
import DemandForecast from "pages/DemandForecast/DemandForecast";
import DemandOutturn from "pages/DemandOutturn/DemandOutturn";
import DerivedData from "pages/DerivedData/DerivedData";
import DetailedSystemPrices from "pages/DetailedSystemPrices/DetailedSystemPrices";
import Events from "pages/Events/Events";
import GenerationByFuelType from "pages/GenerationByFuelType/GenerationByFuelType";
import GenerationForecast from "pages/GenerationForecast/GenerationForecast";
import GenerationForecastForWindAndSolar from "pages/GenerationForecastForWindAndSolar/GenerationForecastForWindAndSolar";
import Home from "pages/Home/Home";
import IndicatedForecasts from "pages/IndicatedForecasts/IndicatedForecasts";
import IndicativeDemandPeak from "pages/IndicativeDemandPeak/IndicativeDemandPeak";
import InterconnectorFlows from "pages/InterconnectorFlows/InterconnectorFlows";
import Iris from "pages/Iris/Iris";
import Lolpdrm from "pages/Lolpdrm/Lolpdrm";
import MarketIndexPrices from "pages/MarketIndexPrices/MarketIndexPrices";
import MarketView from "pages/MarketView/MarketView";
import NotFoundPage from "pages/NotFoundPage/NotFoundPage";
import Profile from "pages/Profile/Profile";
import Remit from "pages/Remit/Remit";
import RemitMessageDetails from "pages/RemitMessageDetails/RemitMessageDetails";
import RollingSystemDemand from "pages/RollingSystemDemand/RollingSystemDemand";
import RollingSystemFrequency from "pages/RollingSystemFrequency/RollingSystemFrequency";
import SettlementMessages from "pages/SettlementMessages/SettlementMessages";
import ShortTermOperatingReserves from "pages/ShortTermOperatingReserves/ShortTermOperatingReserves";
import SoSoTradePrices from "pages/SoSoTradePrices/SoSoTradePrices";
import SurplusAndMargin from "pages/SurplusAndMargin/SurplusAndMargin";
import SystemSellBuyPrices from "pages/SystemSellBuyPrices/SystemSellBuyPrices";
import Temperature from "pages/Temperature/Temperature";
import TotalLoadActualAndDayAhead from "pages/TotalLoadActualAndDayAhead/TotalLoadActualAndDayAhead";
import TotalLoadWeekAhead from "pages/TotalLoadWeekAhead/TotalLoadWeekAhead";
import WhatsNew from "pages/WhatsNew/WhatsNew";
import WindGeneration from "pages/WindGeneration/WindGeneration";
import { FC, lazy } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import featureFlags from "utils/featureFlags";
import routeConstants from "utils/routeConstants";

const ApiDocumentation = lazy(
  () => import("pages/ApiDocumentation/ApiDocumentation")
);

interface OptionalAuthProviderProps {
  authContext?: IPublicClientApplication;
  children: JSX.Element;
}

const OptionalAuthProvider: FC<OptionalAuthProviderProps> = ({
  authContext,
  children,
}) => {
  if (authContext) {
    return <MsalProvider instance={authContext}>{children}</MsalProvider>;
  }
  return <>{children}</>;
};

interface AppProps {
  authContext?: IPublicClientApplication;
}

const App: FC<AppProps> = ({ authContext }) => (
  <AppInsightsContext.Provider value={reactPlugin}>
    <OptionalAuthProvider authContext={authContext}>
      <HelmetProvider>
        <GlobalMeta />
        <Router>
          <ErrorProvider>
            <>
              <ScrollToTop />
              <CustomerFeedbackModalProvider>
                <Layout>
                  <Routes>
                    <Route path={routeConstants.home} element={<Home />} />
                    <Route
                      path={routeConstants.generationByFuelType}
                      element={<GenerationByFuelType />}
                    />
                    <Route
                      path={routeConstants.generationForecast}
                      element={<GenerationForecast />}
                    />
                    <Route
                      path={routeConstants.generationForecastForWindAndSolar}
                      element={<GenerationForecastForWindAndSolar />}
                    />
                    <Route
                      path={routeConstants.interconnectorFlows}
                      element={<InterconnectorFlows />}
                    />
                    <Route
                      path={routeConstants.rollingSystemDemand}
                      element={<RollingSystemDemand />}
                    />
                    <Route
                      path={routeConstants.surplusAndMargin}
                      element={<SurplusAndMargin />}
                    />
                    <Route
                      path={routeConstants.demandForecast}
                      element={<DemandForecast />}
                    />
                    <Route
                      path={routeConstants.shortTermOperatingReserves}
                      element={<ShortTermOperatingReserves />}
                    />
                    <Route
                      path={routeConstants.rollingSystemFrequency}
                      element={<RollingSystemFrequency />}
                    />
                    <Route
                      path={routeConstants.temperature}
                      element={<Temperature />}
                    />
                    <Route
                      path={routeConstants.marketIndexPrices}
                      element={<MarketIndexPrices />}
                    />
                    <Route
                      path={routeConstants.demandOutturn}
                      element={<DemandOutturn />}
                    />
                    <Route
                      path={routeConstants.dailyEnergyTransmitted}
                      element={<DailyEnergyTransmitted />}
                    />
                    <Route
                      path={routeConstants.indicatedForecasts}
                      element={<IndicatedForecasts />}
                    />
                    <Route
                      path={routeConstants.windGeneration}
                      element={<WindGeneration />}
                    />
                    <Route
                      path={routeConstants.actualGenerationPerType}
                      element={<ActualGenerationPerType />}
                    />
                    <Route
                      path={
                        routeConstants.actualOrEstimatedWindAndSolarPowerGeneration
                      }
                      element={<ActualOrEstimatedWindAndSolarPowerGeneration />}
                    />
                    <Route
                      path={routeConstants.dayAheadAggregatedGeneration}
                      element={<DayAheadAggregatedGeneration />}
                    />
                    <Route
                      path={routeConstants.totalLoadActualAndDayAhead}
                      element={<TotalLoadActualAndDayAhead />}
                    />
                    <Route
                      path={routeConstants.totalLoadWeekAhead}
                      element={<TotalLoadWeekAhead />}
                    />
                    <Route
                      path={routeConstants.bmuView}
                      element={<BmuView />}
                    />
                    <Route
                      path={routeConstants.marketView}
                      element={<MarketView />}
                    />
                    <Route
                      path={routeConstants.adjustmentDataNetbsad}
                      element={<AdjustmentDataNetbsad />}
                    />
                    <Route
                      path={routeConstants.creditDefaultNotices}
                      element={<CreditDefaultNotices />}
                    />
                    <Route
                      path={routeConstants.adjustmentActionsDisbsad}
                      element={<AdjustmentActionsDisbsad />}
                    />
                    <Route
                      path={routeConstants.lolpdrm}
                      element={<Lolpdrm />}
                    />
                    <Route
                      path={routeConstants.whatsNew}
                      element={<WhatsNew />}
                    />
                    <Route path={routeConstants.remit} element={<Remit />} />
                    <Route
                      path={`${routeConstants.remitMessageDetails}/:mrid`}
                      element={<RemitMessageDetails />}
                    />
                    <Route
                      path={routeConstants.indicativeDemandPeak}
                      element={<IndicativeDemandPeak />}
                    />
                    <Route path={routeConstants.events} element={<Events />} />
                    <Route
                      path={routeConstants.sosoTradePrices}
                      element={<SoSoTradePrices />}
                    />
                    <Route
                      path={`${routeConstants.apiDocs}/*`}
                      element={
                        <SuspenseWrapper>
                          <ApiDocumentation />
                        </SuspenseWrapper>
                      }
                    />
                    <>
                      <Route
                        path={routeConstants.settlementMessages}
                        element={<SettlementMessages />}
                      />
                      <Route
                        path={routeConstants.derivedData}
                        element={<DerivedData />}
                      />
                      <Route
                        path={routeConstants.systemPrices}
                        element={<SystemSellBuyPrices />}
                      />
                      <Route
                        path={routeConstants.detailedSystemPrices}
                        element={<DetailedSystemPrices />}
                      />
                    </>
                    {featureFlags.accountArea && (
                      <Route
                        path={routeConstants.profile}
                        element={<Profile />}
                      />
                    )}
                    <Route path={routeConstants.iris} element={<Iris />} />
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </Layout>
              </CustomerFeedbackModalProvider>
            </>
          </ErrorProvider>
        </Router>
      </HelmetProvider>
    </OptionalAuthProvider>
  </AppInsightsContext.Provider>
);

export default App;
