import { getDailyEnergyTransmittedData } from "api/dataVisualisation/dailyEnergyTransmitted/dailyEnergyTransmitted";
import DatasetLineChart from "components/dataVisualization/DatasetLineChart/DatasetLineChart";
import {
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import { useDataExport } from "contexts/DataExportContext";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { chartColours } from "styles/colours";
import { transformDailyEnergyTransmittedData } from "utils/ChartDataUtils/dailyEnergyTransmitted/dailyEnergyTransmitted";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  addMonthsToDate,
  ceilToHalfHour,
  restrictDateFilterToMaxDaysRange,
  SIX_MONTHS_IN_DAYS,
  toDateOnlyString,
} from "utils/dateHelpers";

import useDailyEnergyTransmittedChartData from "./useDailyEnergyTransmittedChartData";

const DailyEnergyTransmittedChartContainer: React.FC = () => {
  const { dispatch: dataExportCountDispatch } = useDataExport();
  const defaultEndDate = ceilToHalfHour(new Date());
  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addMonthsToDate(defaultEndDate, -3), defaultEndDate)
  );
  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.ThreeMonths
  );

  const {
    data: rawData,
    fetchData,
    status,
  } = useDailyEnergyTransmittedChartData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const chartData = useMemo(
    () => (rawData ? transformDailyEnergyTransmittedData(rawData) : []),
    [rawData]
  );

  const datasetCategories: DatasetCategory<ChartDatasetModel>[] = [
    {
      datasets: [
        {
          colour: chartColours.dailyEnergyTransmittedOutturn,
          dataKey: "outturn",
          displayName: "Outturn",
        },
      ],
      id: "datasets",
    },
  ];

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getDailyEnergyTransmittedData(dateFilter, fileFormat),
      `DailyEnergyTransmitted-${toDateOnlyString(
        dateFilter.startDate
      )}-${toDateOnlyString(dateFilter.endDate)}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const handleChangeToDateSelection = useCallback(
    (newDateFilter: DateFilterModel, newActiveTab: DateSelectorTabs): void => {
      setDateFilter(
        restrictDateFilterToMaxDaysRange(newDateFilter, SIX_MONTHS_IN_DAYS)
      );
      setActiveDateSelectorTab(newActiveTab);
    },
    [setDateFilter, setActiveDateSelectorTab]
  );

  return (
    <>
      <ChartHeader>
        <ChartFilter
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={[DateSelectorTabs.ThreeMonths]}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={SIX_MONTHS_IN_DAYS}
          showTimeInput={false}
        />
      </ChartHeader>
      <DatasetLineChart
        series={chartData}
        datasetCategories={datasetCategories}
        rerequestData={fetchData}
        dataFetchStatus={status}
        xAxisTitle={"Date"}
        yAxisTitle={"MWh"}
        showPublishTime={false}
        dateOnlyFormat={true}
      />
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFile}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={[DateSelectorTabs.ThreeMonths]}
          activeDateSelectorTab={activeDateSelectorTab}
          showTimeInput={false}
        />
      </ChartFooter>
    </>
  );
};
export default DailyEnergyTransmittedChartContainer;
