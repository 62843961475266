import classnames from "classnames";
import DateInput, {
  DateInputLabel,
  Props as DateInputProps,
} from "components/dataVisualization/dataPageComponents/ChartFilter/DateInput/DateInput";
import useOnEscape from "hooks/useOnEscape";
import React, { forwardRef, useCallback, useState } from "react";
import DatePicker from "react-datepicker";
import { getSettlementPeriodFromSettlementTime } from "utils/DateUtils";
import {
  convertDateForInput,
  convertDateForOutput,
  getListOfYears,
  listOfMonths,
} from "utils/dateHelpers";

import { TimePicker } from "./TimePicker";
import {
  DatePickerContainer,
  MonthDisplay,
  NextPreviousButton,
  StyledHeader,
  StyledOption,
  StyledSelect,
} from "./style";

interface Props {
  selectedDate: Date;
  onChange: (date: Date) => void;
  minDate: Date;
  maxDate: Date;
  isValidTime: (hourString: string, minString: string) => boolean;
  isActiveTab: boolean;
  readOnly?: boolean;
  showTimeInput?: boolean;
  dateInputLabel: DateInputLabel;
  showSettlementPeriod?: boolean;
}

const DatePickerWrapper: React.FC<Props> = ({
  selectedDate,
  onChange,
  minDate,
  maxDate,
  isValidTime,
  isActiveTab,
  readOnly = false,
  showTimeInput = true,
  dateInputLabel,
  showSettlementPeriod = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const years = getListOfYears(minDate, maxDate);
  const months = listOfMonths;

  const customDatePicker = (
    date: Date,
    changeYear: (year: number) => void,
    decreaseMonth: () => void,
    increaseMonth: () => void,
    prevMonthButtonDisabled: boolean,
    nextMonthButtonDisabled: boolean
  ): JSX.Element => (
    <StyledHeader>
      <div>
        <NextPreviousButton
          type="button"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          data-test-id="previous-month-button"
        >
          F
        </NextPreviousButton>
        <MonthDisplay>{months[date.getMonth()]}</MonthDisplay>
        <NextPreviousButton
          type="button"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          data-test-id="next-month-button"
        >
          V
        </NextPreviousButton>
      </div>
      <StyledSelect
        value={date.getFullYear()}
        onChange={({ target: { value } }): void => changeYear(+value)}
        data-test-id="year-select"
      >
        {years.map((option) => (
          <StyledOption key={option} value={option}>
            {option}
          </StyledOption>
        ))}
      </StyledSelect>
    </StyledHeader>
  );

  const handleChange = (date: Date): void => {
    const outputDate = convertDateForOutput(date);
    if (outputDate < minDate) {
      onChange(minDate);
    } else if (outputDate > maxDate) {
      onChange(maxDate);
    } else {
      onChange(outputDate);
    }
  };

  const CustomInput = forwardRef<
    HTMLButtonElement,
    Pick<DateInputProps, "value" | "onClick">
  >(function customDateInput({ value, onClick }, ref) {
    const utcText = showTimeInput ? "UTC" : undefined;
    const settlementPeriodText = showSettlementPeriod
      ? `(SP ${getSettlementPeriodFromSettlementTime(selectedDate)})`
      : undefined;
    const appendText = [utcText, settlementPeriodText]
      .filter((s) => s)
      .join(" ");
    return (
      <DateInput
        value={value}
        onClick={onClick}
        isDisabled={readOnly}
        ref={ref}
        dateInputLabel={dateInputLabel}
        appendText={appendText}
      />
    );
  });

  const closePicker = useCallback(() => setIsOpen(false), []);
  useOnEscape(closePicker);

  return (
    <DatePickerContainer
      className={classnames({ active: isActiveTab && !readOnly })}
      data-test-id="date-picker-container"
    >
      <DatePicker
        dateFormat={showTimeInput ? "dd/MM/yyyy - HH:mm" : "dd/MM/yyyy"}
        readOnly={readOnly}
        selected={convertDateForInput(selectedDate)}
        onChange={handleChange}
        onInputClick={(): void => setIsOpen(true)}
        onClickOutside={closePicker}
        open={isOpen}
        customInput={React.createElement(CustomInput)}
        minDate={convertDateForInput(minDate)}
        maxDate={convertDateForInput(maxDate)}
        formatWeekDay={(nameOfDay): string => nameOfDay[0]}
        renderCustomHeader={({
          date,
          changeYear,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }): JSX.Element =>
          customDatePicker(
            date,
            changeYear,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          )
        }
        showTimeInput={showTimeInput}
        timeInputLabel=""
        customTimeInput={
          <TimePicker
            date={convertDateForInput(selectedDate)}
            handleChange={handleChange}
            isValidTime={isValidTime}
          />
        }
      />
    </DatePickerContainer>
  );
};

export default DatePickerWrapper;
