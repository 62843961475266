import { faDownload } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import Button from "components/components/Button/Button";
import FileTypeSelector from "components/components/FileTypeSelector/FileTypeSelector";
import ForecastFilterSelector from "components/components/ForecastFilterSelector/ForecastFilterSelector";
import InformationTooltip from "components/components/InformationTooltip/InformationTooltip";
import {
  TooltipLabel,
  TooltipLine,
} from "components/components/InformationTooltip/style";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import {
  ModalButtonContainer,
  ModalHeader,
  ModalPadding,
  ModalSection,
  ModalSubHeader,
} from "components/components/Modal/commonStyle";
import { DataDownloadAggregationType } from "models/dataDownload/dataDownloadAggregationType";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import React, { useState } from "react";

interface Props {
  downloadData: (
    aggregationType: DataDownloadAggregationType,
    fileFormat: DataDownloadFormat
  ) => void;
  forecastFilter: ForecastFilterOption;
  handleChangeToForecastFilter: (
    newForecastFilter: ForecastFilterOption
  ) => void;
}

const GenerationForecastDataExporter: React.FC<Props> = ({
  downloadData,
  forecastFilter,
  handleChangeToForecastFilter,
}: Props) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [aggregationType, setAggregationType] = useState(
    DataDownloadAggregationType.FuelOutputUsable
  );
  const [downloadFormat, setDownloadFormat] = useState(DataDownloadFormat.Csv);

  const handleExportClick = (): void => {
    downloadData(aggregationType, downloadFormat);

    setIsModalActive(false);
  };

  return (
    <>
      <div data-test-id="export-data-open-button-container">
        <Button
          ariaLabel="Export"
          buttonText="export"
          iconName={faDownload}
          onClick={(): void => setIsModalActive(true)}
        />
      </div>
      <Modal
        size={ModalSize.large}
        isActive={isModalActive}
        onClose={(): void => setIsModalActive(false)}
        closeOnEsc={false} // TODO: enable this to be escaped see ticket #52645
        headingId="export-modal-header"
      >
        <ModalPadding data-test-id="export-data-modal">
          <ModalSection>
            <ModalHeader id="export-modal-header">
              Select the data you want to export
            </ModalHeader>
          </ModalSection>
          <ModalSection>
            <div>
              <ModalSubHeader>Time frame</ModalSubHeader>
              <ForecastFilterSelector
                forecastFilter={forecastFilter}
                handleChangeToForecastFilter={handleChangeToForecastFilter}
              />
            </div>
          </ModalSection>
          <ModalSection>
            <div>
              <ModalSubHeader>
                Aggregation Type
                <InformationTooltip>
                  <>
                    <TooltipLine>
                      <TooltipLabel>Available aggregation types:</TooltipLabel>
                    </TooltipLine>
                    <TooltipLine>
                      <TooltipLabel>NOU:</TooltipLabel>
                      &nbsp; National Output Usable
                    </TooltipLine>
                    <TooltipLine>
                      <TooltipLabel>FOU:</TooltipLabel>
                      &nbsp; Fuel Output Usable
                    </TooltipLine>
                    <TooltipLine>
                      <TooltipLabel>UOU:</TooltipLabel>
                      &nbsp; (Balancing Mechanism) Unit Output Usable
                    </TooltipLine>
                  </>
                </InformationTooltip>
              </ModalSubHeader>
              <div data-test-id="export-data-aggregation-type">
                <Button
                  dataTestId="national-output-usable"
                  ariaLabel="National Output Usable"
                  onClick={(): void =>
                    setAggregationType(
                      DataDownloadAggregationType.NationalOutputUsable
                    )
                  }
                  className={classnames("filter", {
                    active:
                      aggregationType ===
                      DataDownloadAggregationType.NationalOutputUsable,
                  })}
                  buttonText={DataDownloadAggregationType.NationalOutputUsable}
                />
                <Button
                  dataTestId="fuel-output-usable"
                  ariaLabel="Fuel Output Usable"
                  onClick={(): void =>
                    setAggregationType(
                      DataDownloadAggregationType.FuelOutputUsable
                    )
                  }
                  className={classnames("filter", {
                    active:
                      aggregationType ===
                      DataDownloadAggregationType.FuelOutputUsable,
                  })}
                  buttonText={DataDownloadAggregationType.FuelOutputUsable}
                />
                <Button
                  dataTestId="unit-output-usable"
                  ariaLabel="Unit Output Usable"
                  onClick={(): void =>
                    setAggregationType(
                      DataDownloadAggregationType.UnitOutputUsable
                    )
                  }
                  className={classnames("filter", {
                    active:
                      aggregationType ===
                      DataDownloadAggregationType.UnitOutputUsable,
                  })}
                  buttonText={DataDownloadAggregationType.UnitOutputUsable}
                />
              </div>
            </div>
            <div>
              <ModalSubHeader>File type</ModalSubHeader>
              <FileTypeSelector
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
              />
            </div>
          </ModalSection>
          <hr />
          <ModalSection className="centre">
            <ModalButtonContainer data-test-id="export-data-button-container">
              <Button
                ariaLabel="Cancel"
                buttonText="cancel"
                className="cancel"
                onClick={(): void => setIsModalActive(false)}
              />
              <Button
                ariaLabel="Export"
                buttonText="export"
                onClick={handleExportClick}
              />
            </ModalButtonContainer>
          </ModalSection>
        </ModalPadding>
      </Modal>
    </>
  );
};

export default GenerationForecastDataExporter;
