import MaybePlural from "models/MaybePlural";
import { DynamicDataModel } from "models/bmuData/dynamicDataModel";

export interface DynamicTileTableModel {
  subtitle: string;
  data:
    | {
        time: Date;
        settlementPeriod: number;
        value: number;
        unit: MaybePlural;
      }[]
    | null;
}
export interface DynamicTileDataModel {
  notices: DynamicTileTableModel[];
  minimumPeriods: DynamicTileTableModel[];
  maximumDelivery: DynamicTileTableModel[];
  stableLimits: DynamicTileTableModel[];
}

const getTransformedSubsectionData = (
  subtitle: string,
  dataset: string,
  rawData: DynamicDataModel[],
  unit: MaybePlural
): DynamicTileTableModel => ({
  subtitle,
  data: rawData
    .filter((d) => d.dataset === dataset)
    .map((d) => ({
      time: new Date(d.time),
      settlementPeriod: d.settlementPeriod,
      value: d.value,
      unit,
    }))
    .sort((a, b) => b.time.getTime() - a.time.getTime()),
});

export const transformDynamicTileData = (
  rawData: DynamicDataModel[] | null
): DynamicTileDataModel | null => {
  if (rawData === null) {
    return null;
  }
  return {
    notices: [
      getTransformedSubsectionData(
        "Notice to deviate from zero",
        "NDZ",
        rawData,
        new MaybePlural("min", "mins")
      ),
      getTransformedSubsectionData(
        "Notice to deliver bids",
        "NTB",
        rawData,
        new MaybePlural("min", "mins")
      ),
      getTransformedSubsectionData(
        "Notice to deliver offers",
        "NTO",
        rawData,
        new MaybePlural("min", "mins")
      ),
    ],
    minimumPeriods: [
      getTransformedSubsectionData(
        "Minimum zero time",
        "MZT",
        rawData,
        new MaybePlural("min", "mins")
      ),
      getTransformedSubsectionData(
        "Minimum non-zero time",
        "MNZT",
        rawData,
        new MaybePlural("min", "mins")
      ),
    ],
    maximumDelivery: [
      getTransformedSubsectionData(
        "Maximum delivery volume",
        "MDV",
        rawData,
        new MaybePlural("MWh")
      ),
      getTransformedSubsectionData(
        "Maximum delivery period",
        "MDP",
        rawData,
        new MaybePlural("min", "mins")
      ),
    ],
    stableLimits: [
      getTransformedSubsectionData(
        "Stable export limit",
        "SEL",
        rawData,
        new MaybePlural("MW")
      ),
      getTransformedSubsectionData(
        "Stable import limit",
        "SIL",
        rawData,
        new MaybePlural("MW")
      ),
    ],
  };
};
