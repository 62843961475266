import { getRollingSystemFrequencyJsonData } from "api/dataVisualisation/rollingSystemFrequency/rollingSystemFrequency";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { RollingSystemFrequencyModel } from "models/rollingSystemFrequency/rollingSystemFrequencyModel";
import { useCallback } from "react";

const useRollingSystemFrequencyChartContainerData = (
  dateFilter: DateFilterModel
): {
  fetchData: () => void;
  data: RollingSystemFrequencyModel[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchRollingSystemFrequencyData,
  } = useRequest(getRollingSystemFrequencyJsonData);

  const fetchData = useCallback(() => {
    fetchRollingSystemFrequencyData(dateFilter);
  }, [dateFilter, fetchRollingSystemFrequencyData]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useRollingSystemFrequencyChartContainerData;
