import TableWithErrorHandling from "components/components/TableWithErrorHandling/TableWithErrorHandling";
import { DescriptionText } from "components/components/Tabs/TabDescription/style";
import DemandControlInstructionsTable from "components/dataVisualization/Events/DemandControlInstructions/DemandControlInstructionsTable/DemandControlInstructionsTable";
import useDemandControlInstructionsData from "components/dataVisualization/Events/DemandControlInstructions/useDemandControlInstructionsData/useDemandControlInstructionsData";
import { mapToDemandControlInstructionsRow } from "components/dataVisualization/Events/DemandControlInstructions/utils";
import DateFilterModel from "models/filterModels/dateFilterModel";
import React, { useEffect, useMemo } from "react";

interface Props {
  dateFilter: DateFilterModel;
}

const DemandControlInstructionsTab: React.FC<Props> = ({ dateFilter }) => {
  const {
    data: rawData,
    fetchData,
    status,
  } = useDemandControlInstructionsData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const mappedData = useMemo(
    () => (rawData ? mapToDemandControlInstructionsRow(rawData) : []),
    [rawData]
  );

  const description = (
    <>
      <p>
        A Demand Control Instruction (DCI) is the instruction issued by the
        Transmission Company for the Demand Control Event.
      </p>
      <p>
        This instructs network operators to execute their demand control plans.
        This instruction will contain the level of reduction required to avoid
        the shortfall and specifies the demand control stages required.
      </p>
    </>
  );

  return (
    <>
      <DescriptionText>{description}</DescriptionText>
      <TableWithErrorHandling
        data={mappedData}
        dataFetchStatus={status}
        rerequestData={fetchData}
      >
        <DemandControlInstructionsTable data={mappedData} />
      </TableWithErrorHandling>
    </>
  );
};

export default DemandControlInstructionsTab;
