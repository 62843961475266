import { StyledDateOnly } from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
} from "components/components/SortableTable/SortableTable";
import { SoSoTradePricesTableModel } from "models/soSoTradePrices/soSoTradePricesTableModel";
import React from "react";

interface Props {
  data: SoSoTradePricesTableModel[];
}

const SoSoTradePricesTable: React.FC<Props> = ({ data }) => (
  <SortableTable
    headers={{
      traderUnit: "Trader Unit",
      settlementDate: "Date",
      startTime: "Start Time (UTC)",
      tradeDirection: "Trade Direction",
      contractIdentification: "Contract Identification",
      tradeQuantity: "Trade Quantity (MW)",
      tradePrice: "Trade Price (£)",
    }}
    items={data}
    renderFns={{
      settlementDate: ({ settlementDate }): TableCellRender => ({
        content: StyledDateOnly(settlementDate),
      }),
      startTime: ({ startTime }): TableCellRender => ({
        content: StyledDateOnly(startTime),
      }),
    }}
    scrollable={false}
  />
);

export default SoSoTradePricesTable;
