import {
  ApiData,
  SwaggerJsonFile,
  Endpoint,
} from "models/apiDocumentation/apiDocumentationModels";

export const transformSwaggerJsonFile = (
  swaggerJsonFile: SwaggerJsonFile
): ApiData => {
  const endpointList = Object.entries(swaggerJsonFile.paths).map((path) => {
    const getOperation = path[1].get;
    return {
      url: path[0],
      summary: getOperation.summary,
      description: getOperation.description,
      tags: getOperation.tags,
      deprecated: getOperation.deprecated,
    };
  });

  const activeEndpoints = endpointList.filter(
    (endpoint) => !endpoint.deprecated
  );
  activeEndpoints.sort((a, b) => (a.summary > b.summary ? 1 : -1));

  const activeTags = swaggerJsonFile.tags.filter((t) =>
    activeEndpoints.some((e) => e.tags.includes(t.name))
  );
  activeTags.sort((a, b) => (a.name > b.name ? 1 : -1));

  return { endpoints: activeEndpoints, tags: activeTags };
};

export const getEndpointDataByPath = (
  endpoints: Endpoint[],
  path: string
): Endpoint | null =>
  endpoints.find((item) => `/endpoint${item.url}` === path) ?? null;
