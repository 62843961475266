import { GenerationForecastDataModel } from "models/generationForecast/generationForecastDataModel";
import { GenerationForecastDataWeeklyModel } from "models/generationForecast/generationForecastDataWeeklyModel";

import { getDateOfMondayFromWeekAndYear } from "./dateHelpers";

export const compareForecastDate = (
  a: GenerationForecastDataModel,
  b: GenerationForecastDataModel
): number =>
  new Date(a.forecastDate).getTime() - new Date(b.forecastDate).getTime();

export const addForecastDatesToWeeklyData = (
  weeklyData: GenerationForecastDataWeeklyModel[]
): GenerationForecastDataModel[] =>
  weeklyData.map(
    (
      datapoint: GenerationForecastDataWeeklyModel
    ): GenerationForecastDataModel => {
      datapoint.forecastDate = getDateOfMondayFromWeekAndYear(
        datapoint.year,
        datapoint.calendarWeekNumber
      );
      return datapoint;
    }
  );

export const parseForecastDate = (
  forecastData: GenerationForecastDataModel[]
): GenerationForecastDataModel[] =>
  forecastData.map((datapoint) => {
    datapoint.forecastDate = new Date(datapoint.forecastDate);
    return datapoint;
  });
