import { Serie, Datum } from "@nivo/line";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import { TabulatedTooltipValue } from "models/chartConfiguration/tooltipModels";

type DefaultDatumFunction = (
  dataKey: string,
  lastValidDatum: Datum | undefined,
  date: Date
) => Datum | undefined;

const datumToTabulatedTooltipValue = (
  datasetId: string,
  datum: Datum | undefined
): TabulatedTooltipValue => {
  const valueNullish = datum?.valueNullish;
  const y = !datum || valueNullish ? null : (datum.y as number) ?? null;
  return {
    datasetId,
    y,
    volume: datum?.volume ?? null,
    publishTime: datum?.publishTime ?? null,
    settlementRun: datum?.settlementRun ?? null,
  };
};

export const getTabulatedTooltipValues = (
  datasetCategories: DatasetCategory<ChartDatasetModel>[],
  series: Serie[],
  date: Date,
  createDefaultDatum?: DefaultDatumFunction
): {
  tooltipValues: TabulatedTooltipValue[];
  settlementPeriod?: number;
} => {
  let settlementPeriod: number | undefined;
  let lastValidDatum: Datum | undefined;

  const tooltipValues = datasetCategories.flatMap((category) =>
    category.datasets.flatMap((dataset) => {
      const matchingSerie = series.find(
        (serie) => serie.id === dataset.dataKey
      );

      let matchingDatum = matchingSerie?.data.find(
        (datum) =>
          datum.x instanceof Date && datum.x.getTime() === date.getTime()
      );

      if (!matchingDatum && createDefaultDatum) {
        matchingDatum = createDefaultDatum(
          dataset.dataKey,
          lastValidDatum,
          date
        );
      } else {
        lastValidDatum = matchingDatum;
      }

      if (!settlementPeriod && matchingDatum) {
        settlementPeriod = matchingDatum?.settlementPeriod;
      }

      return datumToTabulatedTooltipValue(dataset.dataKey, matchingDatum);
    })
  );

  return { tooltipValues, settlementPeriod };
};
