import styled from "styled-components";

export const StyledSection = styled.div`
  display: flex;
`;
export const StyledHeader = styled.h3`
  &:not(:first-child):nth-child(2) {
    margin: 0 auto;
  }
`;
