import { EventStatus } from "components/remit/MessageDetails/RemitMessageStatuses/RemitMessageStatuses";
import styled from "styled-components";
import colours from "styles/colours";

export interface EventStatusProps {
  eventStatus: EventStatus;
}

export const StyledEventStatusCircle = styled.div<EventStatusProps>`
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 50%;
  margin-top: 4px;
  margin-right: 3px;
  background-color: ${({ eventStatus }): string => {
    switch (eventStatus) {
      case EventStatus.Active:
        return colours.tropicalRainForest;
      case EventStatus.Inactive:
        return colours.pinkRed;
      case EventStatus.Dismissed:
        return colours.mediumGrey;
      default:
        return "";
    }
  }};
`;

export const StyledContainer = styled.div`
  display: flex;
  margin: 3em 0 2em 0;
`;

export const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 2em;
`;

export const TextWrapper = styled.dl`
  display: flex;
  flex-direction: column;
  margin-left: 5px;

  > dt {
    font-weight: bold;
  }

  > dd {
    margin-left: 0;
  }
`;

export const LoadingSpinnerDiv = styled.div`
  height: 100px;
`;

export const ContainerDiv = styled.div`
  height: 50vh;
  margin-top: 300px;
`;
