import { useState, useCallback } from "react";

const useToggle = (initialValue: boolean): [boolean, () => void] => {
  const [value, setValue] = useState<boolean>(initialValue);

  const toggleValue = useCallback((): void => {
    setValue((v) => !v);
  }, []);

  return [value, toggleValue];
};

export default useToggle;
