import styled from "styled-components";
import colours from "styles/colours";

export const Header = styled.div`
  padding-left: 5px;
  font-weight: bold;
`;

export const ToggleAllButtons = styled.div`
  color: ${colours.disabledGrey};
  margin: 6px 3px;
  > * {
    margin: 0 3px;
  }
`;

export const StyledDisabledText = styled.p`
  padding: 10px 5px 10px;
`;

export const StyledCategoryLabel = styled.div`
  font-weight: ${(props: { selected: boolean }): string =>
    props.selected ? "bold" : "normal"};
`;

export const StyledCategoryList = styled.dl`
  dt {
    display: inline;
  }

  dd {
    display: inline;
    float: right;
  }
`;
