import { getInitialDemandOutturnData } from "api/dataVisualisation/initialDemand/initialDemand";
import Switch from "components/components/Switch/Switch";
import {
  ChartFooter,
  ChartHeader,
  ChartDataToggleContainer,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import InitialDemandOutturnChart from "components/dataVisualization/demandOutturn/DemandOutturnChart/InitialDemandOutturnChart";
import useInitialDemandOutturnChartContainerData from "components/dataVisualization/demandOutturn/DemandOutturnChartContainer/useInitialDemandOutturnChartContainerData";
import { useDataExport } from "contexts/DataExportContext";
import useToggle from "hooks/useToggle";
import {
  DatasetCategory,
  ChartDatasetModel,
  combineDatasetCategories,
} from "models/chartConfiguration/chartDatasetModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useEffect, useMemo, useState } from "react";
import { chartColours } from "styles/colours";
import { transformInitialDemandOutturnModelData } from "utils/ChartDataUtils/demandOutturn/demandOutturn";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  addDaysToDate,
  toDateOnlyString,
  ceilToHalfHour,
  DAY_IN_MS,
  DAYS_IN_ONE_WEEK,
} from "utils/dateHelpers";

const InitialDemandOutturnChartContainer: React.FC = () => {
  const defaultEndDate = ceilToHalfHour(new Date());
  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addDaysToDate(defaultEndDate, -1), defaultEndDate)
  );

  const chartDateSelectorTabs = [
    DateSelectorTabs.TwentyFourHours,
    DateSelectorTabs.OneWeek,
  ];

  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.TwentyFourHours
  );

  const [showIndoData, toggleShowIndoData] = useToggle(true);
  const [showItsdoData, toggleShowItsdoData] = useToggle(true);

  const datasetCategories: DatasetCategory<ChartDatasetModel>[] = useMemo(
    () => [
      {
        datasets: [
          {
            colour: chartColours.indo,
            dataKey: "initialDemandOutturn",
            displayName: "Initial Demand Outturn",
            isHidden: !showIndoData,
            unitSuffix: "MW",
          },
          {
            colour: chartColours.itsdo,
            dataKey: "initialTransmissionSystemDemandOutturn",
            displayName: "Initial Transmission System Demand Outturn",
            isHidden: !showItsdoData,
            unitSuffix: "MW",
          },
        ],
        id: "datasets",
      },
    ],
    [showIndoData, showItsdoData]
  );

  const { dispatch: dataExportCountDispatch } = useDataExport();

  const {
    data: rawData,
    fetchData,
    status,
  } = useInitialDemandOutturnChartContainerData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const preProcessedData = useMemo(() => {
    const d = new Date();
    const localTime = d.getTime();
    const localOffset = d.getTimezoneOffset() * 60000;
    const utc = localTime + localOffset;

    const twentyFourHoursAgo = new Date(new Date(utc).getTime() - DAY_IN_MS);
    const oneWeekAgo = new Date(new Date(utc).getTime() - DAY_IN_MS * 7);

    switch (activeDateSelectorTab) {
      case DateSelectorTabs.TwentyFourHours:
        return rawData?.filter(
          (data) => new Date(data.startTime) > twentyFourHoursAgo
        );
      case DateSelectorTabs.OneWeek:
        return rawData?.filter((data) => new Date(data.startTime) > oneWeekAgo);
      default:
        return rawData;
    }
  }, [rawData, activeDateSelectorTab]);

  const chartData = useMemo(
    () =>
      preProcessedData
        ? transformInitialDemandOutturnModelData(
            preProcessedData,
            combineDatasetCategories(datasetCategories)
          )
        : [],
    [datasetCategories, preProcessedData]
  );

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    setDateFilter(newDateFilter.restrictToMaxOneWeek());
    setActiveDateSelectorTab(newActiveTab);
  };

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getInitialDemandOutturnData(dateFilter, fileFormat),
      `DemandOutturn-${toDateOnlyString(
        dateFilter.startDate
      )}-${toDateOnlyString(dateFilter.endDate)}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  return (
    <>
      <ChartHeader>
        <ChartDataToggleContainer>
          <Switch
            name="initial-demand-outturn"
            onColor={chartColours.indo}
            onClick={toggleShowIndoData}
            switchedOn={showIndoData}
            leftLabel="INDO"
            headerLabel
          />
          <Switch
            name="initial-transmission-demand-outturn"
            onColor={chartColours.itsdo}
            onClick={toggleShowItsdoData}
            switchedOn={showItsdoData}
            leftLabel="ITSDO"
            headerLabel
          />
        </ChartDataToggleContainer>
        <ChartFilter
          dateFilter={dateFilter}
          dateSelectorTabs={chartDateSelectorTabs}
          handleChangeToDateSelection={handleChangeToDateSelection}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={DAYS_IN_ONE_WEEK}
          showTimeInput={false}
        />
      </ChartHeader>
      <InitialDemandOutturnChart
        series={chartData}
        datasetCategories={datasetCategories}
        dataFetchStatus={status}
        rerequestData={fetchData}
      />
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFile}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={chartDateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
          showTimeInput={false}
        />
      </ChartFooter>
    </>
  );
};

export default InitialDemandOutturnChartContainer;
