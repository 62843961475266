import React from "react";

interface Props {
  children: JSX.Element;
}

export interface CustomerFeedbackModal {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export const CustomerFeedbackModalContext = React.createContext<
  CustomerFeedbackModal | undefined
>(undefined);

export const CustomerFeedbackModalProvider: React.FC<Props> = ({
  children,
}) => {
  const [isOpen, setIsVisible] = React.useState(false);

  return (
    <CustomerFeedbackModalContext.Provider
      value={{
        isOpen,
        open: (): void => setIsVisible(true),
        close: (): void => setIsVisible(false),
      }}
    >
      {children}
    </CustomerFeedbackModalContext.Provider>
  );
};

export const useCustomerFeedbackModalContext = (): CustomerFeedbackModal => {
  const context = React.useContext(CustomerFeedbackModalContext);

  if (context === undefined) {
    throw new Error(
      "useCustomerFeedbackModalContext must be used within CustomerFeedbackModalContext's Provider"
    );
  }

  return context;
};
