import { BM_UNITS_QUERY_PARAMETERS_BATCH_SIZE } from "config/config";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";

const stitchJsonResults = <T>(
  results: JsonDataDownloadModel<T>[]
): JsonDataDownloadModel<T> => {
  const data = results.flatMap((result) =>
    // Null-coalescing operator necessary because summary endpoints return a list rather than expected model
    [...(result?.data ?? result)]
  );

  const { metadata } = results[0] ?? {};
  return { data, metadata };
};

const stitchCsvResults = (results: string[]): string =>
  results
    .filter((result) => result.length)
    .flatMap((result, index) => {
      const rows = result.split(/\r?\n/);
      // Include header row only for first result, and skip any empty rows
      return rows.slice(index > 0 ? 1 : 0).filter((row) => row.length);
    })
    .join("\r\n");

const stitchXmlResults = (results: string[]): string => {
  const domParser = new DOMParser();
  const xmlSerializer = new XMLSerializer();

  const data = results
    .filter((result) => result.length)
    .flatMap((result) => [
      ...domParser
        .parseFromString(result, "text/xml")
        .getElementsByTagName("Data")[0].childNodes,
    ]);

  if (!results[0]?.length) {
    return "";
  }

  const xmlDom = domParser.parseFromString(results[0], "text/xml");
  const dataTag = xmlDom.getElementsByTagName("Data")[0];
  dataTag.replaceChildren(...data);

  return xmlSerializer.serializeToString(xmlDom);
};

export const stitchDataResults = <T>(
  results: DataDownloadModel<T>[],
  format: DataDownloadFormat
): DataDownloadModel<T> => {
  switch (format) {
    case DataDownloadFormat.Json:
      return stitchJsonResults(results as JsonDataDownloadModel<T>[]);
    case DataDownloadFormat.Csv:
      return stitchCsvResults(results as string[]);
    case DataDownloadFormat.Xml:
      return stitchXmlResults(results as string[]);
    default:
      throw new Error("Unexpected DataDownloadFormat value.");
  }
};

export const batchBmuIds = (bmUnits: string[]): string[][] => {
  const bmuIdBatches = [];
  for (
    let i = 0;
    i < bmUnits.length;
    i += BM_UNITS_QUERY_PARAMETERS_BATCH_SIZE
  ) {
    bmuIdBatches.push(
      bmUnits.slice(i, i + BM_UNITS_QUERY_PARAMETERS_BATCH_SIZE)
    );
  }
  return bmuIdBatches;
};
