const isObject = (value: unknown): boolean => typeof value === "object";

const uniqueObjectKeys = (aKeys: string[], bKeys: string[]): string[] => [
  ...new Set([...aKeys, ...bKeys]),
];

export const areObjectsEqual = <T>(a: T, b: T): boolean => {
  if (a && b && isObject(a) && isObject(b)) {
    if (a.constructor !== b.constructor) {
      return false;
    }
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    return uniqueObjectKeys(aKeys, bKeys).every((key: string) => {
      const aChild = a[key as keyof T];
      const bChild = b[key as keyof T];
      type ChildType = typeof aChild;
      return areObjectsEqual<ChildType>(aChild, bChild);
    });
  }

  return a === b;
};
