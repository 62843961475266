import React from "react";
import { Link } from "react-router-dom";

import { StyledList } from "./style";

interface Props {
  linkText: string;
  linkTo: string;
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const NestedNavLink: React.FC<Props> = ({
  linkText,
  linkTo,
  onClick,
}: Props) => (
  <StyledList
    className="navbar-item"
    data-test-id={`nested-nav-link-${linkText.replace(/\s/g, "-")}`}
  >
    <Link to={linkTo} onClick={onClick}>
      {linkText}
    </Link>
  </StyledList>
);

export default NestedNavLink;
