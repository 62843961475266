import {
  getData,
  getDataset,
  getDatasetByPublishTimes,
  getGenerationOutturnSummaryData,
} from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { DataDownloadModel } from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { GenerationByFuelTypeRawSummaryDataModel } from "models/generationByFuelType/GenerationByFuelTypeRawSummaryDataModel";
import { OutturnInstantDataPointModel } from "models/outturnInstandModel/outturnInstantDataPointModel";
import { OutturnSummaryModel } from "models/outturnSummaryModel";
import { toDateOnlyString } from "utils/dateHelpers";

export const getGenerationByFuelTypeChartData = async (
  dateFilter: DateFilterModel
): Promise<OutturnSummaryModel[]> =>
  getGenerationOutturnSummaryData(dateFilter, false);

export const getGenerationByFuelTypeSnapshotData = async (): Promise<
  GenerationByFuelTypeRawSummaryDataModel[]
> => {
  const { data } = await getData("/generation/outturn/current");
  return data;
};

const maxDaysPerQuery = 7;

export const getGenerationByFuelTypeDownloadData = async (
  dateFilter: DateFilterModel,
  format: DataDownloadFormat,
  dataset: string
): Promise<DataDownloadModel<OutturnInstantDataPointModel>> =>
  getDatasetByPublishTimes<OutturnInstantDataPointModel>(
    dateFilter,
    format,
    dataset,
    maxDaysPerQuery
  );

export const getWindGenerationOutturnDownloadData = async (
  dateFilter: DateFilterModel,
  format: DataDownloadFormat
): Promise<DataDownloadModel<OutturnInstantDataPointModel>> =>
  getDataset(format, "FUELHH", {
    settlementDateFrom: toDateOnlyString(dateFilter.startDate),
    settlementDateTo: toDateOnlyString(dateFilter.endDate),
    fuelType: "WIND",
  });
