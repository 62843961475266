import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const StyledAnchor = styled.a`
  display: inline-block;
  font-size: ${fonts.small};
  font-family: "DIN Next LT W01", "Source Sans Pro", sans-serif;
  margin-top: 10px;
  color: ${colours.elexonBlack};
`;

export const StyledText = styled.span`
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledIcon = styled.span`
  font-family: elexon-icons;
  font-size: 10px;
  padding-left: 20px;
`;
