import ButtonLink from "components/components/ButtonLink/ButtonLink";
import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import PageMeta from "components/components/Meta/PageMeta";
import VideoContainer from "components/components/VideoContainer/VideoContainer";
import WhatsNewSection from "components/components/WhatsNewSection/WhatsNewSection";
import React from "react";
import routeConstants from "utils/routeConstants";

const Home: React.FC = () => (
  <>
    <PageMeta
      title="Home"
      description="GB electricity data from BMRS (Balancing Mechanism Reporting Service) - Elexon BSC"
    />
    <FullWidthSection className="is-medium is-grey-section">
      <div className="container px-6">
        <div className="columns">
          <div className="column is-one-half">
            <h1 className="title is-size-1 mb-5" data-test-id="welcome-heading">
              Welcome to the Insights Solution
            </h1>
            <p className="subtitle mt-3 mb-5">
              The Elexon Insights Solution is a key pillar of Elexon Kinnect, a
              digital customer-centric platform delivering a smart and flexible
              central data platform for the industry.
            </p>
            <p className="subtitle mb-5">
              Through a number of releases over the next few years, the platform
              will publish Balancing Mechanism Reporting Service (BMRS) data as
              well as Open Settlement Data.
            </p>
            <ButtonLink to="https://www.elexon.co.uk/operations-settlement/bsc-central-services/kinnect-insight-solution/">
              read more about this platform
            </ButtonLink>
          </div>
          <div className="column is-one-half">
            <VideoContainer
              src="https://player.vimeo.com/video/719146699?h=66302830fa&title=0&byline=0&portrait=0"
              targetHeight="370px"
              title="Elexon Kinnect Insights Solution"
            />
          </div>
        </div>
      </div>
    </FullWidthSection>

    <WhatsNewSection />

    <FullWidthSection className="is-grey-section">
      <div className="container px-6">
        <h2 className="title mb-6 mt-5">API Documentation</h2>
        <div className="columns">
          <div className="column pr-6">
            <p className="subtitle mb-5">
              All data sets for the Insights Solution is available via RESTful
              APIs. These are documented within the API Documentation where our
              customers can see the available endpoints and learn how to request
              the data.
            </p>
          </div>
          <div className="column pl-6">
            <ButtonLink className="enable-height" to={routeConstants.apiDocs}>
              API Documentation
            </ButtonLink>
            <p className="subtitle mb-5 pt-3">
              For more information, please email&nbsp;
              <a href="mailto:insightssupport@elexon.co.uk" className="is-link">
                insightssupport@elexon.co.uk
              </a>
            </p>
          </div>
        </div>
      </div>
    </FullWidthSection>
  </>
);

export default Home;
