import { getIndicativeCashflowJsonData } from "api/dataVisualisation/derivedData/indicativeCashflow";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { BmuModel } from "models/bmuData/bmuModel";
import { IndicativeCashflowModel } from "models/derivedData/indicativeCashflow";
import { useCallback } from "react";

import { BidOffer } from "./useAcceptanceVolumeData";

const useIndicativeCashflowData = (
  settlementTime: Date,
  bidOffer: BidOffer,
  bmus?: BmuModel[]
): {
  fetchData: () => void;
  data: IndicativeCashflowModel[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchIndicativeCashflowData,
  } = useRequest(getIndicativeCashflowJsonData);
  const fetchData = useCallback(() => {
    fetchIndicativeCashflowData(settlementTime, bidOffer, bmus);
  }, [bidOffer, bmus, fetchIndicativeCashflowData, settlementTime]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useIndicativeCashflowData;
