import { DynamicRatesDataModel } from "models/bmuData/dynamicRatesDataModel";

import DynamicRatesTableModel from "./DynamicRatesTableModel";

function getNameForRatesDataset(dataset: string): string {
  switch (dataset) {
    case "RDRE":
      return "Run-down rate export";
    case "RURE":
      return "Run-up rate export";
    case "RDRI":
      return "Run-down rate import";
    case "RURI":
      return "Run-up rate import";
    default:
      throw new Error(`Unknown rates dataset '${dataset}'`);
  }
}

export function mapToDynamicRatesRow(
  dynamicRatesData: DynamicRatesDataModel[]
): DynamicRatesTableModel[] {
  return dynamicRatesData.map((row, key) => ({
    id: key,
    dataset: getNameForRatesDataset(row.dataset),
    time: new Date(row.time),
    settlementPeriod: row.settlementPeriod,
    rate1: row.rate1,
    elbow2: row.elbow2,
    rate2: row.rate2,
    elbow3: row.elbow3,
    rate3: row.rate3,
  }));
}
