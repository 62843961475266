export interface NetBalancingServicesAdjustmentModel {
  startTime: Date;
  settlementDate: Date;
  settlementPeriod: number;
  netBuyPriceCostAdjustmentEnergy: number;
  netBuyPriceVolumeAdjustmentEnergy: number;
  netBuyPriceVolumeAdjustmentSystem: number;
  buyPricePriceAdjustment: number;
  netSellPriceCostAdjustmentEnergy: number;
  netSellPriceVolumeAdjustmentEnergy: number;
  netSellPriceVolumeAdjustmentSystem: number;
  sellPricePriceAdjustment: number;
}

export enum NetBalancingServicesAdjustmentIds {
  BuyPriceAdjustment = "buyPriceAdjustment",
  SellPriceAdjustment = "sellPriceAdjustment",
}
