import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import { FuelTypeGrouping } from "models/generationByFuelType/FuelTypeGroupings";
import { fuelTypeColours } from "styles/colours";

export const enum FuelTypeDatasetIds {
  FuelType = "fuelType",
  Interconnectors = "interconnectors",
}

export interface FuelType {
  displayName: string;
  colour: string | null;
  unitSuffix?: string;
  grouping: FuelTypeGrouping;
}

interface ChartDatasetModelWithGrouping extends ChartDatasetModel {
  grouping: FuelTypeGrouping;
}

export const fuelTypesMap: { [key: string]: FuelType } = {
  BIOMASS: {
    displayName: "Biomass",
    colour: fuelTypeColours.biomass,
    grouping: FuelTypeGrouping.lowCarbon,
  },
  CCGT: {
    displayName: "CCGT",
    colour: fuelTypeColours.ccgt,
    grouping: FuelTypeGrouping.fossilFuel,
  },
  COAL: {
    displayName: "Coal",
    colour: fuelTypeColours.coal,
    grouping: FuelTypeGrouping.fossilFuel,
  },
  NPSHYD: {
    displayName: "Hydro (non-PS)",
    colour: fuelTypeColours.npshyd,
    grouping: FuelTypeGrouping.lowCarbon,
  },
  NUCLEAR: {
    displayName: "Nuclear",
    colour: fuelTypeColours.nuclear,
    grouping: FuelTypeGrouping.lowCarbon,
  },
  OCGT: {
    displayName: "OCGT",
    colour: fuelTypeColours.ocgt,
    grouping: FuelTypeGrouping.fossilFuel,
  },
  OIL: {
    displayName: "Oil",
    colour: fuelTypeColours.oil,
    grouping: FuelTypeGrouping.fossilFuel,
  },
  OTHER: {
    displayName: "Other",
    colour: fuelTypeColours.other,
    grouping: FuelTypeGrouping.other,
  },
  PS: {
    displayName: "Pumped Storage",
    colour: fuelTypeColours.ps,
    grouping: FuelTypeGrouping.lowCarbon,
  },
  WIND: {
    displayName: "Wind",
    colour: fuelTypeColours.wind,
    grouping: FuelTypeGrouping.lowCarbon,
  },
  INTELEC: {
    displayName: "Eleclink (INTELEC)",
    colour: fuelTypeColours.intelec,
    grouping: FuelTypeGrouping.interconnector,
  },
  INTFR: {
    displayName: "France (IFA)",
    colour: fuelTypeColours.intfr,
    grouping: FuelTypeGrouping.interconnector,
  },
  INTEW: {
    displayName: "Ireland (East-West)",
    colour: fuelTypeColours.intew,
    unitSuffix: "MW",
    grouping: FuelTypeGrouping.interconnector,
  },
  INTIFA2: {
    displayName: "IFA2 (INTIFA2)",
    colour: fuelTypeColours.intifa2,
    grouping: FuelTypeGrouping.interconnector,
  },
  INTIRL: {
    displayName: "Northern Ireland (Moyle)",
    colour: fuelTypeColours.intirl,
    grouping: FuelTypeGrouping.interconnector,
  },
  INTNED: {
    displayName: "Netherlands (BritNed)",
    colour: fuelTypeColours.intned,
    grouping: FuelTypeGrouping.interconnector,
  },
  INTNEM: {
    displayName: "Belgium (Nemolink)",
    colour: fuelTypeColours.intnem,
    grouping: FuelTypeGrouping.interconnector,
  },
  INTNSL: {
    displayName: "North Sea Link (INTNSL)",
    colour: fuelTypeColours.intnsl,
    grouping: FuelTypeGrouping.interconnector,
  },
  INTVKL: {
    displayName: "Denmark (Viking link)",
    colour: fuelTypeColours.intvkl,
    grouping: FuelTypeGrouping.interconnector,
  },
  INTGRNL: {
    displayName: "Ireland (Greenlink)",
    colour: fuelTypeColours.intgrnl,
    grouping: FuelTypeGrouping.interconnector,
  },
};

const getFuelTypeToReturn = (
  fuelType: string
): ChartDatasetModelWithGrouping[] => {
  if (fuelType in fuelTypesMap) {
    const fuelTypeDataset = fuelTypesMap[fuelType];
    return [
      {
        displayName: fuelTypeDataset.displayName,
        dataKey: fuelType,
        colour: fuelTypeDataset.colour,
        unitSuffix: "MW",
        grouping: fuelTypeDataset.grouping,
      },
    ];
  } else {
    console.log(`Filtering out unrecognised fuel type ${fuelType}`);
    return [];
  }
};

// This needs to reverse of the above, because the area chart
// stacks each item on top of the previous item

export const mapFuelTypesToDatasetCategories = (
  fuelTypes: string[]
): DatasetCategory<ChartDatasetModelWithGrouping>[] => {
  const datasets = fuelTypes.reduce(
    (prev, fuelType) => {
      const interconnector =
        fuelType in fuelTypesMap &&
        fuelTypesMap[fuelType].grouping === FuelTypeGrouping.interconnector;
      const fuelTypeToReturn: ChartDatasetModelWithGrouping[] =
        getFuelTypeToReturn(fuelType);

      return {
        fuelTypes: [
          ...prev.fuelTypes,
          ...(!interconnector ? fuelTypeToReturn : []),
        ],
        interconnectors: [
          ...prev.interconnectors,
          ...(interconnector ? fuelTypeToReturn : []),
        ],
      };
    },
    {
      fuelTypes: [] as ChartDatasetModelWithGrouping[],
      interconnectors: [] as ChartDatasetModelWithGrouping[],
    }
  );
  datasets.fuelTypes.sort((x, y) => x.displayName.localeCompare(y.displayName));
  datasets.interconnectors.sort((x, y) =>
    x.displayName.localeCompare(y.displayName)
  );

  return [
    {
      datasets: datasets.fuelTypes,
      id: FuelTypeDatasetIds.FuelType,
      title: "Fuel type",
    },
    {
      datasets: datasets.interconnectors,
      id: FuelTypeDatasetIds.Interconnectors,
      title: "Interconnectors",
    },
  ];
};

export const mapFuelTypesToInterconnectorDatasetCategory = (
  fuelTypes: string[]
): DatasetCategory<ChartDatasetModelWithGrouping>[] =>
  mapFuelTypesToDatasetCategories(fuelTypes).filter(
    (x) => x.id === FuelTypeDatasetIds.Interconnectors
  );

export const interconnectorFuelTypes = Object.keys(fuelTypesMap).filter(
  (fuelType) =>
    fuelTypesMap[fuelType].grouping === FuelTypeGrouping.interconnector
);
