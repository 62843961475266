export interface SystemPriceModel {
  settlementPeriod: number;
  settlementDate: string;
  createdDateTime: string;
  startTime: string;
  systemSellPrice: number;
  systemBuyPrice: number;
  bsadDefaulted: boolean;
  priceDerivationCode: string | null;
  reserveScarcityPrice: number | null;
  netImbalanceVolume: number;
  sellPriceAdjustment: number | null;
  buyPriceAdjustment: number | null;
  replacementPrice: number | null;
  replacementPriceReferenceVolume: number | null;
  totalAcceptedOfferVolume: number | null;
  totalAcceptedBidVolume: number | null;
  totalAdjustmentSellVolume: number | null;
  totalAdjustmentBuyVolume: number | null;
  totalSystemTaggedAcceptedOfferVolume: number | null;
  totalSystemTaggedAcceptedBidVolume: number | null;
  totalSystemTaggedAdjustmentSellVolume: number | null;
  totalSystemTaggedAdjustmentBuyVolume: number | null;
}

export interface SystemPricesChartModel {
  startTime: string;
  systemSellPrice: number;
  netImbalanceVolume: number;
  settlementDate: string;
  settlementPeriod: number;
  bsadDefaulted: boolean;
  sellPriceAdjustment: number | null;
  buyPriceAdjustment: number | null;
  totalAcceptedOfferVolume: number | null;
  totalAcceptedBidVolume: number | null;
  totalAdjustmentSellVolume: number | null;
  totalAdjustmentBuyVolume: number | null;
}

export enum SystemPricesTooltip {
  systemSellPrice = "systemSellPrice",
  netImbalanceVolume = "netImbalanceVolume",
  sellPriceAdjustment = "sellPriceAdjustment",
  buyPriceAdjustment = "buyPriceAdjustment",
  bsadDefaulted = "bsadDefaulted",
  totalAcceptedOfferVolume = "totalAcceptedOfferVolume",
  totalAcceptedBidVolume = "totalAcceptedBidVolume",
  totalAdjustmentSellVolume = "totalAdjustmentSellVolume",
  totalAdjustmentBuyVolume = "totalAdjustmentBuyVolume",
}

export const systemPricesMap: Record<SystemPricesTooltip, string> = {
  systemSellPrice: "System Sell / Buy Price",
  netImbalanceVolume: "Net Imbalance Volume",
  sellPriceAdjustment: "Sell Price Adjustment",
  buyPriceAdjustment: "Buy Price Adjustment",
  bsadDefaulted: "BSAD Flag",
  totalAcceptedOfferVolume: "Total Accepted Offer Volume",
  totalAcceptedBidVolume: "Total Accepted Bid Volume",
  totalAdjustmentSellVolume: "Total Adjustment Sell Volume",
  totalAdjustmentBuyVolume: "Total Adjustment Buy Volume",
};

export enum SystemPricesDataKey {
  systemSellPrice = "buy-sell-systemSellPrice",
  netImbalanceVolume = "buy-sell-netImbalanceVolume",
  bsadDefaulted = "buy-sell-bsadDefaulted",
  buyPriceAdjustment = "buy-sell-buyPriceAdjustment",
  sellPriceAdjustment = "buy-sell-sellPriceAdjustment",
  totalAcceptedOfferVolume = "buy-sell-totalAcceptedOfferVolume",
  totalAcceptedBidVolume = "buy-sell-totalAcceptedBidVolume",
  totalAdjustmentSellVolume = "buy-sell-totalAdjustmentSellVolume",
  totalAdjustmentBuyVolume = "buy-sell-totalAdjustmentBuyVolume",
}
