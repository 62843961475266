import {
  getTotalLoadWeekAheadLatestChartData,
  getTotalLoadWeekAheadChartData,
} from "api/dataVisualisation/totalLoadWeekAhead/totalLoadWeekAhead";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { TotalLoadWeekAheadModel } from "models/TotalLoad/totalLoadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import { useCallback } from "react";

export const useTotalLoadWeekAheadData = (
  dateFilter: DateFilterModel | null,
  forecastFilter: ForecastFilterOption | null
): {
  data: TotalLoadWeekAheadModel[] | null;
  fetchData: () => void;
  status: RequestStatus;
} => {
  const {
    data: dataLatest,
    status: statusLatest,
    request: makeRequestByLatest,
  } = useRequest(getTotalLoadWeekAheadLatestChartData);

  const {
    data: dataByDateFilter,
    status: statusByDateFilter,
    request: makeRequestByDateFilter,
  } = useRequest(getTotalLoadWeekAheadChartData);

  const fetchDataLatest = useCallback(() => {
    makeRequestByLatest();
  }, [makeRequestByLatest]);

  const fetchDataByDateFilter = useCallback(() => {
    makeRequestByDateFilter(dateFilter!);
  }, [dateFilter, makeRequestByDateFilter]);

  return forecastFilter === ForecastFilterOption.weekAhead
    ? {
        data: dataLatest?.data ?? null,
        fetchData: fetchDataLatest,
        status: statusLatest,
      }
    : {
        data: dataByDateFilter?.data ?? null,
        fetchData: fetchDataByDateFilter,
        status: statusByDateFilter,
      };
};
