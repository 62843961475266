import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const CheckboxLabel = styled.label<{
  isInline?: boolean;
}>`
  cursor: pointer;
  font-size: ${fonts.mediumBigger};
  font-weight: 400;
  line-height: 1.4em;
  margin: -1em;

  ${({ isInline }): false | FlattenSimpleInterpolation =>
    !!isInline &&
    css`
      & {
        position: relative;
        font-size: inherit;
        font-weight: inherit;
        margin: 0;
      }
    `}
`;

export const NativeCheckboxInput = styled.input<{
  isInline?: boolean;
}>`
  position: relative;
  left: 1.22em;
  top: 1.5px;
  width: 1.1em;
  height: 1.1em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline-color: black;

  ${({ isInline }): false | FlattenSimpleInterpolation =>
    !!isInline &&
    css`
      & {
        position: absolute;
        left: 0;
      }
    `}
`;

export const CustomCheckbox = styled.span<{
  isChecked?: boolean;
  isIndeterminate?: boolean;
  isInline?: boolean;
}>`
  cursor: pointer;
  width: 1em;
  height: 1em;
  display: inline-block;
  color: white;
  text-align: center;
  position: relative;
  top: 0.3em;
  margin-right: 1.1em;
  border: 1.5px solid ${colours.elexonBlue};
  border-radius: 10%;

  &::after {
    content: "\\a0";
  }

  ${({ isChecked }): false | FlattenSimpleInterpolation =>
    !!isChecked &&
    css`
      & {
        background-color: ${colours.elexonBlue};

        &::after {
          content: "L";
          font-weight: normal;
          display: inline-block;
          transform: scaleX(-1) rotate(-45deg);
          position: relative;
          bottom: 6px;
          left: 1px;
        }
      }
    `}

  ${({ isIndeterminate }): false | FlattenSimpleInterpolation =>
    !!isIndeterminate &&
    css`
      & {
        background-color: ${colours.elexonBlue};

        &::after {
          content: "—";
          position: relative;
          bottom: 6px;
        }
      }
    `}

  ${({ isInline }): false | FlattenSimpleInterpolation =>
    !!isInline &&
    css`
      & {
        margin-right: 0.6em;
      }
    `}
`;
