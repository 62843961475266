import { getDataset } from "api/dataVisualisation/client";
import AdjustmentActionsDisbsadDataExporter from "components/dataVisualization/AdjustmentActionsDisbsad/AdjustmentActionsDisbsadDataExporter/AdjustmentActionsDisbsadDataExporter";
import AdjustmentActionsDisbsadDetailsTableContainer from "components/dataVisualization/AdjustmentActionsDisbsad/AdjustmentActionsDisbsadDetailsTableContainer/AdjustmentActionsDisbsadDetailsTableContainer";
import AdjustmentActionsDisbsadTooltip from "components/dataVisualization/AdjustmentActionsDisbsad/AdjustmentActionsDisbsadTooltip/AdjustmentActionsDisbsadTooltip";
import PriceAdjustmentChart from "components/dataVisualization/AdjustmentActionsDisbsad/PriceAdjustmentChart/PriceAdjustmentChart";
import VolumeAdjustmentChart from "components/dataVisualization/AdjustmentActionsDisbsad/VolumeAdjustmentChart/VolumeAdjustmentChart";
import MultiChartRectangleContainer from "components/dataVisualization/chartComponents/MultiChartRectangleContainer/MultiChartRectangleContainer";
import { MultiChartTitle } from "components/dataVisualization/chartComponents/MultiChartTitle/style";
import { RectangleSliceTooltipData } from "components/dataVisualization/chartComponents/RectangleSlice/RectangleSlicesLayer";
import {
  ChartFooter,
  ChartHeader,
  ChartTable,
} from "components/dataVisualization/chartComponents/style";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import { useDataExport } from "contexts/DataExportContext";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DisaggregatedBalancingServicesAdjustmentIds,
  DisaggregatedBalancingServicesAdjustmentCategoryIds,
} from "models/disaggregatedBalancingServicesAdjustment/disaggregatedBalancingServicesAdjustmentSummaryModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useEffect, useMemo, useState } from "react";
import { disbsadDatasetColours } from "styles/colours";
import {
  transformDisaggregatedBalancingServicesAdjustmentModelAdjustmentActivitiesData,
  transformDisaggregatedBalancingServicesAdjustmentModelPriceAdjustmentData,
  transformDisaggregatedBalancingServicesAdjustmentModelVolumeAdjustmentData,
} from "utils/ChartDataUtils/adjustmentActionsDisbsad/adjustmentActionsDisbsad";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import { addTooltipRangeValuesToDatasets } from "utils/chartTooltipUtils";
import {
  addDaysToDate,
  ceilToHalfHour,
  getNumberOfHoursBetweenDates,
  HOURS_IN_ONE_DAY,
  THREE_DAYS,
} from "utils/dateHelpers";

import useAdjustmentActionsDisbsadChartContainerData from "./useAdjustmentActionsDisbsadChartContainerData";

const datasetCategories: DatasetCategory<ChartDatasetModel>[] = [
  {
    datasets: [
      {
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.BuyActionCount
          ],
        dataKey: DisaggregatedBalancingServicesAdjustmentIds.BuyActionCount,
        displayName: "Buy Action",
      },
      {
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.SellActionCount
          ],
        dataKey: DisaggregatedBalancingServicesAdjustmentIds.SellActionCount,
        displayName: "Sell Action",
      },
    ],
    id: DisaggregatedBalancingServicesAdjustmentCategoryIds.AdjustmentActivities,
    title: "Adjustment Activities",
  },
  {
    datasets: [
      {
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.BuyPriceMin
          ],
        dataKey: DisaggregatedBalancingServicesAdjustmentIds.BuyPriceMin,
        displayName: "Buy Price Minimum",
      },
      {
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.BuyPriceAvg
          ],
        dataKey: DisaggregatedBalancingServicesAdjustmentIds.BuyPriceAvg,
        displayName: "Buy Price Average",
      },
      {
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.BuyPriceMax
          ],
        dataKey: DisaggregatedBalancingServicesAdjustmentIds.BuyPriceMax,
        displayName: "Buy Price Maximum",
      },
      {
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.SellPriceMin
          ],
        dataKey: DisaggregatedBalancingServicesAdjustmentIds.SellPriceMin,
        displayName: "Sell Price Minimum",
      },
      {
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.SellPriceAvg
          ],
        dataKey: DisaggregatedBalancingServicesAdjustmentIds.SellPriceAvg,
        displayName: "Sell Price Average",
      },
      {
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.SellPriceMax
          ],
        dataKey: DisaggregatedBalancingServicesAdjustmentIds.SellPriceMax,
        displayName: "Sell Price Maximum",
      },
    ],
    id: DisaggregatedBalancingServicesAdjustmentCategoryIds.PriceAdjustments,
    title: "Price (£/MWh)",
  },
  {
    datasets: [
      {
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.BuyVolume
          ],
        dataKey: DisaggregatedBalancingServicesAdjustmentIds.BuyVolume,
        displayName: "Buy Volume",
      },
      {
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.SellVolume
          ],
        dataKey: DisaggregatedBalancingServicesAdjustmentIds.SellVolume,
        displayName: "Sell Volume",
      },
      {
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.NetVolume
          ],
        dataKey: DisaggregatedBalancingServicesAdjustmentIds.NetVolume,
        displayName: "Net Volume",
      },
    ],
    id: DisaggregatedBalancingServicesAdjustmentCategoryIds.VolumeAdjustments,
    title: "Volume (MWh)",
  },
];

const AdjustmentActionsDisbsadChartContainer: React.FC = () => {
  const defaultEndDate = ceilToHalfHour(new Date());

  const chartDateSelectorTabs = [
    DateSelectorTabs.TwentyFourHours,
    DateSelectorTabs.ThreeDays,
  ];

  const [chartDateFilter, setChartDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(
      addDaysToDate(defaultEndDate, -3),
      new Date(defaultEndDate)
    )
  );

  const [downloadDateFilter, setDownloadDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(
      addDaysToDate(defaultEndDate, -1),
      new Date(defaultEndDate)
    )
  );

  const [activeChartDateSelectorTab, setActiveChartDateSelectorTab] = useState(
    DateSelectorTabs.ThreeDays
  );

  const [activeDownloadDateSelectorTab, setActiveDownloadDateSelectorTab] =
    useState(DateSelectorTabs.TwentyFourHours);

  const [rectangleSliceData, setRectangleSliceData] =
    useState<RectangleSliceTooltipData>();

  const [tableDate, setTableDate] = useState<Date>();

  const { dispatch: dataExportCountDispatch } = useDataExport();

  const {
    data: rawData,
    fetchData,
    status,
  } = useAdjustmentActionsDisbsadChartContainerData(chartDateFilter);

  useEffect(fetchData, [fetchData]);

  const adjustmentActivitiesChartData = useMemo(
    () =>
      rawData?.length
        ? transformDisaggregatedBalancingServicesAdjustmentModelAdjustmentActivitiesData(
            rawData
          )
        : [],
    [rawData]
  );

  const priceAdjustmentChartData = useMemo(
    () =>
      rawData?.length
        ? transformDisaggregatedBalancingServicesAdjustmentModelPriceAdjustmentData(
            rawData
          )
        : [],
    [rawData]
  );

  const volumeAdjustmentChartData = useMemo(
    () =>
      rawData?.length
        ? transformDisaggregatedBalancingServicesAdjustmentModelVolumeAdjustmentData(
            rawData
          )
        : [],
    [rawData]
  );

  const allChartData = useMemo(
    () => [
      ...adjustmentActivitiesChartData,
      ...priceAdjustmentChartData,
      ...volumeAdjustmentChartData,
    ],
    [
      adjustmentActivitiesChartData,
      priceAdjustmentChartData,
      volumeAdjustmentChartData,
    ]
  );

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () =>
        getDataset(fileFormat, "DISBSAD", {
          from: downloadDateFilter.startDate.toISOString(),
          to: downloadDateFilter.endDate.toISOString(),
        }),
      `AdjustmentActionsDisbsad-${downloadDateFilter.startDate.toISOString()}-${downloadDateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const handleChangeToChartDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    const newChartDateFilter = newDateFilter.restrictToMaxDaysFromStart(3);

    setChartDateFilter(newChartDateFilter);
    setActiveChartDateSelectorTab(newActiveTab);
    setDownloadDateFilter(newChartDateFilter.restrictToMaxDaysFromEnd(1));

    const hoursBetweenDates = getNumberOfHoursBetweenDates(
      newChartDateFilter.startDate,
      newChartDateFilter.endDate
    );

    if (
      hoursBetweenDates >= HOURS_IN_ONE_DAY &&
      newActiveTab !== DateSelectorTabs.Date
    ) {
      setActiveDownloadDateSelectorTab(DateSelectorTabs.TwentyFourHours);
    } else {
      setActiveDownloadDateSelectorTab(DateSelectorTabs.Date);
    }

    setTableDate(undefined);
  };

  const handleChangeToDownloadDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    const newChartDateFilter = newDateFilter.restrictToMaxDaysFromStart(1);
    setChartDateFilter(newChartDateFilter);
    setActiveChartDateSelectorTab(newActiveTab);
    setDownloadDateFilter(newChartDateFilter);
    setActiveDownloadDateSelectorTab(newActiveTab);
    setTableDate(undefined);
  };

  const onRectangleSelection = (
    selectedRectangle?: RectangleSliceTooltipData
  ): void => {
    const selectedDate = selectedRectangle?.slicePoints?.[0]?.data?.x as
      | Date
      | undefined;

    setTableDate(selectedDate);

    if (!selectedDate) {
      return;
    }

    // scroll table into view
    document
      .getElementById("chart-table")!
      .scrollIntoView({ block: "start", behavior: "smooth" });
  };

  return (
    <>
      <ChartHeader>
        <ChartFilter
          dateSelectorTabs={chartDateSelectorTabs}
          dateFilter={chartDateFilter}
          handleChangeToDateSelection={handleChangeToChartDateSelection}
          activeDateSelectorTab={activeChartDateSelectorTab}
          maxRange={THREE_DAYS}
        />
      </ChartHeader>
      <MultiChartRectangleContainer
        allChartData={allChartData}
        datasetCategories={datasetCategories}
        tooltipLabel="Start Time"
        topOffset={29}
        height={610}
        highlightedRectangle={rectangleSliceData}
        showTooltipCategoryTitles
        dateFilter={chartDateFilter}
        dataFetchStatus={status}
        tooltip={(highlightedRectangle): JSX.Element => (
          <AdjustmentActionsDisbsadTooltip
            highlightedRectangle={highlightedRectangle}
            datasetCategories={addTooltipRangeValuesToDatasets(
              allChartData,
              datasetCategories,
              highlightedRectangle.sliceStartValue,
              highlightedRectangle.sliceEndValue
            )}
          />
        )}
        onRectangleSelection={onRectangleSelection}
      >
        <>
          <MultiChartTitle>Price</MultiChartTitle>
          <PriceAdjustmentChart
            series={priceAdjustmentChartData}
            datasetCategories={datasetCategories}
            dataFetchStatus={status}
            rerequestData={fetchData}
            dateFilter={chartDateFilter}
            rectangleSliceData={rectangleSliceData}
            setRectangleSliceData={setRectangleSliceData}
          />
          <MultiChartTitle>Volume</MultiChartTitle>
          <VolumeAdjustmentChart
            series={volumeAdjustmentChartData}
            datasetCategories={datasetCategories}
            dataFetchStatus={status}
            rerequestData={fetchData}
            dateFilter={chartDateFilter}
            rectangleSliceData={rectangleSliceData}
            setRectangleSliceData={setRectangleSliceData}
          />
        </>
      </MultiChartRectangleContainer>
      <ChartTable id="chart-table">
        <AdjustmentActionsDisbsadDetailsTableContainer startTime={tableDate} />
      </ChartTable>
      <ChartFooter rightAligned id="chart-footer">
        <AdjustmentActionsDisbsadDataExporter
          downloadData={downloadFile}
          dateFilter={downloadDateFilter}
          handleChangeToDateFilter={handleChangeToDownloadDateSelection}
          activeDateSelectorTab={activeDownloadDateSelectorTab}
        />
      </ChartFooter>
    </>
  );
};

export default AdjustmentActionsDisbsadChartContainer;
