import Expander from "components/components/Expander/Expander";
import {
  ContactContainer,
  ExpanderSection,
  FaqContainer,
} from "components/components/Iris/LandingPageInfo/Faqs/style";
import { SubsectionHeader } from "components/components/Iris/commonStyle";
import React from "react";

const Faqs: React.FC = () => (
  <div className="container px-6">
    <SubsectionHeader>FAQs</SubsectionHeader>
    <FaqContainer>
      <ExpanderSection>
        <Expander
          title="Why use IRIS?"
          content={
            <>
              <p>
                IRIS is particularly useful for time-sensitive data. Since
                it&apos;s a push service, you will receive messages as soon as
                they become available - rather than having to poll the APIs.
              </p>
              <p>
                It also sends you all the information we have. If you want
                access to all of the data, for example if you want to use it in
                a machine learning model, using IRIS is more convenient that
                having to poll multiple different endpoints.
              </p>
            </>
          }
          classModifier="expander-list-item"
        />
        <Expander
          title="How much does it cost?"
          content={
            <p>Nothing, it&apos;s free! Create an account to get started.</p>
          }
          classModifier="expander-list-item"
        />
        <Expander
          title="How long does it take to set up?"
          content={<p>Approximately 5 to 10 minutes.</p>}
          classModifier="expander-list-item"
        />
        <Expander
          title="Where do I get a message client?"
          content={
            <>
              <p>
                You will need to connect to a message client through the
                Advanced Message Queuing Protocol (AMQP).
              </p>
              <p>
                We have provided example clients on GitHub in Python, Node.js
                and C#. But you are also able to write your own.
              </p>
            </>
          }
          classModifier="expander-list-item"
        />
        <Expander
          title="What happens if I get disconnected from IRIS?"
          content={
            <>
              <p>
                All IRIS messages have a time-to-live (TTL) value of 3 days.
              </p>
              <p>
                If you do not connect to IRIS for 3 days or more, some messages
                may have expired and no longer be available. In this case, it is
                possible to fill any data gaps using the APIs, given that they
                share the same output format.
              </p>
            </>
          }
          classModifier="expander-list-item"
        />
        <Expander
          title="What happens in an outage?"
          content={
            <>
              <p>
                During a data outage, IRIS will not send data but will remain
                connected.
              </p>
              <p>
                After the outage, any missing files will be submitted to the
                Insights Solution. IRIS will then recommence and files will
                start to appear in your data feed. The files will be sent in the
                order they are received in to the platform.
              </p>
            </>
          }
          classModifier="expander-list-item"
        />
        <Expander
          title="Is there a difference between the data provided via API service and IRIS?"
          content={
            <p>
              No, IRIS and the APIs make available the same data, in the same
              output format. This interchangeability allows you to decide which
              data source works best for you or to use a combination.
            </p>
          }
          classModifier="expander-list-item"
        />
      </ExpanderSection>
    </FaqContainer>
    <ContactContainer>
      If you have any other questions, please get in touch with our team at
      <a href="mailto:insightssupport@elexon.co.uk" className="is-link">
        insightssupport@elexon.co.uk
      </a>
    </ContactContainer>
  </div>
);

export default Faqs;
