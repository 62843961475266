import ButtonLink from "components/components/ButtonLink/ButtonLink";
import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const Header = styled.h2`
  color: ${colours.darkGrey};
  padding-top: 1em;

  && {
    margin-bottom: 0;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  margin: 1.5em 0;
`;

export const LinkSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const LinkContentDiv = styled.div`
  h3 {
    color: ${colours.darkGrey};
    font-size: ${fonts.medium};
  }

  p {
    color: ${colours.darkGrey};
    font-size: ${fonts.smallMedium};
    padding-right: 2em;
    margin-bottom: 1em;
  }
`;

export const StyledLink = styled(ButtonLink)`
  & {
    border: 1px solid ${colours.linkColor};
    padding: 4px 36px;
    color: ${colours.linkColor};
    background-color: transparent;
    letter-spacing: normal;
  }

  :hover {
    text-decoration: underline;
    border: 1px solid ${colours.elexonBlue};
    color: ${colours.linkColor};
  }

  :active {
    box-shadow: inset 5px 5px 8px ${colours.elexonBlack}60;
  }
`;
