import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

import { ModalHeader, ModalSubHeader } from "./commonStyle";

export const StyledModalBackground = styled.div`
  background-color: ${colours.elexonBlack};
  opacity: 0.5;
`;

export const StyledModalContent = styled.div`
  width: 40vw;
  max-width: 400px;

  &.medium {
    width: 50vw;
    max-width: 780px;

    @media screen and (max-width: 1279px) {
      width: 90vw;
    }
  }

  &.overflow {
    overflow: visible;
  }

  &.large {
    width: 60vw;
    max-width: 820px;

    @media screen and (max-width: 1279px) {
      width: 90vw;
    }
  }

  &.x-large {
    width: 80vw;
    max-width: 1520px;

    @media screen and (max-width: 1279px) {
      width: 90vw;
    }
  }

  & button.filter,
  & .react-datepicker__input-container button {
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;

    &.interval {
      width: 100px;
    }
  }

  ${ModalHeader} {
    font-family: "DIN Next LT W01", "Source Sans Pro", sans-serif;
    font-size: ${fonts.mediumLarge};
    color: ${colours.elexonPurple};
    display: block;
    margin-bottom: 0;
  }

  ${ModalSubHeader} {
    display: flex;
    letter-spacing: 0.1em;
    margin-bottom: 15px;
    font-size: ${fonts.smallMedium};
    color: ${colours.elexonBlack};
  }
`;

export const ModalContentContainer = styled.div`
  border-radius: 5px;
  padding: 20px;
  padding-bottom: 20px;
  background-color: ${colours.white};
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-in;

  hr {
    background-color: ${colours.highlightGrey};
    height: 1px;
    margin: 40px 0;
  }
`;
