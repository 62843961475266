const fonts = {
  xSmall: "0.8em",
  small: "1em",
  smallMedium: "1.1em",
  mediumSmaller: "1.25em",
  medium: "1.3em",
  mediumBigger: "1.5em",
  mediumLarge: "1.6em",
  large: "2.1em",
  xLarge: "3em",
  huge: "4.2em",
  light: 300,
  normal: 400,
  semibold: 600,
  heavy: 700,
  SourceSansPro: "Source Sans Pro",
};

export default fonts;
