import { getGenerationByFuelTypeChartData } from "api/dataVisualisation/generationByFuelType/generationByFuelType";
import {
  getWindGenerationInitialForecastJsonData,
  getWindGenerationLatestForecastJsonData,
} from "api/dataVisualisation/windGenerationForecast/windGenerationForecast";
import { WindGenerationModel } from "components/dataVisualization/WindGeneration/WindGenerationChart/WindGenDatasets";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useCallback, useMemo } from "react";
import { combineRequestStatuses } from "utils/requestHelpers";

const useWindGenerationChartContainerData = (
  windGenDateRange: DateFilterModel
): {
  data: WindGenerationModel | null;
  status: RequestStatus;
  fetchData: () => void;
} => {
  const {
    data: outturnData,
    status: outturnStatus,
    request: fetchOutturnData,
  } = useRequest(getGenerationByFuelTypeChartData);

  const {
    data: latestForecastData,
    status: latestForecastStatus,
    request: fetchLatestForecastData,
  } = useRequest(getWindGenerationLatestForecastJsonData);

  const {
    data: initialForecastData,
    status: initialForecastStatus,
    request: fetchInitialForecastData,
  } = useRequest(getWindGenerationInitialForecastJsonData);

  const fetchData = useCallback(() => {
    fetchOutturnData(windGenDateRange);
    fetchLatestForecastData(windGenDateRange);
    fetchInitialForecastData(windGenDateRange);
  }, [
    fetchOutturnData,
    fetchLatestForecastData,
    fetchInitialForecastData,
    windGenDateRange,
  ]);

  const data = useMemo(
    () =>
      outturnData && latestForecastData && initialForecastData
        ? {
            outturnData: outturnData.flatMap((d) => ({
              startTime: d.startTime,
              settlementPeriod: d.settlementPeriod,
              outturn: d.data.find((x) => x.fuelType === "WIND")?.generation,
            })),
            latestForecastData: latestForecastData.data,
            initialForecastData: initialForecastData.data,
          }
        : null,
    [outturnData, latestForecastData, initialForecastData]
  );

  const status = combineRequestStatuses(
    outturnStatus,
    latestForecastStatus,
    initialForecastStatus
  );

  return {
    data,
    status,
    fetchData,
  };
};

export default useWindGenerationChartContainerData;
