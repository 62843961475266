import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import useOnEscape from "hooks/useOnEscape";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import colours from "styles/colours";

import { FollowCursorContainer, TooltipArrow, TooltipContainer } from "./style";

interface BaseInformationTooltipProps {
  children: JSX.Element;
  followCursor?: boolean;
  isVisible: boolean;
  setIsVisible: (newVisible: boolean) => void;
  colour?: string;
}

const BaseInformationTooltip: React.FC<BaseInformationTooltipProps> = ({
  children,
  followCursor,
  isVisible,
  setIsVisible,
  colour = colours.darkGrey,
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const childrenRef = useRef<HTMLDivElement>(null);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: "auto",
    trigger: ["hover", "focus"],
    visible: isVisible,
    onVisibleChange: setIsVisible,
  });

  const closeTooltip = useCallback(() => setIsVisible(false), [setIsVisible]);
  useOnEscape(closeTooltip);

  const onMouseMove = (e: MouseEvent): void => {
    if (!childrenRef.current) {
      return;
    }

    setPosition({
      x: e.clientX - childrenRef.current.clientWidth / 2,
      y: e.clientY - childrenRef.current.clientHeight / 2,
    });
  };

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);

    return (): void => document.removeEventListener("mousemove", onMouseMove);
  }, []);

  const TooltipWrapper = followCursor ? FollowCursorContainer : "div";
  const Tooltip = followCursor ? "div" : TooltipContainer;

  return (
    <>
      {!followCursor && (
        <div
          ref={setTriggerRef}
          role="button"
          tabIndex={0}
          data-test-id="information-tooltip"
        >
          <Icon
            iconName={faQuestionCircle}
            colour={colour}
            ariaLabel="Information tooltip"
          />
        </div>
      )}
      <TooltipWrapper
        aria-live="polite"
        style={
          followCursor
            ? {
                left: `${position.x}px`,
                top: `${position.y}px`,
              }
            : undefined
        }
      >
        {visible && (
          <Tooltip
            ref={setTooltipRef}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...getTooltipProps()}
            role="tooltip"
            data-test-id="tooltip"
          >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {!followCursor && <TooltipArrow {...getArrowProps()} />}
            <div ref={childrenRef}>{children}</div>
          </Tooltip>
        )}
      </TooltipWrapper>
    </>
  );
};

interface InformationTooltipProps {
  colour?: string;
  children: JSX.Element;
}

const InformationTooltip: React.FC<InformationTooltipProps> = ({
  colour,
  children,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  return (
    <BaseInformationTooltip
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      colour={colour}
    >
      {children}
    </BaseInformationTooltip>
  );
};

interface ControlledInformationTooltipProps {
  children: JSX.Element;
  isVisible: boolean;
  setIsVisible: (newVisible: boolean) => void;
}

export const ControlledInformationTooltip: React.FC<
  ControlledInformationTooltipProps
> = ({ children, isVisible, setIsVisible }) => (
  <BaseInformationTooltip
    followCursor
    isVisible={isVisible}
    setIsVisible={setIsVisible}
  >
    {children}
  </BaseInformationTooltip>
);

export default InformationTooltip;
