import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { TabulatedChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/TabulatedChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayers } from "styles/chartStyles/chartLayers";
import { chartColours } from "styles/colours";
import { defaultColumnFormatter } from "utils/ChartDataUtils";

interface Props {
  series: Serie[];
  rerequestData: () => void;
  dataFetchStatus: RequestStatus;
}

const datasetCategories: DatasetCategory<ChartDatasetModel>[] = [
  {
    datasets: [
      {
        colour: chartColours.rollingSystemDemandData,
        dataKey: "demand",
        displayName: "System demand",
      },
    ],
    id: "datasets",
  },
];

const config: ChartConfiguration = {
  datasetCategories,
  tooltipColumns: [
    {
      id: "value",
      dataKey: "y",
      formatter: defaultColumnFormatter({ suffix: "MW" }),
    },
  ],
};

const RollingSystemDemandChart: React.FC<Props> = ({
  series,
  rerequestData,
  dataFetchStatus,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;
    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        enableSlices="x"
        axisBottom={chartAxisBottom("Start Time (UTC)", series, isChartLoaded)}
        axisLeft={chartAxisLeft("MW", isChartLoaded)}
        layers={chartLayers}
        sliceTooltip={(input): JSX.Element => (
          <TabulatedChartTooltip
            series={series}
            config={config}
            text={{ label: "Start Time" }}
            options={{
              interactionType: TooltipInteractionType.Slice,
            }}
            inputPoints={input.slice.points}
          />
        )}
        xScale={chartXTimeScale()}
        yScale={nonStackedLineChartYScale(series)}
      />
    );
  }, [series, dataFetchStatus]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      >
        {memoisedChart}
      </ChartWithErrorHandling>
    ),
    [dataFetchStatus, memoisedChart, rerequestData, series]
  );
};

export default RollingSystemDemandChart;
