import React from "react";
import { disableAnalytics } from "utils/analyticsUtils";
import { deleteCookies } from "utils/cookieHelper";
import featureFlags from "utils/featureFlags";

import RenderAnalytics from "./RenderAnalytics";

const Analytics: React.FC = () => {
  if (
    (featureFlags.google_analytics || featureFlags.hotjar_analytics) &&
    featureFlags.cookieBanner
  ) {
    return <RenderAnalytics />;
  }
  disableAnalytics();
  if (!featureFlags.cookieBanner) {
    deleteCookies();
  }
  return <></>;
};

export default Analytics;
