import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import FormErrorMessage from "components/components/FormErrorMessage/FormErrorMessage";
import React, { forwardRef, InputHTMLAttributes } from "react";
import colours from "styles/colours";

import { StyledInput } from "./style";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  id: string;
  label: string;
  maxLength?: number;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  showError?: boolean;
  required?: boolean;
  type?: InputHTMLAttributes<HTMLInputElement>["type"];
}

const TextInput: React.FC<Props & React.RefAttributes<HTMLInputElement>> =
  forwardRef<HTMLInputElement, Props>(function textInput(
    {
      onChange,
      value,
      name,
      id,
      label,
      maxLength,
      onBlur = (): void => {
        // This is intentionally blank
      },
      errorMessage = "",
      showError,
      required,
      type = "text",
    }: Props,
    ref
  ) {
    return (
      <div className="block field has-text-weight-bold">
        <label htmlFor={id}>
          <span>{label}</span>
          {required === true && <span aria-hidden>*</span>}
          <div
            className={classnames("control", { "has-icons-left": showError })}
          >
            <StyledInput
              className={classnames("input", { invalid: showError })}
              id={id}
              value={value}
              name={name}
              maxLength={maxLength}
              onChange={onChange}
              onBlur={onBlur}
              required={required}
              type={type}
              ref={ref}
            />
            {showError && (
              <FormErrorMessage
                iconName={faInfoCircle}
                iconColour={colours.elexonRed}
                messageText={errorMessage}
              />
            )}
          </div>
        </label>
      </div>
    );
  });

export default TextInput;
