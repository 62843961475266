import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { NetBalancingServicesAdjustmentModel } from "models/netBalancingServicesAdjustment/netBalancingServicesAdjustmentModel";

export const getNetBalancingServicesAdjustmentTimeSeriesData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<NetBalancingServicesAdjustmentModel>> => {
  const { data } = await getData(
    "/balancing/nonbm/netbsad",
    {
      from: dateFilter.startDate,
      to: dateFilter.endDate,
      includeZero: true,
    },
    format
  );
  return data;
};

export const getNetBalancingServicesAdjustmentTimeSeriesJsonData = (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<NetBalancingServicesAdjustmentModel>> =>
  getNetBalancingServicesAdjustmentTimeSeriesData(
    dateFilter,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<NetBalancingServicesAdjustmentModel>>;

export const getNetBalancingServicesAdjustmentEventsData = async (
  count: number,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<NetBalancingServicesAdjustmentModel>> => {
  const { data } = await getData(
    "/balancing/nonbm/netbsad/events",
    {
      count,
    },
    format
  );
  return data;
};

export const getNetBalancingServicesAdjustmentEventsJsonData = (
  count: number
): Promise<JsonDataDownloadModel<NetBalancingServicesAdjustmentModel>> =>
  getNetBalancingServicesAdjustmentEventsData(
    count,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<NetBalancingServicesAdjustmentModel>>;
