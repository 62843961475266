import { WidthType } from "components/components/Tabs/Tabs/Tabs";
import styled from "styled-components";

export interface TabListProps {
  widthType: WidthType;
}

export const TabListContainer = styled.div<TabListProps>`
  max-width: ${(props): string => {
    switch (props.widthType) {
      case WidthType.Wide:
        return "";
      case WidthType.SemiWide:
        return "1000px";
      case WidthType.NotWide:
        return "500px";
      default:
        return "1000px";
    }
  }};
`;

export const TabList = styled.div`
  display: flex;
  justify-content: space-between;
  margin: -1px;

  li + li {
    margin: 0;
  }
`;
