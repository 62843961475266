import StyledButton from "components/components/Button/style";
import React from "react";

interface Props {
  rerequestData: () => void;
  buttonDataTestId?: string;
}

const RerequestButton: React.FC<Props> = ({
  rerequestData,
  buttonDataTestId,
}) => (
  <>
    <h2>Data failed to load</h2>
    <StyledButton
      onClick={rerequestData}
      className="button"
      data-test-id={buttonDataTestId}
    >
      Try Again
    </StyledButton>
  </>
);

export default RerequestButton;
