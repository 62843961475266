import { Point, Serie } from "@nivo/line";
import ChartTooltipContent from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipContent/ChartTooltipContent";
import ChartTooltipPositionWrapper, {
  TooltipInteractionType,
} from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import {
  DatasetCategory,
  combineDatasetCategories,
} from "models/chartConfiguration/chartDatasetModel";
import { ChartDatasetTooltipModel } from "models/chartConfiguration/tooltipModels";
import React from "react";
import { addTooltipPointValuesToDatasets } from "utils/chartTooltipUtils";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

export interface TooltipText {
  subtitle?: string;
  label?: string;
  totalFormatter?: (total: number) => string;
  totalUnitSuffix?: string; // Obsolete - use totalFormatter and Tabulated version of tooltip where possible
  description?: string;
}

export interface ChartTooltipOptions {
  showTotal?: boolean; // Obsolete - use TooltipText.totalFormatter & Tabulated version of tooltip where possible
  dateFormat?: DateFormat;
  highlightSelectedDataset?: boolean;
  showCategoryTitles?: boolean;
  showValues?: boolean;
}

interface StandardContentProps {
  datasetCategories: DatasetCategory<ChartDatasetTooltipModel>[];
  date: Date;
  text: TooltipText;
  selectedDataset?: string;
  options: ChartTooltipOptions;
}

/**
 * @deprecated - please use TabulatedChartTooltip instead
 */
export const StandardChartTooltipContent: React.FC<StandardContentProps> = ({
  datasetCategories,
  options: { dateFormat, showCategoryTitles, showValues, showTotal },
  date,
  text,
  selectedDataset,
}) => {
  const { subtitle, label, totalUnitSuffix, description } = text;
  const combinedDatasets = combineDatasetCategories(datasetCategories);
  const total = showTotal
    ? combinedDatasets
        .map((dataset) => dataset.value)
        .filter((value): value is number => !!value)
        .reduce((previous, current) => previous + current, 0)
    : undefined;

  const title =
    (label ? `${label} - ` : "") + formatDateTimeString(date, dateFormat);

  const settlementPeriod = combinedDatasets.find(
    (c) => c.settlementPeriod !== undefined
  )?.settlementPeriod;
  const hasPublishTimes = combinedDatasets.some(
    (d) => d.publishTime !== undefined
  );

  return (
    <ChartTooltipContent
      datasetCategories={datasetCategories}
      subtitle={subtitle}
      total={total}
      settlementPeriod={settlementPeriod}
      title={title}
      hasPublishTimes={hasPublishTimes}
      totalUnitSuffix={totalUnitSuffix}
      selectedDataset={selectedDataset}
      showCategoryTitles={!!showCategoryTitles}
      description={description}
      showValues={!!showValues}
    />
  );
};

export const defaultChartTooltipOptions: Omit<
  Required<ChartTooltipOptions>,
  "interactionType"
> = {
  showTotal: false,
  dateFormat: DateFormat.DateTime,
  highlightSelectedDataset: false,
  showCategoryTitles: false,
  showValues: true,
};

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetTooltipModel>[];
  text?: TooltipText;
  options: ChartTooltipOptions & { interactionType: TooltipInteractionType };
  inputPoints: readonly Point[];
}

/**
 * @deprecated - please use TabulatedChartTooltip instead
 */
export const StandardChartTooltip: React.FC<Props> = ({
  series,
  datasetCategories,
  text = {},
  options,
  inputPoints,
}) => {
  const tooltipOptions = {
    ...defaultChartTooltipOptions,
    ...options,
  };
  const [dataset] = inputPoints[0].id.split(".");
  const [, idString] = inputPoints[0].id.split(".");
  const pointIsInLeftHalf =
    parseInt(idString, 10) / series[0].data.length < 0.5;
  return (
    <ChartTooltipPositionWrapper
      pointIsInLeftHalf={pointIsInLeftHalf}
      interactionType={tooltipOptions.interactionType}
    >
      <StandardChartTooltipContent
        datasetCategories={addTooltipPointValuesToDatasets(
          inputPoints[0].data,
          series,
          datasetCategories
        )}
        date={inputPoints[0].data.x as Date}
        options={tooltipOptions}
        text={text}
        selectedDataset={options.highlightSelectedDataset ? dataset : undefined}
      />
    </ChartTooltipPositionWrapper>
  );
};
