import { Serie } from "@nivo/line";
import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";
import { IndicatedForecastDayAhead } from "models/indicatedForecast/indicatedForecastDayAhead";

const addIndicatedForecastDayAheadDataToSeries = (
  series: Serie[],
  data: IndicatedForecastDayAhead
): Serie[] =>
  series.reduce(
    (prev, curr) => [
      ...prev,
      {
        id: curr.id,
        colour: curr.colour,
        data: [
          ...curr.data,
          {
            x: new Date(data.startTime),
            y: data[curr.id as keyof IndicatedForecastDayAhead],
            settlementPeriod: data.settlementPeriod,
            publishTime: data.publishTime,
          },
        ],
      },
    ],
    [] as Serie[]
  );

export const transformIndicatedForecastModelData = (
  rawData: IndicatedForecastDayAhead[],
  datasets: ChartDatasetModel[]
): Serie[] => {
  const initialSeries = datasets
    .filter((entry) => !entry.isHidden)
    .map<Serie>((entry) => ({
      id: entry.dataKey,
      colour: entry.colour,
      data: [],
    }));

  return rawData.reduce(
    addIndicatedForecastDayAheadDataToSeries,
    initialSeries
  );
};
