import { getData } from "api/dataVisualisation/client";
import { TotalLoadActualAndDayAheadData } from "models/TotalLoad/totalLoadModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";

export const getTotalLoadActualData = async (
  dateFilter: DateFilterModel,
  format: DataDownloadFormat
): Promise<DataDownloadModel<TotalLoadActualAndDayAheadData>> => {
  const { data } = await getData("/demand/actual/total", {
    from: dateFilter.normalisedStartDate.toISOString(),
    to: dateFilter.normalisedEndDate.toISOString(),
    format,
  });
  return data as Promise<DataDownloadModel<TotalLoadActualAndDayAheadData>>;
};

export const getTotalLoadDayAheadData = async (
  dateFilter: DateFilterModel,
  format: DataDownloadFormat
): Promise<DataDownloadModel<TotalLoadActualAndDayAheadData>> => {
  const { data } = await getData("/forecast/demand/total/day-ahead", {
    from: dateFilter.normalisedStartDate.toISOString(),
    to: dateFilter.normalisedEndDate.toISOString(),
    format,
  });
  return data as Promise<DataDownloadModel<TotalLoadActualAndDayAheadData>>;
};

export const getTotalLoadActualChartData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<TotalLoadActualAndDayAheadData>> => {
  return getTotalLoadActualData(dateFilter, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<TotalLoadActualAndDayAheadData>
  >;
};

export const getTotalLoadDayAheadChartData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<TotalLoadActualAndDayAheadData>> => {
  return getTotalLoadDayAheadData(
    dateFilter,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<TotalLoadActualAndDayAheadData>>;
};
