import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import { chartColours } from "styles/colours";

export enum TemperatureSeriesIds {
  Outturn = "outturn",
  Normal = "normal",
  High = "high",
  Low = "low",
}

const outturnDataset: ChartDatasetModel = {
  colour: chartColours.temperatureData,
  dataKey: TemperatureSeriesIds.Outturn,
  displayName: "Outturn",
  unitSuffix: "°C",
};

const normalDataset: ChartDatasetModel = {
  colour: chartColours.temperatureReferenceNormal,
  dataKey: TemperatureSeriesIds.Normal,
  displayName: "GB normal reference",
  unitSuffix: "°C",
  dashed: true,
};

const referenceAreaDatasets: ChartDatasetModel[] = [
  {
    colour: null,
    dataKey: TemperatureSeriesIds.High,
    displayName: "High reference",
    unitSuffix: "°C",
  },
  {
    colour: null,
    dataKey: TemperatureSeriesIds.Low,
    displayName: "Low reference",
    unitSuffix: "°C",
  },
];

export const getDatasets = (
  showReferenceData: boolean
): DatasetCategory<ChartDatasetModel>[] => [
  {
    datasets: showReferenceData
      ? [outturnDataset, normalDataset]
      : [outturnDataset],
    id: "datasets",
  },
  {
    datasets: showReferenceData ? referenceAreaDatasets : [],
    id: "datasets",
  },
];
