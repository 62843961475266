import styled from "styled-components";
import colours from "styles/colours";

export const ShowMessageLink = styled.span`
  color: ${colours.linkColor};
  text-underline: ${colours.linkColor};
  text-decoration: underline;
  cursor: pointer;
  display: inline;
`;
