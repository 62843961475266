import { fullWidthStyles } from "components/components/FullWidthSection/style";
import styled from "styled-components";
import colours from "styles/colours";

export const StyledFullWidthChartSection = styled.section`
  ${fullWidthStyles};
`;

export const Spacer = styled.hr`
  border-top: 3px solid ${colours.lightGrey};
  width: 100%;

  &.full-width {
    ${fullWidthStyles};
  }
`;
