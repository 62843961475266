/** For multi-select filters that have two options,
 * filtering by both options is the same as filtering by neither
 * therefore if there is one option we return it, otherwise
 * we return undefined so the query parameter is not sent
 */
export const getOneOfTwoFilterOptionsForQueryParam = (
  array: string[]
): string | undefined => {
  if (array.length === 0 || array.length === 2) {
    return undefined;
  } else {
    return array[0];
  }
};
