import { getWindGenerationOutturnDownloadData } from "api/dataVisualisation/generationByFuelType/generationByFuelType";
import {
  getWindGenerationInitialForecastData,
  getWindGenerationLatestForecastData,
} from "api/dataVisualisation/windGenerationForecast/windGenerationForecast";
import { ReadOnlyDates } from "components/components/DateSelector/DateSelector";
import { datasets } from "components/dataVisualization/WindGeneration/WindGenerationChart/WindGenDatasets";
import WindGenerationChart from "components/dataVisualization/WindGeneration/WindGenerationChart/WindGenerationChart";
import useWindGenerationChartContainerData from "components/dataVisualization/WindGeneration/WindGenerationChartContainer/useWindGenerationChartContainerData";
import {
  ChartBody,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import ChartFilterDrawer from "components/dataVisualization/dataPageComponents/ChartFilterDrawer/ChartFilterDrawer";
import { useDataExport } from "contexts/DataExportContext";
import { useDatasetCategoriesWithVisibilities } from "hooks/useDatasetsCategoriesWithVisibilities";
import useToggle from "hooks/useToggle";
import { combineDatasetCategories } from "models/chartConfiguration/chartDatasetModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import DateOnlyFilterModel from "models/filterModels/dateOnlyFilterModel";
import React, { useEffect, useMemo, useState } from "react";
import { transformWindGenerationData } from "utils/ChartDataUtils/wind/wind";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  addDaysToDate,
  endOfDay,
  restrictDateFilterToMaxDaysRange,
  startOfDay,
} from "utils/dateHelpers";

const MAX_DAYS_FOR_WIND_DOWNLOAD = 62;

const WindGenerationChartContainer: React.FC = () => {
  const dateRange = useMemo(() => {
    const today = startOfDay();
    const model = new DateOnlyFilterModel(
      addDaysToDate(today, -1),
      addDaysToDate(today, 2)
    );
    return {
      inclusive: model.toDateFilterModelInclusive(),
      exclusive: model.toDateFilterModelExclusive(),
    };
  }, []);

  const [showChartFilterDrawer, toggleChartFilterDrawer] = useToggle(false);

  const {
    allDatasetCategories,
    datasetCategoriesToDisplay,
    setMatchingDatasetsVisibility,
  } = useDatasetCategoriesWithVisibilities(datasets);

  const { dispatch: dataExportCountDispatch } = useDataExport();
  const [downloadDateFilter, setDownloadDateFilter] = useState<DateFilterModel>(
    dateRange.inclusive
  );

  const {
    data: rawData,
    fetchData,
    status,
  } = useWindGenerationChartContainerData(dateRange.inclusive);

  useEffect(fetchData, [fetchData]);

  const windGenerationChartData = useMemo(
    () =>
      rawData
        ? transformWindGenerationData(
            rawData,
            combineDatasetCategories(datasetCategoriesToDisplay)
          )
        : [],
    [rawData, datasetCategoriesToDisplay]
  );

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    const startDateString = startOfDay(
      downloadDateFilter.startDate
    ).toISOString();

    const endDateString = endOfDay(downloadDateFilter.endDate).toISOString();

    downloadDataToFile(
      () =>
        getWindGenerationInitialForecastData(downloadDateFilter, fileFormat),
      `WindGeneration-InitialForecast-${startDateString}-${endDateString}`,
      fileFormat,
      dataExportCountDispatch
    );

    downloadDataToFile(
      () => getWindGenerationLatestForecastData(downloadDateFilter, fileFormat),
      `WindGeneration-LatestForecast-${startDateString}-${endDateString}`,
      fileFormat,
      dataExportCountDispatch
    );

    downloadDataToFile(
      () =>
        getWindGenerationOutturnDownloadData(downloadDateFilter, fileFormat),
      `WindGeneration-Outturn-${startDateString}-${endDateString}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const handleChangeToDownloadDateSelection = (
    newDateFilter: DateFilterModel
  ): void => {
    setDownloadDateFilter(
      restrictDateFilterToMaxDaysRange(
        newDateFilter,
        MAX_DAYS_FOR_WIND_DOWNLOAD
      )
    );
  };

  return (
    <>
      <ChartHeader>
        <ChartFilter
          dateFilter={dateRange.inclusive}
          handleChangeToDateSelection={(): void => {
            /* Do nothing since chart has fixed time period */
          }}
          dateSelectorTabs={[]}
          activeDateSelectorTab={null}
          showTimeInput={false}
          readOnlyDates={ReadOnlyDates.Both}
          toggleChartFilterDrawer={toggleChartFilterDrawer}
          showChartFilterDrawer={showChartFilterDrawer}
        />
      </ChartHeader>
      <ChartBody>
        {showChartFilterDrawer && (
          <ChartFilterDrawer
            setMatchingDatasetsVisibility={setMatchingDatasetsVisibility}
            toggleChartFilterDrawer={toggleChartFilterDrawer}
            datasetCategories={allDatasetCategories}
          />
        )}
        <div style={{ width: showChartFilterDrawer ? "80vw" : "100vw" }}>
          <WindGenerationChart
            series={windGenerationChartData}
            datasetCategories={datasetCategoriesToDisplay}
            dataFetchStatus={status}
            rerequestData={fetchData}
            dateRange={dateRange.exclusive}
          />
        </div>
      </ChartBody>
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFile}
          dateFilter={downloadDateFilter}
          handleChangeToDateSelection={handleChangeToDownloadDateSelection}
          dateSelectorTabs={[]}
          showTimeInput={false}
          maxRange={MAX_DAYS_FOR_WIND_DOWNLOAD}
        />
      </ChartFooter>
    </>
  );
};

export default WindGenerationChartContainer;
