import BreadcrumbChevron from "components/components/BreadcrumbChevron/BreadcrumbChevron";
import { Link } from "react-router-dom";
import routeConstants from "utils/routeConstants";

import { StyledBreadcrumb, StyledSection } from "./style";

interface BreadcrumbStep {
  title: string;
  route: string;
}

interface Props {
  title: string;
  breadcrumbStep?: BreadcrumbStep;
  isFullWidth?: boolean;
}

export const HeaderNav: React.FC<Props> = ({
  title,
  breadcrumbStep,
  isFullWidth = false,
}) => (
  // Bulma containers have variable margins by default. Bulma fluid containers on the other hand have fixed left/right margins of 32px.
  <div className={`container pt-3 ${isFullWidth && "is-fluid"}`}>
    <StyledSection className={`section ${isFullWidth && "is-fluid"}`}>
      <StyledBreadcrumb className="breadcrumb" aria-label="breadcrumbs">
        <ol className="m-0">
          <li>
            <Link to={routeConstants.home}>Home</Link>
          </li>
          <BreadcrumbChevron />
          {breadcrumbStep && (
            <>
              <li>
                <Link to={breadcrumbStep.route}>{breadcrumbStep.title}</Link>
              </li>
              <BreadcrumbChevron />
            </>
          )}
          <li className="mt-0 has-text-weight-semibold is-active">
            <span
              className="px-3"
              aria-current="page"
              data-test-id="pageheader-breadcrumb-content"
            >
              {title}
            </span>
          </li>
        </ol>
      </StyledBreadcrumb>
    </StyledSection>
  </div>
);

export default HeaderNav;
