import NavMenu from "components/layout/NavMenu/NavMenu";
import TopMenu from "components/layout/TopMenu/TopMenu";
import React from "react";

const Header: React.FC = () => (
  <header>
    <TopMenu />
    <NavMenu />
  </header>
);

export default Header;
