import { useEffect } from "react";

/*
  Detects escape events and calls the supplied function.

  Note the function supplied should be as stable as possible since every time it
  changes the event listeners will be added/removed. This usually means that you
  should use useCallback.
*/
const useOnEscape = (onEscape: () => void): void => {
  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent): void => {
      if (e.key === "Escape") {
        onEscape();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return (): void => document.removeEventListener("keydown", handleEscapeKey);
  }, [onEscape]);
};

export default useOnEscape;
