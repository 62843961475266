import {
  SettlementMessagesModel,
  SettlementMessagesTableModel,
} from "models/settlementMessages/settlementMessagesModel";

export const mapToSettlementMessagesRow = (
  data: SettlementMessagesModel[]
): SettlementMessagesTableModel[] =>
  data.map((row, key) => ({
    id: key,
    settlementDate: new Date(row.settlementDate),
    settlementPeriod: row.settlementPeriod,
    messageReceivedDateTime: new Date(row.messageReceivedDateTime),
    messageSeverity: row.messageSeverity,
    messageType: row.messageType,
    messageText: row.messageText,
  }));

export enum MessageTypeText {
  AllMessages = "All messages",
  FPN = "FPN",
  BOD = "BOD",
  VOLL = "VOLL",
  ArbitrageFlag = "Arbitrage flag",
  PAR = "PAR",
  MID = "MID",
  LOLPDM = "LOLPDM",
  CADL = "CADL",
  DMAT = "DMAT",
  RPAR = "RPAR",
  DISBSAD = "DISBSAD",
}
