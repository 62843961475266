import Expander from "components/components/Expander/Expander";
import HeaderNav from "components/components/HeaderNav/HeaderNav";
import React from "react";

import { PageDescription } from "./style";

interface Props {
  title: string;
  pageDescription: JSX.Element;
  expanderHeading?: string;
  expanderContent?: JSX.Element;
  children?: JSX.Element;
}

const PageHeader: React.FC<Props> = ({
  title,
  pageDescription,
  expanderHeading,
  expanderContent,
  children,
}: Props) => (
  <>
    <HeaderNav title={title} />
    <section className="section pt-5">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds">
            <h1 className="title" data-test-id="pageheader-title-content">
              {title}
            </h1>
            <PageDescription data-test-id="pageheader-page-description-content">
              {pageDescription}
            </PageDescription>
            {expanderHeading && expanderContent && (
              <Expander title={expanderHeading} content={expanderContent} />
            )}
          </div>
          {children && <div className="column is-one-third">{children}</div>}
        </div>
      </div>
    </section>
  </>
);

export default PageHeader;
