/**
 * Transforms the input string to lowercase and replaces spaces with hyphens
 * @param text input string
 */
export const toKebabCase = (text: string): string =>
  text.toLowerCase().split(" ").join("-");

/**
 * Copies the selected string to clipboard
 * @param text input string
 * @param onSuccess callback invoked if the copy succeeds
 * @param onError callback invoked if the copy fails
 */
export const copyText = (
  text: string,
  onSuccess: () => void,
  onError: () => void
): void => {
  navigator.clipboard.writeText(text).then(onSuccess, onError);
};
