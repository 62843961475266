import { getActualGenerationPerTypeDownloadData } from "api/dataVisualisation/actualGenerationPerType/actualGenerationPerType";
import useActualGenerationPerTypeChartData from "components/dataVisualization/actualGenerationPerType/ActualGenerationPerTypeChartContainer/useActualGenerationPerTypeChartData";
import {
  initialDatasetCategories,
  transformActualGenerationPerTypeData,
} from "components/dataVisualization/actualGenerationPerType/ActualGenerationPerTypeChartContainer/utils";
import StackedLineChart from "components/dataVisualization/chartComponents/StackedLineChart/StackedLineChart";
import {
  ChartBody,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import ChartFilterDrawer from "components/dataVisualization/dataPageComponents/ChartFilterDrawer/ChartFilterDrawer";
import { useDataExport } from "contexts/DataExportContext";
import { useDatasetCategoriesWithVisibilities } from "hooks/useDatasetsCategoriesWithVisibilities";
import useToggle from "hooks/useToggle";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  MAX_DAYS_IN_ONE_YEAR,
  addDaysToDate,
  ceilToHalfHour,
  restrictDateFilterToMaxOneYear,
} from "utils/dateHelpers";

const ActualGenerationPerTypeChartContainer: React.FC = () => {
  const { dispatch: dataExportCountDispatch } = useDataExport();
  const defaultEndDate = ceilToHalfHour(new Date());
  const dateSelectorTabs = [
    DateSelectorTabs.TwentyFourHours,
    DateSelectorTabs.OneWeek,
    DateSelectorTabs.OneMonth,
    DateSelectorTabs.OneYear,
  ];
  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addDaysToDate(defaultEndDate, -1), defaultEndDate)
  );
  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.TwentyFourHours
  );
  const [showChartFilterDrawer, toggleChartFilterDrawer] = useToggle(false);
  const [highlightedDataset, setHighlightedDataset] = useState("");
  const {
    allDatasetCategories,
    datasetCategoriesToDisplay,
    setMatchingDatasetsVisibility,
  } = useDatasetCategoriesWithVisibilities(initialDatasetCategories);

  const {
    data: rawData,
    fetchData,
    status,
  } = useActualGenerationPerTypeChartData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const chartData = useMemo(
    () =>
      rawData
        ? transformActualGenerationPerTypeData(
            rawData,
            datasetCategoriesToDisplay.flatMap((x) => x.datasets)
          )
        : [],
    [rawData, datasetCategoriesToDisplay]
  );

  const handleChangeToDateSelection = useCallback(
    (newDateFilter: DateFilterModel, newActiveTab: DateSelectorTabs): void => {
      setDateFilter(restrictDateFilterToMaxOneYear(newDateFilter));
      setActiveDateSelectorTab(newActiveTab);
    },
    [setDateFilter, setActiveDateSelectorTab]
  );

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getActualGenerationPerTypeDownloadData(dateFilter, fileFormat),
      `ActualAggregatedGenerationByType-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  return (
    <>
      <ChartHeader>
        <ChartFilter
          showChartFilterDrawer={showChartFilterDrawer}
          toggleChartFilterDrawer={toggleChartFilterDrawer}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={MAX_DAYS_IN_ONE_YEAR}
        />
      </ChartHeader>
      <ChartBody>
        {showChartFilterDrawer && (
          <ChartFilterDrawer
            highlightedDataset={highlightedDataset}
            sendHighlightedDataset={setHighlightedDataset}
            toggleChartFilterDrawer={toggleChartFilterDrawer}
            datasetCategories={allDatasetCategories}
            setMatchingDatasetsVisibility={setMatchingDatasetsVisibility}
          />
        )}
        <div style={{ width: showChartFilterDrawer ? "80vw" : "100vw" }}>
          <StackedLineChart
            series={chartData}
            datasetCategories={datasetCategoriesToDisplay}
            sendHighlightedDataset={setHighlightedDataset}
            dataFetchStatus={status}
            rerequestData={fetchData}
          />
        </div>
      </ChartBody>
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFile}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          activeDateSelectorTab={activeDateSelectorTab}
          dateSelectorTabs={dateSelectorTabs}
          maxRange={MAX_DAYS_IN_ONE_YEAR}
          infoText="Data on the graph may be aggregated. This export will contain the raw dataset, filtered by publish time."
        />
      </ChartFooter>
    </>
  );
};

export default ActualGenerationPerTypeChartContainer;
