import { getData, getStitchedBmusData } from "api/dataVisualisation/client";
import {
  PhysicalDataModel,
  PhysicalDataset,
} from "models/bmuData/physicalDataModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { getSettlementDateAndPeriodFromSettlementTime } from "utils/DateUtils";
import { toDateOnlyString } from "utils/dateHelpers";

export const getPerBmuPhysicalData = async (
  dateFilter: DateFilterModel,
  bmUnit: string,
  datasets: PhysicalDataset[],
  format?: DataDownloadFormat
): Promise<DataDownloadModel<PhysicalDataModel>> => {
  const { data } = await getData(
    "/balancing/physical",
    {
      from: dateFilter.normalisedStartDate.toISOString(),
      to: dateFilter.normalisedEndDate.toISOString(),
      bmUnit,
      dataset: datasets,
    },
    format
  );
  return data;
};

export const getMarketWidePhysicalData = async (
  dataset: PhysicalDataset,
  date: Date,
  bmUnits?: string[],
  format?: DataDownloadFormat
): Promise<DataDownloadModel<PhysicalDataModel>> => {
  const { settlementDate, settlementPeriod } =
    getSettlementDateAndPeriodFromSettlementTime(date);
  return getStitchedBmusData(
    "/balancing/physical/all",
    {
      dataset: dataset,
      settlementDate: toDateOnlyString(settlementDate),
      settlementPeriod: settlementPeriod,
    },
    bmUnits,
    format
  );
};

export const getPerBmuPhysicalJsonData = (
  dateFilter: DateFilterModel,
  bmUnits: string,
  datasets: PhysicalDataset[]
): Promise<JsonDataDownloadModel<PhysicalDataModel>> =>
  getPerBmuPhysicalData(dateFilter, bmUnits, datasets) as Promise<
    JsonDataDownloadModel<PhysicalDataModel>
  >;

export const getMarketWidePhysicalJsonData = (
  dataset: PhysicalDataset,
  settlementDate: Date,
  bmUnits?: string[]
): Promise<JsonDataDownloadModel<PhysicalDataModel>> =>
  getMarketWidePhysicalData(dataset, settlementDate, bmUnits) as Promise<
    JsonDataDownloadModel<PhysicalDataModel>
  >;
