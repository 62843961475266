import TileChild from "components/components/TileChild/TileChild";
import TileParent from "components/components/TileParent/TileParent";
import TileRowContainer from "components/components/TileRowContainer/TileRowContainer";
import React from "react";

interface Props {
  children: JSX.Element;
}

const SummaryTile: React.FC<Props> = ({ children }: Props) => (
  <TileRowContainer>
    <TileParent>
      <TileChild isMiniTile>{children}</TileChild>
    </TileParent>
  </TileRowContainer>
);

export default SummaryTile;
