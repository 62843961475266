export default class MaybePlural {
  constructor(private singular: string, private plural?: string) {
    if (plural === undefined) {
      this.plural = singular;
    }
  }

  value(count: number): string {
    if (count === 1) {
      return this.singular;
    }

    return this.plural!;
  }
}
