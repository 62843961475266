import styled from "styled-components";
import colours from "styles/colours";

export const StyledToast = styled.div`
  background-color: ${colours.white};
  border: 1px solid ${colours.elexonYellow};
  box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px;
  min-height: 110px;
  min-width: 360px;
  opacity: 1;
  transform: translateY(0);
  animation: fadeIn 0.5s linear;
  will-change: opacity, transform;

  &.dismissed {
    opacity: 0;
    transform: translateY(100px);
    animation: fadeOut 0.5s linear;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(100px);
    }
  }
`;

export const IconContainer = styled.div`
  height: 100%;
  width: 50px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ToastContent = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  margin: 10px;

  .title {
    font-size: 1.2em;
    margin-bottom: 0;
  }

  .toast-message {
    text-decoration: underline;
  }

  > * + * {
    margin-top: 0.5em;
  }
`;

export const ExitButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;

  .icon {
    margin: 0 !important;
  }
`;
