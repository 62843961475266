import React from "react";

import { StyledNavButton } from "./style";

interface Props {
  children: React.ReactNode;
  className?: string;
  ariaLabel?: string;
  onClick?: () => void;
}

const NavButtonLink: React.FC<Props> = ({
  children,
  className,
  ariaLabel,
  onClick,
}: Props) => (
  <StyledNavButton
    onClick={onClick}
    className={className}
    aria-label={ariaLabel}
    type="button"
  >
    {children}
  </StyledNavButton>
);

export default NavButtonLink;
