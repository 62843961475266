import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import {
  buildCsvBlobForDownload,
  buildJsonBlobForDownload,
  buildXmlBlobForDownload,
} from "utils/DataDownloads/ExportDataToFile";

export const exportToFile = (
  filename: string,
  format: string,
  data: DataDownloadModel<unknown> | string
): void => {
  switch (format) {
    case DataDownloadFormat.Csv:
      buildCsvBlobForDownload(data as string, filename);
      break;
    case DataDownloadFormat.Json: {
      const model = data as JsonDataDownloadModel<unknown>;
      buildJsonBlobForDownload(model.data, filename);
      break;
    }
    case DataDownloadFormat.Xml:
      buildXmlBlobForDownload(data as string, filename);
      break;
    default:
      break;
  }
};
