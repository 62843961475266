import PageMeta from "components/components/Meta/PageMeta";
import TemperatureChartContainer from "components/dataVisualization/Temperature/TemperatureChartContainer/TemperatureChartContainer";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import React from "react";

const Temperature: React.FC = () => (
  <>
    <PageMeta title="Temperature" />
    <PageHeader
      title="Temperature"
      pageDescription={
        <>
          <p>
            This report provides daily average GB temperature data (in Celsius)
            as well as reference temperatures (low, normal and high). This
            average data is calculated by National Energy System Operator from
            the data retrieved from 6 weather stations around Britain. NESO use
            this data as part of the electricity demand forecasting process.
          </p>
          <p>
            This graph can show up to a maximum of 1 year of data at a time.
            Current day data will be available by 5pm. If querying before this
            time, today&apos;s data may not be present.
          </p>
        </>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>The reference values are as derived as follows:</p>
          <p>
            <b>Normal:</b> Normal Reference Temperature is the daily average UK
            temperature which was exceeded on 50% of days during a 30 year
            historic period.
            <br />
            <b>Low:</b> As above with an 87.5% chance of the temperature value
            being exceeded.
            <br />
            <b>High:</b> The High Reference Temperature is similar to Normal
            Reference with a 12.5% chance of being exceeded.
          </p>
          <p>
            This means that the region between the low reference and the high
            reference values, shown in green on the graph below, corresponds to
            a 75% chance the temperature will fall in this zone.
          </p>
        </>
      }
    />
    <FullWidthChartSection>
      <TemperatureChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection
      documentationInformation={{
        chart: ["temperature"],
      }}
    />
  </>
);

export default Temperature;
