import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import NavButtonWithMenu from "components/components/NavButton/NavButtonWithMenu";
import { useErrorContext } from "contexts/ErrorContext";
import React from "react";
import { loginRequest } from "utils/authUtils";
import routeConstants from "utils/routeConstants";

import { AccountMenuContainer, StyledNavButton } from "./style";

export const AccountMenu: React.FC = () => {
  const { instance } = useMsal();

  const { registerError } = useErrorContext();

  const handleLogin = async (): Promise<void> => {
    try {
      await instance.loginRedirect({
        ...loginRequest,
        redirectUri: routeConstants.home,
      });
    } catch (e) {
      registerError();
    }
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: routeConstants.home,
      });
    } catch (e) {
      registerError();
    }
  };

  return (
    <AccountMenuContainer aria-label="account">
      <UnauthenticatedTemplate>
        <StyledNavButton onClick={handleLogin}>Log in</StyledNavButton>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <NavButtonWithMenu
          ariaLabel="account menu"
          menuItems={[
            {
              text: "Account",
              path: routeConstants.profile,
            },
            {
              text: "Sign out",
              onClick: handleLogout,
            },
          ]}
        >
          <Icon iconName={faUser} />
        </NavButtonWithMenu>
      </AuthenticatedTemplate>
    </AccountMenuContainer>
  );
};
