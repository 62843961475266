import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const MultiChartTitle = styled.h2`
  &&& {
    font-size: ${fonts.mediumLarge};
    margin: 0 0 0 120px;
    z-index: 2;
    background-color: ${colours.whiteTranslucent};
    display: inline-block;
    position: relative;
  }
`;
