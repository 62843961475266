import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import { InitialDemandOutturnModel } from "models/demandOutturn/InitialDemandOutturnModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { toDateOnlyString } from "utils/dateHelpers";

export const getInitialDemandOutturnData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<InitialDemandOutturnModel>> => {
  const { data } = await getData(
    "/demand/outturn",
    {
      settlementDateFrom: toDateOnlyString(dateFilter.normalisedStartDate),
      settlementDateTo: toDateOnlyString(dateFilter.normalisedEndDate),
    },
    format
  );
  return data;
};

export const getInitialDemandOutturnJsonData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<InitialDemandOutturnModel>> =>
  getInitialDemandOutturnData(dateFilter, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<InitialDemandOutturnModel>
  >;
