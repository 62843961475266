import { Datum, Serie } from "@nivo/line";
import { MarginForecastDaily } from "models/surplusAndMargin/marginDailyModel";
import { MarginForecastWeekly } from "models/surplusAndMargin/marginWeeklyModel";
import { SurplusForecastDaily } from "models/surplusAndMargin/surplusDailyModel";
import { SurplusForecastWeekly } from "models/surplusAndMargin/surplusWeeklyModel";
import { chartColours } from "styles/colours";

const mapSurplusForecastDailyToDatum = (
  rawData: SurplusForecastDaily
): Datum => ({
  x: new Date(rawData.forecastDate),
  y: rawData.surplus,
});

const mapSurplusForecastWeeklyToDatum = (
  rawData: SurplusForecastWeekly
): Datum => ({
  x: new Date(rawData.weekStartDate),
  y: rawData.surplus,
});

export const transformSurplusForecastModelData = (
  rawData: SurplusForecastDaily[] | SurplusForecastWeekly[]
): Serie[] => {
  if (!rawData.length) {
    return [];
  }

  const data = (rawData[0] as SurplusForecastDaily).forecastDate
    ? (rawData as SurplusForecastDaily[]).map(mapSurplusForecastDailyToDatum)
    : (rawData as SurplusForecastWeekly[]).map(mapSurplusForecastWeeklyToDatum);

  return [
    {
      id: "surplus",
      colour: chartColours.surplus,
      data,
    },
  ];
};

const mapMarginForecastDailyToDatum = (
  rawData: MarginForecastDaily
): Datum => ({
  x: new Date(rawData.forecastDate),
  y: rawData.margin,
});

const mapMarginForecastWeeklyToDatum = (
  rawData: MarginForecastWeekly
): Datum => ({
  x: new Date(rawData.weekStartDate),
  y: rawData.margin,
});

export const transformMarginForecastModelData = (
  rawData: MarginForecastDaily[] | MarginForecastWeekly[]
): Serie[] => {
  if (!rawData.length) {
    return [];
  }

  const data = (rawData[0] as MarginForecastDaily).forecastDate
    ? (rawData as MarginForecastDaily[]).map(mapMarginForecastDailyToDatum)
    : (rawData as MarginForecastWeekly[]).map(mapMarginForecastWeeklyToDatum);

  return [
    {
      id: "margin",
      colour: chartColours.margin,
      data,
    },
  ];
};
