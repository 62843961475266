import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "components/components/Button/Button";
import Icon, { SvgIconName } from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import { useErrorContext } from "contexts/ErrorContext";
import React, { useState } from "react";
import colours from "styles/colours";
import { copyText } from "utils/stringHelpers";

import { Container } from "./style";

interface Props {
  value?: string;
}

const CopyToClipboard: React.FC<Props> = ({ value }) => {
  const { registerError } = useErrorContext();
  const [copied, setCopied] = useState<boolean>(false);

  const registerSuccess = (): void => {
    setCopied(true);
  };

  const onClick = (): void => {
    if (value) {
      copyText(value, registerSuccess, registerError);
    }
  };

  if (copied) {
    setTimeout(() => {
      setCopied(false);
    }, 1500);

    return (
      <Container>
        <Icon iconName={faCheck} />
        <span>Copied</span>
      </Container>
    );
  }

  return (
    <Container>
      <Button
        ariaLabel="copy"
        className="icon plain-inline no-underline"
        iconName={SvgIconName.Copy}
        iconColour={colours.focusBlue}
        iconSize={IconSize.xSmall}
        buttonText="Copy"
        onClick={onClick}
      />
    </Container>
  );
};

export default CopyToClipboard;
