import PageMeta from "components/components/Meta/PageMeta";
import RollingSystemFrequencyChartContainer from "components/dataVisualization/RollingSystemFrequency/RollingSystemFrequencyChartContainer/RollingSystemFrequencyChartContainer";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import React from "react";

const RollingSystemFrequency: React.FC = () => (
  <>
    <PageMeta title="Rolling system frequency" />
    <PageHeader
      title="Rolling system frequency"
      pageDescription={
        <>
          <p>
            National Energy System Operator continuously monitors the system
            frequency in real time to ensure the system demand and generation
            are in balance; in the GB electricity system this equates to 50
            Hertz (Hz). Where generation exceeds demand, the frequency rises
            above 50Hz and the inverse applies.
          </p>
          <p>
            Due the granularity of the data, this graph is restricted to a 1
            hour view only. Please use the export button or the API to access
            this data in periods of up to 1 week.
          </p>
        </>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>
            NESO has an obligation to keep the system frequency at 50 Hz, with a
            statutory limit of 0.5 Hz above or below this value; going 1% above
            or below 50Hz risks damaging equipment and infrastructure. NESO must
            therefore ensure sufficient generation and demand are held in
            automatic readiness to manage all credible circumstances that might
            result in frequency variations.
          </p>
          <p>
            The data is updated every 2 minutes with the frequency data shown at
            15 second granularity.
          </p>
          <p>
            A snapshot of the system frequency data in real time is available on
            the&nbsp;
            <a
              href="https://extranet.nationalgrid.com/RealTime"
              target="_blank"
              rel="noreferrer"
            >
              NESO website
            </a>
            .
          </p>
        </>
      }
    />
    <FullWidthChartSection>
      <RollingSystemFrequencyChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection
      documentationInformation={{
        chart: ["system/frequency"],
      }}
    />
  </>
);

export default RollingSystemFrequency;
