import { getSystemPricesJsonData } from "api/dataVisualisation/systemSellAndBuyPrices/systemSellAndBuyPrices";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { SystemPriceModel } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import { useCallback } from "react";

const useSystemSellBuyPricesChartContainerData = (
  dateFilter: DateFilterModel
): {
  fetchData: () => void;
  data: SystemPriceModel[] | null;
  status: RequestStatus;
} => {
  const {
    data: jsonData,
    status,
    request,
  } = useRequest(getSystemPricesJsonData);

  const data = jsonData ? jsonData.data || [] : null;

  const fetchData = useCallback(() => {
    request(dateFilter);
  }, [dateFilter, request]);

  return {
    data,
    status,
    fetchData,
  };
};

export default useSystemSellBuyPricesChartContainerData;
