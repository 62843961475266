import { faAngleDown, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import FormErrorMessage from "components/components/FormErrorMessage/FormErrorMessage";
import Icon from "components/components/Icon/Icon";
import React, { forwardRef } from "react";
import colours from "styles/colours";

import { StyledSelect } from "./style";

interface Props {
  id: string;
  value: string;
  name: string;
  errorMessage: string;
  label: string;
  showError: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: string[];
  required?: boolean;
}

const SelectInput: React.FC<Props & React.RefAttributes<HTMLSelectElement>> =
  forwardRef<HTMLSelectElement, Props>(function selectInput(
    {
      id,
      value,
      name,
      errorMessage,
      label,
      showError,
      onChange,
      options,
      required,
    }: Props,
    ref
  ) {
    return (
      <div className="block field has-text-weight-bold">
        <label htmlFor={id}>
          <span>{label}</span>
          {required === true && <span aria-hidden>*</span>}
          <div
            className={classnames("control", "is-expanded", "has-icons-right", {
              "has-icons-left": showError,
            })}
          >
            <div className="is-fullwidth">
              <StyledSelect
                data-test-id="select-input"
                className={classnames("input", { invalid: showError })}
                id={id}
                value={value}
                name={name}
                onChange={onChange}
                required={required}
                ref={ref}
              >
                <option disabled hidden value="">
                  {" "}
                </option>
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </StyledSelect>
              <span className="icon is-small is-right">
                <Icon iconName={faAngleDown} colour={colours.darkGrey} />
              </span>
            </div>
            {showError && (
              <FormErrorMessage
                iconName={faInfoCircle}
                iconColour={colours.elexonRed}
                messageText={errorMessage}
              />
            )}
          </div>
        </label>
      </div>
    );
  });

export default SelectInput;
