import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { sendFeedbackForm } from "api/feedback/client";
import Button from "components/components/Button/Button";
import { feedbackOptions } from "components/components/Forms/SelectInput/Options/SelectOptions";
import SelectInput from "components/components/Forms/SelectInput/SelectInput";
import TextAreaInput from "components/components/Forms/TextAreaInput/TextAreaInput";
import TextInput from "components/components/Forms/TextInput/TextInput";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import {
  ModalButtonContainer,
  ModalHeader,
  ModalPadding,
  ModalSection,
} from "components/components/Modal/commonStyle";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { CustomerFeedbackFormModel } from "models/forms/customerFeedbackForm";
import React, { createRef, FormEvent, useState } from "react";
import colours from "styles/colours";

import { ResultContainer } from "./style";

interface ResultsModalContent {
  icon?: JSX.Element;
  headerText: string;
  subtitleText: string;
  buttonAriaLabel?: string;
  buttonText: string;
  buttonOnClick: () => void;
}

interface Props {
  modalActive: boolean;
  closeModal: () => void;
}

const CustomerFeedback: React.FC<Props> = ({ modalActive, closeModal }) => {
  const [formData, setFormData] = useState<CustomerFeedbackFormModel>({
    feedbackName: "",
    feedbackEmail: "",
    feedbackType: "",
    feedbackContent: "",
  });

  const [showEmailError, setShowEmailError] = useState(false);
  const [showTypeError, setShowTypeError] = useState(false);
  const [showCommentsError, setShowCommentsError] = useState(false);
  const emailInput = createRef<HTMLInputElement>();
  const typeInput = createRef<HTMLSelectElement>();
  const commentsInput = createRef<HTMLTextAreaElement>();

  const [requestSubmitted, setRequestSubmitted] = useState(false);

  const { request: submitFormRequest, status: submitFormStatus } =
    useRequest(sendFeedbackForm);

  const submissionInProgress = submitFormStatus === RequestStatus.IN_PROGRESS;

  const submitForm = (): void => {
    submitFormRequest(formData).then(() => setRequestSubmitted(true));
  };

  const resetToDefaultFieldValues = (): void => {
    setFormData({
      feedbackName: "",
      feedbackEmail: "",
      feedbackType: "",
      feedbackContent: "",
    });
    setRequestSubmitted(false);
    setShowEmailError(false);
    setShowTypeError(false);
    setShowCommentsError(false);
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    let hasErrors = false;

    // Invalid form errors being displayed after a brief delay makes them take priority over the focus for screen readers.
    // Without this delay, the alert sometimes gets skipped by the screen reader, with the focus overriding it.

    if (!emailInput.current?.validity.valid) {
      setTimeout(() => setShowEmailError(true), 50);
      emailInput.current?.focus();
      hasErrors = true;
    }

    if (!typeInput.current?.validity.valid) {
      setTimeout(() => setShowTypeError(true), 50);

      if (!hasErrors) {
        typeInput.current?.focus();
      }

      hasErrors = true;
    }

    if (!commentsInput.current?.validity.valid) {
      setTimeout(() => setShowCommentsError(true), 50);

      if (!hasErrors) {
        commentsInput.current?.focus();
      }

      hasErrors = true;
    }

    if (!hasErrors) {
      submitForm();
    }
  };

  const handleClose = (): void => {
    resetToDefaultFieldValues();
    closeModal();
  };

  const successModalContent: ResultsModalContent = {
    icon: (
      <div>
        <Icon
          iconName={faCheckCircle}
          colour={colours.elexonPurple}
          size={IconSize.large}
        />
      </div>
    ),
    headerText: "Thank you",
    subtitleText: "Your feedback has been submitted successfully.",
    buttonAriaLabel: "OK",
    buttonText: "ok",
    buttonOnClick: handleClose,
  };

  const errorModalContent: ResultsModalContent = {
    headerText: "Sorry, something went wrong",
    subtitleText: "Return to the feedback form to try again.",
    buttonText: "back",
    buttonOnClick: () => setRequestSubmitted(false),
  };

  const resultsModalContent =
    submitFormStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED
      ? successModalContent
      : errorModalContent;

  return (
    <Modal
      size={ModalSize.large}
      isActive={modalActive}
      onClose={handleClose}
      descriptionId="feedback-modal-description"
      headingId="feedback-modal-header"
    >
      <ModalPadding data-test-id="feedback-modal">
        {requestSubmitted ? (
          <ResultContainer>
            {resultsModalContent.icon}
            <ModalHeader>{resultsModalContent.headerText}</ModalHeader>
            <div>
              <p className="subtitle" data-test-id="submit-message">
                {resultsModalContent.subtitleText}
              </p>
            </div>
            <ModalButtonContainer className="mt-4">
              <Button
                ariaLabel={resultsModalContent.buttonAriaLabel}
                buttonText={resultsModalContent.buttonText}
                onClick={resultsModalContent.buttonOnClick}
              />
            </ModalButtonContainer>
          </ResultContainer>
        ) : (
          <form onSubmit={handleSubmit} noValidate>
            <ModalHeader id="feedback-modal-header">
              Support and Feedback
            </ModalHeader>
            <p className="subtitle mt-4" id="feedback-modal-description">
              Please let us know of any issues, support needs or feedback about
              our service. It only takes a couple of minutes and will help us
              improve or include features you will find useful.
            </p>
            <TextInput
              id="customer-feedback-name"
              value={formData.feedbackName}
              name="FeedbackNameInput"
              label="Name"
              maxLength={200}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                setFormData({ ...formData, feedbackName: event.target.value })
              }
            />
            <TextInput
              id="customer-feedback-email"
              value={formData.feedbackEmail}
              name="FeedbackEmailInput"
              label="Email address"
              errorMessage="Please enter a valid email address (e.g yourname@example.com)"
              maxLength={254}
              showError={showEmailError}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setShowEmailError(false);
                setFormData({ ...formData, feedbackEmail: event.target.value });
              }}
              required
              type="email"
              ref={emailInput}
            />
            <SelectInput
              id="customer-feedback-type"
              name="FeedbackTypeInput"
              value={formData.feedbackType}
              errorMessage="Please select a feedback type"
              label="What would you like to tell us?"
              showError={showTypeError}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>): void => {
                setShowTypeError(false);
                setFormData({ ...formData, feedbackType: event.target.value });
              }}
              options={feedbackOptions}
              required
              ref={typeInput}
            />
            <TextAreaInput
              id="customer-feedback-comments"
              name="FeedbackCommentsInput"
              value={formData.feedbackContent}
              label="Comments"
              errorMessage="Please leave a comment"
              maxLength={3000}
              showError={showCommentsError}
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement>
              ): void => {
                setShowCommentsError(false);
                setFormData({
                  ...formData,
                  feedbackContent: event.target.value,
                });
              }}
              required
              ref={commentsInput}
            />
            <ModalSection className="centre">
              <ModalButtonContainer className="mt-4">
                <Button
                  buttonText="cancel"
                  className="cancel"
                  onClick={handleClose}
                  disabled={submissionInProgress}
                />
                <Button
                  buttonText={submissionInProgress ? "submitting..." : "submit"}
                  type="submit"
                  disabled={submissionInProgress}
                />
              </ModalButtonContainer>
            </ModalSection>
          </form>
        )}
      </ModalPadding>
    </Modal>
  );
};

export default CustomerFeedback;
