import { Point, ResponsiveLine, Serie } from "@nivo/line";
import { sellBuyConfig } from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesChartConfig";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { RequestStatus } from "hooks/useRequest";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartTheme,
  chartXTimeScale,
  commonChartProps,
  modifyChartMargin,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { getUnlabelledVerticalLineMarkerAtTimeNow } from "utils/ChartDrawUtils";
import {
  formatAsTimeWithDatesMarked,
  formatNumberToFixedWithoutTrailingZeroes,
} from "utils/chartHelpers";

interface Props {
  series: Serie[];
  rerequestData: () => void;
  dataFetchStatus: RequestStatus;
  tooltip: (input: Point) => JSX.Element;
  chartIndent?: number;
}

const SellBuyPricesChart: React.FC<Props> = ({
  series,
  rerequestData,
  dataFetchStatus,
  tooltip,
  chartIndent = 0,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    const sellbuyPricesAxisLeft = chartAxisLeft("£/MWh", isChartLoaded, 4);
    sellbuyPricesAxisLeft.format = formatNumberToFixedWithoutTrailingZeroes;
    sellbuyPricesAxisLeft.legendOffset = -80 - chartIndent;

    return (
      <ResponsiveLine
        {...commonChartProps(isChartLoaded, series)}
        enableSlices="x"
        axisBottom={chartAxisBottom(
          "",
          series,
          isChartLoaded,
          undefined,
          formatAsTimeWithDatesMarked
        )}
        axisLeft={sellbuyPricesAxisLeft}
        gridYValues={4}
        enableGridX={true}
        sliceTooltip={(input): JSX.Element => tooltip(input.slice.points[0])}
        xScale={chartXTimeScale()}
        yScale={nonStackedLineChartYScale(series)}
        theme={{
          ...chartTheme,
          crosshair: {
            line: {
              strokeOpacity: 0,
              strokeDasharray: "999", // marker value for <StackedChartContainer> crosshair logic
            },
          },
        }}
        markers={getUnlabelledVerticalLineMarkerAtTimeNow(
          series,
          isChartLoaded
        )}
        margin={modifyChartMargin({ left: chartIndent })}
      />
    );
  }, [dataFetchStatus, series, tooltip, chartIndent]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={sellBuyConfig.datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
        containerHeight={330}
      >
        {memoisedChart}
      </ChartWithErrorHandling>
    ),
    [series, dataFetchStatus, memoisedChart, rerequestData]
  );
};

export default SellBuyPricesChart;
