import {
  Table,
  TileWrapper,
} from "components/remit/MessageDetails/RemitMessageTiles/style";
import OutageProfile from "models/remit/outageProfile";
import React from "react";
import { formatDateTimeString } from "utils/dateHelpers";

const OutageProfileTile: React.FC<{
  tileTitle: string;
  data: OutageProfile[];
}> = ({ tileTitle, data }) => {
  const columnHeaders = ["Start Time", "End Time", "Capacity"];

  return (
    <TileWrapper>
      <h3>{tileTitle}</h3>
      <Table>
        <tbody>
          <tr>
            {columnHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
          {data.map((entry) => (
            <React.Fragment
              key={entry.startTime.toString() + entry.endTime.toString()}
            >
              <tr>
                <td>{formatDateTimeString(new Date(entry.startTime))}</td>
                <td>{formatDateTimeString(new Date(entry.endTime))}</td>
                <td>{`${entry.capacity} MW`}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <p>
        *In cases where the outage profile contains 5 or more entries, this
        information is displayed as a graph.
      </p>
    </TileWrapper>
  );
};

export default OutageProfileTile;
