import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Icon from "components/components/Icon/Icon";
import React from "react";

import { StyledErrorMessage } from "./style";

interface Props {
  iconName?: IconProp;
  iconColour?: string;
  messageText: string;
}

const FormErrorMessage: React.FC<Props> = ({
  iconName,
  iconColour,
  messageText,
}: Props) => (
  <>
    {iconName && iconColour && (
      <span className="icon is-small is-left">
        <Icon iconName={iconName} colour={iconColour} ariaLabel="Error" />
      </span>
    )}
    <StyledErrorMessage
      className="errorMessageText has-text-danger subtitle icon-text"
      role="alert"
    >
      {messageText}
    </StyledErrorMessage>
  </>
);

export default FormErrorMessage;
