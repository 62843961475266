import { getInitialDemandOutturnJsonData } from "api/dataVisualisation/initialDemand/initialDemand";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { InitialDemandOutturnModel } from "models/demandOutturn/InitialDemandOutturnModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useCallback } from "react";

const useInitialDemandOutturnChartContainerData = (
  dateFilter: DateFilterModel
): {
  fetchData: () => void;
  data: InitialDemandOutturnModel[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchInitialDemandOutturnData,
  } = useRequest(getInitialDemandOutturnJsonData);

  const fetchData = useCallback(() => {
    fetchInitialDemandOutturnData(dateFilter);
  }, [dateFilter, fetchInitialDemandOutturnData]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useInitialDemandOutturnChartContainerData;
