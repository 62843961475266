import styled from "styled-components";

export const Header = styled.td`
  font-size: 1.4em;
  font-weight: bold;
`;

export const ValueCell = styled.td`
  font-size: 1.2em;
  font-weight: bold;
`;

export const ValuesHeader = styled.tr`
  text-align: center;
`;

export const SummaryTable = styled.table`
  table-layout: fixed;

  > thead > tr > td,
  > tbody > tr > td {
    border: 0;
    padding: 5px 10px;
    vertical-align: middle;
  }
  > tbody > tr > ${ValueCell} {
    text-align: center;
  }
`;
