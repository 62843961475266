export enum DateSelectorTabs {
  OneHour = "1hr",
  TwentyFourHours = "24hr",
  ThreeDays = "3d",
  OneWeek = "1w",
  OneMonth = "1m",
  ThreeMonths = "3m",
  OneYear = "1y",
  All = "All",
  Date = "Date",
}
