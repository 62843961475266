import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import { indicativeDemandPeakChartColours } from "styles/colours";
import { PointShape } from "utils/ChartDrawUtils/pointShapes";

export type TriadPeakDataset = "peak1" | "peak2" | "peak3";

export type IndicativeDemandPeakChartDataset =
  | "dailyDemandPeak"
  | TriadPeakDataset;

export const indicativeDemandPeakChartDatasetDisplayName: {
  [key in IndicativeDemandPeakChartDataset]: string;
} = {
  dailyDemandPeak: "Demand",
  peak1: "Demand (Peak 1)",
  peak2: "Demand (Peak 2)",
  peak3: "Demand (Peak 3)",
};

const indicativeDemandPeakChartDatasetOrder: IndicativeDemandPeakChartDataset[] =
  ["dailyDemandPeak", "peak1", "peak2", "peak3"];

export const pointShapeForIndicativeDemandPeakDataset: {
  [key in IndicativeDemandPeakChartDataset]: PointShape;
} = {
  dailyDemandPeak: PointShape.None,
  peak1: PointShape.Circle,
  peak2: PointShape.Square,
  peak3: PointShape.Diamond,
};

export const indicativeDemandPeakDatasetCategories: DatasetCategory<ChartDatasetModel>[] =
  [
    {
      datasets: indicativeDemandPeakChartDatasetOrder.map((dataKey) => ({
        colour: indicativeDemandPeakChartColours[dataKey],
        dataKey,
        displayName: indicativeDemandPeakChartDatasetDisplayName[dataKey],
        unitSuffix: "MW",
        swatchShape: pointShapeForIndicativeDemandPeakDataset[dataKey],
      })),
      id: "datasets",
    },
  ];

export type TriadPeak = {
  id: TriadPeakDataset;
  position: number;
  date: Date;
  time: string;
  settlementPeriod: string;
  settlementRun: string;
  demand: number;
};
