import { ModalSection } from "components/components/Modal/commonStyle";
import DataExporterContainer from "components/dataVisualization/dataExporterComponents/DataExporterContainer/DataExporterContainer";
import DataExporterHeader from "components/dataVisualization/dataExporterComponents/dataExporterSections/DataExporterHeader";
import FileFormatSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/FileFormatSection";
import TimeFrameSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/TimeFrameSection";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useState } from "react";

interface Props {
  downloadData: (fileFormat: DataDownloadFormat, dataset?: string) => void;
  dateFilter?: DateFilterModel;
  handleChangeToDateSelection?: (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ) => void;
  dateSelectorTabs?: DateSelectorTabs[];
  activeDateSelectorTab?: DateSelectorTabs | null;
  maxRange?: number;
  showTimeInput?: boolean;
  defaultEndDate?: Date;
  infoText?: string;
}

const DataExporter: React.FC<Props> = ({
  downloadData,
  dateFilter,
  handleChangeToDateSelection,
  dateSelectorTabs,
  activeDateSelectorTab = null,
  maxRange,
  showTimeInput,
  defaultEndDate,
  infoText,
}: Props) => {
  const [downloadFormat, setDownloadFormat] = useState<DataDownloadFormat>(
    DataDownloadFormat.Csv
  );

  const handleExportClick = (): void => {
    downloadData(downloadFormat);
  };

  return (
    <DataExporterContainer onExportClick={handleExportClick}>
      <DataExporterHeader infoText={infoText} />
      {dateFilter && (
        <ModalSection>
          <TimeFrameSection
            dateFilter={dateFilter}
            handleChangeToDateSelection={handleChangeToDateSelection}
            dateTabs={dateSelectorTabs}
            activeTab={activeDateSelectorTab}
            maxRange={maxRange}
            showTimeInput={showTimeInput}
            defaultEndDate={defaultEndDate}
          />
        </ModalSection>
      )}
      <ModalSection>
        <FileFormatSection
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
        />
      </ModalSection>
    </DataExporterContainer>
  );
};

export default DataExporter;
