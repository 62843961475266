import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { SystemPriceModel } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import { getPathsFromDateFilter } from "utils/DateUtils";
import { stitchDataResults } from "utils/dataStitchHelpers";

export const getSystemPricesData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<SystemPriceModel>> => {
  const paths = getPathsFromDateFilter(dateFilter);
  const results = await Promise.all(
    paths.map(
      async (path) =>
        (
          await getData(`/balancing/settlement/system-prices/${path}`, {
            format,
          })
        ).data
    )
  );
  return stitchDataResults(results, format ?? DataDownloadFormat.Json);
};

export const getSystemPricesJsonData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<SystemPriceModel>> =>
  getSystemPricesData(dateFilter, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<SystemPriceModel>
  >;
