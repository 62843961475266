import BmuDetails from "components/components/balancingMechanism/bmuView/BmuDetails/BmuDetails";
import BmuFuzzySearchBar from "components/components/balancingMechanism/bmuView/BmuFuzzySearchBar/BmuFuzzySearchBar";
import { useBmuViewContext } from "contexts/BmuViewContext";

const BmuAndDateSelection: React.FC = () => {
  const { selectedBmu, setSelectedBmu, dateFilter, setDateFilter } =
    useBmuViewContext();

  return (
    <div className="container px-6">
      <BmuFuzzySearchBar
        selectedBmu={selectedBmu}
        setSelectedBmu={setSelectedBmu}
      />
      <BmuDetails
        selectedBmu={selectedBmu}
        dateFilter={dateFilter}
        handleChangeToDateSelection={setDateFilter}
      />
    </div>
  );
};

export default BmuAndDateSelection;
