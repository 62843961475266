import { WithoutId } from "components/components/SortableTable/SortableTable";
import { DropdownOptionGroup } from "components/components/balancingMechanism/marketView/DatasetDropdown/DatasetDropdown";

interface BaseTableModel {
  id: number;
  bmUnit: string;
  leadParty: string;
  bmUnitType: string;
  nationalGridBmUnit: string;
  settlementDate: Date;
  settlementPeriod: number;
  time: Date;
}

export interface DynamicTableModel extends BaseTableModel {
  value: number;
}

export interface RatesTableModel extends BaseTableModel {
  rate1: number | null;
  elbow2: number | null;
  rate2: number | null;
  elbow3: number | null;
  rate3: number | null;
}

export interface DynamicTabDataStructure {
  MDV: DynamicTableModel[];
  MDP: DynamicTableModel[];
  MZT: DynamicTableModel[];
  MNZT: DynamicTableModel[];
  NDZ: DynamicTableModel[];
  NTB: DynamicTableModel[];
  NTO: DynamicTableModel[];
  RDRE: RatesTableModel[];
  RURE: RatesTableModel[];
  RDRI: RatesTableModel[];
  RURI: RatesTableModel[];
  SEL: DynamicTableModel[];
  SIL: DynamicTableModel[];
}

export type DynamicTabDataset = keyof DynamicTabDataStructure;

const dynamicTableHeaders: Record<
  keyof Omit<WithoutId<DynamicTableModel>, "value">,
  string
> = {
  bmUnit: "BMU ID",
  leadParty: "Lead party name",
  bmUnitType: "BMU type",
  nationalGridBmUnit: "NGC BMU name",
  settlementDate: "Settlement date",
  settlementPeriod: "Settlement period",
  time: "Time (UTC)",
};

const ratesTableHeaders: Record<keyof WithoutId<RatesTableModel>, string> = {
  ...dynamicTableHeaders,
  rate1: "Rate 1 (MW/min)",
  elbow2: "Elbow 2 (MW)",
  rate2: "Rate 2 (MW/min)",
  elbow3: "Elbow 3 (MW)",
  rate3: "Rate 3 (MW/min)",
};

interface DynamicOptionGroup extends DropdownOptionGroup<DynamicTabDataset> {
  headers: Record<string, string>;
}

export const DynamicDatasetConfig: DynamicOptionGroup[] = [
  {
    options: [
      { value: "MDV", label: "Maximum delivery volume (MDV)" },
      { value: "MDP", label: "Maximum delivery period (MDP)" },
    ],
    headers: { ...dynamicTableHeaders, value: "Level" },
    omitGroupHeader: true,
  },
  {
    options: [
      { value: "MZT", label: "Minimum zero time (MZT)" },
      { value: "MNZT", label: "Minimum non-zero time (MNZT)" },
    ],
    headers: { ...dynamicTableHeaders, value: "Period" },
  },
  {
    options: [
      { value: "NDZ", label: "Notice to deviate from zero (NDZ)" },
      { value: "NTB", label: "Notice to deliver bids (NTB)" },
      { value: "NTO", label: "Notice to deliver offers (NTO)" },
    ],
    headers: { ...dynamicTableHeaders, value: "Notice" },
  },
  {
    options: [
      { value: "RDRE", label: "Run-down rate export (RDRE)" },
      { value: "RDRI", label: "Run-down rate import (RDRI)" },
      { value: "RURE", label: "Run-up rate export (RURE)" },
      { value: "RURI", label: "Run-up rate import (RURI)" },
    ],
    headers: ratesTableHeaders,
  },
  {
    options: [
      { value: "SEL", label: "Stable export limit (SEL)" },
      { value: "SIL", label: "Stable import limit (SIL)" },
    ],
    headers: { ...dynamicTableHeaders, value: "Level" },
  },
];

export const mapDatasetToHeaders = (
  dataset: DynamicTabDataset
): Record<string, string> => {
  const matchingGroup = DynamicDatasetConfig.find((g) =>
    g.options.some((o) => o.value === dataset)
  );
  if (matchingGroup) {
    return matchingGroup.headers;
  }
  throw new Error(`Unknown dataset '${dataset}'`);
};
