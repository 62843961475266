import styled from "styled-components";
import colours from "styles/colours";

import ChartSection from "./ChartSection/ChartSection";

const AxisDirectionLabel = styled.div`
  position: absolute;
  right: 100px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: ${colours.white};
  line-height: 1;

  &::before {
    position: absolute;
    left: -25px;
    font-family: elexon-icons;
    text-transform: none;
  }
`;

export const ChartHeader = styled(ChartSection)`
  margin-left: 20px;

  &.is-table-view {
    margin-bottom: 20px;
    margin-right: 0;
  }
`;

export const ChartBody = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const ChartTable = styled(ChartSection)`
  display: block;
  margin-bottom: 60px;
  margin-left: 120px;
`;

export const ChartFooter = styled(ChartSection)`
  margin-top: -30px;
  margin-bottom: 20px;
  margin-left: 120px;
`;

export const AxisDirectionLabelTop = styled(AxisDirectionLabel)`
  top: 30px;

  &::before {
    content: "3";
  }
`;

export const AxisDirectionLabelBottom = styled(AxisDirectionLabel)`
  bottom: 90px;

  &::before {
    content: "o";
  }
`;

export const ChartDataToggleContainer = styled.div`
  display: flex;
  margin-left: 100px;

  > * + * {
    margin-left: 10px;
  }
`;
