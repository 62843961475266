import Dropdown from "components/components/Dropdown/Dropdown";
import TableWithErrorHandling from "components/components/TableWithErrorHandling/TableWithErrorHandling";
import TabContentWithDescription from "components/components/Tabs/TabContentWithDescription/TabContentWithDescription";
import SystemWarningsModal from "components/components/balancingMechanism/balancingMechanismTabs/descriptionModals/SystemWarningsModal";
import SystemWarningsTable from "components/dataVisualization/Events/SystemWarnings/SystemWarningsTable/SystemWarningsTable";
import {
  NoResultsMessage,
  WarningTypeFilter,
} from "components/dataVisualization/Events/SystemWarnings/style";
import useSystemWarningsData from "components/dataVisualization/Events/SystemWarnings/useSystemWarningsData/useSystemWarningsData";
import {
  mapToSystemWarningsRow,
  WarningType,
} from "components/dataVisualization/Events/SystemWarnings/utils";
import DateFilterModel from "models/filterModels/dateFilterModel";
import React, { useEffect, useMemo, useState } from "react";

interface Props {
  dateFilter: DateFilterModel;
}

const SystemWarningsTab: React.FC<Props> = ({ dateFilter }) => {
  const [warningType, setWarningType] = useState<WarningType>(
    WarningType.AllMessages
  );

  const {
    data: rawData,
    fetchData,
    status,
    retryAttempted,
  } = useSystemWarningsData(dateFilter, warningType);

  useEffect(fetchData, [fetchData]);

  const getWarningTypeToDisplay = (): string =>
    warningType === WarningType.AllMessages ? "" : warningType;

  const mappedData = useMemo(
    () => (rawData ? mapToSystemWarningsRow(rawData) : []),
    [rawData]
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const modal = (
    <SystemWarningsModal
      isOpen={modalIsOpen}
      close={(): void => setModalIsOpen(false)}
    />
  );

  return (
    <TabContentWithDescription
      description={`
          System Warnings are messages published by the System Operator to
          inform all involved parties of important information concerning
          operational and commercial issues.`}
      modal={modal}
      openModal={(): void => setModalIsOpen(true)}
    >
      <>
        <WarningTypeFilter data-test-id="warning-type-filter">
          <label>Warning Type</label>
          <Dropdown
            values={Object.values(WarningType)}
            selectedValue={warningType}
            onChange={(newValue: string): void =>
              setWarningType(newValue as WarningType)
            }
            ariaLabel={"Warning type dropdown"}
          />
        </WarningTypeFilter>
        {rawData && rawData?.length > 0 && retryAttempted && (
          <NoResultsMessage>
            <h4>
              No results within provided date range, latest{" "}
              {getWarningTypeToDisplay()} message shown below
            </h4>
          </NoResultsMessage>
        )}
        <TableWithErrorHandling
          data={mappedData}
          dataFetchStatus={status}
          rerequestData={fetchData}
        >
          <SystemWarningsTable data={mappedData} />
        </TableWithErrorHandling>
      </>
    </TabContentWithDescription>
  );
};

export default SystemWarningsTab;
