import Button from "components/components/Button/Button";
import Switch from "components/components/Switch/Switch";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import React from "react";

import {
  StyledCategoryLabel,
  Header,
  StyledCategoryList,
  ToggleAllButtons,
  StyledDisabledText,
} from "./style";

interface Props {
  highlightedDataset?: string;
  sendHighlightedDataset?: (newSelectedDataset: string) => void;
  toggleSingleDatasetVisibility: (dataset: ChartDatasetModel) => void;
  setAllDatasetVisibilities: (visible: boolean) => void;
  datasetCategory: DatasetCategory<ChartDatasetModel>;
}

const CategoryFilterList: React.FC<Props> = ({
  highlightedDataset,
  sendHighlightedDataset,
  toggleSingleDatasetVisibility,
  setAllDatasetVisibilities,
  datasetCategory: { datasets, title: heading = "", disabled, disabledText },
}: Props) => (
  <>
    <Header>{heading}</Header>
    {disabled && disabledText ? (
      <StyledDisabledText>{disabledText}</StyledDisabledText>
    ) : (
      <>
        <ToggleAllButtons data-test-id="toggle-all-buttons">
          <Button
            onClick={(): void => setAllDatasetVisibilities(true)}
            buttonText="Select All"
            className="link-inline"
          />
          &nbsp;|&nbsp;
          <Button
            onClick={(): void => setAllDatasetVisibilities(false)}
            buttonText="Deselect All"
            className="link-inline"
          />
        </ToggleAllButtons>
      </>
    )}
    <StyledCategoryList data-test-id="category-list">
      {datasets.map((dataset) => (
        <StyledCategoryLabel
          onMouseOver={
            sendHighlightedDataset
              ? (): void => sendHighlightedDataset(dataset.dataKey)
              : undefined
          }
          onMouseLeave={
            sendHighlightedDataset
              ? (): void => sendHighlightedDataset("")
              : undefined
          }
          key={dataset.displayName}
          selected={!disabled && highlightedDataset === dataset.dataKey}
          data-test-id={`${dataset.dataKey}--toggle`}
        >
          <dt>
            <Switch
              onClick={(): void => toggleSingleDatasetVisibility(dataset)}
              name={dataset.dataKey}
              onColor={dataset.colour}
              switchedOn={!disabled && !dataset.isHidden}
              leftLabel={dataset.displayName}
              disabled={disabled}
            />
          </dt>
        </StyledCategoryLabel>
      ))}
    </StyledCategoryList>
  </>
);

export default CategoryFilterList;
