import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import { dgwsColours } from "styles/colours";
import { PointShape } from "utils/ChartDrawUtils/pointShapes";

export enum DgwsProcess {
  DayAhead = "dayAhead",
  Intraday = "intraday",
  Current = "current",
}

export enum DgwsPsr {
  WindOffshore = "windOffshore",
  WindOnshore = "windOnshore",
  Solar = "solar",
}

export const dgwsProcessTypes = [
  DgwsProcess.DayAhead,
  DgwsProcess.Intraday,
  DgwsProcess.Current,
];

export const dgwsPsrTypes = [
  DgwsPsr.Solar,
  DgwsPsr.WindOffshore,
  DgwsPsr.WindOnshore,
];

const dgwsProcessDisplayNames = {
  [DgwsProcess.DayAhead]: "Day-ahead",
  [DgwsProcess.Intraday]: "Intraday",
  [DgwsProcess.Current]: "Current",
};

const dgwsProcessChartShape = {
  [DgwsProcess.DayAhead]: PointShape.Square,
  [DgwsProcess.Intraday]: PointShape.Diamond,
  [DgwsProcess.Current]: PointShape.Circle,
};

const getDisplayName = (processType: DgwsProcess): string =>
  dgwsProcessDisplayNames[processType];

export const getDgwsDataKey = (
  psrType: DgwsPsr,
  processType: DgwsProcess
): string => `${psrType}-${processType}`;

const getSwatchShape = (processType: DgwsProcess): PointShape =>
  dgwsProcessChartShape[processType];

const dgwsChartColours = {
  [DgwsPsr.Solar]: {
    [DgwsProcess.DayAhead]: dgwsColours.solarDayAhead,
    [DgwsProcess.Intraday]: dgwsColours.solarIntraday,
    [DgwsProcess.Current]: dgwsColours.solarCurrent,
  },
  [DgwsPsr.WindOffshore]: {
    [DgwsProcess.DayAhead]: dgwsColours.windOffshoreDayAhead,
    [DgwsProcess.Intraday]: dgwsColours.windOffshoreIntraday,
    [DgwsProcess.Current]: dgwsColours.windOffshoreCurrent,
  },
  [DgwsPsr.WindOnshore]: {
    [DgwsProcess.DayAhead]: dgwsColours.windOnshoreDayAhead,
    [DgwsProcess.Intraday]: dgwsColours.windOnshoreIntraday,
    [DgwsProcess.Current]: dgwsColours.windOnshoreCurrent,
  },
};

export const getDgwsColour = (
  psrType: DgwsPsr,
  processType: DgwsProcess
): string => dgwsChartColours[psrType][processType];

const windOffshoreTypes = dgwsProcessTypes.map((processType) => ({
  displayName: getDisplayName(processType),
  dataKey: getDgwsDataKey(DgwsPsr.WindOffshore, processType),
  colour: getDgwsColour(DgwsPsr.WindOffshore, processType),
  swatchShape: getSwatchShape(processType),
}));

const windOnshoreTypes = dgwsProcessTypes.map((processType) => ({
  displayName: getDisplayName(processType),
  dataKey: getDgwsDataKey(DgwsPsr.WindOnshore, processType),
  colour: getDgwsColour(DgwsPsr.WindOnshore, processType),
  swatchShape: getSwatchShape(processType),
}));

const solarTypes = dgwsProcessTypes.map((processType) => ({
  displayName: getDisplayName(processType),
  dataKey: getDgwsDataKey(DgwsPsr.Solar, processType),
  colour: getDgwsColour(DgwsPsr.Solar, processType),
  swatchShape: getSwatchShape(processType),
}));

export const datasets: DatasetCategory<ChartDatasetModel>[] = [
  {
    datasets: windOffshoreTypes,
    id: "wind-offshore",
    title: "Wind offshore forecast",
    showTitle: true,
  },
  {
    datasets: windOnshoreTypes,
    id: "wind-onshore",
    title: "Wind onshore forecast",
    showTitle: true,
  },
  {
    datasets: solarTypes,
    id: "solar",
    title: "Solar forecast",
    showTitle: true,
  },
];
