import { CustomLayerProps } from "@nivo/line";
import { ScaleLinear, ScaleTime } from "@nivo/scales";
import { HorizontalArrowAtFixedVerticalPosition } from "components/dataVisualization/chartComponents/Arrow/HorizontalArrow";
import { TriadPeak } from "models/indicativePeakDemandChartData";
import React from "react";
import colours, { indicativeDemandPeakChartColours } from "styles/colours";
import { VerticalLineFromXAxisToPoint } from "utils/ChartDrawUtils";
import {
  addDaysToDate,
  DateFormat,
  formatDateTimeString,
} from "utils/dateHelpers";

import { PeakInfoGroup } from "./style";

const PeakInfo: React.FC<{
  triadPeak: TriadPeak;
  x: number;
  y: number;
  width: number;
}> = ({ triadPeak: { date, time, settlementPeriod, demand }, x, y, width }) => {
  const height = 72;
  return (
    <PeakInfoGroup transform={`translate(${x - width / 2}, ${y - height})`}>
      <rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill={colours.lightGrey}
        opacity={0.9}
      />
      <text y={20} x={width / 2} textAnchor="middle">
        {formatDateTimeString(date, DateFormat.DateOnly)}
      </text>
      <text y={40} x={width / 2} textAnchor="middle">
        {time} (SP {settlementPeriod})
      </text>
      <text y={60} x={width / 2} textAnchor="middle" className="bold">
        {demand} MW
      </text>
    </PeakInfoGroup>
  );
};

const PeakHighlight: React.FC<{
  triadPeak: TriadPeak;
  layerProps: CustomLayerProps;
  showLeftArrow: boolean;
  showRightArrow: boolean;
}> = ({ triadPeak, layerProps, showRightArrow, showLeftArrow }) => {
  const { date, id, demand, position } = triadPeak;
  const { xScale, yScale } = layerProps;

  const tenDaysAfter = addDaysToDate(new Date(date), 10);

  const tenDaysBefore = addDaysToDate(new Date(date), -10);

  const verticalPositionPercentage = 1 + 6 * position;

  return (
    <>
      <PeakInfo
        triadPeak={triadPeak}
        x={(xScale as ScaleTime<Date>)(date)}
        y={(yScale as ScaleLinear<number>)(demand)}
        width={100}
      />
      {showRightArrow && (
        <HorizontalArrowAtFixedVerticalPosition
          colour={colours.darkGrey}
          start={date}
          end={tenDaysAfter}
          verticalPositionPercentage={verticalPositionPercentage}
          layerProps={layerProps}
          label="10 clear days"
          identifier={`${position}-right`}
        />
      )}
      <VerticalLineFromXAxisToPoint
        x={date}
        y={demand}
        colour={indicativeDemandPeakChartColours[id]}
        layerProps={layerProps}
      />
      {showLeftArrow && (
        <HorizontalArrowAtFixedVerticalPosition
          colour={colours.darkGrey}
          start={date}
          end={tenDaysBefore}
          verticalPositionPercentage={verticalPositionPercentage}
          layerProps={layerProps}
          label="10 clear days"
          identifier={`${position}-left`}
        />
      )}
    </>
  );
};

const PeakHighlights: React.FC<{
  peaks: TriadPeak[];
  layerProps: CustomLayerProps;
}> = ({ peaks, layerProps }) => (
  <>
    {[...peaks]
      .sort(({ date: dateA }, { date: dateB }) => (dateB > dateA ? 1 : -1))
      .map((peak, index) => (
        <PeakHighlight
          layerProps={layerProps}
          triadPeak={peak}
          key={peak.id}
          showRightArrow={index !== 0}
          showLeftArrow={index !== peaks.length - 1}
        />
      ))}
  </>
);

export default React.memo(PeakHighlights);
