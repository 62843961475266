import PhysicalChart from "components/dataVisualization/balancingMechanismTabs/Physical/PhysicalChart/PhysicalChart";
import { PhysicalChartHeader } from "components/dataVisualization/balancingMechanismTabs/Physical/PhysicalChartContainer/style";
import { NoDataAvailable } from "components/dataVisualization/balancingMechanismTabs/style";
import ChartFilterButton from "components/dataVisualization/chartComponents/ChartFilterButton/ChartFilterButton";
import { ChartBody } from "components/dataVisualization/chartComponents/style";
import ChartFilterDrawer from "components/dataVisualization/dataPageComponents/ChartFilterDrawer/ChartFilterDrawer";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import { useBmuViewContext } from "contexts/BmuViewContext";
import { useDatasetCategoriesWithVisibilities } from "hooks/useDatasetsCategoriesWithVisibilities";
import useToggle from "hooks/useToggle";
import {
  pointShapeForPhysicalDataset,
  physicalDatasetDisplayOrder,
  physicalChartDatasetDisplayName,
} from "models/bmuData/physicalChartData";
import React, { useMemo } from "react";
import { transformPhysicalChartData } from "utils/ChartDataUtils/physical/stableDataTransformer";
import { getSingleCategoryChartDatasetModelFromSeries } from "utils/ChartDataUtils/rangedChartDataTransformer/rangedChartDataTransformer";
import { chartWouldBeEmpty } from "utils/chartHelpers";

type Props = {
  onSliceClick: (settlementPeriodStartTime: Date) => void;
};

const PhysicalChartContainer: React.FC<Props> = ({ onSliceClick }) => {
  const {
    selectedBmu,
    dateFilter,
    physicalDetails: { data, status },
  } = useBmuViewContext();
  const rawChartData = data?.chartData ?? null;

  const chartData = useMemo(
    () =>
      rawChartData && dateFilter
        ? transformPhysicalChartData(rawChartData, dateFilter)
        : null,
    [dateFilter, rawChartData]
  );

  const [showChartFilterDrawer, toggleChartFilterDrawer] = useToggle(false);

  const {
    allDatasetCategories,
    datasetCategoriesToDisplay,
    setMatchingDatasetsVisibility,
  } = useDatasetCategoriesWithVisibilities(
    getSingleCategoryChartDatasetModelFromSeries({
      datasetDisplayOrder: physicalDatasetDisplayOrder,
      data: chartData,
      title: "Physical data",
      pointShapeDict: pointShapeForPhysicalDataset,
      displayNameDict: physicalChartDatasetDisplayName,
    })
  );

  if (dateFilter === null || selectedBmu === null || chartData === null) {
    return <></>;
  }

  const filteredChartData = chartData.filter((serie) =>
    datasetCategoriesToDisplay.some((datasetCategory) =>
      datasetCategory.datasets.some((dataset) => dataset.dataKey === serie.id)
    )
  );

  if (chartWouldBeEmpty(status, chartData)) {
    return (
      <NoDataAvailable>
        No data available for this BMU in this timeframe
      </NoDataAvailable>
    );
  }

  return (
    <FullWidthChartSection>
      <>
        <PhysicalChartHeader>
          <ChartFilterButton
            toggleChartFilterDrawer={toggleChartFilterDrawer}
            showChartFilterDrawer={showChartFilterDrawer}
          />
        </PhysicalChartHeader>
        <ChartBody>
          {showChartFilterDrawer && (
            <ChartFilterDrawer
              setMatchingDatasetsVisibility={setMatchingDatasetsVisibility}
              toggleChartFilterDrawer={toggleChartFilterDrawer}
              datasetCategories={allDatasetCategories}
            />
          )}
          <div style={{ width: showChartFilterDrawer ? "80vw" : "100vw" }}>
            <PhysicalChart
              datasetCategories={datasetCategoriesToDisplay}
              series={filteredChartData}
              dataFetchStatus={status}
              dateFilter={dateFilter}
              onSliceClick={onSliceClick}
            />
          </div>
        </ChartBody>
      </>
    </FullWidthChartSection>
  );
};

export default React.memo(PhysicalChartContainer);
