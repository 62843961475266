import { RequestStatus } from "hooks/useRequest";
import { MultiSelect } from "react-multi-select-component";
import { Theme, ThemeConfig } from "react-select";
import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";
import { customSelectTheme } from "styles/themes";

const addErrorColours: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral20: colours.elexonRed, // Border colour
  },
});

export const augmentTheme = (
  baseTheme: Theme,
  requestStatus: RequestStatus
): Theme => {
  const theme = customSelectTheme(baseTheme);
  switch (requestStatus) {
    case RequestStatus.ERRORED:
      return addErrorColours(theme);
    default:
      return theme;
  }
};

export const StyledMultiSelect = styled(MultiSelect)`
  > div > * {
    height: 100%;
    font-size: 1rem;
  }

  > div:nth-child(1) {
    height: 3rem;
    border-radius: 0;
    display: flex;
    align-items: center;
    border: 1px solid ${colours.mediumGrey};

    &:hover {
      border: 1px solid ${colours.focusBlue};
    }

    > div:nth-child(2) {
      background: transparent;
      box-shadow: none;

      > div:nth-child(1) {
        border-radius: 0;
    }
`;

export const StyledLabel = styled.div`
  font-size: 1rem;
`;

export const SelectAllLabel = styled.div`
  font-size: 1rem;
  color: ${colours.elexonBlue};
  text-decoration-line: underline;
`;

export const SelectAllRow = styled.div`
  display: flex;
  gap: 0.63rem;
`;

export const ValueRendererLabel = styled.div`
  display: flex;
`;

export const Placeholder = styled.div`
  color: ${colours.mediumGrey};
`;

export const BoldWord = styled.div`
  font-weight: ${fonts.semibold};
`;

export const Divider = styled.div`
  width: 0.06rem;
  height: 1.25rem;
  background: ${colours.highlightGrey};
`;
