import { ShowMessageLink } from "components/dataVisualization/TruncatedText/style";
import {
  removeNewlines,
  truncate,
} from "components/dataVisualization/TruncatedText/utils";
import useToggle from "hooks/useToggle";
import React from "react";

type Props = {
  text: string;
};

const TruncatedText: React.FC<Props> = ({ text }) => {
  const [expanded, toggleExpanded] = useToggle(false);

  const formattedText = removeNewlines(text);

  const textElement = (warningText: string, showText: string): JSX.Element => (
    <>
      <span dangerouslySetInnerHTML={{ __html: warningText }} />
      <ShowMessageLink onClick={toggleExpanded}>{showText}</ShowMessageLink>
    </>
  );

  const renderText = (): JSX.Element => {
    return expanded
      ? textElement(formattedText, "Show less")
      : textElement(truncate(formattedText), "Show full message");
  };

  return <div>{renderText()}</div>;
};

export default TruncatedText;
