import { WidthType } from "components/components/Tabs/Tabs/Tabs";
import { TabDetails, TabName } from "components/components/Tabs/Tabs/Tabs";
import React from "react";
import { toKebabCase } from "utils/stringHelpers";

import {
  TabDetailsContainer,
  TabTitle,
  ContentContainer,
  DefaultMessage,
} from "./style";

interface Props<T> {
  tabs: TabDetails<T>[];
  activeTabTitle: T;
  showDefault: boolean;
  defaultMessage: string;
  widthType?: WidthType;
}

const TabBody = <T extends TabName>({
  tabs,
  activeTabTitle,
  showDefault,
  defaultMessage,
  widthType = WidthType.SemiWide,
}: Props<T>): JSX.Element => {
  const tabInfo = tabs.find(({ title }) => title === activeTabTitle)!;
  const isActive = activeTabTitle === tabInfo.title;
  const kebabTitle = toKebabCase(tabInfo.title);
  const containerClassName =
    widthType === WidthType.Wide ? "" : "container px-6";

  const content = showDefault ? (
    <DefaultMessage>{defaultMessage}</DefaultMessage>
  ) : (
    <ContentContainer className={containerClassName}>
      {tabInfo.pageTitle && (
        <TabTitle data-test-id="tab-container-header">
          {tabInfo.pageTitle}
        </TabTitle>
      )}
      {tabInfo.children}
    </ContentContainer>
  );

  return (
    <TabDetailsContainer
      role="tabpanel"
      tabIndex={isActive ? 0 : -1}
      id={`tab-container-${kebabTitle}`}
      aria-labelledby={`${kebabTitle}-tab-text`}
      aria-live="polite"
    >
      {content}
    </TabDetailsContainer>
  );
};

export default TabBody;
