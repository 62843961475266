import Button from "components/components/Button/Button";
import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const StatusTooltip = styled.span`
  display: none;
  padding: 1.5em;
  position: absolute;
  top: -30px;
  left: 9em;
  width: 400px;
  z-index: 100;
  background-color: ${colours.lightGrey};
  border: 1px solid ${colours.highlightGrey};
  border-radius: 3px;
`;

export const StatusContainer = styled.div`
  display: flex;
  color: ${(props: { secretExpired: boolean }): string =>
    props.secretExpired ? colours.pinkRed : colours.darkOrange};

  p {
    padding-left: 5px;
  }
`;

export const SecretTable = styled.table`
  thead {
    tr {
      background-color: ${colours.elexonBlue};

      th {
        color: ${colours.white};

        :not(:last-child) {
          border-right: 1px solid ${colours.white};
        }
      }
    }
  }

  tbody {
    tr {
      border: 1px solid ${colours.lightGrey};

      :nth-child(odd) {
        background-color: ${colours.lightGrey};
      }

      td {
        padding-top: 0.75em;
        padding-bottom: 0.75em;
        border: none;
        vertical-align: middle;

        :last-child {
          padding-left: 0;
        }

        &.status-tooltip {
          position: relative;
          cursor: help;

          &:hover ${StatusTooltip} {
            display: block;
          }

          &:focus-within ${StatusTooltip} {
            display: block;
          }

          && ${StatusTooltip}:before {
            content: "";
            display: block;
            position: absolute;
            top: 40%;
            left: -8px;
            width: 15px;
            height: 15px;
            background: ${colours.lightGrey};
            border-left: 1px solid ${colours.highlightGrey};
            border-top: 1px solid ${colours.highlightGrey};
            -moz-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }
        }
      }
    }
  }
`;

export const DeleteSecretButton = styled(Button)`
  & {
    background-color: transparent;
    color: ${colours.linkColor};
    border: none;
    border-radius: 0;
    font-size: ${fonts.small};
    font-weight: normal;
    text-transform: none;
    letter-spacing: 0;

    :active {
      box-shadow: none;
    }

    :hover {
      text-decoration: underline;
      color: ${colours.linkColor};
    }

    :focus {
      color: ${colours.linkColor};
    }

    :disabled {
      background-color: transparent;
    }
  }
`;
