import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { GenerationForecastForWindAndSolar } from "models/generationForecastForWindAndSolar/generationForecastForWindAndSolarModel";
import { getSettlementPeriodFromSettlementTime } from "utils/DateUtils";
import { toDateOnlyString } from "utils/dateHelpers";

export const getGenerationForecastForWindAndSolarData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<GenerationForecastForWindAndSolar>> => {
  const { data } = await getData(
    "/forecast/generation/wind-and-solar/day-ahead",
    {
      from: toDateOnlyString(dateFilter.startDate),
      to: toDateOnlyString(dateFilter.endDate),
      settlementPeriodFrom: getSettlementPeriodFromSettlementTime(
        dateFilter.startDate
      ),
      settlementPeriodTo: getSettlementPeriodFromSettlementTime(
        dateFilter.endDate
      ),
      processType: "all",
    },
    format
  );
  return data;
};

export const getGenerationForecastForWindAndSolarJsonData = (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<GenerationForecastForWindAndSolar>> =>
  getGenerationForecastForWindAndSolarData(
    dateFilter,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<GenerationForecastForWindAndSolar>>;
