import { SpinnerSection } from "components/components/Iris/AuthenticatedView/style";
import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import { SpinnerContainer } from "components/components/LoadingSpinner/style";
import { FC, Suspense } from "react";

interface SuspenseWrapperProps {
  children: JSX.Element;
}

const SuspenseWrapper: FC<SuspenseWrapperProps> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <SpinnerSection>
          <SpinnerContainer>
            <LoadingSpinner isLoading />
          </SpinnerContainer>
        </SpinnerSection>
      }
    >
      {children}
    </Suspense>
  );
};

export default SuspenseWrapper;
