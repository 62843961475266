import { Datum, Serie } from "@nivo/line";
import {
  WindGenerationModel,
  WindGenSeriesIds,
} from "components/dataVisualization/WindGeneration/WindGenerationChart/WindGenDatasets";
import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";
import { WindGenerationForecast } from "models/windGeneration/windGenerationForecast";
import { chartColours } from "styles/colours";

const mapForecastData = (forecastData: WindGenerationForecast[]): Datum[] =>
  forecastData.map((d) => ({
    x: new Date(d.startTime),
    y: d.generation,
    settlementPeriod: d.settlementPeriod,
    publishTime:
      d.publishTime !== undefined ? new Date(d.publishTime) : undefined,
  }));

export const transformWindGenerationData = (
  rawData: WindGenerationModel,
  datasets: ChartDatasetModel[]
): Serie[] =>
  [
    {
      id: WindGenSeriesIds.Outturn,
      colour: chartColours.windGenerationOutturn,
      data: rawData.outturnData.map((d) => ({
        x: new Date(d.startTime),
        y: d.outturn,
        settlementPeriod: d.settlementPeriod,
      })),
    },
    {
      id: WindGenSeriesIds.LatestForecast,
      colour: "transparent",
      data: mapForecastData(rawData.latestForecastData),
    },
    {
      id: WindGenSeriesIds.InitialForecast,
      colour: "transparent",
      data: mapForecastData(rawData.initialForecastData),
    },
  ].filter((s) => datasets.some((d) => d.dataKey === s.id));
