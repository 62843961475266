import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const DropdownContainer = styled.div`
  padding-top: 2.5em;
  width: 450px;

  label {
    font-size: ${fonts.smallMedium};
  }

  > div {
    padding-top: 0.5em;
  }
`;

export const Divider = styled.hr`
  margin: 0 0 1em 0;
  background-color: ${colours.highlightGrey};
  height: 1px;
`;
