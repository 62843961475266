const dynamicDatasets = [
  "SIL",
  "SEL",
  "NDZ",
  "NTB",
  "NTO",
  "MZT",
  "MNZT",
  "MDV",
  "MDP",
];

export type DynamicDataset = typeof dynamicDatasets[number];

export const isDynamicDataset = (input: string): boolean =>
  dynamicDatasets.includes(input);

export interface DynamicDataModel {
  dataset: DynamicDataset;
  bmUnit: string;
  nationalGridBmUnit: string;
  time: string;
  value: number;
  settlementDate: string;
  settlementPeriod: number;
}
