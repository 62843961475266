import Button from "components/components/Button/Button";
import { FuelTypeGrouping } from "models/generationByFuelType/FuelTypeGroupings";
import GenerationByFuelTypeSummaryTableModel from "models/generationByFuelType/GenerationByFuelTypeSummaryTableModel";
import React, { useCallback, useEffect, useState } from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";
import { formatNumberToGBLocale } from "utils/summaryHelpers";

import {
  CloseButtonContainer,
  GenerationTable,
  SectionRow,
  LastUpdatedContainer,
  SeparatorRow,
  Subtitle,
  TotalsRow,
} from "./style";

export const inAlphabeticalOrderWithOtherAtTheEnd = (
  a: GenerationByFuelTypeSummaryTableModel,
  b: GenerationByFuelTypeSummaryTableModel
): number => {
  if (a.fuelType === "OTHER") return 1;
  if (b.fuelType === "OTHER") return -1;
  return a.fuelTypeName.localeCompare(b.fuelTypeName);
};

interface Props {
  data: GenerationByFuelTypeSummaryTableModel[];
  summaryTime: Date;
  onClose: () => void;
}

const GenerationByFuelTypeSummaryTable: React.FC<Props> = ({
  data,
  summaryTime,
  onClose,
}: Props) => {
  const [lastHalfHourTime, setLastHalfHourTime] = useState("");
  const [lastTwentyFourHourTime, setLastTwentyFourHourTime] = useState("");

  const buildSummaryTimeStrings = useCallback((): [string, string] => {
    const minutesSinceHalfHour = summaryTime.getMinutes() % 30;

    const halfHour = new Date(summaryTime);
    halfHour.setMinutes(summaryTime.getMinutes() - minutesSinceHalfHour);

    const previousHalfHour = new Date(halfHour);
    previousHalfHour.setMinutes(halfHour.getMinutes() - 30);

    const previousHalfHourString = formatDateTimeString(
      previousHalfHour,
      DateFormat.TimeOnly
    );

    const halfHourString = formatDateTimeString(halfHour, DateFormat.TimeOnly);

    return [previousHalfHourString, halfHourString];
  }, [summaryTime]);

  useEffect(() => {
    const [previousHalfHourString, halfHourString] = buildSummaryTimeStrings();

    setLastHalfHourTime(`(${previousHalfHourString}-${halfHourString})`);
    setLastTwentyFourHourTime(`(${halfHourString}-${halfHourString})`);
  }, [buildSummaryTimeStrings, summaryTime]);

  return (
    <div data-test-id="summary-table-modal">
      <CloseButtonContainer data-test-id="summary-table-close-button-row">
        <Button
          ariaLabel="Close"
          buttonText="Close"
          aria-label="close"
          onClick={onClose}
          data-test-id="close-button"
        />
      </CloseButtonContainer>
      <section className="section pt-5">
        <div className="container">
          <div className="columns">
            <div className="column is-two-thirds">
              <h1 className="title" id="summary-modal-header">
                Generation by fuel type
              </h1>
              <Subtitle className="subtitle" id="summary-modal-description">
                Current summary
              </Subtitle>
            </div>
            <div className="column is-one-third">
              <LastUpdatedContainer data-test-id="summary-table-data-last-updated">
                Data Last Updated:&nbsp;
                {formatDateTimeString(
                  summaryTime,
                  DateFormat.DateTimeWithSeconds
                )}
              </LastUpdatedContainer>
            </div>
          </div>
          <GenerationTable>
            <thead>
              <tr data-test-id="summary-table-data-table-header">
                <td>GB Generating Plant</td>
                <td colSpan={2}>Current</td>
                <td colSpan={2}>
                  Last half hour&nbsp;
                  {lastHalfHourTime}
                </td>
                <td colSpan={2}>
                  Last 24 hours&nbsp;
                  {lastTwentyFourHourTime}
                </td>
              </tr>
            </thead>
            <tbody>
              <SectionRow>
                <td>Fuel Types</td>
                <td>MW</td>
                <td>%</td>
                <td>MW</td>
                <td>%</td>
                <td>MWh</td>
                <td>%</td>
              </SectionRow>
              {data
                .filter(
                  (value) =>
                    value.fuelTypeGrouping !==
                      FuelTypeGrouping.interconnector ||
                    value.fuelType === "OTHER"
                )
                .sort(inAlphabeticalOrderWithOtherAtTheEnd)
                .map((value, idx) => (
                  <tr
                    key={value.fuelType}
                    data-test-id={`summary-table-fuel-type-${idx}`}
                  >
                    <td>{value.fuelTypeName}</td>
                    <td>{formatNumberToGBLocale(value.currentUsage, 0)}</td>
                    <td>{`${value.currentPercentage.toFixed(1)}%`}</td>
                    <td>{formatNumberToGBLocale(value.halfHourUsage, 0)}</td>
                    <td>{`${value.halfHourPercentage.toFixed(1)}%`}</td>
                    <td>
                      {formatNumberToGBLocale(value.twentyFourHourUsage, 0)}
                    </td>
                    <td>{`${value.twentyFourHourPercentage.toFixed(1)}%`}</td>
                  </tr>
                ))}
              <SeparatorRow>
                <td colSpan={7}>&nbsp;</td>
              </SeparatorRow>
              <SectionRow>
                <td>Interconnectors</td>
                <td>MW</td>
                <td>%</td>
                <td>MW</td>
                <td>%</td>
                <td>MWh</td>
                <td>%</td>
              </SectionRow>
              {data
                .filter(
                  (value) =>
                    value.fuelTypeGrouping ===
                      FuelTypeGrouping.interconnector &&
                    value.fuelType !== "OTHER"
                )
                .map((value, idx) => (
                  <tr
                    key={value.fuelType}
                    data-test-id={`summary-table-interconnector-${idx}`}
                  >
                    <td>{`${value.fuelTypeName} - ${value.fuelType}`}</td>
                    <td>{formatNumberToGBLocale(value.currentUsage, 0)}</td>
                    <td>{`${value.currentPercentage.toFixed(1)}%`}</td>
                    <td>{formatNumberToGBLocale(value.halfHourUsage, 0)}</td>
                    <td>{`${value.halfHourPercentage.toFixed(1)}%`}</td>
                    <td>
                      {formatNumberToGBLocale(value.twentyFourHourUsage, 0)}
                    </td>
                    <td>{`${value.twentyFourHourPercentage.toFixed(1)}%`}</td>
                  </tr>
                ))}
              <SeparatorRow>
                <td colSpan={7}>&nbsp;</td>
              </SeparatorRow>
              <TotalsRow data-test-id="summary-table-totals">
                <td>Totals</td>
                <td>
                  {formatNumberToGBLocale(
                    data.reduce<number>(
                      (accumulator, value) => accumulator + value.currentUsage,
                      0
                    ),
                    0
                  )}
                </td>
                <td>100%</td>
                <td>
                  {formatNumberToGBLocale(
                    data.reduce<number>(
                      (accumulator, value) => accumulator + value.halfHourUsage,
                      0
                    ),
                    0
                  )}
                </td>
                <td>100%</td>
                <td>
                  {formatNumberToGBLocale(
                    data.reduce<number>(
                      (accumulator, value) =>
                        accumulator + value.twentyFourHourUsage,
                      0
                    ),
                    0
                  )}
                </td>
                <td>100%</td>
              </TotalsRow>
            </tbody>
          </GenerationTable>
        </div>
      </section>
    </div>
  );
};

export default GenerationByFuelTypeSummaryTable;
