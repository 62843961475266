import DatePickerWrapper from "components/components/DateSelector/DatePickerWrapper";
import { DateInputLabel } from "components/dataVisualization/dataPageComponents/ChartFilter/DateInput/DateInput";
import React from "react";
import { ceilToHalfHour, EARLIEST_DATE } from "utils/dateHelpers";
import { parseTimeOnGivenDate } from "utils/datePickerHelpers";

interface Props {
  selectedDate: Date;
  handleChangeToDateSelection: (newDate: Date) => void;
  maxDate?: Date;
}

const SettlementPeriodSelector: React.FC<Props> = ({
  selectedDate,
  handleChangeToDateSelection,
  maxDate = ceilToHalfHour(new Date()),
}) => {
  const isValidTime = (hourString: string, minString: string): boolean => {
    const timeToCheck = parseTimeOnGivenDate(
      selectedDate,
      hourString,
      minString
    );
    return timeToCheck <= maxDate;
  };

  return (
    <DatePickerWrapper
      selectedDate={selectedDate}
      onChange={handleChangeToDateSelection}
      minDate={EARLIEST_DATE}
      maxDate={maxDate}
      isValidTime={isValidTime}
      isActiveTab={true}
      dateInputLabel={DateInputLabel.SettlementPeriod}
      showSettlementPeriod={true}
    />
  );
};
export default SettlementPeriodSelector;
