import { getGenerationByFuelTypeSnapshotData } from "api/dataVisualisation/generationByFuelType/generationByFuelType";
import LoadingButton from "components/components/Button/LoadingButton";
import InformationTooltip from "components/components/InformationTooltip/InformationTooltip";
import {
  TooltipLabel,
  TooltipLine,
  TooltipWrapper,
} from "components/components/InformationTooltip/style";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import SummaryTile from "components/dataVisualization/dataPageComponents/SummaryTile/SummaryTile";
import {
  Header,
  MajorValueCell,
  SummaryTable,
  ValueCell,
} from "components/dataVisualization/dataPageComponents/SummaryTile/commonStyle";
import GenerationByFuelTypeSummaryTable from "components/dataVisualization/generationByFuelType/GenerationByFuelTypeSummaryTable/GenerationByFuelTypeSummaryTable";
import GenerationByFuelTypeSummaryTableModel from "models/generationByFuelType/GenerationByFuelTypeSummaryTableModel";
import { GenerationByFuelTypeSummaryTileModel } from "models/generationByFuelType/GenerationByFuelTypeSummaryTileModel";
import React, { useEffect, useState } from "react";
import {
  createTwentyFourHourSummary,
  mapToGenerationByFuelTypeSummaryDataModels,
} from "utils/TabularDataUtils/GenerationByFuelTypeSummaryUtils";

import { ValuesHeader } from "./style";

const GenerationByFuelTypeSummaryTile: React.FC = () => {
  const [summaryTableData, setSummaryTableData] =
    useState<GenerationByFuelTypeSummaryTableModel[]>();
  const [summaryTileData, setSummaryTileData] =
    useState<GenerationByFuelTypeSummaryTileModel>();
  const [summaryTime, setSummaryTime] = useState(new Date());
  const [showSummaryModal, setShowSummaryModal] = useState(false);

  useEffect(() => {
    getGenerationByFuelTypeSnapshotData()
      .then((response) => {
        if (response && response.length === 0) {
          return;
        }

        setSummaryTableData(
          mapToGenerationByFuelTypeSummaryDataModels(response)
        );
        setSummaryTileData(createTwentyFourHourSummary(response));
        setSummaryTime(new Date());
      })
      // TODO 36876: Add error handling for failed calls to the API and empty data returned
      // eslint-disable-next-line no-console
      .catch(console.log);
  }, []);

  const viewCurrentSummary = (): void => {
    setShowSummaryModal(true);
  };

  return (
    <SummaryTile>
      <>
        {summaryTableData && (
          <Modal
            size={ModalSize.xLarge}
            isActive={showSummaryModal}
            onClose={(): void => setShowSummaryModal(false)}
            headingId="summary-modal-header"
            descriptionId="summary-modal-description"
          >
            <GenerationByFuelTypeSummaryTable
              data={summaryTableData}
              summaryTime={summaryTime}
              onClose={(): void => setShowSummaryModal(false)}
            />
          </Modal>
        )}
        <SummaryTable tableLayout="auto">
          <thead>
            <tr>
              <Header colSpan={2}>24 hour snapshot</Header>
            </tr>
          </thead>
          <tbody>
            <ValuesHeader>
              <td />
              <td className="right-align">GWh</td>
            </ValuesHeader>
            <tr>
              <td>TOTAL</td>
              <MajorValueCell
                className="right-align"
                data-test-id="summary-tile-data-total"
              >
                {summaryTileData?.total ?? "—"}
              </MajorValueCell>
            </tr>
            <tr>
              <td>
                <TooltipWrapper>
                  Low Carbons
                  <InformationTooltip>
                    <TooltipLine>
                      <TooltipLabel>Low Carbons: </TooltipLabel>
                      Biomass, Hydro (non-PS), Nuclear, Pumped Storage, Wind
                    </TooltipLine>
                  </InformationTooltip>
                </TooltipWrapper>
              </td>
              <ValueCell
                className="right-align"
                data-test-id="summary-tile-data-low-carbons"
              >
                {summaryTileData?.lowCarbons ?? "—"}
              </ValueCell>
            </tr>
            <tr>
              <td>
                <TooltipWrapper>
                  Fossil Fuels
                  <InformationTooltip>
                    <TooltipLine>
                      <TooltipLabel>Fossil Fuels: </TooltipLabel>
                      CCGT, Coal, OCGT, Oil
                    </TooltipLine>
                  </InformationTooltip>
                </TooltipWrapper>
              </td>
              <ValueCell
                className="right-align"
                data-test-id="summary-tile-data-fossil-fuels"
              >
                {summaryTileData?.fossilFuels ?? "—"}
              </ValueCell>
            </tr>
            <tr>
              <td>Interconnectors</td>
              <ValueCell
                className="right-align"
                data-test-id="summary-tile-data-interconnectors"
              >
                {summaryTileData?.interconnectors ?? "—"}
              </ValueCell>
            </tr>
            <tr>
              <td>Other</td>
              <ValueCell
                className="right-align"
                data-test-id="summary-tile-data-other"
              >
                {summaryTileData?.other ?? "—"}
              </ValueCell>
            </tr>
            <tr data-test-id="summary-tile-open-button-row">
              <ValueCell colSpan={2}>
                <LoadingButton
                  onClick={viewCurrentSummary}
                  buttonText="daily view"
                  isLoading={summaryTableData === undefined}
                />
              </ValueCell>
            </tr>
          </tbody>
        </SummaryTable>
      </>
    </SummaryTile>
  );
};

export default GenerationByFuelTypeSummaryTile;
