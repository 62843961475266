import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { ClientSecret, IrisSummary } from "api/account/client";
import SecretStatus from "components/components/Iris/AuthenticatedView/IrisDetailsPage/IrisDetails/SecretsTable/SecretStatus";
import {
  DeleteSecretButton,
  SecretTable,
} from "components/components/Iris/AuthenticatedView/IrisDetailsPage/IrisDetails/SecretsTable/style";
import React from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

type Props = {
  data: IrisSummary;
  deleteDisabled: boolean;
  setSecretToDelete: (s: ClientSecret) => void;
};

const SecretsTable: React.FC<Props> = ({
  data,
  deleteDisabled,
  setSecretToDelete,
}) => {
  const noSecrets = data.applicationSecrets.length === 0;

  return (
    <SecretTable>
      <thead>
        <tr>
          <th>Secret hint</th>
          <th>Expiry date</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody aria-live="polite">
        {noSecrets ? (
          <tr>
            <td>—</td>
            <td>—</td>
            <td>—</td>
            <td>—</td>
          </tr>
        ) : (
          data.applicationSecrets.map((s) => (
            <tr key={s.secretId}>
              <td>{s.secretHint.padEnd(18, "*")}</td>
              <td>
                {formatDateTimeString(
                  new Date(s.expiryTime),
                  DateFormat.DateOnly
                )}
              </td>
              <SecretStatus secret={s} />
              <td>
                <DeleteSecretButton
                  ariaLabel="delete"
                  buttonText="Delete"
                  iconName={faTrashAlt}
                  onClick={(): void => setSecretToDelete(s)}
                  disabled={deleteDisabled}
                />
              </td>
            </tr>
          ))
        )}
      </tbody>
    </SecretTable>
  );
};

export default SecretsTable;
