import styled from "styled-components";
import fonts from "styles/fonts";

export const AdjustmentActivitiesValue = styled.span`
  width: 20px;
  margin-right: 10px;
`;

export const CategoryContainer = styled.div`
  margin-bottom: 1em;
`;

export const TooltipTable = styled.table`
  table-layout: auto;

  > thead > tr > th {
    font-weight: ${fonts.heavy};
    min-width: 100px;
    white-space: nowrap;

    &&& {
      margin-bottom: 0.5em;
    }
  }

  > thead > tr > th,
  > tbody > tr > td {
    padding: 0;
    border: 0;

    &:not(:first-child) {
      text-align: right;
    }
  }
`;
