import useSettlementBidOfferStacksData from "components/dataVisualization/DetailedSystemPrices/SystemPricesHooks/useSettlementBidOfferStacksData";
import useSettlementCalculationSummaryData from "components/dataVisualization/DetailedSystemPrices/SystemPricesHooks/useSettlementCalculationSummaryData";
import useSettlementMessagesData from "components/dataVisualization/DetailedSystemPrices/SystemPricesHooks/useSettlementMessagesData";
import {
  getMostRecentRunData,
  getTransformedSettlementData,
  BidOfferQueryIndicator,
} from "components/dataVisualization/DetailedSystemPrices/utils";
import { RequestStatus } from "hooks/useRequest";
import {
  SettlementBidOfferStackModelTransformed,
  SettlementCalculationSummaryModelTransformed,
  SettlementMessageModelTransformed,
} from "models/detailedSystemPrices/settlementModels";
import { useEffect } from "react";
import { getSettlementDateAndPeriodFromSettlementTime } from "utils/DateUtils";
import { toDateOnlyString } from "utils/dateHelpers";
import { combineRequestStatuses } from "utils/requestHelpers";

export interface SettlementTransformedData {
  summary: SettlementCalculationSummaryModelTransformed | null;
  messages: SettlementMessageModelTransformed[];
  bid: SettlementBidOfferStackModelTransformed[];
  offer: SettlementBidOfferStackModelTransformed[];
}
export interface ConsolidatedData {
  data: SettlementTransformedData;
  status: RequestStatus;
  errorCode: {
    summary: number | null;
    messages: number | null;
    bid: number | null;
    offer: number | null;
  };
  fetch: {
    summary: () => void;
    messages: () => void;
    bid: () => void;
    offer: () => void;
  };
}

const useSystemPricesConsolidatedData = (
  selectedDate: Date
): ConsolidatedData => {
  const { settlementDate, settlementPeriod } =
    getSettlementDateAndPeriodFromSettlementTime(selectedDate);
  const settlementDateOnlyString = toDateOnlyString(settlementDate);

  const {
    data: summaryData,
    fetchData: fetchSummaryData,
    status: summaryStatus,
    errorCode: summaryErrorCode,
  } = useSettlementCalculationSummaryData(
    settlementDateOnlyString,
    settlementPeriod
  );

  const {
    data: messagesData,
    fetchData: fetchMessagesData,
    status: messagesStatus,
    errorCode: messagesErrorCode,
  } = useSettlementMessagesData(settlementDateOnlyString, settlementPeriod);

  const {
    data: bidData,
    fetchData: fetchBidData,
    status: bidStatus,
    errorCode: bidErrorCode,
  } = useSettlementBidOfferStacksData(
    settlementDateOnlyString,
    settlementPeriod,
    BidOfferQueryIndicator.BID
  );

  const {
    data: offerData,
    fetchData: fetchOfferData,
    status: offerStatus,
    errorCode: offerErrorCode,
  } = useSettlementBidOfferStacksData(
    settlementDateOnlyString,
    settlementPeriod,
    BidOfferQueryIndicator.OFFER
  );

  useEffect(fetchSummaryData, [fetchSummaryData]);
  useEffect(fetchMessagesData, [fetchMessagesData]);
  useEffect(fetchBidData, [fetchBidData]);
  useEffect(fetchOfferData, [fetchOfferData]);

  const systemPricesConsolidatedDataStatus = combineRequestStatuses(
    summaryStatus,
    messagesStatus,
    bidStatus,
    offerStatus
  );

  const transformedSettlementData = getTransformedSettlementData(
    summaryData,
    messagesData,
    bidData,
    offerData
  );

  const mostRecentRunData = getMostRecentRunData(transformedSettlementData);

  return {
    data: mostRecentRunData,
    status: systemPricesConsolidatedDataStatus,
    errorCode: {
      summary: summaryErrorCode,
      messages: messagesErrorCode,
      bid: bidErrorCode,
      offer: offerErrorCode,
    },
    fetch: {
      summary: fetchSummaryData,
      messages: fetchMessagesData,
      bid: fetchBidData,
      offer: fetchOfferData,
    },
  };
};

export default useSystemPricesConsolidatedData;
