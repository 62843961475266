const keysPressed: string[] = [];
const toMatch = "softwire";
const img = `
  ________
 |        |
 |________|
 |  _     |
 | ( )    |
 |  \`     |
 |        |
 |   _    |
 |  ( )   |
 |   )\\   |
 |  (  )  |
 |__) (___|
 |--------|
 |INSIGHTS|
 |--------|
 |________|
/__________\\`;

export const checkInput = (s: string): void => {
  keysPressed.push(s.toLowerCase());
  if (keysPressed.length > toMatch.length) {
    keysPressed.shift();
  }
  if (keysPressed.join("") === toMatch) {
    // eslint-disable-next-line no-console
    console.log(img);
  }
};
