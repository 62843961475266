import axios from "axios";
import { SwaggerJsonFile } from "models/apiDocumentation/apiDocumentationModels";

export const getSwaggerJsonFile = async (): Promise<SwaggerJsonFile> => {
  const data = axios
    .get(`${process.env.REACT_APP_API_SWAGGER_FILE}`, {
      responseType: "json",
    })
    .then((response) => response.data);
  return data;
};
