import { CustomLayerProps, Point, Serie } from "@nivo/line";
import { ScaleTime } from "@nivo/scales";
import { bisectLeft } from "d3-array";
import { area } from "d3-shape";
import React, { useCallback, useMemo, useRef } from "react";

// we assume that area charts have 0 as a lowest possible y value for drawing the area elements
const Y_MIN = 0;

/**
 * Generates a chart layer containing transparent areas over the original chart areas to handle mouseover events.
 * This layer required all series to be of the same length. If this is not the case, the yMaxOffsets variable used
 * to manage the stacked areas will not work as intended.
 */
export const generateMouseoverAreas =
  (
    onMouseOver: (point: Point) => void,
    onMouseLeave: () => void
  ): ((props: CustomLayerProps) => JSX.Element) =>
  ({ points, series, xScale, yScale }): JSX.Element => {
    const containerRef = useRef<SVGGElement>(null);

    const createAreaForSerie = useCallback(
      (
        serie: Serie,
        allPoints: readonly Point[],
        yMaxOffsets: number[]
      ): JSX.Element => {
        const seriePoints = allPoints.filter((x) => x.serieId === serie.id);
        let yMaxIndex = 0;

        const areaGenerator = area<Point>()
          .x((point) => (xScale as ScaleTime<Date>)(point.data.x as Date))
          .y1((point) => {
            // eslint-disable-next-line no-param-reassign -- yMaxOffsets passed as ref to manage stacked area
            yMaxOffsets[yMaxIndex] += point.data.y as number;
            const y1 = (yScale as ScaleTime<number>)(yMaxOffsets[yMaxIndex]);
            yMaxIndex += 1;
            return y1;
          })
          .y0(() => (yScale as ScaleTime<number>)(Y_MIN));

        const handleMouseOver = (
          event: React.MouseEvent<Element, MouseEvent>
        ): void => {
          // get x-coordinate of area at cursor
          const { clientX } = event;
          const bounds = containerRef.current!.getBoundingClientRect();
          const xPoint = clientX - bounds.left;

          // get all plotted dates
          const allDates = seriePoints.map((point) => point.data.x) as Date[];

          // use invert to calculate the date that corresponds to the x-coordinate (may be between plotted dates)
          const calculatedXDate = (xScale as ScaleTime<Date>).invert(xPoint);

          // use bisectLeft to calculate the index of where the calculated date would fit in the all plotted dates collection
          const pointIndex = bisectLeft(allDates, calculatedXDate);

          // accessing seriePoints with pointIndex gets the nearest valid point to the cursor's x-coordinate
          onMouseOver(seriePoints[pointIndex]);
        };

        return (
          <path
            d={areaGenerator(seriePoints)!}
            key={serie.id}
            opacity={0}
            onMouseEnter={handleMouseOver}
            onMouseMove={handleMouseOver}
            onMouseLeave={onMouseLeave}
          />
        );
      },
      [xScale, yScale]
    );

    return useMemo(() => {
      if (!series.length) {
        return <></>;
      }

      const yMaxOffsets = Array(series[0].data.length).fill(0);

      const areas = series
        .map((serie) => createAreaForSerie(serie, points, yMaxOffsets))
        .reverse();

      return <g ref={containerRef}>{areas}</g>;
    }, [points, series, createAreaForSerie]);
  };
