import { getRollingSystemFrequencyData } from "api/dataVisualisation/rollingSystemFrequency/rollingSystemFrequency";
import { ReadOnlyDates } from "components/components/DateSelector/DateSelector";
import Switch from "components/components/Switch/Switch";
import RollingSystemFrequencyChart from "components/dataVisualization/RollingSystemFrequency/RollingSystemFrequencyChart/RollingSystemFrequencyChart";
import Legend from "components/dataVisualization/chartComponents/Legend/Legend";
import {
  ChartDataToggleContainer,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import { useDataExport } from "contexts/DataExportContext";
import useToggle from "hooks/useToggle";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useEffect, useMemo, useState } from "react";
import colours, { chartColours } from "styles/colours";
import { transformRollingSystemFrequencyModelData } from "utils/ChartDataUtils/systemFrequency/systemFrequency";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  addHoursToDate,
  ceilToHalfHour,
  DAYS_IN_ONE_WEEK,
  getNumberOfHoursBetweenDates,
} from "utils/dateHelpers";

import useRollingSystemFrequencyChartContainerData from "./useRollingSystemFrequencyChartContainerData";

const RollingSystemFrequencyChartContainer: React.FC = () => {
  const defaultEndDate = ceilToHalfHour(new Date());

  const chartDateSelectorTabs = [DateSelectorTabs.OneHour];
  const downloadDateSelectorTabs = [
    DateSelectorTabs.OneHour,
    DateSelectorTabs.TwentyFourHours,
    DateSelectorTabs.OneWeek,
  ];

  const [chartDateFilter, setChartDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addHoursToDate(defaultEndDate, -1), defaultEndDate)
  );
  const [downloadDateFilter, setDownloadDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addHoursToDate(defaultEndDate, -1), defaultEndDate)
  );

  const [activeChartDateSelectorTab, setActiveChartDateSelectorTab] = useState(
    DateSelectorTabs.OneHour
  );
  const [activeDownloadDateSelectorTab, setActiveDownloadDateSelectorTab] =
    useState(DateSelectorTabs.OneHour);

  const {
    data: rawData,
    fetchData,
    status,
  } = useRollingSystemFrequencyChartContainerData(chartDateFilter);

  const chartData = useMemo(
    () => (rawData ? transformRollingSystemFrequencyModelData(rawData) : []),
    [rawData]
  );

  useEffect(fetchData, [fetchData]);

  const [showReferenceData, toggleReferenceData] = useToggle(false);

  const { dispatch: dataExportCountDispatch } = useDataExport();

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getRollingSystemFrequencyData(downloadDateFilter, fileFormat),
      `RollingSystemFrequency-${downloadDateFilter.startDate.toISOString()}-${downloadDateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const handleChangeToChartDateSelection = (
    { startDate }: { startDate: Date },
    newActiveTab: DateSelectorTabs
  ): void => {
    const endDate = addHoursToDate(startDate, 1);

    setActiveChartDateSelectorTab(newActiveTab);
    setChartDateFilter(new DateFilterModel(startDate, endDate));

    setActiveDownloadDateSelectorTab(newActiveTab);
    setDownloadDateFilter(new DateFilterModel(startDate, endDate));
  };

  const handleChangeToDownloadDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    const numberOfHoursBetweenDates = getNumberOfHoursBetweenDates(
      newDateFilter.startDate,
      newDateFilter.endDate
    );

    const endDate =
      numberOfHoursBetweenDates > 24 * 7
        ? addHoursToDate(newDateFilter.startDate, 24 * 7)
        : newDateFilter.endDate;

    setActiveDownloadDateSelectorTab(newActiveTab);
    setDownloadDateFilter(
      new DateFilterModel(newDateFilter.startDate, endDate)
    );
  };

  return (
    <>
      <ChartHeader>
        <ChartDataToggleContainer>
          <Switch
            name="reference-data"
            onColor={colours.elexonDarkTeal}
            offColor={colours.disabledGrey}
            onClick={toggleReferenceData}
            switchedOn={showReferenceData}
            leftLabel="Reference Threshold"
            headerLabel
          />
        </ChartDataToggleContainer>
        <ChartFilter
          dateFilter={chartDateFilter}
          handleChangeToDateSelection={handleChangeToChartDateSelection}
          dateSelectorTabs={chartDateSelectorTabs}
          activeDateSelectorTab={activeChartDateSelectorTab}
          readOnlyDates={ReadOnlyDates.End}
        />
      </ChartHeader>
      <RollingSystemFrequencyChart
        series={chartData}
        dataFetchStatus={status}
        rerequestData={fetchData}
        showReferenceData={showReferenceData}
      />
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFile}
          dateFilter={downloadDateFilter}
          handleChangeToDateSelection={handleChangeToDownloadDateSelection}
          dateSelectorTabs={downloadDateSelectorTabs}
          activeDateSelectorTab={activeDownloadDateSelectorTab}
          maxRange={DAYS_IN_ONE_WEEK}
        />
        {showReferenceData && (
          <Legend
            items={[
              {
                colour: chartColours.statutoryFrequency,
                label: "Statutory operating frequency tolerance",
              },
              {
                colour: chartColours.nationalGridFrequency,
                label: "NESO operating frequency tolerance",
              },
            ]}
          />
        )}
      </ChartFooter>
    </>
  );
};

export default RollingSystemFrequencyChartContainer;
