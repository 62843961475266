import styled from "styled-components";

export const CustomSwaggerContainer = styled.div`
  flex: 1 1 0;
  height: unset;
  min-width: 0;

  .opblock-summary .copy-to-clipboard {
    display: none;
  }

  .opblock-summary-path,
  .opblock-summary-description {
    // !important needs to be used to override Swagger defaults
    max-width: unset !important;
    flex: 1 1 0 !important;
  }

  #operations-- {
    margin: 0;
  }
`;
