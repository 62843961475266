import { RangedChartData } from "models/rangedChartData";
import { PointShape } from "utils/ChartDrawUtils/pointShapes";

export type BidOfferDataset =
  | "finalPhysicalNotification"
  | "pair1"
  | "pair2"
  | "pair3"
  | "pair4"
  | "pair5"
  | "pairMinus1"
  | "pairMinus2"
  | "pairMinus3"
  | "pairMinus4"
  | "pairMinus5";

export const bidOfferDatasetDisplayName: { [key in BidOfferDataset]: string } =
  {
    finalPhysicalNotification: "Final Physical Notification (FPN)",
    pair1: "Pair 1",
    pair2: "Pair 2",
    pair3: "Pair 3",
    pair4: "Pair 4",
    pair5: "Pair 5",
    pairMinus1: "Pair -1",
    pairMinus2: "Pair -2",
    pairMinus3: "Pair -3",
    pairMinus4: "Pair -4",
    pairMinus5: "Pair -5",
  };

export const bidOfferDatasetDisplayOrder: BidOfferDataset[] = [
  "finalPhysicalNotification",
  "pair1",
  "pair2",
  "pair3",
  "pair4",
  "pair5",
  "pairMinus1",
  "pairMinus2",
  "pairMinus3",
  "pairMinus4",
  "pairMinus5",
];

export type BidOfferChartData = RangedChartData<BidOfferDataset>;

export const pointShapeForBidOfferDataset: {
  [key in BidOfferDataset]: PointShape;
} = {
  finalPhysicalNotification: PointShape.Circle,
  pair1: PointShape.Square,
  pair2: PointShape.Square,
  pair3: PointShape.Square,
  pair4: PointShape.Square,
  pair5: PointShape.Square,
  pairMinus1: PointShape.Diamond,
  pairMinus2: PointShape.Diamond,
  pairMinus3: PointShape.Diamond,
  pairMinus4: PointShape.Diamond,
  pairMinus5: PointShape.Diamond,
};
