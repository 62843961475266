import { BidOfferDataset } from "models/bidOffer/bidOfferChartData";
import { PhysicalChartDataset } from "models/bmuData/physicalChartData";
import { DisaggregatedBalancingServicesAdjustmentIds } from "models/disaggregatedBalancingServicesAdjustment/disaggregatedBalancingServicesAdjustmentSummaryModel";
import { IndicativeDemandPeakChartDataset } from "models/indicativePeakDemandChartData";
import { ForecastHorizon } from "models/lolpdrm/lolpdrmModel";
import { NetBalancingServicesAdjustmentIds } from "models/netBalancingServicesAdjustment/netBalancingServicesAdjustmentModel";
import { SystemPricesTooltip } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";

const colours = {
  elexonBlue: "#00008c",
  elexonGrey: "#d4cdc1",
  elexonGreen: "#21dbad",
  elexonYellow: "#ffd618",
  elexonBlack: "#000000",
  elexonPeach: "#f2a881",
  elexonLightBlue: "#a1c4e5",
  elexonPurple: "#64358c",
  elexonDarkTeal: "#1c454d",
  elexonRed: "#ff4040",
  elexonPink: "#e8308a",
  elexonForestGreen: "#008c32",
  elexonOrange: "#f68c00",
  focusBlue: "#236CC5",
  jungleGreen: "#23AB84",
  tropicalRainForest: "#0E7D5C",
  darkGrey: "#4a4a4a",
  charcoal: "#363636",
  mediumGrey: "#8D8D8D",
  lightGrey: "#F0F0F0",
  backgroundGrey: "#f6f6f6",
  highlightGreen: "#c8eccf",
  highlightGrey: "#C4C4C4",
  darkerDisabledGrey: "#666666",
  disabledGrey: "#B3B3B3",
  white: "#FFF",
  linkColor: "#485fc7",
  lightHighlightGreen: "#e0f4e4",
  lightHighlightGrey: "#d9d9d999",
  lightHighlightYellow: "#fff7d1",
  darkRed: "#880000",
  blueGrey: "#5097D5",
  fuchsia: "#C04E7A",
  dynamicPink: "#b25679",
  validGreen: "#0e7859",
  periwinkle: "#CCCCE7",
  pumpkin: "#E38956",
  grapePurple: "#5E3A87",
  pistachioGreen: "#7EC581",
  oceanGreen: "#52A886",
  darkOrange: "#EB7100",
  pinkRed: "#DC3545",
  transparent: "#00000000",
  whiteTranslucent: "#FFFFFFAA",
  goldYellow: "#FFF6CB",
  lightPink: "#FFE4E4",
  crimsonRed: "#BB0202",
};

export const chartColours = {
  referenceLine: colours.elexonGreen,

  rollingSystemDemandData: colours.elexonBlue,

  frequencyData: colours.elexonBlue,
  nationalGridFrequency: colours.lightHighlightGreen,
  statutoryFrequency: colours.lightHighlightYellow,

  temperatureData: colours.elexonBlue,
  temperatureReference: colours.highlightGreen,
  temperatureReferenceLighter: colours.lightHighlightGreen,
  temperatureReferenceNormal: colours.elexonBlack,

  surplus: colours.jungleGreen,
  margin: colours.elexonPurple,

  indo: colours.elexonPurple,
  itsdo: colours.jungleGreen,
  ndf: colours.elexonPurple,
  tsdf: colours.jungleGreen,

  indicatedMargin: colours.darkRed,
  indicatedGeneration: colours.blueGrey,
  indicatedImbalance: colours.elexonForestGreen,
  indicatedDemand: colours.fuchsia,
  nationalDemand: colours.elexonPurple,

  windGenerationOutturn: colours.elexonBlue,
  windGenerationLatestForecast: colours.elexonForestGreen,
  windGenerationInitialForecast: colours.fuchsia,

  nonBmStor: colours.elexonBlue,

  balancingServicesVolume: colours.elexonBlue,

  marketIndexPricesApx: colours.elexonPurple,
  marketIndexPricesN2ex: colours.jungleGreen,

  remitOutageProfile: colours.elexonBlue,

  atl: colours.elexonPurple,
  datl: colours.oceanGreen,

  watlMax: colours.elexonPurple,
  watlMin: colours.oceanGreen,

  agwsWindOnshore: colours.blueGrey,
  agwsWindOffshore: colours.elexonPurple,
  agwsSolar: colours.elexonForestGreen,

  dailyEnergyTransmittedOutturn: colours.elexonBlue,
};

export const fuelTypeColours = {
  biomass: "#925EB1",
  ccgt: "#C04E7A",
  coal: "#F08549",
  npshyd: "#F6D01B",
  nuclear: "#23AB84",
  ocgt: "#65C87A",
  oil: "#9DD978",
  other: "#5AC1ED",
  ps: "#417FB4",
  wind: "#2A4C77",
  intnem: "#F4E59E",
  intelec: "#E1BD86",
  intfr: "#CFACA4",
  intifa2: "#C6C7C6",
  intew: "#A2B2B5",
  intned: "#7AB1BA",
  intnsl: "#5A929B",
  intirl: "#968FC0",
  intvkl: "#A4CFAE",
  intgrnl: "#BFD690",
  // the next interconnector colour should be #C8AED8
};

export const psrTypeColours = {
  biomass: fuelTypeColours.biomass,
  fossilGas: fuelTypeColours.ccgt,
  fossilCoal: fuelTypeColours.coal,
  fossilOil: fuelTypeColours.oil,
  hydroPumpedStorage: fuelTypeColours.ps,
  hydroRunOfRiver: fuelTypeColours.npshyd,
  nuclear: fuelTypeColours.nuclear,
  other: "#6D6D6D",
  solar: "#8D0C0C",
  windOffshore: "#171796",
  windOnshore: "#31565D",
};

export const dgwsColours = {
  windOffshoreDayAhead: "#0C0C91",
  windOffshoreIntraday: "#4E4EAB",
  windOffshoreCurrent: "#8E8EC3",
  windOnshoreDayAhead: "#274E55",
  windOnshoreIntraday: "#547278",
  windOnshoreCurrent: "#869A9D",
  solarDayAhead: "#8D0C0C",
  solarIntraday: "#B16363",
  solarCurrent: "#C18E8E",
};

export const bidOfferDatasetColours: { [key in BidOfferDataset]: string } = {
  finalPhysicalNotification: colours.grapePurple,
  pair1: "#7EC581",
  pair2: "#B25679",
  pair3: "#E38956",
  pair4: "#F1CF4C",
  pair5: "#52A886",
  pairMinus1: "#73C0E9",
  pairMinus2: "#4F7FB0",
  pairMinus3: "#314C74",
  pairMinus4: "#4A4A4A",
  pairMinus5: "#AAD683",
};

export const physicalDatasetColours: { [key in PhysicalChartDataset]: string } =
  {
    PN: colours.elexonPurple,
    BOAL: colours.blueGrey,
    MELS: colours.jungleGreen,
    MILS: colours.pumpkin,
    SEL: colours.fuchsia,
    SIL: colours.darkRed,
    QPN: colours.linkColor,
  };

export const indicativeDemandPeakChartColours: {
  [key in IndicativeDemandPeakChartDataset]: string;
} = {
  dailyDemandPeak: colours.elexonBlue,
  peak1: "#D66717",
  peak2: "#008C32",
  peak3: "#B25679",
};

export const netbsadDatasetColours: {
  [key in NetBalancingServicesAdjustmentIds]: string;
} = {
  buyPriceAdjustment: colours.elexonPurple,
  sellPriceAdjustment: colours.jungleGreen,
};

export const disbsadDatasetColours: {
  [key in DisaggregatedBalancingServicesAdjustmentIds]: string | null;
} = {
  buyActionCount: null,
  sellActionCount: null,
  buyPriceAdjustment: colours.elexonForestGreen,
  buyPriceMinimum: colours.elexonForestGreen,
  buyPriceMaximum: colours.elexonForestGreen,
  buyPriceAverage: colours.elexonForestGreen,
  sellPriceAdjustment: colours.pumpkin,
  sellPriceMinimum: colours.pumpkin,
  sellPriceMaximum: colours.pumpkin,
  sellPriceAverage: colours.pumpkin,
  buyVolumeAdjustment: colours.elexonForestGreen,
  sellVolumeAdjustment: colours.pumpkin,
  netVolumeAdjustment: colours.elexonBlue,
};

export const lolpdrmColours: {
  [key in ForecastHorizon]: string | null;
} = {
  Forecast12h: colours.elexonPurple,
  Forecast8h: colours.pistachioGreen,
  Forecast4h: colours.dynamicPink,
  Forecast2h: colours.blueGrey,
  Forecast1h: colours.elexonBlue,
};

export const systemPricesColours: {
  [key in SystemPricesTooltip]: string;
} = {
  systemSellPrice: colours.elexonBlue,
  netImbalanceVolume: colours.elexonOrange,
  bsadDefaulted: colours.dynamicPink,
  sellPriceAdjustment: colours.blueGrey,
  buyPriceAdjustment: colours.jungleGreen,
  totalAcceptedOfferVolume: colours.elexonBlue,
  totalAcceptedBidVolume: colours.elexonPurple,
  totalAdjustmentSellVolume: colours.elexonForestGreen,
  totalAdjustmentBuyVolume: colours.fuchsia,
};

export default colours;
