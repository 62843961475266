import classnames from "classnames";
import { Granularity } from "models/Granularity";
import React from "react";

import { GranularityButton } from "./style";

interface Props {
  granularity: Granularity;
  setGranularity: (newGranularity: Granularity) => void;
  isZonalDisabled?: boolean;
}

const GranularitySelector: React.FC<Props> = ({
  granularity,
  setGranularity,
  isZonalDisabled,
}: Props) => (
  <div data-test-id="data-granularity">
    <GranularityButton
      onClick={(): void => setGranularity(Granularity.National)}
      className={classnames("filter", {
        active: granularity === Granularity.National,
      })}
      buttonText="National"
    />
    <GranularityButton
      onClick={(): void => setGranularity(Granularity.Zonal)}
      className={classnames("filter", {
        active: granularity === Granularity.Zonal,
      })}
      buttonText="Zonal"
      disabled={isZonalDisabled}
    />
  </div>
);

export default GranularitySelector;
