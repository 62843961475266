import { fullWidthStyles } from "components/components/FullWidthSection/style";
import styled from "styled-components";

export const StyledDiv = styled.div`
  > div {
    padding-bottom: 10px;
  }
`;

export const WideTabContainer = styled.div`
  ${fullWidthStyles};
  padding: 0 2.5%;
`;

export const ActionTypeFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 20rem;
  padding-top: 2rem;
`;
