import { WithoutId } from "components/components/SortableTable/SortableTable";

import { addMinsToDate } from "./dateHelpers";

export type ModelWithTime =
  | { timeFrom: string }
  | { startTime: string }
  | { time: string };

export const filterHiddenColumns = <TItem>(
  columns: Array<keyof WithoutId<TItem>>,
  hiddenColumns: (keyof WithoutId<TItem>)[] = []
): Array<keyof WithoutId<TItem>> =>
  columns.filter(
    (key) => !hiddenColumns.find((excludeKey) => key === excludeKey)
  );

const selectTimeStringFromRow = <T extends ModelWithTime>(row: T): string => {
  if ("timeFrom" in row) {
    return row.timeFrom;
  }
  if ("startTime" in row) {
    return row.startTime;
  }
  if ("time" in row) {
    return row.time;
  }
  return "";
};

export const filterRawModelToSettlementPeriod = <T extends ModelWithTime>(
  settlementPeriodDatetime: Date,
  tableData: T[]
): T[] =>
  tableData.filter((row) => {
    const time = new Date(selectTimeStringFromRow(row));
    return (
      time >= settlementPeriodDatetime &&
      time < addMinsToDate(settlementPeriodDatetime, 30)
    );
  });

export const roundToMax4dp = (value: number | null): number | null => {
  if (value === null) {
    return null;
  }
  const roundedValue = value.toFixed(4);
  const originalValue = value.toString();
  return roundedValue.length <= originalValue.length
    ? parseFloat(roundedValue)
    : parseFloat(originalValue);
};
