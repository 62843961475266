import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { MarketIndexPriceModel } from "models/marketIndexPrices/marketIndexPricesModel";

export const getMarketIndexPricesDownloadData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<MarketIndexPriceModel>> => {
  const { data } = await getData(
    "/balancing/pricing/market-index",
    {
      from: dateFilter.startDate,
      to: dateFilter.endDate,
    },
    format
  );
  return data;
};

export const getMarketIndexPricesChartData = async (
  dateFilter: DateFilterModel
): Promise<MarketIndexPriceModel[]> => {
  const { data } = (await getMarketIndexPricesDownloadData(
    dateFilter
  )) as JsonDataDownloadModel<MarketIndexPriceModel>;
  return data;
};
