import { getData } from "api/dataVisualisation/client";
import { Granularity } from "models/Granularity";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import { DemandForecastDaily } from "models/demandForecast/demandForecastDaily";
import { DemandForecastDayAhead } from "models/demandForecast/demandForecastDayAhead";
import { DemandForecastWeekly } from "models/demandForecast/demandForecastWeekly";
import { InitialDemandOutturnModel } from "models/demandOutturn/InitialDemandOutturnModel";
import {
  addDaysToDate,
  endOfSettlementDay,
  startOfSettlementDay,
} from "utils/dateHelpers";

export const getDemandForecastDayAheadData = async (
  boundary: Granularity,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<DemandForecastDayAhead>> => {
  const { data } = await getData(
    "/forecast/demand/day-ahead",
    {
      boundary,
    },
    format
  );
  return data;
};

export const getDemandForecastDayAheadNationalJsonData = async (): Promise<
  JsonDataDownloadModel<DemandForecastDayAhead>
> =>
  getDemandForecastDayAheadData(
    Granularity.National,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<DemandForecastDayAhead>>;

export const getDemandForecastDayAheadLatestData = async (
  boundary?: string,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<DemandForecastDayAhead>> => {
  // Day-day ahead is from the point of view of the UK days, not UTC.
  const startOfToday = startOfSettlementDay();
  const endOfOvermorrow = endOfSettlementDay(addDaysToDate(new Date(), 2));

  const { data } = await getData(
    "/forecast/demand/day-ahead/latest",
    {
      from: startOfToday.toISOString(),
      to: endOfOvermorrow.toISOString(),
      boundary,
    },
    format
  );
  return data;
};

export const getDemandForecastDayAheadLatestNationalJsonData =
  async (): Promise<JsonDataDownloadModel<DemandForecastDayAhead>> =>
    getDemandForecastDayAheadLatestData(
      Granularity.National,
      DataDownloadFormat.Json
    ) as Promise<JsonDataDownloadModel<DemandForecastDayAhead>>;

export const getDemandForecastDailyData = async (
  format?: DataDownloadFormat
): Promise<DataDownloadModel<DemandForecastDaily>> => {
  const { data } = await getData("/forecast/demand/daily", undefined, format);
  return data;
};

export const getDemandForecastDailyJsonData = async (): Promise<
  JsonDataDownloadModel<DemandForecastDaily>
> =>
  getDemandForecastDailyData(DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<DemandForecastDaily>
  >;

export const getDemandForecastWeeklyData = async (
  format?: DataDownloadFormat
): Promise<DataDownloadModel<DemandForecastWeekly>> => {
  const { data } = await getData("/forecast/demand/weekly", undefined, format);
  return data;
};

export const getDemandForecastWeeklyJsonData = async (): Promise<
  JsonDataDownloadModel<DemandForecastWeekly>
> =>
  getDemandForecastWeeklyData(DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<DemandForecastWeekly>
  >;

export const getDemandForecastPeakData = async (
  format?: DataDownloadFormat
): Promise<DataDownloadModel<DemandForecastDayAhead>> => {
  const { data } = await getData(
    "/forecast/demand/day-ahead/peak",
    undefined,
    format
  );
  return data;
};

export const getDemandForecastPeakJsonData = async (): Promise<
  JsonDataDownloadModel<DemandForecastDayAhead>
> =>
  getDemandForecastPeakData(DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<DemandForecastDayAhead>
  >;

export const getDemandPeakData = async (
  format?: DataDownloadFormat
): Promise<DataDownloadModel<InitialDemandOutturnModel>> => {
  const { data } = await getData("/demand/peak", undefined, format);
  return data;
};

export const getDemandPeakJsonData = async (): Promise<
  JsonDataDownloadModel<InitialDemandOutturnModel>
> =>
  getDemandPeakData(DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<InitialDemandOutturnModel>
  >;
