import { getAllBmUnits } from "api/dataVisualisation/reference/reference";
import {
  formatOptionLabel,
  Input,
  LoadingIndicator,
  MenuList,
  ValueContainer,
  placeholder,
} from "components/components/balancingMechanism/bmuView/BmuFuzzySearchBar/customReactSelectComponents";
import {
  augmentTheme,
  customStyles,
  DropdownContainer,
} from "components/components/balancingMechanism/bmuView/BmuFuzzySearchBar/style";
import useRequest, { RequestStatus } from "hooks/useRequest";
import React, { useEffect, useMemo, useState } from "react";
import Select, { NoticeProps, components, Theme } from "react-select";
import { InputActionMeta } from "react-select/dist/declarations/src/types";
import {
  BmuOption,
  fuzzyBmuFilter,
  getOptionLabel,
  getOptionValue,
  IsMulti,
  mapModelsToOptions,
} from "utils/fuzzyBmuDataHelpers";

const MAX_BMUS_IN_LIST = 100;

interface Props {
  selectedBmu: BmuOption | null;
  setSelectedBmu: (bmu: BmuOption | null) => void;
}

const BmuFuzzySearchBar: React.FC<Props> = ({
  selectedBmu,
  setSelectedBmu,
}) => {
  const [filteredOptions, setFilteredOptions] = useState<BmuOption[]>([]);
  const [filterString, setFilterString] = useState<string>("");

  const {
    data: rawData,
    status,
    request: fetchData,
  } = useRequest(getAllBmUnits);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const allOptions = useMemo(() => mapModelsToOptions(rawData), [rawData]);

  useEffect(
    () => setFilteredOptions(fuzzyBmuFilter(allOptions, filterString)),
    [allOptions, filterString]
  );

  const onInputChange = (input: string, { action }: InputActionMeta): void => {
    if (action === "input-change") {
      // only set the input when the action that caused the
      // change equals to "input-change" and ignore the other
      // ones like: "set-value", "input-blur", and "menu-close"
      setFilteredOptions([]);
      // By clearing the filteredOptions array when user input is changed,
      // react-select defaults to focusing on the first option in the dropdown list
      // instead of remembering which option was previously focused.
      document
        .getElementsByClassName("bmu-result-row")[0]
        ?.scrollIntoView({ block: "nearest" });
      // This ensures that if the user has scrolled down the menu and then
      // types a new character the menu scrolls up to the top again so they
      // can see the best match
      setFilterString(input);
    }
  };

  const getMessageForStatus = (requestStatus: RequestStatus): string => {
    switch (requestStatus) {
      case RequestStatus.IN_PROGRESS:
        return "Loading...";
      case RequestStatus.ERRORED:
        return "An error occurred, please refresh the page.";
      default:
        return "No BM Units match the search criteria.";
    }
  };

  const NoOptionsMessage = (
    props: NoticeProps<BmuOption, IsMulti>
  ): JSX.Element => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.NoOptionsMessage {...props}>
      {getMessageForStatus(status)}
    </components.NoOptionsMessage>
  );

  return (
    <DropdownContainer data-test-id="fuzzy-bmu-select-container">
      <Select
        theme={(theme): Theme =>
          augmentTheme(theme, status, selectedBmu === null)
        }
        styles={customStyles}
        options={filteredOptions.slice(0, MAX_BMUS_IN_LIST)}
        components={{
          Input,
          MenuList,
          LoadingIndicator,
          NoOptionsMessage,
          ValueContainer,
          DropdownIndicator: null,
          IndicatorSeparator: null,
        }}
        inputValue={filterString}
        formatOptionLabel={formatOptionLabel}
        placeholder={placeholder}
        isLoading={status === RequestStatus.IN_PROGRESS}
        onInputChange={onInputChange}
        filterOption={null} // Fuse will have already done the filtering so react-select should show all results
        onChange={(b): void => {
          setFilterString("");
          setSelectedBmu(b);
        }}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
      />
    </DropdownContainer>
  );
};

export default BmuFuzzySearchBar;
