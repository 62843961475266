import { getTemperatureJsonData } from "api/dataVisualisation/temperature/temperature";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { TemperatureModel } from "models/temperature/temperatureModel";
import { useCallback } from "react";

const useTemperatureChartContainerData = (
  dateFilter: DateFilterModel
): {
  fetchData: () => void;
  data: TemperatureModel[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchTemperatureData,
  } = useRequest(getTemperatureJsonData);

  const fetchData = useCallback(() => {
    fetchTemperatureData(dateFilter);
  }, [dateFilter, fetchTemperatureData]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useTemperatureChartContainerData;
