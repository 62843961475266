import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { TabulatedChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/TabulatedChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScaleWithDumbbellPadding,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayersWithDGWSDumbbells } from "styles/chartStyles/chartLayers";
import { dateFormatter, defaultColumnFormatter } from "utils/ChartDataUtils";
import { formatAsTimeWithDatesAndTodayMarked } from "utils/chartHelpers";
import { DateFormat } from "utils/dateHelpers";

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  dataFetchStatus: RequestStatus;
  rerequestData: () => void;
  dateRange: DateFilterModel;
}

const GenerationForecastForWindAndSolarChart: React.FC<Props> = ({
  series,
  datasetCategories,
  dataFetchStatus,
  rerequestData,
  dateRange,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    const tooltipConfig: ChartConfiguration = {
      datasetCategories,
      tooltipColumns: [
        {
          id: "value",
          dataKey: "y",
          formatter: defaultColumnFormatter({ suffix: "MW" }),
        },
        {
          id: "publishTime",
          title: "Publish Time",
          dataKey: "publishTime",
          formatter: dateFormatter(DateFormat.DateTime),
        },
      ],
    };

    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        axisBottom={chartAxisBottom(
          "Start Time (UTC)",
          series,
          isChartLoaded,
          isChartLoaded ? "every 2 hours" : [],
          formatAsTimeWithDatesAndTodayMarked
        )}
        axisLeft={chartAxisLeft("MW", isChartLoaded)}
        layers={chartLayersWithDGWSDumbbells}
        enableSlices="x"
        sliceTooltip={(input): JSX.Element => (
          <TabulatedChartTooltip
            series={series}
            config={tooltipConfig}
            text={{
              label: "Start Time",
            }}
            options={{
              interactionType: TooltipInteractionType.Slice,
            }}
            inputPoints={input.slice.points}
          />
        )}
        xScale={chartXTimeScaleWithDumbbellPadding(dateRange)}
        yScale={nonStackedLineChartYScale(series)}
      />
    );
  }, [dataFetchStatus, dateRange, series, datasetCategories]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      >
        <>{memoisedChart}</>
      </ChartWithErrorHandling>
    ),
    [dataFetchStatus, datasetCategories, memoisedChart, rerequestData, series]
  );
};

export default GenerationForecastForWindAndSolarChart;
