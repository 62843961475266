import styled from "styled-components";
import colours from "styles/colours";

export const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  align-items: center;
  justify-content: space-between;
  height: 80%;
  aspect-ratio: 3 / 2;

  &.loading {
    display: flex;
  }

  > & + & {
    margin-left: 100%;
  }
`;

export const Bar = styled.div`
  display: block;
  height: 50%;
  width: 12%;

  &.bar1 {
    background-color: ${colours.elexonBlue};
    animation: bar-animation 1.5s linear 0s infinite;
  }

  &.bar2 {
    background-color: ${colours.elexonPurple};
    animation: bar-animation 1.5s linear 0.2s infinite;
  }

  &.bar3 {
    background-color: ${colours.elexonGreen};
    animation: bar-animation 1.5s linear 0.4s infinite;
  }

  &.bar4 {
    background-color: ${colours.elexonPurple};
    animation: bar-animation 1.5s linear 0.6s infinite;
  }

  &.bar5 {
    background-color: ${colours.elexonBlue};
    animation: bar-animation 1.5s linear 0.8s infinite;
  }

  @keyframes bar-animation {
    0%,
    40%,
    100% {
      height: 50%;
    }
    20% {
      height: 100%;
    }
  }
`;

export const ChildContainer = styled.div`
  opacity: 1;
  animation: 0.5s linear;

  &.loading {
    opacity: 0;
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 25%;
  width: 25%;
  text-align: center;

  &.large {
    height: 40%;
  }
`;
