import styled from "styled-components";
import colours from "styles/colours";

export const StyledExitCross = styled.div`
  padding-top: 7px;
  font-weight: bold;
  text-align: right;

  .button {
    background-color: ${colours.lightGrey};
    border: 0px;
    width: 20px;
    height: 20px;

    :focus {
      border: 1px;
    }
  }
`;
