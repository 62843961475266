import {
  createNewUpdateToast,
  dismissNewUpdateToast,
} from "components/components/NewUpdateToast/NewUpdateToast";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import routeConstants from "utils/routeConstants";

export const NewUpdateToastManager: React.FC = () => {
  const currentPath = useLocation().pathname;

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      if (currentPath !== routeConstants.whatsNew) {
        createNewUpdateToast();
      }
      firstRender.current = false;
    } else if (currentPath === routeConstants.whatsNew) {
      dismissNewUpdateToast();
    }
  }, [currentPath]);

  return <></>;
};
