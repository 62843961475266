import { Datum, Serie } from "@nivo/line";
import {
  NetBalancingServicesAdjustmentIds,
  NetBalancingServicesAdjustmentModel,
} from "models/netBalancingServicesAdjustment/netBalancingServicesAdjustmentModel";
import { netbsadDatasetColours } from "styles/colours";
import { sortByStartTime } from "utils/ChartDataUtils";

const mapToBuyPriceAdjustmentDatum = (
  rawData: NetBalancingServicesAdjustmentModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.buyPricePriceAdjustment,
  settlementPeriod: rawData.settlementPeriod,
});

const mapToSellPriceAdjustmentDatum = (
  rawData: NetBalancingServicesAdjustmentModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.sellPricePriceAdjustment,
  settlementPeriod: rawData.settlementPeriod,
});

export const transformNetBalancingServicesAdjustmentModelPriceAdjustmentData = (
  rawData: NetBalancingServicesAdjustmentModel[]
): Serie[] => {
  if (!rawData.length) {
    return [];
  }

  return [
    {
      id: NetBalancingServicesAdjustmentIds.BuyPriceAdjustment,
      colour:
        netbsadDatasetColours[
          NetBalancingServicesAdjustmentIds.BuyPriceAdjustment
        ],
      data: sortByStartTime(rawData).map(mapToBuyPriceAdjustmentDatum),
    },
    {
      id: NetBalancingServicesAdjustmentIds.SellPriceAdjustment,
      colour:
        netbsadDatasetColours[
          NetBalancingServicesAdjustmentIds.SellPriceAdjustment
        ],
      data: sortByStartTime(rawData).map(mapToSellPriceAdjustmentDatum),
    },
  ];
};
