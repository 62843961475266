import Checkbox from "components/components/CheckboxInput/Checkbox";
import {
  CheckboxDataState,
  CheckboxState,
} from "components/components/CheckboxList/CheckboxListContainer";
import ChevronCheckboxButton from "components/components/ChevronCheckboxButton/ChevronCheckboxButton";
import { ListClickEvent, ListClickType } from "hooks/useCheckboxList";
import React from "react";

import { CheckboxContainer, DataGroupName, List, ListElement } from "./style";

export type CheckboxDataGroup = {
  id: number;
  name: string;
  parentId: number | null;
  datasets?: string[];
};

type CheckboxListProps = {
  checkboxDataState: CheckboxDataState[];
  onClick?: (clickEvent: ListClickEvent) => void;
  idsToRender?: number[];
  indentLevel?: number;
};

const CheckboxList: React.FC<CheckboxListProps> = ({
  checkboxDataState,
  onClick,
  idsToRender = [],
  indentLevel = 0,
}) => {
  if (!idsToRender.length) {
    // eslint-disable-next-line no-param-reassign
    idsToRender = Object.values(checkboxDataState)
      .filter((val) => val.parentId === null)
      .map((val) => val.id);
  }

  return (
    <List style={{ paddingLeft: indentLevel * 30 }}>
      {idsToRender.map((id) => {
        const checkboxDataItem = checkboxDataState[id];
        const childCheckboxDataItems = Object.values(checkboxDataState).filter(
          (val) => val.parentId === id
        );

        return (
          <ListElement key={id}>
            <CheckboxContainer>
              <Checkbox
                inputId={checkboxDataItem.id.toString()}
                ariaLabelText={checkboxDataItem.name}
                isChecked={
                  checkboxDataItem.checkboxState === CheckboxState.CHECKED
                }
                isIndeterminate={
                  checkboxDataItem.checkboxState === CheckboxState.INDETERMINATE
                }
                onChange={(): void =>
                  onClick?.({
                    id: checkboxDataItem.id,
                    clickType: ListClickType.CHECKBOX,
                  })
                }
              />
              <DataGroupName
                onClick={(): void =>
                  onClick?.({ id, clickType: ListClickType.CHECKBOX })
                }
              >
                {checkboxDataItem.name}
              </DataGroupName>
              {childCheckboxDataItems.length > 0 && (
                <ChevronCheckboxButton
                  onClick={onClick}
                  checkboxDataItem={checkboxDataItem}
                >
                  <CheckboxList
                    onClick={onClick}
                    idsToRender={childCheckboxDataItems.map((i) => i.id)}
                    indentLevel={indentLevel + 1}
                    checkboxDataState={checkboxDataState}
                  />
                </ChevronCheckboxButton>
              )}
            </CheckboxContainer>
          </ListElement>
        );
      })}
    </List>
  );
};

export default CheckboxList;
