import ResultsCount from "components/components/ResultsCount/ResultsCount";
import {
  StyledTimeOnly,
  StyledBoolean,
} from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
} from "components/components/SortableTable/SortableTable";
import TabContentWithDescription from "components/components/Tabs/TabContentWithDescription/TabContentWithDescription";
import TabContentWithErrorHandling from "components/components/Tabs/TabContentWithErrorHandling/TabContentWithErrorHandling";
import PhysicalModal from "components/components/balancingMechanism/balancingMechanismTabs/descriptionModals/PhysicalModal";
import { DatasetDropdown } from "components/components/balancingMechanism/marketView/DatasetDropdown/DatasetDropdown";
import {
  buildBmuViewQueryParamsWithoutBmuId,
  getBmUnitLinkRenderFn,
} from "components/dataVisualization/balancingMechanismTabs/utils";
import { useMarketViewContext } from "contexts/MarketViewContext";
import {
  PhysicalTabDataset,
  PhysicalTableModel,
  PhysicalTableHeaders,
  PhysicalTabDataStructure,
  BidOfferAcceptanceTableHeaders,
  BidOfferAcceptanceTableModel,
} from "models/bmuData/marketViewPhysicalTabModels";
import { useEffect, useState } from "react";

type DatasetOption = {
  value: PhysicalTabDataset;
  label: string;
};

const options: DatasetOption[] = [
  { value: "BOAL", label: "Bid offer acceptance level (BOAL)" },
  { value: "PN", label: "Final physical notification (FPN)" },
  { value: "MELS", label: "Maximum export limit (MEL)" },
  { value: "MILS", label: "Maximum import limit (MIL)" },
  { value: "QPN", label: "Quiescent physical notification (QPN)" },
];

const timeRangeFunctions: Partial<
  Record<
    keyof PhysicalTableModel,
    <T extends PhysicalTableModel>(el: T) => TableCellRender
  >
> = {
  timeFrom: ({ timeFrom }): TableCellRender => ({
    content: StyledTimeOnly(timeFrom),
  }),
  timeTo: ({ timeTo }): TableCellRender => ({
    content: StyledTimeOnly(timeTo),
  }),
};

const bidOfferAcceptanceFunctions = (): Partial<
  Record<
    keyof BidOfferAcceptanceTableModel,
    (el: BidOfferAcceptanceTableModel) => TableCellRender
  >
> => ({
  ...timeRangeFunctions,
  acceptTime: ({ acceptTime }): TableCellRender => ({
    content: StyledTimeOnly(acceptTime),
  }),
  deemedFlag: ({ deemedFlag }): TableCellRender => ({
    content: StyledBoolean(deemedFlag),
  }),
  soFlag: ({ soFlag }): TableCellRender => ({
    content: StyledBoolean(soFlag),
  }),
  storFlag: ({ storFlag }): TableCellRender => ({
    content: StyledBoolean(storFlag),
  }),
  rrFlag: ({ rrFlag }): TableCellRender => ({
    content: StyledBoolean(rrFlag),
  }),
});

interface TableProps {
  data: Partial<PhysicalTabDataStructure>;
  dataset: PhysicalTabDataset;
}

const DataTable: React.FC<TableProps> = ({ data, dataset }) => {
  const { selectedDate } = useMarketViewContext();
  const urlParamsWithoutBmuIdPhysical = buildBmuViewQueryParamsWithoutBmuId(
    selectedDate,
    "Physical"
  );

  const urlParamsWithoutBmuIdBidOffer = buildBmuViewQueryParamsWithoutBmuId(
    selectedDate,
    "Bid Offer"
  );

  if (data[dataset]?.length === 0) {
    return <></>;
  }

  if (dataset === "BOAL") {
    return (
      <SortableTable
        key={dataset}
        headers={BidOfferAcceptanceTableHeaders}
        items={data[dataset] as BidOfferAcceptanceTableModel[]}
        renderFns={{
          ...bidOfferAcceptanceFunctions(),
          bmUnit: ({ bmUnit }): TableCellRender => ({
            content: getBmUnitLinkRenderFn(
              bmUnit,
              urlParamsWithoutBmuIdBidOffer
            ),
          }),
        }}
      />
    );
  }

  return (
    <SortableTable
      key={dataset}
      headers={PhysicalTableHeaders}
      items={data[dataset] as PhysicalTableModel[]}
      renderFns={{
        ...timeRangeFunctions,
        bmUnit: ({ bmUnit }): TableCellRender => ({
          content: getBmUnitLinkRenderFn(bmUnit, urlParamsWithoutBmuIdPhysical),
        }),
      }}
    />
  );
};

const MarketViewPhysical: React.FC = () => {
  const [dataset, setDataset] = useState<PhysicalTabDataset>("PN");
  const {
    physicalDetails: { data, status, request },
  } = useMarketViewContext();

  useEffect(() => {
    if (!data || !data[dataset]) {
      request(dataset);
    }
  }, [dataset, request, data]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const modal = (
    <PhysicalModal
      isOpen={modalIsOpen}
      close={(): void => setModalIsOpen(false)}
      shouldDisplayStableLimits={false}
    />
  );

  return (
    <TabContentWithDescription
      description={`
          The Physical Data available is the Final Physical Notification (FPN),
          Quiescent Physical Notification (QPN), Maximum Export Limit (MEL),
          Maximum Import Limit (MIL), and Bid-Offer Acceptance Level (BOAL)`}
      modal={modal}
      openModal={(): void => setModalIsOpen(true)}
    >
      <>
        <DatasetDropdown
          options={options}
          dataset={dataset}
          setDataset={setDataset}
        />
        <TabContentWithErrorHandling
          status={status}
          fetchData={(): void => request(dataset)}
        >
          <>
            {data && data[dataset] && (
              <>
                <ResultsCount count={data[dataset]?.length ?? 0} />
                <DataTable data={data} dataset={dataset} />
              </>
            )}
          </>
        </TabContentWithErrorHandling>
      </>
    </TabContentWithDescription>
  );
};
export default MarketViewPhysical;
