import { ResponsiveLine, Serie } from "@nivo/line";
import { StyledChartContainer } from "components/dataVisualization/chartComponents/ChartContainer/style";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { StandardChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/StandardChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import { DatasetCategory } from "models/chartConfiguration/chartDatasetModel";
import { ChartDatasetTooltipModel } from "models/chartConfiguration/tooltipModels";
import DateFilterModel from "models/filterModels/dateFilterModel";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayersWithFixedLengthReferenceLine } from "styles/chartStyles/chartLayers";
import { chartColours } from "styles/colours";

interface Props {
  series: Serie[];
  dataFetchStatus: RequestStatus;
  dateFilter: DateFilterModel;
}

const datasetCategories: DatasetCategory<ChartDatasetTooltipModel>[] = [
  {
    datasets: [
      {
        colour: chartColours.balancingServicesVolume,
        dataKey: "balancingServicesVolume",
        displayName: "Balancing services volume",
        unitSuffix: "MWh",
      },
    ],
    id: "datasets",
  },
];

const BalancingServicesVolumeChart: React.FC<Props> = ({
  series,
  dataFetchStatus,
  dateFilter,
}) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;
    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        enableSlices="x"
        enablePoints
        pointLabelYOffset={0}
        pointSize={7}
        axisBottom={chartAxisBottom("Start Time (UTC)", series, isChartLoaded)}
        axisLeft={chartAxisLeft("MWh", isChartLoaded)}
        layers={chartLayersWithFixedLengthReferenceLine(
          0,
          chartColours.referenceLine,
          dateFilter.normalisedStartDate,
          dateFilter.normalisedEndDate
        )}
        sliceTooltip={(input): JSX.Element => (
          <StandardChartTooltip
            series={series}
            datasetCategories={datasetCategories}
            text={{
              label: "Start Time",
            }}
            options={{
              interactionType: TooltipInteractionType.Slice,
            }}
            inputPoints={input.slice.points}
          />
        )}
        xScale={chartXTimeScale()}
        yScale={nonStackedLineChartYScale(series, { centre: 0 })}
      />
    );
  }, [dataFetchStatus, dateFilter, series]);

  return (
    <StyledChartContainer data-test-id="chart-container">
      {memoisedChart}
    </StyledChartContainer>
  );
};

export default BalancingServicesVolumeChart;
