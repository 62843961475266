import React from "react";

import { RadioLabel, CustomRadio, NativeRadioInput, RadioText } from "./style";

export type RadioProps = {
  inputId: string;
  ariaLabelText?: string;
  isChecked?: boolean;
  isInline?: boolean;
  onChange?: () => void;
  inlineLabelContent?: string | JSX.Element;
};

const Radio: React.FC<RadioProps> = ({
  inputId,
  ariaLabelText,
  isChecked = false,
  isInline = false,
  onChange,
  inlineLabelContent,
}) => (
  <>
    <RadioLabel htmlFor={inputId} isInline={isInline}>
      <NativeRadioInput
        id={inputId}
        aria-label={ariaLabelText}
        type="radio"
        onChange={onChange}
        checked={isChecked}
        isInline={isInline}
      />
      <CustomRadio isChecked={isChecked} isInline={isInline} />
      <RadioText>{inlineLabelContent}</RadioText>
    </RadioLabel>
  </>
);

export default Radio;
