import axios, { AxiosResponse } from "axios";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { OutturnSummaryModel } from "models/outturnSummaryModel";
import { batchBmuIds, stitchDataResults } from "utils/dataStitchHelpers";
import {
  addDaysToDate,
  addSecondsToDate,
  DAY_IN_MS,
  getEarlier,
  startOfDay,
  toDateOnlyString,
} from "utils/dateHelpers";

type QueryParams = Record<
  string,
  string | number | number[] | boolean | undefined | string[]
>;

export const getData = async (
  endpoint: string,
  params?: object,
  format: DataDownloadFormat = DataDownloadFormat.Json
): Promise<AxiosResponse> =>
  axios.get(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
    params: { format, ...params },
    paramsSerializer: {
      indexes: null,
    },
  });

export const getStitchedBmusData = async <T>(
  endpoint: string,
  params: object,
  bmUnits?: string[],
  format: DataDownloadFormat = DataDownloadFormat.Json
): Promise<DataDownloadModel<T>> => {
  const requests: Promise<AxiosResponse>[] = bmUnits
    ? batchBmuIds(bmUnits).map((bmuIdBatch) => {
        return getData(
          endpoint,
          {
            ...params,
            bmUnit: bmuIdBatch,
          },
          format
        ).then((result) => result?.data);
      })
    : [
        getData(
          endpoint,
          {
            ...params,
          },
          format
        ).then((result) => result?.data),
      ];

  const results = await Promise.all(requests);
  return stitchDataResults(results, format);
};

/** For querying endpoints over a longer time period than their individual time range limit
 * @param {string} endpoint The endpoint string, e.g. "/REMIT/list/by-publish"
 * @param {Object} dateParamNames For specifying the names of the query parameters
 * @param {DateFilterModel} dateFilter The date range the query is between
 * @param {number} maxDays The maximum days per individual query
 * @param {QueryParams} staticParams Query parameters for each request
 * @param {DataDownloadFormat} format The data format for the request
 * @param {boolean} dateOnly True to strip the time data from the request query params
 */
export const getStitchedData = async <T>(
  endpoint: string,
  dateParamNames: { from: string; to: string },
  dateFilter: DateFilterModel,
  maxDays: number,
  staticParams: QueryParams = {},
  format: DataDownloadFormat = DataDownloadFormat.Json,
  dateOnly: boolean = false
): Promise<DataDownloadModel<T>> => {
  const dayRange =
    (dateFilter.endDate.getTime() - dateFilter.startDate.getTime()) / DAY_IN_MS;
  const numberOfRequestsRequired = Math.ceil(dayRange / maxDays);

  const requests: Promise<AxiosResponse>[] = [
    ...Array(numberOfRequestsRequired).keys(),
  ].map((i) => {
    const baseFromDate = addDaysToDate(dateFilter.startDate, i * maxDays);
    const baseToDate = getEarlier(
      addDaysToDate(baseFromDate, maxDays),
      dateFilter.endDate
    );

    const fromDate = dateOnly ? startOfDay(baseFromDate) : baseFromDate;
    const toDate = dateOnly ? startOfDay(baseToDate) : baseToDate;

    const toDateWithoutOverlap =
      i < numberOfRequestsRequired - 1 ? addSecondsToDate(toDate, -1) : toDate;

    return getData(
      endpoint,
      {
        [dateParamNames.from]: dateOnly
          ? toDateOnlyString(fromDate)
          : fromDate.toISOString(),
        [dateParamNames.to]: dateOnly
          ? toDateOnlyString(toDateWithoutOverlap)
          : toDateWithoutOverlap.toISOString(),
        ...staticParams,
      },
      format
    ).then((result) => result?.data);
  });

  const results = await Promise.all(requests);
  return stitchDataResults(results, format);
};

export const getDataset = async <T>(
  format: DataDownloadFormat,
  dataset: string,
  queryParams: QueryParams = {}
): Promise<DataDownloadModel<T>> => {
  const { data } = await getData(`/datasets/${dataset}`, queryParams, format);
  return data;
};

export const getDatasetByPublishTimes = async <T>(
  dateFilter: DateFilterModel,
  format: DataDownloadFormat,
  dataset: string,
  maxDaysPerQuery: number,
  additionalParams: QueryParams = {}
): Promise<DataDownloadModel<T>> =>
  getStitchedData<T>(
    `/datasets/${dataset}`,
    { from: "PublishDateTimeFrom", to: "PublishDateTimeTo" },
    dateFilter,
    maxDaysPerQuery,
    additionalParams,
    format
  );

export const getDatasetOrderedByWeekAndYear = async <T>(
  format: DataDownloadFormat,
  dataset: string,
  additionalParams: QueryParams = {}
): Promise<DataDownloadModel<T>> =>
  getDataset<T>(format, dataset, {
    OrderBy: "Year, CalendarWeekNumber",
    ...additionalParams,
  });

export const getGenerationOutturnSummaryData = async (
  dateFilter: DateFilterModel,
  IncludeNegativeGeneration: boolean
): Promise<OutturnSummaryModel[]> => {
  const { data } = await getData("/generation/outturn/summary", {
    StartTime: dateFilter.normalisedStartDate.toISOString(),
    EndTime: dateFilter.normalisedEndDate.toISOString(),
    IncludeNegativeGeneration,
  });
  return data;
};

export const mapDataToCsv = async <T, D>(
  data: Promise<JsonDataDownloadModel<T>>,
  mapData: (nestedData: T[]) => D[],
  ignoreProperties: (keyof D)[]
): Promise<DataDownloadModel<T>> => {
  const { data: dataList } = await data;

  const mappedData = mapData(dataList);

  const updatedList = mappedData.map((item: D) => {
    const filteredItem = Object.fromEntries(
      // @ts-ignore
      Object.entries(item).filter(([key]) => !ignoreProperties.includes(key))
    );
    return filteredItem;
  });

  const headers = Object.keys(updatedList[0]);
  const rows = updatedList.map((obj) => Object.values(obj).join(","));

  return [headers.join(","), ...rows].join("\n");
};

/** Retrieves a large number of resources by id where there are too many ids
 * for one request and stitches the responses together. The maximum Uri length
 * is just over 8,000 characters.
 * @param {string} endpoint The endpoint (e.g. "/REMIT/list/by-publish") starting with a forward slash /
 * @param {string} idParamName Specifies the id query parameter name
 * @param {string[]} ids An array of all ids we require data for
 * @param {number} maxIdsPerRequest The maximum ids per individual request
 * @param {DataDownloadFormat} format The data format
 */
export const getStitchedDataById = async <T>(
  endpoint: string,
  idParamName: string,
  ids: string[],
  maxIdsPerRequest: number,
  format: DataDownloadFormat = DataDownloadFormat.Json
): Promise<DataDownloadModel<T>> => {
  const numberOfRequestsRequired = Math.ceil(ids.length / maxIdsPerRequest);

  const requests: Promise<AxiosResponse>[] = [
    ...Array(numberOfRequestsRequired).keys(),
  ].map(async (batchNumber) => {
    const idsSlice = ids.slice(
      maxIdsPerRequest * batchNumber,
      maxIdsPerRequest * (batchNumber + 1)
    );

    const result = await getData(
      endpoint,
      {
        [idParamName]: idsSlice,
      },
      format
    );
    return result?.data;
  });

  const results = await Promise.all(requests);
  return stitchDataResults(results, format);
};
