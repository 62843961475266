import TableWithErrorHandling from "components/components/TableWithErrorHandling/TableWithErrorHandling";
import DerivedDataTable from "components/dataVisualization/DerivedData/DerivedDataTable/DerivedDataTable";
import { BidOffer } from "components/dataVisualization/DerivedData/useAcceptanceVolumeData";
import {
  mapAcceptanceVolumeDataToRow,
  pairHeaders,
} from "components/dataVisualization/DerivedData/utils";
import { RequestStatus } from "hooks/useRequest";
import {
  AcceptanceVolumeModel,
  AcceptanceVolumeRow,
} from "models/derivedData/acceptanceVolumeModel";
import React, { useMemo } from "react";

interface Props {
  bidOffer: BidOffer;
  request: {
    fetchData: () => void;
    data: AcceptanceVolumeModel[] | null;
    status: RequestStatus;
  };
}

const BidOfferAcceptanceTab: React.FC<Props> = ({
  bidOffer,
  request: { data: rawData, fetchData, status },
}) => {
  const mappedData = useMemo(
    () => (rawData ? mapAcceptanceVolumeDataToRow(rawData) : []),
    [rawData]
  );
  return (
    <TableWithErrorHandling
      data={mappedData}
      dataFetchStatus={status}
      rerequestData={fetchData}
      aria-label={`${bidOffer}-acceptance-table`}
    >
      <DerivedDataTable<AcceptanceVolumeRow>
        headers={{
          bmUnit: "BMU ID",
          leadPartyName: "Lead Party Name",
          acceptanceId: "Acceptance ID",
          acceptanceDuration: "Acceptance Duration",
          totalVolumeAccepted: "Total Volume Accepted",
          ...pairHeaders,
          bmUnitType: "BMU Type",
          nationalGridBmUnit: "NG BMU Name",
        }}
        mappedData={mappedData}
      />
    </TableWithErrorHandling>
  );
};

export default BidOfferAcceptanceTab;
