import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Button from "components/components/Button/Button";
import { CheckboxDataGroup } from "components/components/CheckboxList/CheckboxList";
import CheckboxListContainer, {
  CheckboxState,
} from "components/components/CheckboxList/CheckboxListContainer";
import FileTypeSelector from "components/components/FileTypeSelector/FileTypeSelector";
import { ModalSubHeader } from "components/components/Modal/commonStyle";
import ModalExportWizard, {
  WizardStep,
} from "components/components/ModalExportWizard/ModalExportWizard";
import { useDataExport } from "contexts/DataExportContext";
import useCheckboxList from "hooks/useCheckboxList";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { DataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { useState, useMemo } from "react";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";

import { ModalContent, ModalInfo } from "./style";

export interface DownloadCallback {
  download: (
    downloadFormat: DataDownloadFormat
  ) => Promise<DataDownloadModel<unknown>>;
  filePrefix: string;
}

interface Props {
  fileSuffix: string;
  checkboxList: CheckboxDataGroup[];
  transformToDownloadCallbacks: (datasets: string[]) => DownloadCallback[];
  infoStep: WizardStep;
}

const MultiDatasetExporter: React.FC<Props> = ({
  fileSuffix,
  checkboxList,
  transformToDownloadCallbacks,
  infoStep,
}) => {
  const [downloadFormat, setDownloadFormat] = useState(DataDownloadFormat.Csv);
  const { dispatch: dataExportCountDispatch } = useDataExport();

  const [checkboxDataStates, registerClickForListItem] =
    useCheckboxList(checkboxList);

  const [wizardIsOpen, setWizardIsOpen] = useState<boolean>(false);

  const downloadCallbacks = useMemo(() => {
    const selectedDatasets = [
      ...new Set(
        checkboxDataStates
          .filter(
            (checkbox) => checkbox.checkboxState === CheckboxState.CHECKED
          )
          .flatMap((checkbox) => checkbox.datasets ?? [])
      ),
    ];

    return transformToDownloadCallbacks(selectedDatasets);
  }, [checkboxDataStates, transformToDownloadCallbacks]);

  const documentCount = useMemo(
    () => downloadCallbacks.length,
    [downloadCallbacks]
  );

  const downloadData = (): void => {
    downloadCallbacks.forEach((c) =>
      downloadDataToFile(
        () => c.download(downloadFormat),
        `${c.filePrefix}-${fileSuffix}`,
        downloadFormat,
        dataExportCountDispatch
      )
    );
  };

  const handleExportClick = (): void => {
    downloadData();
    setWizardIsOpen(false);
  };

  const wizardSteps: WizardStep[] = [
    infoStep,
    {
      title: "Select the data you want to export",
      content: (
        <ModalContent>
          <ModalSubHeader>Data group</ModalSubHeader>
          <CheckboxListContainer
            checkboxDataStates={checkboxDataStates}
            registerClickForListItem={registerClickForListItem}
          />
        </ModalContent>
      ),
      disabledPrimaryButton:
        checkboxDataStates.filter(
          (s) => s.checkboxState === CheckboxState.CHECKED
        ).length === 0,
    },
    {
      title: "Select the data format",
      content: (
        <>
          <ModalInfo>
            Your export will contain <b>{documentCount}</b>{" "}
            {documentCount > 1 ? "separate documents" : "document"}.
          </ModalInfo>
          <ModalContent>
            <ModalSubHeader>File type</ModalSubHeader>
            <FileTypeSelector
              downloadFormat={downloadFormat}
              setDownloadFormat={setDownloadFormat}
              showCsvDownload
            />
          </ModalContent>
        </>
      ),
    },
  ];

  return (
    <>
      <div>
        <Button
          buttonText="export"
          iconName={faDownload}
          onClick={(): void => {
            setWizardIsOpen(true);
          }}
        />
      </div>
      <ModalExportWizard
        steps={wizardSteps}
        isOpen={wizardIsOpen}
        onClose={(): void => {
          setWizardIsOpen(false);
        }}
        onExportClick={handleExportClick}
      />
    </>
  );
};

export default MultiDatasetExporter;
