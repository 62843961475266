import PageMeta from "components/components/Meta/PageMeta";
import SettlementMessagesContainer from "components/dataVisualization/SettlementMessages/SettlementMessagesContainer/SettlementMessagesContainer";
import AdditionalInfoSection, {
  ExternalDataSource,
} from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import React from "react";

const SettlementMessages: React.FC = () => (
  <>
    <PageMeta title="Settlement messages" />
    <PageHeader
      title="Settlement messages"
      pageDescription={
        <p>
          Settlement Messages are generated internally by the SAA. For example,
          when performing a settlement run, if no Market Index Data has been
          received from a Market Index Data Provider effective on that day, the
          SAA will generate a system message.
        </p>
      }
    />
    <SettlementMessagesContainer />
    <AdditionalInfoSection
      documentationInformation={{
        table: [
          "balancing/settlement/messages/{settlementDate}/{settlementPeriod}",
          "balancing/settlement/messages/{settlementDate}",
        ],
      }}
      source={ExternalDataSource.Elexon}
    />
  </>
);

export default SettlementMessages;
