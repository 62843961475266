import Swatch from "components/components/Swatch/Swatch";
import {
  LabelPublishTime,
  LabelPublishTimeMissing,
  StyledTooltipCategory,
  StyledTooltipLabel,
  ListItemWithoutBullet,
  NoMarginUnorderedList,
} from "components/dataVisualization/chartComponents/ChartTooltip/style";
import { DatasetCategory } from "models/chartConfiguration/chartDatasetModel";
import { ChartDatasetTooltipModel } from "models/chartConfiguration/tooltipModels";
import React from "react";
import { valueWithPrefixAndSuffix } from "utils/ChartDataUtils";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

interface Props {
  datasetCategory: DatasetCategory<ChartDatasetTooltipModel>;
  selectedDataset?: string;
  hasPublishTimes: boolean;
  showCategoryTitles: boolean;
  showValues: boolean;
}

export const ListDescriptionContent: React.FC<{
  dataset: ChartDatasetTooltipModel;
}> = ({ dataset }) => (
  <>
    {dataset.colour !== null && (
      <Swatch
        colour={dataset.colour}
        dashed={dataset.dashed}
        shape={dataset.swatchShape}
      />
    )}
    <span data-test-id={`${dataset.dataKey}--tooltip-label`}>
      {dataset.displayName}
    </span>
  </>
);

const ChartTooltipSubList = ({
  datasetCategory,
  selectedDataset,
  hasPublishTimes,
  showCategoryTitles,
  showValues,
}: Props): JSX.Element => {
  const formatPublishTime = (date: Date): string =>
    formatDateTimeString(new Date(date), DateFormat.DateTime);
  const listContent = (
    <>
      {datasetCategory.datasets
        .filter((dataset) => dataset.value !== undefined || dataset.alwaysShown)
        .map((dataset) => (
          <StyledTooltipLabel
            key={dataset.displayName}
            selected={selectedDataset === dataset.dataKey}
          >
            {showValues ? (
              <dt>
                <ListDescriptionContent dataset={dataset} />
              </dt>
            ) : (
              <ListItemWithoutBullet>
                <ListDescriptionContent dataset={dataset} />
              </ListItemWithoutBullet>
            )}
            {showValues && (
              <dd>
                {valueWithPrefixAndSuffix(
                  dataset.value!,
                  dataset.unitPrefix,
                  dataset.unitSuffix
                )}
              </dd>
            )}
            {hasPublishTimes &&
              (dataset.publishTime ? (
                <LabelPublishTime>
                  {formatPublishTime(dataset.publishTime)}
                </LabelPublishTime>
              ) : (
                <LabelPublishTimeMissing>—</LabelPublishTimeMissing>
              ))}
          </StyledTooltipLabel>
        ))}
    </>
  );

  return (
    <>
      {showCategoryTitles && datasetCategory.title && (
        <StyledTooltipCategory>{datasetCategory.title}</StyledTooltipCategory>
      )}

      {showValues ? (
        <dl>{listContent}</dl>
      ) : (
        <NoMarginUnorderedList>{listContent}</NoMarginUnorderedList>
      )}
    </>
  );
};

export default ChartTooltipSubList;
