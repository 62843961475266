import styled, { css } from "styled-components";
import colours from "styles/colours";

export const fullWidthStyles = css`
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
`;

const styledSectionCommonStyles = css`
  ${fullWidthStyles};
  background: ${colours.lightGrey};
`;

export const StyledSection = styled.section`
  &.is-grey-section {
    ${styledSectionCommonStyles};

    &.expand-grey-section {
      margin-left: -380px;
      padding-left: 380px;
    }
  }

  &.is-grey-section-header {
    ${styledSectionCommonStyles};
    padding: 0;
  }

  &.is-grey-border-bottom {
    padding: 0;
    border-bottom: 1px solid ${colours.highlightGrey};
  }

  &.is-fullwidth {
    ${fullWidthStyles};
    padding: 2.5% 2.5%;
  }
`;
