import { Point, Serie } from "@nivo/line";
import ChartTooltipPositionWrapper, {
  TooltipInteractionType,
} from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import React from "react";
import { formatDateTimeString } from "utils/dateHelpers";

import {
  ChartTooltipOptions,
  defaultChartTooltipOptions,
  TooltipText,
} from "./StandardChartTooltip";
import { TabulatedChartTooltipContent } from "./TabulatedChartTooltipContent/TabulatedChartTooltipContent";
import { getTabulatedTooltipValues } from "./utils";

interface Props {
  series: Serie[];
  config: ChartConfiguration;
  text?: TooltipText;
  options: ChartTooltipOptions & { interactionType: TooltipInteractionType };
  inputPoints: readonly Point[];
}

export const TabulatedChartTooltip: React.FC<Props> = ({
  series,
  config,
  text = {},
  options,
  inputPoints,
}) => {
  const tooltipOptions = {
    ...defaultChartTooltipOptions,
    ...options,
  };
  const { dateFormat, showValues } = tooltipOptions;
  const { subtitle, label, totalFormatter, description } = text;

  const firstInputPoint = inputPoints[0];

  const date = firstInputPoint.data.x as Date;
  const [dataset, idString] = firstInputPoint.id.split(".");
  const pointIsInLeftHalf =
    parseInt(idString, 10) / series[0].data.length < 0.5;

  const { tooltipValues, settlementPeriod } = getTabulatedTooltipValues(
    config.datasetCategories,
    series,
    date
  );

  const total = totalFormatter
    ? tooltipValues
        .map((value) => value.y)
        .filter((value): value is number => !!value)
        .reduce((prev, curr) => prev + curr, 0)
    : undefined;

  return (
    <ChartTooltipPositionWrapper
      pointIsInLeftHalf={pointIsInLeftHalf}
      interactionType={tooltipOptions.interactionType}
    >
      <TabulatedChartTooltipContent
        config={config}
        title={
          (label ? `${label} - ` : "") + formatDateTimeString(date, dateFormat)
        }
        subtitle={subtitle}
        total={total}
        totalFormatter={totalFormatter}
        settlementPeriod={settlementPeriod}
        showValues={!!showValues}
        tooltipValues={tooltipValues}
        selectedDataset={options.highlightSelectedDataset ? dataset : undefined}
        description={description}
      />
    </ChartTooltipPositionWrapper>
  );
};
