import styled from "styled-components";
import colours from "styles/colours";

export const StyledIcon = styled.span`
  font-family: elexon-icons;
  font-size: 14px;
  padding-top: 2px;
  padding-left: 0.75rem;
  box-sizing: inherit;
  color: ${colours.elexonLightBlue};
`;
