import Bubble from "components/components/Bubble/Bubble";
import TileChild from "components/components/TileChild/TileChild";
import TileParent from "components/components/TileParent/TileParent";
import TileRowContainer from "components/components/TileRowContainer/TileRowContainer";
import {
  DateText,
  FeatureImage,
  HorizontalLayout,
  ContentContainer,
  StyledLink,
  FeaturedContentContainer,
  StyledTitle,
  FeatureImageContainer,
} from "components/components/Updates/style";
import { UpdateWithImage } from "models/updateModel";
import React from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

type Props = {
  update: UpdateWithImage;
};

export const FeaturedUpdate: React.FC<Props> = ({
  update: { title, imageInfo, body, link, linkTitleOverride, date },
}) => {
  const linkText = `Visit the ${linkTitleOverride || title.toLowerCase()} page`;
  return (
    <TileRowContainer>
      <TileParent>
        <TileChild>
          <HorizontalLayout>
            <FeatureImageContainer>
              <FeatureImage src={imageInfo.path} alt={imageInfo.alt} />
            </FeatureImageContainer>
            <FeaturedContentContainer>
              <ContentContainer>
                <Bubble>New</Bubble>
                <StyledTitle>{title}</StyledTitle>
                <DateText>
                  {formatDateTimeString(date, DateFormat.DateOnly)}
                </DateText>
                {body}
                {link && <StyledLink to={link}>{linkText}</StyledLink>}
              </ContentContainer>
            </FeaturedContentContainer>
          </HorizontalLayout>
        </TileChild>
      </TileParent>
    </TileRowContainer>
  );
};
