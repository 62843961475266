export interface DemandForecastDayAhead {
  publishTime: Date;
  startTime: Date;
  settlementDate: Date;
  settlementPeriod: number;
  boundary: string;
  nationalDemand: number;
  transmissionSystemDemand: number;
}

export enum DemandIndicatedForecastDataKeys {
  NationalDemand = "nationalDemand",
}
