import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classnames from "classnames";
import Icon from "components/components/Icon/Icon";
import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import React from "react";
import colours from "styles/colours";

import StyledButton from "./style";

interface Props {
  onClick: () => void;
  buttonText: string;
  isLoading: boolean;
  className?: string;
  disabled?: boolean;
  faIconName?: IconProp;
  iconColour?: string;
}

const LoadingButton: React.FC<Props> = ({
  onClick,
  buttonText,
  className,
  isLoading,
  disabled,
  faIconName,
  iconColour = colours.elexonBlack,
}: Props) => (
  <StyledButton
    onClick={onClick}
    className={classnames("button", { loading: isLoading }, className)}
    disabled={disabled || isLoading}
  >
    <LoadingSpinner isLoading={isLoading}>
      <>
        {faIconName && (
          <Icon iconName={faIconName} colour={iconColour} ariaLabel="Loading" />
        )}
        <span>{buttonText}</span>
      </>
    </LoadingSpinner>
  </StyledButton>
);

export default LoadingButton;
