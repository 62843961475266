import App from "App";
import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "reportWebVitals";
import { authContext } from "utils/authUtils";
import "styles/index.scss";

// Note that we shouldn't export anything from this file as it can have unusual effects
// e.g. the cookiescript modal appearing in component tests where it shouldn't

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App authContext={authContext} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
