import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { RollingSystemFrequencyModel } from "models/rollingSystemFrequency/rollingSystemFrequencyModel";

export const getRollingSystemFrequencyData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<RollingSystemFrequencyModel>> => {
  const { data } = await getData(
    "/system/frequency",
    {
      from: dateFilter.normalisedStartDate.toISOString(),
      to: dateFilter.normalisedEndDate.toISOString(),
    },
    format
  );
  return data;
};

export const getRollingSystemFrequencyJsonData = (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<RollingSystemFrequencyModel>> =>
  getRollingSystemFrequencyData(dateFilter, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<RollingSystemFrequencyModel>
  >;
