import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const Header = styled.h2`
  && {
    color: ${colours.elexonPurple};
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Padding = styled.div`
  padding-left: 1em;
  padding-right: 1em;
`;

export const DescriptionSections = styled.dl`
  dt {
    font-weight: ${fonts.heavy};
    margin-top: 2rem;
  }
  dd + dd {
    margin-top: 1rem;
  }
  ol {
    margin-top: 0;
  }
`;
