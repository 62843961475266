import {
  Centred,
  NoWrapCell,
  StyledTrue,
  SwatchContainer,
} from "components/components/SortableTable/style";
import Swatch from "components/components/Swatch/Swatch";
import { PointShape } from "utils/ChartDrawUtils/pointShapes";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

const StyledDash: JSX.Element = <Centred>—</Centred>;

export const StyledBoolean = (bool: boolean | null): JSX.Element => {
  if (bool == null) {
    return StyledDash;
  }

  return <Centred>{bool ? <StyledTrue>True</StyledTrue> : <>F</>}</Centred>;
};

export const StyledSwatch = (
  colour: string,
  shape: PointShape,
  text: string
): JSX.Element => (
  <NoWrapCell>
    <SwatchContainer>
      <Swatch colour={colour} shape={shape} />
    </SwatchContainer>
    {text}
  </NoWrapCell>
);

export const StyledDateOnly = (date: Date | null): JSX.Element => {
  if (date == null) {
    return StyledDash;
  }
  return (
    <NoWrapCell>{formatDateTimeString(date, DateFormat.DateOnly)}</NoWrapCell>
  );
};

export const StyledTimeOnly = (date: Date | null): JSX.Element => {
  if (date == null) {
    return StyledDash;
  }
  return (
    <NoWrapCell>{formatDateTimeString(date, DateFormat.TimeOnly)}</NoWrapCell>
  );
};

export const StyledDateTime = (date: Date | null): JSX.Element => {
  if (date == null) {
    return StyledDash;
  }
  return <NoWrapCell>{formatDateTimeString(date)}</NoWrapCell>;
};

export const StyledDateTimeWithSeconds = (date: Date | null): JSX.Element => {
  if (date == null) {
    return StyledDash;
  }
  return (
    <NoWrapCell>
      {formatDateTimeString(date, DateFormat.DateTimeWithSeconds)}
    </NoWrapCell>
  );
};
