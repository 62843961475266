import React from "react";

import { RelativeContainer, StyledIFrame } from "./style";

interface Props {
  src: string;
  title: string;
  targetHeight: string;
}

const VideoContainer: React.FC<Props> = ({
  src,
  title,
  targetHeight,
}: Props) => (
  <div className="is-flex" style={{ height: targetHeight }}>
    <RelativeContainer>
      <StyledIFrame
        src={src}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title={title}
      />
    </RelativeContainer>
  </div>
);

export default VideoContainer;
