import {
  getDynamicJsonData,
  getDynamicRatesJsonData,
} from "api/dataVisualisation/dynamic/dynamic";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { DynamicDataModel } from "models/bmuData/dynamicDataModel";
import { DynamicRatesDataModel } from "models/bmuData/dynamicRatesDataModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useCallback } from "react";
import { combineRequestStatuses } from "utils/requestHelpers";

export type DynamicTabData = {
  dynamicData: DynamicDataModel[];
  dynamicRatesData: DynamicRatesDataModel[];
};

const useDynamicData = (
  dateFilter: DateFilterModel | null,
  bmUnit: string | null
): {
  request: () => void;
  data: DynamicTabData | null;
  status: RequestStatus;
} => {
  const {
    data: dynamicData,
    status: dynamicStatus,
    request: fetchDynamicData,
  } = useRequest(getDynamicJsonData);

  const {
    data: dynamicRatesData,
    status: dynamicRatesStatus,
    request: fetchDynamicRatesData,
  } = useRequest(getDynamicRatesJsonData);

  const data =
    dynamicData?.data && dynamicRatesData?.data
      ? {
          dynamicData: dynamicData.data,
          dynamicRatesData: dynamicRatesData.data,
        }
      : null;

  const status = combineRequestStatuses(dynamicStatus, dynamicRatesStatus);

  const request = useCallback(() => {
    if (dateFilter && bmUnit) {
      fetchDynamicData(dateFilter, bmUnit);
      fetchDynamicRatesData(dateFilter, bmUnit);
    }
  }, [bmUnit, dateFilter, fetchDynamicRatesData, fetchDynamicData]);

  return {
    data,
    status,
    request,
  };
};

export default useDynamicData;
