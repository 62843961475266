export interface AgptValueModel {
  businessType: string;
  psrType: string;
  quantity: number;
}

export interface AgptChartModel {
  startTime: Date;
  settlementPeriod: number;
  data: AgptValueModel[];
}

export interface AgptDownloadModel extends AgptValueModel {
  dataset: string;
  documentId: string;
  documentRevisionNumber: number;
  publishTime: Date;
  startTime: Date;
  settlementDate: string;
  settlementPeriod: number;
}

export type AgptSummaryData = {
  all: AgptSummaryModel[];
  lowCarbon: AgptSummaryModel[];
  fossilFuel: AgptSummaryModel[];
};

export type AgptTotalsModel = {
  total: string;
  lowCarbon: string;
  fossilFuel: string;
};

export interface AgptSummaryModel {
  psrType: string;
  halfHourUsage: number;
  halfHourPercentage: number;
  twentyFourHourUsage: number;
  twentyFourHourPercentage: number;
}

export const FossilPsrTypes = ["Fossil Oil", "Fossil Gas", "Fossil Hard coal"];
