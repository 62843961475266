import { recentUpdatesData } from "assets/updatesData/homePageData";
import ButtonLink from "components/components/ButtonLink/ButtonLink";
import RecentUpdates from "components/components/Updates/RecentUpdates";
import {
  StyledLink,
  WhatsNewSectionContainer,
  WhatsNewSectionHeader,
  WhatsNewTitle,
} from "components/components/WhatsNewSection/style";
import React from "react";
import routeConstants from "utils/routeConstants";

const WhatsNewSection: React.FC = () => (
  <WhatsNewSectionContainer>
    <WhatsNewSectionHeader>
      <WhatsNewTitle>What&apos;s new?</WhatsNewTitle>
      <p>
        We are regularly updating the site with new data pages and features.
        Check out the{" "}
        <StyledLink to={routeConstants.whatsNew}>What&apos;s new</StyledLink>{" "}
        page to view all recent and past updates.
      </p>
    </WhatsNewSectionHeader>

    <RecentUpdates data={recentUpdatesData} showDates={false} />

    <ButtonLink
      to={routeConstants.whatsNew}
      dataTestId="view-more-updates-button"
    >
      View more updates
    </ButtonLink>
  </WhatsNewSectionContainer>
);
export default WhatsNewSection;
