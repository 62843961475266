export interface LolpdrmModel {
  startTime: Date;
  settlementPeriod: number;
  forecastHorizon: number;
  lossOfLoadProbability?: number;
  deratedMargin?: number;
}

export enum ForecastHorizon {
  Forecast12h = "Forecast12h",
  Forecast8h = "Forecast8h",
  Forecast4h = "Forecast4h",
  Forecast2h = "Forecast2h",
  Forecast1h = "Forecast1h",
}

export const forecastHorizonMap: Record<ForecastHorizon, number> = {
  Forecast12h: 12,
  Forecast8h: 8,
  Forecast4h: 4,
  Forecast2h: 2,
  Forecast1h: 1,
};
