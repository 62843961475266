import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { ClientSecretWithValue } from "api/account/client";
import Button from "components/components/Button/Button";
import CopyToClipboard from "components/components/CopyToClipboard/CopyToClipboard";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import {
  ButtonsContainer,
  ClientSecretModalContainer,
  SecretContainer,
  WarningContainer,
} from "components/components/Iris/ClientSecretModal/style";
import { LoadingSpinnerContainer } from "components/components/Iris/commonStyle";
import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import React from "react";
import colours from "styles/colours";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

type Props = {
  title: string;
  secret: ClientSecretWithValue | null;
  isActive: boolean;
  isLoading?: boolean;
  close: () => void;
};

const ClientSecretModal: React.FC<Props> = ({
  title,
  secret,
  isActive,
  isLoading = false,
  close,
}) => {
  if (isLoading) {
    return (
      <Modal
        size={ModalSize.large}
        isActive={isActive}
        onClose={close}
        ariaLive="polite"
      >
        <LoadingSpinnerContainer>
          <LoadingSpinner isLoading />
          <p>Requesting new secret...</p>
        </LoadingSpinnerContainer>
      </Modal>
    );
  }

  return (
    <Modal
      size={ModalSize.large}
      isActive={isActive}
      onClose={close}
      ariaLive="polite"
    >
      <ClientSecretModalContainer>
        <h2>{title}</h2>
        <WarningContainer>
          <Icon
            size={IconSize.small}
            colour={colours.elexonOrange}
            iconName={faExclamationTriangle}
          />
          <p>
            Please <b>save the client secret</b> as you will not be shown it
            again once you close this window.
          </p>
        </WarningContainer>
        <SecretContainer>
          <div>
            <dl>
              <dt>Client secret:</dt>
              <dd>{secret?.secretValue}</dd>
            </dl>
            <CopyToClipboard value={secret?.secretValue} />
          </div>
          <div>
            <dl>
              <dt>Expiry:</dt>
              <dd>
                {secret?.expiryTime &&
                  formatDateTimeString(
                    new Date(secret?.expiryTime),
                    DateFormat.DateOnly
                  )}
              </dd>
            </dl>
          </div>
        </SecretContainer>
        <ButtonsContainer>
          <Button ariaLabel="Close" buttonText="Close" onClick={close} />
        </ButtonsContainer>
      </ClientSecretModalContainer>
    </Modal>
  );
};

export default ClientSecretModal;
