import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const StyledChild = styled.div`
  border: 3px solid ${colours.lightGrey};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 16em;
  justify-content: center;

  h3 {
    color: ${colours.elexonGreen};
    font-size: ${fonts.xLarge};
    margin: 0.2em 0 0 0;
  }

  p {
    color: ${colours.darkGrey};
    margin: 0.5em 1em 1.5em 1em;
  }
`;
