import { ReactNode } from "react";
import Select, { Theme } from "react-select";
import { customSelectTheme } from "styles/themes";

import { Divider, DropdownContainer } from "./style";

interface DropdownOption<T> {
  value: T;
  label: string;
}

export interface DropdownOptionGroup<T> {
  options: DropdownOption<T>[];
  omitGroupHeader?: boolean;
}

interface Props<T> {
  options?: DropdownOption<T>[];
  groups?: DropdownOptionGroup<T>[];
  dataset: T;
  setDataset: (v: T) => void;
}

const OptionsOrGroupsDropdown = <T extends string>({
  options,
  groups,
  dataset,
  setDataset,
}: Props<T>): JSX.Element => {
  if ((groups && options) || (!groups && !options)) {
    throw new Error("Either groups or options must be supplied, but not both");
  }

  const flatOptions = groups ? groups.flatMap((g) => g.options) : options;
  const formatGroupLabel = (data: DropdownOptionGroup<T>): ReactNode =>
    data.omitGroupHeader ? undefined : <Divider />;

  return (
    <DropdownContainer>
      <label htmlFor="dataset-dropdown">Dataset</label>
      <Select
        id="dataset-dropdown"
        options={groups ?? options}
        value={flatOptions!.find((x) => x.value === dataset)}
        onChange={(option): void => {
          if (option) {
            setDataset(option.value);
          }
        }}
        components={{
          IndicatorSeparator: null,
          ClearIndicator: undefined,
        }}
        theme={(theme): Theme => customSelectTheme(theme)}
        formatGroupLabel={groups ? formatGroupLabel : undefined}
      />
    </DropdownContainer>
  );
};

export const DatasetDropdown = <T extends string>(
  props: Omit<Props<T>, "groups">
): JSX.Element => <OptionsOrGroupsDropdown {...props} />;

export const DatasetGroupsDropdown = <T extends string>(
  props: Omit<Props<T>, "options">
): JSX.Element => <OptionsOrGroupsDropdown {...props} />;
