import Swatch from "components/components/Swatch/Swatch";
import ChartTooltipPositionWrapper, {
  TooltipInteractionType,
} from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import {
  StyledTooltip,
  StyledTooltipTitle,
  StyledTooltipSettlementPeriod,
  ChartTooltipListContainer,
  TooltipDescription,
  StyledTooltipCategory,
  StyledTooltipLabel,
} from "components/dataVisualization/chartComponents/ChartTooltip/style";
import { RectangleSliceTooltipData } from "components/dataVisualization/chartComponents/RectangleSlice/RectangleSlicesLayer";
import {
  DatasetCategory,
  combineDatasetCategories,
} from "models/chartConfiguration/chartDatasetModel";
import { ChartDatasetTooltipModel } from "models/chartConfiguration/tooltipModels";
import {
  DisaggregatedBalancingServicesAdjustmentCategoryIds,
  DisaggregatedBalancingServicesAdjustmentIds,
} from "models/disaggregatedBalancingServicesAdjustment/disaggregatedBalancingServicesAdjustmentSummaryModel";
import { disbsadDatasetColours } from "styles/colours";
import { PointShape } from "utils/ChartDrawUtils/pointShapes";
import { getSettlementPeriodFromSettlementTime } from "utils/DateUtils";
import { formatDateTimeString, DateFormat } from "utils/dateHelpers";

import {
  AdjustmentActivitiesValue,
  CategoryContainer,
  TooltipTable,
} from "./style";

interface Props {
  highlightedRectangle: RectangleSliceTooltipData;
  datasetCategories: DatasetCategory<ChartDatasetTooltipModel>[];
}

const AdjustmentActionsDisbsadTooltip: React.FC<Props> = ({
  highlightedRectangle,
  datasetCategories,
}) => (
  <ChartTooltipPositionWrapper
    pointIsInLeftHalf={highlightedRectangle.sliceInLeftHalf}
    interactionType={TooltipInteractionType.Controlled}
  >
    <StyledTooltip data-test-id="chart-tooltip">
      <StyledTooltipTitle>
        {formatDateTimeString(
          highlightedRectangle.sliceStartValue,
          DateFormat.DateTime
        )}
      </StyledTooltipTitle>
      <StyledTooltipSettlementPeriod data-test-id="chart-tooltip-settlement-period">
        <span>Settlement Period</span>
        <span>
          {getSettlementPeriodFromSettlementTime(
            highlightedRectangle.sliceStartValue
          )}
        </span>
      </StyledTooltipSettlementPeriod>
      <ChartTooltipListContainer>
        <StyledTooltipCategory>Adjustment Activities</StyledTooltipCategory>
        <CategoryContainer>
          {datasetCategories
            .find(
              (x) =>
                x.id ===
                DisaggregatedBalancingServicesAdjustmentCategoryIds.AdjustmentActivities
            )!
            .datasets.map((dataset) => (
              <StyledTooltipLabel selected={false} key={dataset.dataKey}>
                <AdjustmentActivitiesValue>
                  {dataset.value ?? "—"}
                </AdjustmentActivitiesValue>
                <span>{dataset.displayName}</span>
              </StyledTooltipLabel>
            ))}
        </CategoryContainer>
        <CategoryContainer>
          <TooltipTable>
            <thead>
              <tr>
                <th>Price (£/MWh)</th>
                <th>Min</th>
                <th>Average</th>
                <th>Max</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <>
                    <Swatch
                      colour={
                        disbsadDatasetColours[
                          DisaggregatedBalancingServicesAdjustmentIds
                            .BuyPriceAdjustment
                        ]!
                      }
                      shape={PointShape.Circle}
                    />
                    <span data-test-id="buyPrice--tooltip-label">
                      Buy Price
                    </span>
                  </>
                </td>
                <td>
                  {combineDatasetCategories(datasetCategories).find(
                    (x) =>
                      x.dataKey ===
                      DisaggregatedBalancingServicesAdjustmentIds.BuyPriceMin
                  )!.value ?? "—"}
                </td>
                <td>
                  {combineDatasetCategories(datasetCategories).find(
                    (x) =>
                      x.dataKey ===
                      DisaggregatedBalancingServicesAdjustmentIds.BuyPriceAvg
                  )!.value ?? "—"}
                </td>
                <td>
                  {combineDatasetCategories(datasetCategories).find(
                    (x) =>
                      x.dataKey ===
                      DisaggregatedBalancingServicesAdjustmentIds.BuyPriceMax
                  )!.value ?? "—"}
                </td>
              </tr>
              <tr>
                <td>
                  <>
                    <Swatch
                      colour={
                        disbsadDatasetColours[
                          DisaggregatedBalancingServicesAdjustmentIds
                            .SellPriceAdjustment
                        ]!
                      }
                      shape={PointShape.Diamond}
                    />
                    <span data-test-id="sellPrice--tooltip-label">
                      Sell Price
                    </span>
                  </>
                </td>
                <td>
                  {combineDatasetCategories(datasetCategories).find(
                    (x) =>
                      x.dataKey ===
                      DisaggregatedBalancingServicesAdjustmentIds.SellPriceMin
                  )!.value ?? "—"}
                </td>
                <td>
                  {combineDatasetCategories(datasetCategories).find(
                    (x) =>
                      x.dataKey ===
                      DisaggregatedBalancingServicesAdjustmentIds.SellPriceAvg
                  )!.value ?? "—"}
                </td>
                <td>
                  {combineDatasetCategories(datasetCategories).find(
                    (x) =>
                      x.dataKey ===
                      DisaggregatedBalancingServicesAdjustmentIds.SellPriceMax
                  )!.value ?? "—"}
                </td>
              </tr>
            </tbody>
          </TooltipTable>
        </CategoryContainer>
        <CategoryContainer>
          <TooltipTable>
            <thead>
              <tr>
                <th>Volume (MWh)</th>
                <th>Total</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {datasetCategories
                .find(
                  (x) =>
                    x.id ===
                    DisaggregatedBalancingServicesAdjustmentCategoryIds.VolumeAdjustments
                )!
                .datasets.map((dataset) => (
                  <tr key={dataset.dataKey}>
                    <td>
                      <>
                        {dataset.colour !== null && (
                          <Swatch
                            colour={dataset.colour}
                            dashed={dataset.dashed}
                            shape={dataset.swatchShape}
                          />
                        )}
                        <span
                          data-test-id={`${dataset.dataKey}--tooltip-label`}
                        >
                          {dataset.displayName}
                        </span>
                      </>
                    </td>
                    <td>{dataset.value ?? "—"}</td>
                  </tr>
                ))}
            </tbody>
          </TooltipTable>
        </CategoryContainer>
      </ChartTooltipListContainer>
      <TooltipDescription>
        Click the Settlement Period segment to see additional data in the table
        below the graph.
      </TooltipDescription>
    </StyledTooltip>
  </ChartTooltipPositionWrapper>
);

export default AdjustmentActionsDisbsadTooltip;
