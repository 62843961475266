import { BmuModel } from "models/bmuData/bmuModel";

export interface MarketViewBmuFilters {
  leadParty: string | null;
  bmuType: string | null;
  fuelType: string | null;
}

export const hasAnyFilters = (filters: MarketViewBmuFilters): boolean => {
  const { leadParty, bmuType, fuelType } = filters;
  return !!(leadParty || bmuType || fuelType);
};

const mapToOptions = <T extends keyof BmuModel>(
  data: BmuModel[] | null,
  key: T
): string[] => {
  if (!data) {
    return [];
  }
  const values = data.map((b) => b[key]).filter((x): x is string => x !== null);

  return [...new Set(values)];
};

export const mapToLeadPartyOptions = (data: BmuModel[] | null): string[] =>
  mapToOptions(data, "leadPartyName");

export const mapToBmuTypeOptions = (data: BmuModel[] | null): string[] =>
  mapToOptions(data, "bmUnitType");

export const mapToFuelTypeOptions = (data: BmuModel[] | null): string[] =>
  mapToOptions(data, "fuelType");

export const filterBmuIds = (
  data: BmuModel[],
  { leadParty, bmuType, fuelType }: MarketViewBmuFilters
): string[] =>
  data
    .filter((b: BmuModel) => !leadParty || b.leadPartyName === leadParty)
    .filter((b: BmuModel) => !bmuType || b.bmUnitType === bmuType)
    .filter((b: BmuModel) => !fuelType || b.fuelType === fuelType)
    .map((b: BmuModel) => b.nationalGridBmUnit);
