import classnames from "classnames";
import Button from "components/components/Button/Button";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import React from "react";

interface Props {
  downloadFormat: DataDownloadFormat;
  setDownloadFormat: (newDownloadFormat: DataDownloadFormat) => void;
  showCsvDownload?: boolean;
}

const FileTypeSelector: React.FC<Props> = ({
  downloadFormat,
  setDownloadFormat,
  showCsvDownload = true,
}: Props) => (
  <div data-test-id="data-file-type">
    {showCsvDownload && (
      <Button
        ariaLabel="CSV"
        onClick={(): void => setDownloadFormat(DataDownloadFormat.Csv)}
        className={classnames("filter", {
          active: downloadFormat === DataDownloadFormat.Csv,
        })}
        buttonText={DataDownloadFormat.Csv.toLocaleUpperCase()}
      />
    )}
    <Button
      ariaLabel="JSON"
      onClick={(): void => setDownloadFormat(DataDownloadFormat.Json)}
      className={classnames("filter", {
        active: downloadFormat === DataDownloadFormat.Json,
      })}
      buttonText={DataDownloadFormat.Json.toLocaleUpperCase()}
    />
    <Button
      ariaLabel="XML"
      onClick={(): void => setDownloadFormat(DataDownloadFormat.Xml)}
      className={classnames("filter", {
        active: downloadFormat === DataDownloadFormat.Xml,
      })}
      buttonText={DataDownloadFormat.Xml.toLocaleUpperCase()}
    />
  </div>
);

export default FileTypeSelector;
