import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "components/components/Button/Button";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import {
  ButtonDiv,
  Header,
  Padding,
} from "components/components/balancingMechanism/balancingMechanismTabs/descriptionModals/style";
import React from "react";

export interface ModalProps {
  isOpen: boolean;
  close: () => void;
}

interface Props extends ModalProps {
  headingId: string;
  title: string;
  children: JSX.Element;
}

const TabModal: React.FC<Props> = ({
  isOpen,
  close,
  headingId,
  title,
  children,
}) => (
  <Modal
    size={ModalSize.xLarge}
    isActive={isOpen}
    onClose={close}
    headingId={headingId}
  >
    <Padding>
      <ButtonDiv>
        <Button
          ariaLabel="Close"
          buttonText="Close"
          className="cancel"
          onClick={close}
          iconName={faTimes}
        />
      </ButtonDiv>
      <Header id={headingId}>{title}</Header>
      {children}
    </Padding>
  </Modal>
);

export default TabModal;
