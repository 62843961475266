import styled, { css } from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

const borderStyling = css`
  border: 1px solid ${colours.highlightGrey};
  border-radius: 3px;
  padding: 2em;
`;

const tileStyling = css`
  margin: 0.6em 2em 1.5em 0;
  display: flex;
  flex-direction: column;
`;

export const TileTitle = styled.h2`
  && {
    font-size: ${fonts.mediumBigger};
  }
`;

export const TileWrapper = styled.div`
  ${borderStyling}
  ${tileStyling}
`;

export const TransparentTileWrapper = styled.div`
  ${tileStyling};
  margin: 0.6em 2em 1.5em 0;
`;

export const OutageProfileChartContainer = styled.div`
  ${borderStyling};
  padding: 2em 0.2em 4em 0.2em;
  grid-column: 1 / -1;
  grid-row: span 1;
  height: 35em;
  margin-right: 2em;
  margin-bottom: 0.5em;

  > h2 {
    display: flex;
    justify-content: center;
  }
`;

export const ContentTileWrapper = styled.div`
  ${borderStyling};
`;

export const TilesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 90%;
`;

const dataStyling = css`
  margin-bottom: 1.25em;

  > hr {
    border-top: 2px solid ${colours.lightHighlightGrey};
    margin: 0.2em 0 1em 0;
  }
`;

export const Data = styled.div`
  ${dataStyling}
`;

export const RowStyling = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > dt {
    font-weight: ${fonts.semibold};
  }

  > dd {
    margin-left: 0;
  }
`;

export const RowDl = styled.dl`
  ${RowStyling}
`;

export const RowDiv = styled.div`
  ${RowStyling}
`;

export const Table = styled.table`
  border-collapse: collapse;

  > tbody tr {
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr;
    justify-content: space-around;
    column-gap: 3%;
    border-bottom: 2px solid ${colours.lightHighlightGrey};

    :first-child {
      border: none;
    }

    > th {
      border: none;
    }

    > td {
      border: none;
    }
  }
`;
