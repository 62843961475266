import styled from "styled-components";
import colours from "styles/colours";

export interface WarningContainerProps {
  borderColor: string;
}

export const Container = styled.div`
  margin: 0 1em;
  padding-bottom: 1em;

  h2 {
    padding-top: 0.8em;
    font-size: 1.25em;
  }
`;

export const Title = styled.div`
  display: flex;
  margin-left: 0.5em;
  margin-top: 0.5em;

  p {
    padding-left: 0.2em;
    font-weight: bold;
  }
`;

export const WarningContainer = styled.div<WarningContainerProps>`
  display: flex;
  flex-direction: column;
  background: ${colours.white};
  border: 1px solid ${(props): string => props.borderColor};
  border-radius: 3px;
  margin-bottom: 1em;

  ${Title} + p {
    padding-left: 1em;
    padding-top: 0.5em;
    margin-bottom: 0;
  }

  p:nth-of-type(2) {
    padding-top: 1em;
    padding-left: 1em;
  }
`;

export const SummaryTable = styled.table<{ tableLayout: "auto" | "fixed" }>`
  table-layout: ${({ tableLayout }): string => tableLayout};

  .hidden-header {
    visibility: hidden;
  }

  .table td {
    border-top: 2px solid ${colours.lightHighlightGrey};
  }

  tr.hide_right > td,
  td.hide_right {
    border-top-style: hidden;
  }

  tr.hide_bottom > td,
  td.hide_bottom {
    border-bottom-style: hidden;
  }

  tr td:first-child {
    font-weight: bold;
    text-align: left;
  }
  tr td:last-child {
    border-right: none;
  }
  
  tr {
    text-align: center
  }

  tr td {
  border-right: 2px solid ${colours.lightHighlightGrey};
  border-top: 2px solid ${colours.lightHighlightGrey};
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  }

  tr td:nth-child(2),
  tr td:nth-child(3) {
    border-right: none;
  }

  .positioned-block {
    position: relative;
    top: 25px;

    ::before {
      content: "";
      width: 70px;
      height: 100%;
      background-color: ${colours.white};
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .value {
    position: relative;
  }
  
  .square-purple {
    font-weight: bold;
    display: inline-block;
    background-color: ${colours.grapePurple};
    color: ${colours.white};
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 10%;
    }
  }
`;

export const SpinnerParent = styled.div`
  padding-top: 1em;
  height: 5em;
`;

export const SpinnerContainer = styled.div`
  height: 8em;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
