import TabContentWithDescription from "components/components/Tabs/TabContentWithDescription/TabContentWithDescription";
import TabContentWithErrorHandling from "components/components/Tabs/TabContentWithErrorHandling/TabContentWithErrorHandling";
import PhysicalModal from "components/components/balancingMechanism/balancingMechanismTabs/descriptionModals/PhysicalModal";
import PhysicalChartContainer from "components/dataVisualization/balancingMechanismTabs/Physical/PhysicalChartContainer/PhysicalChartContainer";
import PhysicalTables from "components/dataVisualization/balancingMechanismTabs/Physical/PhysicalTables/PhysicalTables";
import { useBmuViewContext } from "contexts/BmuViewContext";
import React, { useEffect, useState } from "react";

const BmuViewPhysical: React.FC = () => {
  const {
    selectedBmu,
    physicalDetails: { status, request },
  } = useBmuViewContext();

  const [selectedSettlementPeriod, setSelectedSettlementPeriod] =
    useState<Date | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const modal = (
    <PhysicalModal
      isOpen={modalIsOpen}
      close={(): void => setModalIsOpen(false)}
      shouldDisplayStableLimits
    />
  );

  useEffect(() => {
    setSelectedSettlementPeriod(null);
  }, [selectedBmu]);

  useEffect(() => {
    if (selectedSettlementPeriod !== null) {
      document.getElementById("physical-tables")?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
  }, [selectedSettlementPeriod]);

  return (
    <TabContentWithDescription
      description={`
          The Physical Data available is the Final Physical Notification (FPN),
          Quiescent Physical Notification (QPN), Maximum Export Limit (MEL),
          Maximum Import Limit (MIL), Stable Export Limit (SEL), Stable Import
          Limit (SIL), and Bid-Offer Acceptance Level (BOAL)`}
      modal={modal}
      openModal={(): void => setModalIsOpen(true)}
    >
      <TabContentWithErrorHandling status={status} fetchData={request}>
        <>
          <PhysicalChartContainer onSliceClick={setSelectedSettlementPeriod} />
          <br />
          <PhysicalTables settlementPeriodDatetime={selectedSettlementPeriod} />
        </>
      </TabContentWithErrorHandling>
    </TabContentWithDescription>
  );
};

export default BmuViewPhysical;
