import React from "react";

export enum DataExportAction {
  IncrementCountAndShowToast,
  DecrementCount,
  ReportError,
  DismissToastAndIncrementToastId,
}

export interface DataExportState {
  count: number;
  errorCount: number;
  toastVisible: boolean;
  toastId: number;
}

export type DataExportContextType = {
  state: DataExportState;
  dispatch: React.Dispatch<DataExportAction>;
};

export const DataExportContext = React.createContext<
  DataExportContextType | undefined
>(undefined);

export const DataExportReducer = (
  state: DataExportState,
  action: DataExportAction
): DataExportState => {
  switch (action) {
    case DataExportAction.IncrementCountAndShowToast:
      return { ...state, count: state.count + 1, toastVisible: true };
    case DataExportAction.DecrementCount:
      return { ...state, count: state.count - 1 };
    case DataExportAction.ReportError:
      return { ...state, errorCount: state.errorCount + 1 };
    case DataExportAction.DismissToastAndIncrementToastId:
      return {
        ...state,
        errorCount: 0,
        toastVisible: false,
        toastId: state.toastId + 1,
      };
    default:
      return state;
  }
};

export const useDataExport = (): DataExportContextType => {
  const context = React.useContext(DataExportContext);

  if (context === undefined) {
    throw new Error(
      "useDataExport must be used within DataExportContext's Provider"
    );
  }

  return context;
};
