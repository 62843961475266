import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const TabDetailsContainer = styled.div`
  &&:focus-visible {
    outline: 2px solid ${colours.elexonBlack};
    box-shadow: none;
  }
`;

export const TabTitle = styled.h2`
  margin-top: 1.5em;

  && {
    font-size: ${fonts.large};
  }
`;

export const ContentContainer = styled.div`
  margin-bottom: 3em;
  min-height: 15em;
`;

export const DefaultMessage = styled.div`
  display: flex;
  justify-content: center;
  margin: 5em auto;
  font-weight: bold;
`;
