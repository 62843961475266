import Analytics from "components/components/Analytics";
import CustomerFeedback from "components/components/CustomerFeedback/CustomerFeedback";
import { DataExportToastManager } from "components/components/DataExportToast/DataExportToastManager";
import ErrorBoundary from "components/components/ErrorBoundary/ErrorBoundary";
import { NewUpdateToastManager } from "components/components/NewUpdateToast/NewUpdateToastManager";
import Footer from "components/layout/Footer/Footer";
import Header from "components/layout/Header/Header";
import { useCustomerFeedbackModalContext } from "contexts/CustomerFeedbackModalContext";
import {
  DataExportContext,
  DataExportReducer,
} from "contexts/DataExportContext";
import { SwaggerFileProvider } from "contexts/SwaggerFileContext";
import { useIrisLoginModalCookie } from "hooks/useIrisLoginModalCookie";
import React, { useReducer } from "react";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";

interface Props {
  children: JSX.Element;
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  const [dataExportCountState, dataExportCountDispatch] = useReducer(
    DataExportReducer,
    { count: 0, errorCount: 0, toastVisible: false, toastId: 100 }
  );
  const { close, isOpen } = useCustomerFeedbackModalContext();

  useIrisLoginModalCookie();

  const location = useLocation();

  return (
    <>
      <DataExportContext.Provider
        value={{
          state: dataExportCountState,
          dispatch: dataExportCountDispatch,
        }}
      >
        <SwaggerFileProvider>
          <>
            <Analytics />
            <Header />
            <main className={`container content`}>
              <ErrorBoundary location={location}>{children}</ErrorBoundary>
            </main>
            <Footer />
            <Toaster position="bottom-right" />
            <NewUpdateToastManager />
            <DataExportToastManager />
            <CustomerFeedback modalActive={isOpen} closeModal={close} />
          </>
        </SwaggerFileProvider>
      </DataExportContext.Provider>
    </>
  );
};

export default Layout;
