import { getData } from "api/dataVisualisation/client";
import { BidOffer } from "components/dataVisualization/DerivedData/useAcceptanceVolumeData";
import { BmuModel } from "models/bmuData/bmuModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import { AcceptanceVolumeModel } from "models/derivedData/acceptanceVolumeModel";
import { getSettlementDateAndPeriodFromSettlementTime } from "utils/DateUtils";
import { batchBmuIds, stitchDataResults } from "utils/dataStitchHelpers";
import { toDateOnlyString } from "utils/dateHelpers";

export const getAcceptanceVolumeData = async (
  settlementTime: Date,
  bidOffer: BidOffer,
  bmUnits?: BmuModel[],
  format?: DataDownloadFormat
): Promise<DataDownloadModel<AcceptanceVolumeModel>> => {
  const { settlementDate, settlementPeriod } =
    getSettlementDateAndPeriodFromSettlementTime(settlementTime);

  if (bmUnits !== undefined) {
    const bmuIds = bmUnits.map((unit) => unit.elexonBmUnit);
    const bmuBatches = batchBmuIds(bmuIds);
    const requests = bmuBatches.map(async (batch) => {
      const { data } = await getData(
        `/balancing/settlement/acceptance/volumes/all/${bidOffer}/${toDateOnlyString(
          settlementDate
        )}/${settlementPeriod}`,
        { bmUnit: batch },
        format
      );
      return data;
    });

    const results = await Promise.all(requests);
    const data = stitchDataResults<AcceptanceVolumeModel>(
      results,
      format ?? DataDownloadFormat.Json
    );

    return data;
  } else {
    const { data } = await getData(
      `/balancing/settlement/acceptance/volumes/all/${bidOffer}/${toDateOnlyString(
        settlementDate
      )}/${settlementPeriod}`,
      {},
      format
    );
    return data;
  }
};

export const getAcceptanceVolumeJsonData = (
  settlementTime: Date,
  bidOffer: BidOffer,
  bmUnits?: BmuModel[]
): Promise<JsonDataDownloadModel<AcceptanceVolumeModel>> =>
  getAcceptanceVolumeData(
    settlementTime,
    bidOffer,
    bmUnits,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<AcceptanceVolumeModel>>;
