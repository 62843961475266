import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import HeaderNav from "components/components/HeaderNav/HeaderNav";
import Faqs from "components/components/Iris/LandingPageInfo/Faqs/Faqs";
import GetStartedSection from "components/components/Iris/LandingPageInfo/GetStartedSection/GetStartedSection";
import IrisHeader from "components/components/Iris/LandingPageInfo/IrisHeader/IrisHeader";
import { TallButton } from "components/components/Iris/commonStyle";
import PageMeta from "components/components/Meta/PageMeta";
import { NavContainer } from "pages/WhatsNew/style";
import React from "react";

import { IrisFooter } from "./style";

export type ButtonProps = {
  buttonText: string;
  onClick: () => void;
};

type Props = {
  buttonProps: ButtonProps;
};

const LandingPageInfo: React.FC<Props> = ({ buttonProps }) => (
  <>
    <PageMeta title="IRIS" />
    <NavContainer>
      <HeaderNav title="IRIS" />
    </NavContainer>
    <IrisHeader buttonProps={buttonProps} />
    <GetStartedSection />
    <Faqs />
    <FullWidthSection className="is-grey-section">
      <div className="container px-6">
        <IrisFooter>
          <div>
            IRIS is a near real-time, free, publicly available push service for
            accessing electricity market data. Create an account to get access
            within minutes.
          </div>
          <TallButton
            buttonText={buttonProps.buttonText}
            onClick={buttonProps.onClick}
          />
        </IrisFooter>
      </div>
    </FullWidthSection>
  </>
);

export default LandingPageInfo;
