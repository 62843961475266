import { getSoSoTradePricesJsonData } from "api/dataVisualisation/soSoTradePrices/soSoTradePrices";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { SoSoTradePricesModel } from "models/soSoTradePrices/soSoTradePricesModel";
import { useCallback } from "react";

const useSoSoTradePricesData = (
  dateFilter: DateFilterModel
): {
  fetchData: () => void;
  data: SoSoTradePricesModel[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchTableData,
  } = useRequest(getSoSoTradePricesJsonData);

  const fetchData = useCallback(() => {
    fetchTableData(dateFilter);
  }, [dateFilter, fetchTableData]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useSoSoTradePricesData;
