import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import {
  Title,
  WarningContainer,
} from "components/dataVisualization/DetailedSystemPrices/style";
import { getConfigForSeverity } from "components/dataVisualization/DetailedSystemPrices/utils";
import { SettlementMessageModelTransformed } from "models/detailedSystemPrices/settlementModels";
import React from "react";
import { Link } from "react-router-dom";
import routeConstants from "utils/routeConstants";

interface WarningComponentProps {
  filteredMessagesData: SettlementMessageModelTransformed[];
}

const DetailedSystemPricesCaution: React.FC<WarningComponentProps> = ({
  filteredMessagesData,
}) => {
  const uniqueMessageTypesString = Array.from(
    new Set(filteredMessagesData.map((message) => message.messageType))
  ).join(", ");

  const messageSeverity = filteredMessagesData[0].messageSeverity ?? "";
  const config = getConfigForSeverity(messageSeverity);

  return (
    <WarningContainer borderColor={config.colour}>
      <Title>
        <Icon
          size={IconSize.small}
          colour={config.colour}
          iconName={faExclamationTriangle}
        />
        <p>{config.cautionMessage}</p>
      </Title>
      <p>
        The selected Settlement Period has {config.severity.toLowerCase()}s
        associated with: {uniqueMessageTypesString}
      </p>
      <p>
        For more information, please visit the{" "}
        <Link to={routeConstants.settlementMessages}>
          Settlement messages page.
        </Link>
      </p>
    </WarningContainer>
  );
};

export default DetailedSystemPricesCaution;
