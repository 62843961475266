import { useEffect, useRef } from "react";

const useFetchDataIfRequestHasChanged = (
  tabIsActive: boolean,
  fetchData: () => void
): void => {
  const previousFetch = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (tabIsActive && previousFetch.current !== fetchData) {
      fetchData();
      previousFetch.current = fetchData;
    }
  }, [tabIsActive, fetchData]);
};

export default useFetchDataIfRequestHasChanged;
