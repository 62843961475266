interface FooterLinkText {
  url: string;
  text: string;
}

export const footerLinks: FooterLinkText[] = [
  {
    url: "https://www.elexon.com/governance/corporate-policies",
    text: "Elexon corporate policies",
  },
  {
    url: "https://www.elexon.com/governance/corporate-policies/privacy-policy",
    text: "Privacy policy",
  },
  {
    url: "https://www.elexon.com/governance/corporate-policies/cookie-policy",
    text: "Cookie policy",
  },
  {
    url: "https://www.elexon.co.uk/data/balancing-mechanism-reporting-agent/copyright-licence-bmrs-data",
    text: "Copyright",
  },
  {
    url: "https://www.elexon.co.uk/data/kinnect-insights-solution/using-the-insights-solution-website",
    text: "Using this website",
  },
  {
    url: "https://www.elexon.co.uk/glossary",
    text: "Glossary",
  },
];
