import { Serie } from "@nivo/line";
import { ControlledInformationTooltip } from "components/components/InformationTooltip/InformationTooltip";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { StandardChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/StandardChartTooltip";
import { RectangleSlice } from "components/dataVisualization/chartComponents/RectangleSlice/RectangleSlice";
import { RectangleSliceTooltipData } from "components/dataVisualization/chartComponents/RectangleSlice/RectangleSlicesLayer";
import { RequestStatus } from "hooks/useRequest";
import { DatasetCategory } from "models/chartConfiguration/chartDatasetModel";
import { ChartDatasetTooltipModel } from "models/chartConfiguration/tooltipModels";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useCallback, useEffect, useRef, useState } from "react";

import { StyledChartContainer, RectangleContainer } from "./style";

// Set to true to add background to crosshair region to show crosshair container
// Can be used for setting topOffset and height values correctly
const ENABLE_CROSSHAIR_REGION_DEBUG = false;

interface Props {
  children: JSX.Element;
  allChartData: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetTooltipModel>[];
  topOffset: number;
  height: number;
  dateFilter: DateFilterModel;
  dataFetchStatus: RequestStatus;
  tooltip?: (highlightedRectangle: RectangleSliceTooltipData) => JSX.Element;
  tooltipLabel?: string;
  highlightedRectangle?: RectangleSliceTooltipData;
  totalUnitSuffix?: string;
  showTooltipCategoryTitles?: boolean;
  onRectangleSelection?: (
    selectedRectangle?: RectangleSliceTooltipData
  ) => void;
}

const MultiChartRectangleContainer: React.FC<Props> = ({
  children,
  allChartData,
  datasetCategories,
  topOffset,
  height,
  dateFilter,
  dataFetchStatus,
  tooltip,
  tooltipLabel,
  highlightedRectangle,
  totalUnitSuffix,
  showTooltipCategoryTitles,
  onRectangleSelection,
}) => {
  const [selectedRectangle, setSelectedRectangle] =
    useState<RectangleSliceTooltipData>();
  const container = useRef<HTMLDivElement>(null);
  const areChartsLoaded =
    dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

  const onMouseDown = useCallback((): void => {
    if (!highlightedRectangle) {
      return;
    }

    setSelectedRectangle(highlightedRectangle);
    onRectangleSelection?.(highlightedRectangle);
  }, [highlightedRectangle, onRectangleSelection]);

  useEffect(() => {
    const containerRef = container.current!;
    containerRef.addEventListener("mousedown", onMouseDown);

    return (): void => {
      containerRef.removeEventListener("mousedown", onMouseDown);
    };
  }, [onMouseDown]);

  // reset selected rectangle when date filter changes as rectangle regions will have changed
  useEffect(() => {
    setSelectedRectangle(undefined);
  }, [dateFilter]);

  return (
    <StyledChartContainer
      ref={container}
      data-test-id="multi-chart-rectangle-container"
    >
      {
        <>
          <RectangleContainer
            backgroundColour={
              ENABLE_CROSSHAIR_REGION_DEBUG ? "#FF00FFAA" : "transparent"
            }
            height={`${height}px`}
            top={`${topOffset}px`}
          >
            {/* transform to mimic the Nivo generated offset */}
            <g
              transform="translate(120,40)"
              data-test-id="multi-rectangle-container"
            >
              {areChartsLoaded && highlightedRectangle && (
                <RectangleSlice
                  x={highlightedRectangle.sliceXPosition}
                  y={0}
                  width={highlightedRectangle.sliceWidth}
                  height={height}
                  labelText={highlightedRectangle.sliceLabel}
                  manualHover
                />
              )}
              {areChartsLoaded && selectedRectangle && (
                <RectangleSlice
                  x={selectedRectangle.sliceXPosition}
                  y={0}
                  width={selectedRectangle.sliceWidth}
                  height={height}
                  isSelected
                />
              )}
            </g>
          </RectangleContainer>
          <ControlledInformationTooltip
            isVisible={areChartsLoaded && highlightedRectangle !== undefined}
            setIsVisible={(): void => {}}
          >
            <>
              {!tooltip &&
              highlightedRectangle &&
              highlightedRectangle.slicePoints.length ? (
                <StandardChartTooltip
                  series={allChartData}
                  datasetCategories={datasetCategories}
                  text={{
                    label: tooltipLabel,
                    totalUnitSuffix,
                  }}
                  options={{
                    interactionType: TooltipInteractionType.Controlled,
                    showCategoryTitles: showTooltipCategoryTitles,
                  }}
                  inputPoints={highlightedRectangle.slicePoints}
                />
              ) : (
                <></>
              )}
              {tooltip && highlightedRectangle ? (
                tooltip(highlightedRectangle)
              ) : (
                <></>
              )}
            </>
          </ControlledInformationTooltip>
        </>
      }
      {children}
    </StyledChartContainer>
  );
};

export default MultiChartRectangleContainer;
