import { Layer, Point } from "@nivo/line";
import { TemperatureSeriesIds } from "components/dataVisualization/Temperature/TemperatureChart/TemperatureDatasets";
import { WindGenSeriesIds } from "components/dataVisualization/WindGeneration/WindGenerationChart/WindGenDatasets";
import {
  DgwsProcess,
  DgwsPsr,
  getDgwsColour,
  getDgwsDataKey,
  // eslint-disable-next-line max-len
} from "components/dataVisualization/generationForecastForWindAndSolar/GenerationForecastForWindAndSolarChart/GenerationForecastForWindAndSolarDatasets";
import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";
import { DisaggregatedBalancingServicesAdjustmentIds } from "models/disaggregatedBalancingServicesAdjustment/disaggregatedBalancingServicesAdjustmentSummaryModel";
import { chartColours, disbsadDatasetColours } from "styles/colours";
import {
  generateDashedLine,
  generateDatasetLines,
  generateFixedLengthHorizontalLine,
  generateHorizontalLine,
  generateReferenceArea,
  HighlightLines,
} from "utils/ChartDrawUtils";
import {
  DrawCircle,
  DrawDiamond,
  DrawSquare,
  generateDumbbells,
} from "utils/ChartDrawUtils/dumbbellDrawUtils";
import { generateMouseoverAreas } from "utils/ChartDrawUtils/mouseoverAreaUtils";

const lowerLayers: Layer[] = ["grid", "markers"];

const middleLayers: Layer[] = ["areas", "lines"];

const upperLayers: Layer[] = [
  "axes",
  "crosshair",
  "points",
  "slices",
  "mesh",
  "legends",
];

export const chartLayers: Layer[] = [
  ...lowerLayers,
  ...middleLayers,
  ...upperLayers,
];

export const layersWithCustomAreasAndLines = (
  ...customMiddleLayers: Layer[]
): Layer[] => [...lowerLayers, ...customMiddleLayers, ...upperLayers];

export const insertLayerAfterTargetLayer = (
  layerToInsertAfter: Layer,
  newLayer: Layer,
  options: { chartLayers?: Layer[] } = {}
): Layer[] =>
  (options.chartLayers ?? chartLayers).flatMap((layer) =>
    layer === layerToInsertAfter ? [layerToInsertAfter, newLayer] : layer
  );

export const chartLayersWithAreas = (
  onMouseOver: (point: Point) => void,
  onMouseLeave: () => void
): Layer[] =>
  layersWithCustomAreasAndLines(
    "areas",
    "lines",
    HighlightLines,
    generateMouseoverAreas(onMouseOver, onMouseLeave)
  );

export const chartLayersWithReferenceLine = (
  amount: number,
  colour: string
): Layer[] =>
  layersWithCustomAreasAndLines(
    generateHorizontalLine(amount, colour),
    "lines"
  );

export const chartLayersWithFixedLengthReferenceLine = (
  amount: number,
  colour: string,
  xMin: Date,
  xMax: Date
): Layer[] =>
  layersWithCustomAreasAndLines(
    generateFixedLengthHorizontalLine(amount, colour, xMin, xMax),
    "lines"
  );

export const chartLayersWithTemperatureReference: Layer[] =
  layersWithCustomAreasAndLines(
    generateReferenceArea(
      chartColours.temperatureReference,
      TemperatureSeriesIds.High,
      TemperatureSeriesIds.Low
    ),
    "lines",
    generateDashedLine(
      TemperatureSeriesIds.Normal,
      chartColours.temperatureReferenceNormal
    )
  );

export const chartLayersWithDatasetLines = (
  datasets: ChartDatasetModel[]
): Layer[] => layersWithCustomAreasAndLines(generateDatasetLines(datasets));

export const chartLayersWithWindGenerationDumbbells: Layer[] =
  layersWithCustomAreasAndLines(
    generateDumbbells(
      {
        serieId: WindGenSeriesIds.LatestForecast,
        colour: chartColours.windGenerationLatestForecast,
        dumbbellShapeFunction: DrawSquare,
      },
      {
        serieId: WindGenSeriesIds.InitialForecast,
        colour: chartColours.windGenerationInitialForecast,
        dumbbellShapeFunction: DrawDiamond,
      }
    ),
    "lines"
  );

const getDumbellsForPsr = (psr: DgwsPsr): Layer[] => {
  // We create three dumbbells for each PSR to give the illusion
  // that all three process points are connected, even when
  // one of the lines is toggled off.
  //
  // We cannot use innerPointSerie because we do not know
  // which value will be in the middle.
  //
  // It would be nice to refactor generateDumbbells to take an
  // arbitrary length of points to connect, but this suffices
  // for now.
  return [
    generateDumbbells(
      {
        serieId: getDgwsDataKey(psr, DgwsProcess.DayAhead),
        colour: getDgwsColour(psr, DgwsProcess.DayAhead),
        dumbbellShapeFunction: DrawSquare,
      },
      {
        serieId: getDgwsDataKey(psr, DgwsProcess.Current),
        colour: getDgwsColour(psr, DgwsProcess.Current),
        dumbbellShapeFunction: DrawCircle,
      }
    ),
    generateDumbbells(
      {
        serieId: getDgwsDataKey(psr, DgwsProcess.DayAhead),
        colour: getDgwsColour(psr, DgwsProcess.DayAhead),
        dumbbellShapeFunction: DrawSquare,
      },
      {
        serieId: getDgwsDataKey(psr, DgwsProcess.Intraday),
        colour: getDgwsColour(psr, DgwsProcess.Intraday),
        dumbbellShapeFunction: DrawDiamond,
      }
    ),
    generateDumbbells(
      {
        serieId: getDgwsDataKey(psr, DgwsProcess.Current),
        colour: getDgwsColour(psr, DgwsProcess.Current),
        dumbbellShapeFunction: DrawCircle,
      },
      {
        serieId: getDgwsDataKey(psr, DgwsProcess.Intraday),
        colour: getDgwsColour(psr, DgwsProcess.Intraday),
        dumbbellShapeFunction: DrawDiamond,
      }
    ),
  ];
};

export const chartLayersWithDGWSDumbbells: Layer[] =
  layersWithCustomAreasAndLines(
    ...getDumbellsForPsr(DgwsPsr.Solar),
    ...getDumbellsForPsr(DgwsPsr.WindOffshore),
    ...getDumbellsForPsr(DgwsPsr.WindOnshore),
    "lines"
  );

export const chartLayersWithDisbsadPriceDumbbells: Layer[] =
  layersWithCustomAreasAndLines(
    generateDumbbells(
      {
        serieId: DisaggregatedBalancingServicesAdjustmentIds.BuyPriceMin,
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.BuyPriceMin
          ]!,
        dumbbellShapeFunction: DrawCircle,
        opacity: 0.7,
      },
      {
        serieId: DisaggregatedBalancingServicesAdjustmentIds.BuyPriceMax,
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.BuyPriceMax
          ]!,
        dumbbellShapeFunction: DrawCircle,
        opacity: 0.7,
      },
      {
        innerPointSerie: {
          serieId: DisaggregatedBalancingServicesAdjustmentIds.BuyPriceAvg,
          colour:
            disbsadDatasetColours[
              DisaggregatedBalancingServicesAdjustmentIds.BuyPriceAvg
            ]!,
          dumbbellShapeFunction: DrawCircle,
        },
        outerPointSize: 3,
        innerPointSize: 5,
      }
    ),
    generateDumbbells(
      {
        serieId: DisaggregatedBalancingServicesAdjustmentIds.SellPriceMin,
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.SellPriceMin
          ]!,
        dumbbellShapeFunction: DrawDiamond,
        opacity: 0.7,
      },
      {
        serieId: DisaggregatedBalancingServicesAdjustmentIds.SellPriceMax,
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.SellPriceMax
          ]!,
        dumbbellShapeFunction: DrawDiamond,
        opacity: 0.7,
      },
      {
        innerPointSerie: {
          serieId: DisaggregatedBalancingServicesAdjustmentIds.SellPriceAvg,
          colour:
            disbsadDatasetColours[
              DisaggregatedBalancingServicesAdjustmentIds.SellPriceAvg
            ]!,
          dumbbellShapeFunction: DrawDiamond,
        },
        outerPointSize: 3,
        innerPointSize: 5,
      }
    ),
    "lines"
  );

export const chartLayersAreasWithCustomLines = (
  datasets: ChartDatasetModel[]
): Layer[] =>
  layersWithCustomAreasAndLines("areas", generateDatasetLines(datasets));
