import { SoSoTradePricesModel } from "models/soSoTradePrices/soSoTradePricesModel";
import { SoSoTradePricesTableModel } from "models/soSoTradePrices/soSoTradePricesTableModel";

export function mapToSoSoTradePricesRow(
  soSoTradePricesData: SoSoTradePricesModel[]
): SoSoTradePricesTableModel[] {
  return soSoTradePricesData.map((row) => ({
    id: row.contractIdentification,
    traderUnit: row.traderUnit,
    settlementDate: new Date(row.settlementDate),
    startTime: new Date(row.startTime),
    tradeDirection: row.tradeDirection,
    contractIdentification: row.contractIdentification,
    tradeQuantity: row.tradeQuantity,
    tradePrice: row.tradePrice,
  }));
}
