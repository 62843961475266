import { getAllBmUnits } from "api/dataVisualisation/reference/reference";
import { BalancingMechanismTabName } from "components/components/Tabs/Tabs/Tabs";
import { MarketViewBmuFilters } from "components/components/balancingMechanism/marketView/MarketViewFilters/helpers";
import useMarketViewBalancingServicesVolumeData from "components/dataVisualization/balancingMechanismTabs/BalancingServicesVolume/marketView/useMarketViewBalancingServicesVolumeData";
import useMarketViewBidOfferData from "components/dataVisualization/balancingMechanismTabs/BidOffer/marketView/useMarketViewBidOfferData";
import useMarketViewDynamicData from "components/dataVisualization/balancingMechanismTabs/Dynamic/marketView/useMarketViewDynamicData";
import useMarketViewPhysicalData from "components/dataVisualization/balancingMechanismTabs/Physical/marketView/useMarketViewPhysicalData";
import useFetchDataIfActiveTabAndRequestHasChanged from "hooks/useFetchDataIfRequestHasChanged";
import useRequest from "hooks/useRequest";
import BidOfferData from "models/bidOffer/bidOfferData";
import { BalancingServicesVolumeModel } from "models/bmuData/balancingServicesVolumeModel";
import { BmuModel } from "models/bmuData/bmuModel";
import { DynamicTabDataStructure } from "models/bmuData/marketViewDynamicTabModels";
import { PhysicalTabDataStructure } from "models/bmuData/marketViewPhysicalTabModels";
import {
  RequestDetails,
  RequestDetailsWithDatasetArg,
} from "models/requestDetails";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface Props {
  activeTabTitle: BalancingMechanismTabName;
  selectedDate: Date;
}

export interface MarketViewContextType {
  bmuFilters: MarketViewBmuFilters;
  setBmuFilters: (value: MarketViewBmuFilters) => void;
  bmuDetails: RequestDetails<BmuModel[]>;
  physicalDetails: RequestDetailsWithDatasetArg<
    Partial<PhysicalTabDataStructure>
  >;
  dynamicDetails: RequestDetailsWithDatasetArg<
    Partial<DynamicTabDataStructure>
  >;
  bidOfferDetails: RequestDetails<BidOfferData[]>;
  balancingServicesVolumeDetails: RequestDetails<
    BalancingServicesVolumeModel[]
  >;
  selectedDate: Date | undefined;
}

export const MarketViewContext = React.createContext<
  MarketViewContextType | undefined
>(undefined);

export const MarketViewContextProvider: React.FC<PropsWithChildren<Props>> = ({
  activeTabTitle,
  selectedDate,
  children,
}) => {
  const [searchParams] = useSearchParams();
  const [bmuFilters, setBmuFilters] = useState<MarketViewBmuFilters>({
    leadParty: searchParams.get("leadParty"),
    bmuType: searchParams.get("bmuType"),
    fuelType: searchParams.get("fuelType"),
  });

  const bmuDetails = useRequest(getAllBmUnits);
  const { data: bmuData, request: bmuRequest } = bmuDetails;
  useEffect(() => {
    bmuRequest();
  }, [bmuRequest]);

  const physicalDetails = useMarketViewPhysicalData(
    selectedDate,
    bmuFilters,
    bmuData
  );

  const dynamicDetails = useMarketViewDynamicData(
    selectedDate,
    bmuFilters,
    bmuData
  );

  const bidOfferDetails = useMarketViewBidOfferData(
    selectedDate,
    bmuFilters,
    bmuData
  );

  const balancingServicesVolumeDetails =
    useMarketViewBalancingServicesVolumeData(selectedDate, bmuFilters, bmuData);

  useFetchDataIfActiveTabAndRequestHasChanged(
    activeTabTitle === "Bid Offer",
    bidOfferDetails.request
  );

  useFetchDataIfActiveTabAndRequestHasChanged(
    activeTabTitle === "Balancing Services Volume",
    balancingServicesVolumeDetails.request
  );

  const contextValue = useMemo(
    () => ({
      bmuFilters,
      setBmuFilters,
      bmuDetails,
      physicalDetails,
      dynamicDetails,
      bidOfferDetails,
      balancingServicesVolumeDetails,
      selectedDate,
    }),
    [
      bmuDetails,
      bmuFilters,
      dynamicDetails,
      physicalDetails,
      bidOfferDetails,
      balancingServicesVolumeDetails,
      selectedDate,
    ]
  );

  return (
    <MarketViewContext.Provider value={contextValue}>
      {children}
    </MarketViewContext.Provider>
  );
};

export const useMarketViewContext = (): MarketViewContextType => {
  const context = React.useContext(MarketViewContext);

  if (context === undefined) {
    throw new Error(
      "useMarketViewContext must be used within MarketViewContext's Provider"
    );
  }

  return context;
};
