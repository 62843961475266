import { ResponsiveLine } from "@nivo/line";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { TabulatedChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/TabulatedChartTooltip";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import OutageProfile from "models/remit/outageProfile";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayers } from "styles/chartStyles/chartLayers";
import { chartColours } from "styles/colours";
import { defaultColumnFormatter } from "utils/ChartDataUtils";
import { transformOutageProfileData } from "utils/ChartDataUtils/outageProfile/outageProfile";

interface Props {
  data: OutageProfile[];
}

const datasetCategories: DatasetCategory<ChartDatasetModel>[] = [
  {
    datasets: [
      {
        colour: chartColours.remitOutageProfile,
        dataKey: "availableCapacity",
        displayName: "Available capacity",
      },
    ],
    id: "datasets",
  },
];

const config: ChartConfiguration = {
  datasetCategories,
  tooltipColumns: [
    {
      id: "value",
      dataKey: "y",
      formatter: defaultColumnFormatter({ suffix: "MW" }),
    },
  ],
};

const OutageProfileChart: React.FC<Props> = ({ data }: Props) => {
  const series = useMemo(
    () => (data ? transformOutageProfileData(data) : []),
    [data]
  );

  return (
    <ResponsiveLine
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...commonChartProps(true, series)}
      enableSlices="x"
      axisBottom={chartAxisBottom("Start Time (UTC)", series, true)}
      axisLeft={chartAxisLeft("MW", true)}
      curve="stepAfter"
      layers={chartLayers}
      sliceTooltip={(input): JSX.Element => (
        <TabulatedChartTooltip
          series={series}
          config={config}
          options={{
            interactionType: TooltipInteractionType.Slice,
          }}
          inputPoints={input.slice.points}
        />
      )}
      xScale={chartXTimeScale()}
      yScale={nonStackedLineChartYScale(series)}
    />
  );
};

export default OutageProfileChart;
