import styled from "styled-components";
import colours from "styles/colours";

export const Title = styled.h3``;

export const Box = styled.div`
  border: 1px solid ${colours.elexonGrey};
  border-radius: 5px;
  color: ${colours.elexonBlue};
  display: flex;
  padding: 10px;
  width: 100%;

  ${Title} {
    color: ${colours.elexonBlue};
    margin-bottom: 0px;
    font-size: 1.1em;
  }
`;

export const IconContainer = styled.div`
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
`;
