import React, { useEffect, useRef } from "react";

/*
  Detects mousedown events outside the given element and calls the supplied function. 
  Returns a ref object which should be attached to the outermost element for which we
  want to detect external events.

  Note the function supplied should be as stable as possible since every time it
  changes the event listeners will be added/removed. This usually means that you
  should use useCallback.
*/
const useOnClickOutside = <TRef extends HTMLElement>(
  onMouseDown: () => void
): React.RefObject<TRef> => {
  const parentRef = useRef<TRef>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        parentRef.current &&
        !parentRef.current.contains(event.target as Node)
      ) {
        onMouseDown();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return (): void => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onMouseDown, parentRef]);

  return parentRef;
};

export default useOnClickOutside;
