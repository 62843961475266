import { getData } from "api/dataVisualisation/client";
import { CreditDefaultNoticeModel } from "models/creditDefaultNotice/creditDefaultNoticeModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";

export const getCreditDefaultNoticeData = async (
  format?: DataDownloadFormat
): Promise<DataDownloadModel<CreditDefaultNoticeModel>> => {
  const { data } = await getData("/balancing/settlement/default-notices", {
    format,
  });
  return data;
};

export const getCreditDefaultNoticeJsonData = async (): Promise<
  JsonDataDownloadModel<CreditDefaultNoticeModel>
> =>
  getCreditDefaultNoticeData(DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<CreditDefaultNoticeModel>
  >;
