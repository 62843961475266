import { HttpStatusCode } from "axios";
import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import RerequestButton from "components/components/RerequestButton/RerequestButton";
import TileChild from "components/components/TileChild/TileChild";
import DetailedSystemPricesCaution from "components/dataVisualization/DetailedSystemPrices/DetailedSystemPricesCaution/DetailedSystemPricesCaution";
import SystemPricesSummaryTable from "components/dataVisualization/DetailedSystemPrices/DetailedSystemPricesSummary/SystemPricesSummaryTableData/SystemPricesSummaryTable";
import { ConsolidatedData } from "components/dataVisualization/DetailedSystemPrices/SystemPricesHooks/useSystemPricesConsolidatedData";
import {
  Container,
  ErrorContainer,
  SpinnerContainer,
  SpinnerParent,
} from "components/dataVisualization/DetailedSystemPrices/style";
import { RequestStatus } from "hooks/useRequest";
import { SettlementCalculationSummaryModelTransformed } from "models/detailedSystemPrices/settlementModels";
import React from "react";

interface Props {
  request: ConsolidatedData;
}

const DetailedSystemPricesSummary: React.FC<Props> = ({ request }) => {
  const { data, status, errorCode, fetch } = request;

  const renderLoadingSpinnerOrTableOrError = (
    summaryData: SettlementCalculationSummaryModelTransformed | null,
    summaryStatus: RequestStatus,
    summaryErrorCode: number | null,
    summaryFetch: () => void
  ): JSX.Element => {
    if (status === RequestStatus.IN_PROGRESS) {
      return (
        <SpinnerContainer>
          <SpinnerParent>
            <LoadingSpinner isLoading />
          </SpinnerParent>
        </SpinnerContainer>
      );
    }

    const noDataForSettlementPeriod =
      summaryStatus === RequestStatus.ERRORED &&
      summaryErrorCode === HttpStatusCode.NotFound;

    if (
      summaryStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED ||
      noDataForSettlementPeriod
    ) {
      return <SystemPricesSummaryTable data={summaryData} />;
    }

    return (
      <ErrorContainer>
        <RerequestButton rerequestData={summaryFetch} />
      </ErrorContainer>
    );
  };

  return (
    <div data-test-id="calculation-summary">
      {data.messages.length > 0 && (
        <DetailedSystemPricesCaution filteredMessagesData={data.messages} />
      )}
      <TileChild>
        <Container>
          <h2>Calculation Summary</h2>
          {renderLoadingSpinnerOrTableOrError(
            data.summary,
            status,
            errorCode.summary,
            fetch.summary
          )}
        </Container>
      </TileChild>
    </div>
  );
};

export default DetailedSystemPricesSummary;
