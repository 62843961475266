import InfoBox from "components/components/InfoBox/InfoBox";
import { ModalSection } from "components/components/Modal/commonStyle";
import DataExporterContainer from "components/dataVisualization/dataExporterComponents/DataExporterContainer/DataExporterContainer";
import DataExporterHeader from "components/dataVisualization/dataExporterComponents/dataExporterSections/DataExporterHeader";
import FileFormatSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/FileFormatSection";
import TimeFrameSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/TimeFrameSection";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useState } from "react";

interface Props {
  dateFilter: DateFilterModel;
  handleChangeToDateFilter: (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ) => void;
  downloadData: (fileFormat: DataDownloadFormat) => void;
  activeDateSelectorTab: DateSelectorTabs;
}

const AdjustmentActionsDisbsadDataExporter: React.FC<Props> = ({
  dateFilter,
  handleChangeToDateFilter,
  downloadData,
  activeDateSelectorTab,
}: Props) => {
  const [downloadFormat, setDownloadFormat] = useState(DataDownloadFormat.Csv);

  const handleExportClick = (): void => {
    downloadData(downloadFormat);
  };

  return (
    <DataExporterContainer onExportClick={handleExportClick}>
      <DataExporterHeader />
      <ModalSection>
        <TimeFrameSection
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateFilter}
          dateTabs={[DateSelectorTabs.TwentyFourHours]}
          activeTab={activeDateSelectorTab}
          customDateNewLine={false}
        />
        <FileFormatSection
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
        />
      </ModalSection>
      <ModalSection>
        <InfoBox
          title="Exports are limited to 24 hours."
          description="If a time range longer than this was selected on the chart, the latest 24 hours of this range will be exported by default."
        />
      </ModalSection>
    </DataExporterContainer>
  );
};

export default AdjustmentActionsDisbsadDataExporter;
