import { DerivedDataTabName } from "components/components/Tabs/Tabs/Tabs";
import useAcceptanceVolumeData from "components/dataVisualization/DerivedData/useAcceptanceVolumeData";
import useIndicativeCashflowData from "components/dataVisualization/DerivedData/useIndicativeCashflowData";
import useIndicativeVolumeData from "components/dataVisualization/DerivedData/useIndicativeVolumeData";
import { BidOfferQueryIndicator } from "components/dataVisualization/DetailedSystemPrices/utils";
import useFetchDataIfActiveTabAndRequestHasChanged from "hooks/useFetchDataIfRequestHasChanged";
import { BmuModel } from "models/bmuData/bmuModel";
import { AcceptanceVolumeModel } from "models/derivedData/acceptanceVolumeModel";
import { IndicativeCashflowModel } from "models/derivedData/indicativeCashflow";
import { IndicativeVolumeModel } from "models/derivedData/indicativeVolumeModel";

import { RequestStatus } from "./useRequest";

type DerivedDataType = {
  bidAcceptanceData: {
    fetchData: () => void;
    data: AcceptanceVolumeModel[] | null;
    status: RequestStatus;
  };
  offerAcceptanceData: {
    fetchData: () => void;
    data: AcceptanceVolumeModel[] | null;
    status: RequestStatus;
  };
  indicativeCashflowBidData: {
    fetchData: () => void;
    data: IndicativeCashflowModel[] | null;
    status: RequestStatus;
  };
  indicativeCashflowOfferData: {
    fetchData: () => void;
    data: IndicativeCashflowModel[] | null;
    status: RequestStatus;
  };
  indicativeVolumeBidData: {
    fetchData: () => void;
    data: IndicativeVolumeModel[] | null;
    status: RequestStatus;
  };
  indicativeVolumeOfferData: {
    fetchData: () => void;
    data: IndicativeVolumeModel[] | null;
    status: RequestStatus;
  };
};

export const useDerivedData = (
  settlementTime: Date,
  bmus: BmuModel[] | undefined,
  activeTabTitle: DerivedDataTabName
): DerivedDataType => {
  const bidAcceptanceData = useAcceptanceVolumeData(
    settlementTime,
    BidOfferQueryIndicator.BID,
    bmus
  );

  const offerAcceptanceData = useAcceptanceVolumeData(
    settlementTime,
    BidOfferQueryIndicator.OFFER,
    bmus
  );

  const indicativeCashflowBidData = useIndicativeCashflowData(
    settlementTime,
    BidOfferQueryIndicator.BID,
    bmus
  );

  const indicativeCashflowOfferData = useIndicativeCashflowData(
    settlementTime,
    BidOfferQueryIndicator.OFFER,
    bmus
  );

  const indicativeVolumeBidData = useIndicativeVolumeData(
    settlementTime,
    BidOfferQueryIndicator.BID,
    bmus
  );

  const indicativeVolumeOfferData = useIndicativeVolumeData(
    settlementTime,
    BidOfferQueryIndicator.OFFER,
    bmus
  );

  useFetchDataIfActiveTabAndRequestHasChanged(
    activeTabTitle === "Bid Acceptance Volume",
    bidAcceptanceData.fetchData
  );

  useFetchDataIfActiveTabAndRequestHasChanged(
    activeTabTitle === "Offer Acceptance Volume",
    offerAcceptanceData.fetchData
  );

  useFetchDataIfActiveTabAndRequestHasChanged(
    activeTabTitle === "Indicative Period BM Unit Cashflows",
    indicativeCashflowBidData.fetchData
  );

  useFetchDataIfActiveTabAndRequestHasChanged(
    activeTabTitle === "Indicative Period BM Unit Cashflows",
    indicativeCashflowOfferData.fetchData
  );

  useFetchDataIfActiveTabAndRequestHasChanged(
    activeTabTitle === "Indicative Period BM Unit Acceptance Volumes",
    indicativeVolumeBidData.fetchData
  );

  useFetchDataIfActiveTabAndRequestHasChanged(
    activeTabTitle === "Indicative Period BM Unit Acceptance Volumes",
    indicativeVolumeOfferData.fetchData
  );

  return {
    bidAcceptanceData,
    offerAcceptanceData,
    indicativeCashflowBidData,
    indicativeCashflowOfferData,
    indicativeVolumeBidData,
    indicativeVolumeOfferData,
  };
};
