/**
 * Returns a new Date object, with the time part given by parsing input strings as hours & minutes.
 *
 * @param {Date} date The reference date to set the time on
 * @param {string} hourString Represents hours in 24h format
 * @param {string} minString Represents minutes
 * @return {Date} a Date with the parsed time and the same date as the input date
 */
export const parseTimeOnGivenDate = (
  date: Date,
  hourString: string,
  minString: string
): Date => {
  const hourVal = parseInt(hourString, 10);
  const minVal = parseInt(minString, 10);

  const newDate = new Date(date);
  newDate.setUTCHours(hourVal, minVal, 0, 0);
  return newDate;
};

/**

 Returns a new Date object, parsed from a string in the format "DD/MM/YYYY - hh:mm".
 @param {string} dateString The input string representing the date and time from the date picker text
 @return {Date} a new Date object, with the date and time set according to the input string
 */
export const parseInputDate = (dateString: string): Date => {
  const trimmed = dateString.trim();
  if (!trimmed.match(/^\d{1,2}\/\d{1,2}\/\d{4} - \d{1,2}:\d{2}$/)) {
    throw Error("Tried to parse date but did not match expected format.");
  }

  const [datePart, timePart] = trimmed.split(" - ");
  const [day, month, year] = datePart.split("/");
  const [hour, minute] = timePart.split(":");
  return new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      parseInt(hour),
      parseInt(minute)
    )
  );
};
