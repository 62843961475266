import TabModal, {
  ModalProps,
} from "components/components/balancingMechanism/balancingMechanismTabs/descriptionModals/TabModal";
import React from "react";

const BalancingServicesVolumeModal: React.FC<ModalProps> = ({
  isOpen,
  close,
}) => (
  <TabModal
    title="Balancing Services Volume"
    headingId="balancing-services-volume-modal-header"
    isOpen={isOpen}
    close={close}
  >
    <>
      <p>
        BM Units can provide Balancing Services outside of the Balancing
        Mechanism. These services include:
      </p>
      <ul>
        <li>Short Term Operating Reserve (STOR)</li>
        <li>Negative Slow Reserve (NSR)</li>
        <li>Fast Reserve</li>
        <li>Demand Turn Up</li>
        <li>Operational Downward Flexibility Management (ODFM)</li>
      </ul>
      <p>
        It is calculated and provided by NESO. This volume is added to the total
        accepted bid and offer volumes for the relevant BM Unit when settlement
        calculations are performed. Values can be positive or negative. If no
        data is shown for a BM Unit, no activity outside of the Balancing
        Service has taken place for that period.
      </p>
    </>
  </TabModal>
);

export default BalancingServicesVolumeModal;
