import React from "react";
import { setInputCheckmarks } from "utils/checkboxListHelpers";

import { CheckboxLabel, CustomCheckbox, NativeCheckboxInput } from "./style";

type CheckboxProps = {
  inputId: string;
  ariaLabelText?: string;
  isChecked?: boolean;
  isIndeterminate?: boolean;
  isInline?: boolean;
  onChange?: () => void;
  inlineLabelContent?: string | JSX.Element;
};

const Checkbox: React.FC<CheckboxProps> = ({
  inputId,
  ariaLabelText,
  isChecked = false,
  isIndeterminate = false,
  isInline = false,
  onChange,
  inlineLabelContent,
}) => (
  <>
    <CheckboxLabel htmlFor={inputId} isInline={isInline}>
      <NativeCheckboxInput
        id={inputId}
        aria-label={ariaLabelText}
        data-test-id={`${ariaLabelText}-checkbox`}
        type="checkbox"
        onChange={onChange}
        ref={(input): void => {
          setInputCheckmarks(input, isChecked, isIndeterminate);
        }}
        isInline={isInline}
      />
      <CustomCheckbox
        isChecked={isChecked}
        isIndeterminate={isIndeterminate}
        isInline={isInline}
      />
      {inlineLabelContent}
    </CheckboxLabel>
  </>
);

export default Checkbox;
