import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import colours from "styles/colours";

const buttonStyle = css`
  color: ${colours.charcoal};
  background-color: ${colours.elexonYellow};
  border-radius: 200px;
  text-transform: uppercase;
  padding: 5px 35px;
  font-weight: bold;
  letter-spacing: 1.85px;
  height: 35px;

  :hover {
    text-decoration: underline;
  }

  :active {
    box-shadow: inset 5px 5px 8px ${colours.elexonBlack}60;
  }

  &&:focus-visible {
    outline: 2px solid ${colours.elexonBlack};
    box-shadow: none;
  }
`;

export const StyledButtonLink = styled(Link)`
  ${buttonStyle};

  &&.enable-height {
    display: inline-block;
  }
`;

export const StyledButtonAnchor = styled.a`
  ${buttonStyle};
  display: block;
  width: fit-content;
`;

export const IconWrapper = styled.div`
  margin-left: 0.6em;
  display: inline-block;
`;
