/**
 * Formats the supplied number (in MWh) as a string with the unit GWh
 * to {decimalPlaces} number of decimal places, without adding the UoM
 * (Unit of Measurement) at the end of the string
 * @param mWhValue input value in MWh
 * @param decimalPlaces number of decimal places to round to, default 1
 */
export const formatMWhasGWhWithoutUoM = (
  mWhValue: number,
  decimalPlaces: number = 1
): string => `${(mWhValue / 1000).toFixed(decimalPlaces)}`;

/**
 * Formats the supplied number into GB locale (comma as thousands separator)
 * with {decimalPlaces} number of decimal places.
 * @param number input value
 * @param decimalPlaces number of decimal places to round to, default 0
 */
export const formatNumberToGBLocale = (
  number: number,
  decimalPlaces: number = 0
): string =>
  number.toLocaleString("en-GB", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
