import styled, { css, FlattenSimpleInterpolation } from "styled-components";

export const HideableContainer = styled.div<{
  isHidden?: boolean;
}>`
  ${({ isHidden }): false | FlattenSimpleInterpolation =>
    !!isHidden &&
    css`
      & {
        display: none;
      }
    `}
`;

export const InlineSwatchWrapper = styled.span`
  & > span {
    margin-left: 0.2em;
    margin-right: 0.2em;
  }
`;
