import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { StandardChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/StandardChartTooltip";
import {
  AxisDirectionLabelBottom,
  AxisDirectionLabelTop,
} from "components/dataVisualization/chartComponents/style";
import { RequestStatus } from "hooks/useRequest";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import React, { useEffect, useMemo, useState } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import {
  chartLayers,
  chartLayersWithReferenceLine,
} from "styles/chartStyles/chartLayers";
import { chartColours } from "styles/colours";
import {
  generateFixedArea,
  generateHorizontalLine,
} from "utils/ChartDrawUtils";
import { getExtremaForNonStackedLineChartYScaleWithMinMax } from "utils/chartHelpers";
import { DateFormat } from "utils/dateHelpers";

interface Props {
  series: Serie[];
  dataFetchStatus: RequestStatus;
  rerequestData: () => void;
  showReferenceData: boolean;
}

const datasetCategories: DatasetCategory<ChartDatasetModel>[] = [
  {
    datasets: [
      {
        colour: chartColours.frequencyData,
        dataKey: "frequency",
        displayName: "System frequency",
        unitSuffix: "Hz",
      },
    ],
    id: "datasets",
  },
];

const RollingSystemFrequencyChart: React.FC<Props> = ({
  series,
  dataFetchStatus,
  rerequestData,
  showReferenceData,
}: Props) => {
  const [layers, setLayers] = useState(chartLayers);
  const [yScale, setYScale] = useState(
    nonStackedLineChartYScale(series, { centre: 50 })
  );

  useEffect(() => {
    if (showReferenceData) {
      setLayers([
        generateFixedArea(49.5, 50.5, chartColours.statutoryFrequency), // statutory frequency area
        generateFixedArea(49.8, 50.2, chartColours.nationalGridFrequency), // NESO area
        "grid",
        "markers",
        "axes",
        generateHorizontalLine(50, chartColours.referenceLine), // 50 Hz reference line
        "lines",
        "crosshair",
        "points",
        "slices",
        "mesh",
        "legends",
      ]);

      const { min, max } = getExtremaForNonStackedLineChartYScaleWithMinMax(
        49.5,
        50.5
      );

      setYScale({
        type: "linear",
        stacked: false,
        min,
        max,
      });
    } else {
      setLayers(chartLayersWithReferenceLine(50, chartColours.referenceLine)); // 50 Hz reference line
      setYScale(nonStackedLineChartYScale(series, { centre: 50 }));
    }
  }, [showReferenceData, series]);

  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;
    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        enableSlices="x"
        axisBottom={chartAxisBottom("Time (UTC)", series, isChartLoaded)}
        axisLeft={chartAxisLeft("Frequency (Hz)", isChartLoaded)}
        layers={layers}
        sliceTooltip={(input): JSX.Element => (
          <StandardChartTooltip
            series={series}
            datasetCategories={datasetCategories}
            text={{ label: "Time" }}
            options={{
              interactionType: TooltipInteractionType.Slice,
              dateFormat: DateFormat.DateTimeWithSeconds,
            }}
            inputPoints={input.slice.points}
          />
        )}
        xScale={chartXTimeScale()}
        yScale={yScale}
      />
    );
  }, [series, dataFetchStatus, layers, yScale]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      >
        <>
          {memoisedChart}
          <AxisDirectionLabelTop>Generation is higher</AxisDirectionLabelTop>
          <AxisDirectionLabelBottom>Demand is higher</AxisDirectionLabelBottom>
        </>
      </ChartWithErrorHandling>
    ),
    [dataFetchStatus, memoisedChart, rerequestData, series]
  );
};

export default RollingSystemFrequencyChart;
