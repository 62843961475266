import styled from "styled-components";
import colours from "styles/colours";

export const Drawer = styled.div`
  margin-top: 10px;
  width: 20vw;
  padding-left: 20px;
  border-right: 3px solid ${colours.lightGrey};
  background-color: ${colours.lightGrey};
  min-width: 200px;
`;
