import { ResponsiveLine, Serie } from "@nivo/line";
import {
  initialForecastDatasets,
  initialOutturnDatasets,
} from "components/dataVisualization/DemandForecast/DemandForecastChartContainer/datasets";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { StandardChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/StandardChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayersWithDatasetLines } from "styles/chartStyles/chartLayers";

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  dataFetchStatus: RequestStatus;
  rerequestData: () => void;
}

const DemandForecastChart: React.FC<Props> = ({
  series,
  datasetCategories,
  dataFetchStatus,
  rerequestData,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        enableSlices="x"
        axisBottom={chartAxisBottom("Start Time (UTC)", series, isChartLoaded)}
        axisLeft={chartAxisLeft("MW", isChartLoaded)}
        layers={chartLayersWithDatasetLines([
          ...initialForecastDatasets,
          ...initialOutturnDatasets,
        ])}
        sliceTooltip={(input): JSX.Element => (
          <StandardChartTooltip
            series={series}
            datasetCategories={datasetCategories}
            text={{
              label: "Start Time",
            }}
            options={{
              interactionType: TooltipInteractionType.Slice,
            }}
            inputPoints={input.slice.points}
          />
        )}
        xScale={chartXTimeScale()}
        yScale={nonStackedLineChartYScale(series)}
      />
    );
  }, [dataFetchStatus, series, datasetCategories]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      >
        <>{memoisedChart}</>
      </ChartWithErrorHandling>
    ),
    [dataFetchStatus, datasetCategories, memoisedChart, rerequestData, series]
  );
};

export default DemandForecastChart;
