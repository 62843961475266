import {
  Data,
  RowDl,
  TileTitle,
  TileWrapper,
} from "components/remit/MessageDetails/RemitMessageTiles/style";
import {
  propertyDisplayNames,
  formatRowValue,
  getDisplayNameFromJsonKey,
} from "components/remit/remitMessagePageHelpers";
import RemitMessageDetailsModel from "models/remit/remitMessageDetailsModel";
import React from "react";

const RemitMessageTile: React.FC<{
  tileTitle: string;
  data: Partial<RemitMessageDetailsModel>;
}> = ({ tileTitle, data }) => {
  return (
    <TileWrapper>
      <TileTitle>{tileTitle}</TileTitle>
      <Data>
        {Object.entries(data).map(([key, value]) => (
          <React.Fragment key={key}>
            <RowDl>
              <dt>{getDisplayNameFromJsonKey(key)}</dt>
              <dd>
                {formatRowValue(
                  data,
                  key as keyof RemitMessageDetailsModel,
                  value
                )}
              </dd>
            </RowDl>
            {getDisplayNameFromJsonKey(key) !==
              propertyDisplayNames.messageHeading && <hr />}
          </React.Fragment>
        ))}
      </Data>
    </TileWrapper>
  );
};

export default RemitMessageTile;
