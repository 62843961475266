import { fullWidthStyles } from "components/components/FullWidthSection/style";
import styled, { css } from "styled-components";
import colours from "styles/colours";

export const NavContainer = styled.div`
  ${fullWidthStyles};
  background-color: ${colours.lightGrey};
`;

export const addMarginsToFullWidth = css`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 40px;
`;

export const DivWithMargins = styled.div`
  ${addMarginsToFullWidth};
`;

export const Title = styled.h1`
  font-size: 40px !important;
  margin-left: 10px;
`;

export const Description = styled.p`
  font-size: 18px;
  margin-left: 10px;
`;
