import { getCreditDefaultNoticeJsonData } from "api/dataVisualisation/creditDefaultNotice/creditDefaultNotice";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { CreditDefaultNoticeModel } from "models/creditDefaultNotice/creditDefaultNoticeModel";
import { useCallback } from "react";

const useCreditDefaultNoticeData = (): {
  fetchData: () => void;
  data: CreditDefaultNoticeModel[] | null;
  status: RequestStatus;
} => {
  const {
    data: jsonData,
    status,
    request: fetchCreditDefaultNoticeData,
  } = useRequest(getCreditDefaultNoticeJsonData);

  const data = jsonData ? jsonData.data : null;

  const fetchData = useCallback(() => {
    fetchCreditDefaultNoticeData();
  }, [fetchCreditDefaultNoticeData]);

  return {
    data,
    status,
    fetchData,
  };
};

export default useCreditDefaultNoticeData;
