import getAccessToken from "api/account/tokenClient";
import axios from "axios";

const accountApiBaseUrl = process.env.REACT_APP_ACCOUNT_API_BASE_URL;

interface IrisResponseCommonDetails {
  applicationId: string;
  queueName: string;
  queueUrl: string;
  serviceBusNamespace: string;
}

export interface ClientSecret {
  secretId: string;
  description: string;
  secretHint: string;
  generatedTime: string;
  expiryTime: string;
}

export interface ClientSecretWithValue extends ClientSecret {
  secretValue: string;
}

export interface IrisPostResponse extends IrisResponseCommonDetails {
  applicationSecrets: [ClientSecretWithValue];
}

export interface IrisGetResponse extends IrisResponseCommonDetails {
  applicationSecrets: ClientSecret[];
}

export type IrisSummary = IrisGetResponse | IrisPostResponse;

const createHeaders = (accessToken: string): Record<string, string> => ({
  Authorization: `Bearer ${accessToken}`,
});

const httpGet = async <T>(path: string): Promise<T> => {
  const token = await getAccessToken();
  const url = `${accountApiBaseUrl}${path}`;
  const config = { headers: createHeaders(token) };

  const { data } = await axios.get<T>(url, config);

  return data;
};

const httpPost = async <T>(path: string, body = {}): Promise<T> => {
  const token = await getAccessToken();
  const url = `${accountApiBaseUrl}${path}`;
  const config = { headers: createHeaders(token) };

  const { data } = await axios.post<T>(url, body, config);

  return data;
};

const httpDelete = async <T>(path: string): Promise<T> => {
  const token = await getAccessToken();
  const url = `${accountApiBaseUrl}${path}`;
  const config = { headers: createHeaders(token) };

  const { data } = await axios.delete(url, config);

  return data;
};

export const getIrisConnectionDetails = async (): Promise<IrisGetResponse> =>
  httpGet<IrisGetResponse>("/iris");

export const createIrisConnection = async (): Promise<IrisPostResponse> =>
  httpPost<IrisPostResponse>("/iris");

export const createIrisSecret = async (): Promise<ClientSecretWithValue> =>
  httpPost<ClientSecretWithValue>("/iris/secret");

export const deleteIrisSecret = async (secretId: string): Promise<void> =>
  httpDelete(`/iris/secret/${secretId}`);
