import ResultsCount from "components/components/ResultsCount/ResultsCount";
import SortableTable, {
  TableCellRender,
} from "components/components/SortableTable/SortableTable";
import { NoWrapCell } from "components/components/SortableTable/style";
import { SettlementMessagesTableModel } from "models/settlementMessages/settlementMessagesModel";
import React from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

interface Props {
  data?: SettlementMessagesTableModel[];
}

const SettlementMessagesTable: React.FC<Props> = ({ data }) => {
  return (
    <>
      <ResultsCount count={data ? data.length : 0} />
      <SortableTable
        headers={{
          settlementDate: "Settlement Date",
          settlementPeriod: "Settlement Period",
          messageReceivedDateTime: "Message Received Time",
          messageSeverity: "Message Severity",
          messageType: "Message Type",
          messageText: "Message Text",
        }}
        items={data ?? []}
        renderFns={{
          settlementDate: (el): TableCellRender => ({
            content: (
              <NoWrapCell>
                {formatDateTimeString(el.settlementDate, DateFormat.DateOnly)}
              </NoWrapCell>
            ),
          }),
          messageReceivedDateTime: (el): TableCellRender => ({
            content: (
              <NoWrapCell>
                {el.messageReceivedDateTime
                  ? formatDateTimeString(
                      el.messageReceivedDateTime,
                      DateFormat.DateTime
                    )
                  : "—"}
              </NoWrapCell>
            ),
          }),
        }}
        dataTestId="settlement-messages-table"
        scrollable={false}
      />
    </>
  );
};

export default SettlementMessagesTable;
