import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import colours from "styles/colours";

const horizontalCentredLayout = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WhatsNewSectionContainer = styled.div`
  ${horizontalCentredLayout};
  margin-bottom: 2em;
`;

export const WhatsNewSectionHeader = styled.div`
  ${horizontalCentredLayout};
  margin: 1.2em 1em 2em 0;
`;

export const WhatsNewTitle = styled.div`
  font-size: 28px;
  color: ${colours.elexonPurple};
  font-weight: bold;
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
`;
