import { Datum, Serie } from "@nivo/line";
import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";
import { MarketIndexPriceModel } from "models/marketIndexPrices/marketIndexPricesModel";

const transformSerieData = (data: MarketIndexPriceModel[]): Datum[] =>
  data.reduce(
    (prev, curr) => [
      ...prev,
      {
        x: new Date(curr.startTime),
        y: curr.price,
        settlementPeriod: curr.settlementPeriod,
        volume: curr.volume,
      },
    ],
    [] as Datum[]
  );

export const transformMarketIndexPriceData = (
  rawData: MarketIndexPriceModel[],
  datasets: ChartDatasetModel[]
): Serie[] =>
  datasets
    .filter((dataset) => !dataset.isHidden)
    .map<Serie>((dataset) => {
      const filteredData = rawData.filter(
        (datum) => datum.dataProvider === dataset.dataKey
      );
      return {
        id: dataset.dataKey,
        colour: dataset.colour,
        data: transformSerieData(filteredData),
      };
    });
