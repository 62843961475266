import DateSelector from "components/components/DateSelector/DateSelector";
import ForecastFilterSelector from "components/components/ForecastFilterSelector/ForecastFilterSelector";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";

import { StyledDiv } from "./style";

interface Props {
  forecastFilter: ForecastFilterOption | null;
  handleChangeToForecastFilter: (forecastFilter: ForecastFilterOption) => void;
  dateFilterForDisplay: DateFilterModel;
  handleChangeToDateSelection: (newDateFilter: DateFilterModel) => void;
  maximumDate: Date;
  maxRange: number;
}

const TotalLoadWeekAheadFilters: React.FC<Props> = ({
  forecastFilter,
  handleChangeToForecastFilter,
  dateFilterForDisplay,
  handleChangeToDateSelection,
  maximumDate,
  maxRange,
}: Props) => {
  return (
    <StyledDiv>
      <ForecastFilterSelector
        forecastFilter={forecastFilter}
        handleChangeToForecastFilter={handleChangeToForecastFilter}
        filterTabs={[ForecastFilterOption.weekAhead]}
      />
      <DateSelector
        dateFilter={dateFilterForDisplay}
        handleChangeToDateSelection={handleChangeToDateSelection}
        dateTabs={[]}
        activeTab={forecastFilter ? null : DateSelectorTabs.Date}
        maxRange={maxRange}
        showTimeInput={false}
        maximumDate={maximumDate}
      />
    </StyledDiv>
  );
};

export default TotalLoadWeekAheadFilters;
