import {
  createIrisSecret,
  IrisPostResponse,
  IrisSummary,
} from "api/account/client";
import IrisDetails from "components/components/Iris/AuthenticatedView/IrisDetailsPage/IrisDetails/IrisDetails";
import { FullWidthContainer } from "components/components/Iris/AuthenticatedView/IrisDetailsPage/style";
import ClientSecretModal from "components/components/Iris/ClientSecretModal/ClientSecretModal";
import UsefulLinks from "components/components/Iris/UsefulLinks/UsefulLinks";
import {
  RequestStatus,
  useRequestWithDefaultErrorHandling,
} from "hooks/useRequest";
import React, { useEffect, useMemo, useState } from "react";

type Props = {
  data: IrisSummary;
  fetchIrisDetails: () => void;
  fetchIrisDetailsStatus: RequestStatus;
};

const hasSecretValue = (
  response: IrisSummary
): response is IrisPostResponse => {
  const postResponse = response as IrisPostResponse;
  return (
    postResponse.applicationSecrets.length &&
    !!postResponse.applicationSecrets[0].secretValue
  );
};

const IrisDetailsPage: React.FC<Props> = ({
  data,
  fetchIrisDetails,
  fetchIrisDetailsStatus,
}) => {
  const [showFirstConnectionModal, setShowFirstConnectionModal] =
    useState(false);
  const [showNewSecretModal, setShowNewSecretModal] = useState(false);

  const {
    data: createSecretData,
    request: createSecretRequest,
    status: createSecretStatus,
  } = useRequestWithDefaultErrorHandling(
    createIrisSecret,
    "Sorry, something went wrong when requesting the secret."
  );

  const firstSecretValue = useMemo(
    () =>
      hasSecretValue(data)
        ? data.applicationSecrets?.find((s) => !!s.secretValue)!
        : null,
    [data]
  );

  useEffect(() => {
    if (firstSecretValue) {
      setShowFirstConnectionModal(true);
    }
  }, [firstSecretValue]);

  useEffect(() => {
    if (createSecretStatus === RequestStatus.IN_PROGRESS || createSecretData) {
      setShowNewSecretModal(true);
    }
  }, [firstSecretValue, createSecretStatus, createSecretData]);

  return (
    <>
      <IrisDetails
        data={data}
        fetchIrisDetails={fetchIrisDetails}
        fetchIrisDetailsStatus={fetchIrisDetailsStatus}
        requestNewSecret={createSecretRequest}
      />
      <FullWidthContainer className="container px-6">
        <UsefulLinks title="Useful links" />
      </FullWidthContainer>
      <ClientSecretModal
        // This is the modal for the first time a user ever connects to IRIS
        title="You are now connected to IRIS"
        secret={firstSecretValue}
        isActive={showFirstConnectionModal}
        close={(): void => {
          setShowFirstConnectionModal(false);
          fetchIrisDetails();
        }}
      />
      <ClientSecretModal
        // This is the modal for when a user creates a new secret
        title="Your new client secret"
        isLoading={createSecretStatus === RequestStatus.IN_PROGRESS}
        secret={createSecretData}
        isActive={showNewSecretModal}
        close={(): void => {
          setShowNewSecretModal(false);
          fetchIrisDetails();
        }}
      />
    </>
  );
};

export default IrisDetailsPage;
