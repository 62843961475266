import React, { useEffect, useRef, useState } from "react";

import {
  StyledChartContainer,
  CrosshairLine,
  CrosshairContainer,
} from "./style";

// Set to true to add background to crosshair region to show crosshair container
// Can be used for setting topOffset and height values correctly
const ENABLE_CROSSHAIR_REGION_DEBUG = false;

interface Props {
  children: JSX.Element;
  topOffset?: number;
  height?: number;
  chartIndent?: number;
}

const MultiChartLineContainer: React.FC<Props> = ({
  children,
  topOffset = 29,
  height = 610,
  chartIndent = 0,
}) => {
  const [crosshairPosition, setCrosshairPosition] = useState<number>();
  const container = useRef<HTMLDivElement>(null);

  const onMouseMove = (): void => {
    if (container === null) {
      return;
    }

    setCrosshairPosition(
      container.current!.querySelector<SVGLineElement>(
        "line[style*='dasharray: 999']"
      )?.x1?.baseVal?.value
    );
  };

  useEffect(() => {
    const containerRef = container.current!;
    containerRef.addEventListener("mousemove", onMouseMove);
    containerRef.addEventListener("mouseleave", onMouseMove);

    return (): void => {
      containerRef.removeEventListener("mousemove", onMouseMove);
      containerRef.removeEventListener("mouseleave", onMouseMove);
    };
  }, []);

  return (
    <StyledChartContainer
      ref={container}
      data-test-id="multi-chart-line-container"
    >
      {crosshairPosition !== undefined && (
        <>
          <CrosshairContainer
            backgroundColour={
              ENABLE_CROSSHAIR_REGION_DEBUG ? "#FF00FFAA" : "transparent"
            }
            height={`${height}px`}
            top={`${topOffset}px`}
          >
            <CrosshairLine
              style={{ left: crosshairPosition - 0.5 + chartIndent }}
              data-test-id="multi-chart-line-crosshair"
            />
          </CrosshairContainer>
        </>
      )}
      {children}
    </StyledChartContainer>
  );
};

export default MultiChartLineContainer;
