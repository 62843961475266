import { getTemperatureData } from "api/dataVisualisation/temperature/temperature";
import Switch from "components/components/Switch/Switch";
import TemperatureChart from "components/dataVisualization/Temperature/TemperatureChart/TemperatureChart";
import { TemperatureSeriesIds } from "components/dataVisualization/Temperature/TemperatureChart/TemperatureDatasets";
import Legend from "components/dataVisualization/chartComponents/Legend/Legend";
import {
  ChartDataToggleContainer,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import { useDataExport } from "contexts/DataExportContext";
import useToggle from "hooks/useToggle";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useEffect, useMemo, useState } from "react";
import colours, { chartColours } from "styles/colours";
import { transformTemperatureModelData } from "utils/ChartDataUtils/temperature/temperature";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  addDaysToDate,
  MAX_DAYS_IN_ONE_YEAR,
  restrictDateFilterToMaxOneYear,
  startOfDay,
  toDateOnlyString,
} from "utils/dateHelpers";

import useTemperatureChartContainerData from "./useTemperatureChartContainerData";

const TemperatureChartContainer: React.FC = () => {
  const today = startOfDay();
  const dateSelectorTabs = [
    DateSelectorTabs.OneWeek,
    DateSelectorTabs.OneMonth,
    DateSelectorTabs.OneYear,
  ];
  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addDaysToDate(today, -7), new Date(today))
  );
  const [activeDateSelectorTab, setActiveDataSelectorTab] = useState(
    DateSelectorTabs.OneWeek
  );
  const [showReferenceData, toggleReferenceData] = useToggle(false);
  const { dispatch: dataExportCountDispatch } = useDataExport();

  const {
    data: rawData,
    fetchData,
    status,
  } = useTemperatureChartContainerData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const allChartData = useMemo(
    () => (rawData ? transformTemperatureModelData(rawData) : []),
    [rawData]
  );

  const chartData = useMemo(
    () =>
      showReferenceData
        ? allChartData
        : allChartData.filter((x) => x.id === TemperatureSeriesIds.Outturn),
    [allChartData, showReferenceData]
  );

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getTemperatureData(dateFilter, fileFormat),
      `Temperature-${toDateOnlyString(dateFilter.startDate)}-${toDateOnlyString(
        dateFilter.endDate
      )}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    setDateFilter(restrictDateFilterToMaxOneYear(newDateFilter));
    setActiveDataSelectorTab(newActiveTab);
  };

  return (
    <>
      <ChartHeader>
        <ChartDataToggleContainer>
          <Switch
            name="reference-data"
            onColor={colours.elexonDarkTeal}
            onClick={toggleReferenceData}
            switchedOn={showReferenceData}
            leftLabel="Reference data"
            headerLabel
          />
        </ChartDataToggleContainer>
        <ChartFilter
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={MAX_DAYS_IN_ONE_YEAR}
          showTimeInput={false}
        />
      </ChartHeader>
      <TemperatureChart
        series={chartData}
        dataFetchStatus={status}
        rerequestData={fetchData}
        showReferenceData={showReferenceData}
      />
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFile}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
          showTimeInput={false}
        />
        {showReferenceData && (
          <Legend
            items={[
              {
                colour: chartColours.temperatureReference,
                label: "75% chance the temperature will fall in this zone",
              },
            ]}
          />
        )}
      </ChartFooter>
    </>
  );
};

export default TemperatureChartContainer;
