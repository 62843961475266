import { getSettlementCalculationSummaryJsonData } from "api/dataVisualisation/detailedSystemPrices/detailedSystemPrices";
import { HttpStatusCode } from "axios";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { SettlementCalculationSummaryModel } from "models/detailedSystemPrices/settlementModels";
import { useCallback } from "react";

const useSettlementCalculationSummaryData = (
  settlementDate: string,
  settlementPeriod: number
): {
  data: SettlementCalculationSummaryModel | null;
  status: RequestStatus;
  fetchData: () => void;
  errorCode: number | null;
} => {
  const { data, status, request, errorCode } = useRequest(
    getSettlementCalculationSummaryJsonData
  );

  const fetchData = useCallback(() => {
    request(settlementDate, settlementPeriod);
  }, [request, settlementDate, settlementPeriod]);

  return {
    data: errorCode === HttpStatusCode.NotFound ? null : data,
    status:
      errorCode === HttpStatusCode.NotFound
        ? RequestStatus.SUCCESSFUL_OR_NOT_STARTED
        : status,
    fetchData,
    errorCode,
  };
};

export default useSettlementCalculationSummaryData;
