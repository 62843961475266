import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import RectangleSliceProvider from "components/dataVisualization/chartComponents/RectangleSlice/RectangleSliceProvider";
import { RectangleSliceTooltipData } from "components/dataVisualization/chartComponents/RectangleSlice/RectangleSlicesLayer";
import { RequestStatus } from "hooks/useRequest";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import React, { useMemo } from "react";
import {
  chartAxisLeft,
  chartXTimeScaleWithDumbbellPadding,
  commonChartProps,
  modifyChartMargin,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayersWithDisbsadPriceDumbbells } from "styles/chartStyles/chartLayers";
import { chartColours } from "styles/colours";

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  rerequestData: () => void;
  dateFilter: DateFilterModel;
  dataFetchStatus: RequestStatus;
  rectangleSliceData?: RectangleSliceTooltipData;
  setRectangleSliceData: (
    newRectangleSliceTooltipData?: RectangleSliceTooltipData
  ) => void;
}

const PriceAdjustmentChart: React.FC<Props> = ({
  series,
  datasetCategories,
  rerequestData,
  dateFilter,
  dataFetchStatus,
  rectangleSliceData,
  setRectangleSliceData,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    return (
      <ResponsiveLine
        {...commonChartProps(isChartLoaded, series)}
        axisBottom={null}
        axisLeft={chartAxisLeft("£/MWh", isChartLoaded, 5)}
        gridYValues={5}
        layers={chartLayersWithDisbsadPriceDumbbells}
        xScale={chartXTimeScaleWithDumbbellPadding(dateFilter)}
        yScale={nonStackedLineChartYScale(series, {
          maximumMin: 0,
          minimumMax: 10,
        })}
        margin={modifyChartMargin({ top: 20 })}
        // Adds reference line at 0
        markers={
          series.length && isChartLoaded
            ? [
                {
                  axis: "y",
                  value: 0,
                  lineStyle: {
                    stroke: chartColours.referenceLine,
                  },
                },
              ]
            : []
        }
      />
    );
  }, [dataFetchStatus, series, dateFilter]);

  return (
    <ChartWithErrorHandling
      series={series}
      datasetCategories={datasetCategories}
      dataFetchStatus={dataFetchStatus}
      rerequestData={rerequestData}
      containerHeight={330}
    >
      {series.length ? (
        <RectangleSliceProvider
          rectangleSliceData={rectangleSliceData}
          setRectangleSliceData={setRectangleSliceData}
          showSlices={false}
          idPrefix="price"
        >
          {memoisedChart}
        </RectangleSliceProvider>
      ) : (
        <>{memoisedChart}</>
      )}
    </ChartWithErrorHandling>
  );
};

export default PriceAdjustmentChart;
