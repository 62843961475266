import {
  getData,
  getDatasetByPublishTimes,
} from "api/dataVisualisation/client";
import {
  AgptChartModel,
  AgptDownloadModel,
  AgptSummaryModel,
} from "models/actualGenerationModel/actualGeneration";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";

export const getActualGenerationPerTypeDownloadData = async (
  dateFilter: DateFilterModel,
  format: DataDownloadFormat
): Promise<DataDownloadModel<AgptDownloadModel>> =>
  getDatasetByPublishTimes<AgptDownloadModel>(dateFilter, format, "AGPT", 4);

export const getActualGenerationPerTypeChartData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<AgptChartModel>> => {
  const { data } = await getData("/generation/actual/per-type", {
    from: dateFilter.normalisedStartDate.toISOString(),
    to: dateFilter.normalisedEndDate.toISOString(),
  });

  return data as Promise<JsonDataDownloadModel<AgptChartModel>>;
};

export const getActualGenerationPerTypeSummaryData = async (): Promise<
  AgptSummaryModel[]
> => {
  const { data } = await getData("/generation/actual/per-type/day-total");
  return data as Promise<AgptSummaryModel[]>;
};
