import { MultiChartTitle } from "components/dataVisualization/chartComponents/MultiChartTitle/style";
import styled from "styled-components";

export interface LolpdrmMultiChartTitleProps {
  chartIndent: number;
}

export const LolpdrmMultiChartTitle = styled(
  MultiChartTitle
)<LolpdrmMultiChartTitleProps>`
  &&& {
    margin: ${({ chartIndent }): string => `0 0 0 ${120 + chartIndent}px`};
  }
`;
