import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import {
  StyledLink,
  Header,
  LinkContentDiv,
  LinkSection,
  LinksContainer,
} from "components/components/Iris/UsefulLinks/style";
import React from "react";
import routeConstants, {
  azureStorageAccountUrl,
  irisGitHubUrl,
} from "utils/routeConstants";

type Props = {
  title?: string;
};

const UsefulLinks: React.FC<Props> = ({ title }) => (
  <>
    {title && <Header>{title}</Header>}
    <LinksContainer>
      <LinkSection>
        <LinkContentDiv>
          <h3>IRIS GitHub</h3>
          <p>
            IRIS is based on the Advanced Message Queuing Protocol (AMQP)
            open-source standard and you will need a message client to access
            the data. You can either write your own client, or use one of our
            examples (in Python, Node.js and C#) provided on GitHub.
          </p>
        </LinkContentDiv>
        <StyledLink to={irisGitHubUrl}>View IRIS GitHub</StyledLink>
      </LinkSection>
      <LinkSection>
        <LinkContentDiv>
          <h3>API documentation</h3>
          <p>
            IRIS and our suite of APIs make available the same data, in the same
            output format, which enables you to use the API documentation as a
            reference when using IRIS. This interchangeability allows you to
            decide which data source works best for you or to use a combination.
          </p>
        </LinkContentDiv>
        <StyledLink to={routeConstants.apiDocs}>
          View API documentation
        </StyledLink>
      </LinkSection>
      {title && (
        <LinkSection>
          <LinkContentDiv>
            <h3>IRIS Archive</h3>
            <p>
              The IRIS Archive is a public storage of all files sent out to IRIS
              subscribers. It can be accessed using Azure Storage Explorer by
              passing in the URL https://archive.data.elexon.co.uk/iris-archive
              or interacted with programmatically via{" "}
              <a
                href="https://azure.microsoft.com/en-gb/downloads/"
                target="_blank"
                rel="noopener noreferrer"
                className="is-link"
              >
                Azure SDKs
                <Icon iconName={faExternalLinkAlt} size={IconSize.xSmall} />
              </a>
              . See full setup instructions in our{" "}
              <a
                href="https://github.com/elexon-data/insights-docs/blob/main/docs/iris_archive.md"
                target="_blank"
                rel="noopener noreferrer"
                className="is-link"
              >
                GitHub docs
                <Icon iconName={faExternalLinkAlt} size={IconSize.xSmall} />
              </a>
              .
            </p>
          </LinkContentDiv>
          <StyledLink to={azureStorageAccountUrl}>
            Azure Storage Explorer
          </StyledLink>
        </LinkSection>
      )}
    </LinksContainer>
  </>
);

export default UsefulLinks;
