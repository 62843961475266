import React, { forwardRef } from "react";

import { StyledButton } from "./style";

export enum DateInputLabel {
  StartDate = "start date",
  EndDate = "end date",
  SettlementPeriod = "settlement date & period",
}

export interface Props {
  value: string;
  appendText?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  dateInputLabel: DateInputLabel;
}

const DateInput = forwardRef(function dateInput(
  { value, onClick, isDisabled = false, dateInputLabel, appendText }: Props,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <StyledButton
      type="button"
      onClick={onClick}
      data-test-id="date-input"
      ref={ref}
      disabled={isDisabled}
      aria-label={`Change ${dateInputLabel} from ${value}`}
      aria-disabled={isDisabled}
    >
      {value}
      {appendText && ` ${appendText}`}
    </StyledButton>
  );
});

export default DateInput;
