import styled, { keyframes } from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const NoMarginUnorderedList = styled.ul`
  && {
    margin: 0;
  }
`;

export const ListItemWithoutBullet = styled.li`
  list-style-type: none;
`;

const hideOnFirstFrame = keyframes`
  from {
      visibility: hidden;
  }
  to {
      visibility: visible;
  }
`;

export const StyledTooltip = styled.div`
  background-color: ${colours.white};
  opacity: 0.9;
  box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  min-width: 225px;
  animation: ${hideOnFirstFrame} 1ms;
`;

export const StyledTooltipTitle = styled.p`
  font-size: 25px;
  font-weight: bold;
  white-space: nowrap;

  &&& {
    margin-bottom: 0.2em;
  }
`;

export const StyledTooltipSubtitle = styled.p`
  font-size: 20px;
  text-transform: uppercase;

  &&& {
    margin-bottom: 0.2em;
  }
`;

export const StyledTooltipCategory = styled.p`
  font-weight: ${fonts.heavy};

  &&& {
    margin-bottom: 0.5em;
  }
`;

export const StyledTooltipLabel = styled.div`
  font-weight: ${(props: { selected: boolean }): string =>
    props.selected ? "bold" : "normal"};
  display: flex;

  dt {
    flex-grow: 1;
    display: inline;
  }

  dd {
    display: inline;
    margin-left: 1em;
    white-space: nowrap;
  }
`;

export const LabelPublishTime = styled.dd`
  width: 150px;
  text-align: center;
`;

export const LabelPublishTimeMissing = styled(LabelPublishTime)`
  color: ${colours.highlightGrey};
`;

export const StyledTooltipTotal = styled.p`
  font-size: 20px;

  span {
    margin-right: 40px;
  }

  span:nth-child(2) {
    font-weight: bold;
  }
`;

export const StyledTooltipSettlementPeriod = styled.p`
  font-size: 20px;

  span {
    margin-right: 5px;
  }
`;

export const PublishTimeContainer = styled.div`
  font-size: ${fonts.small};
  font-weight: bold;
  display: flex;
  margin-top: -10px;
  flex-direction: row-reverse;
  width: 100%;

  span {
    width: 120px;
  }
`;

export const ChartTooltipListContainer = styled.div`
  margin-top: 1em;
`;

export const TooltipDescription = styled.p`
  margin-top: 32px;
`;

export const StyledTooltipValuesTable = styled.table`
  && th,
  && td {
    border: 0;
    padding: 0;
    white-space: nowrap;
  }

  && thead > tr > th,
  tbody > tr > td {
    text-align: right;
  }

  tr > *:not(:first-child) {
    padding-left: 1em;
  }

  .selected > * {
    font-weight: ${fonts.heavy};
  }

  tbody > tr > th {
    font-weight: ${fonts.normal};
  }

  caption {
    font-weight: ${fonts.heavy};
    text-align: left;
    margin-bottom: 0.2em;
  }
`;
