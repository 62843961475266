import TabContentWithDescription from "components/components/Tabs/TabContentWithDescription/TabContentWithDescription";
import TabContentWithErrorHandling from "components/components/Tabs/TabContentWithErrorHandling/TabContentWithErrorHandling";
import BalancingServicesVolumeModal from "components/components/balancingMechanism/balancingMechanismTabs/descriptionModals/BalancingServicesVolumeModal";
import { DataFetcher } from "models/dataFetcher";
import React, { useState } from "react";

interface Props extends DataFetcher {
  children: JSX.Element;
}

const BalancingServicesVolume: React.FC<Props> = ({
  status,
  fetchData,
  children,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const description = `
          Balancing services volume data represents the volume of energy (MWh)
          for each BM Unit and Settlement Period where action has been taken
          outside of the Balancing Mechanism.`;
  const modal = (
    <BalancingServicesVolumeModal
      isOpen={modalIsOpen}
      close={(): void => setModalIsOpen(false)}
    />
  );

  return (
    <TabContentWithDescription
      description={description}
      modal={modal}
      openModal={(): void => setModalIsOpen(true)}
    >
      <TabContentWithErrorHandling status={status} fetchData={fetchData}>
        {children}
      </TabContentWithErrorHandling>
    </TabContentWithDescription>
  );
};

export default BalancingServicesVolume;
