import { getMarketIndexPricesChartData } from "api/dataVisualisation/marketIndexPrices/marketIndexPrices";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { MarketIndexPriceModel } from "models/marketIndexPrices/marketIndexPricesModel";
import { useCallback } from "react";

const useMarketIndexPricesChartContainerData = (
  dateFilter: DateFilterModel
): {
  data: MarketIndexPriceModel[] | null;
  fetchData: () => void;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchMarketIndexPricesData,
  } = useRequest(getMarketIndexPricesChartData);

  const fetchData = useCallback(() => {
    fetchMarketIndexPricesData(dateFilter);
  }, [dateFilter, fetchMarketIndexPricesData]);

  return {
    data,
    fetchData,
    status,
  };
};

export default useMarketIndexPricesChartContainerData;
