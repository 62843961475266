import { Theme } from "react-select";

import colours from "./colours";

export const customSelectTheme = (theme: Theme): Theme => ({
  ...theme,
  borderRadius: 0,
  spacing: {
    ...theme.spacing,
    // controlHeight: 100%,
  },
  colors: {
    ...theme.colors,
    primary: colours.focusBlue, // Border colour when active
    neutral20: colours.mediumGrey, // Border colour
    neutral30: colours.focusBlue, // Border colour on hover
    primary25: colours.elexonYellow, // Background colour of dropdown option on hover
  },
});
