import { Datum, Serie } from "@nivo/line";
import {
  DisaggregatedBalancingServicesAdjustmentIds,
  DisaggregatedBalancingServicesAdjustmentSummaryModel,
} from "models/disaggregatedBalancingServicesAdjustment/disaggregatedBalancingServicesAdjustmentSummaryModel";
import colours, { disbsadDatasetColours } from "styles/colours";
import { sortByStartTime } from "utils/ChartDataUtils";

const mapToBuyActionCountDatum = (
  rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.buyActionCount,
  settlementPeriod: rawData.settlementPeriod,
});

const mapToSellActionCountDatum = (
  rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.sellActionCount,
  settlementPeriod: rawData.settlementPeriod,
});

export const transformDisaggregatedBalancingServicesAdjustmentModelAdjustmentActivitiesData =
  (
    rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel[]
  ): Serie[] => {
    if (!rawData.length) {
      return [];
    }

    return [
      {
        id: DisaggregatedBalancingServicesAdjustmentIds.BuyActionCount,
        data: sortByStartTime(rawData).map(mapToBuyActionCountDatum),
      },
      {
        id: DisaggregatedBalancingServicesAdjustmentIds.SellActionCount,
        data: sortByStartTime(rawData).map(mapToSellActionCountDatum),
      },
    ];
  };

const mapToBuyPriceMinimumDatum = (
  rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.buyPriceMinimum,
  settlementPeriod: rawData.settlementPeriod,
});

const mapToBuyPriceAverageDatum = (
  rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.buyPriceAverage,
  settlementPeriod: rawData.settlementPeriod,
});

const mapToBuyPriceMaximumDatum = (
  rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.buyPriceMaximum,
  settlementPeriod: rawData.settlementPeriod,
});

const mapToSellPriceMinimumDatum = (
  rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.sellPriceMinimum,
  settlementPeriod: rawData.settlementPeriod,
});

const mapToSellPriceAverageDatum = (
  rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.sellPriceAverage,
  settlementPeriod: rawData.settlementPeriod,
});

const mapToSellPriceMaximumDatum = (
  rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.sellPriceMaximum,
  settlementPeriod: rawData.settlementPeriod,
});

export const transformDisaggregatedBalancingServicesAdjustmentModelPriceAdjustmentData =
  (
    rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel[]
  ): Serie[] => {
    if (!rawData.length) {
      return [];
    }

    return [
      {
        id: DisaggregatedBalancingServicesAdjustmentIds.BuyPriceMin,
        colour: colours.transparent,
        data: sortByStartTime(rawData).map(mapToBuyPriceMinimumDatum),
      },
      {
        id: DisaggregatedBalancingServicesAdjustmentIds.BuyPriceAvg,
        colour: colours.transparent,
        data: sortByStartTime(rawData).map(mapToBuyPriceAverageDatum),
      },
      {
        id: DisaggregatedBalancingServicesAdjustmentIds.BuyPriceMax,
        colour: colours.transparent,
        data: sortByStartTime(rawData).map(mapToBuyPriceMaximumDatum),
      },
      {
        id: DisaggregatedBalancingServicesAdjustmentIds.SellPriceMin,
        colour: colours.transparent,
        data: sortByStartTime(rawData).map(mapToSellPriceMinimumDatum),
      },
      {
        id: DisaggregatedBalancingServicesAdjustmentIds.SellPriceAvg,
        colour: colours.transparent,
        data: sortByStartTime(rawData).map(mapToSellPriceAverageDatum),
      },
      {
        id: DisaggregatedBalancingServicesAdjustmentIds.SellPriceMax,
        colour: colours.transparent,
        data: sortByStartTime(rawData).map(mapToSellPriceMaximumDatum),
      },
    ];
  };

const mapToBuyVolumeAdjustmentDatum = (
  rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.buyVolumeTotal,
  settlementPeriod: rawData.settlementPeriod,
});

const mapToSellVolumeAdjustmentDatum = (
  rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.sellVolumeTotal,
  settlementPeriod: rawData.settlementPeriod,
});

const mapToNetVolumeAdjustmentDatum = (
  rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel
): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.netVolume,
  settlementPeriod: rawData.settlementPeriod,
});

export const transformDisaggregatedBalancingServicesAdjustmentModelVolumeAdjustmentData =
  (
    rawData: DisaggregatedBalancingServicesAdjustmentSummaryModel[]
  ): Serie[] => {
    if (!rawData.length) {
      return [];
    }

    return [
      {
        id: DisaggregatedBalancingServicesAdjustmentIds.BuyVolume,
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.BuyVolume
          ],
        data: sortByStartTime(rawData).map(mapToBuyVolumeAdjustmentDatum),
      },
      {
        id: DisaggregatedBalancingServicesAdjustmentIds.SellVolume,
        colour:
          disbsadDatasetColours[
            DisaggregatedBalancingServicesAdjustmentIds.SellVolume
          ],
        data: sortByStartTime(rawData).map(mapToSellVolumeAdjustmentDatum),
      },
      {
        id: DisaggregatedBalancingServicesAdjustmentIds.NetVolume,
        colour: colours.transparent, // transparent to avoid Nivo drawing this with area, instead generated via chartLayersAreasWithCustomLines
        data: sortByStartTime(rawData).map(mapToNetVolumeAdjustmentDatum),
      },
    ];
  };
