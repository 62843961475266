import classnames from "classnames";
import React from "react";

import { StyledSection } from "./style";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const FullWidthSection: React.FC<Props> = ({ children, className }) => (
  <StyledSection className={classnames("section", className)}>
    {children}
  </StyledSection>
);

export default FullWidthSection;
