import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { disableAnalytics, initialiseAnalytics } from "utils/analyticsUtils";
import {
  hasAcceptedAnalyticsCookies,
  isCookieScriptAcceptEvent,
  parseCookies,
} from "utils/cookieHelper";
import featureFlags from "utils/featureFlags";

export const COOKIE_SCRIPT_ACCEPT_EVENT_NAME = "CookieScriptAccept";
export const COOKIE_SCRIPT_ACCEPT_ALL_EVENT_NAME = "CookieScriptAcceptAll";
export const COOKIE_SCRIPT_REJECT_EVENT_NAME = "CookieScriptReject";

const RenderAnalytics: React.FC = () => {
  const cookieScriptConsentCookie = parseCookies(
    document.cookie
  ).CookieScriptConsent;
  const initialConsent = cookieScriptConsentCookie?.categories
    ? hasAcceptedAnalyticsCookies(cookieScriptConsentCookie)
    : false;
  const [hasConsent, setConsent] = useState(initialConsent);
  const location = useLocation();

  useEffect(() => {
    const onAcceptAllCookies = (): void => {
      setConsent(true);
    };
    const onAcceptSomeCookies = (e: Event): void => {
      if (isCookieScriptAcceptEvent(e)) {
        // Analytics cookies will be under the Cookie Script "performance" category
        setConsent(e.detail.categories.includes("performance"));
      } else {
        throw new Error(
          `Event object did not match event type "${COOKIE_SCRIPT_ACCEPT_EVENT_NAME}".`
        );
      }
    };

    const onRejectAllCookies = (): void => {
      setConsent(false);
    };

    window.addEventListener(
      COOKIE_SCRIPT_ACCEPT_ALL_EVENT_NAME,
      onAcceptAllCookies
    );
    window.addEventListener(
      COOKIE_SCRIPT_ACCEPT_EVENT_NAME,
      onAcceptSomeCookies
    );
    window.addEventListener(
      COOKIE_SCRIPT_REJECT_EVENT_NAME,
      onRejectAllCookies
    );

    return (): void => {
      window.removeEventListener(
        COOKIE_SCRIPT_ACCEPT_ALL_EVENT_NAME,
        onAcceptAllCookies
      );
      window.removeEventListener(
        COOKIE_SCRIPT_ACCEPT_EVENT_NAME,
        onAcceptSomeCookies
      );
      window.removeEventListener(
        COOKIE_SCRIPT_REJECT_EVENT_NAME,
        onRejectAllCookies
      );
    };
  }, []);

  useEffect(() => {
    if (hasConsent) {
      initialiseAnalytics();
    } else {
      disableAnalytics();
    }
  }, [hasConsent]);

  useEffect(() => {
    if (hasConsent && featureFlags.google_analytics) {
      setTimeout(
        // This is in a setTimeout callback to push it to the back of the event loop so that the
        // render has finished and the page title is updated correctly before firing the GA event.
        // See this issue with React Helmet: https://github.com/nfl/react-helmet/issues/189, with
        // this solution mentioned in one of the comments.
        () =>
          ReactGA.send({
            hitType: "pageview",
            page: location.pathname + location.search,
          }),
        0
      );
    }
  }, [hasConsent, location]);

  return <></>;
};

export default RenderAnalytics;
