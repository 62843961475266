import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const ClientDetailsContainer = styled.div`
  margin: 3em 0;
  max-width: 45em;
`;

export const ContentDiv = styled.div`
  font-size: ${fonts.smallMedium};
`;

export const RequestSecretButton = styled.button`
  border: none;
  border-radius: 0;
  background-color: transparent;
  color: ${colours.linkColor};
  font-size: 1em;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;

  :hover {
    color: ${colours.charcoal};
  }
`;
