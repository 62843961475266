import { getSwaggerJsonFile } from "api/apiDocumentation";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { SwaggerJsonFile } from "models/apiDocumentation/apiDocumentationModels";
import { useCallback } from "react";

const useApiData = (): {
  fetchData: () => void;
  data: SwaggerJsonFile | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchApiData,
  } = useRequest(getSwaggerJsonFile);

  const fetchData = useCallback(() => {
    fetchApiData();
  }, [fetchApiData]);

  return {
    data,
    status,
    fetchData,
  };
};

export default useApiData;
