import { Serie } from "@nivo/line";
import { utcFormat } from "d3-time-format";
import { RequestStatus } from "hooks/useRequest";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import { CHART_VERTICAL_PADDING_MULTIPLIER } from "styles/chartStyles";
import { AxisTickArrangement } from "utils/ChartDrawUtils/chartTickArrangement";
import {
  DAY_IN_MS as DAY,
  HOUR_IN_MS as HOUR,
  MONTH_LOWER_BOUND_IN_MS as MONTH,
  YEAR_LOWER_BOUND_IN_MS as YEAR,
  isToday,
} from "utils/dateHelpers";

// These formats use the d3 time format specifiers originating from the C strftime function
// For details and a full list, see https://github.com/d3/d3-time-format
export const formatAsYear = utcFormat("%Y");
export const formatAsMonth = utcFormat("%b '%y");
export const formatAsDay = utcFormat("%-d %b");
export const formatAsTime = utcFormat("%H:%M");

export const formatNumberToFixedWithoutTrailingZeroes = (
  value: number
): string => {
  if (Number.isInteger(value)) {
    return value.toString();
  }
  // LOLP is stored as decimal(10, 9) in database, so will never have more than 9 digits after decimal point
  return value.toFixed(9).replace(/0+$/, "");
};

/**
 * Format a date as a string
 * @param  {Date} date The date to format
 * @return {string} The date formatted according to these rules:
 *
 * 1. If the time of day is midnight, format as date: DD MMM
 * 2. Else, format as time: HH:mm
 */
export const formatAsTimeWithDatesMarked = (date: Date): string =>
  date.getUTCHours() === 0 && date.getUTCMinutes() === 0
    ? formatAsDay(date)
    : formatAsTime(date);

/**
 * Format a date as a string
 * @param  {Date} date The date to format
 * @return {string} The date formatted according to these rules:
 *
 * 1. If the date is midnight today (beginning of the day): "Today".
 * 2. If the time of day is midnight but not today, format as date: DD MMM
 * 3. Else, format as time: HH:mm
 */
export const formatAsTimeWithDatesAndTodayMarked = (date: Date): string =>
  isToday(date) && date.getUTCHours() === 0 && date.getUTCMinutes() === 0
    ? "Today"
    : formatAsTimeWithDatesMarked(date);

export const getMaxStackedYValue = (series: Serie[]): number => {
  const stackedTotalsByDate = new Map();
  series.forEach((serie) => {
    serie.data.forEach((datum) => {
      const dateAsNumber = (datum.x as Date).getTime();
      if (stackedTotalsByDate.has(dateAsNumber)) {
        const currentTotal = stackedTotalsByDate.get(dateAsNumber);
        stackedTotalsByDate.set(dateAsNumber, currentTotal + datum.y);
      } else {
        stackedTotalsByDate.set(dateAsNumber, datum.y);
      }
    });
  });
  return Math.max(...stackedTotalsByDate.values());
};

export const getYValuesExtrema = (
  series: Serie[]
): { maxYAxisValue: number; minYAxisValue: number } => {
  const allYValues = series.flatMap((s) =>
    s.data
      .filter((d) => d.y !== null && d.y !== undefined)
      .flatMap((d) => d.y as number)
  );
  return {
    minYAxisValue: Math.min(...allYValues),
    maxYAxisValue: Math.max(...allYValues),
  };
};

export const getXTimeValuesExtrema = (
  series: Serie[]
): { maxXAxisValue: Date; minXAxisValue: Date } => {
  const sortedXValues = series
    .flatMap((s) =>
      s.data
        .filter((d) => d.x !== null && d.x !== undefined)
        .map((d) => (d.x as Date).getTime())
    )
    .sort((timeA, timeB) => (timeB < timeA ? 1 : -1));
  return {
    minXAxisValue: new Date(sortedXValues[0]),
    maxXAxisValue: new Date(sortedXValues[sortedXValues.length - 1]),
  };
};

export const getExtremaForNonStackedLineChartYScaleWithMinMax = (
  minYAxisValue: number,
  maxYAxisValue: number,
  centre?: number
): { min: number; max: number } => {
  if (centre !== undefined) {
    const maxDistanceFromCentre = Math.max(
      Math.abs(maxYAxisValue - centre),
      Math.abs(minYAxisValue - centre)
    );
    const range = maxDistanceFromCentre * 2;
    const padding = range * CHART_VERTICAL_PADDING_MULTIPLIER;
    const paddedMaxDistanceFromCentre = maxDistanceFromCentre + padding;
    return {
      min: centre - paddedMaxDistanceFromCentre,
      max: centre + paddedMaxDistanceFromCentre,
    };
  }
  const range = maxYAxisValue - minYAxisValue;
  const padding = range * CHART_VERTICAL_PADDING_MULTIPLIER;
  const minWithPadding = minYAxisValue - padding;
  const maxWithPadding = maxYAxisValue + padding;
  return {
    // If the data is all positive, we limit to 0 in order to not have a negative portion of the axis
    min: minYAxisValue >= 0 ? Math.max(0, minWithPadding) : minWithPadding,
    // and vice versa for all negative data
    max: maxYAxisValue <= 0 ? Math.min(0, maxWithPadding) : maxWithPadding,
  };
};

export const getMaxForStackedLineChartYScale = (series: Serie[]): number =>
  getMaxStackedYValue(series) * (1 + CHART_VERTICAL_PADDING_MULTIPLIER);

export const getExtremaForNonStackedLineChartYScale = (
  series: Serie[],
  centre?: number
): { min: number; max: number } => {
  const { maxYAxisValue, minYAxisValue } = getYValuesExtrema(series);

  return getExtremaForNonStackedLineChartYScaleWithMinMax(
    minYAxisValue,
    maxYAxisValue,
    centre
  );
};

export const chartWouldBeEmpty = (
  dataFetchStatus: RequestStatus,
  series: Serie[],
  datasets?: DatasetCategory<ChartDatasetModel>[]
): boolean =>
  dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED &&
  (datasets === undefined
    ? series.every((x) => !x.data.length)
    : series.every((x) => !x.data.length) || !datasets.length);

export const tickArrangementsByDuration: {
  minDurationInMs: number;
  arrangement: AxisTickArrangement;
}[] = [
  {
    minDurationInMs: 3 * YEAR * 1.01,
    arrangement: {
      tickValues: "every 1 year",
      format: formatAsYear,
    },
  },
  {
    minDurationInMs: YEAR * 1.01,
    arrangement: {
      tickValues: "every 3 months",
      format: formatAsMonth,
    },
  },
  {
    minDurationInMs: 6 * MONTH * 1.01,
    arrangement: {
      tickValues: "every 1 month",
      format: formatAsMonth,
    },
  },
  {
    minDurationInMs: 3 * MONTH * 1.01,
    arrangement: {
      tickValues: "every 2 weeks",
      format: formatAsDay,
    },
  },
  {
    minDurationInMs: MONTH * 1.01,
    arrangement: {
      tickValues: "every 1 week",
      format: formatAsDay,
    },
  },
  /*
Nivo bug:
On the x-axis, when tickValues = 'every X days', sometimes an additional x-axis tick is visible off the right-hand side of the graph.
To reproduce the bug, it might be necessary to experiment with different chart periods including time of day, and with different screen sizes.
Issue raised here: https://github.com/plouc/nivo/issues/1910
*/
  // TODO: change 14 * day arrangement tickValues to 'every 2 days' if/when the aforementioned nivo bug gets resolved
  {
    minDurationInMs: 14 * DAY * 1.01,
    arrangement: {
      tickValues: "every 1 week",
      format: formatAsDay,
    },
  },
  {
    minDurationInMs: 3 * DAY * 1.01,
    arrangement: {
      tickValues: "every 24 hours",
      format: formatAsDay,
    },
  },
  {
    minDurationInMs: 24 * HOUR * 1.01,
    arrangement: {
      tickValues: "every 6 hours",
      format: formatAsTimeWithDatesMarked,
    },
  },
  {
    minDurationInMs: 12 * HOUR * 1.01,
    arrangement: {
      tickValues: "every 3 hours",
      format: formatAsTimeWithDatesMarked,
    },
  },
  {
    minDurationInMs: 4 * HOUR * 1.01,
    arrangement: {
      tickValues: "every 1 hour",
      format: formatAsTimeWithDatesMarked,
    },
  },
  {
    minDurationInMs: 2 * HOUR * 1.01,
    arrangement: {
      tickValues: "every 30 minutes",
      format: formatAsTimeWithDatesMarked,
    },
  },
  {
    minDurationInMs: HOUR * 1.01,
    arrangement: {
      tickValues: "every 10 minutes",
      format: formatAsTimeWithDatesMarked,
    },
  },
  {
    minDurationInMs: 0,
    arrangement: {
      tickValues: "every 5 minutes",
      format: formatAsTimeWithDatesMarked,
    },
  },
];
