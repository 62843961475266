import {
  getDatasetOrderedByWeekAndYear,
  getDataset,
} from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { DataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { GenerationForecastDownloadDataModel } from "models/generationForecast/generationForecastDownloadModels/generationForecastDownloadDataModel";

export const getGenerationForecast14DayFOUDownloadData = async (
  format: DataDownloadFormat
): Promise<DataDownloadModel<GenerationForecastDownloadDataModel>> =>
  getDataset<GenerationForecastDownloadDataModel>(format, "FOU2T14D");

export const getGenerationForecast3YearFOUDownloadData = async (
  format: DataDownloadFormat
): Promise<DataDownloadModel<GenerationForecastDownloadDataModel>> =>
  getDatasetOrderedByWeekAndYear<GenerationForecastDownloadDataModel>(
    format,
    "FOU2T3YW"
  );
