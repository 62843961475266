import {
  ModalHeader,
  ModalInformation,
  ModalSection,
} from "components/components/Modal/commonStyle";

const DataExporterReadOnlyHeader = (
  <ModalSection>
    <div>
      <ModalHeader id="export-modal-header">
        Confirm the data you wish to export
      </ModalHeader>
      <ModalInformation>
        Information based on the filters you have applied.
      </ModalInformation>
    </div>
  </ModalSection>
);

export default DataExporterReadOnlyHeader;
