import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import ShortTermOperatingReservesChartContainer from "components/dataVisualization/shortTermOperatingReserves/ShortTermOperatingReservesChartContainer/ShortTermOperatingReservesChartContainer";
import ShortTermOperatingReservesSummaryTile from "components/dataVisualization/shortTermOperatingReserves/ShortTermOperatingReservesSummaryTile/ShortTermOperatingReservesSummaryTile";
import { ChartFocussedDateProvider } from "contexts/ChartFocussedDateContext";
import React from "react";

const ShortTermOperatingReserves: React.FC = () => (
  <ChartFocussedDateProvider>
    <>
      <PageMeta title="Short-term operating reserves" />
      <PageHeader
        title="Short-term operating reserves"
        pageDescription={
          <>
            <p>
              STOR (Short Term Operating Reserve) is a service outside of the
              Balancing Mechanism by which National Energy System Operator can
              issue instructions for reserve power. STOR providers (Non-BM STOR
              units) will meet this requirement by either increasing generation
              or reducing demand. This is to manage instances where actual
              demand exceeds forecast demand including unavailability of power
              plants due to unplanned outages.
            </p>
            <p>
              These instructions are shown on the graph below as a series of
              half hourly MWh values shown against start time in UTC. The graph
              can show either up to a maximum of 1 week of data or 24 hours at a
              time up to the most recently completed period of today.
            </p>
          </>
        }
        expanderHeading="What you need to know while using this data"
        expanderContent={
          <>
            <p>
              NESO procures sources of extra power ahead of time with STOR
              providers through STOR contracts. The need for STOR will vary on
              the time of year, the time of week, and time of day; to reflect
              this, each year NESO publishes the{" "}
              <a
                href="https://www.elexon.co.uk/data/short-term-operating-reserve-stor-availability-window"
                target="_blank"
                rel="noopener noreferrer"
                className="is-link"
              >
                STOR Availability Window
                <Icon iconName={faExternalLinkAlt} size={IconSize.xSmall} />
              </a>{" "}
              ahead of time starting from April.
            </p>
            <p>
              The data is updated following the end of each Settlement Period as
              the latest figure is received from NESO.
            </p>
          </>
        }
      >
        <ShortTermOperatingReservesSummaryTile />
      </PageHeader>
      <FullWidthChartSection>
        <ShortTermOperatingReservesChartContainer />
      </FullWidthChartSection>
      <AdditionalInfoSection
        documentationInformation={{
          chart: ["balancing/nonbm/stor"],
          tile: ["balancing/nonbm/stor/events"],
        }}
      />
    </>
  </ChartFocussedDateProvider>
);

export default ShortTermOperatingReserves;
