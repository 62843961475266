import { getBidOfferDatasetFromPairId } from "components/dataVisualization/balancingMechanismTabs/BidOffer/bmuView/useBidOfferData";
import { PhysicalTableModel } from "components/dataVisualization/balancingMechanismTabs/Physical/bmuView/models";
import { BidOfferPairModel } from "models/bidOffer/bidOfferPairModel";
import BmuViewBidOfferTableModel from "models/bidOffer/bmuViewBidOfferTableModel";
import { PhysicalDataModel } from "models/bmuData/physicalDataModel";

export function mapToBidOfferRow(
  bidOfferData: BidOfferPairModel[]
): BmuViewBidOfferTableModel[] {
  return [...bidOfferData]
    .sort((pairA, pairB) => {
      if (pairA.pairId === pairB.pairId)
        return (
          new Date(pairA.timeFrom).getTime() -
          new Date(pairB.timeFrom).getTime()
        );
      // To create default order 1, 2, 3, 4, 5, -1, -2, -3, -4, -5
      const signA = Math.sign(pairA.pairId);
      const signB = Math.sign(pairB.pairId);
      if (signA === signB)
        return Math.abs(pairA.pairId) - Math.abs(pairB.pairId);
      return signB - signA;
    })
    .map((row, key) => ({
      id: key,
      pairName: getBidOfferDatasetFromPairId(row.pairId),
      timeFrom: new Date(row.timeFrom),
      levelFrom: row.levelFrom,
      timeTo: new Date(row.timeTo),
      levelTo: row.levelTo,
      bid: row.bid,
      offer: row.offer,
    }));
}

export function mapToPhysicalNotificationRow(
  physicalNotificationData: PhysicalDataModel[]
): PhysicalTableModel[] {
  return physicalNotificationData
    .map((row, key) => ({
      id: key,
      dataset: "finalPhysicalNotification",
      timeFrom: new Date(row.timeFrom),
      levelFrom: row.levelFrom,
      timeTo: new Date(row.timeTo),
      levelTo: row.levelTo,
    }))
    .sort(
      (noticeA, noticeB) =>
        noticeA.timeFrom.getTime() - noticeB.timeFrom.getTime()
    );
}
