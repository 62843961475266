import styled, { css } from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const FieldName = styled.dt`
  color: ${colours.darkGrey};
  margin-top: 0.5em;
`;

export const FieldValue = styled.dd`
  color: ${colours.elexonBlack};
  font-weight: bold;
  font-size: ${fonts.medium};

  && {
    margin-left: 0;
  }
`;

export const BmuFieldValue = styled(FieldValue)`
  font-size: ${fonts.mediumLarge};
  line-height: 1.3em;
`;

export const NameValuePair = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1em;
`;

export const DetailsAndDateContainer = styled.div`
  display: flex;
  margin-top: 2em;
  align-items: flex-end;
`;

const sharedBmuDataStyles = css`
  display: flex;
  min-width: 50%;
  min-height: 8.5em;
`;

export const BmuDataContainer = styled.dl`
  ${sharedBmuDataStyles};
  flex-direction: column;
  flex-grow: 1;
  &&& {
    margin-bottom: 0;
  }
`;

export const NoBmuSelected = styled.div`
  ${sharedBmuDataStyles};
  align-items: flex-end;
  font-weight: bold;
  padding-bottom: 1.2em;
  flex-grow: 1;
`;

export const OtherDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 3em;
`;

export const DateContainer = styled.div`
  padding: 1em;
  border-left: 2px solid ${colours.highlightGrey};
`;
