import React from "react";

interface Props {
  children: JSX.Element;
}

export interface ErrorDisplay {
  hasError: boolean;
  errorText?: string;
  registerError: (errorText?: string) => void;
  clearError: () => void;
}

export const ErrorContext = React.createContext<ErrorDisplay | undefined>(
  undefined
);

export const ErrorProvider: React.FC<Props> = ({ children }) => {
  const [hasError, setHasError] = React.useState(false);
  const [errorText, setErrorText] = React.useState<string | undefined>(
    undefined
  );

  return (
    <ErrorContext.Provider
      value={{
        hasError,
        errorText,
        registerError: (text): void => {
          setHasError(true);
          setErrorText(text);
        },
        clearError: (): void => {
          setHasError(false);
          setErrorText(undefined);
        },
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export const useErrorContext = (): ErrorDisplay => {
  const context = React.useContext(ErrorContext);

  if (context === undefined) {
    throw new Error(
      "useErrorContext must be used within ErrorContext's Provider"
    );
  }

  return context;
};
