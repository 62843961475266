import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const StyledCard = styled.div`
  box-shadow: none;
  background-color: ${colours.lightGrey};
  border-radius: 0;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;

  &.expander-list-item {
    margin-bottom: 20px;

    > header {
      font-size: ${fonts.small};
    }
  }

  &.sidebar-item {
    margin: 0;

    &.active {
      > header {
        background-color: ${colours.focusBlue};
        color: ${colours.white};
      }

      .card-header-title {
        color: ${colours.white};
      }
    }

    .card-header:first-child {
      border-radius: 0;
    }

    > header {
      font-size: ${fonts.small};

      padding-left: 16px;

      span {
        padding: 0 7px 0 0;
      }

      button {
        padding: 16px;
      }
    }
  }
`;

export const StyledHeader = styled.header`
  height: 60px;
  font-size: ${fonts.mediumSmaller};
  box-shadow: none;
  cursor: pointer;
`;

export const Title = styled.span`
  margin-bottom: 0;
  padding-left: 24px;
`;

export const Subtitle = styled.span`
  display: flex;
  align-items: center;
  font-size: ${fonts.xSmall};
  padding-right: 7px;
`;

export const StyledButton = styled.button`
  font-family: elexon-icons;
`;

export const StyledDiv = styled.div`
  display: none;
  padding: 0 24px 24px;

  &.expanded {
    display: block;
  }
`;
