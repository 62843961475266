import classnames from "classnames";
import Button from "components/components/Button/Button";
import {
  ModalSection,
  ModalSubHeader,
} from "components/components/Modal/commonStyle";
import DataExporterContainer from "components/dataVisualization/dataExporterComponents/DataExporterContainer/DataExporterContainer";
import DataExporterHeader from "components/dataVisualization/dataExporterComponents/dataExporterSections/DataExporterHeader";
import FileFormatSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/FileFormatSection";
import TimeFrameSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/TimeFrameSection";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { DataDownloadInterval } from "models/dataDownload/dataDownloadInterval";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useState } from "react";

interface Props {
  downloadData: (fileFormat: DataDownloadFormat, dataset: string) => void;
  dateFilter: DateFilterModel;
  handleChangeToDateSelection: (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ) => void;
  activeDateSelectorTab: DateSelectorTabs | null;
  infoText?: string;
}

const FuelGenerationDataExporter: React.FC<Props> = ({
  downloadData,
  dateFilter,
  handleChangeToDateSelection,
  activeDateSelectorTab = null,
  infoText,
}: Props) => {
  const [dataInterval, setDataInterval] = useState(
    DataDownloadInterval.FiveMinutes
  );
  const [downloadFormat, setDownloadFormat] = useState<DataDownloadFormat>(
    DataDownloadFormat.Csv
  );

  const handleExportClick = (): void => {
    if (dataInterval === DataDownloadInterval.FiveMinutes) {
      downloadData(downloadFormat, "FUELINST");
    } else {
      downloadData(downloadFormat, "FUELHH");
    }
  };

  return (
    <DataExporterContainer onExportClick={handleExportClick}>
      <DataExporterHeader infoText={infoText} />
      <ModalSection>
        <TimeFrameSection
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          activeTab={activeDateSelectorTab}
        />
      </ModalSection>
      <ModalSection>
        <div data-test-id="export-data-interval">
          <ModalSubHeader>Interval</ModalSubHeader>
          <Button
            ariaLabel="5 minutes"
            onClick={(): void =>
              setDataInterval(DataDownloadInterval.FiveMinutes)
            }
            className={classnames("filter", "interval", {
              active: dataInterval === DataDownloadInterval.FiveMinutes,
            })}
            buttonText={DataDownloadInterval.FiveMinutes}
          />
          <Button
            ariaLabel="30 minutes"
            onClick={(): void =>
              setDataInterval(DataDownloadInterval.ThirtyMinutes)
            }
            className={classnames("filter", "interval", {
              active: dataInterval === DataDownloadInterval.ThirtyMinutes,
            })}
            buttonText={DataDownloadInterval.ThirtyMinutes}
          />
        </div>
        <ModalSection>
          <FileFormatSection
            downloadFormat={downloadFormat}
            setDownloadFormat={setDownloadFormat}
          />
        </ModalSection>
      </ModalSection>
    </DataExporterContainer>
  );
};

export default FuelGenerationDataExporter;
