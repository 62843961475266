import styled from "styled-components";
import colours from "styles/colours";

const tooltipBackground = colours.lightGrey;
const tooltipBorder = colours.elexonGrey;

export const TooltipContainer = styled.div`
  background-color: ${tooltipBackground};
  border: 1px solid ${tooltipBorder};
  border-radius: 4px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  transition: opacity 0.3s;
  z-index: 9999;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: normal;
  color: ${colours.darkGrey};

  &[data-popper-interactive="false"] {
    pointer-events: none;
  }

  &[data-popper-placement*="bottom"] > div:first-child {
    left: 0;
    margin-top: -0.4rem;
    top: 0;

    &:before {
      border-color: transparent transparent ${tooltipBorder} transparent;
      border-width: 0 0.5rem 0.4rem 0.5rem;
      position: absolute;
      top: -1px;
    }

    &:after {
      border-color: transparent transparent ${tooltipBackground} transparent;
      border-width: 0 0.5rem 0.4rem 0.5rem;
    }
  }

  &[data-popper-placement*="top"] > div:first-child {
    bottom: 0;
    left: 0;
    margin-bottom: -1rem;

    &:before {
      border-color: ${tooltipBorder} transparent transparent transparent;
      border-width: 0.4rem 0.5rem 0 0.5rem;
      position: absolute;
      top: 1px;
    }

    &:after {
      border-color: ${tooltipBackground} transparent transparent transparent;
      border-width: 0.4rem 0.5rem 0 0.5rem;
    }
  }

  &[data-popper-placement*="right"] > div:first-child {
    left: 0;
    margin-left: -0.7rem;

    &:before {
      border-color: transparent ${tooltipBorder} transparent transparent;
      border-width: 0.5rem 0.4rem 0.5rem 0;
    }

    &:after {
      border-color: transparent ${tooltipBackground} transparent transparent;
      border-width: 0.5rem 0.4rem 0.5rem 0;
      left: 6px;
      top: 0;
    }
  }

  &[data-popper-placement*="left"] > div:first-child {
    margin-right: -0.7rem;
    right: 0;

    &:before {
      border-color: transparent transparent transparent ${tooltipBorder};
      border-width: 0.5rem 0 0.5rem 0.4em;
    }

    &:after {
      border-color: transparent transparent transparent ${tooltipBackground};
      border-width: 0.5rem 0 0.5rem 0.4em;
      left: 3px;
      top: 0;
    }
  }
`;

export const TooltipArrow = styled.div`
  height: 1rem;
  position: absolute;
  width: 1rem;
  pointer-events: none;

  &:before {
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    margin: auto;
    width: 0;
  }

  &:after {
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
  }
`;

export const TooltipLine = styled.div`
  & + & {
    margin-top: 10px;
  }
`;

export const TooltipLabel = styled.span`
  font-weight: bold;
`;

export const TooltipWrapper = styled.div`
  display: flex;
`;

export const HeaderWithTooltipContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: flex-start;
`;

export const FollowCursorContainer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 10;
`;
