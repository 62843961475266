import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const StyledTextArea = styled.textarea`
  display: block;
  width: 100%;
  height: 200px;
  border: none;
  border: 1px solid ${colours.elexonGrey};
  font-family: "Source Sans Pro", sans-serif;
  font-size: ${fonts.small};
  padding: 10px;
  resize: none;

  &:focus {
    outline: none;
    border: 1px solid ${colours.elexonBlue};
  }

  &.invalid {
    border: 1px solid ${colours.elexonRed};
  }
`;
