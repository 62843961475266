import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  createIrisConnection,
  getIrisConnectionDetails,
  IrisGetResponse,
  IrisPostResponse,
} from "api/account/client";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { useCallback } from "react";
import { irisRequest } from "utils/authUtils";

type IrisRequestData<TData> = {
  data: TData | null;
  status: RequestStatus;
  errorCode: number | null;
  request: () => Promise<void>;
};

const useIrisRequest = <TData extends object>(
  baseRequest: () => Promise<TData>
): IrisRequestData<TData> => {
  const { instance, inProgress } = useMsal();

  const authenticatedRequest = useCallback(async (): Promise<TData | null> => {
    if (inProgress !== InteractionStatus.None) {
      return null;
    }

    try {
      return await baseRequest();
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect({
          ...irisRequest,
          account: instance.getActiveAccount() as AccountInfo,
        });
        return null;
      }
      throw e;
    }
  }, [baseRequest, inProgress, instance]);

  const { request, data, status, errorCode } = useRequest(authenticatedRequest);

  return {
    request,
    data,
    status,
    errorCode,
  };
};

export const useRegisterUser = (): IrisRequestData<IrisPostResponse> =>
  useIrisRequest(createIrisConnection);

export const useGetDetails = (): IrisRequestData<IrisGetResponse> =>
  useIrisRequest(getIrisConnectionDetails);
