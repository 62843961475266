import { Serie } from "@nivo/line";
import {
  StackedChartDataModel,
  transformDataToSeries,
} from "components/dataVisualization/chartComponents/StackedLineChart/utils";
import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";
import { GenerationForecastDataModel } from "models/generationForecast/generationForecastDataModel";
import { getUniqueDatesFromList } from "utils/dateHelpers";

const mapDataToStackedChartModel = (
  rawData: GenerationForecastDataModel[]
): StackedChartDataModel[] => {
  if (rawData.length === 0) {
    return [];
  }

  const chartDates = rawData.map((datapoint) => datapoint.forecastDate);
  const individualChartDates = getUniqueDatesFromList(chartDates);
  const datapoints = individualChartDates.map((chartDate) => {
    const rawDatapointsForDate = rawData.filter(
      (datapoint, index) => chartDates[index].getTime() === chartDate.getTime()
    );
    return rawDatapointsForDate.map((datapoint) => ({
      datasetType: datapoint.fuelType,
      value: datapoint.outputUsable,
    }));
  });
  return individualChartDates.map((chartDate, index) => ({
    date: chartDate,
    datapoints: datapoints[index],
  }));
};

export const transformGenerationForecastModelData = (
  rawData: GenerationForecastDataModel[],
  datasets: ChartDatasetModel[]
): Serie[] => {
  return transformDataToSeries(mapDataToStackedChartModel(rawData), datasets);
};
