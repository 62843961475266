import { ModalSubHeader } from "components/components/Modal/commonStyle";
import { WizardStep } from "components/components/ModalExportWizard/ModalExportWizard";
import MultiDatasetExporter, {
  DownloadCallback,
} from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/MultiDatasetExporter";
import {
  FieldValue,
  ModalContent,
} from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/style";
import DateFilterModel from "models/filterModels/dateFilterModel";
import React from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";
import { BmuOption } from "utils/fuzzyBmuDataHelpers";

import {
  bmuCheckboxDataGroups,
  transformPerBmuDownloadCallbacks,
} from "./utils";

interface Props {
  selectedBmu: BmuOption;
  dateFilter: DateFilterModel;
}

const BmuViewDataExporter: React.FC<Props> = ({ selectedBmu, dateFilter }) => {
  const bmuId = selectedBmu.elexonBmuId;
  const infoStep: WizardStep = {
    title: "Confirm the BMU and time frame",
    content: (
      <>
        <ModalContent>
          <ModalSubHeader>
            Selected BMU ID: {selectedBmu.elexonBmuId}
          </ModalSubHeader>
          <div>
            <span>Lead Party Name: </span>
            <FieldValue>{selectedBmu.leadPartyName}</FieldValue>
          </div>
          <div>
            <span>NGC BMU Name: </span>
            <FieldValue>{selectedBmu.nationalGridBmuName}</FieldValue>
          </div>
          <div>
            <span>BMU Type: </span>
            <FieldValue>{selectedBmu.bmuType}</FieldValue>
          </div>
        </ModalContent>
        <ModalContent>
          <ModalSubHeader>Selected time frame</ModalSubHeader>
          <div>
            <FieldValue>
              {formatDateTimeString(dateFilter.startDate, DateFormat.DateTime)}
            </FieldValue>
            <FieldValue> to </FieldValue>
            <FieldValue>
              {formatDateTimeString(dateFilter.endDate, DateFormat.DateTime)}
            </FieldValue>
          </div>
        </ModalContent>
      </>
    ),
  };

  return (
    <MultiDatasetExporter
      fileSuffix={`${bmuId}-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`}
      checkboxList={bmuCheckboxDataGroups}
      transformToDownloadCallbacks={(datasets: string[]): DownloadCallback[] =>
        transformPerBmuDownloadCallbacks(datasets, dateFilter, bmuId)
      }
      infoStep={infoStep}
    />
  );
};
export default BmuViewDataExporter;
