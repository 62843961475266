import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  IPublicClientApplication,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import featureFlags from "utils/featureFlags";

const authConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID!,
    authority: process.env.REACT_APP_AUTH_AUTHORITY!,
  },
};

export const loginRequest: RedirectRequest = {
  scopes: ["https://graph.microsoft.com/User.Read"],
};

export const irisRequest: RedirectRequest = {
  scopes: [
    `${process.env.REACT_APP_ACCOUNT_API_APPLICATION_ID_URI}/Iris.Read`,
    `${process.env.REACT_APP_ACCOUNT_API_APPLICATION_ID_URI}/Iris.Write`,
  ],
};

export const initialiseAuth = (): IPublicClientApplication => {
  const authContext = new PublicClientApplication(authConfig);

  // User should never be able to sign in with more than one account because there is no login button
  // if they're already logged in.
  const accounts = authContext.getAllAccounts();
  if (accounts.length > 0) {
    authContext.setActiveAccount(accounts[0]);
  }

  authContext.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      authContext.setActiveAccount(account);
    }
  });

  return authContext;
};
export const authContext = featureFlags.accountArea
  ? initialiseAuth()
  : undefined;
