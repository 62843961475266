import styled from "styled-components";
import colours from "styles/colours";

export const ClientSecretModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 2.5em;
`;

export const WarningContainer = styled.div`
  display: flex;
  border: 1px solid ${colours.elexonOrange};
  border-radius: 3px;
  padding: 1em;
  margin: 1em 0;
  width: 100%;

  p {
    padding-left: 5px;
  }
`;

export const SecretContainer = styled.div`
  display: flex;
  margin: 0;
  width: 100%;
  justify-content: space-between;

  div {
    display: flex;
  }

  dl {
    display: inline-flex;

    dt {
      padding-right: 5px;
      font-weight: bold;
    }

    dd {
      margin: 0;
    }
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 2.75em;
`;
