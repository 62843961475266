import ResultsCount from "components/components/ResultsCount/ResultsCount";
import {
  StyledDateOnly,
  StyledTimeOnly,
} from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
} from "components/components/SortableTable/SortableTable";
import TabContentWithErrorHandling from "components/components/Tabs/TabContentWithErrorHandling/TabContentWithErrorHandling";
import { DatasetGroupsDropdown } from "components/components/balancingMechanism/marketView/DatasetDropdown/DatasetDropdown";
import Dynamic from "components/dataVisualization/balancingMechanismTabs/Dynamic/Dynamic";
import {
  buildBmuViewQueryParamsWithoutBmuId,
  getBmUnitLinkRenderFn,
} from "components/dataVisualization/balancingMechanismTabs/utils";
import { useMarketViewContext } from "contexts/MarketViewContext";
import {
  DynamicTabDataset,
  DynamicTabDataStructure,
  DynamicTableModel,
  DynamicDatasetConfig,
  mapDatasetToHeaders,
} from "models/bmuData/marketViewDynamicTabModels";
import { useEffect, useState } from "react";

interface TableProps {
  data: Partial<DynamicTabDataStructure>;
  dataset: DynamicTabDataset;
}

const DataTable: React.FC<TableProps> = ({ data, dataset }) => {
  const { selectedDate } = useMarketViewContext();
  const urlParamsWithoutBmuId = buildBmuViewQueryParamsWithoutBmuId(
    selectedDate,
    "Dynamic"
  );

  if (data[dataset]?.length === 0) {
    return <></>;
  }

  return (
    <SortableTable
      key={dataset}
      headers={mapDatasetToHeaders(dataset)}
      items={data[dataset] as DynamicTableModel[]}
      renderFns={{
        time: ({ time }: DynamicTableModel): TableCellRender => ({
          content: StyledTimeOnly(time),
        }),
        settlementDate: ({
          settlementDate,
        }: DynamicTableModel): TableCellRender => ({
          content: StyledDateOnly(settlementDate),
        }),
        bmUnit: ({ bmUnit }): TableCellRender => ({
          content: getBmUnitLinkRenderFn(bmUnit, urlParamsWithoutBmuId),
        }),
      }}
    />
  );
};

const MarketViewDynamic: React.FC = () => {
  const [dataset, setDataset] = useState<DynamicTabDataset>("MDV");
  const {
    dynamicDetails: { data, status, request: fetchData },
  } = useMarketViewContext();

  useEffect(() => {
    if (!data || !data[dataset]) {
      fetchData(dataset);
    }
  }, [dataset, fetchData, data]);

  return (
    <Dynamic>
      <>
        <DatasetGroupsDropdown
          groups={DynamicDatasetConfig}
          dataset={dataset}
          setDataset={setDataset}
        />
        <TabContentWithErrorHandling
          status={status}
          fetchData={(): void => fetchData(dataset)}
        >
          <>
            {data && data[dataset] && (
              <>
                <ResultsCount count={data[dataset]?.length ?? 0} />
                <DataTable dataset={dataset} data={data} />
              </>
            )}
          </>
        </TabContentWithErrorHandling>
      </>
    </Dynamic>
  );
};
export default MarketViewDynamic;
