import styled from "styled-components";

export const RelativeContainer = styled.div`
  flex-grow: 1;
  position: relative;
`;

export const StyledIFrame = styled.iframe`
  position: absolute;
  left: 0;
  top: 0;
`;
