import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { ClientSecret } from "api/account/client";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import {
  StatusContainer,
  StatusTooltip,
} from "components/components/Iris/AuthenticatedView/IrisDetailsPage/IrisDetails/SecretsTable/style";
import React from "react";
import colours from "styles/colours";
import {
  getNumberOfDaysBetweenDates,
  MAX_DAYS_IN_ONE_MONTH,
} from "utils/dateHelpers";

type Props = {
  secret: ClientSecret;
};

const SecretStatus: React.FC<Props> = ({ secret: { expiryTime } }) => {
  const expiryDate = new Date(expiryTime);
  const now = new Date();
  const daysBeforeExpiry = getNumberOfDaysBetweenDates(now, expiryDate);
  if (daysBeforeExpiry > MAX_DAYS_IN_ONE_MONTH) {
    return <td>Valid</td>;
  }

  const secretExpired = expiryDate < now;
  const tooltipText = secretExpired
    ? "Request a new secret and replace it in your client to resume receiving IRIS messages."
    : "Request a new secret before this secret expires and replace it " +
      "in your client to ensure you continue receiving IRIS messages.";

  return (
    <td className="status-tooltip">
      <StatusContainer secretExpired={secretExpired} role="button" tabIndex={0}>
        <Icon
          iconName={secretExpired ? faExclamationCircle : faExclamationTriangle}
          size={IconSize.small}
          colour={secretExpired ? colours.pinkRed : colours.darkOrange}
          ariaDescribedBy="secret-status-tooltip-text"
        />
        <p>{secretExpired ? "Expired" : "Expiring soon"}</p>
      </StatusContainer>
      <StatusTooltip id="secret-status-tooltip-text">
        {tooltipText}
      </StatusTooltip>
    </td>
  );
};

export default SecretStatus;
