import NavButton from "components/components/NavButton/NavButton";
import styled from "styled-components";

export const AccountMenuContainer = styled.nav`
  position: absolute;
  right: 0;
  text-align: right;
  padding-top: 10px;
`;

export const StyledNavButton = styled(NavButton)`
  :focus-visible {
    outline: 1px solid white;
  }
`;
