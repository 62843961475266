import styled, { css } from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const PageContainer = styled.div`
  width: 85vw;
  position: relative;
  left: calc(-35vw + 50%);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WarningContainer = styled.div`
  display: flex;
  border: 1px solid ${colours.focusBlue};
  border-radius: 3px;
  width: 30em;

  > p {
    display: flex;
    align-items: center;
    padding: 10px;

    > b {
      margin: 0 3px;
    }
  }

  > span {
    display: flex;
    align-items: center;
    padding: 25px 10px;
    margin-left: 15px;
  }
`;

export const TilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65vw;

  > :nth-child(2) {
    align-self: flex-end;
    margin-right: 12%;
  }
`;

export const OverflowContainer = styled.span`
  position: relative;
  height: 80%;
  width: 100%;
  overflow: auto;
`;

export const SidebarContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 20vw;
  display: flex;
  flex-direction: column;
  background-color: ${colours.lightGrey};
  font: ${fonts.smallMedium};
  font-weight: ${fonts.semibold};
  margin-top: 250px;
  margin-right: 0;
  margin-bottom: 0;
  //max-height: 1200px;

  &.warning-box-active {
    margin-top: 330px;
  }
`;

export const SidebarHeader = styled.h2`
  && {
    font: ${fonts.xSmall};
    padding: 24px 16px 24px 16px;
  }
`;

export const blueBorderAndTextStyle = css`
  border: 1px solid ${colours.focusBlue};

  & {
    color: ${colours.focusBlue};
  }
`;

export const greyBorderAndTextStyle = css`
  border: 1px solid ${colours.mediumGrey};

  & {
    color: ${colours.mediumGrey};
  }
`;
export const SidebarContent = styled.div`
  margin-left: -24px;
  padding: 5px 16px;

  > button {
    background-color: transparent;
    ${blueBorderAndTextStyle};
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
      ${blueBorderAndTextStyle}
    }

    &:disabled {
      background-color: transparent;

      ${greyBorderAndTextStyle}
      &:hover,
      &:active,
      &:focus {
        border-bottom: 1px solid ${colours.mediumGrey} !important;
      }
    }
  }

  & > p {
    font-weight: normal;
  }

  ul {
    font-weight: normal;
  }
`;
