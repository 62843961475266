import { Point, Serie } from "@nivo/line";
import { sellBuyConfig } from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesChartConfig";
import { createDefaultSystemPricesDatum } from "components/dataVisualization/SystemSellBuyPrices/utils";
import ChartTooltipPositionWrapper, {
  TooltipInteractionType,
} from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import TooltipValuesTable from "components/dataVisualization/chartComponents/ChartTooltip/TooltipValuesTable/TooltipValuesTable";
import {
  StyledTooltip,
  StyledTooltipTitle,
  StyledTooltipSettlementPeriod,
} from "components/dataVisualization/chartComponents/ChartTooltip/style";
import { getTabulatedTooltipValues } from "components/dataVisualization/chartComponents/ChartTooltip/utils";
import React from "react";
import {
  formatDateTimeString,
  DateFormat,
  getDifferenceInMs,
} from "utils/dateHelpers";

interface Props {
  allChartData: Serie[];
  minXAxisValue: Date;
  maxXAxisValue: Date;
  highlightedPoint: Point;
}

const SellBuyPricesTooltip: React.FC<Props> = ({
  allChartData,
  minXAxisValue,
  maxXAxisValue,
  highlightedPoint,
}) => {
  const date = highlightedPoint.data.x as Date;

  const distanceFromMin = getDifferenceInMs(date, minXAxisValue);
  const distanceFromMax = getDifferenceInMs(date, maxXAxisValue);
  const pointIsInLeftHalf = distanceFromMax > distanceFromMin;

  const { tooltipValues: systemPricesValues, settlementPeriod } =
    getTabulatedTooltipValues(
      sellBuyConfig.datasetCategories,
      allChartData,
      date,
      createDefaultSystemPricesDatum
    );

  return (
    <ChartTooltipPositionWrapper
      pointIsInLeftHalf={pointIsInLeftHalf}
      interactionType={TooltipInteractionType.Slice}
    >
      <StyledTooltip data-test-id="chart-tooltip">
        <StyledTooltipTitle>
          {formatDateTimeString(date, DateFormat.DateTime)}
        </StyledTooltipTitle>
        <StyledTooltipSettlementPeriod data-test-id="chart-tooltip-settlement-period">
          <span>Settlement Period</span>
          <span>{settlementPeriod}</span>
        </StyledTooltipSettlementPeriod>
        <TooltipValuesTable
          config={sellBuyConfig}
          tooltipValues={systemPricesValues}
        />
      </StyledTooltip>
    </ChartTooltipPositionWrapper>
  );
};

export default SellBuyPricesTooltip;
