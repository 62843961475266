import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const FilterContainer = styled.div`
  display: flex;

  &.multi-line {
    flex-direction: column;
    gap: 0.9rem;
  }
`;

export const ButtonFilterContainer = styled.div`
  margin-right: 1.25rem;
  height: 1.9rem;
`;

export const DatePickerContainer = styled.div`
  display: inline-block;

  &.active
    > .react-datepicker-wrapper
    > .react-datepicker__input-container
    > button {
    border-bottom: 3px solid ${colours.elexonBlue};
    margin-bottom: 0;
  }

  &.react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: inline;
    button {
      width: auto;

      &:disabled {
        color: ${colours.darkerDisabledGrey};
      }
    }
  }

  .react-datepicker {
    border: none;
    background-color: ${colours.white};
    filter: drop-shadow(2px 2px 8px rgb(0, 0, 0, 0.25));

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__header {
      background-color: ${colours.white};
      border: none;
      padding: 0;
    }

    .react-datepicker__day {
      &:hover {
        font-weight: bold;
        background-color: transparent;
      }
    }

    .react-datepicker__day-names {
      border-top: 1px solid ${colours.elexonGrey};
    }

    .react-datepicker__day-name {
      color: ${colours.disabledGrey};
    }

    .react-datepicker__day--selected {
      background-color: ${colours.elexonBlue};
      border-radius: 50%;

      &:hover {
        background-color: ${colours.elexonBlue};
        font-weight: normal;
      }
    }

    .react-datepicker__day--today {
      border: 0;
      position: relative;
    }

    .react-datepicker__day--today::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid #4a4a4a;
      border-radius: 50%;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: ${colours.highlightGrey};
      color: ${colours.white};
      border-radius: 50%;

      &:hover {
        background-color: ${colours.highlightGrey};
      }

      &:focus {
        outline: none;
      }
    }

    .react-datepicker__day--disabled {
      color: ${colours.disabledGrey};

      &:hover {
        font-weight: normal;
      }
    }

    .react-datepicker__input-time-container {
      border-top: 1px solid ${colours.elexonGrey};
      margin: 0;

      .react-datepicker-time__input-container {
        width: 100%;

        .react-datepicker-time__input {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
`;

export const StyledHeader = styled.div`
  margin: 0.6rem;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.6rem;
`;

export const NextPreviousButton = styled.button`
  background-color: transparent;
  color: ${colours.elexonBlack};
  border: none;
  font-size: ${fonts.xSmall};
  font-weight: bold;
  font-family: elexon-icons;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px ${colours.focusBlue};
    border-radius: 2px;
  }
`;

export const MonthDisplay = styled.span`
  font-family: "DIN Next LT W01", "Source Sans Pro", sans-serif;
  font-size: ${fonts.smallMedium};
  margin: 0 0.6rem;
`;

export const TimeDisplay = styled.span`
  font-family: "Source Sans Pro", sans-serif;
  font-size: ${fonts.smallMedium};
  margin: 0 0.6rem;
`;

export const StyledSelect = styled.select`
  border: none;
  border-bottom: 1px solid #d4cdc1;
  font-family: "DIN Next LT W01", "Source Sans Pro", sans-serif;
  font-size: ${fonts.smallMedium};

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px ${colours.focusBlue};
    border-radius: 2px;
  }
`;

export const StyledOption = styled.option`
  font-size: 1rem;
  text-align: center;
  color: ${colours.elexonBlack};
  font-family: "Source Sans Pro", sans-serif;
`;

export const ToText = styled.span`
  color: ${(props: { isGrey: boolean }): string =>
    props.isGrey ? colours.darkerDisabledGrey : colours.elexonBlack};
`;
