import styled from "styled-components";

export const StyledForm = styled.form`
  display: none;
  &.visible {
    display: block;
  }
`;

export const DropdownsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  > div:first-child {
    width: 500px;
  }

  > div:not(:first-child) {
    width: 250px;
  }
`;
