import { StyledChild } from "components/components/Iris/LandingPageInfo/GetStartedSection/Tile/style";
import React from "react";

type Props = {
  title: string | number;
  text: JSX.Element | string;
};

const Tile: React.FC<Props> = ({ title, text }) => (
  <div className="tile is-parent">
    <StyledChild className="tile is-child">
      <h3>{title}</h3>
      <p>{text}</p>
    </StyledChild>
  </div>
);

export default Tile;
