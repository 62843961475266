import styled from "styled-components";

export const SwatchWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  span {
    margin: 0;
  }
`;
