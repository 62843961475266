import useOnClickOutside from "hooks/useOnClickOutside";
import useOnEscape from "hooks/useOnEscape";
import React, { useCallback, useState } from "react";

import {
  DropdownContainer,
  MenuItemButton,
  MenuItemLink,
  StyledNavButton,
} from "./style";

const menuId = "account-menu";

interface InternalLinkItem {
  text: string;
  path: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

interface ButtonItem {
  text: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const isLinkItem = (
  object: InternalLinkItem | ButtonItem
): object is InternalLinkItem => "path" in object;

interface Props {
  children: React.ReactNode;
  className?: string;
  ariaLabel?: string;
  menuItems: (InternalLinkItem | ButtonItem)[];
}

const NavButtonWithMenu: React.FC<Props> = ({
  children,
  className,
  ariaLabel,
  menuItems,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleMenu = (): void => setIsExpanded((prevState) => !prevState);

  const closeMenu = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const containerRef = useOnClickOutside<HTMLDivElement>(closeMenu);
  useOnEscape(closeMenu);

  return (
    <div ref={containerRef}>
      <StyledNavButton
        onClick={toggleMenu}
        className={className}
        aria-label={ariaLabel}
        type="button"
        aria-expanded={isExpanded}
        aria-controls={menuId}
        aria-haspopup
      >
        {children}
      </StyledNavButton>
      {isExpanded && (
        <DropdownContainer id={menuId}>
          {menuItems.map((item) => {
            if (isLinkItem(item)) {
              return (
                <MenuItemLink
                  key={item.text}
                  to={item.path}
                  onClick={(e): void => {
                    item.onClick?.(e);
                    closeMenu();
                  }}
                >
                  {item.text}
                </MenuItemLink>
              );
            }
            return (
              <MenuItemButton
                key={item.text}
                type="button"
                onClick={(e): void => {
                  item.onClick(e);
                  closeMenu();
                }}
              >
                {item.text}
              </MenuItemButton>
            );
          })}
        </DropdownContainer>
      )}
    </div>
  );
};

export default NavButtonWithMenu;
