import React from "react";

const ArrowheadMarker: React.FC<{
  size: number;
  colour: string;
  identifier: string;
}> = ({ size, colour, identifier }) => (
  <marker
    id={`arrowheadmarker-${identifier}`}
    markerWidth={size * 2}
    markerHeight={size * 2}
    refX={size}
    refY={size}
    orient="auto"
  >
    <path
      d={`M 0 0 L ${size} ${size} L 0 ${size * 2}`}
      fill="transparent"
      stroke={colour}
    />
  </marker>
);

export default ArrowheadMarker;
