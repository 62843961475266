import {
  BidOfferAcceptanceTableModel,
  PhysicalTableModel,
  StableLimitTableModel,
} from "components/dataVisualization/balancingMechanismTabs/Physical/bmuView/models";
import { BidOfferAcceptanceModel } from "models/bidOffer/bidOfferAcceptanceModel";
import { StableLimitRangedData } from "models/bmuData/physicalChartData";
import { PhysicalDataModel } from "models/bmuData/physicalDataModel";

export function mapToBidOfferAcceptanceRow(
  bidOfferAcceptanceData: BidOfferAcceptanceModel[]
): BidOfferAcceptanceTableModel[] {
  return bidOfferAcceptanceData
    .map((row, key) => ({
      id: key,
      timeFrom: new Date(row.timeFrom),
      levelFrom: row.levelFrom,
      timeTo: new Date(row.timeTo),
      levelTo: row.levelTo,
      acceptId: row.acceptanceNumber,
      acceptTime: new Date(row.acceptanceTime),
      deemedFlag: row.deemedBoFlag,
      soFlag: row.soFlag,
      storFlag: row.storFlag,
      rrFlag: row.rrFlag,
    }))
    .sort((rowA, rowB) => rowA.timeFrom.getTime() - rowB.timeFrom.getTime());
}

export function mapToPhysicalRow(
  physicalData: PhysicalDataModel[]
): PhysicalTableModel[] {
  return physicalData
    .map((row, key) => ({
      id: key,
      dataset: row.dataset,
      timeFrom: new Date(row.timeFrom),
      levelFrom: row.levelFrom,
      timeTo: new Date(row.timeTo),
      levelTo: row.levelTo,
    }))
    .sort((rowA, rowB) => {
      if (rowA.dataset < rowB.dataset) {
        return -1;
      }
      if (rowA.dataset > rowB.dataset) {
        return 1;
      }
      return rowA.timeFrom.getTime() - rowB.timeFrom.getTime();
    });
}

export function mapToStableLimitRow(
  stableLimitRangedData: StableLimitRangedData[]
): StableLimitTableModel[] {
  return stableLimitRangedData
    .map((row, key) => ({
      id: key,
      dataset: row.dataset,
      publishTime: new Date(row.publishTime),
      level: row.level,
    }))
    .sort((rowA, rowB) => {
      if (rowA.dataset < rowB.dataset) {
        return -1;
      }
      if (rowA.dataset > rowB.dataset) {
        return 1;
      }
      return rowA.publishTime.getTime() - rowB.publishTime.getTime();
    });
}
