import DateSelector from "components/components/DateSelector/DateSelector";
import { ModalSubHeader } from "components/components/Modal/commonStyle";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import { getSettlementPeriodFromSettlementTime } from "utils/DateUtils";
import { formatDateTimeString, DateFormat } from "utils/dateHelpers";

interface Props {
  dateFilter: DateFilterModel;
  handleChangeToDateSelection?: (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ) => void;
  dateTabs?: DateSelectorTabs[];
  activeTab?: DateSelectorTabs | null;
  maxRange?: number;
  showTimeInput?: boolean;
  defaultEndDate?: Date;
  /** Styles the custom date pickers on a new line under the date tabs. Defaults to true */
  customDateNewLine?: boolean;
  showSingleSettlementPeriod?: boolean;
}

const defaultDateSelectorTabs = [
  DateSelectorTabs.TwentyFourHours,
  DateSelectorTabs.OneWeek,
  DateSelectorTabs.OneMonth,
  DateSelectorTabs.OneYear,
  DateSelectorTabs.All,
];

const TimeFrameSection: React.FC<Props> = ({
  dateFilter,
  handleChangeToDateSelection,
  dateTabs = defaultDateSelectorTabs,
  activeTab = null,
  maxRange,
  showTimeInput,
  defaultEndDate,
  customDateNewLine = true,
  showSingleSettlementPeriod = false,
}) => {
  const dateFormat = showTimeInput
    ? DateFormat.DateTimeWithHyphen
    : DateFormat.DateOnly;

  const timeFrame = showSingleSettlementPeriod ? (
    <div>
      {formatDateTimeString(dateFilter.startDate, dateFormat)} (SP
      {getSettlementPeriodFromSettlementTime(dateFilter.startDate)})
    </div>
  ) : (
    <div>
      {formatDateTimeString(dateFilter.startDate, dateFormat)}
      &nbsp; to &nbsp;
      {formatDateTimeString(dateFilter.endDate, dateFormat)}
    </div>
  );
  return (
    <div>
      <ModalSubHeader>Time frame</ModalSubHeader>
      {handleChangeToDateSelection ? (
        <DateSelector
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateTabs={dateTabs}
          activeTab={activeTab}
          customDateNewLine={customDateNewLine}
          maxRange={maxRange}
          showTimeInput={showTimeInput}
          defaultEndDate={defaultEndDate}
        />
      ) : (
        timeFrame
      )}
    </div>
  );
};

export default TimeFrameSection;
