import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const RadioLabel = styled.label<{
  isInline?: boolean;
}>`
  cursor: pointer;
  font-size: ${fonts.mediumBigger};
  font-weight: 400;
  line-height: 1.4em;
  margin: -1em;
  display: flex;
  align-items: center;

  ${({ isInline }): false | FlattenSimpleInterpolation =>
    !!isInline &&
    css`
      & {
        position: relative;
        font-size: inherit;
        font-weight: inherit;
        margin: 0 1.5em 0 0;
      }
    `}
`;

export const RadioText = styled.div`
  margin-left: 1.5em;
  color: black;
`;

export const NativeRadioInput = styled.input<{
  isInline?: boolean;
}>`
  position: relative;
  left: 1.22em;
  top: 1.5px;
  width: 1.1em;
  height: 1.1em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline-color: black;

  ${({ isInline }): false | FlattenSimpleInterpolation =>
    !!isInline &&
    css`
      & {
        position: absolute;
        left: 0;
      }
    `}
`;

export const CustomRadio = styled.span<{
  isChecked?: boolean;
  isInline?: boolean;
}>`
  cursor: pointer;
  width: 1em;
  height: 1em;
  position: absolute;
  border: 1.5px solid ${colours.elexonBlue};
  border-radius: 50%;

  &::after {
    content: "\\a0";
  }

  ${({ isChecked }): false | FlattenSimpleInterpolation =>
    !!isChecked &&
    css`
      &::after {
        background-color: ${colours.elexonBlue};
        border-radius: 50%;
        width: 70%;
        height: 70%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    `}

  ${({ isInline }): false | FlattenSimpleInterpolation =>
    !!isInline &&
    css`
      & {
        margin-right: 0.6em;
      }
    `}
`;

export const ToggleControlRow = styled.div`
  margin-bottom: 1.25em;
  display: flex;
`;

export const InlineRadioLabel = styled(RadioLabel)`
  position: relative;
  font-size: inherit;
  font-weight: inherit;
  margin: 0 1.5em 0 0;
`;

export const InlineNativeRadioInput = styled(NativeRadioInput)`
  position: absolute;
  left: 0;
`;

export const InlineCustomRadio = styled(CustomRadio)`
  margin-right: 0.6em;
`;
