import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  functionalityCookiesAreEnabled,
  setBooleanCookie,
  SHOW_IRIS_LOGIN_MODAL_COOKIE,
} from "utils/cookieHelper";
import routeConstants from "utils/routeConstants";

export const useIrisLoginModalCookie = (): void => {
  const isAuthenticated = useIsAuthenticated();
  const currentPath = useLocation().pathname;
  const consent = functionalityCookiesAreEnabled();

  const isOnIrisPage = useMemo(
    () => currentPath === routeConstants.iris,
    [currentPath]
  );

  useEffect(() => {
    if (!isAuthenticated && consent) {
      setBooleanCookie(SHOW_IRIS_LOGIN_MODAL_COOKIE, isOnIrisPage);
      // This cookie needs to be set on every page (not just the IRIS page)
      // to ensure that the login modal only shows when the user logs in
      // and is navigating to the IRIS page
    }
  }, [consent, isAuthenticated, isOnIrisPage]);
};
