import PageMeta from "components/components/Meta/PageMeta";
import DayAheadAggregatedGenerationChartContainer from "components/dataVisualization/DayAheadAggregatedGeneration/DayAheadAggregatedGenerationChartContainer";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";

const DayAheadAggregatedGeneration: React.FC = () => (
  <>
    <PageMeta title="Day-ahead aggregated generation" />
    <PageHeader
      title="Day-ahead aggregated generation"
      pageDescription={
        <p>
          An estimate of the total net scheduled generation (MW) per bidding
          zone, per Settlement Period of the following day.
        </p>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <p>
          The information shall be published no later than 18:00 Brussels time,
          one day before actual delivery takes place.
        </p>
      }
    />
    <FullWidthChartSection>
      <DayAheadAggregatedGenerationChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection
      documentationInformation={{
        chart: ["forecast/generation/day-ahead"],
      }}
    />
  </>
);

export default DayAheadAggregatedGeneration;
