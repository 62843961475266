import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const StyledSelect = styled.select`
  display: block;
  width: 100%;
  border: none;
  border: 1px solid ${colours.elexonGrey};
  border-radius: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: ${fonts.small};
  padding: 0 10px;

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid ${colours.elexonBlue};
  }

  &.invalid {
    border: 1px solid ${colours.elexonRed};
    padding-left: 33px;
  }
`;
