import {
  getAllAssets,
  getAllParticipants,
} from "api/dataVisualisation/reference/reference";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { useEffect } from "react";
import { combineRequestStatuses } from "utils/requestHelpers";

const useParticipantAndAssetData = (): {
  assetAndParticipantIDs: {
    participantIDs: string[];
    assetIDs: string[];
  };
  assetAndParticipantStatus: RequestStatus;
} => {
  const {
    data: participants,
    status: participantsStatus,
    request: fetchParticipantsData,
  } = useRequest(getAllParticipants);

  useEffect(() => {
    fetchParticipantsData();
  }, [fetchParticipantsData]);

  const {
    data: assets,
    status: assetsStatus,
    request: fetchAssetsData,
  } = useRequest(getAllAssets);

  useEffect(() => {
    fetchAssetsData();
  }, [fetchAssetsData]);

  const assetAndParticipantStatus = combineRequestStatuses(
    participantsStatus,
    assetsStatus
  );

  const assetAndParticipantIDs = {
    participantIDs: participants ?? [],
    assetIDs: assets ?? [],
  };

  return {
    assetAndParticipantIDs,
    assetAndParticipantStatus,
  };
};

export default useParticipantAndAssetData;
