import ComponentWithRequest from "components/components/ComponentWithRequest/ComponentWithRequest";
import { RequestStatus } from "hooks/useRequest";

import { StyledTableContainer } from "./style";

interface HasId {
  id: number | string;
}

interface Props<TItem> {
  children: JSX.Element;
  data: TItem[];
  dataFetchStatus: RequestStatus;
  rerequestData: () => void;
  maxRows?: number;
}

const TableWithErrorHandling = <TItem extends HasId>({
  children,
  data,
  dataFetchStatus,
  rerequestData,
  maxRows,
}: Props<TItem>): JSX.Element => {
  const requestSuccessfulOrNotStarted =
    dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

  const dataIsEmpty = requestSuccessfulOrNotStarted && data.length == 0;
  const tooMuchData = !!maxRows && data.length > maxRows;

  return (
    <StyledTableContainer
      data-test-id="table-container"
      style={{ minHeight: requestSuccessfulOrNotStarted ? "10rem" : "20rem" }}
    >
      <ComponentWithRequest
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
        dataIsEmpty={dataIsEmpty}
        totalRows={data.length}
        maxRows={maxRows}
      >
        {requestSuccessfulOrNotStarted &&
          !dataIsEmpty &&
          !tooMuchData &&
          children}
      </ComponentWithRequest>
    </StyledTableContainer>
  );
};

export default TableWithErrorHandling;
