import PageMeta from "components/components/Meta/PageMeta";
import SystemSellBuyPricesChartContainer from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesChartContainer/SystemSellBuyPricesChartContainer";
import AdditionalInfoSection, {
  ExternalDataSource,
} from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import React from "react";

const SystemSellBuyPrices: React.FC = () => (
  <>
    <PageMeta title="System Sell Buy Prices" />
    <PageHeader
      title="System sell and buy prices"
      pageDescription={
        <>
          <p>
            The indicative price calculation is published 15 minutes after the
            effective Settlement Period based on parameters available at the
            time the calculations were run.
          </p>
          <p>
            System Buy/Sell Prices is displaying the indicative £/MWh for a
            settlement period. It is derived from Bid-Offer Acceptances, BSAD
            and Market Index Data.
          </p>
          <p>
            Net Imbalance Volume is the net imbalance volume (in MWh) of the
            total system for a given Settlement Period. It is derived by netting
            Buy and Sell Actions in the Balancing Mechanism. Where the NIV is
            positive, the system is short and would normally result in the SO
            accepting Offers to increase generation/decrease consumption.
          </p>
        </>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <p>
          The calculations are refreshed at D+1, i.e. the following day, to
          account for late actions or changes made by the System Operator
          showing a more accurate view of the price calculations.
        </p>
      }
    />
    <FullWidthChartSection>
      <SystemSellBuyPricesChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection
      documentationInformation={{
        chart: [
          "balancing/settlement/system-prices/{settlementDate}/{settlementPeriod}",
          "balancing/settlement/system-prices/{settlementDate}",
        ],
      }}
      source={ExternalDataSource.Elexon}
    />
  </>
);

export default SystemSellBuyPrices;
