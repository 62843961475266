import { Serie, Datum } from "@nivo/line";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { RangedChartData } from "models/rangedChartData";
import { sortByTimeFrom } from "utils/ChartDataUtils/index";
import { PointShape } from "utils/ChartDrawUtils/pointShapes";

export const getDatasetNamesFromRangedData = <T>(
  rawData: RangedChartData<T>[]
): T[] =>
  rawData.reduce(
    (datasets: T[], rangedChartData) =>
      datasets.includes(rangedChartData.dataset)
        ? datasets
        : [...datasets, rangedChartData.dataset],
    []
  );

const mapRangedDataToSerie = <T extends string>(
  dataset: T,
  rangedData: RangedChartData<T>[],
  dateFilter: DateFilterModel,
  pointShapeDict: { [key in T]: PointShape },
  colourDict: { [key in T]: string }
): Serie => {
  const pointShape = pointShapeDict[dataset];
  const serieData: Datum[] = rangedData.reduce(
    (
      dataPoints,
      { timeFrom, timeTo, levelFrom, levelTo, settlementPeriod },
      index
    ): Datum[] => {
      const nextPointIsContinuous =
        rangedData[index + 1]?.timeFrom.getTime() === timeTo.getTime();
      return [
        ...dataPoints,
        ...(timeFrom >= dateFilter.startDate
          ? [
              {
                x: timeFrom,
                y: levelFrom,
                settlementPeriod,
                pointShape,
              },
            ]
          : []),
        ...(timeTo <= dateFilter.endDate
          ? [
              {
                x: timeTo,
                y: levelTo,
                settlementPeriod,
                pointShape,
              },
            ]
          : []),
        ...(timeTo < dateFilter.endDate && !nextPointIsContinuous
          ? [{ x: timeTo, y: undefined }]
          : []),
      ];
    },
    [] as Datum[]
  );

  return {
    id: dataset,
    colour: colourDict[dataset],
    data: serieData,
  };
};

export const transformRangedChartData = <T extends string>(
  rawData: RangedChartData<T>[],
  dateFilter: DateFilterModel,
  pointShapeDict: { [key in T]: PointShape },
  colourDict: { [key in T]: string }
): Serie[] => {
  const datasets = getDatasetNamesFromRangedData(rawData);
  return datasets.map((dataset): Serie => {
    const pairData = sortByTimeFrom(
      rawData.filter(
        ({ dataset: currentDatasetName }) => dataset === currentDatasetName
      )
    );
    return mapRangedDataToSerie(
      dataset,
      pairData,
      dateFilter,
      pointShapeDict,
      colourDict
    );
  });
};

type DatasetModelOptions<T extends string> = {
  datasetDisplayOrder: T[];
  data: Serie[] | null;
  title: string;
  pointShapeDict?: { [key in T]: PointShape };
  displayNameDict: { [key in T]: string };
};

export const getSingleCategoryChartDatasetModelFromSeries = <T extends string>({
  datasetDisplayOrder,
  data,
  title,
  pointShapeDict,
  displayNameDict,
}: DatasetModelOptions<T>): DatasetCategory<ChartDatasetModel>[] => [
  {
    datasets: data
      ? datasetDisplayOrder.reduce((result, dataset) => {
          const serieForDataset = data.find((serie) => serie.id === dataset);
          if (!serieForDataset) {
            return result;
          }
          const { id, colour } = serieForDataset;
          return [
            ...result,
            {
              displayName: displayNameDict[id as T],
              dataKey: id as string,
              colour,
              visible: true,
              swatchShape: pointShapeDict?.[id as T] ?? PointShape.Circle,
            },
          ];
        }, [] as ChartDatasetModel[])
      : [],
    id: title,
  },
];
