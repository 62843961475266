import { Serie } from "@nivo/line";
import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";

interface DatumWithType {
  datasetType: string;
  value: number;
}

export interface StackedChartDataModel {
  datapoints: DatumWithType[];
  date: Date;
}

const addDataToSeries = (
  series: Serie[],
  dataModel: StackedChartDataModel
): Serie[] =>
  series.reduce((prev, curr) => {
    const currData = dataModel.datapoints.find(
      (dm) => dm.datasetType == curr.id
    );
    return [
      ...prev,
      {
        id: curr.id,
        colour: curr.colour,
        data: [
          ...curr.data,
          {
            x: new Date(dataModel.date),
            y: currData?.value ?? 0,
            valueNullish:
              currData?.value === null || currData?.value === undefined,
          },
        ],
      },
    ];
  }, [] as Serie[]);

export const transformDataToSeries = (
  rawData: StackedChartDataModel[],
  datasets: ChartDatasetModel[]
): Serie[] => {
  const initialSeries = datasets
    .filter((entry) => !entry.isHidden)
    .map<Serie>((entry) => ({
      id: entry.dataKey,
      colour: entry.colour,
      data: [],
    }));
  const results = rawData.reduce(addDataToSeries, initialSeries);
  return results.reverse();
};
