import { RangedChartData } from "models/rangedChartData";
import { PointShape } from "utils/ChartDrawUtils/pointShapes";

export type PhysicalChartDataset =
  | "PN"
  | "BOAL"
  | "MELS"
  | "MILS"
  | "SEL"
  | "SIL"
  | "QPN";

export type StableLimitChartDataset = "SIL" | "SEL";

export const physicalChartDatasetDisplayName: {
  [key in PhysicalChartDataset]: string;
} = {
  PN: "Final Physical Notification (FPN)",
  BOAL: "Bid Offer Acceptance Level (BOAL)",
  MELS: "Maximum Export Limit (MEL)",
  MILS: "Maximum Import Limit (MIL)",
  SEL: "Stable Export Limit (SEL)",
  SIL: "Stable Import Limit (SIL)",
  QPN: "Quiescent Physical Notification (QPN)",
};

export const physicalDatasetDisplayOrder: PhysicalChartDataset[] = [
  "PN",
  "BOAL",
  "MELS",
  "MILS",
  "SEL",
  "SIL",
  "QPN",
];

export const pointShapeForPhysicalDataset: {
  [key in PhysicalChartDataset]: PointShape;
} = {
  PN: PointShape.Diamond,
  BOAL: PointShape.Square,
  MELS: PointShape.Circle,
  MILS: PointShape.Pentagon,
  SEL: PointShape.None,
  SIL: PointShape.None,
  QPN: PointShape.None,
};

export type PhysicalChartData = RangedChartData<PhysicalChartDataset>;

export interface StableLimitData {
  dataset: StableLimitChartDataset;
  bmUnit: string;
  nationalGridBmUnit: string;
  time: Date;
  value: number;
  settlementDate: Date;
  settlementPeriod: number;
}

export interface StableLimitRangedData {
  dataset: StableLimitChartDataset;
  bmUnit: string;
  nationalGridBmUnit: string;
  timeFrom: Date;
  timeTo: Date;
  level: number;
  publishTime: Date;
}

export type PhysicalChartDataWithStableLimitData = {
  rangedData: PhysicalChartData[];
  stableLimitChartData: StableLimitData[];
};
