import { AxisProps } from "@nivo/axes";
import { LineSvgProps, Serie } from "@nivo/line";
import { ScaleLinearSpec, ScaleSpec } from "@nivo/scales";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { chartLayers } from "styles/chartStyles/chartLayers";
import colours from "styles/colours";
import { getTickArrangementForChartSeries } from "utils/ChartDrawUtils/chartTickArrangement";
import {
  getExtremaForNonStackedLineChartYScale,
  getMaxForStackedLineChartYScale,
  getXTimeValuesExtrema,
} from "utils/chartHelpers";
import { addMinsToDate } from "utils/dateHelpers";

export const CHART_VERTICAL_PADDING_MULTIPLIER = 0.1;

export const chartAxisBottom = (
  legend: string,
  series: Serie[],
  isChartLoaded: boolean,
  tickValues?: AxisProps["tickValues"],
  axisValueFormatter?: AxisProps["format"]
): AxisProps => {
  const { tickValues: computedTickValues, format: computedFormat } =
    getTickArrangementForChartSeries(series, isChartLoaded);

  return {
    tickValues: tickValues ?? computedTickValues,
    format: axisValueFormatter ?? computedFormat,
    legend,
    legendPosition: "middle",
    legendOffset: 65,
  };
};

export const emptyChartAxisBottom: AxisProps = {
  tickValues: [],
};

export const chartAxisLeft = (
  legend: string,
  isChartLoaded: boolean,
  tickValues?: AxisProps["tickValues"]
): AxisProps => ({
  ...{
    legend,
    legendPosition: "middle" as "middle",
    legendOffset: -80,
  },
  ...(isChartLoaded ? { tickValues } : { tickValues: [] }),
});

type ChartMargin = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

const chartMargin: ChartMargin = {
  top: 20,
  right: 80,
  bottom: 80,
  left: 120,
};

export const modifyChartMargin = (
  increaseBy: Partial<ChartMargin>
): ChartMargin =>
  Object.entries(increaseBy).reduce(
    (result, [marginName, value]) => ({
      ...result,
      [marginName]: chartMargin[marginName as keyof ChartMargin] + value,
    }),
    { ...chartMargin } as ChartMargin
  );

export const chartTheme = {
  axis: {
    legend: {
      text: {
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: '"Source Sans Pro", sans-serif',
      },
    },
    ticks: {
      text: {
        fontSize: "16px",
        fontFamily: '"Source Sans Pro", sans-serif',
      },
      line: {
        stroke: colours.elexonBlack,
      },
    },
    domain: {
      line: {
        stroke: colours.elexonBlack,
      },
    },
  },
  crosshair: {
    line: {
      stroke: colours.darkGrey,
      strokeDasharray: "1 0",
    },
  },
  grid: {
    line: {
      stroke: colours.elexonGrey,
      strokeDasharray: "3 3",
    },
  },
};

export const chartXTimeScale = (
  min: Date | "auto" = "auto",
  max: Date | "auto" = "auto"
): ScaleSpec => ({
  type: "time" as "time",
  min,
  max,
  useUTC: true,
});

export const chartXTimeScaleWithDumbbellPadding = (
  dateRange: DateFilterModel
): ScaleSpec => {
  return chartXTimeScale(
    // Add padding to the left-hand side of the chart so dumbbells don't overlap with the y-axis
    addMinsToDate(dateRange.startDate, -30),
    dateRange.endDate
  );
};

type ScaleMargins = {
  startMargin?: number;
  endMargin?: number;
};

export const chartXTimeScaleFromSeriesWithMargin = (
  series: Serie[],
  { startMargin = 0, endMargin = 0 }: ScaleMargins = {}
): ScaleSpec => {
  const { minXAxisValue, maxXAxisValue } = getXTimeValuesExtrema(series);
  const minTime = minXAxisValue.getTime();
  const maxTime = maxXAxisValue.getTime();
  const range = maxTime - minTime;
  return chartXTimeScale(
    new Date(minTime - (startMargin / 100) * range),
    new Date(maxTime + (endMargin / 100) * range)
  );
};

export const stackedLineChartYScale = (series: Serie[]): ScaleLinearSpec => ({
  type: "linear",
  stacked: true,
  min: 0,
  max: getMaxForStackedLineChartYScale(series),
});

type NonStackedLineChartYScaleOptions = {
  topMarginPercentage?: number;
  bottomMarginPercentage?: number;
  maximumMin?: number;
  minimumMax?: number;
  centre?: number;
};

export const nonStackedLineChartYScale = (
  series: Serie[],
  {
    centre,
    maximumMin,
    minimumMax,
    // Note that margins are applied after the calculation of the centre and minMax
    topMarginPercentage = 0,
    bottomMarginPercentage = 0,
  }: NonStackedLineChartYScaleOptions = {}
): ScaleLinearSpec => {
  const { min, max } = getExtremaForNonStackedLineChartYScale(series, centre);
  const chartRange = max - min;
  return {
    type: "linear",
    stacked: false,
    min:
      (maximumMin !== undefined ? Math.min(min, maximumMin) : min) -
      (chartRange * bottomMarginPercentage) / 100,
    max:
      (minimumMax !== undefined ? Math.max(max, minimumMax) : max) +
      (chartRange * topMarginPercentage) / 100,
  };
};

export const commonChartProps = (
  isChartLoaded: boolean,
  series: Serie[]
): LineSvgProps => ({
  animate: false,
  areaOpacity: 1,
  colors: { datum: "colour" },
  crosshairType: "x",
  data: isChartLoaded ? series : [],
  enableGridX: false,
  enableGridY: isChartLoaded,
  enablePoints: false,
  enableSlices: false,
  layers: chartLayers,
  margin: chartMargin,
  theme: chartTheme,
  role: "group",
});
