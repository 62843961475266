import TabDescription from "components/components/Tabs/TabDescription/TabDescription";
import React from "react";

export interface Props {
  description: string;
  modal: JSX.Element;
  openModal: () => void;
  children: JSX.Element;
}

const TabContentWithDescription: React.FC<Props> = ({
  description,
  modal,
  openModal,
  children,
}) => {
  return (
    <>
      {modal}
      <TabDescription openModal={openModal}>
        <>{description}</>
      </TabDescription>
      {children}
    </>
  );
};

export default TabContentWithDescription;
