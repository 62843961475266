import BalancingServicesVolume from "components/dataVisualization/balancingMechanismTabs/BalancingServicesVolume/BalancingServicesVolume";
import BalancingServicesVolumeChartContainer from "components/dataVisualization/balancingMechanismTabs/BalancingServicesVolume/bmuView/BalancingServicesVolumeChartContainer/BalancingServicesVolumeChartContainer";
import { useBmuViewContext } from "contexts/BmuViewContext";
import React from "react";

const BmuViewBalancingServicesVolume: React.FC = () => {
  const {
    balancingServicesVolumeDetails: { status, request },
  } = useBmuViewContext();

  return (
    <BalancingServicesVolume status={status} fetchData={request}>
      <BalancingServicesVolumeChartContainer />
    </BalancingServicesVolume>
  );
};

export default BmuViewBalancingServicesVolume;
