import {
  getDemandForecastPeakJsonData,
  getDemandPeakJsonData,
} from "api/dataVisualisation/demandForecast/demandForecast";
import SummaryTile from "components/dataVisualization/dataPageComponents/SummaryTile/SummaryTile";
import {
  Header,
  SummaryTable,
  ValueCell,
  ValueCellNoWrap,
  ValuesHeaderRow,
} from "components/dataVisualization/dataPageComponents/SummaryTile/commonStyle";
import React, { useEffect, useState } from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

interface DemandForecast {
  yesterday: string;
  today: string;
  tomorrow: string;
}

const DemandForecastSummaryTile: React.FC = () => {
  const [demandForecast, setDemandForecast] = useState<DemandForecast>();
  const [demandActualYesterday, setDemandActualYesterday] = useState<string>();

  const getPeakDemandString = (peak: number, time: Date): string =>
    `${peak} (${formatDateTimeString(new Date(time), DateFormat.TimeOnly)})`;

  useEffect(() => {
    getDemandForecastPeakJsonData()
      .then((response) => {
        if (response && response.data.length !== 3) {
          return;
        }

        setDemandForecast({
          yesterday: getPeakDemandString(
            response.data[0].transmissionSystemDemand,
            response.data[0].startTime
          ),
          today: getPeakDemandString(
            response.data[1].transmissionSystemDemand,
            response.data[1].startTime
          ),
          tomorrow: getPeakDemandString(
            response.data[2].transmissionSystemDemand,
            response.data[2].startTime
          ),
        });
      })
      // TODO 36876: Add error handling for failed calls to the API and empty data returned
      // eslint-disable-next-line no-console
      .catch(console.log);

    getDemandPeakJsonData()
      .then((response) => {
        if (response && response.data.length !== 1) {
          return;
        }

        setDemandActualYesterday(
          getPeakDemandString(
            response.data[0].initialTransmissionSystemDemandOutturn,
            response.data[0].startTime
          )
        );
      })
      // TODO 36876: Add error handling for failed calls to the API and empty data returned
      // eslint-disable-next-line no-console
      .catch(console.log);
  }, []);

  return (
    <SummaryTile>
      <SummaryTable tableLayout="auto">
        <thead>
          <tr>
            <Header colSpan={3}>Peak Demand</Header>
          </tr>
        </thead>
        <tbody>
          <ValuesHeaderRow>
            <td />
            <td>Forecast (MW)</td>
            <td>Actual (MW)</td>
          </ValuesHeaderRow>
          <tr>
            <td>Yesterday</td>
            <ValueCellNoWrap data-test-id="summary-tile-data-forecast-yesterday">
              {demandForecast?.yesterday ?? "—"}
            </ValueCellNoWrap>
            <ValueCellNoWrap data-test-id="summary-tile-data-actual-yesterday">
              {demandActualYesterday ?? "—"}
            </ValueCellNoWrap>
          </tr>
          <tr>
            <td>Today</td>
            <ValueCellNoWrap data-test-id="summary-tile-data-forecast-today">
              {demandForecast?.today ?? "—"}
            </ValueCellNoWrap>
            <ValueCell>—</ValueCell>
          </tr>
          <tr>
            <td>Tomorrow</td>
            <ValueCellNoWrap data-test-id="summary-tile-data-forecast-tomorrow">
              {demandForecast?.tomorrow ?? "—"}
            </ValueCellNoWrap>
            <ValueCell>—</ValueCell>
          </tr>
        </tbody>
      </SummaryTable>
    </SummaryTile>
  );
};

export default DemandForecastSummaryTile;
