import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { SettlementMessagesModel } from "models/settlementMessages/settlementMessagesModel";
import { getPathsFromDateFilter } from "utils/DateUtils";
import { stitchDataResults } from "utils/dataStitchHelpers";

export const getSettlementMessagesData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<SettlementMessagesModel>> => {
  const paths = getPathsFromDateFilter(dateFilter);
  const results = await Promise.all(
    paths.map(
      async (path) =>
        (
          await getData(`/balancing/settlement/messages/${path}`, { format })
        ).data
    )
  );
  return stitchDataResults(results, format ?? DataDownloadFormat.Json);
};

export const getSettlementMessagesJsonData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<SettlementMessagesModel>> => {
  return (await getSettlementMessagesData(
    dateFilter,
    DataDownloadFormat.Json
  )) as JsonDataDownloadModel<SettlementMessagesModel>;
};
