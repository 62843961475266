export enum ToastState {
  Complete,
  Error,
  InProgress,
}

export interface ToastDetails {
  id?: string;
  title: string;
  message?: string;
  state: ToastState;
}
