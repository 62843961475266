import styled from "styled-components";
import colours from "styles/colours";

interface Props {
  backgroundColour?: string;
  textColour?: string;
}

const Bubble = styled.div<Props>`
  width: min-content;
  background-color: ${(props: Props): string =>
    props.backgroundColour ?? colours.elexonGreen};
  border-radius: 36px;
  padding: 0.3em 1.5em;
  margin-bottom: 1em;
  color: ${(props: Props): string => props.textColour ?? colours.elexonBlack};
  font-weight: bold;
  font-size: 13px;
`;

export default Bubble;
