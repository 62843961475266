import { Datum, Serie } from "@nivo/line";
import { DailyEnergyTransmittedModel } from "models/dailyEnergyTransmitted/dailyEnergyTransmittedModel";
import { chartColours } from "styles/colours";

export const transformDailyEnergyTransmittedData = (
  rawData: DailyEnergyTransmittedModel[]
): Serie[] => {
  const data = rawData.reduce(
    (prev, curr) => [
      ...prev,
      {
        x: new Date(curr.settlementDate),
        y: curr.demand,
      },
    ],
    [] as Datum[]
  );

  return [
    {
      id: "outturn",
      colour: chartColours.dailyEnergyTransmittedOutturn,
      data,
    },
  ];
};
