import { getSystemWarningsJsonData } from "api/dataVisualisation/systemWarnings/systemWarnings";
import { setWarningTypeNullIfAllMessagesSelected } from "components/dataVisualization/Events/SystemWarnings/utils";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { SystemWarningsModel } from "models/systemWarnings/systemWarningsModel";
import { useCallback, useEffect, useRef } from "react";

const useSystemWarningsData = (
  dateFilter: DateFilterModel,
  warningType: string
): {
  fetchData: () => void;
  data: SystemWarningsModel[] | null;
  status: RequestStatus;
  retryAttempted: boolean;
} => {
  const retryAttemptedRef = useRef(false);

  const {
    data,
    status,
    request: fetchSystemWarningsData,
  } = useRequest(getSystemWarningsJsonData);

  const fetchData = useCallback(() => {
    fetchSystemWarningsData(
      dateFilter,
      setWarningTypeNullIfAllMessagesSelected(warningType)
    );
    retryAttemptedRef.current = false;
  }, [dateFilter, warningType, fetchSystemWarningsData]);

  useEffect(() => {
    if (data?.data.length === 0 && !retryAttemptedRef.current) {
      retryAttemptedRef.current = true;
      fetchSystemWarningsData(
        null,
        setWarningTypeNullIfAllMessagesSelected(warningType)
      );
    }
  }, [data, fetchSystemWarningsData, warningType]);

  const retryAttempted = retryAttemptedRef.current;

  return {
    data: data?.data ?? null,
    status,
    fetchData,
    retryAttempted,
  };
};

export default useSystemWarningsData;
