import { CheckboxDataGroup } from "components/components/CheckboxList/CheckboxList";
import {
  CheckboxDataState,
  CheckboxState,
  ChevronState,
} from "components/components/CheckboxList/CheckboxListContainer";
import React, { useReducer } from "react";

export enum ListClickType {
  CHECKBOX,
  CHEVRON,
}

export type ListClickEvent = {
  id: number;
  clickType: ListClickType;
};

export const updateCheckboxDataStates = (
  CheckboxDataStates: CheckboxDataState[],
  clickEvent: ListClickEvent
): CheckboxDataState[] => {
  const newState = CheckboxDataStates.map((i) => ({ ...i }));
  const clickedId = clickEvent.id;

  const updateParent = (id: number): void => {
    const checkboxStateItem = CheckboxDataStates[id];

    if (checkboxStateItem.parentId === null) {
      return;
    }

    const checkboxStateParent = CheckboxDataStates[checkboxStateItem.parentId];

    const childStates = CheckboxDataStates.filter(
      ({ parentId }) => parentId === checkboxStateParent.id
    ).map(({ id: childId }) => newState[childId].checkboxState);

    const parentState = newState[checkboxStateParent.id];

    if (childStates.every((s) => s === CheckboxState.CHECKED)) {
      parentState.checkboxState = CheckboxState.CHECKED;
    } else if (childStates.every((s) => s === CheckboxState.UNCHECKED)) {
      parentState.checkboxState = CheckboxState.UNCHECKED;
    } else {
      parentState.checkboxState = CheckboxState.INDETERMINATE;
    }
    updateParent(checkboxStateParent.id);
  };

  const updateChildren = (id: number, f: (id: number) => void): void => {
    CheckboxDataStates.filter((i) => i.parentId === id)
      .map((i) => i.id)
      .forEach((childId) => f(childId));
  };

  const setUnchecked = (id: number): void => {
    newState[id].checkboxState = CheckboxState.UNCHECKED;
    newState[id].chevronState = ChevronState.CLOSED;
    updateChildren(id, setUnchecked);
  };

  const setChecked = (id: number): void => {
    newState[id].checkboxState = CheckboxState.CHECKED;
    newState[id].chevronState = ChevronState.OPEN;
    updateChildren(id, setChecked);
  };

  const setClosed = (id: number): void => {
    newState[id].chevronState = ChevronState.CLOSED;
    updateChildren(id, setClosed);
  };

  const setOpened = (id: number): void => {
    newState[id].chevronState = ChevronState.OPEN;
  };

  const resetChevrons = (): void => {
    newState.forEach((e) => {
      e.chevronState = ChevronState.CLOSED;
    });
  };

  const openParentChevrons = (initialId: number): void => {
    let parentId: number | null = initialId;
    while (parentId) {
      newState[parentId].chevronState = ChevronState.OPEN;
      parentId = newState[parentId].parentId;
    }
  };

  switch (clickEvent.clickType) {
    case ListClickType.CHEVRON:
      if (newState[clickedId].chevronState === ChevronState.OPEN) {
        setClosed(clickedId);
      } else {
        resetChevrons();
        setOpened(clickedId);
        openParentChevrons(clickedId);
      }
      break;
    case ListClickType.CHECKBOX:
      if (newState[clickedId].checkboxState === CheckboxState.CHECKED) {
        setUnchecked(clickedId);
        updateParent(clickedId);
      } else {
        resetChevrons();
        setChecked(clickedId);
        updateParent(clickedId);
        openParentChevrons(clickedId);
      }
      break;
    default:
      break;
  }

  return newState;
};

const useCheckboxList = (
  checkboxList: CheckboxDataGroup[],
  defaultChecked?: boolean
): [CheckboxDataState[], React.Dispatch<ListClickEvent>] => {
  const defaultCheckboxDataStates: CheckboxDataState[] = checkboxList.map((i) =>
    defaultChecked
      ? {
          ...i,
          checkboxState: CheckboxState.CHECKED,
          chevronState: ChevronState.OPEN,
        }
      : {
          ...i,
          checkboxState: CheckboxState.UNCHECKED,
          chevronState: ChevronState.CLOSED,
        }
  );

  const [CheckboxDataStates, registerClickForListItem] = useReducer(
    updateCheckboxDataStates,
    defaultCheckboxDataStates
  );
  return [CheckboxDataStates, registerClickForListItem];
};

export default useCheckboxList;
