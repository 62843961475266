import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import MarketIndexPricesChartContainer from "components/dataVisualization/marketIndexPrices/MarketIndexPricesChartContainer/MarketIndexPricesChartContainer";
import { ChartFocussedDateProvider } from "contexts/ChartFocussedDateContext";

const MarketIndexPrices: React.FC = () => (
  <ChartFocussedDateProvider>
    <>
      <PageMeta title="Market index prices" />
      <PageHeader
        title="Market index prices"
        pageDescription={
          <>
            <p>
              Market Index Price data is received from each of the appointed
              Market Index Data Providers (MIDPs) and reflects the price of
              wholesale electricity in Great Britain for the relevant period in
              the short term markets. Market Index Prices are a key component in
              the calculation of System Buy Price and System Sell Price for each
              Settlement Period.
            </p>
          </>
        }
        expanderHeading="What you need to know while using this data"
        expanderContent={
          <>
            <p>
              The graph&apos;s default view is the last 24 hours and you are
              able to change to a week view or a custom date range.
            </p>
            <p>
              The graph shows the Market Index Prices in £/MWh with a line each
              for price reported by APX and N2EX. The corresponding Market
              Volume Data in MWh is available in the tooltip when hovering over
              the graph.
            </p>
          </>
        }
      />
      <FullWidthChartSection>
        <MarketIndexPricesChartContainer />
      </FullWidthChartSection>
      <AdditionalInfoSection
        documentationInformation={{
          chart: ["balancing/pricing/market-index"],
        }}
      />
    </>
  </ChartFocussedDateProvider>
);

export default MarketIndexPrices;
