import { Serie } from "@nivo/line";
import {
  DgwsProcess,
  DgwsPsr,
  dgwsProcessTypes,
  dgwsPsrTypes,
  getDgwsColour,
  // eslint-disable-next-line max-len
} from "components/dataVisualization/generationForecastForWindAndSolar/GenerationForecastForWindAndSolarChart/GenerationForecastForWindAndSolarDatasets";
import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";
import {
  DgwsProcessString,
  DgwsPsrString,
  GenerationForecastForWindAndSolar,
} from "models/generationForecastForWindAndSolar/generationForecastForWindAndSolarModel";

const processTypesMapping = {
  "Day ahead": DgwsProcess.DayAhead,
  "Intraday process": DgwsProcess.Intraday,
  "Intraday total": DgwsProcess.Current,
};

const psrTypesMapping = {
  Solar: DgwsPsr.Solar,
  "Wind Offshore": DgwsPsr.WindOffshore,
  "Wind Onshore": DgwsPsr.WindOnshore,
};

const mapDgwsProcess = (processString: DgwsProcessString): DgwsProcess =>
  processTypesMapping[processString];

const mapDgwsPsr = (processString: DgwsPsrString): DgwsPsr =>
  psrTypesMapping[processString];

export const transformGenerationForecastForWindAndSolarData = (
  rawData: GenerationForecastForWindAndSolar[],
  datasets: ChartDatasetModel[]
): Serie[] => {
  const chartData = dgwsPsrTypes.flatMap((psrType) => {
    const rawDataFilteredByDgwsPsr = rawData.filter(
      (d) => mapDgwsPsr(d.psrType) === psrType
    );

    return (
      dgwsProcessTypes
        .map((processType) => ({
          id: `${psrType}-${processType}`,
          colour:
            // Only Day-ahead is shown as a line
            processType === DgwsProcess.DayAhead
              ? getDgwsColour(psrType, processType)
              : "transparent",
          data: rawDataFilteredByDgwsPsr
            .filter((d) => mapDgwsProcess(d.processType) === processType)
            .map((d) => ({
              x: new Date(d.startTime),
              y: d.quantity,
              settlementPeriod: d.settlementPeriod,
              publishTime: new Date(d.publishTime),
            })),
        }))
        // there are times throughout the day where we don't have data for
        // all PSR types but we still want to display the graph, therefore
        // remove lines without data so "null" paths are not drawn
        .filter((s) => s.data.length > 0)
    );
  });

  return chartData.filter((s) => datasets.some((d) => d.dataKey === s.id));
};
