import { getTooltipValueSuffixForDataset } from "components/dataVisualization/SystemSellBuyPrices/utils";
import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";
import {
  systemPricesMap,
  SystemPricesTooltip,
} from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import { systemPricesColours } from "styles/colours";
import { defaultColumnFormatter } from "utils/ChartDataUtils";

export const systemBuySellPricesChartDatasetModel: ChartDatasetModel[] =
  Object.entries(systemPricesMap).map(([key, val]) => ({
    colour: systemPricesColours[key as SystemPricesTooltip],
    dataKey: `buy-sell-${key}`,
    displayName: val,
  }));

export const sellBuyConfig = {
  datasetCategories: [
    {
      datasets: systemBuySellPricesChartDatasetModel,
      id: "sell-buy-data",
      title: "System Buy / Sell Prices",
    },
  ],
  tooltipColumns: [
    {
      id: "sell-buy",
      dataKey: "y",
      formatter: getTooltipValueSuffixForDataset,
    },
  ],
};

export const nivConfig = {
  datasetCategories: [
    {
      datasets: systemBuySellPricesChartDatasetModel,
      id: "niv-data",
      title: "NIV",
    },
  ],
  tooltipColumns: [
    {
      id: "niv",
      dataKey: "y",
      formatter: defaultColumnFormatter({ suffix: "MW" }),
    },
  ],
};
