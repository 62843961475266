import { getStitchedBmusData } from "api/dataVisualisation/client";
import { BalancingServicesVolumeModel } from "models/bmuData/balancingServicesVolumeModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";

export const getBalancingServicesVolumeData = async (
  dateFilter: DateFilterModel,
  bmUnits?: string[],
  format?: DataDownloadFormat
): Promise<DataDownloadModel<BalancingServicesVolumeModel>> =>
  (await getStitchedBmusData(
    "/balancing/nonbm/volumes",
    {
      from: dateFilter.normalisedStartDate.toISOString(),
      to: dateFilter.normalisedEndDate.toISOString(),
    },
    bmUnits,
    format
  )) as DataDownloadModel<BalancingServicesVolumeModel>;

export const getBalancingServicesVolumeJsonData = (
  dateFilter: DateFilterModel,
  bmUnits?: string[]
): Promise<JsonDataDownloadModel<BalancingServicesVolumeModel>> =>
  getBalancingServicesVolumeData(dateFilter, bmUnits) as Promise<
    JsonDataDownloadModel<BalancingServicesVolumeModel>
  >;
