import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { ClientSecret, deleteIrisSecret } from "api/account/client";
import Button from "components/components/Button/Button";
import {
  ButtonsDiv,
  ContentContainer,
  DeleteButton,
} from "components/components/Iris/DeleteSecretModal/style";
import { LoadingSpinnerContainer } from "components/components/Iris/commonStyle";
import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import {
  RequestStatus,
  useRequestWithDefaultErrorHandling,
} from "hooks/useRequest";
import React from "react";

type Props = {
  secret: ClientSecret | null;
  close: () => void;
};

const DeleteSecretModal: React.FC<Props> = ({ secret, close }) => {
  const isActive = secret !== null;

  const { request: deleteSecretRequest, status: deletionStatus } =
    useRequestWithDefaultErrorHandling(
      deleteIrisSecret,
      "Sorry, something went wrong when deleting the secret."
    );

  const deleteSecret = async (): Promise<void> => {
    if (secret) {
      await deleteSecretRequest(secret?.secretId);
      close();
    }
  };

  return (
    <Modal size={ModalSize.medium} isActive={isActive} onClose={close}>
      <div aria-live="polite">
        {deletionStatus === RequestStatus.IN_PROGRESS ? (
          <LoadingSpinnerContainer>
            <LoadingSpinner isLoading />
            <p>Deleting secret...</p>
          </LoadingSpinnerContainer>
        ) : (
          <ContentContainer>
            <h2>Are you sure you want to delete this secret?</h2>
            <p>
              Please ensure secret <b>{secret?.secretHint.padEnd(18, "*")}</b>{" "}
              is not one you are currently using in your client, otherwise you
              will stop receiving IRIS messages.
            </p>
            <hr />
            <ButtonsDiv>
              <DeleteButton
                ariaLabel="Delete secret"
                buttonText="Delete secret"
                iconName={faTrashAlt}
                onClick={deleteSecret}
              />
              <Button ariaLabel="Cancel" buttonText="Cancel" onClick={close} />
            </ButtonsDiv>
          </ContentContainer>
        )}
      </div>
    </Modal>
  );
};

export default DeleteSecretModal;
