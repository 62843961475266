import {
  getGenerationForecast14DayFOUDownloadData,
  getGenerationForecast3YearFOUDownloadData,
} from "api/dataVisualisation/generationForecast/fou/fou";
import {
  getGenerationForecast14DayNOUDownloadData,
  getGenerationForecast3YearNOUDownloadData,
} from "api/dataVisualisation/generationForecast/nou/nou";
import {
  getGenerationForecast14DayUOUDownloadData,
  getGenerationForecast3YearUOUDownloadData,
} from "api/dataVisualisation/generationForecast/uou/uou";
import StackedLineChart from "components/dataVisualization/chartComponents/StackedLineChart/StackedLineChart";
import {
  ChartBody,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import ChartFilterDrawer from "components/dataVisualization/dataPageComponents/ChartFilterDrawer/ChartFilterDrawer";
import ForecastFilter from "components/dataVisualization/dataPageComponents/ForecastFilter/ForecastFilter";
import GenerationForecastDataExporter from "components/dataVisualization/generationForecast/GenerationForecastDataExporter/GenerationForecastDataExporter";
import { useDataExport } from "contexts/DataExportContext";
import { useDatasetCategoriesWithVisibilities } from "hooks/useDatasetsCategoriesWithVisibilities";
import useToggle from "hooks/useToggle";
import { combineDatasetCategories } from "models/chartConfiguration/chartDatasetModel";
import { DataDownloadAggregationType } from "models/dataDownload/dataDownloadAggregationType";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { DataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import React, { useEffect, useMemo, useState } from "react";
import { sortByForecastDate } from "utils/ChartDataUtils";
import { transformGenerationForecastModelData } from "utils/ChartDataUtils/generationForecast/generationForecast";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  FuelTypeDatasetIds,
  mapFuelTypesToDatasetCategories,
} from "utils/FuelTypeMap";

import useGenerationForecastChartContainerData from "./useGenerationForecastChartContainerData";

const GenerationForecastChartContainer: React.FC = () => {
  const [forecastFilter, setForecastFilter] = useState<ForecastFilterOption>(
    ForecastFilterOption.twoToFourteenDay
  );
  const [showChartFilterDrawer, toggleChartFilterDrawer] = useToggle(false);

  const {
    allDatasetCategories,
    datasetCategoriesToDisplay,
    setDatasetCategories,
    setMatchingDatasetsVisibility,
  } = useDatasetCategoriesWithVisibilities();

  const [highlightedDataset, setHighlightedDataset] = useState("");
  const { dispatch: dataExportCountDispatch } = useDataExport();

  const {
    data: rawData,
    fetchData,
    status,
  } = useGenerationForecastChartContainerData(forecastFilter);

  useEffect(fetchData, [fetchData]);

  useEffect(() => {
    setDatasetCategories(
      rawData?.fuelTypes
        ? mapFuelTypesToDatasetCategories(rawData.fuelTypes)
        : [
            {
              datasets: [],
              id: FuelTypeDatasetIds.FuelType,
              title: "Fuel type",
            },
            {
              datasets: [],
              id: FuelTypeDatasetIds.Interconnectors,
              title: "Interconnectors",
            },
          ]
    );
  }, [rawData?.fuelTypes, setDatasetCategories]);

  const chartData = useMemo(
    () =>
      rawData?.generationForecastData
        ? transformGenerationForecastModelData(
            sortByForecastDate(rawData?.generationForecastData),
            combineDatasetCategories(datasetCategoriesToDisplay)
          )
        : [],
    [rawData?.generationForecastData, datasetCategoriesToDisplay]
  );

  const downloadFile = (
    filter: ForecastFilterOption,
    aggregationType: DataDownloadAggregationType,
    fileFormat: DataDownloadFormat
  ): void => {
    let download: () => Promise<DataDownloadModel<unknown>>;
    let filename: string;

    switch (filter) {
      case ForecastFilterOption.twoToFourteenDay: {
        switch (aggregationType) {
          case DataDownloadAggregationType.NationalOutputUsable:
            download = (): Promise<DataDownloadModel<unknown>> =>
              getGenerationForecast14DayNOUDownloadData(fileFormat);
            break;
          case DataDownloadAggregationType.FuelOutputUsable:
            download = (): Promise<DataDownloadModel<unknown>> =>
              getGenerationForecast14DayFOUDownloadData(fileFormat);
            break;
          case DataDownloadAggregationType.UnitOutputUsable:
            download = (): Promise<DataDownloadModel<unknown>> =>
              getGenerationForecast14DayUOUDownloadData(fileFormat);
            break;
          default:
            break;
        }
        filename = `GenerationForecast-${aggregationType}-14day-${new Date().toISOString()}`;
        break;
      }
      // TODO 47792: This currently downloads the same information as the 156W option, should be truncated to 52W
      case ForecastFilterOption.twoToFiftyTwoWeek: {
        switch (aggregationType) {
          case DataDownloadAggregationType.NationalOutputUsable:
            download = (): Promise<DataDownloadModel<unknown>> =>
              getGenerationForecast3YearNOUDownloadData(fileFormat);
            break;
          case DataDownloadAggregationType.FuelOutputUsable:
            download = (): Promise<DataDownloadModel<unknown>> =>
              getGenerationForecast3YearFOUDownloadData(fileFormat);
            break;
          case DataDownloadAggregationType.UnitOutputUsable:
            download = (): Promise<DataDownloadModel<unknown>> =>
              getGenerationForecast3YearUOUDownloadData(fileFormat);
            break;
          default:
            break;
        }
        filename = `GenerationForecast-${aggregationType}-2to52weeks-${new Date().toISOString()}`;
        break;
      }
      case ForecastFilterOption.twoToOneFiveSixWeek: {
        switch (aggregationType) {
          case DataDownloadAggregationType.NationalOutputUsable:
            download = (): Promise<DataDownloadModel<unknown>> =>
              getGenerationForecast3YearNOUDownloadData(fileFormat);
            break;
          case DataDownloadAggregationType.FuelOutputUsable:
            download = (): Promise<DataDownloadModel<unknown>> =>
              getGenerationForecast3YearFOUDownloadData(fileFormat);
            break;
          case DataDownloadAggregationType.UnitOutputUsable:
            download = (): Promise<DataDownloadModel<unknown>> =>
              getGenerationForecast3YearUOUDownloadData(fileFormat);
            break;
          default:
            break;
        }
        filename = `GenerationForecast-${aggregationType}-2to156weeks-${new Date().toISOString()}`;
        break;
      }
      default: {
        break;
      }
    }

    downloadDataToFile(
      download!,
      filename!,
      fileFormat,
      dataExportCountDispatch
    );
  };

  return (
    <>
      <ChartHeader>
        <ForecastFilter
          forecastFilter={forecastFilter}
          setForecastFilter={setForecastFilter}
          showChartFilterDrawer={showChartFilterDrawer}
          toggleChartFilterDrawer={toggleChartFilterDrawer}
        />
      </ChartHeader>
      <ChartBody>
        {showChartFilterDrawer && (
          <ChartFilterDrawer
            highlightedDataset={highlightedDataset}
            sendHighlightedDataset={setHighlightedDataset}
            setMatchingDatasetsVisibility={setMatchingDatasetsVisibility}
            toggleChartFilterDrawer={toggleChartFilterDrawer}
            datasetCategories={allDatasetCategories}
          />
        )}
        <div style={{ width: showChartFilterDrawer ? "80vw" : "100vw" }}>
          <StackedLineChart
            series={chartData}
            datasetCategories={datasetCategoriesToDisplay}
            sendHighlightedDataset={setHighlightedDataset}
            dataFetchStatus={status}
            rerequestData={fetchData}
          />
        </div>
      </ChartBody>
      <ChartFooter rightAligned>
        <GenerationForecastDataExporter
          downloadData={(
            aggregationType: DataDownloadAggregationType,
            format: DataDownloadFormat
          ): void => downloadFile(forecastFilter, aggregationType, format)}
          forecastFilter={forecastFilter}
          handleChangeToForecastFilter={setForecastFilter}
        />
      </ChartFooter>
    </>
  );
};

export default GenerationForecastChartContainer;
