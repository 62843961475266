import { getData } from "api/dataVisualisation/client";
import { AgwsData } from "models/actualOrEstimatedWindAndSolarPowerGeneration/ActualOrEstimatedWindAndSolarPowerGeneration";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";

export const getAgwsData = async (
  dateFilter: DateFilterModel,
  format: DataDownloadFormat
): Promise<DataDownloadModel<AgwsData>> => {
  const { data } = await getData("/generation/actual/per-type/wind-and-solar", {
    from: dateFilter.normalisedStartDate.toISOString(),
    to: dateFilter.normalisedEndDate.toISOString(),
    format,
  });
  return data;
};

export const getAgwsChartData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<AgwsData>> =>
  getAgwsData(dateFilter, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<AgwsData>
  >;
