import TableWithErrorHandling from "components/components/TableWithErrorHandling/TableWithErrorHandling";
import DetailedSystemPricesTable from "components/dataVisualization/DetailedSystemPrices/DetailedSystemPricesTable/DetailedSystemPricesTable";
import { ConsolidatedData } from "components/dataVisualization/DetailedSystemPrices/SystemPricesHooks/useSystemPricesConsolidatedData";
import {
  createDetailedSystemPricesTableHeaders,
  mapSettlementDataToTableRow,
  StackTypeHeader,
} from "components/dataVisualization/DetailedSystemPrices/utils";
import { ActionType } from "pages/DetailedSystemPrices/utils";
import React, { useMemo } from "react";

interface Props {
  request: ConsolidatedData;
  actionType: ActionType;
}

const BuyStackTab: React.FC<Props> = ({
  request: { data, fetch, status },
  actionType,
}) => {
  const mappedData = useMemo(
    () =>
      data.offer ? mapSettlementDataToTableRow(data.offer, actionType) : [],
    [actionType, data.offer]
  );
  const detailedSystemPricesBuyTableHeaders =
    createDetailedSystemPricesTableHeaders(StackTypeHeader.OFFER);

  return (
    <TableWithErrorHandling
      data={mappedData}
      dataFetchStatus={status}
      rerequestData={fetch.bid}
      aria-label="buy-stack"
    >
      <DetailedSystemPricesTable
        data={mappedData}
        DetailedSystemPricesTableHeaders={detailedSystemPricesBuyTableHeaders}
      />
    </TableWithErrorHandling>
  );
};

export default BuyStackTab;
