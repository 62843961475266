import { getSettlementMessagesData } from "api/dataVisualisation/settlementMessages/settlementMessages";
import DateSelector from "components/components/DateSelector/DateSelector";
import Dropdown from "components/components/Dropdown/Dropdown";
import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import { StyledDiv } from "components/components/SectionWithErrorHandling/style";
import TableWithErrorHandling from "components/components/TableWithErrorHandling/TableWithErrorHandling";
import SettlementMessagesTable from "components/dataVisualization/SettlementMessages/SettlementMessagesTable/SettlementMessagesTable";
import {
  MessageTypeText,
  mapToSettlementMessagesRow,
} from "components/dataVisualization/SettlementMessages/settlementMessageUtils";
import { MessageTypeFilter } from "components/dataVisualization/SettlementMessages/style";
import useSettlementMessagesData from "components/dataVisualization/SettlementMessages/useSettlementMessagesData";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import { TableFooter } from "components/dataVisualization/tableComponents/style";
import { useDataExport } from "contexts/DataExportContext";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import React, { useEffect, useMemo, useState } from "react";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  DAYS_IN_ONE_WEEK,
  addDaysToDate,
  floorToHalfHour,
  restrictDateFilterToMaxDaysRange,
} from "utils/dateHelpers";

const SettlementMessagesContainer: React.FC = () => {
  const [messageType, setMessageType] = useState<MessageTypeText>(
    MessageTypeText.AllMessages
  );
  const defaultEndTime = floorToHalfHour(new Date());
  const defaultDateFilter = useMemo(
    () =>
      new DateFilterModel(addDaysToDate(defaultEndTime, -7), defaultEndTime),
    [defaultEndTime]
  );

  const [dateFilter, setDateFilter] =
    useState<DateFilterModel>(defaultDateFilter);

  const {
    data: rawData,
    status,
    fetchData,
  } = useSettlementMessagesData(dateFilter, messageType);

  useEffect(fetchData, [fetchData]);

  const tableData = useMemo(
    () => (rawData ? mapToSettlementMessagesRow(rawData) : []),
    [rawData]
  );

  const { dispatch: dataExportCountDispatch } = useDataExport();

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getSettlementMessagesData(dateFilter, fileFormat),
      `SettlementMessages-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel
  ): void => {
    setDateFilter(restrictDateFilterToMaxDaysRange(newDateFilter, 7));
  };

  return (
    <>
      <FullWidthSection className="is-grey-section">
        <StyledDiv className="container px-6">
          <h2>Time frame</h2>
          <DateSelector
            dateFilter={dateFilter}
            handleChangeToDateSelection={handleChangeToDateSelection}
            dateTabs={[]}
            activeTab={null}
            maxRange={DAYS_IN_ONE_WEEK}
          />
        </StyledDiv>
      </FullWidthSection>
      <MessageTypeFilter data-test-id="message-type-filter">
        <label>Message Type</label>
        <Dropdown
          values={Object.values(MessageTypeText)}
          selectedValue={messageType}
          onChange={(newValue: string): void =>
            setMessageType(newValue as MessageTypeText)
          }
          ariaLabel={"Message type dropdown"}
        />
      </MessageTypeFilter>
      <TableWithErrorHandling
        data={tableData}
        dataFetchStatus={status}
        rerequestData={fetchData}
      >
        <SettlementMessagesTable data={tableData} />
      </TableWithErrorHandling>
      <TableFooter rightAligned>
        <DataExporter downloadData={downloadFile} />
      </TableFooter>
    </>
  );
};
export default SettlementMessagesContainer;
