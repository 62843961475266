import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Button from "components/components/Button/Button";
import FileTypeSelector from "components/components/FileTypeSelector/FileTypeSelector";
import ForecastFilterSelector from "components/components/ForecastFilterSelector/ForecastFilterSelector";
import GranularitySelector from "components/components/GranularitySelector/GranularitySelector";
import InfoBox from "components/components/InfoBox/InfoBox";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import {
  ModalButtonContainer,
  ModalHeader,
  ModalPadding,
  ModalSection,
  ModalSubHeader,
} from "components/components/Modal/commonStyle";
import { Granularity } from "models/Granularity";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import React, { useState } from "react";

interface Props {
  setForecastFilter: (newForecastFilter: ForecastFilterOption) => void;
  downloadData: (
    fileFormat: DataDownloadFormat,
    granularity: Granularity
  ) => void;
}

const IndicatedForecastDataExporter: React.FC<Props> = ({
  setForecastFilter,
  downloadData,
}: Props) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState(DataDownloadFormat.Csv);
  const [granularity, setGranularity] = useState(Granularity.National);

  const handleExportClick = (): void => {
    downloadData(downloadFormat, granularity);

    setIsModalActive(false);
  };

  return (
    <>
      <div data-test-id="export-data-open-button-container">
        <Button
          ariaLabel="Export"
          buttonText="export"
          iconName={faDownload}
          onClick={(): void => setIsModalActive(true)}
        />
      </div>
      <Modal
        size={ModalSize.large}
        isActive={isModalActive}
        onClose={(): void => setIsModalActive(false)}
        headingId="export-modal-header"
      >
        <ModalPadding data-test-id="export-data-modal">
          <ModalSection>
            <ModalHeader id="export-modal-header">
              Select the data you want to export
            </ModalHeader>
          </ModalSection>
          <ModalSection>
            <div>
              <ModalSubHeader>Time frame</ModalSubHeader>
              <ForecastFilterSelector
                forecastFilter={ForecastFilterOption.dayAndDayAhead}
                handleChangeToForecastFilter={setForecastFilter}
                filterTabs={[ForecastFilterOption.dayAndDayAhead]}
              />
            </div>
          </ModalSection>
          <ModalSection>
            <div>
              <ModalSubHeader>File type</ModalSubHeader>
              <FileTypeSelector
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
              />
            </div>
            <div>
              <ModalSubHeader>Granularity</ModalSubHeader>
              <GranularitySelector
                granularity={granularity}
                setGranularity={setGranularity}
              />
            </div>
          </ModalSection>
          {granularity === Granularity.Zonal && (
            <ModalSection>
              <InfoBox
                title="Zonal data is only available for some data sets."
                description="All data sets except NDF will be downloaded if you select this option."
              />
            </ModalSection>
          )}
          <hr />
          <ModalSection className="centre">
            <ModalButtonContainer data-test-id="export-data-button-container">
              <Button
                ariaLabel="Cancel"
                buttonText="cancel"
                className="cancel"
                onClick={(): void => setIsModalActive(false)}
              />
              <Button
                ariaLabel="Export"
                buttonText="export"
                onClick={handleExportClick}
              />
            </ModalButtonContainer>
          </ModalSection>
        </ModalPadding>
      </Modal>
    </>
  );
};

export default IndicatedForecastDataExporter;
