import { getSettlementBidOfferStacksJsonData } from "api/dataVisualisation/detailedSystemPrices/detailedSystemPrices";
import { BidOfferQueryIndicator } from "components/dataVisualization/DetailedSystemPrices/utils";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { SettlementBidOfferStackModel } from "models/detailedSystemPrices/settlementModels";
import { useCallback } from "react";

const useSettlementBidOfferStacksData = (
  settlementDate: string,
  settlementPeriod: number,
  stackType: BidOfferQueryIndicator
): {
  data: SettlementBidOfferStackModel[] | null;
  status: RequestStatus;
  fetchData: () => void;
  errorCode: number | null;
} => {
  const { data, status, request, errorCode } = useRequest(
    getSettlementBidOfferStacksJsonData
  );

  const fetchData = useCallback(() => {
    request(settlementDate, settlementPeriod, stackType);
  }, [request, settlementDate, settlementPeriod, stackType]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
    errorCode,
  };
};

export default useSettlementBidOfferStacksData;
