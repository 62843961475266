import { getData } from "api/dataVisualisation/client";
import { MeteringDataType } from "components/dataVisualization/indicativeDemandPeak/IndicativeDemandPeakContainer/IndicativeDemandPeakChartContainer";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import { IndicativeDemandPeakModel } from "models/indicativeDemandPeak/indicativeDemandPeak";

export const getIndicativeDemandPeakTriadDataByTriadYear = async (
  meteringData: MeteringDataType,
  triadYear: number,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<IndicativeDemandPeakModel>> => {
  const { data } = await getData(
    `/demand/peak/triad?data=${meteringData}`,
    {
      triadSeasonStartYear: triadYear,
    },
    format
  );
  return data;
};

export const getIndicativeDemandPeakTriadJsonDataByTriadYear = (
  meteringData: MeteringDataType,
  triadYear: number
): Promise<JsonDataDownloadModel<IndicativeDemandPeakModel>> =>
  getIndicativeDemandPeakTriadDataByTriadYear(
    meteringData,
    triadYear
  ) as Promise<JsonDataDownloadModel<IndicativeDemandPeakModel>>;
