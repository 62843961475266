export interface IndicatedForecastDayAhead {
  publishTime: Date;
  startTime: Date;
  settlementDate: Date;
  settlementPeriod: number;
  boundary: string;
  indicatedGeneration: number;
  indicatedDemand: number;
  indicatedMargin: number;
  indicatedImbalance: number;
}

export enum IndicatedForecastDataKeys {
  IndicatedMargin = "indicatedMargin",
  IndicatedGeneration = "indicatedGeneration",
  IndicatedImbalance = "indicatedImbalance",
  IndicatedDemand = "indicatedDemand",
}
