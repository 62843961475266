import { Datum, Serie } from "@nivo/line";
import OutageProfile from "models/remit/outageProfile";
import { chartColours } from "styles/colours";
import { sortByStartTime } from "utils/ChartDataUtils/index";

export const transformOutageProfileData = (
  outageProfileData: OutageProfile[]
): Serie[] => {
  const sortedData = sortByStartTime(outageProfileData);
  const data = sortedData.reduce(
    (prev, curr) => [
      ...prev,
      {
        x: new Date(curr.startTime),
        y: curr.capacity,
      },
    ],
    [] as Datum[]
  );
  return [
    {
      id: "availableCapacity",
      colour: chartColours.remitOutageProfile,
      data,
    },
  ];
};
