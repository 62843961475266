import React from "react";
import { getIntervalMinuteStrings, getAllHourStrings } from "utils/dateHelpers";

import { StyledFooter, StyledOption, StyledSelect, TimeDisplay } from "./style";

type ChangeHandler = (newDate: Date) => void;
type TimeValidator = (hourString: string, minString: string) => boolean;

interface Props {
  date: Date;
  handleChange: ChangeHandler;
  isValidTime: TimeValidator;
}

export const TimePicker: React.FC<Props> = ({
  date,
  handleChange,
  isValidTime,
}) => {
  const selectedHour = date.getHours().toString().padStart(2, "0");
  const selectedMin = date.getMinutes().toString().padStart(2, "0");

  const handleTimePickerChange = (
    hourString: string,
    minuteValue: string
  ): void => {
    const hourVal = parseInt(hourString, 10);
    const minVal = parseInt(minuteValue, 10);
    date.setHours(hourVal, minVal, 0, 0);
    handleChange(date);
  };

  const handleHourChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const hourString = e.target.value;
    handleTimePickerChange(hourString, selectedMin);
  };

  const handleMinuteChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const minuteString = e.target.value;
    handleTimePickerChange(selectedHour, minuteString);
  };

  return (
    <StyledFooter>
      <TimeDisplay>Time:</TimeDisplay>
      <div>
        <StyledSelect
          data-test-id="time-hour-select"
          value={selectedHour}
          onChange={handleHourChange}
          aria-label="select hour"
        >
          {getAllHourStrings()
            .filter(
              (option: string) =>
                isValidTime(option, "00") || isValidTime(option, "30")
            )
            .map((option: string) => (
              <StyledOption key={option} value={option}>
                {option}
              </StyledOption>
            ))}
        </StyledSelect>
        <StyledSelect
          data-test-id="time-minute-select"
          value={selectedMin}
          onChange={handleMinuteChange}
          aria-label="select minute"
        >
          {getIntervalMinuteStrings(30)
            .filter((option: string) => isValidTime(selectedHour, option))
            .map((option: string) => (
              <StyledOption key={option} value={option}>
                {option}
              </StyledOption>
            ))}
        </StyledSelect>
      </div>
    </StyledFooter>
  );
};
