import { authContext, irisRequest } from "utils/authUtils";

const getAccessToken = async (): Promise<string> => {
  if (!authContext) {
    throw new Error(
      "Authorisation context not initialised - is the account area feature flag set?"
    );
  }

  const account = authContext.getActiveAccount();

  if (!account) {
    throw new Error(
      "No active account - verify a user has been signed in and setActiveAccount has been called"
    );
  }

  const response = await authContext.acquireTokenSilent({
    ...irisRequest,
    account,
  });

  return response.accessToken;
};

export default getAccessToken;
// This is exported as default to enable stubbing in the Cypress component tests for IRIS AuthenticatedView
// Named exporting doesn't seem to work with Cypress stubbing
