import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import InitialDemandOutturnChartContainer from "components/dataVisualization/demandOutturn/DemandOutturnChartContainer/InitialDemandOutturnChartContainer";
import React from "react";

const DemandOutturn: React.FC = () => (
  <>
    <PageMeta title="Demand outturn" />
    <PageHeader
      title="Demand outturn"
      pageDescription={
        <div>
          <p>
            The Initial Demand Outturn measures the half-hour average MW demand
            metered by the Transmission Company based on its operational
            metering. The data is updated every 30 minutes and within 15 minutes
            of the end of the effective Settlement Period. The data is
            represented with:
          </p>
          <ul>
            <li>
              <b> Initial Demand Outturn</b> (INDO) which takes into account
              transmission losses but does not include station transformer load,
              pumped storage demand or interconnector demand
            </li>
            <li>
              <b> Initial Transmission System Demand Outturn </b>
              (ITSDO) which takes into account transmission losses, station
              transformer load, pumped storage demand and interconnector demand
            </li>
          </ul>
        </div>
      }
    />
    <FullWidthChartSection>
      <InitialDemandOutturnChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection
      documentationInformation={{
        chart: ["demand/outturn"],
      }}
    />
  </>
);

export default DemandOutturn;
