import { mapDataToCsv } from "api/dataVisualisation/client";
import {
  getRemitTableDownloadData,
  getRemitTableJsonData,
} from "api/remit/remit";
import {
  ModalSection,
  ModalSubHeader,
} from "components/components/Modal/commonStyle";
import DataExporterContainer from "components/dataVisualization/dataExporterComponents/DataExporterContainer/DataExporterContainer";
import DataExporterReadOnlyHeader from "components/dataVisualization/dataExporterComponents/dataExporterSections/DataExporterReadOnlyHeader";
import FileFormatSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/FileFormatSection";
import TimeFrameSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/TimeFrameSection";
import {
  getRemitFiltersFromUrlParams,
  mapToRemitRow,
  readableRadioButton,
} from "components/remit/remitLandingPageHelpers";
import { useDataExport } from "contexts/DataExportContext";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { DataDownloadModel } from "models/dataDownload/dataDownloadModel";
import RemitMessageDetailsModel from "models/remit/remitMessageDetailsModel";
import { RemitTableModel } from "models/remit/remitTableModel";
import React, { useState } from "react";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";

interface Props {
  searchParams: URLSearchParams;
}

const RemitLandingPageDataExporter: React.FC<Props> = ({ searchParams }) => {
  const selectedFilters = getRemitFiltersFromUrlParams(searchParams);

  const fileName = `RemitData-${selectedFilters.dateFilter.startDate.toISOString()}-${selectedFilters.dateFilter.endDate.toISOString()}`;
  const { dispatch: dataExportCountDispatch } = useDataExport();

  const getRemitDownloadData = (
    format: DataDownloadFormat
  ): Promise<DataDownloadModel<RemitMessageDetailsModel>> => {
    // CSV must be handled separately since it must download the table rather than the API response
    if (format === DataDownloadFormat.Csv) {
      return mapDataToCsv<RemitMessageDetailsModel, RemitTableModel>(
        getRemitTableJsonData(selectedFilters),
        mapToRemitRow,
        ["url", "id"]
      );
    } else {
      return getRemitTableDownloadData(selectedFilters, format);
    }
  };

  const downloadData = (downloadFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getRemitDownloadData(downloadFormat),
      fileName,
      downloadFormat,
      dataExportCountDispatch
    );
  };

  const [downloadFormat, setDownloadFormat] = useState(DataDownloadFormat.Csv);

  const handleExportClick = (): void => {
    downloadData(downloadFormat);
  };

  return (
    <DataExporterContainer onExportClick={handleExportClick}>
      {DataExporterReadOnlyHeader}
      <ModalSection>
        <TimeFrameSection
          dateFilter={selectedFilters.dateFilter}
          showTimeInput={true}
        />
      </ModalSection>
      <ModalSection>
        <div>
          <ModalSubHeader>Time frame sorted by</ModalSubHeader>
          <div>{readableRadioButton[selectedFilters.endpoint]}</div>
        </div>
      </ModalSection>
      <ModalSection>
        <div>
          <ModalSubHeader>Revisions</ModalSubHeader>
          <div>{readableRadioButton[selectedFilters.revisionHistory]}</div>
        </div>
      </ModalSection>
      <ModalSection>
        <FileFormatSection
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
        />
      </ModalSection>
    </DataExporterContainer>
  );
};
export default RemitLandingPageDataExporter;
