import Button from "components/components/Button/Button";
import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const ApplyFiltersButton = styled(Button)`
  && {
    background: transparent;
    color: ${colours.linkColor};
    border: 1px solid ${colours.linkColor};
    font-weight: ${fonts.semibold};
    letter-spacing: unset;
    padding: 0.6rem 1.9rem;
    height: 2.5rem;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  margin: 2.25rem 0 1.13rem;

  button:not(:first-child) {
    margin-left: 1.5rem;
  }
`;

export const UnsavedChangesDiv = styled.div`
  color: ${colours.linkColor};
`;

export const ErrorMessageDiv = styled.div`
  color: ${colours.elexonRed};
`;
