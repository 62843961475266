import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const StyledLink = styled.li`
  padding: 0;

  & button {
    border: none;
    background-color: transparent;
    width: 12vw;
    height: 100%;
    max-width: 300px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    flex-flow: wrap;
    justify-content: center;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    color: inherit;
    letter-spacing: 2.2px;
    font-size: ${fonts.small};
    text-transform: uppercase;

    &:hover,
    &.active {
      background-color: ${colours.elexonBlue};
      border-top: 5px solid ${colours.elexonGreen};
      border-bottom: 5px solid transparent;
      color: ${colours.white};
      cursor: pointer;
    }
  }
`;
