import Button from "components/components/Button/Button";
import styled from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PredefinedFilterContainer = styled.div`
  height: 30px;
`;

export const PredefinedFilter = styled(Button)`
  width: 75px !important;
`;

export const DayAndDayAheadFilter = styled(Button)`
  width: 162px !important;
`;
