import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import {
  Description,
  DescriptionHeader,
  Column,
  IrisProsList,
} from "components/components/Iris/LandingPageInfo/IrisHeader/style";
import { ButtonProps } from "components/components/Iris/LandingPageInfo/LandingPageInfo";
import { PageTitle, TallButton } from "components/components/Iris/commonStyle";
import VideoContainer from "components/components/VideoContainer/VideoContainer";
import React from "react";
import colours from "styles/colours";

type Props = {
  buttonProps: ButtonProps;
};

const IrisHeader: React.FC<Props> = ({
  buttonProps: { buttonText, onClick },
}) => (
  <FullWidthSection className="is-grey-section">
    <div className="container px-6">
      <PageTitle>Insights Real-Time Information Service (IRIS)</PageTitle>
      <div>
        <Column className="mt-5 columns">
          <div className="column is-one-half">
            <VideoContainer
              src="https://player.vimeo.com/video/912525748?title=0&byline=0&portrait=0"
              targetHeight="370px"
              title="Elexon Insights Solution: How to set up and use the IRIS service"
            />
          </div>
          <div className="column is-one-half mt-5">
            <IrisProsList>
              <li>
                <Icon
                  colour={colours.elexonGreen}
                  iconName={faCheckCircle}
                  size={IconSize.small}
                />
                Automated push of JSON files as they come in
              </li>
              <li>
                <Icon
                  colour={colours.elexonGreen}
                  iconName={faCheckCircle}
                  size={IconSize.small}
                />
                Minimal latency
              </li>
              <li>
                <Icon
                  colour={colours.elexonGreen}
                  iconName={faCheckCircle}
                  size={IconSize.small}
                />
                Automatic outage recovery
              </li>
              <li>
                <Icon
                  colour={colours.elexonGreen}
                  iconName={faCheckCircle}
                  size={IconSize.small}
                />
                Shared output format with API
              </li>
            </IrisProsList>
            <DescriptionHeader>
              Automatically receive data just seconds after we get it
            </DescriptionHeader>
            <Description>
              IRIS is a near real-time, free, publicly available push service
              for accessing Insights Solution data. View the publicly accessible
              archive or create an account to get access within minutes.
            </Description>
            <TallButton buttonText={buttonText} onClick={onClick} />
          </div>
        </Column>
      </div>
    </div>
  </FullWidthSection>
);

export default IrisHeader;
