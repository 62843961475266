import BalancingServicesVolumeTableModel from "components/dataVisualization/balancingMechanismTabs/BalancingServicesVolume/marketView/BalancingServicesVolumeTable/BalancingServicesVolumeTableModel";
import { BalancingServicesVolumeModel } from "models/bmuData/balancingServicesVolumeModel";
import { BmuModel } from "models/bmuData/bmuModel";
import { matchingBmUnitData } from "utils/marketViewTableUtils";

export function mapToBalancingServicesVolumeRow(
  bsvData: BalancingServicesVolumeModel[],
  bmuData: BmuModel[] | null
): BalancingServicesVolumeTableModel[] {
  return bsvData.map((row, key) => {
    const { leadParty, bmUnitType } = matchingBmUnitData(
      row.nationalGridBmUnit,
      bmuData
    );
    return {
      id: key,
      bmuId: row.bmUnit,
      bmuType: bmUnitType,
      leadParty: leadParty,
      ngc: row.nationalGridBmUnit,
      date: new Date(row.settlementDate),
      period: row.settlementPeriod,
      time: new Date(row.time),
      volume: row.bmUnitApplicableBalancingServicesVolume ?? "—",
    };
  });
}
