import { ResponsiveLine, Serie, SliceTooltipProps } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { TabulatedChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/TabulatedChartTooltip";
import PeakHighlights from "components/dataVisualization/chartComponents/PeakHighlights/PeakHighlights";
import { scaleLinear } from "d3-scale";
import { RequestStatus } from "hooks/useRequest";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import {
  indicativeDemandPeakDatasetCategories,
  TriadPeak,
} from "models/indicativePeakDemandChartData";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  commonChartProps,
  nonStackedLineChartYScale,
  chartXTimeScaleFromSeriesWithMargin,
} from "styles/chartStyles";
import { insertLayerAfterTargetLayer } from "styles/chartStyles/chartLayers";
import { defaultColumnFormatter } from "utils/ChartDataUtils";
import { hideDailyDemandDatasetOnPeakDates } from "utils/ChartDataUtils/indicativeDemandPeak/indicativeDemandPeak";
import { buildCustomShapePoint } from "utils/ChartDrawUtils/pointShapes";
import { getYValuesExtrema } from "utils/chartHelpers";
import { DateFormat } from "utils/dateHelpers";

interface Props {
  series: Serie[];
  rerequestData: () => void;
  dataFetchStatus: RequestStatus;
  triadPeaks: TriadPeak[];
  isSettlement?: boolean;
}

const IndicativeDemandPeakChart: React.FC<Props> = ({
  series,
  rerequestData,
  dataFetchStatus,
  triadPeaks,
  isSettlement,
}: Props) => {
  const currentDate = new Date();
  const isNovemberFirst =
    currentDate.getDate() === 1 && currentDate.getMonth() === 10;

  const memoisedChart = useMemo(() => {
    const config = (input: SliceTooltipProps): ChartConfiguration => ({
      datasetCategories: hideDailyDemandDatasetOnPeakDates(
        input.slice.points,
        triadPeaks
      ),
      tooltipColumns: [
        ...[
          {
            id: "demand",
            dataKey: "y",
            formatter: defaultColumnFormatter({ suffix: "MW" }),
          },
        ],
        ...(isSettlement
          ? [
              {
                id: "settlementRun",
                dataKey: "settlementRun",
                formatter: defaultColumnFormatter({}),
                title: "Settlement Run",
              },
            ]
          : []),
      ],
    });

    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    const { minYAxisValue, maxYAxisValue } = getYValuesExtrema(series);
    const ticks = scaleLinear()
      .domain([minYAxisValue, maxYAxisValue])
      .nice()
      .ticks(6);

    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        enablePoints
        pointSymbol={buildCustomShapePoint}
        pointSize={9}
        pointLabelYOffset={0}
        enableSlices="x"
        axisBottom={chartAxisBottom("Settlement Date", series, isChartLoaded)}
        axisLeft={chartAxisLeft("GB Demand (MW)", isChartLoaded, ticks)}
        gridYValues={ticks}
        layers={insertLayerAfterTargetLayer(
          "crosshair",
          (layerProps) =>
            isChartLoaded && (
              <PeakHighlights peaks={triadPeaks} layerProps={layerProps} />
            )
        )}
        sliceTooltip={(input): JSX.Element => (
          <TabulatedChartTooltip
            series={series}
            config={config(input)}
            text={{
              label: "Start Time",
            }}
            options={{
              interactionType: TooltipInteractionType.Slice,
              dateFormat: DateFormat.DateOnly,
            }}
            inputPoints={input.slice.points}
          />
        )}
        xScale={chartXTimeScaleFromSeriesWithMargin(series, {
          startMargin: 7,
          endMargin: 7,
        })}
        yScale={nonStackedLineChartYScale(series, {
          bottomMarginPercentage: 28,
          topMarginPercentage: 10,
        })}
      />
    );
  }, [dataFetchStatus, isSettlement, series, triadPeaks]);

  return (
    <ChartWithErrorHandling
      series={series}
      datasetCategories={indicativeDemandPeakDatasetCategories}
      dataFetchStatus={dataFetchStatus}
      rerequestData={rerequestData}
      emptyChartPlaceholder={
        <>
          {isNovemberFirst ? (
            <>
              <p>Today is the start of a new Triad period</p>
              <p>Data will be available from tomorrow from 00:30</p>
            </>
          ) : (
            <p>No data to show</p>
          )}
        </>
      }
    >
      {memoisedChart}
    </ChartWithErrorHandling>
  );
};

export default React.memo(IndicativeDemandPeakChart);
