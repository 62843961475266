import ResultsCount from "components/components/ResultsCount/ResultsCount";
import SortableTable from "components/components/SortableTable/SortableTable";
import { getNullPairHeaders } from "components/dataVisualization/DerivedData/utils";
import { DerivedDataPairs } from "models/derivedData/derivedData";

export interface DerivedDataTableProps<T extends DerivedDataPairs> {
  headers: Record<Exclude<keyof T, "id">, string>;
  mappedData: T[];
}

const DerivedDataTable = <T extends DerivedDataPairs>({
  headers,
  mappedData,
}: DerivedDataTableProps<T>): JSX.Element => {
  const hiddenColumns = getNullPairHeaders(mappedData);
  return (
    <>
      <ResultsCount count={mappedData ? mappedData.length : 0} />
      <SortableTable
        headers={headers}
        items={mappedData}
        hiddenColumns={hiddenColumns as Exclude<keyof T, "id">[]}
      />
    </>
  );
};

export default DerivedDataTable;
