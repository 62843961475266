import { Datum } from "@nivo/line";
import { TabulatedTooltipValue } from "models/chartConfiguration/tooltipModels";
import { SystemPricesDataKey } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import { defaultColumnFormatter } from "utils/ChartDataUtils";

export const getTooltipValueSuffixForDataset = (
  value: unknown,
  tooltipValue?: TabulatedTooltipValue
): string => {
  let suffix = "MWh";
  if (tooltipValue?.datasetId) {
    switch (tooltipValue.datasetId) {
      case SystemPricesDataKey.bsadDefaulted:
        suffix = "";
        break;
      case SystemPricesDataKey.systemSellPrice:
      case SystemPricesDataKey.sellPriceAdjustment:
      case SystemPricesDataKey.buyPriceAdjustment:
        suffix = "£/MWh";
        break;
    }
  }
  return defaultColumnFormatter({ suffix })(value);
};

const formatValue = (value: number | undefined): string | number =>
  value === 0 || !value ? 0 : value.toFixed(2);

export const createDefaultSystemPricesDatum = (
  datasetKey: string,
  lastValidDatum: Datum | undefined,
  date: Date
): Datum => {
  let y: string | number = 0;

  switch (datasetKey) {
    case SystemPricesDataKey.bsadDefaulted:
      y = lastValidDatum?.bsadDefaulted ? "True" : "False";
      break;
    case SystemPricesDataKey.buyPriceAdjustment:
      y = lastValidDatum?.buyPriceAdjustment;
      break;
    case SystemPricesDataKey.sellPriceAdjustment:
      y = lastValidDatum?.sellPriceAdjustment;
      break;
    case SystemPricesDataKey.totalAcceptedOfferVolume: {
      y = formatValue(lastValidDatum?.totalAdjustmentSellVolume);
      break;
    }
    case SystemPricesDataKey.totalAcceptedBidVolume: {
      y = formatValue(lastValidDatum?.totalAcceptedBidVolume);
      break;
    }
    case SystemPricesDataKey.totalAdjustmentSellVolume: {
      y = formatValue(lastValidDatum?.totalAdjustmentSellVolume);
      break;
    }
    case SystemPricesDataKey.totalAdjustmentBuyVolume: {
      y = formatValue(lastValidDatum?.totalAdjustmentBuyVolume);
      break;
    }
  }

  return {
    x: date,
    y,
    k: datasetKey,
  };
};
