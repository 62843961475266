import Button from "components/components/Button/Button";
import DateSelector, {
  ReadOnlyDates,
} from "components/components/DateSelector/DateSelector";
import Dropdown from "components/components/Dropdown/Dropdown";
import MultiSelectDropdown from "components/components/Dropdown/MultiSelectDropdown";
import { FilterOption } from "components/components/Dropdown/helpers";
import {
  ApplyFiltersButton,
  ButtonsDiv,
  ErrorMessageDiv,
  UnsavedChangesDiv,
} from "components/components/FiltersForm/style";
import InputBox from "components/components/InputBox/InputBox";
import RadioButtonsRow from "components/components/RadioInput/RadioButtonsRow";
import {
  ensureFiltersInValidState,
  EventType,
  MessageType,
  RemitFilterEndpoint,
  RemitFilters,
  RevisionHistory,
  TimeRangeLength,
  TimeRangeType,
  UnavailabilityType,
} from "components/components/Remit/RemitFiltersForm/remitFilters";
import {
  defaultRemitFilters,
  getRemitFiltersFromUrlParams,
  getUrlParamsFromRemitFilters,
  readableRadioButton,
} from "components/remit/remitLandingPageHelpers";
import { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { FormEvent, useMemo, useState } from "react";
import { stringArraysEqual } from "utils/arrayUtils";
import {
  addYearsToDate,
  getNumberOfDaysBetweenDates,
  MAX_DAYS_IN_ONE_MONTH,
  MAX_DAYS_IN_ONE_YEAR,
} from "utils/dateHelpers";
import { areObjectsEqual } from "utils/objectUtils";

import {
  AdditionalDropdownsDiv,
  AdditionalFiltersDiv,
  AdditionalInputsDiv,
  DateSelectorContainer,
  Divider,
  FlexRow,
  LabelAndFilter,
  StyledDiv,
  StyledForm,
  TimeframeDiv,
  TimeRangeDropdownsDiv,
} from "./style";
import useFuelTypesData from "./useFuelTypesData";
import useParticipantAndAssetData from "./useParticipantAndAssetData";

interface Props {
  searchParams: URLSearchParams;
  setSearchParams: (params: { [key: string]: string }) => void;
}

const RemitFiltersForm: React.FC<Props> = ({
  searchParams,
  setSearchParams,
}) => {
  const oneYearInFuture = addYearsToDate(new Date(), 1);

  const { assetAndParticipantIDs, assetAndParticipantStatus } =
    useParticipantAndAssetData();

  const { fuelTypes, fuelTypesStatus } = useFuelTypesData();

  const [selectedFilters, setSelectedFilters] = useState<RemitFilters>(
    getRemitFiltersFromUrlParams(searchParams)
  );
  const [activeFilters, setActiveFilters] = useState<RemitFilters>(
    getRemitFiltersFromUrlParams(searchParams)
  );

  const unsavedChanges = useMemo(
    () => !areObjectsEqual<RemitFilters>(activeFilters, selectedFilters),
    [selectedFilters, activeFilters]
  );

  const status = RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

  const onApplyFilters = (filters: RemitFilters): void => {
    const queryParams = getUrlParamsFromRemitFilters(filters);

    setSearchParams(queryParams);

    history.replaceState(
      {
        query: `?${new URLSearchParams(queryParams).toString()}`,
      },
      ""
    );
  };

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    onApplyFilters(selectedFilters);
    setActiveFilters(selectedFilters);
  };

  const handleReset = (): void => {
    if (!areObjectsEqual<RemitFilters>(defaultRemitFilters, selectedFilters)) {
      setSelectedFilters(defaultRemitFilters);
    }
  };

  const validateAndUpdateFilterState = (
    filters: RemitFilters,
    key?: string
  ): void => {
    const validatedFilters = ensureFiltersInValidState(filters, key);
    setSelectedFilters(validatedFilters);
  };

  const handleDropdownFilterChange = <T extends keyof RemitFilters>(
    newValue: string,
    oldValue: string | null,
    key: T
  ): void => {
    if (newValue === oldValue) {
      return;
    }

    validateAndUpdateFilterState(
      {
        ...selectedFilters,
        [key]: newValue,
      },
      key
    );
  };

  const handleDateFilterChange = (newValue: DateFilterModel): void => {
    validateAndUpdateFilterState({
      ...selectedFilters,
      dateFilter: newValue,
    });
  };

  const handleMultiSelectChange = <T extends keyof RemitFilters>(
    newFilterOptionValue: FilterOption[],
    oldValues: string[],
    key: T
  ): void => {
    const newValues = newFilterOptionValue.map((value) => value.label);

    if (stringArraysEqual(newValues, oldValues)) {
      return;
    }

    validateAndUpdateFilterState({
      ...selectedFilters,
      [key]: newValues,
    });
  };

  const participantOrAssetIDKey = (newValue: string): keyof RemitFilters => {
    return assetAndParticipantIDs.assetIDs.includes(newValue)
      ? "assetID"
      : "participantID";
  };

  const areDatePickersReadOnly =
    selectedFilters.timeRangeType !== TimeRangeType.Custom
      ? ReadOnlyDates.Both
      : ReadOnlyDates.None;

  const isTimeRangeLengthDisabled =
    selectedFilters.timeRangeType === TimeRangeType.Custom;

  const validTimeRangeTypeSelections =
    selectedFilters.endpoint === RemitFilterEndpoint.Event
      ? Object.values(TimeRangeType)
      : [TimeRangeType.Previous, TimeRangeType.Custom];

  const isParticipantOrAssetIDSelected =
    selectedFilters.assetID || selectedFilters.participantID;

  const maxDaysBasedOnFilters = isParticipantOrAssetIDSelected
    ? MAX_DAYS_IN_ONE_YEAR
    : MAX_DAYS_IN_ONE_MONTH;

  const invalidDateRangeSelected =
    getNumberOfDaysBetweenDates(
      selectedFilters.dateFilter.startDate,
      selectedFilters.dateFilter.endDate
    ) > maxDaysBasedOnFilters;

  interface RadioButton {
    inputId: string;
    inlineLabelContent: string;
  }

  const radioButton = (
    button: RemitFilterEndpoint | RevisionHistory
  ): RadioButton => {
    return {
      inputId: button,
      inlineLabelContent: readableRadioButton[button],
    };
  };

  return (
    <StyledDiv className="container px-6">
      <StyledForm onSubmit={handleSubmit} noValidate aria-live="polite">
        <h3>Timeframe filters</h3>
        <TimeframeDiv>
          <RadioButtonsRow
            radioButtons={[
              radioButton(RemitFilterEndpoint.Publish),
              radioButton(RemitFilterEndpoint.Event),
            ]}
            selected={selectedFilters.endpoint}
            setSelected={(newValue: string): void => {
              handleDropdownFilterChange(
                newValue,
                selectedFilters.endpoint,
                "endpoint"
              );
            }}
          />
          <FlexRow>
            <TimeRangeDropdownsDiv>
              <div data-test-id="time-range-filter">
                <Dropdown
                  values={validTimeRangeTypeSelections}
                  selectedValue={selectedFilters.timeRangeType}
                  onChange={(newValue: string): void => {
                    handleDropdownFilterChange(
                      newValue,
                      selectedFilters.timeRangeType,
                      "timeRangeType"
                    );
                  }}
                  ariaLabel={"Time range type dropdown"}
                  status={status}
                />
              </div>
              <div data-test-id="time-length-filter">
                <Dropdown
                  values={Object.values(TimeRangeLength)}
                  selectedValue={selectedFilters.timeRangeLength}
                  onChange={(newValue: string): void =>
                    handleDropdownFilterChange(
                      newValue,
                      selectedFilters.timeRangeLength,
                      "timeRangeLength"
                    )
                  }
                  status={status}
                  ariaLabel={"Time range length dropdown"}
                  isDisabled={isTimeRangeLengthDisabled}
                />
              </div>
            </TimeRangeDropdownsDiv>
            <DateSelectorContainer>
              <DateSelector
                dateFilter={selectedFilters.dateFilter}
                handleChangeToDateSelection={handleDateFilterChange}
                dateTabs={[DateSelectorTabs.Date]}
                activeTab={DateSelectorTabs.Date}
                readOnlyDates={areDatePickersReadOnly}
                maximumDate={oneYearInFuture}
                maxRange={MAX_DAYS_IN_ONE_YEAR}
              />
            </DateSelectorContainer>
            {invalidDateRangeSelected && (
              <ErrorMessageDiv>
                Choose a Participant or Asset ID, or restrict search to at most
                1 month.
              </ErrorMessageDiv>
            )}
          </FlexRow>
        </TimeframeDiv>
        <h3>Additional filters</h3>
        <RadioButtonsRow
          radioButtons={[
            radioButton(RevisionHistory.Include),
            radioButton(RevisionHistory.LatestOnly),
          ]}
          selected={selectedFilters.revisionHistory}
          setSelected={(newValue: string): void =>
            handleDropdownFilterChange(
              newValue,
              selectedFilters.revisionHistory,
              "revisionHistory"
            )
          }
        />
        <AdditionalFiltersDiv>
          <AdditionalDropdownsDiv>
            <LabelAndFilter data-test-id="message-type-filter">
              <label>Message Type</label>
              <MultiSelectDropdown
                values={Object.values(MessageType)}
                selectedValues={selectedFilters.messageType}
                onChange={(newValue: FilterOption[]): void => {
                  handleMultiSelectChange(
                    newValue,
                    selectedFilters.messageType,
                    "messageType"
                  );
                }}
                status={status}
              />
            </LabelAndFilter>
            <LabelAndFilter data-test-id="unavailability-type-filter">
              <label>Unavailability Type</label>
              <MultiSelectDropdown
                values={Object.values(UnavailabilityType)}
                selectedValues={selectedFilters.unavailabilityType}
                onChange={(newValue: FilterOption[]): void =>
                  handleMultiSelectChange(
                    newValue,
                    selectedFilters.unavailabilityType,
                    "unavailabilityType"
                  )
                }
                status={status}
              />
            </LabelAndFilter>
            <LabelAndFilter data-test-id="event-type-filter">
              <label>Event Type</label>
              <MultiSelectDropdown
                values={Object.values(EventType)}
                selectedValues={selectedFilters.eventType}
                onChange={(newValue: FilterOption[]): void =>
                  handleMultiSelectChange(
                    newValue,
                    selectedFilters.eventType,
                    "eventType"
                  )
                }
                status={status}
              />
            </LabelAndFilter>
            <LabelAndFilter data-test-id="fuel-type-filter">
              <label>Fuel Type</label>
              <MultiSelectDropdown
                values={fuelTypes ?? []}
                selectedValues={selectedFilters.fuelType}
                onChange={(newValue: FilterOption[]): void =>
                  handleMultiSelectChange(
                    newValue,
                    selectedFilters.fuelType,
                    "fuelType"
                  )
                }
                status={fuelTypesStatus}
                disableSearchBar={false}
              />
            </LabelAndFilter>
          </AdditionalDropdownsDiv>
          <Divider />
          <AdditionalInputsDiv>
            <LabelAndFilter data-test-id="participant-ID-filter">
              <label>Participant ID or Asset ID</label>
              <Dropdown
                values={assetAndParticipantIDs.assetIDs.concat(
                  assetAndParticipantIDs.participantIDs
                )}
                selectedValue={
                  selectedFilters.participantID ?? selectedFilters.assetID
                }
                onChange={(newValue: string): void =>
                  handleDropdownFilterChange(
                    newValue,
                    selectedFilters.participantID ?? selectedFilters.assetID,
                    participantOrAssetIDKey(newValue)
                  )
                }
                ariaLabel={"Participant ID or Asset ID dropdown"}
                status={assetAndParticipantStatus}
                placeholder={"Input or search"}
              />
            </LabelAndFilter>
            <LabelAndFilter data-test-id="message-ID-filter">
              <label>Message Group (MRID)</label>
              <InputBox
                selectedValue={selectedFilters.messageID ?? ""}
                onChange={(newValue: string): void =>
                  handleDropdownFilterChange(
                    newValue,
                    selectedFilters.messageID,
                    "messageID"
                  )
                }
                placeholder={"Input Message Group"}
              />
            </LabelAndFilter>
          </AdditionalInputsDiv>
        </AdditionalFiltersDiv>
        <ButtonsDiv>
          <ApplyFiltersButton
            buttonText="Apply Filters"
            type="submit"
            disabled={invalidDateRangeSelected}
          />
          <Button
            className="link-inline"
            buttonText="Reset all filters"
            type="reset"
            onClick={handleReset}
          />
        </ButtonsDiv>
        {unsavedChanges && (
          <UnsavedChangesDiv>
            There are unsaved changes in your filters. Remember to click
            &quot;apply filters&quot; to see accurate results in the table
            below.
          </UnsavedChangesDiv>
        )}
      </StyledForm>
    </StyledDiv>
  );
};

export default RemitFiltersForm;
