import ResultsCount from "components/components/ResultsCount/ResultsCount";
import { StyledBoolean } from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
  ColumnHeader,
  WithoutId,
} from "components/components/SortableTable/SortableTable";
import { getCellRender } from "components/dataVisualization/DetailedSystemPrices/utils";
import { SettlementBidOfferTableRowModel } from "models/detailedSystemPrices/settlementModels";
import React from "react";

export interface DetailedSystemPricesTableProps {
  data: SettlementBidOfferTableRowModel[];
  DetailedSystemPricesTableHeaders: Record<
    keyof WithoutId<SettlementBidOfferTableRowModel>,
    string | ColumnHeader
  >;
}

const DetailedSystemPricesTable = ({
  data,
  DetailedSystemPricesTableHeaders,
}: DetailedSystemPricesTableProps): JSX.Element => {
  return (
    <>
      <ResultsCount count={data ? data.length : 0} />
      <SortableTable
        headers={DetailedSystemPricesTableHeaders}
        items={data}
        renderFns={{
          soFlag: ({ soFlag }): TableCellRender => ({
            content: StyledBoolean(soFlag),
          }),
          cadlFlag: ({ cadlFlag }): TableCellRender => ({
            content: StyledBoolean(cadlFlag),
          }),
          storProviderFlag: ({ storProviderFlag }): TableCellRender => ({
            content: StyledBoolean(storProviderFlag),
          }),
          repricedIndicator: ({ repricedIndicator }): TableCellRender => ({
            content: StyledBoolean(repricedIndicator),
          }),
          nivAdjustedVolume: (row): TableCellRender =>
            getCellRender(row, "nivAdjustedVolume"),
          parAdjustedVolume: (row): TableCellRender =>
            getCellRender(row, "parAdjustedVolume"),
          finalPrice: (row): TableCellRender =>
            getCellRender(row, "finalPrice"),
          transmissionLossMultiplier: (row): TableCellRender =>
            getCellRender(row, "transmissionLossMultiplier"),
          tlmAdjustedVolume: (row): TableCellRender =>
            getCellRender(row, "tlmAdjustedVolume"),
          tlmAdjustedCost: (row): TableCellRender =>
            getCellRender(row, "tlmAdjustedCost"),
        }}
        totals={{
          columnsWithTotals: ["tlmAdjustedVolume", "tlmAdjustedCost"],
          totalsLabelLocation: "transmissionLossMultiplier",
        }}
      />
    </>
  );
};

export default DetailedSystemPricesTable;
