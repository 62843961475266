import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
} from "react";

// Set to true to add borders to tooltip containers to show how offsets are used to keep the tooltip on screen
const ENABLE_TOOLTIP_DEBUG = false;

/**
 * TooltipInteractionType should match the type of Nivo chart interaction.
 * This is used to set the tooltip offset to standardise its position.
 */
export enum TooltipInteractionType {
  /** Used when the chart is wrapped in a `ControlledInformationTooltip` component */
  Controlled,
  /** Used when the chart has `enableSlices` and `sliceTooltip` properties */
  Slice,
  /** Used when the chart has `useMesh` and `tooltip` properties */
  Voronoi,
  /** Used when the chart is showing the tooltip via the `RectangleSliceProvider` component */
  RectangleSlice,
}

interface Props {
  pointIsInLeftHalf: boolean;
  interactionType: TooltipInteractionType;
}

const getTooltipContainerOffsets = (
  div: HTMLDivElement,
  interactionType: TooltipInteractionType
): [number, number] => {
  let xOffset = 0;
  let yOffset = 0;

  // top goes into negative off the top of the screen
  // bottom is greater than window.innerHeight when off the bottom of the screen
  // height and width of the container are used to provide relatively equivalent offsets from the centered tooltips
  const { top, bottom, height, width } = div.getBoundingClientRect();

  switch (interactionType) {
    case TooltipInteractionType.Controlled: {
      // controlled tooltip is initially centered on the mouse, requires top, bottom and side fixes
      xOffset = width * 0.8;

      if (top < 0) {
        yOffset = -top;
      } else if (bottom > window.innerHeight) {
        yOffset = -(bottom - window.innerHeight);
      }

      break;
    }
    case TooltipInteractionType.Voronoi: {
      // voronoi tooltip is initially centered above the mouse, requires top, bottom and side fixes
      xOffset = width * 0.6;

      // as the tooltip is above the mouse, we need to use (height / 2) to correct the checks and re-align in the else case
      if (top + height / 2 < 0) {
        yOffset = -top;
      } else if (bottom + height / 2 > window.innerHeight) {
        yOffset = -(bottom - window.innerHeight);
      } else {
        yOffset = height / 2;
      }

      break;
    }
    case TooltipInteractionType.Slice: {
      // slice tooltip is initially centered beside the mouse, only requires top and bottom fixes
      if (top < 0) {
        yOffset = -top;
      } else if (bottom > window.innerHeight) {
        yOffset = -(bottom - window.innerHeight);
      }

      break;
    }
    default:
      break;
  }

  return [xOffset, yOffset];
};

export const ChartTooltipPositionWrapper: React.FC<
  PropsWithChildren<Props>
> = ({ pointIsInLeftHalf, children, interactionType }) => {
  const [offsets, setOffsets] = useState({ x: 0, y: 0 });
  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 });
  const outerTooltipContainer = useRef<HTMLDivElement>(null);

  const onMouseMove = useCallback(
    (e: MouseEvent) => setMouseCoords({ x: e.clientX, y: e.clientY }),
    []
  );

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);

    return (): void => document.removeEventListener("mousemove", onMouseMove);
  }, [onMouseMove]);

  useEffect(() => {
    const [x, y] = getTooltipContainerOffsets(
      outerTooltipContainer.current!,
      interactionType
    );
    setOffsets({ x, y });
  }, [mouseCoords, outerTooltipContainer, interactionType]);

  return (
    <div
      ref={outerTooltipContainer}
      style={{
        border: ENABLE_TOOLTIP_DEBUG ? "2px solid red" : undefined,
      }}
    >
      <div
        style={{
          transform: pointIsInLeftHalf
            ? `translate(${offsets.x}px, ${offsets.y}px)`
            : `translate(-${offsets.x}px, ${offsets.y}px)`,
          border: ENABLE_TOOLTIP_DEBUG ? "2px solid blue" : undefined,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ChartTooltipPositionWrapper;
