import { DemandControlInstructionsModel } from "models/demandControlInstructions/demandControlInstructionsModel";
import { DemandControlInstructionsTableModel } from "models/demandControlInstructions/demandControlInstructionsTableModel";

export const mapToDemandControlInstructionsRow = (
  demandControlInstructionsData: DemandControlInstructionsModel[]
): DemandControlInstructionsTableModel[] => {
  return demandControlInstructionsData.map((row) => {
    return {
      id: row.demandControlId,
      dcId: row.demandControlId,
      affectedDso: row.affectedDso,
      instructionSequence: row.instructionSequence,
      demandControlEventFlag: row.demandControlEventFlag,
      timeFrom: new Date(row.timeFrom),
      timeTo: new Date(row.timeTo),
      volume: row.volume,
      systemManagementActionFlag: row.systemManagementActionFlag,
    };
  });
};
