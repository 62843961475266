import { getAllRemitFuelTypes } from "api/dataVisualisation/reference/reference";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { useEffect } from "react";

const useFuelTypesData = (): {
  fuelTypes: string[] | null;
  fuelTypesStatus: RequestStatus;
} => {
  const {
    data: fuelTypes,
    status: fuelTypesStatus,
    request: fetchFuelTypesData,
  } = useRequest(getAllRemitFuelTypes);

  useEffect(() => {
    fetchFuelTypesData();
  }, [fetchFuelTypesData]);

  return {
    fuelTypes,
    fuelTypesStatus,
  };
};

export default useFuelTypesData;
