import { useCustomerFeedbackModalContext } from "contexts/CustomerFeedbackModalContext";
import React from "react";

import { footerLinks } from "./footerLinks";
import {
  Copyright,
  FooterContainer,
  FooterLink,
  FooterLinksContainer,
  StyledFooter,
} from "./style";

const Footer: React.FC = () => {
  const { open: openCustomerFeedbackModal } = useCustomerFeedbackModalContext();
  const currentYear = new Date().getFullYear();
  return (
    <StyledFooter className="py-6" data-test-id="footer">
      <FooterContainer className="container px-6">
        <FooterLinksContainer>
          {footerLinks.map((footerLink) => (
            <FooterLink
              key={footerLink.url + footerLink.text}
              href={footerLink.url}
              aria-label={footerLink.text}
              target={"_blank"}
            >
              {footerLink.text}
            </FooterLink>
          ))}
          <FooterLink
            onClick={openCustomerFeedbackModal}
            aria-label="Contact us"
          >
            Contact us
          </FooterLink>
        </FooterLinksContainer>
        <Copyright>Copyright &copy; {currentYear} Elexon </Copyright>
      </FooterContainer>
    </StyledFooter>
  );
};

export default Footer;
