import CategoryFilterList from "components/dataVisualization/dataPageComponents/CategoryFilterList/CategoryFilterList";
import ExitCross from "components/dataVisualization/dataPageComponents/ExitCross/ExitCross";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import React from "react";

import { Drawer } from "./style";

interface Props {
  highlightedDataset?: string;
  sendHighlightedDataset?: (newSelectedDataset: string) => void;
  setMatchingDatasetsVisibility: (
    visibility: boolean,
    dataset?: ChartDatasetModel,
    categoryTitle?: string
  ) => void;
  toggleChartFilterDrawer: () => void;
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
}

const ChartFilterDrawer: React.FC<Props> = ({
  highlightedDataset,
  sendHighlightedDataset,
  setMatchingDatasetsVisibility,
  toggleChartFilterDrawer,
  datasetCategories,
}: Props) => {
  const toggleSingleDatasetVisibility = (
    selectedDataset: ChartDatasetModel
  ): void => {
    setMatchingDatasetsVisibility(!!selectedDataset.isHidden, selectedDataset);
  };

  return (
    <Drawer data-test-id="chart-filter-drawer">
      <ExitCross onClick={toggleChartFilterDrawer} focusOnMount />
      {datasetCategories.map((datasetCategory) => (
        <CategoryFilterList
          key={datasetCategory.id}
          highlightedDataset={highlightedDataset}
          sendHighlightedDataset={sendHighlightedDataset}
          toggleSingleDatasetVisibility={toggleSingleDatasetVisibility}
          setAllDatasetVisibilities={(visible: boolean): void =>
            setMatchingDatasetsVisibility(
              visible,
              undefined,
              datasetCategory.id
            )
          }
          datasetCategory={datasetCategory}
        />
      ))}
    </Drawer>
  );
};

export default ChartFilterDrawer;
