import { getAllBmUnits } from "api/dataVisualisation/reference/reference";
import {
  BalancingMechanismTabName,
  BalancingMechanismTabNameArray,
} from "components/components/Tabs/Tabs/Tabs";
import useBalancingServicesVolumeData from "components/dataVisualization/balancingMechanismTabs/BalancingServicesVolume/bmuView/useBalancingServicesVolumeData";
import useBidOfferData, {
  BidOfferTabData,
} from "components/dataVisualization/balancingMechanismTabs/BidOffer/bmuView/useBidOfferData";
import useDynamicData, {
  DynamicTabData,
} from "components/dataVisualization/balancingMechanismTabs/Dynamic/bmuView/useDynamicData";
import usePhysicalData, {
  PhysicalTabData,
} from "components/dataVisualization/balancingMechanismTabs/Physical/bmuView/usePhysicalData";
import useFetchDataIfActiveTabAndRequestHasChanged from "hooks/useFetchDataIfRequestHasChanged";
import useRequest from "hooks/useRequest";
import { BalancingServicesVolumeModel } from "models/bmuData/balancingServicesVolumeModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { RequestDetails } from "models/requestDetails";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  floorToHalfHour,
  addDaysToDate,
  getNumberOfHoursBetweenDates,
  HOURS_IN_ONE_DAY,
  MAX_HOURS_IN_ONE_DAY,
  startOfSettlementDay,
  addMinsToDate,
} from "utils/dateHelpers";
import { BmuOption, mapModelsToOptions } from "utils/fuzzyBmuDataHelpers";

const MAX_DAYS_TO_SHOW_ON_CHART = 1;

export interface BmuViewContextType {
  selectedBmu: BmuOption | null;
  setSelectedBmu: (value: BmuOption | null) => void;
  dateFilter: DateFilterModel | null;
  setDateFilter: (value: DateFilterModel) => void;

  physicalDetails: RequestDetails<PhysicalTabData>;
  dynamicDetails: RequestDetails<DynamicTabData>;
  bidOfferDetails: RequestDetails<BidOfferTabData>;
  balancingServicesVolumeDetails: RequestDetails<
    BalancingServicesVolumeModel[]
  >;
  activeTabTitle: BalancingMechanismTabName;
  setActiveTabTitle: React.Dispatch<
    React.SetStateAction<BalancingMechanismTabName>
  >;
}

export const BmuViewContext = React.createContext<
  BmuViewContextType | undefined
>(undefined);

export const BmuViewContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [selectedBmu, setSelectedBmu] = useState<BmuOption | null>(null);
  const [dateFilter, setDateFilter] = useState<DateFilterModel | null>(null);
  const elexonBmuId = selectedBmu?.elexonBmuId ?? null;
  const [searchParams, setSearchParams] = useSearchParams();
  const [urlParams] = useState({
    bmuId: searchParams.get("bmuId"),
    activeTab: searchParams.get("activeTab"),
    startDate: searchParams.get("startDate"),
    endDate: searchParams.get("endDate"),
  });
  const [activeTabTitle, setActiveTabTitle] =
    useState<BalancingMechanismTabName>(
      urlParams && urlParams.activeTab
        ? (urlParams.activeTab as BalancingMechanismTabName)
        : "Physical"
    );

  const physicalDetails = usePhysicalData(dateFilter, elexonBmuId);
  const dynamicDetails = useDynamicData(dateFilter, elexonBmuId);
  const bidOfferDetails = useBidOfferData(dateFilter, elexonBmuId);
  const balancingServicesVolumeDetails = useBalancingServicesVolumeData(
    dateFilter,
    elexonBmuId ?? undefined
  );

  // Default to showing all the data that we expect to have for the current day
  const defaultDateFilter = useMemo(
    () =>
      new DateFilterModel(
        startOfSettlementDay(),
        // We receive data within 5 minutes after gate closure, which is an hour before the start of
        // the settlement period. So this is the end of the latest settlement period we should have data for.
        addMinsToDate(floorToHalfHour(addMinsToDate(new Date(), -5)), 90)
      ),
    []
  );

  useFetchDataIfActiveTabAndRequestHasChanged(
    activeTabTitle === "Physical",
    physicalDetails.request
  );

  useFetchDataIfActiveTabAndRequestHasChanged(
    activeTabTitle === "Dynamic",
    dynamicDetails.request
  );

  useFetchDataIfActiveTabAndRequestHasChanged(
    activeTabTitle === "Bid Offer",
    bidOfferDetails.request
  );

  useFetchDataIfActiveTabAndRequestHasChanged(
    activeTabTitle === "Balancing Services Volume",
    balancingServicesVolumeDetails.request
  );

  useEffect((): void => {
    setDateFilter(defaultDateFilter);
  }, [defaultDateFilter, setDateFilter]);

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel
  ): void => {
    const hoursBetweenDates = getNumberOfHoursBetweenDates(
      newDateFilter.startDate,
      newDateFilter.endDate
    );

    const endDate =
      hoursBetweenDates > MAX_DAYS_TO_SHOW_ON_CHART * HOURS_IN_ONE_DAY
        ? addDaysToDate(newDateFilter.startDate, MAX_DAYS_TO_SHOW_ON_CHART)
        : newDateFilter.endDate;

    setDateFilter(new DateFilterModel(newDateFilter.startDate, endDate));
  };

  useEffect(() => {
    if (selectedBmu !== null) {
      const params = {
        ...(selectedBmu.elexonBmuId && { bmuId: selectedBmu.elexonBmuId }),
        ...(activeTabTitle && { activeTab: activeTabTitle }),
        ...(dateFilter?.startDate && {
          startDate: dateFilter?.startDate?.toISOString(),
          endDate: dateFilter?.endDate?.toISOString(),
        }),
      };

      setSearchParams(params);
    }
  }, [selectedBmu, dateFilter, setSearchParams, activeTabTitle]);

  const { data: allBmus, request: fetchData } = useRequest(getAllBmUnits);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const allOptions = useMemo(() => mapModelsToOptions(allBmus), [allBmus]);

  useEffect(() => {
    if (urlParams.bmuId !== null) {
      const bmuFromQueryParam = allOptions.find(
        (bmuOption) => bmuOption.elexonBmuId === urlParams.bmuId
      );

      if (bmuFromQueryParam) {
        setSelectedBmu(bmuFromQueryParam);
      }
    }

    if (urlParams.startDate !== null && urlParams.endDate !== null) {
      const startDate = new Date(urlParams.startDate);
      const endDate = new Date(urlParams.endDate);

      // TODO 133783: Update the validation of BMU view URL parameters
      if (
        startDate.getTime() > endDate.getTime() ||
        getNumberOfHoursBetweenDates(startDate, endDate) > MAX_HOURS_IN_ONE_DAY
      ) {
        setDateFilter(defaultDateFilter);
      } else {
        setDateFilter(new DateFilterModel(startDate, endDate));
      }
    }

    const activeTabParam = urlParams.activeTab;
    if (
      activeTabParam &&
      Object.values(BalancingMechanismTabNameArray).includes(
        activeTabParam as BalancingMechanismTabName
      )
    ) {
      setActiveTabTitle(activeTabParam as BalancingMechanismTabName);
    }
  }, [
    allOptions,
    urlParams.bmuId,
    urlParams.startDate,
    urlParams.endDate,
    urlParams.activeTab,
    setActiveTabTitle,
    defaultDateFilter,
  ]);

  return (
    <BmuViewContext.Provider
      value={{
        selectedBmu,
        setSelectedBmu,
        dateFilter,
        setDateFilter: handleChangeToDateSelection,
        physicalDetails,
        dynamicDetails,
        bidOfferDetails,
        balancingServicesVolumeDetails,
        activeTabTitle,
        setActiveTabTitle,
      }}
    >
      {children}
    </BmuViewContext.Provider>
  );
};

export const useBmuViewContext = (): BmuViewContextType => {
  const context = React.useContext(BmuViewContext);

  if (context === undefined) {
    throw new Error(
      "useBmuViewContext must be used within BmuViewContext's Provider"
    );
  }

  return context;
};
