import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import Im, { fromJS } from "immutable";
import { Endpoint } from "models/apiDocumentation/apiDocumentationModels";
import React, { ReactElement } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import {
  GetComponentType as ComponentFetcher,
  OperationContainerProps,
  Plugin,
} from "types/apiDocs";

import { CustomSwaggerContainer } from "./style";

interface ComponentProps {
  getComponent: ComponentFetcher;
}

interface LayoutProps extends ComponentProps {
  path: string;
}

const SingleEndpointLayout: React.FC<LayoutProps> = ({
  path,
  getComponent,
}) => {
  // This fetches the component defined here: https://github.com/swagger-api/swagger-ui/blob/master/src/core/containers/OperationContainer.jsx
  // which currently uses the deprecated method `UNSAFE_componentWillReceiveProps`.
  // This will result in console warnings on this page until this is fixed in swagger-ui.
  // See open issue: https://github.com/swagger-api/swagger-ui/issues/5729
  const OperationContainer: React.FC<OperationContainerProps> = getComponent(
    "OperationContainer",
    true
  );
  const SwaggerSvgAssets = getComponent("SvgAssets");

  return (
    <>
      <SwaggerSvgAssets />
      <OperationContainer
        specPath={Im.List(["paths", path, "get"])}
        op={fromJS({ path, method: "get" })}
        path={path}
        method="get"
      />
    </>
  );
};

interface PluginProps {
  endpointData: Endpoint;
}

const SwaggerSingleRowPlugin = ({ endpointData }: PluginProps): Plugin => ({
  components: {
    [endpointData.url]: ({ getComponent }: ComponentProps): ReactElement => (
      <SingleEndpointLayout
        path={endpointData.url}
        getComponent={getComponent}
      />
    ),
  },
});

interface SwaggerSingleRowProps {
  swaggerSpec: string;
  endpointData: Endpoint | null;
}

const SwaggerSingleRow: React.FC<SwaggerSingleRowProps> = ({
  swaggerSpec,
  endpointData,
}) => {
  const content =
    endpointData === null ? (
      <LoadingSpinner isLoading />
    ) : (
      <SwaggerUI
        key={endpointData.url}
        spec={swaggerSpec}
        url=""
        plugins={[SwaggerSingleRowPlugin({ endpointData })]}
        layout={endpointData.url}
      />
    );

  return (
    <CustomSwaggerContainer className="swagger-ui">
      {content}
    </CustomSwaggerContainer>
  );
};

export default SwaggerSingleRow;
