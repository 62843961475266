import { Serie } from "@nivo/line";
import {
  StackedChartDataModel,
  transformDataToSeries,
} from "components/dataVisualization/chartComponents/StackedLineChart/utils";
import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";
import { OutturnSummaryModel } from "models/outturnSummaryModel";

const mapToStackedChartModel = (
  rawData: OutturnSummaryModel[]
): StackedChartDataModel[] =>
  rawData.map((summary) => ({
    date: new Date(summary.startTime),
    datapoints: summary.data.map((datum) => ({
      datasetType: datum.fuelType,
      value: datum.generation,
    })),
  }));

export const transformOutturnSummaryModelData = (
  rawData: OutturnSummaryModel[],
  datasets: ChartDatasetModel[]
): Serie[] => transformDataToSeries(mapToStackedChartModel(rawData), datasets);
