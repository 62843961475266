import TileChild from "components/components/TileChild/TileChild";
import TileParent from "components/components/TileParent/TileParent";
import React from "react";
import { DynamicTileTableModel } from "utils/TabularDataUtils/DynamicDataTileUtils";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

import { DataRow, SubtitleRow, TileContent, TileDataTable } from "./style";

interface DataRowsProps {
  table: DynamicTileTableModel;
}

interface SubsectionProps {
  tables?: DynamicTileTableModel[];
}

interface DynamicDataTileProps extends SubsectionProps {
  title: string;
  dataTestId: string;
}

const DataRows: React.FC<DataRowsProps> = ({ table }) => {
  if (table.data === null || table.data.length === 0) {
    return (
      <DataRow>
        <td>No data available for this BMU in this timeframe.</td>
      </DataRow>
    );
  }
  return (
    <>
      {table.data?.map((row) => (
        <DataRow key={row.time.toUTCString()}>
          <td>
            {formatDateTimeString(row.time, DateFormat.DateTime)} UTC (SP{" "}
            {row.settlementPeriod})
          </td>
          <td>{row.value}</td>
          <td>{row.unit.value(row.value)}</td>
        </DataRow>
      ))}
    </>
  );
};

const Subsection: React.FC<SubsectionProps> = ({ tables }) => {
  if (!tables || tables.flatMap((row) => row.data).length === 0) {
    return <>No data available for this BMU in this timeframe.</>;
  }

  return (
    <TileDataTable>
      {tables?.map((table) => (
        <tbody key={table.subtitle}>
          <SubtitleRow>
            <td>{table.subtitle}</td>
          </SubtitleRow>
          <DataRows table={table} />
        </tbody>
      ))}
    </TileDataTable>
  );
};

const DynamicDataTile: React.FC<DynamicDataTileProps> = ({
  title,
  tables,
  dataTestId,
}) => (
  <TileParent>
    <TileChild isMiniTile>
      <TileContent data-test-id={dataTestId}>
        <h3>{title}</h3>
        <Subsection tables={tables} />
      </TileContent>
    </TileChild>
  </TileParent>
);

export default DynamicDataTile;
