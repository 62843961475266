import { Serie } from "@nivo/line";
import {
  StackedChartDataModel,
  transformDataToSeries,
} from "components/dataVisualization/chartComponents/StackedLineChart/utils";
import { AgptChartModel } from "models/actualGenerationModel/actualGeneration";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import { psrTypeColours } from "styles/colours";

const productionTypes: ChartDatasetModel[] = [
  {
    displayName: "Biomass",
    dataKey: "Biomass",
    colour: psrTypeColours.biomass,
  },
  {
    displayName: "Fossil Gas",
    dataKey: "Fossil Gas",
    colour: psrTypeColours.fossilGas,
  },
  {
    displayName: "Fossil Hard Coal",
    dataKey: "Fossil Hard coal",
    colour: psrTypeColours.fossilCoal,
  },
  {
    displayName: "Fossil Oil",
    dataKey: "Fossil Oil",
    colour: psrTypeColours.fossilOil,
  },
  {
    displayName: "Hydro Pumped Storage",
    dataKey: "Hydro Pumped Storage",
    colour: psrTypeColours.hydroPumpedStorage,
  },
  {
    displayName: "Hydro Run-of-river and Poundage",
    dataKey: "Hydro Run-of-river and poundage",
    colour: psrTypeColours.hydroRunOfRiver,
  },
  {
    displayName: "Nuclear",
    dataKey: "Nuclear",
    colour: psrTypeColours.nuclear,
  },
  {
    displayName: "Other",
    dataKey: "Other",
    colour: psrTypeColours.other,
  },
];

const solarTypes: ChartDatasetModel[] = [
  {
    displayName: "Solar",
    dataKey: "Solar",
    colour: psrTypeColours.solar,
  },
];

const windTypes: ChartDatasetModel[] = [
  {
    displayName: "Wind Offshore",
    dataKey: "Wind Offshore",
    colour: psrTypeColours.windOffshore,
  },
  {
    displayName: "Wind Onshore",
    dataKey: "Wind Onshore",
    colour: psrTypeColours.windOnshore,
  },
];

export const initialDatasetCategories: DatasetCategory<ChartDatasetModel>[] = [
  {
    datasets: windTypes,
    id: "wind",
    title: "Wind generation",
    showTitle: true,
  },
  {
    datasets: solarTypes,
    id: "solar",
    title: "Solar generation",
    showTitle: true,
  },
  {
    datasets: productionTypes,
    id: "production",
    title: "Production",
    showTitle: true,
  },
];

const mapToStackedChartModel = (
  rawData: AgptChartModel[]
): StackedChartDataModel[] =>
  rawData.map((summary) => ({
    date: new Date(summary.startTime),
    datapoints: summary.data.map((datum) => ({
      datasetType: datum.psrType,
      value: datum.quantity,
    })),
  }));

export const transformActualGenerationPerTypeData = (
  rawData: AgptChartModel[],
  datasets: ChartDatasetModel[]
): Serie[] => {
  return transformDataToSeries(mapToStackedChartModel(rawData), datasets);
};
