import React from "react";

import { RectangleSliceGroup } from "./style";

export type LabelText = {
  topText: string;
  bottomText: string;
};

type SliceLabelProps = {
  labelText: LabelText;
  xCenterPoint: number;
  y: number;
  width: number;
};

const SliceLabel: React.FC<SliceLabelProps> = ({
  xCenterPoint,
  y,
  labelText: { topText, bottomText },
  width,
}) => (
  <>
    <text x={xCenterPoint} y={y} textAnchor="middle">
      {topText}
    </text>
    <text x={xCenterPoint} y={y + 18} textAnchor="middle" width={width}>
      {bottomText}
    </text>
  </>
);

type BarSliceProps = {
  x: number;
  y: number;
  width: number;
  height: number;
  id?: string;
  isSelected?: boolean;
  onClick?: () => void;
  labelText?: LabelText;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  manualHover?: boolean;
};

export const RectangleSlice: React.FC<BarSliceProps> = ({
  x,
  y,
  width,
  height,
  isSelected,
  onClick,
  labelText,
  onMouseEnter,
  onMouseLeave,
  id,
  manualHover,
}) => (
  <RectangleSliceGroup
    onClick={onClick}
    isSelected={isSelected}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    id={id}
    manualHover={manualHover}
  >
    {labelText && (
      <SliceLabel
        width={width}
        xCenterPoint={x + width / 2}
        y={-28}
        labelText={labelText}
      />
    )}
    <line x1={x} x2={x} y1={y} y2={y + height} />
    <rect x={x} y={y} width={width} height={height} />
    <line x1={x + width} x2={x + width} y1={y} y2={y + height} />
  </RectangleSliceGroup>
);
