import styled from "styled-components";
import fonts from "styles/fonts";

export const List = styled.ul`
  padding: 0;

  && {
    margin: 0;
  }
`;

export const ListElement = styled.li`
  padding: 3px;
  color: #444;
  font-size: 12px;
  list-style-type: none;
`;

export const DataGroupName = styled.p`
  font-size: ${fonts.mediumBigger};
  display: inline-block;
  margin-left: 0.6em;
  cursor: pointer;

  &&& {
    margin-bottom: 0;
  }
`;

export const CheckboxContainer = styled.div``;
