import {
  getData,
  getStitchedData,
  getStitchedDataById,
} from "api/dataVisualisation/client";
import { RemitFilters } from "components/components/Remit/RemitFiltersForm/remitFilters";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import { RemitIdentifierModel } from "models/remit/remitIdentifierModel";
import RemitMessageDetailsModel from "models/remit/remitMessageDetailsModel";
import { getOneOfTwoFilterOptionsForQueryParam } from "utils/queryParamUtils";

export const getRemitRevisions = async (
  mrid: string
): Promise<RemitIdentifierModel[]> => {
  const { data } = await getData(
    "/REMIT/revisions",
    { mrid: mrid },
    DataDownloadFormat.Json
  );

  return data.data;
};

export const getRemitTableDownloadData = async (
  filters: RemitFilters,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<RemitMessageDetailsModel>> => {
  if (format === DataDownloadFormat.Csv) {
    throw new Error("CSV is invalid file format for Remit Message download");
  }

  const endpoint =
    filters.endpoint !== "event"
      ? "/REMIT/list/by-publish"
      : "/REMIT/list/by-event";

  const messageType = getOneOfTwoFilterOptionsForQueryParam(
    filters.messageType
  )?.replace(/\s+/g, ""); // Must remove spaces from messageType

  const unavailabilityType = getOneOfTwoFilterOptionsForQueryParam(
    filters.unavailabilityType
  );

  const staticFilters = {
    messageType: messageType,
    unavailabilityType: unavailabilityType,
    eventType: filters.eventType,
    fuelType: filters.fuelType,
    participantID: filters.participantID ?? undefined,
    assetID: filters.assetID ?? undefined,
    messageID: filters.messageID ?? undefined,
    latestRevisionOnly: filters.revisionHistory === "latest-only",
  };

  const remitIdentifiersResult = (await getStitchedData(
    endpoint,
    { from: "from", to: "to" },
    filters.dateFilter,
    7,
    staticFilters,
    DataDownloadFormat.Json,
    false
  )) as JsonDataDownloadModel<RemitIdentifierModel>;

  const ids = remitIdentifiersResult.data.map((identifier) =>
    identifier.id.toString()
  );

  const deduplicatedIds = [...new Set(ids)];

  return getStitchedDataById(
    "/REMIT",
    "messageId",
    deduplicatedIds,
    200,
    format
  );
};

export const getRemitTableJsonData = (
  filters: RemitFilters
): Promise<JsonDataDownloadModel<RemitMessageDetailsModel>> =>
  getRemitTableDownloadData(filters, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<RemitMessageDetailsModel>
  >;

export const getRemitMessageDetailsDownloadData = async (
  id: number,
  format: DataDownloadFormat
): Promise<DataDownloadModel<RemitMessageDetailsModel>> => {
  if (format === DataDownloadFormat.Csv) {
    throw new Error("CSV is invalid file format for Remit Message download");
  }

  const { data } = await getData(`/REMIT/${id}`, undefined, format);
  return data;
};

export const getRemitMessageDetailsJsonData = (
  id: number
): Promise<JsonDataDownloadModel<RemitMessageDetailsModel>> =>
  getRemitMessageDetailsDownloadData(id, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<RemitMessageDetailsModel>
  >;

export const getRemitMessageDetails = async (
  id: number
): Promise<RemitMessageDetailsModel> => {
  const { data } = await getRemitMessageDetailsJsonData(id);

  return data[0];
};

export const getRemitBulkMessageDetails = async (
  messageId: number[]
): Promise<RemitMessageDetailsModel[]> => {
  const { data } = await getData(
    "/REMIT/",
    { messageId: messageId },
    DataDownloadFormat.Json
  );

  return data.data;
};
