import classnames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";

import { StyledLink } from "./style";

interface Props {
  linkText: string;
  isActive: boolean;
  linkTo?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  onMouseLeave?: (navButtonId: string) => void;
  external?: boolean;
}

const NavLink: React.FC<Props> = ({
  linkText,
  linkTo,
  isActive,
  children,
  onClick = (): void => {
    // This is intentionally blank
  },
  onMouseLeave = (navButtonId): void => {
    document.getElementById(navButtonId)?.blur();
  },
  external,
}: Props) => {
  const navigate = useNavigate();
  const navLinkTestIdSpaceless = `nav-link-${linkText.replace(/\s/g, "-")}`;
  const navButtonIdSpaceless = `nav-button-${linkText
    .replace(/\s/g, "-")
    .toLowerCase()}`;

  const handleClick = (page: string): void => {
    if (external) {
      window.open(page);
    } else {
      navigate(page);
      onClick();
    }
  };

  if (children) {
    return (
      <li
        className="navbar-item has-dropdown is-hoverable"
        onMouseLeave={(): void => onMouseLeave(navButtonIdSpaceless)}
      >
        <StyledLink
          className="navbar-link is-arrowless"
          onClick={onClick}
          data-test-id={navLinkTestIdSpaceless}
          as="div"
        >
          <button
            type="button"
            id={navButtonIdSpaceless}
            className={classnames({ active: isActive })}
          >
            {linkText}
          </button>
        </StyledLink>
        <div className="navbar-dropdown">{children}</div>
      </li>
    );
  }

  return linkTo ? (
    <StyledLink
      className="navbar-item"
      onClick={(): void => handleClick(linkTo)}
      data-test-id={navLinkTestIdSpaceless}
    >
      <button
        type="button"
        id={navButtonIdSpaceless}
        className={classnames({ active: isActive })}
      >
        {linkText}
      </button>
    </StyledLink>
  ) : null;
};

export default NavLink;
