import { getData } from "api/dataVisualisation/client";
import { DailyEnergyTransmittedModel } from "models/dailyEnergyTransmitted/dailyEnergyTransmittedModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { toDateOnlyString } from "utils/dateHelpers";

export const getDailyEnergyTransmittedData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<DailyEnergyTransmittedModel>> => {
  const { data } = await getData(
    "/demand/outturn/daily",
    {
      settlementDateFrom: toDateOnlyString(dateFilter.startDate),
      settlementDateTo: toDateOnlyString(dateFilter.endDate),
    },
    format
  );
  return data;
};

export const getDailyEnergyTransmittedJsonData = (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<DailyEnergyTransmittedModel>> =>
  getDailyEnergyTransmittedData(dateFilter) as Promise<
    JsonDataDownloadModel<DailyEnergyTransmittedModel>
  >;
