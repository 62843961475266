import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import { TimeRange } from "models/filterModels/timeRange";
import { SurplusForecastDaily } from "models/surplusAndMargin/surplusDailyModel";
import { SurplusForecastWeekly } from "models/surplusAndMargin/surplusWeeklyModel";

export const getSurplusForecastDailyData = async (
  format?: DataDownloadFormat
): Promise<DataDownloadModel<SurplusForecastDaily>> => {
  const { data } = await getData("/forecast/surplus/daily", undefined, format);
  return data;
};

export const getSurplusForecastDailyJsonData = (): Promise<
  JsonDataDownloadModel<SurplusForecastDaily>
> =>
  getSurplusForecastDailyData(DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<SurplusForecastDaily>
  >;

export const getSurplusForecastWeeklyData = async (
  timeRange: TimeRange,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<SurplusForecastWeekly>> => {
  const { data } = await getData(
    "/forecast/surplus/weekly",
    {
      range: timeRange,
    },
    format
  );
  return data;
};

export const getSurplusForecastWeeklyJsonData = (
  timeRange: TimeRange
): Promise<JsonDataDownloadModel<SurplusForecastWeekly>> =>
  getSurplusForecastWeeklyData(timeRange, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<SurplusForecastWeekly>
  >;
