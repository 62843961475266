import {
  faCaretDown,
  faCaretUp,
  faLock,
  faLockOpen,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import colours from "styles/colours";
import { filterHiddenColumns } from "utils/tableHelpers";

import ScrollButtons from "./ScrollButtons";
import {
  calculateTotal,
  getHeaderElement,
  getHeaderName,
  useTableScrolling,
} from "./helpers";
import {
  BodyRow,
  CrossIcon,
  HeaderCategoryRow,
  HeaderLockRow,
  HeaderNameContainer,
  HeaderNameRow,
  LockContainer,
  LockIcon,
  LockOrder,
  ScrollableContainer,
  SortContainer,
  TableContainer,
  TotalContainer,
  TotalsLabel,
} from "./style";

export interface HeaderGrouping {
  fromColumnNumber: number;
  toColumnNumber: number;
  name: string;
}

export interface ColumnHeader {
  headerText: string;
  tooltipText: string;
}

export interface HasId {
  id: number | string;
}

export interface MaybeHasUrl {
  url?: string;
}

export type WithoutId<TItem> = Omit<TItem, "id">;

type Order<TItem> = {
  key: keyof WithoutId<TItem>;
  sort: "ASC" | "DESC";
};

export type RenderFunction<TItem> = (el: TItem) => {
  content: JSX.Element;
  tdStyle?: React.CSSProperties;
};

export interface TableCellRender {
  content: JSX.Element;
  tdStyle?: React.CSSProperties;
}

interface Props<TItem> {
  headers: Record<keyof WithoutId<TItem>, string | ColumnHeader>;
  items: TItem[];
  renderFns?: Partial<Record<keyof TItem, RenderFunction<TItem>>>;
  headerGroupings?: HeaderGrouping[];
  dataTestId?: string;
  hiddenColumns?: (keyof WithoutId<TItem>)[];
  shouldOverlapPreviousElement?: boolean;
  scrollable?: boolean;
  allowWrapping?: boolean;
  totals?: {
    columnsWithTotals: (keyof WithoutId<TItem>)[];
    totalsLabelLocation: keyof WithoutId<TItem>;
  };
}

/**
 * @param headers - Table headers, each header can be a string
 * or a ColumnHeader object if they require a tooltip.
 * @param items - Items to be displayed in the table.
 * @param renderFns - Functions for custom rendering of specific columns.
 * @param headerGroupings - Groupings for headers in the table.
 * @param dataTestId - ID of the table for testing.
 * @param hiddenColumns - Columns to be hidden in the table.
 * @param shouldOverlapPreviousElement=true - Indicates whether there is room for horizontal-scroll buttons to be
 * displayed above the table. If not, for example on the per-BMU bid offer tab, then we need to create some space above
 * the table for the buttons to be displayed.
 * @param scrollable=true - Flag to enable horizontal scrolling for the table.
 * @param allowWrapping=false - Flag to allow text wrapping within table cells.
 * @param totals - Object containing information about which columns should have totals and where to put the
 * 'Totals' label.
 */
const SortableTable = <TItem extends HasId & MaybeHasUrl>({
  headers,
  items,
  renderFns,
  headerGroupings,
  dataTestId,
  hiddenColumns,
  shouldOverlapPreviousElement = true,
  scrollable = true,
  allowWrapping = false,
  totals = undefined,
}: Props<TItem>): JSX.Element => {
  const [orders, setOrders] = useState<Array<Order<TItem>>>([]);
  const [orderedItems, setOrderedItems] = useState<TItem[]>(items);

  useEffect(() => {
    setOrderedItems(
      [...items].sort((a, b) =>
        orders.reduce((prev, order) => {
          // if we've already decided which is first, skip the remaining comparisons
          if (prev !== 0) {
            return prev;
          }

          const aField = a[order.key];
          const bField = b[order.key];

          // handling null comparisons - null fields are always sorted to the bottom.
          // note the null checks also check if undefined
          if (aField == null && bField == null) {
            return 0;
          }
          if (aField == null && bField != null) {
            return 1;
          }
          if (aField != null && bField == null) {
            return -1;
          }

          // Convert values to numbers for numerical comparison
          const numericA = Number(aField);
          const numericB = Number(bField);

          // If both values are numbers, perform numerical comparison
          if (!isNaN(numericA) && !isNaN(numericB)) {
            if (numericA === numericB) {
              return 0;
            }
            return (
              (order.sort === "ASC" ? 1 : -1) * (numericA < numericB ? -1 : 1)
            );
          }

          // calling valueOf to explicitly perform value comparison and not reference comparison
          if ((aField as object).valueOf() === (bField as object).valueOf()) {
            return 0;
          }

          return (order.sort === "ASC" ? 1 : -1) * (aField < bField ? -1 : 1);
        }, 0)
      )
    );
  }, [items, orders]);

  const onSortButtonClick = useCallback((key: keyof WithoutId<TItem>): void => {
    setOrders((prevOrders) =>
      prevOrders.some((o) => o.key === key)
        ? prevOrders.map((o) =>
            o.key === key
              ? { ...o, sort: o.sort === "ASC" ? "DESC" : "ASC" }
              : o
          )
        : [...prevOrders, { key, sort: "ASC" }]
    );
  }, []);

  const onRemoveSortClick = useCallback((key: keyof WithoutId<TItem>): void => {
    setOrders((oldOrders) => oldOrders.filter((x) => x.key !== key));
  }, []);

  const generateHeaderCategoryRow = useCallback(() => {
    if (!headerGroupings) {
      return <></>;
    }

    return (
      <HeaderCategoryRow data-test-id="header-category-row">
        {Object.keys(headers)
          .filter(
            (key) => !hiddenColumns?.find((excludeKey) => key === excludeKey)
          )
          .map((_, i) => {
            const headerGrouping = headerGroupings.find(
              (x) => x.fromColumnNumber <= i && i <= x.toColumnNumber
            );

            if (!headerGrouping) {
              // eslint-disable-next-line react/no-array-index-key
              return <th key={`empty-${i}`} />;
            }

            if (i > headerGrouping.fromColumnNumber) {
              // eslint-disable-next-line react/no-array-index-key
              return <Fragment key={`already-rendered-${i}`} />;
            }

            return (
              <th
                key={headerGrouping.name}
                colSpan={
                  headerGrouping.toColumnNumber -
                  headerGrouping.fromColumnNumber +
                  1
                }
                className="active"
              >
                {headerGrouping.name}
              </th>
            );
          })}
      </HeaderCategoryRow>
    );
  }, [headers, headerGroupings, hiddenColumns]);

  const generateHeaderNameRow = useCallback(
    () => (
      <HeaderNameRow data-test-id="header-name-row">
        {filterHiddenColumns(
          Object.keys(headers) as Array<keyof WithoutId<TItem>>,
          hiddenColumns
        ).map((key) => {
          const order = orders.find((x) => x.key === key);
          let headerElement = getHeaderElement(headers[key]);
          let ariaLabel = getHeaderName(headers[key]);

          if (order?.sort === "ASC") {
            ariaLabel += ", ascending";
          } else if (order?.sort === "DESC") {
            ariaLabel += ", descending";
          }

          return (
            <th
              key={key.toString()}
              tabIndex={0}
              onClick={(): void => onSortButtonClick(key)}
              onKeyDown={(e): void => {
                if (e.key === "Enter" || e.key === " ") {
                  onSortButtonClick(key);
                }
              }}
              aria-label={ariaLabel}
            >
              <HeaderNameContainer>
                {headerElement}
                <SortContainer>
                  <Icon
                    iconName={faCaretUp}
                    colour={
                      order?.sort === "DESC"
                        ? colours.elexonBlue
                        : colours.white
                    }
                    size={IconSize.small}
                  />
                  <Icon
                    iconName={faCaretDown}
                    colour={
                      order?.sort === "ASC" ? colours.elexonBlue : colours.white
                    }
                    size={IconSize.small}
                  />
                </SortContainer>
              </HeaderNameContainer>
            </th>
          );
        })}
      </HeaderNameRow>
    ),
    [headers, hiddenColumns, orders, onSortButtonClick]
  );

  const generateHeaderLockRow = useCallback(
    () => (
      <HeaderLockRow data-test-id="header-lock-row">
        {filterHiddenColumns(
          Object.keys(headers) as Array<keyof WithoutId<TItem>>,
          hiddenColumns
        ).map((key) => {
          const order = orders.find((x) => x.key === key);
          const orderIndex = orders.findIndex((x) => x === order);

          if (!order) {
            return (
              <th key={`lock-${key.toString()}`}>
                <LockContainer>
                  <Icon
                    iconName={faLockOpen}
                    colour={colours.mediumGrey}
                    size={IconSize.xSmall}
                    ariaLabel={`Not sorted by ${headers[key]}`}
                  />
                </LockContainer>
              </th>
            );
          }

          return (
            <th
              key={`lock-${key.toString()}`}
              className="active"
              tabIndex={0}
              onClick={(): void => onRemoveSortClick(key)}
              onKeyDown={(e): void => {
                if (e.key === "Enter" || e.key === " ") {
                  onRemoveSortClick(key);
                  e.currentTarget.blur();
                }
              }}
            >
              <LockContainer>
                <LockIcon>
                  <Icon
                    iconName={faLock}
                    colour={colours.white}
                    size={IconSize.xSmall}
                    ariaLabel={`Sorted by ${headers[key]}, ${
                      order!.sort === "ASC" ? "ascending" : "descending"
                    }`}
                  />
                </LockIcon>
                <CrossIcon>
                  <Icon
                    iconName={faTimes}
                    colour={colours.white}
                    size={IconSize.xSmall}
                    ariaLabel={`Remove sort by ${headers[key]}`}
                  />
                </CrossIcon>
                <LockOrder aria-label="">({orderIndex + 1})</LockOrder>
              </LockContainer>
            </th>
          );
        })}
      </HeaderLockRow>
    ),
    [headers, hiddenColumns, orders, onRemoveSortClick]
  );

  const {
    showScrollButtons,
    disableLeftScroll,
    disableRightScroll,
    scrollLeft,
    scrollRight,
    setTableRef,
  } = useTableScrolling();

  return (
    <>
      <TableContainer>
        {showScrollButtons && (
          <ScrollButtons
            handleScrollLeft={scrollLeft}
            handleScrollRight={scrollRight}
            disableLeftScroll={disableLeftScroll}
            disableRightScroll={disableRightScroll}
            shouldOverlapPreviousElement={shouldOverlapPreviousElement}
          />
        )}
        <ScrollableContainer
          scrollable={scrollable}
          ref={setTableRef}
          data-test-id="scrollable-table"
          style={allowWrapping ? { whiteSpace: "pre" } : {}}
        >
          <table data-test-id={dataTestId}>
            <thead>
              {generateHeaderCategoryRow()}
              {generateHeaderNameRow()}
              {generateHeaderLockRow()}
            </thead>
            <tbody>
              <>
                {orderedItems.map((item) => (
                  <BodyRow
                    key={item.id}
                    onClick={(): void => {
                      if (item.url) {
                        window.open(item.url, "_blank");
                      }
                    }}
                    isClickable={!!item.url}
                  >
                    {filterHiddenColumns(
                      (Object.keys(item) as Array<keyof TItem>).filter(
                        (key): key is keyof WithoutId<TItem> => key !== "id"
                      ),
                      hiddenColumns
                    ).map((key) => {
                      const renderFn = renderFns?.[key];
                      let content: JSX.Element;
                      let tdStyle: React.CSSProperties | undefined;

                      if (renderFn) {
                        ({ content, tdStyle } = renderFn(item));
                        if (item[key] === null) {
                          content = <>—</>;
                        }
                      } else {
                        // Default content and no additional styling
                        content = (
                          <>
                            {item[key] == null
                              ? "—"
                              : (item[key] as object).toString()}
                          </>
                        );
                      }

                      return (
                        <td key={key.toString()} style={tdStyle}>
                          {content}
                        </td>
                      );
                    })}
                  </BodyRow>
                ))}
                {totals && (
                  <tr>
                    {filterHiddenColumns(
                      Object.keys(headers) as Array<keyof WithoutId<TItem>>,
                      hiddenColumns
                    ).map((key) => {
                      if (totals.columnsWithTotals.includes(key)) {
                        return (
                          <TotalContainer
                            key={getHeaderName(headers[key]) + "-total"}
                          >
                            <>{calculateTotal(orderedItems, key)}</>
                          </TotalContainer>
                        );
                      } else if (key === totals.totalsLabelLocation) {
                        return (
                          <TotalsLabel key="totals-label">Totals</TotalsLabel>
                        );
                      } else {
                        return (
                          <td
                            key={getHeaderName(headers[key]) + "-no-total"}
                          ></td>
                        );
                      }
                    })}
                  </tr>
                )}
              </>
            </tbody>
          </table>
        </ScrollableContainer>
      </TableContainer>
    </>
  );
};

export default SortableTable;
