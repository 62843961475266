import {
  getTotalLoadWeekAheadData,
  getTotalLoadWeekAheadLatestData,
} from "api/dataVisualisation/totalLoadWeekAhead/totalLoadWeekAhead";
import {
  ModalSection,
  ModalSubHeader,
} from "components/components/Modal/commonStyle";
import DataExporterContainer from "components/dataVisualization/dataExporterComponents/DataExporterContainer/DataExporterContainer";
import DataExporterHeader from "components/dataVisualization/dataExporterComponents/dataExporterSections/DataExporterHeader";
import FileFormatSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/FileFormatSection";
import TotalLoadWeekAheadFilters from "components/dataVisualization/totalLoadWeekAhead/TotalLoadWeekAheadFilters/TotalLoadWeekAheadFilters";
import { useDataExport } from "contexts/DataExportContext";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import { useState } from "react";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import { toDateOnlyString } from "utils/dateHelpers";

interface Props {
  forecastFilter: ForecastFilterOption | null;
  handleChangeToForecastFilter: (forecastFilter: ForecastFilterOption) => void;
  dateFilterForDisplay: DateFilterModel;
  handleChangeToDateSelection: (newDateFilter: DateFilterModel) => void;
  maximumDate: Date;
  maxRange: number;
}

const TotalLoadWeekAheadDataExporter: React.FC<Props> = ({
  forecastFilter,
  handleChangeToForecastFilter,
  dateFilterForDisplay,
  handleChangeToDateSelection,
  maximumDate,
  maxRange,
}: Props) => {
  const { dispatch: dataExportCountDispatch } = useDataExport();
  const [downloadFormat, setDownloadFormat] = useState<DataDownloadFormat>(
    DataDownloadFormat.Csv
  );

  const handleExportClick = (): void => {
    if (forecastFilter === ForecastFilterOption.weekAhead) {
      downloadDataToFile(
        () => getTotalLoadWeekAheadLatestData(downloadFormat),
        `TotalLoadWeekAhead-${toDateOnlyString(new Date())}`,
        downloadFormat,
        dataExportCountDispatch
      );
    } else {
      downloadDataToFile(
        () => getTotalLoadWeekAheadData(dateFilterForDisplay, downloadFormat),
        `TotalLoadWeekAhead-${toDateOnlyString(
          dateFilterForDisplay.startDate
        )}-to-${toDateOnlyString(dateFilterForDisplay.endDate)}`,
        downloadFormat,
        dataExportCountDispatch
      );
    }
  };

  return (
    <DataExporterContainer onExportClick={handleExportClick}>
      <DataExporterHeader />
      <ModalSection>
        <div>
          <ModalSubHeader>Time frame</ModalSubHeader>
          <TotalLoadWeekAheadFilters
            forecastFilter={forecastFilter}
            handleChangeToForecastFilter={handleChangeToForecastFilter}
            dateFilterForDisplay={dateFilterForDisplay}
            handleChangeToDateSelection={handleChangeToDateSelection}
            maximumDate={maximumDate}
            maxRange={maxRange}
          />
        </div>
      </ModalSection>
      <ModalSection>
        <FileFormatSection
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
        />
      </ModalSection>
    </DataExporterContainer>
  );
};

export default TotalLoadWeekAheadDataExporter;
