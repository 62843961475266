import {
  getDatasetByPublishTimes,
  getGenerationOutturnSummaryData,
} from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { DataDownloadModel } from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { OutturnInstantDataPointModel } from "models/outturnInstandModel/outturnInstantDataPointModel";
import { OutturnSummaryModel } from "models/outturnSummaryModel";
import { interconnectorFuelTypes } from "utils/FuelTypeMap";

export const getInterconnectorFlowsChartData = async (
  dateFilter: DateFilterModel
): Promise<OutturnSummaryModel[]> =>
  getGenerationOutturnSummaryData(dateFilter, true);

const maxDaysPerQuery = 7;

export const getInterconnectorFlowsDownloadData = async (
  dateFilter: DateFilterModel,
  format: DataDownloadFormat,
  dataset: string
): Promise<DataDownloadModel<OutturnInstantDataPointModel>> =>
  getDatasetByPublishTimes<OutturnInstantDataPointModel>(
    dateFilter,
    format,
    dataset,
    maxDaysPerQuery,
    {
      fuelType: interconnectorFuelTypes,
    }
  );
