import styled from "styled-components";

export const NoResultsMessage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;

export const WarningTypeFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 20rem;
  padding-top: 2.5rem;
`;
