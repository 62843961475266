import { getDagChartData } from "api/dataVisualisation/dayAheadAggregatedGeneration/dayAheadAggregatedGeneration";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { DagData } from "models/dayAheadAggregatedGeneration/dayAheadAggregatedGeneration";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useCallback } from "react";

const useDayAheadAggregatedGenerationChartData = (
  dateFilter: DateFilterModel
): {
  fetchData: () => void;
  data: DagData[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchDagChartData,
  } = useRequest(getDagChartData);

  const fetchData = useCallback(() => {
    fetchDagChartData(dateFilter);
  }, [dateFilter, fetchDagChartData]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useDayAheadAggregatedGenerationChartData;
