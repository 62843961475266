import { BmuModel } from "models/bmuData/bmuModel";

export const matchingBmUnitData = (
  nationalGridBmUnit: string,
  bmuData: BmuModel[] | null
): { leadParty: string; bmUnitType: string } => {
  const matchingBmUnit = bmuData?.find(
    (b) => b.nationalGridBmUnit === nationalGridBmUnit
  );
  return {
    leadParty: matchingBmUnit?.leadPartyName ?? "—",
    bmUnitType: matchingBmUnit?.bmUnitType ?? "—",
  };
};
