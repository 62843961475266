import { getLolpdrmJsonData as getLolpdrmJsonData } from "api/dataVisualisation/lolpdrm/lolpdrm";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { LolpdrmModel } from "models/lolpdrm/lolpdrmModel";
import { useCallback } from "react";

const useLolpdrmChartContainerData = (
  dateFilter: DateFilterModel
): {
  fetchData: () => void;
  data: LolpdrmModel[] | null;
  status: RequestStatus;
} => {
  const { data, status, request } = useRequest(getLolpdrmJsonData);

  const fetchData = useCallback(() => {
    request(dateFilter);
  }, [dateFilter, request]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useLolpdrmChartContainerData;
