import CheckboxList, {
  CheckboxDataGroup,
} from "components/components/CheckboxList/CheckboxList";
import { ListClickEvent } from "hooks/useCheckboxList";
import React from "react";

export enum CheckboxState {
  UNCHECKED,
  CHECKED,
  INDETERMINATE,
}

export enum ChevronState {
  CLOSED,
  OPEN,
}

export interface CheckboxDataState extends CheckboxDataGroup {
  checkboxState: CheckboxState;
  chevronState: ChevronState;
}

type CheckboxListContainerProps = {
  checkboxDataStates: CheckboxDataState[];
  registerClickForListItem: React.Dispatch<ListClickEvent>;
};

const CheckboxListContainer: React.FC<CheckboxListContainerProps> = ({
  checkboxDataStates,
  registerClickForListItem,
}) => (
  <CheckboxList
    onClick={registerClickForListItem}
    checkboxDataState={checkboxDataStates}
  />
);

export default CheckboxListContainer;
