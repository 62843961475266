const physicalDatasets = ["PN", "QPN", "MILS", "MELS"];

export type PhysicalDataset = typeof physicalDatasets[number];

export const isPhysicalDataset = (input: string): boolean =>
  physicalDatasets.includes(input);

export interface PhysicalDataModel {
  settlementDate: string;
  settlementPeriod: number;
  nationalGridBmUnit: string;
  bmUnit: string;
  timeFrom: string;
  timeTo: string;
  levelTo: number;
  levelFrom: number;
  dataset: PhysicalDataset;
}
