import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

const selectedStyling = css`
  & {
    cursor: default;
  }

  && > line {
    display: block;
    stroke: ${colours.elexonBlue};
    stroke-dasharray: none;
  }

  && > rect {
    fill: ${colours.elexonBlue};
    opacity: 0.3;
  }
`;

const manualHoverStyling = css`
  && > line {
    display: block;
    stroke: ${colours.highlightGrey};
    stroke-dasharray: 2;
  }

  && > rect {
    fill: ${colours.highlightGrey};
    opacity: 0.3;
  }

  && > text {
    display: block;
  }
`;

export const RectangleSliceGroup = styled.g<{
  isSelected?: boolean;
  manualHover?: boolean;
}>`
  cursor: pointer;

  > rect {
    opacity: 0;
  }

  > line {
    display: none;
  }

  > text {
    font-weight: ${fonts.heavy};
    fill: ${colours.mediumGrey};
    display: none;
  }

  :hover > line {
    display: block;
    stroke: ${colours.highlightGrey};
    stroke-dasharray: 2;
  }

  :hover > rect {
    fill: ${colours.highlightGrey};
    opacity: 0.3;
  }

  :hover > text {
    display: block;
  }

  ${({ isSelected }): FlattenSimpleInterpolation | false =>
    !!isSelected && selectedStyling}

  ${({ manualHover }): FlattenSimpleInterpolation | false =>
    !!manualHover && manualHoverStyling}
`;

export const CustomTooltipWrapper = styled.div<{
  offset: number;
  tooltipToRight: boolean;
}>`
  position: absolute;
  top: 100px;

  /* TODO 88303 This is an approximation of tooltip behaviour implemented in Nivo
   There is a way to hook into the existing behaviour through the nivo/tooltip package but this was outside
   the scope of work for this feature, see tech debt ticket for info */
  ${({ offset, tooltipToRight }): string =>
    tooltipToRight
      ? `left: ${offset + 180}px`
      : `left: ${offset - 330 < 0 ? 0 : offset - 330}px`};
  pointer-events: none;
  max-width: 400px;
`;

export const CustomTooltipChart = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
