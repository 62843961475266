import {
  getGenerationForecast14DayData,
  getGenerationForecast3YearData,
} from "api/dataVisualisation/generationForecast/availability/availability";
import { getAllFuelTypes } from "api/dataVisualisation/reference/reference";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import { GenerationForecastDataModel } from "models/generationForecast/generationForecastDataModel";
import { useCallback } from "react";
import { getUniqueDatesFromList } from "utils/dateHelpers";
import { compareForecastDate } from "utils/generationForecastModelHelpers";
import { combineRequestStatuses } from "utils/requestHelpers";

const getGenerationForecast2To52WeekData = async (): Promise<
  GenerationForecastDataModel[]
> => {
  const forecastData = await getGenerationForecast3YearData();
  forecastData.sort(compareForecastDate);

  const forecastDates = forecastData.map((datapoint) => datapoint.forecastDate);
  const individualForecastDates = getUniqueDatesFromList(forecastDates);
  // The zero-index week is week 2 so we want the first 50 dates (2-52 weeks ahead)
  const finalDateToInclude = individualForecastDates[49];
  return forecastData.filter(
    (datapoint) =>
      datapoint.forecastDate.getTime() <= finalDateToInclude.getTime()
  );
};

const useGenerationForecastChartContainerData = (
  forecastFilter: ForecastFilterOption
): {
  fetchData: () => void;
  data: {
    generationForecastData: GenerationForecastDataModel[];
    fuelTypes: string[];
  } | null;
  status: RequestStatus;
} => {
  const {
    data: fuelTypes,
    status: fuelTypesStatus,
    request: fetchFuelTypes,
  } = useRequest(getAllFuelTypes);

  const getGenerationForecastRequest = (): (() => Promise<
    GenerationForecastDataModel[]
  >) => {
    switch (forecastFilter) {
      case ForecastFilterOption.twoToFiftyTwoWeek:
        return getGenerationForecast2To52WeekData;
      case ForecastFilterOption.twoToOneFiveSixWeek:
        return getGenerationForecast3YearData;
      case ForecastFilterOption.twoToFourteenDay:
      default:
        return getGenerationForecast14DayData;
    }
  };

  const {
    data: generationForecastData,
    status: generationForecastStatus,
    request: fetchGenerationForecastData,
  } = useRequest(getGenerationForecastRequest());

  const fetchData = useCallback(() => {
    if (!fuelTypes?.length) {
      fetchFuelTypes();
    }
    fetchGenerationForecastData();
  }, [fetchFuelTypes, fetchGenerationForecastData, fuelTypes?.length]);

  const data =
    fuelTypes && generationForecastData
      ? {
          fuelTypes,
          generationForecastData,
        }
      : null;

  const status = combineRequestStatuses(
    fuelTypesStatus,
    generationForecastStatus
  );

  return {
    data,
    status,
    fetchData,
  };
};

export default useGenerationForecastChartContainerData;
