import { getCreditDefaultNoticeData } from "api/dataVisualisation/creditDefaultNotice/creditDefaultNotice";
import TableWithErrorHandling from "components/components/TableWithErrorHandling/TableWithErrorHandling";
import CreditDefaultNoticeTable from "components/dataVisualization/creditDefaultNotice/CreditDefaultNoticeTable/CreditDefaultNoticeTable";
import { mapToCreditDefaultNoticeRow } from "components/dataVisualization/creditDefaultNotice/CreditDefaultNoticeUtils";
import useCreditDefaultNoticeData from "components/dataVisualization/creditDefaultNotice/useCreditDefaultNoticeData";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import { TableFooter } from "components/dataVisualization/tableComponents/style";
import { useDataExport } from "contexts/DataExportContext";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import React, { useEffect, useMemo } from "react";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";

const CreditDefaultNoticeTableContainer: React.FC = () => {
  const {
    data: rawData,
    status: status,
    fetchData: fetchData,
  } = useCreditDefaultNoticeData();

  useEffect(fetchData, [fetchData]);

  const tableData = useMemo(
    () => (rawData ? mapToCreditDefaultNoticeRow(rawData) : []),
    [rawData]
  );

  const { dispatch: dataExportCountDispatch } = useDataExport();

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getCreditDefaultNoticeData(fileFormat),
      `CreditDefaultNotice`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  return (
    <>
      <TableWithErrorHandling
        data={tableData}
        dataFetchStatus={status}
        rerequestData={fetchData}
      >
        <CreditDefaultNoticeTable data={tableData} />
      </TableWithErrorHandling>
      <TableFooter rightAligned>
        <DataExporter downloadData={downloadFile} />
      </TableFooter>
    </>
  );
};
export default CreditDefaultNoticeTableContainer;
