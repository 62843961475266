import classnames from "classnames";
import FormErrorMessage from "components/components/FormErrorMessage/FormErrorMessage";
import React, { forwardRef } from "react";

import { StyledTextArea } from "./style";

interface Props {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  id: string;
  name: string;
  value: string;
  label: string;
  errorMessage: string;
  maxLength: number;
  showError: boolean;
  required?: boolean;
}

const TextAreaInput: React.FC<
  Props & React.RefAttributes<HTMLTextAreaElement>
> = forwardRef<HTMLTextAreaElement, Props>(function textAreaInput(
  {
    onChange,
    id,
    name,
    value,
    label,
    errorMessage,
    maxLength,
    showError,
    required,
  }: Props,
  ref
) {
  return (
    <div className="block field has-text-weight-bold">
      <label htmlFor={id}>
        <span>{label}</span>
        {required === true && <span aria-hidden>*</span>}
        <div className={classnames("control", { "has-icons-left": showError })}>
          <StyledTextArea
            className={classnames({ invalid: showError })}
            id={id}
            value={value}
            name={name}
            maxLength={maxLength}
            onChange={onChange}
            required={required}
            ref={ref}
          />
          {showError && <FormErrorMessage messageText={errorMessage} />}
        </div>
      </label>
    </div>
  );
});

export default TextAreaInput;
