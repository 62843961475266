import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { StandardChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/StandardChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import {
  chartLayers,
  chartLayersWithTemperatureReference,
} from "styles/chartStyles/chartLayers";
import { DateFormat } from "utils/dateHelpers";

import { getDatasets } from "./TemperatureDatasets";

interface Props {
  series: Serie[];
  dataFetchStatus: RequestStatus;
  rerequestData: () => void;
  showReferenceData: boolean;
}

const TemperatureChart: React.FC<Props> = ({
  series,
  dataFetchStatus,
  rerequestData,
  showReferenceData,
}: Props) => {
  const datasetCategories = useMemo<DatasetCategory<ChartDatasetModel>[]>(
    () => getDatasets(showReferenceData),
    [showReferenceData]
  );

  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;
    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        enableSlices="x"
        axisBottom={chartAxisBottom("Settlement Date", series, isChartLoaded)}
        axisLeft={chartAxisLeft("Temperature (°C)", isChartLoaded)}
        layers={
          showReferenceData && isChartLoaded
            ? chartLayersWithTemperatureReference
            : chartLayers
        }
        sliceTooltip={(input): JSX.Element => (
          <StandardChartTooltip
            series={series}
            datasetCategories={datasetCategories}
            text={{}}
            options={{
              interactionType: TooltipInteractionType.Slice,
              dateFormat: DateFormat.DateOnly,
            }}
            inputPoints={input.slice.points}
          />
        )}
        xScale={chartXTimeScale()}
        yScale={nonStackedLineChartYScale(series)}
      />
    );
  }, [series, datasetCategories, dataFetchStatus, showReferenceData]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      >
        {memoisedChart}
      </ChartWithErrorHandling>
    ),
    [dataFetchStatus, datasetCategories, memoisedChart, rerequestData, series]
  );
};

export default TemperatureChart;
