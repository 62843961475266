import ResultsCount from "components/components/ResultsCount/ResultsCount";
import {
  StyledDateOnly,
  StyledTimeOnly,
} from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
} from "components/components/SortableTable/SortableTable";
import {
  buildBmuViewQueryParamsWithoutBmuId,
  getBmUnitLinkRenderFn,
} from "components/dataVisualization/balancingMechanismTabs/utils";
import { useMarketViewContext } from "contexts/MarketViewContext";
import React from "react";

import BalancingServicesVolumeTableModel from "./BalancingServicesVolumeTableModel";

interface Props {
  data: BalancingServicesVolumeTableModel[];
}

const BalancingServicesVolumeTable: React.FC<Props> = ({ data }) => {
  const { selectedDate } = useMarketViewContext();
  const urlParamsWithoutBmuId = buildBmuViewQueryParamsWithoutBmuId(
    selectedDate,
    "Balancing Services Volume"
  );
  return (
    <>
      <ResultsCount count={data.length} />
      <SortableTable
        headers={{
          bmuId: "BMU ID",
          bmuType: "BMU Type",
          leadParty: "Lead party name",
          ngc: "NGC BMU name",
          date: "Settlement Date",
          period: "Settlement Period",
          time: "Time (UTC)",
          volume: "Volume (MW)",
        }}
        items={data}
        renderFns={{
          date: ({ date }): TableCellRender => ({
            content: StyledDateOnly(date),
          }),
          time: ({ time }): TableCellRender => ({
            content: StyledTimeOnly(time),
          }),
          bmuId: ({ bmuId }): TableCellRender => ({
            content: getBmUnitLinkRenderFn(bmuId, urlParamsWithoutBmuId),
          }),
        }}
        dataTestId="balancing-services-volume-table"
      />
    </>
  );
};

export default BalancingServicesVolumeTable;
