import { getData, getStitchedBmusData } from "api/dataVisualisation/client";
import {
  DynamicDataModel,
  DynamicDataset,
} from "models/bmuData/dynamicDataModel";
import {
  DynamicRatesDataModel,
  DynamicRatesDataset,
} from "models/bmuData/dynamicRatesDataModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { getSettlementDateAndPeriodFromSettlementTime } from "utils/DateUtils";
import { toDateOnlyString } from "utils/dateHelpers";

export const getDynamicData = async (
  dateFilter: DateFilterModel,
  bmUnit: string,
  datasets?: DynamicDataset[],
  format?: DataDownloadFormat
): Promise<DataDownloadModel<DynamicDataModel>> => {
  const { data } = await getData("/balancing/dynamic", {
    snapshotAt: dateFilter.normalisedStartDate.toISOString(),
    until: dateFilter.normalisedEndDate.toISOString(),
    bmUnit,
    dataset: datasets,
    format,
  });
  return data;
};

export const getDynamicJsonData = async (
  dateFilter: DateFilterModel,
  bmUnit: string,
  datasets?: DynamicDataset[]
): Promise<JsonDataDownloadModel<DynamicDataModel>> =>
  getDynamicData(
    dateFilter,
    bmUnit,
    datasets,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<DynamicDataModel>>;

export const getDynamicRatesData = async (
  dateFilter: DateFilterModel,
  bmUnit: string,
  datasets?: DynamicRatesDataset[],
  format?: DataDownloadFormat
): Promise<DataDownloadModel<DynamicRatesDataModel>> => {
  const { data } = await getData("/balancing/dynamic/rates", {
    snapshotAt: dateFilter.normalisedStartDate.toISOString(),
    until: dateFilter.normalisedEndDate.toISOString(),
    bmUnit,
    dataset: datasets,
    format,
  });
  return data;
};

export const getDynamicRatesJsonData = async (
  dateFilter: DateFilterModel,
  bmUnit: string,
  datasets?: DynamicRatesDataset[]
): Promise<JsonDataDownloadModel<DynamicRatesDataModel>> =>
  getDynamicRatesData(
    dateFilter,
    bmUnit,
    datasets,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<DynamicRatesDataModel>>;

export const getMarketWideDynamicData = async (
  dataset: DynamicDataset,
  date: Date,
  bmUnits?: string[],
  format?: DataDownloadFormat
): Promise<DataDownloadModel<DynamicDataModel>> => {
  const { settlementDate, settlementPeriod } =
    getSettlementDateAndPeriodFromSettlementTime(date);
  return getStitchedBmusData(
    "/balancing/dynamic/all",
    {
      dataset: dataset,
      settlementDate: toDateOnlyString(settlementDate),
      settlementPeriod: settlementPeriod,
    },
    bmUnits,
    format
  );
};

export const getMarketWideDynamicRatesData = async (
  dataset: DynamicRatesDataset,
  date: Date,
  bmUnits?: string[],
  format?: DataDownloadFormat
): Promise<DataDownloadModel<DynamicRatesDataModel>> => {
  const { settlementDate, settlementPeriod } =
    getSettlementDateAndPeriodFromSettlementTime(date);
  return getStitchedBmusData(
    "/balancing/dynamic/rates/all",
    {
      dataset: dataset,
      settlementDate: toDateOnlyString(settlementDate),
      settlementPeriod: settlementPeriod,
    },
    bmUnits,
    format
  );
};

export const getMarketWideDynamicJsonData = async (
  dataset: DynamicDataset,
  settlementDate: Date,
  bmUnits?: string[]
): Promise<JsonDataDownloadModel<DynamicDataModel>> =>
  getMarketWideDynamicData(
    dataset,
    settlementDate,
    bmUnits,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<DynamicDataModel>>;

export const getMarketWideDynamicRatesJsonData = async (
  dataset: DynamicRatesDataset,
  settlementDate: Date,
  bmUnits?: string[]
): Promise<JsonDataDownloadModel<DynamicRatesDataModel>> =>
  getMarketWideDynamicRatesData(
    dataset,
    settlementDate,
    bmUnits,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<DynamicRatesDataModel>>;
