import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { NonBmStorModel } from "models/nonBmStor/nonBmStorModel";

export const getNonBmStorTimeSeriesData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<NonBmStorModel>> => {
  const { data } = await getData(
    "/balancing/nonbm/stor",
    {
      from: dateFilter.startDate,
      to: dateFilter.endDate,
      includeZero: true,
    },
    format
  );
  return data;
};

export const getNonBmStorTimeSeriesJsonData = (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<NonBmStorModel>> =>
  getNonBmStorTimeSeriesData(dateFilter, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<NonBmStorModel>
  >;

export const getNonBmStorEventsData = async (
  count: number,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<NonBmStorModel>> => {
  const { data } = await getData(
    "/balancing/nonbm/stor/events",
    {
      count,
    },
    format
  );
  return data;
};

export const getNonBmStorEventsJsonData = (
  count: number
): Promise<JsonDataDownloadModel<NonBmStorModel>> =>
  getNonBmStorEventsData(count, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<NonBmStorModel>
  >;
