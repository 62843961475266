import { getIndicativeVolumeJsonData } from "api/dataVisualisation/derivedData/indicativeVolume";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { BmuModel } from "models/bmuData/bmuModel";
import { IndicativeVolumeModel } from "models/derivedData/indicativeVolumeModel";
import { useCallback } from "react";

import { BidOffer } from "./useAcceptanceVolumeData";

const useIndicativeVolumeData = (
  settlementTime: Date,
  bidOffer: BidOffer,
  bmus?: BmuModel[]
): {
  fetchData: () => void;
  data: IndicativeVolumeModel[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchIndicativeVolumeData,
  } = useRequest(getIndicativeVolumeJsonData);
  const fetchData = useCallback(() => {
    fetchIndicativeVolumeData(settlementTime, bidOffer, bmus);
  }, [bidOffer, bmus, fetchIndicativeVolumeData, settlementTime]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useIndicativeVolumeData;
