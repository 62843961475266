import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import { SpinnerContainer } from "components/components/LoadingSpinner/style";
import RerequestButton from "components/components/RerequestButton/RerequestButton";
import { RequestStatus } from "hooks/useRequest";
import { DataFetcher } from "models/dataFetcher";

import { SpinnerParent } from "./style";

interface Props extends DataFetcher {
  children: JSX.Element;
}

const SpinnerWrapper: React.FC<{
  children: JSX.Element;
}> = ({ children }) => (
  <SpinnerParent>
    <SpinnerContainer className="large">{children}</SpinnerContainer>
  </SpinnerParent>
);

const TabContentWithErrorHandling: React.FC<Props> = ({
  status,
  fetchData,
  children,
}) => {
  if (status === RequestStatus.ERRORED) {
    return (
      <SpinnerWrapper>
        <RerequestButton rerequestData={fetchData} />
      </SpinnerWrapper>
    );
  }

  if (status === RequestStatus.IN_PROGRESS) {
    return (
      <SpinnerWrapper>
        <LoadingSpinner isLoading />
      </SpinnerWrapper>
    );
  }

  return children;
};

export default TabContentWithErrorHandling;
