import { Serie } from "@nivo/line";
import {
  PhysicalChartData,
  PhysicalChartDataWithStableLimitData,
  pointShapeForPhysicalDataset,
  StableLimitChartDataset,
  StableLimitData,
  StableLimitRangedData,
} from "models/bmuData/physicalChartData";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { physicalDatasetColours } from "styles/colours";
import { transformRangedChartData } from "utils/ChartDataUtils/rangedChartDataTransformer/rangedChartDataTransformer";

const stableLimitDatasets: StableLimitChartDataset[] = ["SEL", "SIL"];

export const transformStableLimitDataToRangedData = (
  stableLimitData: StableLimitData[],
  timeFrom: Date,
  timeTo: Date
): StableLimitRangedData[] =>
  stableLimitDatasets.flatMap((stableLimitDataset): StableLimitRangedData[] =>
    stableLimitData
      .filter(({ dataset }) => dataset === stableLimitDataset)
      .reduce(
        (
          result,
          { value, time, nationalGridBmUnit, bmUnit, dataset },
          index,
          array
        ): StableLimitRangedData[] => {
          const constantValues: Pick<
            StableLimitRangedData,
            "nationalGridBmUnit" | "bmUnit" | "dataset" | "publishTime"
          > = {
            nationalGridBmUnit,
            bmUnit,
            dataset,
            publishTime: time,
          };
          // First point chronologically or only point
          // As the first chronological point is a snapshot value it will always
          // be at or earlier than the chart start time, therefore we can safely
          // draw the value from the start of the x-axis
          if (index === array.length - 1) {
            const nextPoint = result[0];
            return [
              {
                ...constantValues,
                timeFrom,
                timeTo: nextPoint?.timeFrom ?? timeTo,
                level: value,
              },
              ...result,
            ];
          }
          // Last point chronologically and not only point
          if (index === 0) {
            return [
              {
                ...constantValues,
                timeFrom: time,
                timeTo,
                level: value,
              },
              ...result,
            ];
          }
          // Middle point
          const nextPoint = result[0];
          return [
            {
              ...constantValues,
              timeFrom: time,
              timeTo: nextPoint.timeFrom,
              level: value,
            },
            ...result,
          ];
        },
        [] as StableLimitRangedData[]
      )
  );

export const transformStableLimitDataToPhysicalChartData = (
  stableLimitData: StableLimitData[],
  chartTimeFrom: Date,
  chartTimeTo: Date
): PhysicalChartData[] =>
  transformStableLimitDataToRangedData(
    stableLimitData,
    chartTimeFrom,
    chartTimeTo
  ).map(({ publishTime, level, ...chartData }) => ({
    levelFrom: level,
    levelTo: level,
    ...chartData,
  }));

export const transformPhysicalChartData = (
  rawData: PhysicalChartDataWithStableLimitData,
  dateFilter: DateFilterModel
): Serie[] => {
  const rangedData = [
    ...rawData.rangedData,
    ...transformStableLimitDataToPhysicalChartData(
      rawData.stableLimitChartData,
      dateFilter.startDate,
      dateFilter.endDate
    ),
  ];
  return transformRangedChartData(
    rangedData,
    dateFilter,
    pointShapeForPhysicalDataset,
    physicalDatasetColours
  );
};
