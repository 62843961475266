import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import Button from "components/components/Button/Button";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import { DataExportAction, useDataExport } from "contexts/DataExportContext";
import { ToastDetails, ToastState } from "models/ToastDetails";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import colours from "styles/colours";

import {
  ExitButtonContainer,
  LoadingSpinnerContainer,
  IconContainer,
  StyledToast,
  ToastContent,
} from "./style";

interface Props {
  details: ToastDetails;
}

const DISMISS_TIMEOUT_MILLISECONDS = 3000;

export const DataExportToast: React.FC<Props> = ({ details }: Props) => {
  const { dispatch } = useDataExport();
  const [dismissed, setDismissed] = useState(false);
  const [dismissTimeout, setDismissTimeout] = useState<NodeJS.Timeout>();

  const dismiss = useCallback((): void => {
    toast.dismiss(details.id);
    dispatch(DataExportAction.DismissToastAndIncrementToastId);
    setDismissed(true);
  }, [details.id, dispatch]);

  useEffect(() => {
    if (
      details.state === ToastState.Complete ||
      details.state === ToastState.Error
    ) {
      setDismissTimeout(setTimeout(dismiss, DISMISS_TIMEOUT_MILLISECONDS));
    } else if (dismissTimeout) {
      clearTimeout(dismissTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- updating on dismissTimeout is unnecessary and causes a loop
  }, [details, dismiss]);

  const toastIcon = (state: ToastState): JSX.Element => {
    switch (state) {
      case ToastState.Complete:
        return (
          <Icon
            iconName={faCheckCircle}
            colour={colours.elexonPurple}
            ariaLabel="Complete"
            size={IconSize.medium}
          />
        );
      case ToastState.Error:
        return (
          <Icon
            iconName={faTimes}
            colour={colours.elexonPurple}
            ariaLabel="Error"
            size={IconSize.medium}
          />
        );
      case ToastState.InProgress:
        return (
          <LoadingSpinnerContainer>
            <LoadingSpinner isLoading />
          </LoadingSpinnerContainer>
        );
      default:
        return <></>;
    }
  };

  return (
    <StyledToast
      className={classnames({ dismissed })}
      data-test-id="export-toast-container"
    >
      <IconContainer>{toastIcon(details.state)}</IconContainer>
      <ToastContent>
        <h4 className="title" data-test-id="toast-title">
          {details.title}
        </h4>
        {details.message && (
          <p data-test-id="update-toast-message">{details.message}</p>
        )}
      </ToastContent>
      <ExitButtonContainer>
        <Button
          className="plain-inline"
          ariaLabel="Dismiss"
          buttonText=""
          iconName={faTimes}
          onClick={dismiss}
        />
      </ExitButtonContainer>
    </StyledToast>
  );
};

export const createOrUpdateDataExportToast = (
  toastDetails: ToastDetails,
  duration: number
): string =>
  toast.custom(<DataExportToast details={toastDetails} />, {
    id: toastDetails.id,
    duration,
  });
