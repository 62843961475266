import { getData } from "api/dataVisualisation/client";
import { BidOfferQueryIndicator } from "components/dataVisualization/DetailedSystemPrices/utils";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import {
  SettlementBidOfferStackModel,
  SettlementCalculationSummaryModel,
  SettlementMessageModel,
} from "models/detailedSystemPrices/settlementModels";

export const getSettlementCalculationSummaryData = async (
  settlementDate: string,
  settlementPeriod: number,
  format?: DataDownloadFormat
): Promise<SettlementCalculationSummaryModel> => {
  const { data } = await getData(
    `/balancing/settlement/summary/${settlementDate}/${settlementPeriod}`,
    undefined,
    format
  );
  return data;
};

export const getSettlementCalculationSummaryJsonData = (
  settlementDate: string,
  settlementPeriod: number
): Promise<SettlementCalculationSummaryModel> =>
  getSettlementCalculationSummaryData(
    settlementDate,
    settlementPeriod,
    DataDownloadFormat.Json
  );

export const getSettlementMessagesData = async (
  settlementDate: string,
  settlementPeriod: number,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<SettlementMessageModel>> => {
  const { data } = await getData(
    `/balancing/settlement/messages/${settlementDate}/${settlementPeriod}`,
    undefined,
    format
  );
  return data;
};

export const getSettlementMessagesJsonData = (
  settlementDate: string,
  settlementPeriod: number
): Promise<JsonDataDownloadModel<SettlementMessageModel>> =>
  getSettlementMessagesData(
    settlementDate,
    settlementPeriod,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<SettlementMessageModel>>;

export const getSettlementBidOfferStacksData = async (
  settlementDate: string,
  settlementPeriod: number,
  stackType: BidOfferQueryIndicator,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<SettlementBidOfferStackModel>> => {
  const { data } = await getData(
    `/balancing/settlement/stack/all/${stackType}/${settlementDate}/${settlementPeriod}`,
    undefined,
    format
  );
  return data;
};

export const getSettlementBidOfferStacksJsonData = (
  settlementDate: string,
  settlementPeriod: number,
  stackType: BidOfferQueryIndicator
): Promise<JsonDataDownloadModel<SettlementBidOfferStackModel>> =>
  getSettlementBidOfferStacksData(
    settlementDate,
    settlementPeriod,
    stackType,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<SettlementBidOfferStackModel>>;
