import React from "react";

import { Spacer, StyledFullWidthChartSection } from "./style";

interface Props {
  children?: JSX.Element;
}

const FullWidthChartSection: React.FC<Props> = ({ children }: Props) => (
  <StyledFullWidthChartSection>
    <Spacer />
    {children}
  </StyledFullWidthChartSection>
);

export default FullWidthChartSection;
