import ResultsCount from "components/components/ResultsCount/ResultsCount";
import { StyledTimeOnly } from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
} from "components/components/SortableTable/SortableTable";
import {
  buildBmuViewQueryParamsWithoutBmuId,
  getBmUnitLinkRenderFn,
} from "components/dataVisualization/balancingMechanismTabs/utils";
import { useMarketViewContext } from "contexts/MarketViewContext";
import MarketViewBidOfferTableModel from "models/bidOffer/marketViewBidOfferTableModel";
import React from "react";

interface Props {
  data: MarketViewBidOfferTableModel[];
}

const BidOfferTable: React.FC<Props> = ({ data }) => {
  const { selectedDate } = useMarketViewContext();
  const urlParamsWithoutBmuId = buildBmuViewQueryParamsWithoutBmuId(
    selectedDate,
    "Bid Offer"
  );
  return (
    <>
      <ResultsCount count={data.length} />
      <SortableTable
        headers={{
          bmUnit: "BMU ID",
          leadParty: "Lead party name",
          bmuType: "BMU Type",
          nationalGridBmUnit: "NGC BMU name",
          pairName: "Pair number",
          timeFrom: "From time (UTC)",
          levelFrom: "From level (MW)",
          timeTo: "To time (UTC)",
          levelTo: "To level (MW)",
          bid: "Bid price (£/MWh)",
          offer: "Offer price (£/MWh)",
        }}
        items={data}
        renderFns={{
          timeFrom: ({ timeFrom }): TableCellRender => ({
            content: StyledTimeOnly(timeFrom),
          }),
          timeTo: ({ timeTo }): TableCellRender => ({
            content: StyledTimeOnly(timeTo),
          }),
          bmUnit: ({ bmUnit }): TableCellRender => ({
            content: getBmUnitLinkRenderFn(bmUnit, urlParamsWithoutBmuId),
          }),
        }}
        dataTestId="bid-offer-table"
      />
    </>
  );
};

export default BidOfferTable;
