const ratesDatasets = ["RDRE", "RURE", "RDRI", "RURI"];
export type DynamicRatesDataset = typeof ratesDatasets[number];

export const isRatesDataset = (input: string): boolean =>
  ratesDatasets.includes(input);

export interface DynamicRatesDataModel {
  dataset: string;
  bmUnit: string;
  nationalGridBmUnit: string;
  time: string;
  rate1: number | null;
  elbow2: number | null;
  rate2: number | null;
  elbow3: number | null;
  rate3: number | null;
  settlementDate: string;
  settlementPeriod: number;
}
