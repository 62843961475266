import { PointSymbolProps } from "@nivo/line";
import React from "react";

export enum PointShape {
  Circle,
  Square,
  Diamond,
  Pentagon,
  None,
}

type CustomPointShapeProps = { size: number; color: string };

export const SquarePoint: React.FC<CustomPointShapeProps> = ({
  size,
  color,
}) => (
  <rect x={-size / 2} y={-size / 2} width={size} height={size} fill={color} />
);

export const DiamondPoint: React.FC<CustomPointShapeProps> = ({
  size,
  color,
}) => (
  <g transform={`rotate(45,0,0)`}>
    <SquarePoint size={size} color={color} />
  </g>
);

export const PentagonSvg: React.FC<CustomPointShapeProps> = ({
  size,
  color,
}) => (
  <svg width={size} height={size} viewBox="-1 0 101 100">
    <path d="M20,0 L80,0 L100,60 L50,100 L0,60z" fill={color} />
  </svg>
);

export const PentagonPoint: React.FC<CustomPointShapeProps> = ({
  size,
  color,
}) => (
  <g transform={`rotate(180) translate(${-size / 2},${-size / 2})`}>
    <PentagonSvg size={size + 2} color={color} />
  </g>
);

export const CirclePoint: React.FC<CustomPointShapeProps> = ({
  size,
  color,
}) => <circle r={size / 2} fill={color} />;

// To use custom shapes, add a pointShape property of type PointShape to each data point
// and pass this function to the pointSymbol Nivo property
export const buildCustomShapePoint = ({
  datum,
  size,
  color,
}: PointSymbolProps): React.ReactNode => {
  switch (datum?.pointShape) {
    case PointShape.Diamond:
      return <DiamondPoint size={size} color={color} />;
    case PointShape.Square:
      return <SquarePoint size={size} color={color} />;
    case PointShape.Pentagon:
      return <PentagonPoint size={size} color={color} />;
    case PointShape.None:
      return null;
    case PointShape.Circle:
    default:
      return <CirclePoint size={size} color={color} />;
  }
};
