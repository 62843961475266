import { Datum, Serie } from "@nivo/line";
import { TotalLoadWeekAheadModel } from "models/TotalLoad/totalLoadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { chartColours } from "styles/colours";
import { sortByForecastDate } from "utils/ChartDataUtils";

const maxAvailableDataKey = "maximumAvailable";
const minPossibleDataKey = "minimumPossible";

export const datasetCategories = [
  {
    datasets: [
      {
        colour: chartColours.watlMax,
        dataKey: maxAvailableDataKey,
        displayName: "Maximum available",
      },
      {
        colour: chartColours.watlMin,
        dataKey: minPossibleDataKey,
        displayName: "Minimum possible",
      },
    ],
    id: "Total Load",
  },
];

export const getDateRangeFor = (
  data: TotalLoadWeekAheadModel[]
): DateFilterModel => {
  const sortedData = sortByForecastDate(data);
  const minDate = new Date(sortedData[0].forecastDate);
  const maxDate = new Date(sortedData[sortedData.length - 1].forecastDate);

  return new DateFilterModel(minDate, maxDate);
};

export const transformDataToSerie = (
  data: TotalLoadWeekAheadModel[]
): Serie[] => {
  const sortedData = sortByForecastDate(data);
  const dataSeries = sortedData.reduce(
    (prev, curr) => ({
      minimumPossible: [
        ...prev.minimumPossible,
        {
          x: new Date(curr.forecastDate),
          y: curr.minimumPossible,
          publishTime: new Date(curr.publishTime),
        },
      ],
      maximumAvailable: [
        ...prev.maximumAvailable,
        {
          x: new Date(curr.forecastDate),
          y: curr.maximumAvailable,
          publishTime: new Date(curr.publishTime),
        },
      ],
    }),
    {
      minimumPossible: [] as Datum[],
      maximumAvailable: [] as Datum[],
    }
  );

  return [
    {
      id: minPossibleDataKey,
      colour: chartColours.watlMin,
      data: dataSeries.minimumPossible,
    },
    {
      id: maxAvailableDataKey,
      colour: chartColours.watlMax,
      data: dataSeries.maximumAvailable,
    },
  ];
};
