import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import { TimeRange } from "models/filterModels/timeRange";
import { MarginForecastDaily } from "models/surplusAndMargin/marginDailyModel";
import { MarginForecastWeekly } from "models/surplusAndMargin/marginWeeklyModel";

export const getMarginForecastDailyData = async (
  format?: DataDownloadFormat
): Promise<DataDownloadModel<MarginForecastDaily>> => {
  const { data } = await getData("/forecast/margin/daily", undefined, format);
  return data;
};

export const getMarginForecastDailyJsonData = (): Promise<
  JsonDataDownloadModel<MarginForecastDaily>
> =>
  getMarginForecastDailyData(DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<MarginForecastDaily>
  >;

export const getMarginForecastWeeklyData = async (
  timeRange: TimeRange,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<MarginForecastWeekly>> => {
  const { data } = await getData(
    "/forecast/margin/weekly",
    {
      range: timeRange,
    },
    format
  );
  return data;
};

export const getMarginForecastWeeklyJsonData = (
  timeRange: TimeRange
): Promise<JsonDataDownloadModel<MarginForecastWeekly>> =>
  getMarginForecastWeeklyData(timeRange, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<MarginForecastWeekly>
  >;
