import classnames from "classnames";
import { StyledIcon } from "components/components/Icon/styles";
import React from "react";

export enum IconSize {
  xSmall = "x-small",
  small = "",
  mediumSmall = "medium-small",
  medium = "medium",
  large = "large",
  xLarge = "x-large",
}

interface Props {
  ariaLabel?: string;
  ariaDescribedBy?: string;
  size?: IconSize;
  onClick?: () => void;
  children: JSX.Element;
}

const IconWrapper: React.FC<Props> = ({
  ariaLabel,
  ariaDescribedBy,
  size = IconSize.small,
  onClick,
  children,
}: Props) => (
  <StyledIcon
    className={classnames("icon", size)}
    aria-label={ariaLabel}
    aria-describedby={ariaDescribedBy}
    aria-hidden={ariaLabel === undefined}
    role="img"
    onClick={onClick}
  >
    {children}
  </StyledIcon>
);

export default IconWrapper;
