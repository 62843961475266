import {
  StyledSwatch,
  StyledTimeOnly,
} from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
} from "components/components/SortableTable/SortableTable";
import {
  mapToBidOfferRow,
  mapToPhysicalNotificationRow,
} from "components/dataVisualization/balancingMechanismTabs/BidOffer/bmuView/utils";
import { useBmuViewContext } from "contexts/BmuViewContext";
import {
  BidOfferDataset,
  pointShapeForBidOfferDataset,
  bidOfferDatasetDisplayName,
} from "models/bidOffer/bidOfferChartData";
import React from "react";
import { bidOfferDatasetColours } from "styles/colours";
import { toSettlementPeriodRangeString } from "utils/dateHelpers";
import { filterRawModelToSettlementPeriod } from "utils/tableHelpers";

interface Props {
  settlementPeriodDatetime: Date | null;
}

const BidOfferTables: React.FC<Props> = ({ settlementPeriodDatetime }) => {
  const {
    bidOfferDetails: { data },
  } = useBmuViewContext();

  const physicalNotificationTableData =
    data?.physicalNotificationData && settlementPeriodDatetime
      ? mapToPhysicalNotificationRow(
          filterRawModelToSettlementPeriod(
            settlementPeriodDatetime,
            data?.physicalNotificationData
          )
        )
      : [];

  const bidOfferTableData =
    data?.bidOfferPairData && settlementPeriodDatetime
      ? mapToBidOfferRow(
          filterRawModelToSettlementPeriod(
            settlementPeriodDatetime,
            data?.bidOfferPairData
          )
        )
      : [];

  return (
    <div data-test-id="bid-offer-tables" id="bid-offer-tables">
      {settlementPeriodDatetime ? (
        <>
          <h3>{toSettlementPeriodRangeString(settlementPeriodDatetime)}</h3>
          <SortableTable
            headers={{
              dataset: "Dataset",
              timeFrom: "From time (UTC)",
              levelFrom: "From level (MW)",
              timeTo: "To time (UTC)",
              levelTo: "To level (MW)",
            }}
            items={physicalNotificationTableData}
            renderFns={{
              dataset: (): TableCellRender => ({
                content: StyledSwatch(
                  bidOfferDatasetColours.finalPhysicalNotification,
                  pointShapeForBidOfferDataset.finalPhysicalNotification,
                  bidOfferDatasetDisplayName.finalPhysicalNotification
                ),
              }),
              timeFrom: ({ timeFrom }): TableCellRender => ({
                content: StyledTimeOnly(timeFrom),
              }),
              timeTo: ({ timeTo }): TableCellRender => ({
                content: StyledTimeOnly(timeTo),
              }),
            }}
            dataTestId="physical-notification-table-bid-offer-data"
          />
          <br />
          <SortableTable
            headers={{
              pairName: "Pair number",
              timeFrom: "From time (UTC)",
              levelFrom: "From level (MW)",
              timeTo: "To time (UTC)",
              levelTo: "To level (MW)",
              bid: "Bid price (£/MWh)",
              offer: "Offer price (£/MWh)",
            }}
            items={bidOfferTableData}
            renderFns={{
              pairName: ({ pairName }): TableCellRender => {
                const pairDataset = pairName as BidOfferDataset;
                return {
                  content: StyledSwatch(
                    bidOfferDatasetColours[pairDataset],
                    pointShapeForBidOfferDataset[pairDataset],
                    bidOfferDatasetDisplayName[pairDataset]
                  ),
                };
              },
              timeFrom: ({ timeFrom }): TableCellRender => ({
                content: StyledTimeOnly(timeFrom),
              }),
              timeTo: ({ timeTo }): TableCellRender => ({
                content: StyledTimeOnly(timeTo),
              }),
            }}
            dataTestId="bid-offer-table-bid-offer-data"
            shouldOverlapPreviousElement={false}
          />
        </>
      ) : (
        // Only show prompt when data is available
        !!data?.chartData?.length &&
        "Select a settlement period on the graph above to see the values of each dataset."
      )}
    </div>
  );
};

export default BidOfferTables;
