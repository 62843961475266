import {
  getDemandForecastDailyData,
  getDemandForecastDayAheadLatestData,
  getDemandForecastWeeklyData,
} from "api/dataVisualisation/demandForecast/demandForecast";
import DemandForecastChart from "components/dataVisualization/DemandForecast/DemandForecastChart/DemandForecastChart";
import useDemandForecastChartContainerData from "components/dataVisualization/DemandForecast/DemandForecastChartContainer/useDemandForecastChartContainerData";
import DemandForecastDataExporter from "components/dataVisualization/DemandForecast/DemandForecastDataExporter/DemandForecastDataExporter";
import {
  ChartBody,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import ChartFilterDrawer from "components/dataVisualization/dataPageComponents/ChartFilterDrawer/ChartFilterDrawer";
import ForecastFilter from "components/dataVisualization/dataPageComponents/ForecastFilter/ForecastFilter";
import { useDataExport } from "contexts/DataExportContext";
import { useDatasetCategoriesWithVisibilities } from "hooks/useDatasetsCategoriesWithVisibilities";
import useToggle from "hooks/useToggle";
import { Granularity } from "models/Granularity";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { DataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import React, { useEffect, useMemo, useState } from "react";
import { transformDemandForecastModelData } from "utils/ChartDataUtils/demandForecast/demandForecast";
import { transformInitialDemandOutturnModelData } from "utils/ChartDataUtils/demandOutturn/demandOutturn";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import { startOfSettlementDay } from "utils/dateHelpers";

import { initialForecastDatasets, initialOutturnDatasets } from "./datasets";

const enum DatasetIds {
  Forecast = "forecast",
  Outturn = "outturn",
}

const DemandForecastChartContainer: React.FC = () => {
  const forecastFilterTabs = [
    ForecastFilterOption.dayAndDayAhead,
    ForecastFilterOption.twoToFourteenDay,
    ForecastFilterOption.twoToFiftyTwoWeek,
  ];
  const [forecastFilter, setForecastFilter] = useState<ForecastFilterOption>(
    ForecastFilterOption.dayAndDayAhead
  );

  const [showChartFilterDrawer, toggleChartFilterDrawer] = useToggle(false);

  const {
    allDatasetCategories,
    datasetCategoriesToDisplay,
    setMatchingDatasetsVisibility,
    setMatchingDatasetsDisabled,
  } = useDatasetCategoriesWithVisibilities([
    {
      datasets: initialForecastDatasets,
      id: DatasetIds.Forecast,
      title: "Forecasts",
    },
    {
      datasets: initialOutturnDatasets,
      id: DatasetIds.Outturn,
      title: "Outturn",
      disabledText: "These data sets are only available for Day-Day Ahead.",
    },
  ]);

  const { dispatch: dataExportCountDispatch } = useDataExport();

  const {
    data: rawData,
    fetchData,
    status,
  } = useDemandForecastChartContainerData(forecastFilter);

  useEffect(fetchData, [fetchData]);

  useEffect(() => {
    setMatchingDatasetsDisabled(
      forecastFilter !== ForecastFilterOption.dayAndDayAhead,
      DatasetIds.Outturn
    );
  }, [setMatchingDatasetsDisabled, forecastFilter]);

  const demandForecastChartData = useMemo(
    () =>
      rawData?.demandForecastData?.length
        ? transformDemandForecastModelData(
            rawData.demandForecastData,
            datasetCategoriesToDisplay.find(
              (x) => x.id === DatasetIds.Forecast
            )!.datasets
          )
        : [],
    [rawData?.demandForecastData, datasetCategoriesToDisplay]
  );

  const demandOutturnChartData = useMemo(() => {
    if (!rawData?.initialDemandOutturnData?.length) {
      return [];
    }

    const today = startOfSettlementDay();
    const filteredData = rawData.initialDemandOutturnData.filter(
      (data) => new Date(data.startTime) >= today
    );

    return transformInitialDemandOutturnModelData(
      filteredData,
      datasetCategoriesToDisplay.find((x) => x.id === DatasetIds.Outturn)!
        .datasets
    );
  }, [rawData?.initialDemandOutturnData, datasetCategoriesToDisplay]);

  const chartData = useMemo(
    () => [...demandForecastChartData, ...demandOutturnChartData],
    [demandForecastChartData, demandOutturnChartData]
  );

  const downloadFile = (
    fileFormat: DataDownloadFormat,
    granularity: Granularity
  ): void => {
    let download: () => Promise<DataDownloadModel<unknown>>;
    let filename: string;

    const granularityTag =
      granularity === Granularity.National ? "National" : "Zonal";

    switch (forecastFilter) {
      case ForecastFilterOption.dayAndDayAhead: {
        download = (): Promise<DataDownloadModel<unknown>> =>
          getDemandForecastDayAheadLatestData(granularity, fileFormat);
        filename = `DemandForecast-${granularityTag}-DayAndDayAhead-${new Date().toISOString()}`;
        break;
      }
      case ForecastFilterOption.twoToFourteenDay: {
        download = (): Promise<DataDownloadModel<unknown>> =>
          getDemandForecastDailyData(fileFormat);
        filename = `DemandForecast-2to14day-${new Date().toISOString()}`;
        break;
      }
      case ForecastFilterOption.twoToFiftyTwoWeek: {
        download = (): Promise<DataDownloadModel<unknown>> =>
          getDemandForecastWeeklyData(fileFormat);
        filename = `DemandForecast-2to52week-${new Date().toISOString()}`;
        break;
      }
      default: {
        break;
      }
    }

    downloadDataToFile(
      download!,
      filename!,
      fileFormat,
      dataExportCountDispatch
    );
  };

  return (
    <>
      <ChartHeader>
        <ForecastFilter
          forecastFilter={forecastFilter}
          setForecastFilter={setForecastFilter}
          forecastFilterTabs={forecastFilterTabs}
          showChartFilterDrawer={showChartFilterDrawer}
          toggleChartFilterDrawer={toggleChartFilterDrawer}
        />
      </ChartHeader>
      <ChartBody>
        {showChartFilterDrawer && (
          <ChartFilterDrawer
            setMatchingDatasetsVisibility={setMatchingDatasetsVisibility}
            toggleChartFilterDrawer={toggleChartFilterDrawer}
            datasetCategories={allDatasetCategories}
          />
        )}
        <div style={{ width: showChartFilterDrawer ? "80vw" : "100vw" }}>
          <DemandForecastChart
            series={chartData}
            datasetCategories={datasetCategoriesToDisplay}
            dataFetchStatus={status}
            rerequestData={fetchData}
          />
        </div>
      </ChartBody>
      <ChartFooter rightAligned>
        <DemandForecastDataExporter
          forecastFilter={forecastFilter}
          setForecastFilter={setForecastFilter}
          forecastFilterTabs={forecastFilterTabs}
          downloadData={downloadFile}
        />
      </ChartFooter>
    </>
  );
};

export default DemandForecastChartContainer;
