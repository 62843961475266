import { getDisaggregatedBalancingServicesAdjustmentDetailsJsonData } from "api/dataVisualisation/disaggregatedBalancingServicesAdjustment/disaggregatedBalancingServicesAdjustment";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { DisaggregatedBalancingServicesAdjustmentDetailsModel } from "models/disaggregatedBalancingServicesAdjustment/disaggregatedBalancingServicesAdjustmentDetailsModel";
import { useCallback } from "react";
import { getSettlementDateAndPeriodFromSettlementTime } from "utils/DateUtils";

const useDisbsadDetailsData = (
  startTime?: Date
): {
  fetchData: () => void;
  data: DisaggregatedBalancingServicesAdjustmentDetailsModel[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchDisbsadDetailsData,
  } = useRequest(getDisaggregatedBalancingServicesAdjustmentDetailsJsonData);

  const fetchData = useCallback(() => {
    if (startTime) {
      const { settlementDate, settlementPeriod } =
        getSettlementDateAndPeriodFromSettlementTime(startTime);

      fetchDisbsadDetailsData(settlementDate, settlementPeriod);
    }
  }, [fetchDisbsadDetailsData, startTime]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useDisbsadDetailsData;
