import classnames from "classnames";
import useToggle from "hooks/useToggle";
import React from "react";

import {
  StyledButton,
  StyledCard,
  StyledDiv,
  StyledHeader,
  Subtitle,
  Title,
} from "./style";

interface Props {
  title: string;
  subtitle?: string;
  content: JSX.Element;
  classModifier?: string;
  expanderIconType?: ExpanderIconType;
}

// symbols refer to icons as font, specified in /src/assets/fonts/elexon-uat.ttf
const ExpanderIcon = {
  PlusMinus: { firstExpanderIcon: "*", secondExpanderIcon: "(" },
  Arrow: { firstExpanderIcon: "3", secondExpanderIcon: "o" },
} as const;

export enum ExpanderIconType {
  PlusMinus = "PlusMinus",
  Arrow = "Arrow",
}

const Expander: React.FC<Props> = ({
  title,
  subtitle,
  content,
  classModifier,
  expanderIconType = ExpanderIconType.PlusMinus,
}: Props) => {
  const [expanded, toggleExpanded] = useToggle(false);
  const { firstExpanderIcon, secondExpanderIcon } =
    ExpanderIcon[expanderIconType];
  return (
    <StyledCard className={classModifier}>
      <StyledHeader
        className="card-header"
        onClick={toggleExpanded}
        data-test-id="expander-header"
      >
        <Title
          className="card-header-title"
          data-test-id="expander-title-content"
        >
          {title}
        </Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <StyledButton
          type="button"
          className="card-header-icon"
          data-test-id="expander-button"
          aria-label={expanded ? "less information" : "more information"}
        >
          {expanded ? firstExpanderIcon : secondExpanderIcon}
        </StyledButton>
      </StyledHeader>
      <StyledDiv className={classnames({ expanded })}>
        <div className="content" data-test-id="expander-content">
          {content}
        </div>
      </StyledDiv>
    </StyledCard>
  );
};

export default Expander;
