import {
  DashedCircle,
  SolidDiamond,
  SolidCircle,
  SolidSquare,
  SolidLine,
  PentagonSvgWrapper,
} from "components/components/Swatch/style";
import React from "react";
import { PointShape, PentagonSvg } from "utils/ChartDrawUtils/pointShapes";

type BaseSwatchProps = {
  colour: string;
  dashed?: boolean;
};

export type SwatchProps = BaseSwatchProps & {
  shape?: PointShape;
};

const CircleSwatch: React.FC<BaseSwatchProps> = ({ colour, dashed }) =>
  dashed ? <DashedCircle colour={colour} /> : <SolidCircle colour={colour} />;

const SquareSwatch: React.FC<BaseSwatchProps> = ({ colour, dashed }) => {
  if (dashed) {
    throw new Error("Dashed square is not supported yet.");
  }
  return <SolidSquare colour={colour} />;
};

const DiamondSwatch: React.FC<BaseSwatchProps> = ({ colour, dashed }) => {
  if (dashed) {
    throw new Error("Dashed diamond is not supported yet.");
  }
  return <SolidDiamond colour={colour} />;
};

const LineSwatch: React.FC<BaseSwatchProps> = ({ colour, dashed }) => {
  if (dashed) {
    throw new Error("Dashed line is not supported yet.");
  }
  return <SolidLine colour={colour} />;
};

const PentagonSwatch: React.FC<BaseSwatchProps> = ({ colour, dashed }) => {
  if (dashed) {
    throw new Error("Dashed pentagon is not supported yet.");
  }
  return (
    <PentagonSvgWrapper>
      <PentagonSvg color={colour} size={11} />
    </PentagonSvgWrapper>
  );
};

const Swatch: React.FC<SwatchProps> = ({
  colour,
  dashed,
  shape = PointShape.Circle,
}) => {
  switch (shape) {
    case PointShape.Circle:
      return <CircleSwatch colour={colour} dashed={dashed} />;
    case PointShape.Square:
      return <SquareSwatch colour={colour} dashed={dashed} />;
    case PointShape.Diamond:
      return <DiamondSwatch colour={colour} dashed={dashed} />;
    case PointShape.None:
      return <LineSwatch colour={colour} dashed={dashed} />;
    case PointShape.Pentagon:
      return <PentagonSwatch colour={colour} dashed={dashed} />;
    default:
      throw new Error("Unsupported SwatchShape");
  }
};

export default Swatch;
