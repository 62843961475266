import { SwatchProps } from "components/components/Swatch/Swatch";
import styled, { css } from "styled-components";

const dashedDotUrlString = (hexColour: string): string => {
  const HEX_CODE_REGEX = /^#[A-Fa-f0-9]{6}$/;
  if (!hexColour.match(HEX_CODE_REGEX)) {
    throw new Error(
      "hexColour must be a valid 6-digit hexadecimal colour code"
    );
  }
  const digitsOnly = hexColour.substring(1);
  return (
    // Generated on: https://kovart.github.io/dashed-border-generator/
    "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect " +
    `width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23${digitsOnly}FF' stroke-width='4' ` +
    "stroke-dasharray='19.5%25' stroke-dashoffset='87' stroke-linecap='butt'/%3e%3c/svg%3e"
  );
};

const dotStyle = css`
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;
`;

export const SolidCircle = styled.span<SwatchProps>`
  ${dotStyle};
  background-color: ${(props: SwatchProps): string => props.colour};
`;

export const DashedCircle = styled.span<SwatchProps>`
  ${dotStyle};
  background-image: url("${(props: SwatchProps): string =>
    dashedDotUrlString(props.colour)}");
`;

const squareStyle = css`
  height: 10px;
  width: 10px;
  margin-right: 10px;
  display: inline-block;
`;

export const SolidSquare = styled.span<SwatchProps>`
  ${squareStyle};
  background-color: ${(props: SwatchProps): string => props.colour};
`;

export const SolidDiamond = styled.span<SwatchProps>`
  ${squareStyle};
  transform: rotateZ(45deg);
  background-color: ${(props: SwatchProps): string => props.colour};
`;

export const SolidLine = styled.span<SwatchProps>`
  ${squareStyle};
  height: 3px;
  margin-bottom: 3.5px;
  background-color: ${(props: SwatchProps): string => props.colour};
`;

export const PentagonSvgWrapper = styled.div`
  margin-right: 10px;
  display: inline-block;
  transform: rotateZ(180deg);
`;
