import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {
  CheckboxDataState,
  ChevronState,
} from "components/components/CheckboxList/CheckboxListContainer";
import { ListClickEvent, ListClickType } from "hooks/useCheckboxList";
import React, { PropsWithChildren } from "react";

import { StyledButton } from "./style";

const ChevronCheckboxButton: React.FC<
  PropsWithChildren<{
    onClick?: (clickEvent: ListClickEvent) => void;
    checkboxDataItem: CheckboxDataState;
  }>
> = ({ onClick, checkboxDataItem, children }) => (
  <>
    <StyledButton
      dataTestId="chevron-button"
      ariaLabel="Open menu"
      buttonText=""
      iconName={
        checkboxDataItem.chevronState === ChevronState.OPEN
          ? faChevronUp
          : faChevronDown
      }
      onClick={(): void =>
        onClick?.({ id: checkboxDataItem.id, clickType: ListClickType.CHEVRON })
      }
    />
    {checkboxDataItem.chevronState === ChevronState.OPEN ? children : <></>}
  </>
);

export default ChevronCheckboxButton;
