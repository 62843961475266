import styled from "styled-components";

export const PeakInfoGroup = styled.g`
  > text {
    font-size: 14px;

    &.bold {
      font-weight: bold;
    }
  }
`;
