import kinnectLogo from "assets/images/ElexonKinnectSymbolGreen.webp";
import logo from "assets/images/logo.webp";
import { AccountMenu } from "components/layout/AccountMenu/AccountMenu";
import React from "react";
import { Link } from "react-router-dom";
import featureFlags from "utils/featureFlags";
import routeConstants from "utils/routeConstants";

import {
  Logo,
  StyledLogoBanner,
  KinnectLogo,
  StyledSideLogo,
  AccountMenuContainer,
} from "./style";

interface Props {
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const LogoBanner: React.FC<Props> = ({ onClick }: Props) => (
  <StyledLogoBanner>
    <StyledSideLogo>
      <a
        href="https://www.elexon.co.uk/operations-settlement/bsc-central-services/elexon-kinnect-platform/"
        target="_blank"
        rel="noreferrer"
      >
        <KinnectLogo
          src={kinnectLogo}
          alt="Kinnect Logo"
          title="Elexon Kinnect Platform"
          data-test-id="kinnect-banner-logo"
        />
      </a>
    </StyledSideLogo>
    <Link to={routeConstants.home} onClick={onClick}>
      <Logo
        src={logo}
        alt="BMRS Logo"
        title="Insights Solution Homepage"
        data-test-id="bmrs-banner-logo"
      />
    </Link>
    <AccountMenuContainer>
      {featureFlags.accountArea && <AccountMenu />}
    </AccountMenuContainer>
  </StyledLogoBanner>
);

export default LogoBanner;
