import { getTotalLoadWeekAheadLatestChartData } from "api/dataVisualisation/totalLoadWeekAhead/totalLoadWeekAhead";
import DatasetLineChart from "components/dataVisualization/DatasetLineChart/DatasetLineChart";
import { ChartFooter } from "components/dataVisualization/chartComponents/style";
import TotalLoadWeekAheadDataExporter from "components/dataVisualization/totalLoadWeekAhead/TotalLoadWeekAheadDataExporter/TotalLoadWeekAheadDataExporter";
import TotalLoadWeekAheadFilters from "components/dataVisualization/totalLoadWeekAhead/TotalLoadWeekAheadFilters/TotalLoadWeekAheadFilters";
import {
  datasetCategories,
  getDateRangeFor,
  transformDataToSerie,
} from "components/dataVisualization/totalLoadWeekAhead/utils";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import React, { useEffect, useMemo, useState } from "react";
import {
  MAX_DAYS_IN_ONE_YEAR,
  addDaysToDate,
  restrictDateFilterToMaxDaysRange,
  startOfDay,
} from "utils/dateHelpers";

import { RightAlignedChartHeader } from "./style";
import { useTotalLoadWeekAheadData } from "./useTotalLoadWeekAheadData";

const TotalLoadWeekAheadChartContainer: React.FC = () => {
  const [forecastFilter, setForecastFilter] =
    useState<ForecastFilterOption | null>(ForecastFilterOption.weekAhead);

  const maxDateRange = MAX_DAYS_IN_ONE_YEAR;

  const today = startOfDay();
  const [defaultEndDate, setDefaultEndDate] = useState<Date>(
    addDaysToDate(today, 7)
  );
  const defaultDateFilter = new DateFilterModel(today, defaultEndDate);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const { data } = await getTotalLoadWeekAheadLatestChartData();
        setDefaultEndDate(getDateRangeFor(data).endDate);
      } catch {}
    })();
  }, []);

  const [dateFilter, setDateFilter] = useState<DateFilterModel | null>(
    defaultDateFilter
  );

  const {
    data: rawData,
    fetchData,
    status,
  } = useTotalLoadWeekAheadData(dateFilter, forecastFilter);

  useEffect(fetchData, [fetchData]);

  const chartData = useMemo(
    () => (rawData ? transformDataToSerie(rawData) : []),
    [rawData]
  );

  const dateFilterForDisplay =
    forecastFilter === ForecastFilterOption.weekAhead && rawData
      ? getDateRangeFor(rawData)
      : dateFilter;

  const handleChangeToForecastFilter = (
    newForecastFilter: ForecastFilterOption
  ): void => {
    setForecastFilter(newForecastFilter);
    setDateFilter(null);
  };

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel
  ): void => {
    setForecastFilter(null);
    const restrictedDateFilter = restrictDateFilterToMaxDaysRange(
      newDateFilter,
      maxDateRange
    );
    setDateFilter(restrictedDateFilter);
  };

  return (
    <>
      <RightAlignedChartHeader>
        <TotalLoadWeekAheadFilters
          forecastFilter={forecastFilter}
          handleChangeToForecastFilter={handleChangeToForecastFilter}
          dateFilterForDisplay={dateFilterForDisplay ?? defaultDateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          maximumDate={defaultEndDate}
          maxRange={maxDateRange}
        />
      </RightAlignedChartHeader>
      <DatasetLineChart
        series={chartData}
        datasetCategories={datasetCategories}
        dataFetchStatus={status}
        rerequestData={fetchData}
        xAxisTitle={"Forecast Date"}
        dateOnlyFormat={true}
      />
      <ChartFooter rightAligned>
        <TotalLoadWeekAheadDataExporter
          forecastFilter={forecastFilter}
          handleChangeToForecastFilter={handleChangeToForecastFilter}
          dateFilterForDisplay={dateFilterForDisplay ?? defaultDateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          maximumDate={defaultEndDate}
          maxRange={maxDateRange}
        />
      </ChartFooter>
    </>
  );
};

export default TotalLoadWeekAheadChartContainer;
