import TabModal, {
  ModalProps,
} from "components/components/balancingMechanism/balancingMechanismTabs/descriptionModals/TabModal";
import React from "react";

import { DescriptionSections } from "./style";

const DynamicModal: React.FC<ModalProps> = ({ isOpen, close }) => (
  <TabModal
    title="Dynamic Data"
    headingId="dynamic-modal-header"
    isOpen={isOpen}
    close={close}
  >
    <DescriptionSections>
      <dt>Notices</dt>
      <dd>
        Notice to Deviate from Zero expresses the notification time required for
        a BM Unit to change its consumption or production level from a zero PN
        level, as the result of a Bid-Offer Acceptance.
      </dd>
      <dd>
        Notice to Deliver Bids indicates the length of time between the issuing
        of a Bid-Offer Acceptance and the time when a BM Unit begins to deliver
        Bid volumes.
      </dd>
      <dd>
        Notice to Deliver Offers indicates the length of time between the
        issuing of a Bid-Offer Acceptance and the time when a BM Unit begins to
        deliver Offer volumes.
      </dd>

      <dt>Minimum Periods</dt>
      <dd>
        If action by the National Energy System Operator (i.e. a Bid-Offer
        Acceptance) places a BM Unit at such a level then the Minimum Zero Time
        (MZT) is the minimum time that either:
        <ol type="a">
          <li>
            BM Unit which has been exporting must operate at zero or import,
            before returning to export
          </li>
          <li>
            BM Unit which has been importing must operate at zero or export,
            returning to import
          </li>
        </ol>
      </dd>
      <dd>
        The Minimum Non-Zero Time (MNZT) represents the minimum time that a BM
        Unit can operate at a non-zero level as a result of a Bid-Offer
        Acceptance.
      </dd>

      <dt>Maximum Delivery</dt>
      <dd>
        Maximum Delivery Volume (MDV) is the maximum number of MW hours of Offer
        (or Bid), that a particular BM Unit may deliver within the associated
        Maximum Delivery Period.
      </dd>
      <dd>
        Maximum Delivery Period (MDP) is the time period over which the Maximum
        Delivery Volume can be delivered.
      </dd>

      <dt>Stable Limits</dt>
      <dd>
        Stable Export Limit (SEL) is a positive MW value, expressing the minimum
        stable operating level at which a particular BM Unit can export power to
        the transmission system.
      </dd>
      <dd>
        Stable Import Limit (SIL) is a negative MW value, expressing the minimum
        stable operating level at which a particular BM Unit can import power
        from the transmission system.
      </dd>

      <dt>Rates</dt>
      <dd>
        Run Up Rate(s) Export (RURE) express the rate of increase in active
        power production (MW/minute) for a particular BM Unit which is exporting
        power within a particular operating range. There can be up to three of
        these for a given BM Unit.
      </dd>
      <dd>
        Run Up Rate(s) Import (RURI) express the rate of decrease in active
        power consumption (MW/minute) for a particular BM Unit which is
        importing power within a particular operating range. There can be up to
        three of these for a given BM Unit.
      </dd>
      <dd>
        Run Down Rate(s) Import (RDRI) express the rate of increase of active
        power consumption (MW/minute) for a particular BM Unit which is
        importing power within a particular operating range. There can be up to
        three of these for a given BM Unit.
      </dd>
      <dd>
        Run Down Rate(s) Export (RDRE) express the rate of decrease in active
        power production (MW/minute) for a particular BM Unit which is exporting
        power within a particular operating range. There can be up to three of
        these for a given BM Unit.
      </dd>
    </DescriptionSections>
  </TabModal>
);

export default DynamicModal;
