import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "components/components/Button/Button";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import { SectionContainer } from "components/components/Iris/LoginSuccessModal/style";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import React, { useEffect, useState } from "react";
import colours from "styles/colours";
import {
  functionalityCookiesAreEnabled,
  irisCookieIsSet,
  setBooleanCookie,
  SHOW_IRIS_LOGIN_MODAL_COOKIE,
} from "utils/cookieHelper";

type Props = {
  registerRequest: () => void;
};

const LoginSuccessModal: React.FC<Props> = ({ registerRequest }) => {
  const consent = functionalityCookiesAreEnabled();

  const showModal = irisCookieIsSet();
  const [isActive, setIsActive] = useState(showModal);

  useEffect(() => {
    if (consent) {
      setBooleanCookie(SHOW_IRIS_LOGIN_MODAL_COOKIE, false);
    }
  }, [isActive, consent]);

  const onClickHandler = (): void => {
    setIsActive(false);
    registerRequest();
  };

  return (
    <Modal
      size={ModalSize.medium}
      isActive={isActive}
      onClose={(): void => setIsActive(false)}
    >
      <>
        <SectionContainer className="is-column">
          <Icon
            size={IconSize.large}
            colour={colours.elexonGreen}
            iconName={faCheckCircle}
          />
          <h2>Log in successful</h2>
          <p>You can now request your IRIS credentials</p>
          <hr />
        </SectionContainer>
        <SectionContainer className="is-row">
          <Button
            ariaLabel="Cancel"
            buttonText="cancel"
            className="cancel"
            onClick={(): void => {
              setIsActive(false);
            }}
          />
          <Button
            buttonText="Request IRIS credentials"
            onClick={onClickHandler}
          />
        </SectionContainer>
      </>
    </Modal>
  );
};

export default LoginSuccessModal;
