import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const ClientDetailsContainer = styled.div`
  &&& {
    position: relative;
    left: 0.75em;
    width: 100%;
    margin-bottom: 2em;
  }
`;

export const ClientDetailsRow = styled.div`
  border-bottom: 1px solid ${colours.mediumGrey};
  font-size: ${fonts.smallMedium};

  && {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0.3em;
    margin-bottom: 0.6em;
  }

  .is-child:last-child {
    display: flex;
    justify-content: flex-end;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-left: 0;
  }
`;
