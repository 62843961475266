import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Button from "components/components/Button/Button";
import FileTypeSelector from "components/components/FileTypeSelector/FileTypeSelector";
import ForecastFilterSelector from "components/components/ForecastFilterSelector/ForecastFilterSelector";
import InformationTooltip from "components/components/InformationTooltip/InformationTooltip";
import {
  TooltipLabel,
  TooltipLine,
  HeaderWithTooltipContainer,
} from "components/components/InformationTooltip/style";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import {
  ModalButtonContainer,
  ModalHeader,
  ModalPadding,
  ModalSection,
  ModalSubHeader,
} from "components/components/Modal/commonStyle";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import React, { useState } from "react";

interface Props {
  downloadData: (fileFormat: DataDownloadFormat) => void;
  forecastFilter: ForecastFilterOption;
  handleChangeToForecastFilter: (
    newForecastFilter: ForecastFilterOption
  ) => void;
}

const SurplusAndMarginDataExporter: React.FC<Props> = ({
  downloadData,
  forecastFilter,
  handleChangeToForecastFilter,
}: Props) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState(DataDownloadFormat.Csv);

  const handleExportClick = (): void => {
    downloadData(downloadFormat);

    setIsModalActive(false);
  };

  return (
    <>
      <div data-test-id="export-data-open-button-container">
        <Button
          ariaLabel="Export"
          buttonText="export"
          iconName={faDownload}
          onClick={(): void => setIsModalActive(true)}
        />
      </div>
      <Modal
        size={ModalSize.large}
        isActive={isModalActive}
        onClose={(): void => setIsModalActive(false)}
        closeOnEsc={false} // TODO: enable this to be escaped see ticket #52645
        headingId="export-modal-header"
      >
        <ModalPadding data-test-id="export-data-modal">
          <ModalSection>
            <ModalHeader id="export-modal-header">
              Select the data you want to export
            </ModalHeader>
          </ModalSection>
          <ModalSection>
            <div>
              <HeaderWithTooltipContainer>
                <ModalSubHeader>Time frame</ModalSubHeader>
                <InformationTooltip>
                  <>
                    <TooltipLine>
                      <TooltipLabel>Surplus forecast data sets:</TooltipLabel>
                    </TooltipLine>
                    <TooltipLine>2-14 days ahead: OCNMFD</TooltipLine>
                    <TooltipLine>2-156 weeks ahead: OCNMF3Y</TooltipLine>
                    <TooltipLine>
                      <TooltipLabel>
                        Generating plant demand margin data sets:
                      </TooltipLabel>
                    </TooltipLine>
                    <TooltipLine>2-14 days ahead: OCNMFD2</TooltipLine>
                    <TooltipLine>2-156 weeks ahead: OCNMF3Y2</TooltipLine>
                  </>
                </InformationTooltip>
              </HeaderWithTooltipContainer>
              <ForecastFilterSelector
                forecastFilter={forecastFilter}
                handleChangeToForecastFilter={handleChangeToForecastFilter}
              />
            </div>
            <div>
              <ModalSubHeader>File type</ModalSubHeader>
              <FileTypeSelector
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
              />
            </div>
          </ModalSection>
          <hr />
          <ModalSection className="centre">
            <ModalButtonContainer data-test-id="export-data-button-container">
              <Button
                ariaLabel="Cancel"
                buttonText="cancel"
                className="cancel"
                onClick={(): void => setIsModalActive(false)}
              />
              <Button
                ariaLabel="Export"
                buttonText="export"
                onClick={handleExportClick}
              />
            </ModalButtonContainer>
          </ModalSection>
        </ModalPadding>
      </Modal>
    </>
  );
};

export default SurplusAndMarginDataExporter;
