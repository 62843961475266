interface Props {
  count: number;
}

const ResultsCount: React.FC<Props> = ({ count }) => (
  <h3>
    {count.toLocaleString("en-GB")} result{count === 1 ? "" : "s"}
  </h3>
);

export default ResultsCount;
