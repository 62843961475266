import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import Icon from "components/components/Icon/Icon";
import React, { useEffect, useRef } from "react";
import colours from "styles/colours";

import { StyledExitCross } from "./style";

interface Props {
  onClick: () => void;
  focusOnMount?: boolean;
}

const ExitCross: React.FC<Props> = ({ onClick, focusOnMount }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focusOnMount) {
      buttonRef?.current?.focus();
    }
  }, [buttonRef, focusOnMount]);
  return (
    <StyledExitCross>
      <button
        ref={buttonRef}
        type="button"
        className="button"
        onClick={onClick}
        aria-label="Exit"
        data-test-id="exit-cross"
      >
        <Icon iconName={faTimes} colour={colours.elexonBlack} />
      </button>
    </StyledExitCross>
  );
};

export default ExitCross;
