import styled from "styled-components";

export const ComponentPopover = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  display: grid;
  place-items: center;
  text-align: center;
`;
