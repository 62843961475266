import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { TabulatedChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/TabulatedChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayers } from "styles/chartStyles/chartLayers";
import { defaultColumnFormatter } from "utils/ChartDataUtils";
import { DateFormat } from "utils/dateHelpers";

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  rerequestData: () => void;
  dataFetchStatus: RequestStatus;
}

const SurplusAndMarginChart: React.FC<Props> = ({
  series,
  datasetCategories,
  rerequestData,
  dataFetchStatus,
}: Props) =>
  useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    const config: ChartConfiguration = {
      datasetCategories,
      tooltipColumns: [
        {
          id: "value",
          dataKey: "y",
          formatter: defaultColumnFormatter({ suffix: "MW" }),
        },
      ],
    };

    return (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      >
        <ResponsiveLine
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...commonChartProps(isChartLoaded, series)}
          enableSlices="x"
          axisBottom={chartAxisBottom("Settlement Date", series, isChartLoaded)}
          axisLeft={chartAxisLeft("MW", isChartLoaded)}
          layers={chartLayers}
          sliceTooltip={(input): JSX.Element => (
            <TabulatedChartTooltip
              series={series}
              config={config}
              text={{
                subtitle: "Capacity",
                label: "Settlement Date",
              }}
              options={{
                interactionType: TooltipInteractionType.Slice,
                dateFormat: DateFormat.DateOnly,
              }}
              inputPoints={input.slice.points}
            />
          )}
          xScale={chartXTimeScale()}
          yScale={nonStackedLineChartYScale(series)}
        />
      </ChartWithErrorHandling>
    );
  }, [dataFetchStatus, datasetCategories, rerequestData, series]);

export default SurplusAndMarginChart;
