import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import RectangleSliceProvider from "components/dataVisualization/chartComponents/RectangleSlice/RectangleSliceProvider";
import { RectangleSliceTooltipData } from "components/dataVisualization/chartComponents/RectangleSlice/RectangleSlicesLayer";
import { RequestStatus } from "hooks/useRequest";
import {
  DatasetCategory,
  ChartDatasetModel,
  combineDatasetCategories,
} from "models/chartConfiguration/chartDatasetModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScaleWithDumbbellPadding,
  commonChartProps,
  modifyChartMargin,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayersAreasWithCustomLines } from "styles/chartStyles/chartLayers";
import { chartColours } from "styles/colours";

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  rerequestData: () => void;
  dateFilter: DateFilterModel;
  dataFetchStatus: RequestStatus;
  rectangleSliceData?: RectangleSliceTooltipData;
  setRectangleSliceData: (
    newRectangleSliceTooltipData?: RectangleSliceTooltipData
  ) => void;
}

const VolumeAdjustmentChart: React.FC<Props> = ({
  series,
  datasetCategories,
  rerequestData,
  dateFilter,
  dataFetchStatus,
  rectangleSliceData,
  setRectangleSliceData,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;
    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        enableArea
        axisBottom={chartAxisBottom("Start Time (UTC)", series, isChartLoaded)}
        axisLeft={chartAxisLeft("MWh", isChartLoaded, 5)}
        gridYValues={5}
        layers={chartLayersAreasWithCustomLines(
          combineDatasetCategories(datasetCategories).filter(
            (x) => x.dataKey === "netVolumeAdjustment"
          )
        )}
        areaOpacity={0.7}
        // VolumeAdjustmentChart does not have dumbbells of its own, however
        // we add padding to align with PriceAdjustmentChart which does have dumbbells
        xScale={chartXTimeScaleWithDumbbellPadding(dateFilter)}
        yScale={nonStackedLineChartYScale(series, { minimumMax: 10 })}
        margin={modifyChartMargin({ top: 20 })}
        // Adds reference line at 0
        markers={
          series.length && isChartLoaded
            ? [
                {
                  axis: "y",
                  value: 0,
                  lineStyle: {
                    stroke: chartColours.referenceLine,
                  },
                },
              ]
            : []
        }
      />
    );
  }, [dataFetchStatus, datasetCategories, series, dateFilter]);

  return (
    <ChartWithErrorHandling
      series={series}
      datasetCategories={datasetCategories}
      dataFetchStatus={dataFetchStatus}
      rerequestData={rerequestData}
      containerHeight={330}
    >
      {series.length ? (
        <RectangleSliceProvider
          rectangleSliceData={rectangleSliceData}
          setRectangleSliceData={setRectangleSliceData}
          showSlices={false}
          idPrefix="volume"
        >
          {memoisedChart}
        </RectangleSliceProvider>
      ) : (
        <>{memoisedChart}</>
      )}
    </ChartWithErrorHandling>
  );
};

export default VolumeAdjustmentChart;
