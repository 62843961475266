import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const StyledBreadcrumb = styled.nav`
  font-size: ${fonts.small};

  a {
    padding-right: 0;
    color: ${colours.elexonBlack};

    &:hover {
      color: ${colours.elexonBlack};
      text-decoration: underline;
    }
  }
`;

export const StyledSection = styled.div`
  padding-top: 0;
  padding-bottom: 0;

  &.is-fluid {
    margin-left: -32px;
  }
`;
