import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import { DisaggregatedBalancingServicesAdjustmentDetailsModel } from "models/disaggregatedBalancingServicesAdjustment/disaggregatedBalancingServicesAdjustmentDetailsModel";
import { DisaggregatedBalancingServicesAdjustmentSummaryModel } from "models/disaggregatedBalancingServicesAdjustment/disaggregatedBalancingServicesAdjustmentSummaryModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { toDateOnlyString } from "utils/dateHelpers";

export const getDisaggregatedBalancingServicesAdjustmentSummaryData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<
  DataDownloadModel<DisaggregatedBalancingServicesAdjustmentSummaryModel>
> => {
  const { data } = await getData(
    "/balancing/nonbm/disbsad/summary",
    {
      from: dateFilter.startDate,
      to: dateFilter.endDate,
    },
    format
  );
  return data;
};

export const getDisaggregatedBalancingServicesAdjustmentSummaryJsonData = (
  dateFilter: DateFilterModel
): Promise<
  JsonDataDownloadModel<DisaggregatedBalancingServicesAdjustmentSummaryModel>
> =>
  getDisaggregatedBalancingServicesAdjustmentSummaryData(
    dateFilter,
    DataDownloadFormat.Json
  ) as Promise<
    JsonDataDownloadModel<DisaggregatedBalancingServicesAdjustmentSummaryModel>
  >;

export const getDisaggregatedBalancingServicesAdjustmentDetailsData = async (
  settlementDate: Date,
  settlementPeriod: number,
  format?: DataDownloadFormat
): Promise<
  DataDownloadModel<DisaggregatedBalancingServicesAdjustmentDetailsModel>
> => {
  const { data } = await getData(
    "/balancing/nonbm/disbsad/details",
    {
      settlementDate: toDateOnlyString(settlementDate),
      settlementPeriod,
    },
    format
  );
  return data;
};

export const getDisaggregatedBalancingServicesAdjustmentDetailsJsonData = (
  settlementDate: Date,
  settlementPeriod: number
): Promise<
  JsonDataDownloadModel<DisaggregatedBalancingServicesAdjustmentDetailsModel>
> =>
  getDisaggregatedBalancingServicesAdjustmentDetailsData(
    settlementDate,
    settlementPeriod
  ) as Promise<
    JsonDataDownloadModel<DisaggregatedBalancingServicesAdjustmentDetailsModel>
  >;
