import styled from "styled-components";
import colours from "styles/colours";

export const StyledTileChild = styled.article`
  background-color: ${colours.white};
  border-top: 8px solid ${colours.elexonBlue};
  box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`;

export const MiniStyledTileChild = styled(StyledTileChild)`
  padding: 1rem;
`;
