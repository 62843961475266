import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { StandardChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/StandardChartTooltip";
import {
  AxisDirectionLabelBottom,
  AxisDirectionLabelTop,
} from "components/dataVisualization/chartComponents/style";
import { RequestStatus } from "hooks/useRequest";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayersWithReferenceLine } from "styles/chartStyles/chartLayers";
import { chartColours } from "styles/colours";

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  sendHighlightedDataset: (newSelectedDataset: string) => void;
  dataFetchStatus: RequestStatus;
  rerequestData: () => void;
}

const InterconnectorFlowsChart: React.FC<Props> = ({
  series,
  datasetCategories,
  sendHighlightedDataset,
  dataFetchStatus,
  rerequestData,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;
    return (
      <ResponsiveLine
        {...commonChartProps(isChartLoaded, series)}
        axisBottom={chartAxisBottom("Start Time (UTC)", series, isChartLoaded)}
        axisLeft={chartAxisLeft("MW", isChartLoaded)}
        layers={chartLayersWithReferenceLine(0, chartColours.referenceLine)} // 0 MW reference line
        onMouseLeave={(): void => sendHighlightedDataset("")}
        onMouseMove={(point): void =>
          sendHighlightedDataset(point.id.split(".")[0])
        }
        tooltip={(input): JSX.Element => (
          <StandardChartTooltip
            series={series}
            datasetCategories={datasetCategories}
            text={{
              label: "Start Time",
              totalUnitSuffix: "MW",
            }}
            options={{
              interactionType: TooltipInteractionType.Voronoi,
              showTotal: true,
              highlightSelectedDataset: true,
            }}
            inputPoints={[input.point]}
          />
        )}
        xScale={chartXTimeScale()}
        yScale={nonStackedLineChartYScale(series, { centre: 0 })}
        useMesh
      />
    );
  }, [dataFetchStatus, series, sendHighlightedDataset, datasetCategories]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      >
        <>
          {memoisedChart}
          <AxisDirectionLabelTop>Importing</AxisDirectionLabelTop>
          <AxisDirectionLabelBottom>Exporting</AxisDirectionLabelBottom>
        </>
      </ChartWithErrorHandling>
    ),
    [dataFetchStatus, datasetCategories, memoisedChart, rerequestData, series]
  );
};
export default InterconnectorFlowsChart;
