import {
  getMarginForecastDailyJsonData,
  getMarginForecastWeeklyJsonData,
} from "api/dataVisualisation/marginForecast/marginForecast";
import {
  getSurplusForecastDailyJsonData,
  getSurplusForecastWeeklyJsonData,
} from "api/dataVisualisation/surplusForecast/surplusForecast";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { JsonDataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import { TimeRange } from "models/filterModels/timeRange";
import { MarginForecastDaily } from "models/surplusAndMargin/marginDailyModel";
import { MarginForecastWeekly } from "models/surplusAndMargin/marginWeeklyModel";
import { SurplusForecastDaily } from "models/surplusAndMargin/surplusDailyModel";
import { SurplusForecastWeekly } from "models/surplusAndMargin/surplusWeeklyModel";
import { useCallback } from "react";
import { combineRequestStatuses } from "utils/requestHelpers";

const useSurplusAndMarginChartContainerData = (
  forecastFilter: ForecastFilterOption
): {
  fetchData: () => void;
  data: {
    surplus: SurplusForecastDaily[] | SurplusForecastWeekly[];
    margin: MarginForecastDaily[] | MarginForecastWeekly[];
  } | null;
  status: RequestStatus;
} => {
  const getSurplusRequest = useCallback(
    (
      newForecastFilter: ForecastFilterOption
    ): Promise<
      | JsonDataDownloadModel<SurplusForecastDaily>
      | JsonDataDownloadModel<SurplusForecastWeekly>
    > => {
      switch (newForecastFilter) {
        case ForecastFilterOption.twoToFiftyTwoWeek:
          return getSurplusForecastWeeklyJsonData(TimeRange.FiftyTwoWeeks);
        case ForecastFilterOption.twoToOneFiveSixWeek:
          return getSurplusForecastWeeklyJsonData(
            TimeRange.OneHundredAndFiftySixWeeks
          );
        case ForecastFilterOption.twoToFourteenDay:
        default:
          return getSurplusForecastDailyJsonData();
      }
    },
    []
  );

  const getMarginRequest = useCallback(
    (
      newForecastFilter: ForecastFilterOption
    ): Promise<
      | JsonDataDownloadModel<MarginForecastDaily>
      | JsonDataDownloadModel<MarginForecastWeekly>
    > => {
      switch (newForecastFilter) {
        case ForecastFilterOption.twoToFiftyTwoWeek:
          return getMarginForecastWeeklyJsonData(TimeRange.FiftyTwoWeeks);
        case ForecastFilterOption.twoToOneFiveSixWeek:
          return getMarginForecastWeeklyJsonData(
            TimeRange.OneHundredAndFiftySixWeeks
          );
        case ForecastFilterOption.twoToFourteenDay:
        default:
          return getMarginForecastDailyJsonData();
      }
    },
    []
  );

  const {
    data: surplusForecastData,
    status: surplusForecastStatus,
    request: fetchSurplusForecastData,
  } = useRequest(getSurplusRequest);

  const {
    data: marginForecastData,
    status: marginForecastStatus,
    request: fetchMarginForecastData,
  } = useRequest(getMarginRequest);

  const data =
    surplusForecastData?.data && marginForecastData?.data
      ? {
          surplus: surplusForecastData.data,
          margin: marginForecastData.data,
        }
      : null;

  const status = combineRequestStatuses(
    surplusForecastStatus,
    marginForecastStatus
  );

  const fetchData = useCallback(() => {
    fetchSurplusForecastData(forecastFilter);
    fetchMarginForecastData(forecastFilter);
  }, [forecastFilter, fetchSurplusForecastData, fetchMarginForecastData]);

  return {
    data,
    status,
    fetchData,
  };
};

export default useSurplusAndMarginChartContainerData;
