import { getData } from "api/dataVisualisation/client";
import { Granularity } from "models/Granularity";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import { IndicatedForecastDayAhead } from "models/indicatedForecast/indicatedForecastDayAhead";

export const getIndicatedForecastDayAheadData = async (
  boundary?: string,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<IndicatedForecastDayAhead>> => {
  const { data } = await getData(
    "/forecast/indicated/day-ahead",
    {
      boundary,
    },
    format
  );
  return data;
};

export const getIndicatedForecastDayAheadNationalJsonData = async (): Promise<
  JsonDataDownloadModel<IndicatedForecastDayAhead>
> =>
  getIndicatedForecastDayAheadData(
    Granularity.National,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<IndicatedForecastDayAhead>>;
