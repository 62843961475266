import Button from "components/components/Button/Button";
import styled from "styled-components";
import colours from "styles/colours";

export const ButtonsDiv = styled.div`
  display: flex;
  width: 50%;
  margin-left: 25%;
  justify-content: space-around;
`;

export const ContentContainer = styled.div`
  margin-top: 0.75em;
  margin-left: 5em;
  margin-right: 5em;
  text-align: center;
  justify-content: space-around;

  > h2 {
    margin-bottom: 1.5rem;
  }

  > p {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }

  > hr {
    margin-top: 3.75em;
  }
`;

export const DeleteButton = styled(Button)`
  color: ${colours.elexonRed};
  background-color: transparent;
  font-weight: normal;
  text-transform: none;

  :hover {
    color: ${colours.elexonRed};
  }

  :active {
    box-shadow: none;
  }

  :focus {
    color: ${colours.elexonRed};
  }
`;
