import { getMarketIndexPricesDownloadData } from "api/dataVisualisation/marketIndexPrices/marketIndexPrices";
import Switch from "components/components/Switch/Switch";
import {
  ChartDataToggleContainer,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import MarketIndexPricesChart from "components/dataVisualization/marketIndexPrices/MarketIndexPricesChart/MarketIndexPricesChart";
import { useDataExport } from "contexts/DataExportContext";
import useToggle from "hooks/useToggle";
import {
  combineDatasetCategories,
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import { DataProviders } from "models/marketIndexPrices/marketIndexPricesModel";
import { useEffect, useMemo, useState } from "react";
import { chartColours } from "styles/colours";
import { sortByStartTime } from "utils/ChartDataUtils";
import { transformMarketIndexPriceData } from "utils/ChartDataUtils/marketIndexPrices/marketIndexPrices";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  addHoursToDate,
  ceilToHalfHour,
  DAYS_IN_ONE_WEEK,
} from "utils/dateHelpers";

import useMarketIndexPricesChartContainerData from "./useMarketIndexPricesChartContainerData";

const MarketIndexPricesChartContainer: React.FC = () => {
  const defaultEndDate = ceilToHalfHour(new Date());
  const [showApx, toggleApx] = useToggle(true);
  const [showN2ex, toggleN2ex] = useToggle(true);

  const datasetCategories: DatasetCategory<ChartDatasetModel>[] = useMemo(
    () => [
      {
        datasets: [
          {
            colour: chartColours.marketIndexPricesApx,
            dataKey: DataProviders.Apx,
            displayName: "APX",
            isHidden: !showApx,
          },
          {
            colour: chartColours.marketIndexPricesN2ex,
            dataKey: DataProviders.N2ex,
            displayName: "N2EX",
            isHidden: !showN2ex,
          },
        ],
        id: "datasets",
      },
    ],
    [showApx, showN2ex]
  );

  const dateSelectorTabs = [
    DateSelectorTabs.TwentyFourHours,
    DateSelectorTabs.OneWeek,
  ];

  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addHoursToDate(defaultEndDate, -24), defaultEndDate)
  );

  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.TwentyFourHours
  );

  const { dispatch: dataExportCountDispatch } = useDataExport();

  const {
    data: rawData,
    fetchData,
    status,
  } = useMarketIndexPricesChartContainerData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const sortedData = useMemo(
    () => (rawData ? sortByStartTime(rawData) : []),
    [rawData]
  );

  const chartData = useMemo(
    () =>
      sortedData.length
        ? transformMarketIndexPriceData(
            sortedData,
            combineDatasetCategories(datasetCategories)
          )
        : [],
    [datasetCategories, sortedData]
  );

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    setDateFilter(newDateFilter.restrictToMaxOneWeek());
    setActiveDateSelectorTab(newActiveTab);
  };

  const downloadData = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getMarketIndexPricesDownloadData(dateFilter, fileFormat),
      `MarketIndexPrices-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  return (
    <>
      <ChartHeader>
        <ChartDataToggleContainer data-test-id="chart-dataset-toggle-container">
          <Switch
            name="apx-data"
            onColor={chartColours.marketIndexPricesApx}
            onClick={toggleApx}
            switchedOn={showApx}
            leftLabel="APX"
            headerLabel
          />
          <Switch
            name="n2ex-data"
            onColor={chartColours.marketIndexPricesN2ex}
            onClick={toggleN2ex}
            switchedOn={showN2ex}
            leftLabel="N2EX"
            headerLabel
          />
        </ChartDataToggleContainer>
        <ChartFilter
          dateSelectorTabs={dateSelectorTabs}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={DAYS_IN_ONE_WEEK}
        />
      </ChartHeader>
      <MarketIndexPricesChart
        series={chartData}
        datasetCategories={datasetCategories}
        rerequestData={fetchData}
        dataFetchStatus={status}
      />
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadData}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
        />
      </ChartFooter>
    </>
  );
};

export default MarketIndexPricesChartContainer;
