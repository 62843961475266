import { Link } from "react-router-dom";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import colors from "styles/colours";
import fonts from "styles/fonts";

const expandedButtonStyle = css`
  background-color: ${colors.white};
  color: ${colors.elexonBlue};
  border-radius: 16px 16px 0 0;
  border: none;
`;

const collapsedButtonStyle = css`
  background-color: transparent;
  color: ${colors.white};
  border: ${colors.white} solid 1px;
  border-radius: 16px;

  &:hover {
    background-color: ${colors.white};
    color: ${colors.elexonBlue};
  }
`;

export const StyledNavButton = styled.button`
  min-width: 80px;
  height: 2rem;
  cursor: pointer;

  &:active {
    box-shadow: inset 2px 2px 4px rgba(0, 0, 140, 0.25);
  }

  ${({ "aria-expanded": expanded }): FlattenSimpleInterpolation =>
    expanded ? expandedButtonStyle : collapsedButtonStyle};
`;

export const DropdownContainer = styled.div`
  position: absolute;
  width: 100%;
`;

const MenuItemStyle = css`
  background-color: ${colors.white};
  display: block;
  margin: 1px 0;
  padding: 8px;
  width: 100%;
  cursor: pointer;

  border: none;

  text-align: center;
  line-height: 1em;
  color: ${colors.darkGrey};
  font-size: ${fonts.small};

  &:last-child {
    border-radius: 0 0 16px 16px;
  }

  &:hover {
    background-color: ${colors.elexonGreen};
  }
`;

export const MenuItemLink = styled(Link)`
  ${MenuItemStyle}
`;

export const MenuItemButton = styled.button`
  ${MenuItemStyle}
`;
