import { PropsWithChildren } from "react";

import { StyledSection } from "./style";

interface ChartSectionProps {
  className?: string;
  id?: string;
  rightAligned?: boolean;
}

const ChartSection: React.FC<PropsWithChildren<ChartSectionProps>> = ({
  className,
  id,
  rightAligned,
  children,
}) => (
  <StyledSection
    data-test-id="chart-section"
    rightAligned={rightAligned}
    id={id}
    className={className}
  >
    {children}
  </StyledSection>
);

export default ChartSection;
