import CheckboxListContainer, {
  CheckboxState,
} from "components/components/CheckboxList/CheckboxListContainer";
import {
  ModalSection,
  ModalSubHeader,
} from "components/components/Modal/commonStyle";
import { DerivedDataTabName } from "components/components/Tabs/Tabs/Tabs";
import { checkboxDataGroups } from "components/dataVisualization/CheckboxDataExporter/utils";
import DataExporterContainer from "components/dataVisualization/dataExporterComponents/DataExporterContainer/DataExporterContainer";
import DataExporterHeader from "components/dataVisualization/dataExporterComponents/dataExporterSections/DataExporterHeader";
import FileFormatSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/FileFormatSection";
import { useDataExport } from "contexts/DataExportContext";
import useCheckboxList from "hooks/useCheckboxList";
import { BmuModel } from "models/bmuData/bmuModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import { getSettlementDateAndPeriodFromSettlementTime } from "utils/DateUtils";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

import {
  DerivedDataCheckboxGroups,
  transformPerDerivedDataDownloadCallbacks,
} from "./utils";

interface Props {
  selectedDate: Date;
  bmus?: BmuModel[];
  activeTab: DerivedDataTabName;
}

export const DerivedDataExporter: React.FC<Props> = ({
  selectedDate,
  bmus,
}) => {
  const { settlementDate, settlementPeriod } =
    getSettlementDateAndPeriodFromSettlementTime(selectedDate);

  const fileSuffix = `${selectedDate.toISOString()}`;
  const { dispatch: dataExportCountDispatch } = useDataExport();
  const [downloadFormat, setDownloadFormat] = useState(DataDownloadFormat.Json);

  const [checkboxDataStates, registerClickForListItem] = useCheckboxList(
    checkboxDataGroups(DerivedDataCheckboxGroups),
    true
  );
  const downloadCallbacks = useMemo(() => {
    const selectedDatasets = [
      ...new Set(
        checkboxDataStates
          .filter(
            (checkbox) => checkbox.checkboxState === CheckboxState.CHECKED
          )
          .flatMap((checkbox) => checkbox.datasets ?? [])
      ),
    ];

    return transformPerDerivedDataDownloadCallbacks(
      selectedDatasets,
      selectedDate,
      bmus
    );
  }, [bmus, checkboxDataStates, selectedDate]);

  const downloadData = (): void => {
    downloadCallbacks.forEach((c) => {
      downloadDataToFile(
        () => c.download(downloadFormat),
        `${c.filePrefix}-${fileSuffix}`,
        downloadFormat,
        dataExportCountDispatch
      );
    });
  };

  const [searchParams] = useSearchParams();
  const leadParty = searchParams.get("leadParty");
  const bmuType = searchParams.get("bmuType");
  return (
    <DataExporterContainer
      onExportClick={downloadData}
      exportDisabled={downloadCallbacks.length === 0}
    >
      <DataExporterHeader />
      <ModalSection>
        <ModalSubHeader>Settlement Period</ModalSubHeader>
      </ModalSection>
      {`${formatDateTimeString(
        settlementDate,
        DateFormat.DateOnly
      )} - (SP ${settlementPeriod})`}
      {(leadParty || bmuType) && (
        <>
          <ModalSection>
            <ModalSubHeader>Filters:</ModalSubHeader>
          </ModalSection>
          <p>{leadParty && `Lead Party Name: ${leadParty}`}</p>
          <p>{bmuType && `BMU Type: ${bmuType}`}</p>
        </>
      )}

      <ModalSection>
        <div>
          <ModalSubHeader>Datasets</ModalSubHeader>
          <CheckboxListContainer
            checkboxDataStates={checkboxDataStates}
            registerClickForListItem={registerClickForListItem}
          />
        </div>
      </ModalSection>
      <ModalSection>
        <FileFormatSection
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
          showCsvDownload={false}
        />
      </ModalSection>
    </DataExporterContainer>
  );
};
