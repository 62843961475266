import { getDemandForecastDayAheadNationalJsonData } from "api/dataVisualisation/demandForecast/demandForecast";
import { getIndicatedForecastDayAheadNationalJsonData } from "api/dataVisualisation/indicatedForecast/indicatedForecast";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { DemandForecastDayAhead } from "models/demandForecast/demandForecastDayAhead";
import { IndicatedForecastDayAhead } from "models/indicatedForecast/indicatedForecastDayAhead";
import { useCallback } from "react";
import { combineRequestStatuses } from "utils/requestHelpers";

const useIndicatedForecastsChartContainerData = (): {
  data: {
    indicatedForecastsData: IndicatedForecastDayAhead[];
    nationalDemandForecastData: DemandForecastDayAhead[];
  } | null;
  status: RequestStatus;
  fetchData: () => void;
} => {
  const {
    data: indicatedForecastData,
    status: indicatedForecastStatus,
    request: fetchIndicatedForecastData,
  } = useRequest(getIndicatedForecastDayAheadNationalJsonData);

  const {
    data: demandForecastData,
    status: demandForecastStatus,
    request: fetchDemandForecastData,
  } = useRequest(getDemandForecastDayAheadNationalJsonData);

  const fetchData = useCallback(() => {
    fetchIndicatedForecastData();
    fetchDemandForecastData();
  }, [fetchIndicatedForecastData, fetchDemandForecastData]);

  const data =
    indicatedForecastData && demandForecastData
      ? {
          indicatedForecastsData: indicatedForecastData.data,
          nationalDemandForecastData: demandForecastData.data,
        }
      : null;

  const status = combineRequestStatuses(
    indicatedForecastStatus,
    demandForecastStatus
  );

  return {
    data,
    status,
    fetchData,
  };
};

export default useIndicatedForecastsChartContainerData;
