import styled from "styled-components";
import colours from "styles/colours";

export const StyledTopMenuLink = styled.li`
  border: 0px;
  width: auto;
  height: auto;

  a {
    color: ${colours.white};
    font-size: 10px;
    border: 0px;
    background-color: transparent;
    padding: 0px;
    line-height: 1;
    height: auto;
    padding: 2px 10px;
    font-weight: 400;
    letter-spacing: 1.85px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledTopMenu = styled.ul`
  background-color: ${colours.darkGrey};
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0px 15px;
`;
