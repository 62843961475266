import { ValueFormatter } from "@nivo/axes";
import { Serie } from "@nivo/line";
import { TicksSpec } from "@nivo/scales";
import { utcFormat } from "d3-time-format";
import {
  getXTimeValuesExtrema,
  tickArrangementsByDuration,
} from "utils/chartHelpers";
import { getDifferenceInMs } from "utils/dateHelpers";

export interface AxisTickArrangement {
  tickValues: TicksSpec<Date>;
  format: ValueFormatter<Date>;
}

export const getChartDurationInMs = (series: Serie[]): number | undefined => {
  const nonEmptySeries = series.filter(
    (serie) => serie.data && serie.data.length > 0
  );

  if (nonEmptySeries.length === 0) {
    return undefined;
  }

  const { minXAxisValue, maxXAxisValue } = getXTimeValuesExtrema(series);

  return getDifferenceInMs(minXAxisValue, maxXAxisValue);
};

const emptyTickArrangement: AxisTickArrangement = {
  tickValues: [],
  format: utcFormat(""),
};

export const getTickArrangementForChartPeriod = (
  chartDurationInMs: number
): AxisTickArrangement =>
  tickArrangementsByDuration.find(
    (option) => chartDurationInMs >= option.minDurationInMs
  )?.arrangement ?? emptyTickArrangement;

export const getTickArrangementForChartSeries = (
  series: Serie[],
  isChartLoaded: boolean
): AxisTickArrangement => {
  if (!isChartLoaded) {
    return emptyTickArrangement;
  }
  const chartDurationInMs = getChartDurationInMs(series);

  return chartDurationInMs
    ? getTickArrangementForChartPeriod(chartDurationInMs)
    : emptyTickArrangement;
};
