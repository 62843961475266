import { getRemitMessageDetailsDownloadData } from "api/remit/remit";
import {
  FlexContainerColumn,
  ModalSection,
  ModalSubHeader,
} from "components/components/Modal/commonStyle";
import RadioButtonsRow from "components/components/RadioInput/RadioButtonsRow";
import DataExporterContainer from "components/dataVisualization/dataExporterComponents/DataExporterContainer/DataExporterContainer";
import DataExporterHeader from "components/dataVisualization/dataExporterComponents/dataExporterSections/DataExporterHeader";
import FileFormatSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/FileFormatSection";
import {
  generateRevisionOption,
  getMessageDataById,
} from "components/remit/remitMessagePageHelpers";
import { useDataExport } from "contexts/DataExportContext";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import RemitMessageDetailsModel from "models/remit/remitMessageDetailsModel";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";

interface Props {
  details: RemitMessageDetailsModel[];
  viewedRevision: RemitMessageDetailsModel;
  latestRevision: RemitMessageDetailsModel;
}

const RemitDataExporter: React.FC<Props> = ({
  details,
  viewedRevision,
  latestRevision,
}) => {
  const { dispatch: dataExportCountDispatch } = useDataExport();

  const [selectedMessageData, setSelectedMessageData] =
    useState<RemitMessageDetailsModel>(() => viewedRevision);

  const [selectedMessageId, setSelectedMessageId] = useState<string>(
    viewedRevision.id.toString()
  );

  useEffect(() => {
    setSelectedMessageData(
      getMessageDataById(
        details,
        parseInt(selectedMessageId)
      ) as RemitMessageDetailsModel
    );
  }, [details, selectedMessageId]);

  useEffect(() => {
    setSelectedMessageId(viewedRevision.id.toString());
  }, [viewedRevision.id]);

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () =>
        getRemitMessageDetailsDownloadData(selectedMessageData.id, fileFormat),
      `RemitMessageDetails-${selectedMessageData.createdTime}-Revision-${selectedMessageData.revisionNumber}-MessageId-${selectedMessageData.id}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const generateRevisionSection = useCallback((): JSX.Element => {
    const isViewingLatestRevision = viewedRevision.id === latestRevision.id;

    return (
      <ModalSection>
        <div>
          <ModalSubHeader>Revision</ModalSubHeader>
          {isViewingLatestRevision ? (
            <RadioButtonsRow
              radioButtons={[generateRevisionOption(latestRevision)]}
              selected={selectedMessageId}
              setSelected={setSelectedMessageId}
            />
          ) : (
            <>
              <p>
                The revision you are viewing is not the most recent one. Which
                one would you like to export?
              </p>
              <FlexContainerColumn>
                <RadioButtonsRow
                  radioButtons={[
                    generateRevisionOption(viewedRevision),
                    generateRevisionOption(latestRevision),
                  ]}
                  selected={selectedMessageId}
                  setSelected={setSelectedMessageId}
                />
              </FlexContainerColumn>
            </>
          )}
        </div>
      </ModalSection>
    );
  }, [viewedRevision, latestRevision, selectedMessageId, setSelectedMessageId]);

  const radioButtons = useMemo(() => {
    return (
      <ModalSection>
        <>{generateRevisionSection()}</>
      </ModalSection>
    );
  }, [generateRevisionSection]);

  const [downloadFormat, setDownloadFormat] = useState(DataDownloadFormat.Json);

  const handleExportClick = (): void => {
    downloadFile(downloadFormat);
  };

  return (
    <DataExporterContainer onExportClick={handleExportClick}>
      <DataExporterHeader />
      {radioButtons}
      <ModalSection>
        <FileFormatSection
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
          showCsvDownload={false}
        />
      </ModalSection>
    </DataExporterContainer>
  );
};

export default RemitDataExporter;
