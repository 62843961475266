import {
  getMarginForecastDailyData,
  getMarginForecastWeeklyData,
} from "api/dataVisualisation/marginForecast/marginForecast";
import {
  getSurplusForecastDailyData,
  getSurplusForecastWeeklyData,
} from "api/dataVisualisation/surplusForecast/surplusForecast";
import Switch from "components/components/Switch/Switch";
import SurplusAndMarginDataExporter from "components/dataVisualization/SurplusAndMargin//SurplusAndMarginDataExporter/SurplusAndMarginDataExporter";
import SurplusAndMarginChart from "components/dataVisualization/SurplusAndMargin/SurplusAndMarginChart/SurplusAndMarginChart";
import {
  ChartDataToggleContainer,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import ForecastFilter from "components/dataVisualization/dataPageComponents/ForecastFilter/ForecastFilter";
import { useDataExport } from "contexts/DataExportContext";
import useToggle from "hooks/useToggle";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { DataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import { TimeRange } from "models/filterModels/timeRange";
import React, { useEffect, useMemo, useState } from "react";
import { chartColours } from "styles/colours";
import {
  transformMarginForecastModelData,
  transformSurplusForecastModelData,
} from "utils/ChartDataUtils/surplusAndMargin/surplusAndMargin";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";

import useSurplusAndMarginChartContainerData from "./useSurplusAndMarginChartContainerData";

const SurplusAndMarginChartContainer: React.FC = () => {
  const [forecastFilter, setForecastFilter] = useState<ForecastFilterOption>(
    ForecastFilterOption.twoToFourteenDay
  );

  const [showSurplus, toggleSurplus] = useToggle(true);
  const [showMargin, toggleMargin] = useToggle(true);

  const { dispatch: dataExportCountDispatch } = useDataExport();

  const {
    data: rawData,
    fetchData,
    status,
  } = useSurplusAndMarginChartContainerData(forecastFilter);

  useEffect(fetchData, [fetchData]);

  const datasetCategories: DatasetCategory<ChartDatasetModel>[] = useMemo(
    () => [
      {
        datasets: [
          {
            colour: chartColours.surplus,
            dataKey: "surplus",
            displayName: "Surplus",
            isHidden: !showSurplus,
          },
          {
            colour: chartColours.margin,
            dataKey: "margin",
            displayName: "Margin",
            isHidden: !showMargin,
          },
        ],
        id: "datasets",
      },
    ],
    [showSurplus, showMargin]
  );

  const surplusChartData = useMemo(
    () =>
      rawData?.surplus?.length
        ? transformSurplusForecastModelData(rawData.surplus)
        : [],
    [rawData?.surplus]
  );

  const marginChartData = useMemo(
    () =>
      rawData?.margin?.length
        ? transformMarginForecastModelData(rawData.margin)
        : [],
    [rawData?.margin]
  );

  const chartData = useMemo(
    () =>
      surplusChartData.length && marginChartData.length
        ? [
            ...(showSurplus ? surplusChartData : []),
            ...(showMargin ? marginChartData : []),
          ]
        : [],
    [showSurplus, surplusChartData, showMargin, marginChartData]
  );

  const downloadFiles = (
    filter: ForecastFilterOption,
    fileFormat: DataDownloadFormat
  ): void => {
    let surplusDownload: () => Promise<DataDownloadModel<unknown>>;
    let surplusFilename: string;
    let marginDownload: () => Promise<DataDownloadModel<unknown>>;
    let marginFilename: string;

    switch (filter) {
      case ForecastFilterOption.twoToFourteenDay: {
        surplusDownload = (): Promise<DataDownloadModel<unknown>> =>
          getSurplusForecastDailyData(fileFormat);
        surplusFilename = `SurplusForecast-14day-${new Date().toISOString()}`;
        marginDownload = (): Promise<DataDownloadModel<unknown>> =>
          getMarginForecastDailyData(fileFormat);
        marginFilename = `MarginForecast-14day-${new Date().toISOString()}`;
        break;
      }
      case ForecastFilterOption.twoToFiftyTwoWeek: {
        surplusDownload = (): Promise<DataDownloadModel<unknown>> =>
          getSurplusForecastWeeklyData(TimeRange.FiftyTwoWeeks, fileFormat);
        surplusFilename = `SurplusForecast-2to52weeks-${new Date().toISOString()}`;
        marginDownload = (): Promise<DataDownloadModel<unknown>> =>
          getMarginForecastWeeklyData(TimeRange.FiftyTwoWeeks, fileFormat);
        marginFilename = `MarginForecast-2to52weeks-${new Date().toISOString()}`;
        break;
      }
      case ForecastFilterOption.twoToOneFiveSixWeek: {
        surplusDownload = (): Promise<DataDownloadModel<unknown>> =>
          getSurplusForecastWeeklyData(
            TimeRange.OneHundredAndFiftySixWeeks,
            fileFormat
          );
        surplusFilename = `SurplusForecast-2to156weeks-${new Date().toISOString()}`;
        marginDownload = (): Promise<DataDownloadModel<unknown>> =>
          getMarginForecastWeeklyData(
            TimeRange.OneHundredAndFiftySixWeeks,
            fileFormat
          );
        marginFilename = `MarginForecast-2to156weeks-${new Date().toISOString()}`;
        break;
      }
      default: {
        break;
      }
    }

    downloadDataToFile(
      surplusDownload!,
      surplusFilename!,
      fileFormat,
      dataExportCountDispatch
    );

    downloadDataToFile(
      marginDownload!,
      marginFilename!,
      fileFormat,
      dataExportCountDispatch
    );
  };

  return (
    <>
      <ChartHeader>
        <ChartDataToggleContainer>
          <Switch
            name="surplus-data"
            onColor={chartColours.surplus}
            onClick={toggleSurplus}
            switchedOn={showSurplus}
            leftLabel="Surplus"
            headerLabel
          />
          <Switch
            name="margin-data"
            onColor={chartColours.margin}
            onClick={toggleMargin}
            switchedOn={showMargin}
            leftLabel="Margin"
            headerLabel
          />
        </ChartDataToggleContainer>
        <ForecastFilter
          forecastFilter={forecastFilter}
          setForecastFilter={setForecastFilter}
        />
      </ChartHeader>
      <SurplusAndMarginChart
        series={chartData}
        datasetCategories={datasetCategories}
        dataFetchStatus={status}
        rerequestData={fetchData}
      />
      <ChartFooter rightAligned>
        <SurplusAndMarginDataExporter
          downloadData={(format: DataDownloadFormat): void =>
            downloadFiles(forecastFilter, format)
          }
          forecastFilter={forecastFilter}
          handleChangeToForecastFilter={setForecastFilter}
        />
      </ChartFooter>
    </>
  );
};

export default SurplusAndMarginChartContainer;
