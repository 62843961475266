import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { getUserData, UserData } from "api/user/client";
import TextInput from "components/components/Forms/TextInput/TextInput";
import PageMeta from "components/components/Meta/PageMeta";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import useRequest, { RequestStatus } from "hooks/useRequest";
import GeneralErrorPage from "pages/GeneralErrorPage/GeneralErrorPage";
import React, { useCallback, useEffect } from "react";
import { loginRequest } from "utils/authUtils";

const Profile: React.FC = () => {
  const { instance, inProgress } = useMsal();

  const fetchUserData = useCallback(async (): Promise<UserData | undefined> => {
    if (inProgress === InteractionStatus.None) {
      try {
        return await getUserData();
      } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            ...loginRequest,
            account: instance.getActiveAccount() as AccountInfo,
          });
        } else {
          throw e;
        }
      }
    }
    return undefined;
  }, [inProgress, instance]);

  const {
    data: userData,
    request: userDataRequest,
    status: userDataStatus,
  } = useRequest(fetchUserData);

  useEffect(() => {
    userDataRequest();
  }, [userDataRequest]);

  return (
    <div aria-live="polite">
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{ ...loginRequest }}
        errorComponent={GeneralErrorPage}
      >
        {userDataStatus === RequestStatus.ERRORED ? (
          // The error page is rendered as a child of the MsalAuthenticationTemplate (rather than
          // registering the error and using the error boundary) so that the MsalAuthenticationTemplate
          // doesn't unmount and cause issues with stale state.
          <GeneralErrorPage />
        ) : (
          <>
            <PageMeta title="Profile" />
            <PageHeader
              title="Profile"
              pageDescription={
                <>
                  <p>This is the personal information you provided us with.</p>
                  {userData && (
                    <>
                      {/* TODO:60568 - proper profile page, including editing.
                          Note, E2E testing might be a bit fiddly because of the Auth. These links may or may not be useful:
                          - https://dev.to/kauppfbi_96/test-msal-based-spas-with-cypress-4goe
                          - https://github.com/AzureAD/microsoft-authentication-library-for-js/pull/2771
                          - https://github.com/juunas11/AzureAdUiTestAutomation/tree/main/UiTestAutomation.Cypress/cypress */}
                      <TextInput
                        id="given-name"
                        value={userData.givenName}
                        name="ProfileGivenNameInput"
                        label="First Name"
                        onChange={(): void => {}}
                      />
                      <TextInput
                        id="surname"
                        value={userData.surname}
                        name="ProfileSurnameInput"
                        label="Surname"
                        onChange={(): void => {}}
                      />
                    </>
                  )}
                </>
              }
            />
          </>
        )}
      </MsalAuthenticationTemplate>
    </div>
  );
};

export default Profile;
