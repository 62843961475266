import { getPerBmuBidOfferAcceptancesJsonData } from "api/dataVisualisation/bidOfferAcceptances/bidOfferAcceptances";
import { getDynamicJsonData } from "api/dataVisualisation/dynamic/dynamic";
import { getPerBmuPhysicalJsonData } from "api/dataVisualisation/physical/physical";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { BidOfferAcceptanceModel } from "models/bidOffer/bidOfferAcceptanceModel";
import { DynamicDataModel } from "models/bmuData/dynamicDataModel";
import {
  PhysicalChartData,
  PhysicalChartDataset,
  StableLimitData,
  StableLimitChartDataset,
  PhysicalChartDataWithStableLimitData,
} from "models/bmuData/physicalChartData";
import { PhysicalDataModel } from "models/bmuData/physicalDataModel";
import { JsonDataDownloadModel } from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useCallback } from "react";

const physicalDataToPhysicalChartData = ({
  settlementDate,
  settlementPeriod,
  nationalGridBmUnit,
  timeFrom,
  timeTo,
  levelTo,
  levelFrom,
  bmUnit,
  dataset,
}: PhysicalDataModel): PhysicalChartData => ({
  settlementDate: new Date(settlementDate),
  settlementPeriod,
  nationalGridBmUnit,
  bmUnit,
  timeFrom: new Date(timeFrom),
  timeTo: new Date(timeTo),
  levelTo,
  levelFrom,
  dataset: dataset as PhysicalChartDataset,
});

const bidOfferAcceptanceDataToPhysicalChartData = ({
  settlementDate,
  nationalGridBmUnit,
  settlementPeriodFrom,
  timeFrom,
  timeTo,
  levelTo,
  levelFrom,
  bmUnit,
}: BidOfferAcceptanceModel): PhysicalChartData => ({
  settlementDate: new Date(settlementDate),
  settlementPeriod: settlementPeriodFrom,
  nationalGridBmUnit,
  bmUnit,
  timeFrom: new Date(timeFrom),
  timeTo: new Date(timeTo),
  levelTo,
  levelFrom,
  dataset: "BOAL",
});

const dynamicDataToStablePhysicalChartData = (
  dynamicData: DynamicDataModel
): StableLimitData => ({
  ...dynamicData,
  dataset: dynamicData.dataset as StableLimitChartDataset,
  time: new Date(dynamicData.time),
  settlementDate: new Date(dynamicData.settlementDate),
});

export const mapDownloadedDataToChartData = (
  rawBidOfferAcceptancesData: JsonDataDownloadModel<BidOfferAcceptanceModel>,
  rawPhysicalData: JsonDataDownloadModel<PhysicalDataModel>,
  rawDynamicData: JsonDataDownloadModel<DynamicDataModel>
): PhysicalChartDataWithStableLimitData => ({
  rangedData: [
    ...(rawBidOfferAcceptancesData.data?.map(
      bidOfferAcceptanceDataToPhysicalChartData
    ) || []),
    ...(rawPhysicalData.data?.map(physicalDataToPhysicalChartData) || []),
  ],
  stableLimitChartData: rawDynamicData.data?.map(
    dynamicDataToStablePhysicalChartData
  ),
});

export type PhysicalTabData = {
  chartData: PhysicalChartDataWithStableLimitData | null;
  bidOfferAcceptanceData: BidOfferAcceptanceModel[] | null;
  physicalData: PhysicalDataModel[] | null;
  stableData: DynamicDataModel[] | null;
};

export const getPhysicalTabData = async (
  dateFilter: DateFilterModel,
  bmUnit: string
): Promise<PhysicalTabData> => {
  const bidOfferAcceptancesDataRequest = getPerBmuBidOfferAcceptancesJsonData(
    dateFilter,
    bmUnit
  );

  const rawPhysicalDataRequest = getPerBmuPhysicalJsonData(dateFilter, bmUnit, [
    "PN",
    "QPN",
    "MELS",
    "MILS",
  ]);

  const rawDynamicDataRequest = getDynamicJsonData(dateFilter, bmUnit, [
    "SIL",
    "SEL",
  ]);

  const [rawBidOfferAcceptancesData, rawPhysicalData, rawDynamicData] =
    await Promise.all([
      bidOfferAcceptancesDataRequest,
      rawPhysicalDataRequest,
      rawDynamicDataRequest,
    ]);

  return {
    chartData: mapDownloadedDataToChartData(
      rawBidOfferAcceptancesData,
      rawPhysicalData,
      rawDynamicData
    ),
    bidOfferAcceptanceData: rawBidOfferAcceptancesData?.data,
    physicalData: rawPhysicalData.data,
    stableData: rawDynamicData.data,
  };
};

const usePhysicalData = (
  dateFilter: DateFilterModel | null,
  bmUnit: string | null
): {
  request: () => void;
  data: PhysicalTabData | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchPhysicalData,
  } = useRequest(getPhysicalTabData);

  const request = useCallback(() => {
    if (dateFilter && bmUnit) {
      fetchPhysicalData(dateFilter, bmUnit);
    }
  }, [bmUnit, dateFilter, fetchPhysicalData]);

  return {
    data,
    status,
    request,
  };
};

export default usePhysicalData;
