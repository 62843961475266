import {
  getGenerationForecastForWindAndSolarData,
  getGenerationForecastForWindAndSolarJsonData,
} from "api/dataVisualisation/generationForecastForWindAndSolar/generationForecastForWindAndSolar";
import {
  ChartBody,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import ChartFilterDrawer from "components/dataVisualization/dataPageComponents/ChartFilterDrawer/ChartFilterDrawer";
import GenerationForecastForWindAndSolarChart from "components/dataVisualization/generationForecastForWindAndSolar/GenerationForecastForWindAndSolarChart/GenerationForecastForWindAndSolarChart";
import { datasets } from "components/dataVisualization/generationForecastForWindAndSolar/GenerationForecastForWindAndSolarChart/GenerationForecastForWindAndSolarDatasets";
import { useDataExport } from "contexts/DataExportContext";
import { useDatasetCategoriesWithVisibilities } from "hooks/useDatasetsCategoriesWithVisibilities";
import useRequest from "hooks/useRequest";
import useToggle from "hooks/useToggle";
import { combineDatasetCategories } from "models/chartConfiguration/chartDatasetModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { transformGenerationForecastForWindAndSolarData } from "utils/ChartDataUtils/generationForecastForWindAndSolar/generationForecastForWindAndSolar";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  addDaysToDate,
  floorToHalfHour,
  ONE_DAY,
  restrictDateFilterToTwentyFourHours,
} from "utils/dateHelpers";

const GenerationForecastForWindAndSolarChartContainer: React.FC = () => {
  const { dispatch: dataExportCountDispatch } = useDataExport();

  const defaultStartTime = floorToHalfHour(new Date());
  const defaultDateFilter = useMemo(
    () =>
      new DateFilterModel(defaultStartTime, addDaysToDate(defaultStartTime, 1)),
    [defaultStartTime]
  );

  const [dateFilter, setDateFilter] =
    useState<DateFilterModel>(defaultDateFilter);
  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.TwentyFourHours
  );
  const [showChartFilterDrawer, toggleChartFilterDrawer] = useToggle(false);
  const {
    allDatasetCategories,
    datasetCategoriesToDisplay,
    setMatchingDatasetsVisibility,
  } = useDatasetCategoriesWithVisibilities(datasets);

  const {
    data,
    status,
    request: fetchData,
  } = useRequest(getGenerationForecastForWindAndSolarJsonData);

  useEffect(() => {
    fetchData(dateFilter);
  }, [fetchData, dateFilter]);

  const generationChartData = useMemo(
    () =>
      data
        ? transformGenerationForecastForWindAndSolarData(
            data.data,
            combineDatasetCategories(datasetCategoriesToDisplay)
          )
        : [],
    [data, datasetCategoriesToDisplay]
  );

  const handleChangeToDateSelection = useCallback(
    (newDateFilter: DateFilterModel, newActiveTab: DateSelectorTabs): void => {
      if (newActiveTab === DateSelectorTabs.TwentyFourHours) {
        setDateFilter(defaultDateFilter);
      } else {
        setDateFilter(restrictDateFilterToTwentyFourHours(newDateFilter));
      }
      setActiveDateSelectorTab(newActiveTab);
    },
    [defaultDateFilter]
  );

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getGenerationForecastForWindAndSolarData(dateFilter, fileFormat),
      `GenerationForecastForWindAndSolar-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  return (
    <>
      <ChartHeader>
        <ChartFilter
          showChartFilterDrawer={showChartFilterDrawer}
          toggleChartFilterDrawer={toggleChartFilterDrawer}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={[DateSelectorTabs.TwentyFourHours]}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={ONE_DAY}
        />
      </ChartHeader>
      <ChartBody>
        {showChartFilterDrawer && (
          <ChartFilterDrawer
            toggleChartFilterDrawer={toggleChartFilterDrawer}
            datasetCategories={allDatasetCategories}
            setMatchingDatasetsVisibility={setMatchingDatasetsVisibility}
          />
        )}
        <div style={{ width: showChartFilterDrawer ? "80vw" : "100vw" }}>
          <GenerationForecastForWindAndSolarChart
            series={generationChartData}
            datasetCategories={datasetCategoriesToDisplay}
            dataFetchStatus={status}
            rerequestData={(): Promise<void> => fetchData(dateFilter)}
            dateRange={dateFilter}
          />
        </div>
      </ChartBody>
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFile}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          activeDateSelectorTab={activeDateSelectorTab}
          dateSelectorTabs={[DateSelectorTabs.TwentyFourHours]}
        />
      </ChartFooter>
    </>
  );
};

export default GenerationForecastForWindAndSolarChartContainer;
