import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import React from "react";
import colours from "styles/colours";

import { ScrollButton, ScrollButtonsContainer } from "./style";

interface ScrollButtonsProps {
  handleScrollLeft: () => void;
  handleScrollRight: () => void;
  disableLeftScroll: boolean;
  disableRightScroll: boolean;
  shouldOverlapPreviousElement: boolean;
}

const ScrollButtons: React.FC<ScrollButtonsProps> = ({
  handleScrollLeft,
  handleScrollRight,
  disableLeftScroll,
  disableRightScroll,
  shouldOverlapPreviousElement,
}) => (
  <ScrollButtonsContainer
    shouldOverlapPreviousElement={shouldOverlapPreviousElement}
  >
    <ScrollButton
      onClick={handleScrollLeft}
      disabled={disableLeftScroll}
      data-test-id="left-scroll-button"
    >
      <Icon
        iconName={faChevronLeft}
        colour={disableLeftScroll ? colours.mediumGrey : colours.elexonBlue}
        size={IconSize.medium}
      />
    </ScrollButton>
    <ScrollButton
      onClick={handleScrollRight}
      disabled={disableRightScroll}
      data-test-id="right-scroll-button"
    >
      <Icon
        iconName={faChevronRight}
        colour={disableRightScroll ? colours.mediumGrey : colours.elexonBlue}
        size={IconSize.medium}
      />
    </ScrollButton>
  </ScrollButtonsContainer>
);

export default ScrollButtons;
