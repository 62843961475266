import FileTypeSelector from "components/components/FileTypeSelector/FileTypeSelector";
import { ModalSubHeader } from "components/components/Modal/commonStyle";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";

interface Props {
  showCsvDownload?: boolean;
  downloadFormat: DataDownloadFormat;
  setDownloadFormat: (format: DataDownloadFormat) => void;
}

const FileFormatSection: React.FC<Props> = ({
  showCsvDownload = true,
  downloadFormat,
  setDownloadFormat,
}) => {
  return (
    <div>
      <ModalSubHeader>File type</ModalSubHeader>
      <FileTypeSelector
        downloadFormat={downloadFormat}
        setDownloadFormat={setDownloadFormat}
        showCsvDownload={showCsvDownload}
      />
    </div>
  );
};

export default FileFormatSection;
