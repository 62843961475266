import { getData, getStitchedBmusData } from "api/dataVisualisation/client";
import BidOfferData from "models/bidOffer/bidOfferData";
import { BidOfferPairModel } from "models/bidOffer/bidOfferPairModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { getSettlementDateAndPeriodFromSettlementTime } from "utils/DateUtils";
import { toDateOnlyString } from "utils/dateHelpers";

export const getBidOfferData = async (
  dateFilter: DateFilterModel,
  bmUnit: string,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<BidOfferPairModel>> => {
  const { data } = await getData(
    "/balancing/bid-offer",
    {
      from: dateFilter.normalisedStartDate.toISOString(),
      to: dateFilter.normalisedEndDate.toISOString(),
      bmUnit,
    },
    format
  );
  return data;
};

export const getBidOfferJsonData = (
  dateFilter: DateFilterModel,
  bmUnits: string
): Promise<JsonDataDownloadModel<BidOfferPairModel>> =>
  getBidOfferData(dateFilter, bmUnits) as Promise<
    JsonDataDownloadModel<BidOfferPairModel>
  >;

export const getAllBidOfferData = async (
  date: Date,
  bmUnits?: string[],
  format?: DataDownloadFormat
): Promise<DataDownloadModel<BidOfferData>> => {
  const { settlementDate, settlementPeriod } =
    getSettlementDateAndPeriodFromSettlementTime(date);
  return getStitchedBmusData(
    "/balancing/bid-offer/all",
    {
      settlementDate: toDateOnlyString(settlementDate),
      settlementPeriod: settlementPeriod,
    },
    bmUnits,
    format
  );
};

export const getAllBidOfferJsonData = (
  settlementDate: Date,
  bmUnits?: string[]
): Promise<JsonDataDownloadModel<BidOfferData>> =>
  getAllBidOfferData(settlementDate, bmUnits) as Promise<
    JsonDataDownloadModel<BidOfferData>
  >;
