import styled from "styled-components";
import fonts from "styles/fonts";

export const IrisProsList = styled.ul`
  list-style: none;
  flex: 2;

  && {
    margin-left: 0;
    margin-top: 0;
  }

  li {
    display: flex;
    font-size: ${fonts.mediumBigger};

    span.icon {
      margin-right: 5px;
      margin-top: 5px;
    }
  }
`;

export const DescriptionHeader = styled.p`
  && {
    font-weight: ${fonts.heavy};
    font-size: ${fonts.mediumBigger};
  }
`;

export const Description = styled.p`
  font-size: ${fonts.medium};

  && {
    padding-bottom: 1.5em;
  }
`;

export const Column = styled.div`
  column-gap: 1em;
`;
