import styled from "styled-components";
import colours from "styles/colours";

export const StyledList = styled.li`
  a {
    color: ${colours.elexonBlack};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledExternalNavLink = styled.a`
  color: ${colours.elexonBlack};
  &&&:hover {
    text-decoration: underline;
    box-shadow: none;
    background-color: ${colours.white};
  }
`;
