import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as BlueExclamationCircleIcon } from "assets/icons/blueExclamationCircle.svg";
import { ReactComponent as CalendarWithTickIcon } from "assets/icons/calendarWithTick.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as LightBulbIcon } from "assets/icons/lightBulb.svg";
import { ReactComponent as StopWatchIcon } from "assets/icons/stopWatch.svg";
import IconWrapper, { IconSize } from "components/components/Icon/IconWrapper";
import React from "react";

export enum SvgIconName {
  Copy,
  BlueExclamationCircle,
  StopWatch,
  LightBulb,
  CalendarWithTick,
}

type SvgIconProps = {
  iconName: SvgIconName;
  colour?: string;
};

const SvgIcon: React.FC<SvgIconProps> = ({ iconName, colour }) => {
  switch (iconName) {
    case SvgIconName.Copy:
      return <CopyIcon fill={colour} />;
    case SvgIconName.BlueExclamationCircle:
      return <BlueExclamationCircleIcon />;
    case SvgIconName.StopWatch:
      return <StopWatchIcon />;
    case SvgIconName.LightBulb:
      return <LightBulbIcon />;
    case SvgIconName.CalendarWithTick:
      return <CalendarWithTickIcon />;
    default:
      throw new Error(`Unexpected icon name ${iconName}`);
  }
};

type IconProps = {
  iconName: IconProp | SvgIconName;
  colour?: string;
  ariaLabel?: string;
  ariaDescribedBy?: string;
  size?: IconSize;
  onClick?: () => void;
};

const isSvgIconName = (
  iconName: IconProp | SvgIconName
): iconName is SvgIconName => typeof iconName === "number";

const Icon: React.FC<IconProps> = ({
  iconName,
  colour,
  ariaLabel,
  ariaDescribedBy,
  size,
  onClick,
}) => (
  <IconWrapper
    ariaLabel={ariaLabel}
    ariaDescribedBy={ariaDescribedBy}
    size={size}
    onClick={onClick}
  >
    {isSvgIconName(iconName) ? (
      <SvgIcon iconName={iconName} colour={colour} />
    ) : (
      <FontAwesomeIcon icon={iconName} color={colour} />
    )}
  </IconWrapper>
);

export default Icon;
