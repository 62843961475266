import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Button from "components/components/Button/Button";
import FileTypeSelector from "components/components/FileTypeSelector/FileTypeSelector";
import ForecastFilterSelector from "components/components/ForecastFilterSelector/ForecastFilterSelector";
import GranularitySelector from "components/components/GranularitySelector/GranularitySelector";
import InfoBox from "components/components/InfoBox/InfoBox";
import InformationTooltip from "components/components/InformationTooltip/InformationTooltip";
import {
  HeaderWithTooltipContainer,
  TooltipLabel,
  TooltipLine,
} from "components/components/InformationTooltip/style";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import {
  ModalButtonContainer,
  ModalHeader,
  ModalPadding,
  ModalSection,
  ModalSubHeader,
} from "components/components/Modal/commonStyle";
import { Granularity } from "models/Granularity";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import React, { useState } from "react";

interface Props {
  forecastFilter: ForecastFilterOption;
  setForecastFilter: (newForecastFilter: ForecastFilterOption) => void;
  forecastFilterTabs: ForecastFilterOption[];
  downloadData: (
    fileFormat: DataDownloadFormat,
    granularity: Granularity
  ) => void;
}

const DemandForecastDataExporter: React.FC<Props> = ({
  forecastFilter,
  setForecastFilter,
  forecastFilterTabs,
  downloadData,
}: Props) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState(DataDownloadFormat.Csv);
  const [granularity, setGranularity] = useState(Granularity.National);

  const handleExportClick = (): void => {
    downloadData(downloadFormat, granularity);

    setIsModalActive(false);
  };

  return (
    <>
      <div data-test-id="export-data-open-button-container">
        <Button
          ariaLabel="Export"
          buttonText="export"
          iconName={faDownload}
          onClick={(): void => {
            setIsModalActive(true);
            if (forecastFilter !== ForecastFilterOption.dayAndDayAhead) {
              setGranularity(Granularity.National);
            }
          }}
        />
      </div>
      <Modal
        size={ModalSize.large}
        isActive={isModalActive}
        onClose={(): void => setIsModalActive(false)}
        closeOnEsc={false} // TODO: enable this to be escaped see ticket #52645
        headingId="export-modal-header"
      >
        <ModalPadding data-test-id="export-data-modal">
          <ModalSection>
            <ModalHeader id="export-modal-header">
              Select the data you want to export
            </ModalHeader>
          </ModalSection>
          <ModalSection>
            <div>
              <HeaderWithTooltipContainer>
                <ModalSubHeader>Time frame</ModalSubHeader>
                <InformationTooltip>
                  <>
                    <TooltipLine>
                      <TooltipLabel>Demand forecast data sets:</TooltipLabel>
                    </TooltipLine>
                    <TooltipLine>Day and day ahead: NDF & TSDF</TooltipLine>
                    <TooltipLine>2-14 days ahead: NDFD & TSDFD</TooltipLine>
                    <TooltipLine>2-52 weeks ahead: NDFW & TSDFW</TooltipLine>
                  </>
                </InformationTooltip>
              </HeaderWithTooltipContainer>
              <ForecastFilterSelector
                forecastFilter={forecastFilter}
                handleChangeToForecastFilter={(
                  filterOption: ForecastFilterOption
                ): void => {
                  if (filterOption !== ForecastFilterOption.dayAndDayAhead) {
                    setGranularity(Granularity.National);
                  }
                  setForecastFilter(filterOption);
                }}
                filterTabs={forecastFilterTabs}
              />
            </div>
          </ModalSection>
          <ModalSection>
            <div>
              <ModalSubHeader>File type</ModalSubHeader>
              <FileTypeSelector
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
              />
            </div>
            <div>
              <ModalSubHeader>Granularity</ModalSubHeader>
              <GranularitySelector
                granularity={granularity}
                setGranularity={setGranularity}
                isZonalDisabled={
                  forecastFilter !== ForecastFilterOption.dayAndDayAhead
                }
              />
            </div>
          </ModalSection>
          {granularity === Granularity.Zonal && (
            <ModalSection>
              <InfoBox
                title="Zonal data is only available for TSDF."
                description="Other data sets will not be downloaded if you select this option."
              />
            </ModalSection>
          )}
          <hr />
          <ModalSection className="centre">
            <ModalButtonContainer data-test-id="export-data-button-container">
              <Button
                ariaLabel="Cancel"
                buttonText="cancel"
                className="cancel"
                onClick={(): void => {
                  setIsModalActive(false);
                }}
              />
              <Button
                ariaLabel="Export"
                buttonText="export"
                onClick={handleExportClick}
              />
            </ModalButtonContainer>
          </ModalSection>
        </ModalPadding>
      </Modal>
    </>
  );
};

export default DemandForecastDataExporter;
