import CheckboxListContainer, {
  CheckboxState,
} from "components/components/CheckboxList/CheckboxListContainer";
import {
  ModalSection,
  ModalSubHeader,
} from "components/components/Modal/commonStyle";
import {
  checkboxDataGroups,
  Dataset,
} from "components/dataVisualization/CheckboxDataExporter/utils";
import DataExporterContainer from "components/dataVisualization/dataExporterComponents/DataExporterContainer/DataExporterContainer";
import DataExporterHeader from "components/dataVisualization/dataExporterComponents/dataExporterSections/DataExporterHeader";
import FileFormatSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/FileFormatSection";
import TimeFrameSection from "components/dataVisualization/dataExporterComponents/dataExporterSections/TimeFrameSection";
import { DownloadCallback } from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/MultiDatasetExporter";
import { useDataExport } from "contexts/DataExportContext";
import useCheckboxList from "hooks/useCheckboxList";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import React, { useMemo, useState } from "react";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";

interface Props {
  dateFilter: DateFilterModel;
  transformToDownloadCallbacks: (datasets: string[]) => DownloadCallback[];
  datasets: { [key: string]: Dataset };
  showTimeInput?: boolean;
  showSingleSettlementPeriod?: boolean;
}

const CheckboxDataExporter = ({
  dateFilter,
  transformToDownloadCallbacks,
  datasets,
  showTimeInput,
  showSingleSettlementPeriod,
}: Props): JSX.Element => {
  const fileSuffix = `${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`;
  const { dispatch: dataExportCountDispatch } = useDataExport();
  const [downloadFormat, setDownloadFormat] = useState(DataDownloadFormat.Csv);
  const [checkboxDataStates, registerClickForListItem] = useCheckboxList(
    checkboxDataGroups(datasets),
    true
  );

  const downloadCallbacks = useMemo(() => {
    const selectedDatasets = [
      ...new Set(
        checkboxDataStates
          .filter(
            (checkbox) => checkbox.checkboxState === CheckboxState.CHECKED
          )
          .flatMap((checkbox) => checkbox.datasets ?? [])
      ),
    ];

    return transformToDownloadCallbacks(selectedDatasets);
  }, [checkboxDataStates, transformToDownloadCallbacks]);

  const downloadData = (): void => {
    downloadCallbacks.forEach((c) =>
      downloadDataToFile(
        () => c.download(downloadFormat),
        `${c.filePrefix}-${fileSuffix}`,
        downloadFormat,
        dataExportCountDispatch
      )
    );
  };

  return (
    <DataExporterContainer
      onExportClick={downloadData}
      exportDisabled={downloadCallbacks.length === 0}
    >
      <DataExporterHeader />
      <ModalSection>
        <TimeFrameSection
          dateFilter={dateFilter}
          showTimeInput={showTimeInput ?? true}
          showSingleSettlementPeriod={showSingleSettlementPeriod ?? true}
        />
      </ModalSection>
      <ModalSection>
        <div>
          <ModalSubHeader>Datasets</ModalSubHeader>
          <CheckboxListContainer
            checkboxDataStates={checkboxDataStates}
            registerClickForListItem={registerClickForListItem}
          />
        </div>
      </ModalSection>
      <ModalSection>
        <FileFormatSection
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
        />
      </ModalSection>
    </DataExporterContainer>
  );
};
export default CheckboxDataExporter;
