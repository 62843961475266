import ErrorMessage from "components/components/ErrorMessage/ErrorMessage";
import PageMeta from "components/components/Meta/PageMeta";
import { useErrorContext } from "contexts/ErrorContext";
import React from "react";

const GeneralErrorPage: React.FC = () => {
  const { errorText } = useErrorContext();

  return (
    <>
      <PageMeta title="Error" />
      <ErrorMessage
        errorText={errorText ?? "Sorry, something went wrong."}
        refreshText
      />
    </>
  );
};
export default GeneralErrorPage;
