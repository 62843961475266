import { Datum, Serie } from "@nivo/line";
import {
  drmChartDatasetModel,
  lolpChartDatasetModel,
} from "components/dataVisualization/lolpdrm/lolpdrmChartConfig";
import { ForecastHorizon, LolpdrmModel } from "models/lolpdrm/lolpdrmModel";

const transformDatapoints = (
  rawData: LolpdrmModel[],
  forecastHorizon: number,
  key: keyof LolpdrmModel
): Datum[] =>
  rawData
    .filter((d) => d.forecastHorizon === forecastHorizon)
    .map((d) => ({
      x: new Date(d.startTime),
      y: d[key],
      settlementPeriod: d.settlementPeriod,
    }));

const transformDrmDatapoints = (
  rawData: LolpdrmModel[],
  forecastHorizon: number
): Datum[] => transformDatapoints(rawData, forecastHorizon, "deratedMargin");

const transformLolpDatapoints = (
  rawData: LolpdrmModel[],
  forecastHorizon: number
): Datum[] =>
  transformDatapoints(rawData, forecastHorizon, "lossOfLoadProbability");

export const transformDrmData = (rawData: LolpdrmModel[]): Serie[] =>
  drmChartDatasetModel.map((dataset) => ({
    id: dataset.dataKey,
    colour: dataset.colour,
    data: transformDrmDatapoints(rawData, dataset.forecastHorizon),
  }));

export const transformLolpData = (rawData: LolpdrmModel[]): Serie[] =>
  lolpChartDatasetModel.map((dataset) => ({
    id: dataset.dataKey,
    colour: dataset.colour,
    data: transformLolpDatapoints(rawData, dataset.forecastHorizon),
  }));

/**
 * Filters the forecast series for chart display.
 *
 * The chart should plot all forecasts after NOW, and only the 1h forecast before NOW.
 * This function filters the input series to return only datapoints that should be shown.
 * (NB the original series with all datapoints is still used for tooltip display.)
 *
 * @param  {Serie[]} serie   data series with all LOLP/DRM forecasts
 * @param  {Date} cutoffDate datetime before which only the 1h forecast should be shown
 * @return {Serie[]}         filtered series to use for chart display
 */
export const filterSerieToDisplay = (
  serie: Serie[],
  cutoffDate: Date
): Serie[] =>
  serie
    .map((s): Serie => {
      if (s.id.toString().endsWith(ForecastHorizon.Forecast1h)) {
        return s;
      }

      return {
        ...s,
        data: s.data.filter((d) => d.x && d.x >= cutoffDate),
      };
    })
    .filter((s) => s.data.length > 0);
