import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import { DemandControlInstructionsModel } from "models/demandControlInstructions/demandControlInstructionsModel";
import DateFilterModel from "models/filterModels/dateFilterModel";

export const getDemandControlInstructionsData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<DemandControlInstructionsModel>> => {
  const { data } = await getData(
    "/system/demand-control-instructions",
    {
      from: dateFilter.normalisedStartDate.toISOString(),
      to: dateFilter.normalisedEndDate.toISOString(),
    },
    format
  );
  return data;
};

export const getDemandControlInstructionsJsonData = (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<DemandControlInstructionsModel>> =>
  getDemandControlInstructionsData(
    dateFilter,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<DemandControlInstructionsModel>>;
