import { getRollingSystemDemandChartData } from "api/dataVisualisation/rollingSystemDemand/rollingSystemDemand";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { RollingSystemDemandModel } from "models/rollingSystemDemand/rollingSystemDemandModel";
import { useCallback } from "react";

const useRollingSystemDemandChartContainerData = (
  dateFilter: DateFilterModel
): {
  fetchData: () => void;
  data: RollingSystemDemandModel[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchRollingSystemDemandData,
  } = useRequest(getRollingSystemDemandChartData);

  const fetchData = useCallback(() => {
    fetchRollingSystemDemandData(dateFilter);
  }, [dateFilter, fetchRollingSystemDemandData]);

  return {
    data,
    status,
    fetchData,
  };
};

export default useRollingSystemDemandChartContainerData;
