import { getInterconnectorFlowsDownloadData } from "api/dataVisualisation/interconnectorFlows/interconnectorFlows";
import {
  ChartBody,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import FuelGenerationDataExporter from "components/dataVisualization/dataExporterComponents/FuelGenerationDataExporter/FuelGenerationDataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import ChartFilterDrawer from "components/dataVisualization/dataPageComponents/ChartFilterDrawer/ChartFilterDrawer";
import InterconnectorFlowsChart from "components/dataVisualization/interconnectorFlows/InterconnectorFlowsChart/InterconnectorFlowsChart";
import { useDataExport } from "contexts/DataExportContext";
import { useDatasetCategoriesWithVisibilities } from "hooks/useDatasetsCategoriesWithVisibilities";
import useToggle from "hooks/useToggle";
import { combineDatasetCategories } from "models/chartConfiguration/chartDatasetModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useEffect, useMemo, useState } from "react";
import { sortByStartTime } from "utils/ChartDataUtils";
import { transformOutturnSummaryModelData } from "utils/ChartDataUtils/outturnSummary/outturnSummary";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import { mapFuelTypesToInterconnectorDatasetCategory } from "utils/FuelTypeMap";
import { addDaysToDate, ceilToHalfHour } from "utils/dateHelpers";

import useInterconnectorFlowsChartContainerData from "./useInterconnectorFlowsChartContainerData";

const InterconnectorFlowsChartContainer: React.FC = () => {
  const defaultEndDate = ceilToHalfHour(new Date());
  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addDaysToDate(defaultEndDate, -1), defaultEndDate)
  );
  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.TwentyFourHours
  );
  const [showChartFilterDrawer, toggleChartFilterDrawer] = useToggle(false);

  const {
    allDatasetCategories,
    datasetCategoriesToDisplay,
    setDatasetCategories,
    setMatchingDatasetsVisibility,
  } = useDatasetCategoriesWithVisibilities();

  const [highlightedDataset, setHighlightedDataset] = useState("");
  const { dispatch: dataExportCountDispatch } = useDataExport();

  const {
    data: rawData,
    fetchData,
    status,
  } = useInterconnectorFlowsChartContainerData(dateFilter);

  useEffect(fetchData, [fetchData]);

  useEffect(() => {
    setDatasetCategories(
      rawData?.fuelTypes
        ? mapFuelTypesToInterconnectorDatasetCategory(rawData.fuelTypes)
        : []
    );
  }, [rawData?.fuelTypes, setDatasetCategories]);

  const chartData = useMemo(
    () =>
      rawData?.interconnectorFlowsData
        ? transformOutturnSummaryModelData(
            sortByStartTime(rawData?.interconnectorFlowsData),
            combineDatasetCategories(datasetCategoriesToDisplay)
          )
        : [],
    [rawData?.interconnectorFlowsData, datasetCategoriesToDisplay]
  );

  const downloadFile = (
    fileFormat: DataDownloadFormat,
    dataset: string
  ): void => {
    downloadDataToFile(
      () => getInterconnectorFlowsDownloadData(dateFilter, fileFormat, dataset),
      `InterconnectorFlows-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    setDateFilter(newDateFilter);
    setActiveDateSelectorTab(newActiveTab);
  };

  return (
    <>
      <ChartHeader>
        <ChartFilter
          showChartFilterDrawer={showChartFilterDrawer}
          toggleChartFilterDrawer={toggleChartFilterDrawer}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          activeDateSelectorTab={activeDateSelectorTab}
        />
      </ChartHeader>
      <ChartBody>
        {showChartFilterDrawer && (
          <ChartFilterDrawer
            highlightedDataset={highlightedDataset}
            sendHighlightedDataset={setHighlightedDataset}
            setMatchingDatasetsVisibility={setMatchingDatasetsVisibility}
            toggleChartFilterDrawer={toggleChartFilterDrawer}
            datasetCategories={allDatasetCategories}
          />
        )}
        <div style={{ width: showChartFilterDrawer ? "80vw" : "100vw" }}>
          <InterconnectorFlowsChart
            series={chartData}
            datasetCategories={datasetCategoriesToDisplay}
            sendHighlightedDataset={setHighlightedDataset}
            dataFetchStatus={status}
            rerequestData={fetchData}
          />
        </div>
      </ChartBody>
      <ChartFooter rightAligned>
        <FuelGenerationDataExporter
          downloadData={(format: DataDownloadFormat, dataset: string): void =>
            downloadFile(format, dataset)
          }
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          activeDateSelectorTab={activeDateSelectorTab}
        />
      </ChartFooter>
    </>
  );
};

export default InterconnectorFlowsChartContainer;
