import PageMeta from "components/components/Meta/PageMeta";
import DailyEnergyTransmittedChartContainer from "components/dataVisualization/dailyEnergyTransmitted/DailyEnergyTransmittedChartContainer/DailyEnergyTransmittedChartContainer";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import React from "react";

const DailyEnergyTransmitted: React.FC = () => (
  <>
    <PageMeta title="Daily energy transmitted" />
    <PageHeader
      title="Daily energy transmitted (INDOD)"
      pageDescription={
        <p>
          NESO provides the Initial National Demand Out-Turn Daily (INDOD) data,
          which is presented on a graph covering a rolling 3-month period.
        </p>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>
            The Initial National Demand Out-Turn Daily (INDOD) is a metric
            representing the total daily demand in megawatts (MWh) on the
            electricity grid. It&apos;s calculated by summing the INDO values
            for each Settlement Day, with the result divided by two to convert
            it to MWh. While it&apos;s not formally defined in the Grid Code, it
            is submitted to Insights by the System Operator.
          </p>
          <p>
            The Initial National Demand Out-Turn (INDO) is the average megawatt
            value of demand for each Settlement Period. It includes transmission
            losses but excludes factors like station transformer load, pumped
            storage demand, and interconnector demand. The INDO data is made
            available by the System Operator within 15 minutes after each
            Settlement Period, based on their operational metering. The
            composition of INDO matches that of the National Demand Forecast
            (NDF), making them comparable figures. Like INDOD, INDO is submitted
            to Insights by the System Operator, although it&apos;s not formally
            defined in the Grid Code
          </p>
        </>
      }
    />
    <FullWidthChartSection>
      <DailyEnergyTransmittedChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection
      documentationInformation={{
        chart: ["demand/outturn/daily"],
      }}
    />
  </>
);

export default DailyEnergyTransmitted;
