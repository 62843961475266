import { getStitchedData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { RollingSystemDemandModel } from "models/rollingSystemDemand/rollingSystemDemandModel";

const maxDaysPerQuery = 7;

enum Resolution {
  Minute = "minute",
  Hourly = "hourly",
  Daily = "daily",
  Weekly = "weekly",
}

const getDataResolutionFromDateFilter = (
  dateFilter: DateFilterModel
): Resolution => {
  const durationMilliseconds =
    dateFilter.endDate.valueOf() - dateFilter.startDate.valueOf();

  // Thresholds are chosen to keep the number of data points under 3k, too much higher than
  // this the chart loads slowly, chugs in slower browsers, and is hard to read

  const durationDays = durationMilliseconds / (1000 * 60 * 60 * 24);
  // 2d * 24h * 60m = 2880 points at most
  if (durationDays <= 2) {
    return Resolution.Minute;
  }

  const durationMonths = durationDays / 31;
  // 4m * 31d * 24h = 2976 points at most
  if (durationMonths <= 4) {
    return Resolution.Hourly;
  }

  // We have data going back to 2016, so 8 years * 365 days = 2920 points at most
  return Resolution.Daily;
};

export const getRollingSystemDemandChartData = async (
  dateFilter: DateFilterModel
): Promise<RollingSystemDemandModel[]> => {
  const { data } = (await getStitchedData(
    "/demand/outturn/summary",
    {
      from: "From",
      to: "To",
    },
    dateFilter,
    maxDaysPerQuery,
    { resolution: getDataResolutionFromDateFilter(dateFilter) }
  )) as JsonDataDownloadModel<RollingSystemDemandModel>;

  return data;
};

export const getRollingSystemDemandDownloadData = async (
  dateFilter: DateFilterModel,
  format: DataDownloadFormat
): Promise<DataDownloadModel<RollingSystemDemandModel>> =>
  getStitchedData(
    "/generation/outturn",
    {
      from: "From",
      to: "To",
    },
    dateFilter,
    maxDaysPerQuery,
    {},
    format
  );
