import React from "react";

import { StyledTileParent } from "./style";

interface Props {
  children: JSX.Element;
}

const TileParent: React.FC<Props> = ({ children }) => (
  <StyledTileParent className="tile is-parent">{children}</StyledTileParent>
);

export default TileParent;
