import classnames from "classnames";
import {
  TabButton,
  StyledDiv,
  StyledText,
} from "components/components/Tabs/TabSelector/style";
import {
  DerivedDataTabEnum,
  TabDetails,
  TabName,
} from "components/components/Tabs/Tabs/Tabs";
import { KeyboardEventHandler, useEffect, useRef } from "react";
import { toKebabCase } from "utils/stringHelpers";

interface Props<T> {
  tab: TabDetails<T>;
  isActiveTab: boolean;
  onClick: () => void;
  handleKeyPress: KeyboardEventHandler;
  isFocused: boolean;
}

const addBreak = (title: TabName, active: boolean): JSX.Element => {
  const content =
    title === DerivedDataTabEnum.IndicativeVolume ? (
      <>
        Indicative Period BM Unit
        <br />
        Acceptance Volumes
      </>
    ) : (
      <>{title}</>
    );
  return (
    <StyledText
      id={`${toKebabCase(title)}-tab-text`}
      data-test-id={active ? "active-tab-text" : "inactive-tab-text"}
    >
      {content}
    </StyledText>
  );
};

const TabSelector = <T extends TabName>({
  tab,
  isActiveTab,
  onClick,
  handleKeyPress,
  isFocused,
}: Props<T>): JSX.Element => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    ref.current?.focus();
  });

  return (
    <TabButton
      role="tab"
      id={`${toKebabCase(tab.title)}-tab`}
      aria-controls={
        // Cypress doesn't like it when the controlled element doesn't exist in the DOM
        isActiveTab ? `tab-container-${toKebabCase(tab.title)}` : undefined
      }
      aria-selected={isActiveTab}
      tabIndex={isActiveTab ? 0 : -1}
      className={classnames({ active: isActiveTab })}
      onClick={onClick}
      onKeyDown={handleKeyPress}
      isActive={isActiveTab}
      ref={isFocused && isActiveTab ? ref : null}
      data-test-id={isActiveTab ? "active-tab" : "inactive-tab"}
    >
      <StyledDiv>{addBreak(tab.title, isActiveTab)}</StyledDiv>
    </TabButton>
  );
};

export default TabSelector;
