import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import Tile from "components/components/Iris/LandingPageInfo/GetStartedSection/Tile/Tile";
import UsefulLinks from "components/components/Iris/UsefulLinks/UsefulLinks";
import { SubsectionHeader } from "components/components/Iris/commonStyle";
import React from "react";
import { azureStorageAccountUrl } from "utils/routeConstants";

const azureStorageExplorerUrl = (
  <div>
    Download{" "}
    <a
      href={azureStorageAccountUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="is-link"
    >
      Azure Storage Explorer
      <Icon iconName={faExternalLinkAlt} size={IconSize.xSmall} />
    </a>
  </div>
);
const GetStartedSection: React.FC = () => (
  <div className="container px-6">
    <SubsectionHeader>How to get started with IRIS</SubsectionHeader>
    <h3>Subscribe to receive files</h3>
    <div className="tile is-ancestor">
      <Tile title={1} text="Create an Insights Solution account" />
      <Tile title={2} text="Request client credentials" />
      <Tile title={3} text="Enter your credentials into a message client" />
    </div>
    <h3>Browse IRIS history</h3>
    The IRIS Archive is a public blob container that contains all files sent out
    to IRIS subscribers. It can be accessed using Azure Storage Explorer by
    passing in the URL <b>https://archive.data.elexon.co.uk/iris-archive</b>.
    See full setup instructions in our{" "}
    <a
      href="https://github.com/elexon-data/insights-docs/blob/main/docs/iris_archive.md"
      target="_blank"
      rel="noopener noreferrer"
      className="is-link"
    >
      GitHub docs
      <Icon iconName={faExternalLinkAlt} size={IconSize.xSmall} />
    </a>
    .
    <br />
    <br />
    <div className="tile is-ancestor">
      <Tile title={1} text={azureStorageExplorerUrl} />
      <Tile title={2} text="Add a new blob container" />
      <Tile title={3} text="Select public access" />
      <Tile title={4} text="Enter the URL" />
    </div>
    The IRIS archive can also be interacted with programmatically via{" "}
    <a
      href="https://azure.microsoft.com/en-gb/downloads/"
      target="_blank"
      rel="noopener noreferrer"
      className="is-link"
    >
      Azure SDKs
      <Icon iconName={faExternalLinkAlt} size={IconSize.xSmall} />
    </a>
    .
    <UsefulLinks />
  </div>
);

export default GetStartedSection;
