export const setInputCheckmarks = (
  input: HTMLInputElement | null,
  isChecked: boolean,
  isIndeterminate: boolean
): void => {
  const inputElement = input;
  if (isIndeterminate && inputElement) {
    inputElement.indeterminate = true;
  } else if (isChecked && inputElement) {
    inputElement.indeterminate = false;
    inputElement.checked = true;
  } else if (inputElement) {
    inputElement.checked = false;
    inputElement.indeterminate = false;
  }
};
