import styled from "styled-components";
import colours from "styles/colours";

export const StyledChartContainer = styled.div`
  position: relative;
`;

interface CrosshairContainerProps {
  backgroundColour: string;
  height: string;
  top: string;
}

export const CrosshairContainer = styled.div<CrosshairContainerProps>`
  position: absolute;
  background-color: ${({ backgroundColour }: CrosshairContainerProps): string =>
    backgroundColour};
  height: ${({ height }: CrosshairContainerProps): string => height};
  left: 120px;
  top: ${({ top }: CrosshairContainerProps): string => top};
  width: calc(100% - 200px);
`;

export const CrosshairLine = styled.div`
  border-left: 1px solid ${colours.darkGrey};
  position: absolute;
  height: 100%;
  z-index: 1; // z-index required to appear above chart
  pointer-events: none;
`;
