import BalancingServicesVolumeChart from "components/dataVisualization/balancingMechanismTabs/BalancingServicesVolume/bmuView/BalancingServicesVolumeChart/BalancingServicesVolumeChart";
import { NoDataAvailable } from "components/dataVisualization/balancingMechanismTabs/style";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import { useBmuViewContext } from "contexts/BmuViewContext";
import React, { useMemo } from "react";
import { transformBalancingServicesVolumeModelData } from "utils/ChartDataUtils/balancingServicesVolume/balancingServicesVolume";
import { chartWouldBeEmpty } from "utils/chartHelpers";

const BalancingServicesVolumeChartContainer: React.FC = () => {
  const {
    selectedBmu,
    dateFilter,
    balancingServicesVolumeDetails: { status, data },
  } = useBmuViewContext();

  const chartData = useMemo(
    () =>
      data && dateFilter
        ? transformBalancingServicesVolumeModelData(data, dateFilter)
        : [],
    [dateFilter, data]
  );

  if (dateFilter === null || selectedBmu === null) {
    return <></>;
  }

  if (chartWouldBeEmpty(status, chartData)) {
    return (
      <NoDataAvailable>
        No data available for this BMU in this timeframe
      </NoDataAvailable>
    );
  }

  return (
    <FullWidthChartSection>
      <BalancingServicesVolumeChart
        series={chartData}
        dataFetchStatus={status}
        dateFilter={dateFilter}
      />
    </FullWidthChartSection>
  );
};

export default BalancingServicesVolumeChartContainer;
