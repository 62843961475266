import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import GenerationForecastForWindAndSolarChartContainer from "components/dataVisualization/generationForecastForWindAndSolar/GenerationForecastForWindAndSolarChartContainer/GenerationForecastForWindAndSolarChartContainer";
import React from "react";

const GenerationForecastForWindAndSolar: React.FC = () => (
  <>
    <PageMeta title="Generation forecast for wind & solar" />
    <PageHeader
      title="Generation forecast for wind & solar"
      pageDescription={
        <p>
          A forecast of wind and solar power generation (MW) per bidding zone,
          per Settlement Period of the following day.
        </p>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <p>
          The information shall be published no later than 18:00 Brussels time,
          one day before actual delivery takes place. The information shall be
          regularly updated and published during intra-day trading with at least
          one update to be published at 8:00 Brussels time on the day of actual
          delivery.
        </p>
      }
    />
    <FullWidthChartSection>
      <GenerationForecastForWindAndSolarChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection
      documentationInformation={{
        chart: ["forecast/generation/wind-and-solar/day-ahead"],
      }}
    />
  </>
);

export default GenerationForecastForWindAndSolar;
