import SortableTable, {
  TableCellRender,
} from "components/components/SortableTable/SortableTable";
import { NoWrapCell } from "components/components/SortableTable/style";
import TabContentWithErrorHandling from "components/components/Tabs/TabContentWithErrorHandling/TabContentWithErrorHandling";
import { DescriptionText } from "components/components/Tabs/TabDescription/style";
import TileRowContainer from "components/components/TileRowContainer/TileRowContainer";
import Dynamic from "components/dataVisualization/balancingMechanismTabs/Dynamic/Dynamic";
import DynamicDataTile from "components/dataVisualization/balancingMechanismTabs/Dynamic/bmuView/DynamicDataTile";
import { TilesContainer } from "components/dataVisualization/balancingMechanismTabs/Dynamic/bmuView/style";
import { useBmuViewContext } from "contexts/BmuViewContext";
import React from "react";
import { transformDynamicTileData } from "utils/TabularDataUtils/DynamicDataTileUtils";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

import { mapToDynamicRatesRow } from "./utils";

const BmuViewDynamic: React.FC = () => {
  const {
    dynamicDetails: { data, status, request },
  } = useBmuViewContext();

  const tableData = data?.dynamicRatesData
    ? mapToDynamicRatesRow(data?.dynamicRatesData)
    : [];

  const groupedData = data && transformDynamicTileData(data.dynamicData);

  return (
    <Dynamic>
      <TabContentWithErrorHandling status={status} fetchData={request}>
        <>
          <TilesContainer>
            <TileRowContainer>
              <>
                <DynamicDataTile
                  title="Notices"
                  tables={groupedData?.notices}
                  dataTestId="notices-dynamic-data-tile"
                />
                <DynamicDataTile
                  title="Minimum periods"
                  tables={groupedData?.minimumPeriods}
                  dataTestId="minimum-periods-dynamic-data-tile"
                />
              </>
            </TileRowContainer>
            <TileRowContainer>
              <>
                <DynamicDataTile
                  title="Maximum delivery"
                  tables={groupedData?.maximumDelivery}
                  dataTestId="maximum-delivery-dynamic-data-tile"
                />
                <DynamicDataTile
                  title="Stable limits"
                  tables={groupedData?.stableLimits}
                  dataTestId="stable-limits-dynamic-data-tile"
                />
              </>
            </TileRowContainer>
          </TilesContainer>
          <h3>Rates</h3>
          <DescriptionText>
            If a rate type has no data in the selected time frame then the most
            recent data received is displayed. If there is no data for a rate
            type during or before the selected time frame then those rows are
            omitted.
          </DescriptionText>
          <br />
          <SortableTable
            headers={{
              dataset: "Rate",
              time: "Time (UTC)",
              settlementPeriod: "Settlement Period",
              rate1: "Rate 1 (MW/min)",
              elbow2: "Elbow 2 (MW)",
              rate2: "Rate 2 (MW/min)",
              elbow3: "Elbow 3 (MW)",
              rate3: "Rate 3 (MW/min)",
            }}
            items={tableData}
            renderFns={{
              time: ({ time }): TableCellRender => ({
                content: (
                  <NoWrapCell>
                    {formatDateTimeString(time, DateFormat.DateTimeWithHyphen)}
                  </NoWrapCell>
                ),
              }),
              dataset: ({ dataset }): TableCellRender => ({
                content: (
                  <NoWrapCell>
                    <b>{dataset}</b>
                  </NoWrapCell>
                ),
              }),
            }}
            dataTestId="rates-table-dynamic-data"
          />
        </>
      </TabContentWithErrorHandling>
    </Dynamic>
  );
};
export default BmuViewDynamic;
