import styled from "styled-components";
import colours from "styles/colours";

export const StyledInput = styled.input`
  box-sizing: border-box;
  border: 1px solid ${colours.mediumGrey};
  padding: 0.6rem;
  width: 100%;
  height: 3rem;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-family: inherit;

  &:focus {
    outline: none;
    border: 2px solid ${colours.focusBlue};
  }

  &:hover {
    border-color: ${colours.focusBlue};
  }
`;
