import { getAgwsData } from "api/dataVisualisation/actualOrEstimatedWindAndSolarPowerGeneration/actualOrEstimatedWindAndSolarPowerGeneration";
import useActualOrEstimatedWindAndSolarPowerGenerationChartData from "components/dataVisualization/ActualOrEstimatedWindAndSolarPowerGeneration/useActualOrEstimatedWindAndSolarPowerGenerationChartData";
import {
  datasetCategories,
  transformAgwsDataToSerie,
} from "components/dataVisualization/ActualOrEstimatedWindAndSolarPowerGeneration/utils";
import DatasetLineChart from "components/dataVisualization/DatasetLineChart/DatasetLineChart";
import {
  ChartBody,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import ChartFilterDrawer from "components/dataVisualization/dataPageComponents/ChartFilterDrawer/ChartFilterDrawer";
import { useDataExport } from "contexts/DataExportContext";
import { useDatasetCategoriesWithVisibilities } from "hooks/useDatasetsCategoriesWithVisibilities";
import useToggle from "hooks/useToggle";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  DAYS_IN_ONE_WEEK,
  addDaysToDate,
  ceilToHalfHour,
} from "utils/dateHelpers";

const ActualOrEstimatedWindAndSolarPowerGenerationChartContainer: React.FC =
  () => {
    const { dispatch: dataExportCountDispatch } = useDataExport();
    const defaultEndDate = ceilToHalfHour(new Date());
    const dateSelectorTabs = [
      DateSelectorTabs.TwentyFourHours,
      DateSelectorTabs.OneWeek,
    ];
    const [dateFilter, setDateFilter] = useState<DateFilterModel>(
      new DateFilterModel(addDaysToDate(defaultEndDate, -7), defaultEndDate)
    );
    const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
      DateSelectorTabs.OneWeek
    );

    const [showChartFilterDrawer, toggleChartFilterDrawer] = useToggle(false);

    const { data, fetchData, status } =
      useActualOrEstimatedWindAndSolarPowerGenerationChartData(dateFilter);

    useEffect(fetchData, [fetchData]);

    const handleChangeToDateSelection = useCallback(
      (
        newDateFilter: DateFilterModel,
        newActiveTab: DateSelectorTabs
      ): void => {
        setDateFilter(newDateFilter.restrictToMaxOneWeek());
        setActiveDateSelectorTab(newActiveTab);
      },
      [setDateFilter, setActiveDateSelectorTab]
    );

    const downloadFile = (fileFormat: DataDownloadFormat): void => {
      downloadDataToFile(
        () => getAgwsData(dateFilter, fileFormat),
        `ActualOrEstimatedWindAndSolarPowerGeneration-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
        fileFormat,
        dataExportCountDispatch
      );
    };

    const {
      allDatasetCategories,
      datasetCategoriesToDisplay,
      setMatchingDatasetsVisibility,
    } = useDatasetCategoriesWithVisibilities(datasetCategories);

    const chartData = useMemo(
      () =>
        data ? transformAgwsDataToSerie(data, datasetCategoriesToDisplay) : [],
      [data, datasetCategoriesToDisplay]
    );

    return (
      <>
        <ChartHeader>
          <ChartFilter
            dateFilter={dateFilter}
            defaultEndDate={defaultEndDate}
            handleChangeToDateSelection={handleChangeToDateSelection}
            dateSelectorTabs={dateSelectorTabs}
            activeDateSelectorTab={activeDateSelectorTab}
            maxRange={DAYS_IN_ONE_WEEK}
            toggleChartFilterDrawer={toggleChartFilterDrawer}
            showChartFilterDrawer={showChartFilterDrawer}
          />
        </ChartHeader>
        <ChartBody>
          {showChartFilterDrawer && (
            <ChartFilterDrawer
              setMatchingDatasetsVisibility={setMatchingDatasetsVisibility}
              toggleChartFilterDrawer={toggleChartFilterDrawer}
              datasetCategories={allDatasetCategories}
            />
          )}
          <div style={{ width: showChartFilterDrawer ? "80vw" : "100vw" }}>
            <DatasetLineChart
              series={chartData}
              datasetCategories={datasetCategoriesToDisplay}
              dataFetchStatus={status}
              rerequestData={fetchData}
              showPublishTime={false}
              tooltipText={"Start Time"}
            />
          </div>
        </ChartBody>
        <ChartFooter rightAligned>
          <DataExporter
            downloadData={downloadFile}
            dateFilter={dateFilter}
            defaultEndDate={defaultEndDate}
            handleChangeToDateSelection={handleChangeToDateSelection}
            activeDateSelectorTab={activeDateSelectorTab}
            dateSelectorTabs={dateSelectorTabs}
            maxRange={DAYS_IN_ONE_WEEK}
          />
        </ChartFooter>
      </>
    );
  };

export default ActualOrEstimatedWindAndSolarPowerGenerationChartContainer;
