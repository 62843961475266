import Icon, { SvgIconName } from "components/components/Icon/Icon";
import {
  StyledEventStatusCircle,
  StyledPageHeader,
  StyledContainer,
  TextWrapper,
} from "components/remit/MessageDetails/RemitMessageStatuses/style";
import RemitMessageDetailsModel from "models/remit/remitMessageDetailsModel";
import React from "react";
import {
  formatUTCDuration,
  getDurationFromStartAndEndDatesUTC,
} from "utils/dateHelpers";

export enum EventStatus {
  Active = "Active",
  Inactive = "Inactive",
  Dismissed = "Dismissed",
}

const RemitMessageStatuses: React.FC<{ details: RemitMessageDetailsModel }> = ({
  details,
}) => {
  const eventDuration = details.eventEndTime
    ? formatUTCDuration(
        getDurationFromStartAndEndDatesUTC(
          new Date(details.eventStartTime),
          new Date(details.eventEndTime)
        )
      )
    : "—";

  const availableCapacityPercentage =
    details.availableCapacity && details.normalCapacity
      ? `${Math.round(
          (details.availableCapacity / details.normalCapacity) * 100
        )}% of normal capacity`
      : "—";

  return (
    <StyledContainer>
      <StyledPageHeader>
        <StyledEventStatusCircle
          eventStatus={details.eventStatus as EventStatus}
        />
        <TextWrapper>
          <dt>Event status</dt>
          <dd>{details.eventStatus}</dd>
        </TextWrapper>
      </StyledPageHeader>
      <StyledPageHeader>
        <Icon iconName={SvgIconName.StopWatch} />
        <TextWrapper>
          <dt>Event duration</dt>
          <dd>{eventDuration}</dd>
        </TextWrapper>
      </StyledPageHeader>
      <StyledPageHeader>
        <Icon iconName={SvgIconName.LightBulb} />
        <TextWrapper>
          <dt>Available capacity</dt>
          <dd>{availableCapacityPercentage}</dd>
        </TextWrapper>
      </StyledPageHeader>
      <StyledPageHeader>
        <Icon iconName={SvgIconName.CalendarWithTick} />
        <TextWrapper>
          <dt>Unavailability</dt>
          <dd>
            {details.unavailabilityType ? details.unavailabilityType : "—"}
          </dd>
        </TextWrapper>
      </StyledPageHeader>
    </StyledContainer>
  );
};

export default RemitMessageStatuses;
