import Button from "components/components/Button/Button";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import {
  ModalButtonContainer,
  ModalPadding,
  ModalSection,
} from "components/components/Modal/commonStyle";
import { useState } from "react";

import { WizardHeader } from "./style";

export interface WizardStep {
  title: string;
  content: React.ReactNode;
  disabledPrimaryButton?: boolean;
}

interface Props {
  steps: WizardStep[];
  isOpen: boolean;
  onClose: () => void;
  onExportClick: () => void;
}

const ModalExportWizard: React.FC<Props> = ({
  steps,
  isOpen,
  onClose,
  onExportClick,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const nextStep = (): void => {
    setCurrentPage(currentPage + 1);
  };
  const prevStep = (): void => {
    setCurrentPage(currentPage - 1);
  };
  const resetPageAndClose = (): void => {
    onClose();
    setCurrentPage(0);
  };
  const resetPageAndExport = (): void => {
    onExportClick();
    setCurrentPage(0);
  };
  const totalPages = steps.length;
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === totalPages - 1;
  return (
    <Modal
      size={ModalSize.large}
      isActive={isOpen}
      onClose={resetPageAndClose}
      closeOnEsc={false} // TODO: enable this to be escaped see ticket #52645
      headingId="export-modal-header"
    >
      <ModalPadding data-test-id="export-data-modal-page" aria-live="polite">
        <div>
          Step {currentPage + 1} of {totalPages}
        </div>
        <WizardHeader id="export-modal-header">
          {steps[currentPage]?.title ?? ""}
        </WizardHeader>
        <div>{steps[currentPage]?.content ?? ""}</div>
        <hr />
        <ModalSection className="centre">
          <ModalButtonContainer data-test-id="modal-wizard-buttons">
            {isFirstPage ? (
              <Button
                ariaLabel="Cancel"
                buttonText="cancel"
                className="cancel"
                onClick={resetPageAndClose}
              />
            ) : (
              <Button
                ariaLabel="Previous"
                buttonText="previous"
                className="cancel"
                onClick={prevStep}
              />
            )}
            {isLastPage ? (
              <Button
                ariaLabel="Export"
                buttonText="export"
                onClick={resetPageAndExport}
                disabled={steps[currentPage]?.disabledPrimaryButton}
              />
            ) : (
              <Button
                ariaLabel="Next"
                buttonText="next"
                onClick={nextStep}
                disabled={steps[currentPage]?.disabledPrimaryButton}
              />
            )}
          </ModalButtonContainer>
        </ModalSection>
      </ModalPadding>
    </Modal>
  );
};
export default ModalExportWizard;
