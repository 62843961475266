import { faFilter } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import MarketViewFiltersForm from "components/components/balancingMechanism/marketView/MarketViewFiltersForm/MarketViewFiltersForm";
import { useMarketViewContext } from "contexts/MarketViewContext";
import useToggle from "hooks/useToggle";

import { hasAnyFilters } from "./helpers";
import { StyledFilterButton, StyledDiv } from "./style";

interface Props {
  children: JSX.Element;
}

const MarketViewFilters: React.FC<Props> = ({ children }) => {
  const [showForm, toggleForm] = useToggle(false);
  const { bmuFilters } = useMarketViewContext();

  const filterButton = (
    <StyledFilterButton
      onClick={toggleForm}
      className={classnames("filter", {
        active: hasAnyFilters(bmuFilters),
      })}
      type="button"
      data-test-id="market-view-filters-button"
    >
      <span>Filters</span>
      <Icon iconName={faFilter} size={IconSize.xSmall} />
    </StyledFilterButton>
  );

  return (
    <StyledDiv className="container px-6">
      <h2>Time frame and filters</h2>
      <div>
        {children}
        {filterButton}
      </div>
      <MarketViewFiltersForm show={showForm} />
    </StyledDiv>
  );
};
export default MarketViewFilters;
