import { Datum, Serie } from "@nivo/line";
import { BalancingServicesVolumeModel } from "models/bmuData/balancingServicesVolumeModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { chartColours } from "styles/colours";
import { sortByTime } from "utils/ChartDataUtils/index";
import { getHalfHourlyTimesBetween } from "utils/dateHelpers";

const overwriteUndefinedPointsWithDataPoints = (
  halfHourlyUndefinedPoints: Datum[],
  dataPoints: Datum[]
): Datum[] =>
  halfHourlyUndefinedPoints.reduce((accumulated, curr) => {
    const dataPoint = dataPoints.find(
      (d) => (d.x as Date).getTime() === (curr.x as Date).getTime()
    );
    return [...accumulated, dataPoint ?? curr];
  }, [] as Datum[]);

export const transformBalancingServicesVolumeModelData = (
  rawData: BalancingServicesVolumeModel[],
  dateFilter: DateFilterModel
): Serie[] => {
  if (rawData.length === 0) {
    return [];
  }
  const sortedData = sortByTime(rawData);
  const dataPoints = sortedData.reduce(
    (accumulated, curr) => [
      ...accumulated,
      {
        x: new Date(curr.time),
        y: curr.bmUnitApplicableBalancingServicesVolume,
        settlementPeriod: curr.settlementPeriod,
      },
    ],
    [] as Datum[]
  );

  const halfHourlyUndefinedPoints = getHalfHourlyTimesBetween(
    dateFilter.normalisedStartDate,
    dateFilter.normalisedEndDate
  ).map((d) => ({
    x: d,
    y: undefined,
  }));

  const data = overwriteUndefinedPointsWithDataPoints(
    halfHourlyUndefinedPoints,
    dataPoints
  );

  return [
    {
      id: "balancingServicesVolume",
      colour: chartColours.balancingServicesVolume,
      data,
    },
  ];
};
