import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const StyledText = styled.p`
  font-size: ${fonts.smallMedium};
  color: ${colours.darkGrey};
  border-radius: 4px 4px 0 0;
  letter-spacing: 0.4px;
  margin: 1.7em 0;
`;

export const TabButton = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  max-width: 20em;
  cursor: pointer;

  background: ${(props: { isActive: boolean }): string =>
    props.isActive ? colours.white : "inherit"};
  border: ${(props: { isActive: boolean }): string =>
    props.isActive
      ? `1px solid ${colours.highlightGrey}`
      : "1px solid transparent"};
  border-bottom: 2px solid transparent;

  & ${StyledText} {
    border-bottom: ${(props: { isActive: boolean }): string =>
      props.isActive ? `3px solid ${colours.elexonBlue}` : "0"};
    font-weight: ${(props: { isActive: boolean }): string =>
      props.isActive ? "bold" : "normal"};
    letter-spacing: ${(props: { isActive: boolean }): string =>
      props.isActive ? "0" : "0.4px"};
  }

  &:hover ${StyledText} {
    font-weight: bold;
    letter-spacing: 0;
  }

  &&:focus-visible {
    border: 2px solid ${colours.elexonBlack};
    box-shadow: none;
  }
`;

export const StyledDiv = styled.div`
  all: unset;
  text-align: center;
  display: flex;
  align-items: center;
  height: 105px;
`;
