import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { reactPlugin } from "AppInsights";
import { ErrorContext } from "contexts/ErrorContext";
import GeneralErrorPage from "pages/GeneralErrorPage/GeneralErrorPage";
import React from "react";
import { Location } from "react-router-dom";

interface Props {
  children: JSX.Element;
  location: Location;
}

class ErrorBoundary extends React.Component<Props> {
  static contextType = ErrorContext;

  context: React.ContextType<typeof ErrorContext>;

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      prevProps.location.key !== this.props.location.key &&
      this.context?.hasError
    ) {
      this.context.clearError();
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.context?.registerError();
    reactPlugin.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: errorInfo,
    });
  }

  render(): JSX.Element {
    return (
      <>
        <div aria-live="assertive">
          {this.context?.hasError ? <GeneralErrorPage /> : <></>}
        </div>
        {this.context?.hasError ? <></> : this.props.children}
      </>
    );
  }
}
export default ErrorBoundary;
