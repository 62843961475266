import { DropdownIndicator } from "components/components/Forms/DropdownIndicator/DropdownIndicator";
import { RequestStatus } from "hooks/useRequest";
import { useEffect, useMemo, useState } from "react";
import Select, {
  CSSObjectWithLabel,
  InputActionMeta,
  Theme,
} from "react-select";
import colours from "styles/colours";

import {
  convertToOption,
  FilterOption,
  fuzzyFilter,
  mapToOptions,
} from "./helpers";
import { augmentTheme } from "./style";

interface Props {
  values: string[];
  selectedValue: string | null;
  onChange: (value: string) => void;
  ariaLabel: string;
  status?: RequestStatus;
  placeholder?: string;
  isDisabled?: boolean;
}

const Dropdown: React.FC<Props> = ({
  values,
  selectedValue,
  onChange,
  ariaLabel,
  status = RequestStatus.SUCCESSFUL_OR_NOT_STARTED,
  placeholder = "Select or search",
  isDisabled = false,
}) => {
  const allOptions = useMemo(() => mapToOptions(values), [values]);
  const selectedOption = useMemo(() => {
    if (selectedValue === null) {
      return null;
    }
    return convertToOption(selectedValue);
  }, [selectedValue]);

  const [filterString, setFilterString] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<FilterOption[]>([]);

  useEffect(
    () => setFilteredOptions(fuzzyFilter(allOptions, filterString)),
    [allOptions, filterString]
  );

  const onInputChange = (input: string, { action }: InputActionMeta): void => {
    if (action === "input-change") {
      // By clearing the filteredOptions array when user input is changed,
      // react-select defaults to focusing on the first option in the dropdown list
      // instead of remembering which option was previously focused.
      setFilteredOptions([]);
      // only set the input when the action that caused the
      // change equals to "input-change" and ignore the other
      // ones like: "set-value", "input-blur", and "menu-close"
      setFilterString(input);
    }
  };

  const onBlur = (): void => {
    setFilterString("");
    setFilteredOptions(allOptions);
  };

  const onSelectionChange = (newOption: unknown): void =>
    onChange((newOption as FilterOption)?.value ?? null);

  return (
    <Select
      options={filteredOptions}
      aria-label={ariaLabel}
      filterOption={null}
      value={selectedOption}
      onInputChange={onInputChange}
      onChange={onSelectionChange}
      onBlur={onBlur}
      components={{
        DropdownIndicator,
        IndicatorSeparator: null,
        ClearIndicator: undefined,
      }}
      isMulti={false}
      isClearable={true}
      placeholder={placeholder}
      theme={(theme): Theme => augmentTheme(theme, status)}
      isLoading={status === RequestStatus.IN_PROGRESS}
      isDisabled={isDisabled}
      styles={{
        control: (provided: CSSObjectWithLabel) => ({
          ...provided,
          height: "3rem",
        }),
        placeholder: (provided: CSSObjectWithLabel) => ({
          ...provided,
          color: colours.mediumGrey,
        }),
      }}
    />
  );
};

export default Dropdown;
