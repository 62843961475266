import { getAgwsChartData } from "api/dataVisualisation/actualOrEstimatedWindAndSolarPowerGeneration/actualOrEstimatedWindAndSolarPowerGeneration";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { AgwsData } from "models/actualOrEstimatedWindAndSolarPowerGeneration/ActualOrEstimatedWindAndSolarPowerGeneration";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useCallback } from "react";

const useActualOrEstimatedWindAndSolarPowerGenerationChartData = (
  dateFilter: DateFilterModel
): {
  fetchData: () => void;
  data: AgwsData[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchAgwsChartData,
  } = useRequest(getAgwsChartData);

  const fetchData = useCallback(() => {
    fetchAgwsChartData(dateFilter);
  }, [dateFilter, fetchAgwsChartData]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useActualOrEstimatedWindAndSolarPowerGenerationChartData;
