import { TabulatedTooltipValue } from "models/chartConfiguration/tooltipModels";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

const sortByDateFieldName = <K extends string, T extends Record<K, Date>>(
  data: T[],
  dateFieldName: K
): T[] =>
  [...data].sort(
    (a, b) =>
      new Date(a[dateFieldName]).getTime() -
      new Date(b[dateFieldName]).getTime()
  );

export const sortByStartTime = <T extends { startTime: Date }>(
  data: T[]
): T[] => sortByDateFieldName(data, "startTime");

export const sortByMeasurementTime = <T extends { measurementTime: Date }>(
  data: T[]
): T[] => sortByDateFieldName(data, "measurementTime");

export const sortByMeasurementDate = <T extends { measurementDate: Date }>(
  data: T[]
): T[] => sortByDateFieldName(data, "measurementDate");

export const sortByForecastDate = <T extends { forecastDate: Date }>(
  data: T[]
): T[] => sortByDateFieldName(data, "forecastDate");

export const sortByTime = <T extends { time: Date }>(data: T[]): T[] =>
  sortByDateFieldName(data, "time");

export const sortByTimeFrom = <T extends { timeFrom: Date }>(data: T[]): T[] =>
  sortByDateFieldName(data, "timeFrom");

export const valueWithPrefixAndSuffix = (
  value: number | string,
  prefix?: string,
  suffix?: string
): string => `${prefix || ""}${value}${suffix ? ` ${suffix}` : ""}`;

interface ColumnFormatterProps {
  prefix?: string;
  suffix?: string;
}

export type Formatter = (
  value: unknown,
  tooltipValue?: TabulatedTooltipValue
) => string;

export const defaultColumnFormatter =
  ({ prefix, suffix }: ColumnFormatterProps): Formatter =>
  (value: unknown): string => {
    if (value === null) return "—";
    const suffixWithSpace = suffix ? ` ${suffix}` : "";
    return `${prefix || ""}${value}${suffixWithSpace}`;
  };

export const priceFormatter: Formatter = (value: unknown): string => {
  if (value === null || typeof value !== "number") return "£—";

  const isNegative = value < 0;
  const formattedNumber = Math.abs(value).toFixed(2);

  return isNegative ? `-£${formattedNumber}` : `£${formattedNumber}`;
};

export const dateFormatter =
  (dateFormat: DateFormat = DateFormat.DateTime): Formatter =>
  (value: unknown): string => {
    if (value instanceof Date) {
      return formatDateTimeString(value, dateFormat);
    }

    if (value === null || typeof value !== "string") {
      return "—";
    }

    const date = new Date(value);
    if (!date.getDate()) {
      return "—";
    }
    return formatDateTimeString(date, dateFormat);
  };

/**
 * Returns a formatter that formats probability values to a given number of decimal places.
 * The formatter returns a dash if the value is not a valid probability.
 *
 * @param fractionDigits Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
 */
export const probabilityFormatter =
  (fractionDigits: number): Formatter =>
  (value: unknown): string => {
    if (value === null || typeof value !== "number" || value < 0 || value > 1) {
      return "—";
    }

    if (value === 0) {
      return "0";
    }

    return value.toFixed(fractionDigits);
  };
