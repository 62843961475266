import ButtonLink from "components/components/ButtonLink/ButtonLink";
import React from "react";
import routeConstants from "utils/routeConstants";

import {
  StyledErrorText,
  StyledErrorCode,
  StyledRefreshText,
  ErrorMessageWrapper,
} from "./style";

interface Props {
  errorCode?: number;
  errorText: string;
  refreshText?: boolean;
}

const ErrorMessage: React.FC<Props> = ({
  errorCode,
  errorText,
  refreshText,
}: Props) => (
  <ErrorMessageWrapper>
    {errorCode ? (
      <StyledErrorCode data-test-id="error-code"> {errorCode} </StyledErrorCode>
    ) : (
      <br />
    )}
    <StyledErrorText data-test-id="error-text">{errorText}</StyledErrorText>
    <div className="level pb-6 is-mobile">
      <div className="level-item is-justify-content-right">
        {refreshText && (
          <StyledRefreshText>Try refreshing the page or go</StyledRefreshText>
        )}
      </div>
      <div className="level-item is-justify-content-left">
        <ButtonLink to={routeConstants.home}>Back To Homepage</ButtonLink>
      </div>
    </div>
  </ErrorMessageWrapper>
);

export default ErrorMessage;
