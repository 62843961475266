import { getSettlementMessagesJsonData } from "api/dataVisualisation/detailedSystemPrices/detailedSystemPrices";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { SettlementMessageModel } from "models/detailedSystemPrices/settlementModels";
import { useCallback } from "react";

const useSettlementMessagesData = (
  settlementDate: string,
  settlementPeriod: number
): {
  data: SettlementMessageModel[] | null;
  status: RequestStatus;
  fetchData: () => void;
  errorCode: number | null;
} => {
  const { data, status, request, errorCode } = useRequest(
    getSettlementMessagesJsonData
  );

  const fetchData = useCallback(() => {
    request(settlementDate, settlementPeriod);
  }, [request, settlementDate, settlementPeriod]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
    errorCode,
  };
};

export default useSettlementMessagesData;
