import { getDemandForecastDayAheadData } from "api/dataVisualisation/demandForecast/demandForecast";
import { getIndicatedForecastDayAheadData } from "api/dataVisualisation/indicatedForecast/indicatedForecast";
import {
  ChartBody,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import ChartFilterDrawer from "components/dataVisualization/dataPageComponents/ChartFilterDrawer/ChartFilterDrawer";
import ForecastFilter from "components/dataVisualization/dataPageComponents/ForecastFilter/ForecastFilter";
import IndicatedForecastsChart from "components/dataVisualization/indicatedForecasts/IndicatedForecastsChart/IndicatedForecastsChart";
import useIndicatedForecastsChartContainerData from "components/dataVisualization/indicatedForecasts/IndicatedForecastsChartContainer/useIndicatedForecastsChartContainerData";
import IndicatedForecastDataExporter from "components/dataVisualization/indicatedForecasts/IndicatedForecastsDataExporter/IndicatedForecastDataExporter";
import { useDataExport } from "contexts/DataExportContext";
import { useDatasetCategoriesWithVisibilities } from "hooks/useDatasetsCategoriesWithVisibilities";
import useToggle from "hooks/useToggle";
import { Granularity } from "models/Granularity";
import {
  ChartDatasetModel,
  combineDatasetCategories,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { DemandIndicatedForecastDataKeys } from "models/demandForecast/demandForecastDayAhead";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import { IndicatedForecastDataKeys } from "models/indicatedForecast/indicatedForecastDayAhead";
import React, { useEffect, useMemo, useState } from "react";
import { chartColours } from "styles/colours";
import { transformDemandForecastModelData } from "utils/ChartDataUtils/demandForecast/demandForecast";
import { transformIndicatedForecastModelData } from "utils/ChartDataUtils/indicatedForecast/indicatedForecast";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";

const initialDatasets: DatasetCategory<ChartDatasetModel>[] = [
  {
    datasets: [
      {
        colour: chartColours.indicatedMargin,
        dataKey: IndicatedForecastDataKeys.IndicatedMargin,
        displayName: "Indicated Margin Forecast",
      },
      {
        colour: chartColours.indicatedGeneration,
        dataKey: IndicatedForecastDataKeys.IndicatedGeneration,
        displayName: "Indicated Generation Forecast",
      },
      {
        colour: chartColours.indicatedImbalance,
        dataKey: IndicatedForecastDataKeys.IndicatedImbalance,
        displayName: "Indicated Imbalance Forecast",
      },
      {
        colour: chartColours.indicatedDemand,
        dataKey: IndicatedForecastDataKeys.IndicatedDemand,
        displayName: "Indicated Demand Forecast",
      },
      {
        colour: chartColours.nationalDemand,
        dataKey: DemandIndicatedForecastDataKeys.NationalDemand,
        displayName: "National Demand Forecast",
      },
    ],
    id: "datasets",
    title: "Forecasts",
  },
];

const IndicatedForecastsChartContainer: React.FC = () => {
  const forecastFilterTabs = [ForecastFilterOption.dayAndDayAhead];
  const [forecastFilter, setForecastFilter] = useState<ForecastFilterOption>(
    ForecastFilterOption.dayAndDayAhead
  );

  const [showChartFilterDrawer, toggleChartFilterDrawer] = useToggle(false);

  const {
    allDatasetCategories,
    datasetCategoriesToDisplay,
    setMatchingDatasetsVisibility,
  } = useDatasetCategoriesWithVisibilities(initialDatasets);

  const [highlightedDataset, setHighlightedDataset] = useState("");
  const { dispatch: dataExportCountDispatch } = useDataExport();

  const {
    data: rawData,
    fetchData,
    status,
  } = useIndicatedForecastsChartContainerData();

  useEffect(fetchData, [fetchData]);

  const indicatedForecastsChartData = useMemo(
    () =>
      rawData?.indicatedForecastsData?.length
        ? transformIndicatedForecastModelData(
            rawData.indicatedForecastsData,
            combineDatasetCategories(datasetCategoriesToDisplay).filter(
              (x) =>
                x.dataKey !== DemandIndicatedForecastDataKeys.NationalDemand
            )
          )
        : [],
    [rawData?.indicatedForecastsData, datasetCategoriesToDisplay]
  );

  const nationalDemandChartData = useMemo(
    () =>
      rawData?.nationalDemandForecastData?.length
        ? transformDemandForecastModelData(
            rawData.nationalDemandForecastData,
            combineDatasetCategories(datasetCategoriesToDisplay).filter(
              (x) =>
                x.dataKey === DemandIndicatedForecastDataKeys.NationalDemand
            )
          )
        : [],
    [rawData?.nationalDemandForecastData, datasetCategoriesToDisplay]
  );

  const chartData = useMemo(
    () => [...indicatedForecastsChartData, ...nationalDemandChartData],
    [indicatedForecastsChartData, nationalDemandChartData]
  );

  const downloadFile = (
    fileFormat: DataDownloadFormat,
    granularity: Granularity
  ): void => {
    if (granularity === Granularity.National) {
      downloadDataToFile(
        () =>
          getIndicatedForecastDayAheadData(Granularity.National, fileFormat),
        `IndicatedForecasts-National-DayAndDayAhead-${new Date().toISOString()}`,
        fileFormat,
        dataExportCountDispatch
      );

      downloadDataToFile(
        () => getDemandForecastDayAheadData(Granularity.National, fileFormat),
        `DemandForecasts-National-DayAndDayAhead-${new Date().toISOString()}`,
        fileFormat,
        dataExportCountDispatch
      );
    } else {
      downloadDataToFile(
        () => getIndicatedForecastDayAheadData(Granularity.Zonal, fileFormat),
        `IndicatedForecasts-Zonal-DayAndDayAhead-${new Date().toISOString()}`,
        fileFormat,
        dataExportCountDispatch
      );
    }
  };

  return (
    <>
      <ChartHeader>
        <ForecastFilter
          forecastFilter={forecastFilter}
          setForecastFilter={setForecastFilter}
          forecastFilterTabs={forecastFilterTabs}
          showChartFilterDrawer={showChartFilterDrawer}
          toggleChartFilterDrawer={toggleChartFilterDrawer}
        />
      </ChartHeader>
      <ChartBody>
        {showChartFilterDrawer && (
          <ChartFilterDrawer
            highlightedDataset={highlightedDataset}
            sendHighlightedDataset={setHighlightedDataset}
            setMatchingDatasetsVisibility={setMatchingDatasetsVisibility}
            toggleChartFilterDrawer={toggleChartFilterDrawer}
            datasetCategories={allDatasetCategories}
          />
        )}
        <div style={{ width: showChartFilterDrawer ? "80vw" : "100vw" }}>
          <IndicatedForecastsChart
            series={chartData}
            datasetCategories={datasetCategoriesToDisplay}
            sendHighlightedDataset={setHighlightedDataset}
            dataFetchStatus={status}
            rerequestData={fetchData}
          />
        </div>
      </ChartBody>
      <ChartFooter rightAligned>
        <IndicatedForecastDataExporter
          setForecastFilter={setForecastFilter}
          downloadData={downloadFile}
        />
      </ChartFooter>
      ;
    </>
  );
};

export default IndicatedForecastsChartContainer;
