import React from "react";

import { DescriptionText, LearnMore } from "./style";

interface Props {
  openModal: () => void;
  children: JSX.Element;
}

const TabDescription: React.FC<Props> = ({ openModal, children }) => (
  <DescriptionText>
    <p>
      {children}
      <LearnMore type="button" onClick={openModal}>
        Learn more
      </LearnMore>
    </p>
  </DescriptionText>
);

export default TabDescription;
