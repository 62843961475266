import styled from "styled-components";
import colours from "styles/colours";

export const StyledLogoBanner = styled.div`
  background-color: ${colours.elexonBlue};
  display: flex;
  justify-content: space-between;
`;

export const StyledSideLogo = styled.div`
  background-color: ${colours.elexonBlue};
  width: 100px;
  align-self: right;
  margin: 20px 0 0 10px;
`;

// Note: size/horizontal margin matches StyledSideLogo for symmetry
export const AccountMenuContainer = styled.div`
  float: right;
  width: 100px;
  margin-right: 10px;

  position: relative;
`;

export const KinnectLogo = styled.img`
  max-height: 100px;
`;

export const Logo = styled.img`
  max-height: 80px;
  margin: 30px 0;
`;

export const StyledNav = styled.nav`
  border-bottom: 1px solid ${colours.elexonBlack};
`;
