import Fuse from "fuse.js";
import { BmuModel } from "models/bmuData/bmuModel";
import {
  GetOptionLabel,
  GetOptionValue,
} from "react-select/dist/declarations/src/types";

export interface BmuOption {
  nationalGridBmuName: string;
  elexonBmuId: string;
  leadPartyName: string;
  bmuType: string;
  fuelType: string;
}

export type IsMulti = false;

export const getOptionLabel: GetOptionLabel<BmuOption> = (option) =>
  option.elexonBmuId;

export const getOptionValue: GetOptionValue<BmuOption> = (option) =>
  option.nationalGridBmuName;

export const mapModelsToOptions = (rawData: BmuModel[] | null): BmuOption[] =>
  rawData?.map((b) => ({
    elexonBmuId: b.elexonBmUnit ?? "—",
    nationalGridBmuName: b.nationalGridBmUnit,
    leadPartyName: b.leadPartyName ?? "—",
    bmuType: b.bmUnitType ?? "—",
    fuelType: b.fuelType ?? "—",
  })) ?? [];

const keys: (keyof BmuOption)[] = [
  "nationalGridBmuName",
  "elexonBmuId",
  "leadPartyName",
];

export const fuzzyBmuFilter = (
  allOptions: BmuOption[],
  filterString: string
): BmuOption[] => {
  if (!filterString) {
    return allOptions;
  }
  const config: Fuse.IFuseOptions<BmuOption> = {
    includeMatches: true, // This will allow for future highlighting of matches within BMU data (e.g. in bold in the Figma designs)
    threshold: 0.4, // Controls how lenient the fuzzy search is
    keys,
  };
  const fuse = new Fuse(allOptions, config);
  const results = fuse.search(filterString);
  return results.map((r) => r.item);
};
