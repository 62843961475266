export interface DisaggregatedBalancingServicesAdjustmentSummaryModel {
  startTime: Date;
  settlementDate: Date;
  settlementPeriod: number;
  buyActionCount: number;
  sellActionCount: number;
  buyPriceMinimum?: number;
  buyPriceMaximum?: number;
  buyPriceAverage?: number;
  sellPriceMinimum?: number;
  sellPriceMaximum?: number;
  sellPriceAverage?: number;
  buyVolumeTotal?: number;
  sellVolumeTotal?: number;
  netVolume?: number;
}

export enum DisaggregatedBalancingServicesAdjustmentCategoryIds {
  AdjustmentActivities = "adjustmentActivities",
  PriceAdjustments = "priceAdjustments",
  VolumeAdjustments = "volumeAdjustments",
}

export enum DisaggregatedBalancingServicesAdjustmentIds {
  BuyActionCount = "buyActionCount",
  SellActionCount = "sellActionCount",
  BuyPriceAdjustment = "buyPriceAdjustment",
  BuyPriceMin = "buyPriceMinimum",
  BuyPriceMax = "buyPriceMaximum",
  BuyPriceAvg = "buyPriceAverage",
  SellPriceAdjustment = "sellPriceAdjustment",
  SellPriceMin = "sellPriceMinimum",
  SellPriceMax = "sellPriceMaximum",
  SellPriceAvg = "sellPriceAverage",
  BuyVolume = "buyVolumeAdjustment",
  SellVolume = "sellVolumeAdjustment",
  NetVolume = "netVolumeAdjustment",
}
