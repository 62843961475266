import { getDailyEnergyTransmittedJsonData } from "api/dataVisualisation/dailyEnergyTransmitted/dailyEnergyTransmitted";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { DailyEnergyTransmittedModel } from "models/dailyEnergyTransmitted/dailyEnergyTransmittedModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useCallback } from "react";

const useDailyEnergyTransmittedChartData = (
  dateFilter: DateFilterModel
): {
  fetchData: () => void;
  data: DailyEnergyTransmittedModel[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchChartData,
  } = useRequest(getDailyEnergyTransmittedJsonData);

  const fetchData = useCallback(() => {
    fetchChartData(dateFilter);
  }, [dateFilter, fetchChartData]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useDailyEnergyTransmittedChartData;
