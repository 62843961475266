import { SystemWarningsModel } from "models/systemWarnings/systemWarningsModel";
import { SystemWarningsTableModel } from "models/systemWarnings/systemWarningsTableModel";

export enum WarningType {
  AllMessages = "All messages",
  ItSystemsOutage = "IT Systems Outage",
  SOSOTrades = "SO-SO Trades",
  ElectricityMarginNotice = "Electricity Margin Notice",
  CapacityMarketNotice = "Capacity Market Notice",
  HighRiskOfDemandControl = "High Risk of Demand Control",
  DemandControlImminent = "Demand Control Imminent",
  NegativeReserveActivePowerMargin = "Negative Reserve Active Power Margin",
  DemandFlexibilityService = "Demand Flexibility Service",
  Other = "Other",
}

const mapWarningTypeToCase = (warningType: string): string => {
  for (const type of Object.values(WarningType)) {
    if (type.toUpperCase() === warningType) {
      return type;
    }
  }
  return WarningType.Other;
};

export const mapToSystemWarningsRow = (
  systemWarningsData: SystemWarningsModel[]
): SystemWarningsTableModel[] => {
  return systemWarningsData.map((row) => {
    return {
      id: row.publishTime,
      publishTime: new Date(row.publishTime),
      warningType: mapWarningTypeToCase(row.warningType),
      warningText: row.warningText,
    };
  });
};

export const setWarningTypeNullIfAllMessagesSelected = (
  warningType: string
): string | null =>
  warningType === WarningType.AllMessages ? null : warningType;
