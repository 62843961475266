import { faFilter } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import Button from "components/components/Button/Button";
import React from "react";

import { ChartFilterButtonContainer } from "./style";

const ChartFilterButton: React.FC<{
  toggleChartFilterDrawer?: () => void;
  showChartFilterDrawer?: boolean;
}> = ({ toggleChartFilterDrawer, showChartFilterDrawer }) => (
  <ChartFilterButtonContainer data-test-id="chart-filter-button-container">
    {toggleChartFilterDrawer && (
      <Button
        onClick={toggleChartFilterDrawer}
        className={classnames("toggle", { active: showChartFilterDrawer })}
        buttonText="Filter"
        iconName={faFilter}
      />
    )}
  </ChartFilterButtonContainer>
);

export default ChartFilterButton;
