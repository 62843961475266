import { Point, ResponsiveLine, Serie } from "@nivo/line";
import { ControlledInformationTooltip } from "components/components/InformationTooltip/InformationTooltip";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { TabulatedChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/TabulatedChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import React, { useCallback, useMemo, useState } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  stackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayersWithAreas } from "styles/chartStyles/chartLayers";
import { defaultColumnFormatter } from "utils/ChartDataUtils";

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  sendHighlightedDataset: (newSelectedDataset: string) => void;
  dataFetchStatus: RequestStatus;
  rerequestData: () => void;
}

const StackedLineChart: React.FC<Props> = ({
  series,
  datasetCategories,
  sendHighlightedDataset,
  dataFetchStatus,
  rerequestData,
}: Props) => {
  const unitSuffix = "MW";
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [highlightedPoint, setHighlightedPoint] = useState<Point>();

  const onMouseOver = useCallback(
    (point: Point): void => {
      sendHighlightedDataset(point.id.split(".")[0]);
      setTooltipVisible(true);
      setHighlightedPoint(point);
    },
    [sendHighlightedDataset]
  );

  const onMouseLeave = useCallback((): void => {
    sendHighlightedDataset("");
    setTooltipVisible(false);
    setHighlightedPoint(undefined);
  }, [sendHighlightedDataset]);

  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    return (
      <ResponsiveLine
        {...commonChartProps(isChartLoaded, series)}
        axisBottom={chartAxisBottom("Start Time (UTC)", series, isChartLoaded)}
        axisLeft={chartAxisLeft(unitSuffix, isChartLoaded)}
        enableArea
        layers={chartLayersWithAreas(onMouseOver, onMouseLeave)}
        xScale={chartXTimeScale()}
        yScale={stackedLineChartYScale(series)}
      />
    );
  }, [series, dataFetchStatus, onMouseOver, onMouseLeave, unitSuffix]);

  const tooltipConfig: ChartConfiguration = {
    datasetCategories,
    tooltipColumns: [
      {
        id: "value",
        dataKey: "y",
        formatter: defaultColumnFormatter({ suffix: unitSuffix }),
      },
    ],
  };

  return (
    <ChartWithErrorHandling
      series={series}
      datasetCategories={datasetCategories}
      dataFetchStatus={dataFetchStatus}
      rerequestData={rerequestData}
    >
      <>
        {memoisedChart}
        <ControlledInformationTooltip
          isVisible={tooltipVisible}
          setIsVisible={setTooltipVisible}
        >
          <>
            {highlightedPoint && (
              <TabulatedChartTooltip
                series={series}
                config={tooltipConfig}
                text={{
                  label: "Start Time",
                  totalFormatter: defaultColumnFormatter({
                    suffix: unitSuffix,
                  }),
                }}
                options={{
                  interactionType: TooltipInteractionType.Controlled,
                  highlightSelectedDataset: true,
                }}
                inputPoints={[highlightedPoint]}
              />
            )}
          </>
        </ControlledInformationTooltip>
      </>
    </ChartWithErrorHandling>
  );
};

export default StackedLineChart;
