import useApiData from "components/apiDocs/ApiSidebar/useApiData";
import { RequestStatus } from "hooks/useRequest";
import { SwaggerJsonFile } from "models/apiDocumentation/apiDocumentationModels";
import React, { useEffect } from "react";

interface Props {
  children: JSX.Element;
}

interface SwaggerContextType {
  data: SwaggerJsonFile | null;
  status: RequestStatus;
}

export const SwaggerFileContext = React.createContext<
  SwaggerContextType | undefined
>(undefined);

export const SwaggerFileProvider: React.FC<Props> = ({ children }) => {
  const { data, fetchData, status } = useApiData();

  useEffect(fetchData, [fetchData]);

  return (
    <SwaggerFileContext.Provider value={{ data, status }}>
      {children}
    </SwaggerFileContext.Provider>
  );
};

export const useSwaggerFile = (): SwaggerContextType => {
  const context = React.useContext(SwaggerFileContext);

  if (context === undefined) {
    throw new Error(
      "useSwaggerFile must be used within SwaggerFileContext's Provider"
    );
  }

  return context;
};
