import { getWindGenerationPeakForecastData } from "api/dataVisualisation/windGenerationForecast/windGenerationForecast";
import { TooltipWrapper } from "components/components/InformationTooltip/style";
import TileChild from "components/components/TileChild/TileChild";
import TileParent from "components/components/TileParent/TileParent";
import TileRowContainer from "components/components/TileRowContainer/TileRowContainer";
import { WindGenerationSummaryTileModel } from "models/windGeneration/windGenerationSummaryTileModel";
import React, { useEffect, useState } from "react";
import {
  addDaysToDate,
  DateFormat,
  formatDateTimeString,
  startOfDay,
} from "utils/dateHelpers";

import { Header, SummaryTable, ValueCell, ValuesHeader } from "./style";

const timeOrDash = (date?: Date): string =>
  date !== undefined ? formatDateTimeString(date, DateFormat.TimeOnly) : "—";

const WindGenerationSummaryTile: React.FC = () => {
  const [summaryTileData, setSummaryTileData] = useState<{
    today: WindGenerationSummaryTileModel;
    tomorrow: WindGenerationSummaryTileModel;
  }>();

  useEffect(() => {
    const today = startOfDay();
    const tomorrow = addDaysToDate(today, 1);
    getWindGenerationPeakForecastData(today, tomorrow)
      .then((response) => {
        if (response && response.data.length === 0) {
          return;
        }
        setSummaryTileData({
          today: {
            generation: response.data[0].generation,
            startTime: new Date(response.data[0].startTime),
          },
          tomorrow: {
            generation: response.data[1].generation,
            startTime: new Date(response.data[1].startTime),
          },
        });
      })
      // TODO 36876: Add error handling for failed calls to the API and empty data returned
      // eslint-disable-next-line no-console
      .catch(console.log);
  }, []);

  return (
    <TileRowContainer>
      <TileParent>
        <TileChild isMiniTile>
          <SummaryTable>
            <thead>
              <tr>
                <Header colSpan={3}>Peak Wind Generation Forecast</Header>
              </tr>
            </thead>
            <tbody>
              <ValuesHeader>
                <td />
                <td>Peak (MW)</td>
                <td>Time of peak</td>
              </ValuesHeader>
              <tr>
                <td>
                  <TooltipWrapper>Today</TooltipWrapper>
                </td>
                <ValueCell data-test-id="summary-tile-data-peak-today">
                  {summaryTileData?.today.generation ?? "—"}
                </ValueCell>
                <ValueCell data-test-id="summary-tile-data-peak-time-today">
                  {timeOrDash(summaryTileData?.today.startTime)}
                </ValueCell>
              </tr>
              <tr>
                <td>
                  <TooltipWrapper>Tomorrow</TooltipWrapper>
                </td>
                <ValueCell data-test-id="summary-tile-data-peak-tomorrow">
                  {summaryTileData?.tomorrow.generation ?? "—"}
                </ValueCell>
                <ValueCell data-test-id="summary-tile-data-peak-time-tomorrow">
                  {timeOrDash(summaryTileData?.tomorrow.startTime)}
                </ValueCell>
              </tr>
            </tbody>
          </SummaryTable>
        </TileChild>
      </TileParent>
    </TileRowContainer>
  );
};

export default WindGenerationSummaryTile;
