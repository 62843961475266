import { UpdateWithImage } from "models/updateModel";

import {
  derivedDataUpdate,
  detailedSystemPricesUpdate,
  systemSellBuyPricesUpdate,
} from "./updates";

/*
PLEASE READ THE COMMENTS in the whatsNewPageData.ts file before changing this file.
 */

/**
 * These are the updates displayed in the "What's new" section on the home page.
 */
export const recentUpdatesData: UpdateWithImage[] = [
  derivedDataUpdate,
  detailedSystemPricesUpdate,
  systemSellBuyPricesUpdate,
];
