import { Serie } from "@nivo/line";
import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";
import { DemandForecastDaily } from "models/demandForecast/demandForecastDaily";
import { DemandForecastDayAhead } from "models/demandForecast/demandForecastDayAhead";
import { DemandForecastWeekly } from "models/demandForecast/demandForecastWeekly";

const addDemandForecastDayAheadDataToSeries = (
  series: Serie[],
  data: DemandForecastDayAhead
): Serie[] =>
  series.reduce(
    (prev, curr) => [
      ...prev,
      {
        id: curr.id,
        colour: curr.colour,
        data: [
          ...curr.data,
          {
            x: new Date(data.startTime),
            y: data[curr.id as keyof DemandForecastDayAhead],
            settlementPeriod: data.settlementPeriod,
            publishTime: data.publishTime,
          },
        ],
      },
    ],
    [] as Serie[]
  );

const addDemandForecastDailyDataToSeries = (
  series: Serie[],
  data: DemandForecastDaily
): Serie[] =>
  series.reduce(
    (prev, curr) => [
      ...prev,
      {
        id: curr.id,
        colour: curr.colour,
        data: [
          ...curr.data,
          {
            x: new Date(data.forecastDate),
            y: data[curr.id as keyof DemandForecastDaily],
            publishTime: data.publishTime,
          },
        ],
      },
    ],
    [] as Serie[]
  );

const addDemandForecastWeeklyDataToSeries = (
  series: Serie[],
  data: DemandForecastWeekly
): Serie[] =>
  series.reduce(
    (prev, curr) => [
      ...prev,
      {
        id: curr.id,
        colour: curr.colour,
        data: [
          ...curr.data,
          {
            x: new Date(data.weekStartDate),
            y: data[curr.id as keyof DemandForecastWeekly],
            publishTime: data.publishTime,
          },
        ],
      },
    ],
    [] as Serie[]
  );

export const transformDemandForecastModelData = (
  rawData: (
    | DemandForecastDayAhead
    | DemandForecastDaily
    | DemandForecastWeekly
  )[],
  datasets: ChartDatasetModel[]
): Serie[] => {
  const initialSeries = datasets
    .filter((entry) => !entry.isHidden)
    .map<Serie>((entry) => ({
      id: entry.dataKey,
      colour: entry.colour,
      data: [],
    }));

  if ((rawData[0] as DemandForecastWeekly).forecastYear) {
    return (rawData as DemandForecastWeekly[]).reduce(
      addDemandForecastWeeklyDataToSeries,
      initialSeries
    );
  }

  if ((rawData[0] as DemandForecastDaily).forecastDate) {
    return (rawData as DemandForecastDaily[]).reduce(
      addDemandForecastDailyDataToSeries,
      initialSeries
    );
  }

  return (rawData as DemandForecastDayAhead[]).reduce(
    addDemandForecastDayAheadDataToSeries,
    initialSeries
  );
};
