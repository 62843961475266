import React from "react";

import { ColourBlob, Header, Label } from "./style";

export interface LegendItem {
  colour: string;
  label: string;
}

interface Props {
  items: LegendItem[];
}

const Legend: React.FC<Props> = ({ items }: Props) => (
  <div data-test-id="chart-legend">
    <Header>Legend</Header>
    {items.map((x) => (
      <div key={x.label + x.colour}>
        <ColourBlob style={{ backgroundColor: x.colour }} />
        <Label>{x.label}</Label>
      </div>
    ))}
  </div>
);

export default Legend;
