import styled from "styled-components";
import fonts from "styles/fonts";

export const FaqContainer = styled.div`
  display: flex;
  width: 50%;
`;

export const ExpanderSection = styled.div`
  padding-right: 3em;
  padding-bottom: 3em;
`;

export const ContactContainer = styled.div`
  padding-bottom: 3em;
  font-size: ${fonts.smallMedium};

  > * {
    padding-left: 3px;
  }
`;
