import { Serie } from "@nivo/line";
import ComponentWithRequest from "components/components/ComponentWithRequest/ComponentWithRequest";
import { RequestStatus } from "hooks/useRequest";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import React from "react";
import { chartWouldBeEmpty } from "utils/chartHelpers";

import { StyledChartContainer } from "./style";

interface Props {
  children: JSX.Element;
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  dataFetchStatus: RequestStatus;
  rerequestData: () => void;
  containerHeight?: number;
  emptyChartPlaceholder?: React.ReactNode;
}

const ChartWithErrorHandling: React.FC<Props> = ({
  children,
  series,
  datasetCategories,
  dataFetchStatus,
  rerequestData,
  containerHeight,
  emptyChartPlaceholder,
}: Props) => (
  <StyledChartContainer
    data-test-id="chart-container"
    style={{ height: containerHeight }}
  >
    <ComponentWithRequest
      dataFetchStatus={dataFetchStatus}
      rerequestData={rerequestData}
      dataIsEmpty={chartWouldBeEmpty(
        dataFetchStatus,
        series,
        datasetCategories
      )}
      emptyDataPlaceholder={emptyChartPlaceholder}
    >
      {children}
    </ComponentWithRequest>
  </StyledChartContainer>
);

export default ChartWithErrorHandling;
