import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import {
  IndicativeDemandPeakModel,
  Metering,
} from "models/indicativeDemandPeak/indicativeDemandPeak";

export const getIndicativeDemandPeakDataByTriadYear = async (
  metering: Metering,
  triadYear: number,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<IndicativeDemandPeakModel>> => {
  const { data } = await getData(
    `/demand/peak/indicative/${metering}/${triadYear}`,
    undefined,
    format
  );
  return data;
};

export const getIndicativeDemandPeakJsonDataByTriadYear = (
  metering: Metering,
  triadYear: number
): Promise<JsonDataDownloadModel<IndicativeDemandPeakModel>> =>
  getIndicativeDemandPeakDataByTriadYear(metering, triadYear) as Promise<
    JsonDataDownloadModel<IndicativeDemandPeakModel>
  >;
