import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import RollingSystemDemandChartContainer from "components/dataVisualization/rollingSystemDemand/RollingSystemDemandChartContainer/RollingSystemDemandChartContainer";
import React from "react";

const RollingSystemDemand: React.FC = () => (
  <>
    <PageMeta title="Rolling system demand" />
    <PageHeader
      title="Rolling system demand"
      pageDescription={
        <p>
          NESO provides an estimate of the Transmission System Demand (or
          Rolling System Demand) using operational meters and this is derived
          from the total of all Fuel Type categories from the Generation by Fuel
          Type report.
        </p>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <p>
          This includes all demand met from the Transmission system including
          power station auxiliary demand, pumping demand and interconnector
          exports demand. The data is provided as 5 minute average MW values,
          half hourly averages in MW and daily MWh totals. As this section is
          concerned with the breakdown by fuel type of total positive generation
          to meet all Transmission System Demand, any fuel type categories with
          negative values will be capped to zero.
        </p>
      }
    />
    <FullWidthChartSection>
      <RollingSystemDemandChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection
      documentationInformation={{
        chart: ["demand/outturn/summary"],
      }}
    />
  </>
);

export default RollingSystemDemand;
