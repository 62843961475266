import styled from "styled-components";
import fonts from "styles/fonts";

export const IrisFooter = styled.div`
  display: flex;

  > div {
    max-width: 50%;
    font-size: ${fonts.medium};
    margin-right: 1em;
  }
`;
