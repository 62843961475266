import React from "react";

interface Props {
  children: JSX.Element;
}

const TileRowContainer: React.FC<Props> = ({ children }) => (
  <div className="tile is-ancestor">{children}</div>
);

export default TileRowContainer;
