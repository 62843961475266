import styled from "styled-components";
import fonts from "styles/fonts";

export const SectionContainer = styled.div`
  display: flex;

  &.is-column {
    flex-direction: column;
    align-items: center;
    margin: 1em;
  }

  &.is-row {
    justify-content: center;
  }

  > p {
    font-size: ${fonts.smallMedium};
  }

  > hr {
    width: 80%;
  }
`;
