import styled from "styled-components";

export const StyledChartContainer = styled.div`
  position: relative;
`;

interface RectangleContainerProps {
  backgroundColour: string;
  height: string;
  top: string;
}

export const RectangleContainer = styled.svg<RectangleContainerProps>`
  position: absolute;
  background-color: ${({ backgroundColour }: RectangleContainerProps): string =>
    backgroundColour};
  height: ${({ height }: RectangleContainerProps): string => height};
  top: ${({ top }: RectangleContainerProps): string => top};
  width: calc(100%);
  z-index: 1; // z-index required to appear above chart
  pointer-events: none;
`;
