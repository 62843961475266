import React, { ChangeEvent } from "react";

import { StyledInput } from "./style";

interface Props {
  selectedValue: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const InputBox: React.FC<Props> = ({
  selectedValue,
  onChange,
  placeholder,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
  };

  return (
    <StyledInput
      type="text"
      value={selectedValue}
      onChange={handleInputChange}
      placeholder={placeholder}
    />
  );
};

export default InputBox;
