import { Datum, Serie } from "@nivo/line";
import { systemBuySellPricesChartDatasetModel } from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesChartConfig";
import {
  SystemPricesChartModel,
  SystemPricesDataKey,
} from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";

const transformDatapoints = (
  rawData: SystemPricesChartModel[],
  key: keyof SystemPricesChartModel
): Datum[] =>
  rawData.map((d) => ({
    x: new Date(d.startTime),
    y: parseFloat((d[key] as number).toFixed(2)),
    bsadDefaulted: d.bsadDefaulted,
    settlementPeriod: d.settlementPeriod,
    sellPriceAdjustment: d.sellPriceAdjustment,
    buyPriceAdjustment: d.buyPriceAdjustment,
    totalAcceptedOfferVolume: d.totalAcceptedOfferVolume,
    totalAcceptedBidVolume: d.totalAcceptedBidVolume,
    totalAdjustmentSellVolume: d.totalAdjustmentSellVolume,
    totalAdjustmentBuyVolume: d.totalAdjustmentBuyVolume,
  }));

const transformSystemSellBuyData = (
  rawData: SystemPricesChartModel[]
): Datum[] => transformDatapoints(rawData, "systemSellPrice");

const transformNetImbalanceVolumeData = (
  rawData: SystemPricesChartModel[]
): Datum[] => transformDatapoints(rawData, "netImbalanceVolume");

export const transformSystemSellPriceData = (
  rawData: SystemPricesChartModel[]
): Serie[] =>
  systemBuySellPricesChartDatasetModel
    .filter(
      (dataset) => dataset.dataKey === SystemPricesDataKey.systemSellPrice
    )
    .map((dataset) => ({
      id: dataset.dataKey,
      colour: dataset.colour,
      data: transformSystemSellBuyData(rawData),
    }));

export const transformNivData = (rawData: SystemPricesChartModel[]): Serie[] =>
  systemBuySellPricesChartDatasetModel
    .filter(
      (dataset) => dataset.dataKey === SystemPricesDataKey.netImbalanceVolume
    )
    .map((dataset) => ({
      id: dataset.dataKey,
      colour: dataset.colour,
      data: transformNetImbalanceVolumeData(rawData),
    }));
