export interface MarketIndexPriceModel {
  startTime: Date;
  dataProvider: string;
  settlementDate: Date;
  settlementPeriod: number;
  price: number;
  volume: number;
}

export enum DataProviders {
  Apx = "APXMIDP",
  N2ex = "N2EXMIDP",
}
