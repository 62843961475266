import { StyledBoolean } from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
} from "components/components/SortableTable/SortableTable";
import React from "react";

export type DisbsadDetailsTableRow = {
  volume: number;
  soFlag: boolean;
  cost: number | null;
  assetId: string;
  price: number | null;
  service: string;
  isTendered: boolean | null;
  actionId: number;
  id: number;
  storFlag: boolean;
  partyId: string;
};

const AdjustmentActionsDisbsadDetailsTable: React.FC<{
  tableData: DisbsadDetailsTableRow[];
}> = ({ tableData }) => (
  <SortableTable
    headers={{
      actionId: "Action ID",
      partyId: "Party ID",
      assetId: "Asset ID",
      cost: "Cost (£)",
      volume: "Volume (MWh)",
      price: "Price (£/MWh)",
      service: "Service Type",
      isTendered: "Tendered Status",
      soFlag: "SO-flag",
      storFlag: "STOR-flag",
    }}
    items={tableData}
    renderFns={{
      isTendered: ({ isTendered }): TableCellRender => ({
        content: StyledBoolean(isTendered),
      }),
      soFlag: ({ soFlag }): TableCellRender => ({
        content: StyledBoolean(soFlag),
      }),
      storFlag: ({ storFlag }): TableCellRender => ({
        content: StyledBoolean(storFlag),
      }),
    }}
  />
);

export default React.memo(AdjustmentActionsDisbsadDetailsTable);
