import { IrisSummary } from "api/account/client";
import { HttpStatusCode } from "axios";
import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import HeaderNav from "components/components/HeaderNav/HeaderNav";
import { SpinnerSection } from "components/components/Iris/AuthenticatedView/style";
import IrisLandingPageInfo from "components/components/Iris/LandingPageInfo/LandingPageInfo";
import LoginSuccessModal from "components/components/Iris/LoginSuccessModal/LoginSuccessModal";
import { PageTitle } from "components/components/Iris/commonStyle";
import LoadingSpinner from "components/components/LoadingSpinner/LoadingSpinner";
import { SpinnerContainer } from "components/components/LoadingSpinner/style";
import PageMeta from "components/components/Meta/PageMeta";
import { useErrorContext } from "contexts/ErrorContext";
import { useGetDetails, useRegisterUser } from "hooks/useIrisRequest";
import { RequestStatus } from "hooks/useRequest";
import React, { useEffect, useMemo } from "react";

import IrisDetailsPage from "./IrisDetailsPage/IrisDetailsPage";

interface Props {
  children: JSX.Element;
}

const IrisDetailsPageWrapper: React.FC<Props> = ({ children }) => (
  <>
    <PageMeta title="IRIS" />
    <HeaderNav title="IRIS" />
    <FullWidthSection>
      <PageTitle>Insights Real-Time Information Service (IRIS)</PageTitle>
      {children}
    </FullWidthSection>
  </>
);

const IrisDetailsLoadingView: React.FC = () => (
  <IrisDetailsPageWrapper>
    <SpinnerSection>
      <SpinnerContainer>
        <LoadingSpinner isLoading />
        <span>
          Please wait while we create your IRIS credentials. This may take up to
          a minute.
        </span>
      </SpinnerContainer>
    </SpinnerSection>
  </IrisDetailsPageWrapper>
);

const AuthenticatedView: React.FC = () => {
  const { registerError } = useErrorContext();
  const {
    data: getDetailsData,
    request: getDetailsRequest,
    status: getDetailsStatus,
    errorCode: getDetailsErrorCode,
  } = useGetDetails();

  const {
    data: registerData,
    request: registerRequest,
    status: registerStatus,
  } = useRegisterUser();

  const data: IrisSummary | null = useMemo(
    () => getDetailsData ?? registerData ?? null,
    [registerData, getDetailsData]
  );

  useEffect(() => {
    getDetailsRequest();
  }, [getDetailsRequest]);

  useEffect(() => {
    if (
      (getDetailsStatus === RequestStatus.ERRORED &&
        getDetailsErrorCode !== HttpStatusCode.NotFound) ||
      registerStatus === RequestStatus.ERRORED
    ) {
      registerError();
    }
  }, [getDetailsErrorCode, getDetailsStatus, registerError, registerStatus]);

  if (data) {
    return (
      <IrisDetailsPageWrapper>
        <IrisDetailsPage
          data={data}
          fetchIrisDetails={getDetailsRequest}
          fetchIrisDetailsStatus={getDetailsStatus}
        />
      </IrisDetailsPageWrapper>
    );
  }

  const isLoading =
    getDetailsStatus === RequestStatus.IN_PROGRESS ||
    registerStatus === RequestStatus.IN_PROGRESS;
  const hasErrored =
    (getDetailsStatus === RequestStatus.ERRORED &&
      getDetailsErrorCode !== HttpStatusCode.NotFound) ||
    registerStatus === RequestStatus.ERRORED;
  // The useEffect above will register an error to show the general error page,
  // but until that takes effect we will show the loading view
  const isInPreRequestState =
    getDetailsStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED &&
    registerStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED &&
    data === null;

  if (isLoading || hasErrored || isInPreRequestState) {
    return <IrisDetailsLoadingView />;
  }

  return (
    <>
      <IrisLandingPageInfo
        buttonProps={{
          buttonText: "Request IRIS credentials",
          onClick: registerRequest,
        }}
      />
      <LoginSuccessModal registerRequest={registerRequest} />
    </>
  );
};

export default AuthenticatedView;
