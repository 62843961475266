import { Datum, Serie } from "@nivo/line";
import { RollingSystemDemandModel } from "models/rollingSystemDemand/rollingSystemDemandModel";
import { chartColours } from "styles/colours";
import { sortByStartTime } from "utils/ChartDataUtils/index";

export const transformRollingSystemDemandModelData = (
  rawData: RollingSystemDemandModel[]
): Serie[] => {
  const sortedData = sortByStartTime(rawData);
  const data = sortedData.reduce(
    (prev, curr) => [
      ...prev,
      {
        x: new Date(curr.startTime),
        y: curr.demand,
      },
    ],
    [] as Datum[]
  );
  return [
    {
      id: "demand",
      colour: chartColours.rollingSystemDemandData,
      data,
    },
  ];
};
