import { getMarketWideBidOfferAcceptancesJsonData } from "api/dataVisualisation/bidOfferAcceptances/bidOfferAcceptances";
import { getMarketWidePhysicalJsonData } from "api/dataVisualisation/physical/physical";
import {
  filterBmuIds,
  hasAnyFilters,
  MarketViewBmuFilters,
} from "components/components/balancingMechanism/marketView/MarketViewFilters/helpers";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { BidOfferAcceptanceModel } from "models/bidOffer/bidOfferAcceptanceModel";
import { BmuModel } from "models/bmuData/bmuModel";
import {
  BidOfferAcceptanceTableModel,
  PhysicalTabDataset,
  PhysicalTabDataStructure,
  PhysicalTableModel,
} from "models/bmuData/marketViewPhysicalTabModels";
import { PhysicalDataModel } from "models/bmuData/physicalDataModel";
import { JsonDataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { useCallback, useEffect, useState } from "react";
import { matchingBmUnitData } from "utils/marketViewTableUtils";

const mapPhysicalDataToTableData = (
  rawData: JsonDataDownloadModel<PhysicalDataModel>,
  bmuData: BmuModel[]
): PhysicalTableModel[] =>
  rawData.data?.map((row, key) => {
    const { leadParty, bmUnitType } = matchingBmUnitData(
      row.nationalGridBmUnit,
      bmuData
    );
    return {
      id: key,
      bmUnit: row.bmUnit,
      leadParty: leadParty,
      bmUnitType: bmUnitType,
      nationalGridBmUnit: row.nationalGridBmUnit,
      timeFrom: new Date(row.timeFrom),
      levelFrom: row.levelFrom,
      timeTo: new Date(row.timeTo),
      levelTo: row.levelTo,
    };
  });

const mapBoalDataToTableData = (
  rawData: JsonDataDownloadModel<BidOfferAcceptanceModel>,
  bmuData: BmuModel[]
): BidOfferAcceptanceTableModel[] =>
  rawData.data?.map((row, key) => {
    const { leadParty, bmUnitType } = matchingBmUnitData(
      row.nationalGridBmUnit,
      bmuData
    );
    return {
      id: key,
      bmUnit: row.bmUnit,
      leadParty: leadParty,
      bmUnitType: bmUnitType,
      nationalGridBmUnit: row.nationalGridBmUnit,
      timeFrom: new Date(row.timeFrom),
      levelFrom: row.levelFrom,
      timeTo: new Date(row.timeTo),
      levelTo: row.levelTo,
      acceptNumber: row.acceptanceNumber,
      acceptTime: new Date(row.acceptanceTime),
      deemedFlag: row.deemedBoFlag,
      soFlag: row.soFlag,
      storFlag: row.storFlag,
      rrFlag: row.rrFlag,
    };
  });

const getData = async (
  dataset: PhysicalTabDataset,
  settlementDate: Date,
  bmuData: BmuModel[],
  bmUnits?: string[]
): Promise<Partial<PhysicalTabDataStructure>> => {
  let transformedData: PhysicalTableModel[] | BidOfferAcceptanceTableModel[];
  if (dataset === "BOAL") {
    const rawData = await getMarketWideBidOfferAcceptancesJsonData(
      settlementDate,
      bmUnits
    );
    transformedData = mapBoalDataToTableData(rawData, bmuData);
  } else {
    const rawData = await getMarketWidePhysicalJsonData(
      dataset,
      settlementDate,
      bmUnits
    );
    transformedData = mapPhysicalDataToTableData(rawData, bmuData);
  }

  return {
    [dataset]: transformedData,
  };
};

const filterBmusAndGetData = async (
  dataset: PhysicalTabDataset,
  settlementDate: Date,
  bmuFilters: MarketViewBmuFilters,
  bmuData: BmuModel[]
): Promise<Partial<PhysicalTabDataStructure>> => {
  if (hasAnyFilters(bmuFilters)) {
    const bmUnits = filterBmuIds(bmuData, bmuFilters);

    if (bmUnits.length === 0) {
      return {
        [dataset]: [],
      };
    }

    return getData(dataset, settlementDate, bmuData, bmUnits);
  }
  return getData(dataset, settlementDate, bmuData);
};

const useMarketViewPhysicalData = (
  settlementDate: Date,
  bmuFilters: MarketViewBmuFilters,
  bmuData: BmuModel[] | null
): {
  data: Partial<PhysicalTabDataStructure> | null;
  status: RequestStatus;
  request: (dataset: string) => void;
} => {
  const [allData, setAllData] = useState<Partial<PhysicalTabDataStructure>>({});

  const {
    data: singleDatasetData,
    status,
    request,
  } = useRequest(filterBmusAndGetData);

  const fetchData = useCallback(
    (dataset: string) => {
      if (dataset && bmuData) {
        request(
          dataset as PhysicalTabDataset,
          settlementDate,
          bmuFilters,
          bmuData
        );
      }
    },
    [request, settlementDate, bmuFilters, bmuData]
  );

  useEffect(() => {
    setAllData((curr) => {
      return { ...curr, ...singleDatasetData };
    });
  }, [singleDatasetData]);

  useEffect(() => {
    // Reset to ensure we re-run all queries
    setAllData({});
  }, [settlementDate, bmuFilters]);

  return {
    data: allData,
    status,
    request: fetchData,
  };
};

export default useMarketViewPhysicalData;
