import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import PreviousUpdate from "components/components/Updates/PreviousUpdates/PreviousUpdate";
import {
  AllUpdatesLoaded,
  PreviousUpdatesListContainer,
  LoadMore,
  PreviousUpdatesTitle,
} from "components/components/Updates/PreviousUpdates/style";
import { Update } from "models/updateModel";
import React, { useState } from "react";

interface Props {
  updates: Update[];
}

const PreviousUpdatesList: React.FC<Props> = ({ updates }: Props) => {
  const [numberOfUpdatesToShow, setNumberOfUpdatesToShow] = useState(3);
  const loadMoreClickHandler = (): void => {
    setNumberOfUpdatesToShow((prevState) => prevState + 3);
  };
  const allUpdatesLoaded = numberOfUpdatesToShow >= updates.length;

  return (
    <FullWidthSection>
      <PreviousUpdatesListContainer>
        <PreviousUpdatesTitle>Previous updates</PreviousUpdatesTitle>
        {updates.slice(0, numberOfUpdatesToShow).map((u) => (
          <PreviousUpdate key={u.title + u.date} update={u} />
        ))}
        {allUpdatesLoaded ? (
          <AllUpdatesLoaded>No more updates available</AllUpdatesLoaded>
        ) : (
          <LoadMore
            onClick={loadMoreClickHandler}
            role="button"
            aria-label="Load more"
          >
            Load more
          </LoadMore>
        )}
      </PreviousUpdatesListContainer>
    </FullWidthSection>
  );
};

export default PreviousUpdatesList;
