import TileRowContainer from "components/components/TileRowContainer/TileRowContainer";
import { OtherUpdate } from "components/components/Updates/OtherUpdate";
import { UpdateWithImage } from "models/updateModel";
import React from "react";

interface Props {
  data: UpdateWithImage[];
  showNewBubbles?: boolean;
  showDates?: boolean;
}

const RecentUpdates: React.FC<Props> = ({
  data,
  showNewBubbles = false,
  showDates = true,
}: Props) => (
  <TileRowContainer>
    <>
      {data.map((u) => (
        <OtherUpdate
          key={u.title + u.date}
          update={u}
          showNewBubble={showNewBubbles}
          showDate={showDates}
        />
      ))}
    </>
  </TileRowContainer>
);

export default RecentUpdates;
