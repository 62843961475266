import { getIndicativeDemandPeakDataByTriadYear } from "api/dataVisualisation/indicativeDemandPeak/indicativeDemandPeak";
import { getIndicativeDemandPeakTriadDataByTriadYear } from "api/dataVisualisation/indicativeDemandPeak/indicativeDemandPeakTriad";
import { Dataset } from "components/dataVisualization/CheckboxDataExporter/utils";
import { DownloadCallback } from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/MultiDatasetExporter";
import { MeteringDataType } from "components/dataVisualization/indicativeDemandPeak/IndicativeDemandPeakContainer/IndicativeDemandPeakChartContainer";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";

export const datasets: { [key: string]: Dataset } = {
  SettlementData: {
    name: "Settlement Data",
    id: "settlement-data",
  },
  OperationalData: {
    name: "Operational Data",
    id: "operational-data",
  },
};

export const transformTriadDownloadCallbacks = (
  datasetsToDownload: string[],
  date: Date
): DownloadCallback[] => {
  const operationalDataCallbacks = datasetsToDownload.includes(
    datasets.OperationalData.id
  )
    ? [
        {
          download: (format: DataDownloadFormat) =>
            getIndicativeDemandPeakDataByTriadYear(
              MeteringDataType.Operational,
              date.getFullYear(),
              format
            ),
          filePrefix: `OperationalData-IndicativeDemandPeak`,
        },
        {
          download: (format: DataDownloadFormat) =>
            getIndicativeDemandPeakTriadDataByTriadYear(
              MeteringDataType.Operational,
              date.getFullYear(),
              format
            ),
          filePrefix: `OperationalData-IndicativeDemandPeakTriad`,
        },
      ]
    : [];

  const settlementDataCallbacks = datasetsToDownload.includes(
    datasets.SettlementData.id
  )
    ? [
        {
          download: (format: DataDownloadFormat) =>
            getIndicativeDemandPeakDataByTriadYear(
              MeteringDataType.Settlement,
              date.getFullYear(),
              format
            ),
          filePrefix: `SettlementData-IndicativeDemandPeak`,
        },
        {
          download: (format: DataDownloadFormat) =>
            getIndicativeDemandPeakTriadDataByTriadYear(
              MeteringDataType.Settlement,
              date.getFullYear(),
              format
            ),
          filePrefix: `SettlementData-IndicativeDemandPeakTriad`,
        },
      ]
    : [];

  return [...operationalDataCallbacks, ...settlementDataCallbacks];
};
