import { Datum, Serie } from "@nivo/line";
import { NonBmStorModel } from "models/nonBmStor/nonBmStorModel";
import { chartColours } from "styles/colours";
import { sortByStartTime } from "utils/ChartDataUtils";

const mapNonBmStorToDatum = (rawData: NonBmStorModel): Datum => ({
  x: new Date(rawData.startTime),
  y: rawData.generation,
  settlementPeriod: rawData.settlementPeriod,
});

export const transformNonBmStorModelData = (
  rawData: NonBmStorModel[]
): Serie[] => {
  if (!rawData.length) {
    return [];
  }

  return [
    {
      id: "nonBmStor",
      colour: chartColours.nonBmStor,
      data: sortByStartTime(rawData).map(mapNonBmStorToDatum),
    },
  ];
};
