import styled from "styled-components";

export const StyledSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin-right: 80px;
  flex-direction: row;
  flex-direction: ${(props: { rightAligned?: boolean }): string =>
    props.rightAligned ? "row-reverse" : "row"};
`;
