export const removeNewlines = (text: string): string => {
  return text
    .replace(/\\n\\n/g, "<br>")
    .replace(/\\n/g, " ")
    .trim();
};

export const MAX_CHARS_IN_ONE_LINE = 136;
export const MAX_CHARS_IN_MESSAGE = 14;

export const MAX_CHARS_IN_TWO_LINES =
  MAX_CHARS_IN_ONE_LINE * 2 - MAX_CHARS_IN_MESSAGE;

const getMaxIndex = (firstLineBreakIndex: number): number => {
  if (firstLineBreakIndex > MAX_CHARS_IN_TWO_LINES || firstLineBreakIndex < 0) {
    return MAX_CHARS_IN_TWO_LINES;
  } else if (firstLineBreakIndex > MAX_CHARS_IN_ONE_LINE) {
    return firstLineBreakIndex;
  } else {
    return firstLineBreakIndex + (MAX_CHARS_IN_ONE_LINE - MAX_CHARS_IN_MESSAGE);
  }
};

export const truncate = (textToTruncate: string): string => {
  const lines = textToTruncate.split("<br>");
  const firstTwoLines = lines.slice(0, 2).join("<br>");
  const firstLineBreakIndex = firstTwoLines.indexOf("<br>");

  return firstTwoLines.slice(0, getMaxIndex(firstLineBreakIndex)) + "... ";
};
