import styled from "styled-components";
import colours from "styles/colours";

export const DescriptionText = styled.div`
  width: 65%;
`;

export const LearnMore = styled.button`
  display: block;
  border: none;
  color: ${colours.blueGrey};
  background-color: transparent;
  font-size: 1em;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;

  :hover {
    color: ${colours.charcoal};
  }
`;
