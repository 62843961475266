import { getBalancingServicesVolumeData } from "api/dataVisualisation/balancingServicesVolume/balancingServicesVolume";
import { getAllBidOfferData } from "api/dataVisualisation/bidOffer/bidOffer";
import { getMarketWideBidOfferAcceptancesData } from "api/dataVisualisation/bidOfferAcceptances/bidOfferAcceptances";
import {
  getMarketWideDynamicData,
  getMarketWideDynamicRatesData,
} from "api/dataVisualisation/dynamic/dynamic";
import { getMarketWidePhysicalData } from "api/dataVisualisation/physical/physical";
import { DownloadCallback } from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/MultiDatasetExporter";
import { isDynamicDataset } from "models/bmuData/dynamicDataModel";
import { isRatesDataset } from "models/bmuData/dynamicRatesDataModel";
import { isPhysicalDataset } from "models/bmuData/physicalDataModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";

export const transformMarketWideDownloadCallbacks = (
  datasets: string[],
  date: Date,
  bmUnits?: string[]
): DownloadCallback[] => {
  const physicalDatasets = datasets.filter(isPhysicalDataset);
  const physicalCallbacks = physicalDatasets.map((dataset) => ({
    download: (format: DataDownloadFormat) =>
      getMarketWidePhysicalData(dataset, date, bmUnits, format),
    filePrefix: `PhysicalData-${dataset}`,
  }));

  const dynamicDatasets = datasets.filter(isDynamicDataset);
  const dynamicCallbacks = dynamicDatasets.map((dataset) => ({
    download: (format: DataDownloadFormat) =>
      getMarketWideDynamicData(dataset, date, bmUnits, format),
    filePrefix: `DynamicData-${dataset}`,
  }));

  const ratesDatasets = datasets.filter(isRatesDataset);
  const ratesCallbacks = ratesDatasets.map((dataset) => ({
    download: (format: DataDownloadFormat) =>
      getMarketWideDynamicRatesData(dataset, date, bmUnits, format),
    filePrefix: `DynamicRatesData-${dataset}`,
  }));

  const boalCallbacks = datasets.includes("BOAL")
    ? [
        {
          download: (format: DataDownloadFormat) =>
            getMarketWideBidOfferAcceptancesData(date, bmUnits, format),
          filePrefix: "BidOfferAcceptancesData",
        },
      ]
    : [];

  const bodCallbacks = datasets.includes("BOD")
    ? [
        {
          download: (format: DataDownloadFormat) =>
            getAllBidOfferData(date, bmUnits, format),
          filePrefix: "BidOfferData",
        },
      ]
    : [];

  const dateFilter = new DateFilterModel(date, date);
  const qasCallbacks = datasets.includes("QAS")
    ? [
        {
          download: (format: DataDownloadFormat) =>
            getBalancingServicesVolumeData(dateFilter, bmUnits, format),
          filePrefix: "BalancingServicesVolumeData",
        },
      ]
    : [];

  return [
    ...physicalCallbacks,
    ...dynamicCallbacks,
    ...ratesCallbacks,
    ...boalCallbacks,
    ...bodCallbacks,
    ...qasCallbacks,
  ];
};

export const marketViewCheckboxDataGroups = [
  {
    id: 0,
    name: "Physical data",
    parentId: null,
  },
  {
    id: 1,
    name: "Bid offer acceptance level (BOAL)",
    parentId: 0,
    datasets: ["BOAL"],
  },
  {
    id: 2,
    name: "Final physical notification (FPN)",
    parentId: 0,
    datasets: ["PN"],
  },
  {
    id: 3,
    name: "Maximum export limit (MEL)",
    parentId: 0,
    datasets: ["MELS"],
  },
  {
    id: 4,
    name: "Maximum import limit (MIL)",
    parentId: 0,
    datasets: ["MILS"],
  },
  {
    id: 5,
    name: "Quiescent physical notification (QPN)",
    parentId: 0,
    datasets: ["QPN"],
  },
  {
    id: 6,
    name: "Dynamic data",
    parentId: null,
  },
  {
    id: 7,
    name: "Maximum delivery",
    parentId: 6,
    datasets: ["MDV", "MDP"],
  },
  {
    id: 8,
    name: "Minimum periods",
    parentId: 6,
    datasets: ["MZT", "MNZT"],
  },
  {
    id: 9,
    name: "Notices",
    parentId: 6,
    datasets: ["NDZ", "NTB", "NTO"],
  },
  {
    id: 10,
    name: "Rates",
    parentId: 6,
    datasets: ["RDRE", "RURE", "RDRI", "RURI"],
  },
  {
    id: 11,
    name: "Stable limits",
    parentId: 6,
    datasets: ["SIL", "SEL"],
  },
  {
    id: 12,
    name: "Bid offer data (BOD)",
    parentId: null,
    datasets: ["BOD"],
  },
  {
    id: 13,
    name: "Balancing services volume data (QAS)",
    parentId: null,
    datasets: ["QAS"],
  },
];
