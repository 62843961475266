import TabDescription from "components/components/Tabs/TabDescription/TabDescription";
import DynamicModal from "components/components/balancingMechanism/balancingMechanismTabs/descriptionModals/DynamicModal";
import React, { PropsWithChildren, useState } from "react";

const Dynamic: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <DynamicModal
        isOpen={modalIsOpen}
        close={(): void => setModalIsOpen(false)}
      />
      <TabDescription openModal={(): void => setModalIsOpen(true)}>
        <>
          Dynamic data displays the RURE, RURI, RDRI, RDRE, MZT, MNZT, SEL, SIL,
          MDV, and MDP data for the selected time frame.
        </>
      </TabDescription>
      {children}
    </>
  );
};
export default Dynamic;
