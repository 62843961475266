import {
  ModalHeader,
  ModalInformation,
  ModalSection,
} from "components/components/Modal/commonStyle";

interface Props {
  infoText?: string;
}

const DataExporterHeader: React.FC<Props> = ({ infoText }) => (
  <ModalSection>
    <div>
      <ModalHeader id="export-modal-header">
        Select the data you want to export
      </ModalHeader>
      {infoText && <ModalInformation>{infoText}</ModalInformation>}
    </div>
  </ModalSection>
);

export default DataExporterHeader;
