import Swatch from "components/components/Swatch/Swatch";
import SummaryTile from "components/dataVisualization/dataPageComponents/SummaryTile/SummaryTile";
import {
  Header,
  NoEmphasisNoWrapCell,
  SummaryTable,
  SwatchCell,
  ValueCellNoWrap,
  ValuesHeaderRow,
} from "components/dataVisualization/dataPageComponents/SummaryTile/commonStyle";
import { MeteringDataType } from "components/dataVisualization/indicativeDemandPeak/IndicativeDemandPeakContainer/IndicativeDemandPeakChartContainer";
import { IndicativeDemandPeakModel } from "models/indicativeDemandPeak/indicativeDemandPeak";
import {
  pointShapeForIndicativeDemandPeakDataset,
  TriadPeak,
} from "models/indicativePeakDemandChartData";
import React from "react";
import { indicativeDemandPeakChartColours } from "styles/colours";
import { getTriadPeaksFromRawPeakData } from "utils/ChartDataUtils/indicativeDemandPeak/indicativeDemandPeak";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

import { SwatchWrapper } from "./style";

type Props = {
  triadPeaksRaw: IndicativeDemandPeakModel[] | undefined;
  showOperationalData: boolean;
};

const DemandForecastSummaryTile: React.FC<Props> = ({
  triadPeaksRaw,
  showOperationalData,
}) => {
  const triadPeaks = getTriadPeaksFromRawPeakData(triadPeaksRaw ?? []);
  const peaksWithBlanks: Partial<TriadPeak>[] = [null, null, null].map(
    (_, index) => triadPeaks?.[index] ?? {}
  );

  return (
    <SummaryTile>
      <SummaryTable tableLayout="auto" data-test-id="triad-summary-table">
        <thead>
          <tr>
            <Header colSpan={3}>
              Triad demand (
              {showOperationalData
                ? MeteringDataType.Operational
                : MeteringDataType.Settlement}{" "}
              metering)
            </Header>
          </tr>
        </thead>
        <tbody>
          <ValuesHeaderRow>
            <td />
            <NoEmphasisNoWrapCell>Date</NoEmphasisNoWrapCell>
            <NoEmphasisNoWrapCell>Time (UTC)</NoEmphasisNoWrapCell>
            <NoEmphasisNoWrapCell>Peak (MW)</NoEmphasisNoWrapCell>
          </ValuesHeaderRow>
          {peaksWithBlanks.map(({ date, time, demand, id }, index) => (
            <tr
              data-test-id={`triad-summary-table-row-${index + 1}`}
              key={id ?? `empty-row-${index}`}
            >
              <SwatchCell>
                {id && (
                  <SwatchWrapper>
                    <Swatch
                      colour={indicativeDemandPeakChartColours[id]}
                      shape={pointShapeForIndicativeDemandPeakDataset[id]}
                    />
                  </SwatchWrapper>
                )}
              </SwatchCell>
              <ValueCellNoWrap>
                {date ? formatDateTimeString(date, DateFormat.DateOnly) : "—"}
              </ValueCellNoWrap>
              <ValueCellNoWrap>{time ?? "—"}</ValueCellNoWrap>
              <ValueCellNoWrap>{demand ?? "—"}</ValueCellNoWrap>
            </tr>
          ))}
        </tbody>
      </SummaryTable>
    </SummaryTile>
  );
};

export default DemandForecastSummaryTile;
