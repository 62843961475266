import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import Icon, { SvgIconName } from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import React from "react";

import StyledButton from "./style";

interface Props {
  children?: JSX.Element;
  onClick?: () => void;
  ariaLabel?: string;
  buttonText?: string;
  className?: string;
  disabled?: boolean;
  iconName?: IconProp | SvgIconName;
  iconColour?: string;
  iconSize?: IconSize;
  type?: "submit" | "reset" | "button";
  dataTestId?: string;
}

const Button: React.FC<Props> = ({
  children,
  onClick,
  buttonText,
  className,
  disabled,
  iconName,
  iconColour,
  iconSize,
  ariaLabel,
  type = "button",
  dataTestId,
}: Props) => (
  <StyledButton
    onClick={onClick}
    className={classNames("button", className)}
    disabled={disabled}
    aria-label={ariaLabel}
    type={type}
    data-test-id={dataTestId}
  >
    {iconName !== undefined && (
      <Icon iconName={iconName} colour={iconColour} size={iconSize} />
    )}
    {buttonText && <span>{buttonText}</span>}
    {children}
  </StyledButton>
);

export default Button;
