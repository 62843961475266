import { getDemandControlInstructionsData } from "api/dataVisualisation/demandControlInstructions/demandControlInstructions";
import { getSystemWarningsData } from "api/dataVisualisation/systemWarnings/systemWarnings";
import { Dataset } from "components/dataVisualization/CheckboxDataExporter/utils";
import { DownloadCallback } from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/MultiDatasetExporter";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";

export const datasets: { [key: string]: Dataset } = {
  SystemWarnings: {
    name: "System Warnings",
    id: "SYS_WARN",
  },
  DemandControlInstruction: {
    name: "Demand Control Instructions",
    id: "DCI",
  },
};

export const transformEventDownloadCallbacks = (
  datasetsToDownload: string[],
  dateFilter: DateFilterModel
): DownloadCallback[] => {
  const systemWarningCallbacks = datasetsToDownload.includes(
    datasets.SystemWarnings.id
  )
    ? [
        {
          download: (format: DataDownloadFormat) =>
            getSystemWarningsData(dateFilter, null, format),
          filePrefix: `SystemWarnings`,
        },
      ]
    : [];

  const demandControlInstructionCallbacks = datasetsToDownload.includes(
    datasets.DemandControlInstruction.id
  )
    ? [
        {
          download: (format: DataDownloadFormat) =>
            getDemandControlInstructionsData(dateFilter, format),
          filePrefix: `DemandControlInstructions`,
        },
      ]
    : [];

  return [...systemWarningCallbacks, ...demandControlInstructionCallbacks];
};
