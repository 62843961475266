import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import AuthenticatedView from "components/components/Iris/AuthenticatedView/AuthenticatedView";
import LandingPageInfo from "components/components/Iris/LandingPageInfo/LandingPageInfo";
import { useErrorContext } from "contexts/ErrorContext";
import React from "react";
import { loginRequest } from "utils/authUtils";
import routeConstants from "utils/routeConstants";

const Iris: React.FC = () => {
  const { instance } = useMsal();
  const { registerError } = useErrorContext();

  const handleLogin = async (): Promise<void> => {
    try {
      await instance.loginRedirect({
        ...loginRequest,
        redirectUri: routeConstants.home,
      });
    } catch (e) {
      registerError();
    }
  };

  return (
    <>
      <AuthenticatedTemplate>
        <AuthenticatedView />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LandingPageInfo
          buttonProps={{
            buttonText: "Register / Log in",
            onClick: handleLogin,
          }}
        />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Iris;
