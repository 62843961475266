import { SummaryTable } from "components/dataVisualization/DetailedSystemPrices/style";
import { renderNumericTableCell } from "components/dataVisualization/DetailedSystemPrices/utils";
import { SettlementCalculationSummaryModelTransformed } from "models/detailedSystemPrices/settlementModels";
import React from "react";

const SystemPricesSummaryTable: React.FC<{
  data: SettlementCalculationSummaryModelTransformed | null;
}> = ({ data }) => (
  <SummaryTable tableLayout="auto">
    <tbody>
      <tr>
        <th className="hidden-header">Header1</th>
        <th>Cost</th>
        <th className="hidden-header">Header2</th>
        <th>Volume</th>
        <th>Adjuster</th>
        <th>Value</th>
        <th>Price derivation code</th>
        <th>Sell / Buy price</th>
      </tr>
      <tr className="hide_right hide_right_second">
        <td>Main Price</td>
        <td>{renderNumericTableCell(data?.mainPrice?.cost)}</td>
        <td>÷</td>
        <td>{renderNumericTableCell(data?.mainPrice?.volume)}</td>
        <td className="positioned-block">
          <span className="value">{data?.adjuster ?? "—"}</span>
        </td>
        <td>{renderNumericTableCell(data?.mainPrice?.value)}</td>
        <td className="positioned-block">
          <span className="value square-purple">
            {data?.priceDerivationCode ?? "—"}
          </span>
        </td>
        <td className="positioned-block">
          <span className="value">
            {renderNumericTableCell(data?.systemSellPrice)}
          </span>
        </td>
      </tr>
      <tr className="hide_bottom">
        <td>Market Price</td>
        <td>{renderNumericTableCell(data?.marketPrice?.cost)}</td>
        <td>÷</td>
        <td>{renderNumericTableCell(data?.marketPrice?.volume)}</td>
        <td></td>
        <td>{renderNumericTableCell(data?.marketPrice?.value)}</td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </SummaryTable>
);

export default SystemPricesSummaryTable;
