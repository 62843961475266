import { getBalancingServicesVolumeData } from "api/dataVisualisation/balancingServicesVolume/balancingServicesVolume";
import { getBidOfferData } from "api/dataVisualisation/bidOffer/bidOffer";
import { getPerBmuBidOfferAcceptancesData } from "api/dataVisualisation/bidOfferAcceptances/bidOfferAcceptances";
import {
  getDynamicData,
  getDynamicRatesData,
} from "api/dataVisualisation/dynamic/dynamic";
import { getPerBmuPhysicalData } from "api/dataVisualisation/physical/physical";
import { DownloadCallback } from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/MultiDatasetExporter";
import { isDynamicDataset } from "models/bmuData/dynamicDataModel";
import { isRatesDataset } from "models/bmuData/dynamicRatesDataModel";
import { isPhysicalDataset } from "models/bmuData/physicalDataModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";

export const transformPerBmuDownloadCallbacks = (
  selectedDatasets: string[],
  dateFilter: DateFilterModel,
  bmuId: string
): DownloadCallback[] => {
  const downloadCallbacks: DownloadCallback[] = [];

  const physicalDatasets = selectedDatasets.filter(isPhysicalDataset);

  if (physicalDatasets.length > 0) {
    downloadCallbacks.push({
      download: (format: DataDownloadFormat) =>
        getPerBmuPhysicalData(dateFilter, bmuId, physicalDatasets, format),
      filePrefix: "PhysicalData",
    });
  }

  const dynamicDatasets = selectedDatasets.filter(isDynamicDataset);
  if (dynamicDatasets.length > 0) {
    downloadCallbacks.push({
      download: (format: DataDownloadFormat) =>
        getDynamicData(dateFilter, bmuId, dynamicDatasets, format),
      filePrefix: "DynamicData",
    });
  }

  const ratesDatasets = selectedDatasets.filter(isRatesDataset);
  if (ratesDatasets.length > 0) {
    downloadCallbacks.push({
      download: (format: DataDownloadFormat) =>
        getDynamicRatesData(dateFilter, bmuId, ratesDatasets, format),
      filePrefix: "DynamicRatesData",
    });
  }

  if (selectedDatasets.includes("BOD")) {
    downloadCallbacks.push({
      download: (format: DataDownloadFormat) =>
        getBidOfferData(dateFilter, bmuId, format),
      filePrefix: "BidOfferData",
    });
  }

  if (selectedDatasets.includes("BOAL")) {
    downloadCallbacks.push({
      download: (format: DataDownloadFormat) =>
        getPerBmuBidOfferAcceptancesData(dateFilter, bmuId, format),
      filePrefix: "BidOfferAcceptancesData",
    });
  }

  if (selectedDatasets.includes("QAS")) {
    downloadCallbacks.push({
      download: (format: DataDownloadFormat) =>
        getBalancingServicesVolumeData(dateFilter, [bmuId], format),
      filePrefix: "BalancingServicesVolumeData",
    });
  }

  return downloadCallbacks;
};

export const bmuCheckboxDataGroups = [
  {
    id: 0,
    name: "Physical data",
    parentId: null,
  },
  {
    id: 1,
    name: "Bid offer acceptance level (BOAL)",
    parentId: 0,
    datasets: ["BOAL"],
  },
  {
    id: 2,
    name: "Final physical notification (FPN)",
    parentId: 0,
    datasets: ["PN"],
  },
  {
    id: 3,
    name: "Maximum export limit (MEL)",
    parentId: 0,
    datasets: ["MELS"],
  },
  {
    id: 4,
    name: "Maximum import limit (MIL)",
    parentId: 0,
    datasets: ["MILS"],
  },
  {
    id: 5,
    name: "Quiescent physical notification (QPN)",
    parentId: 0,
    datasets: ["QPN"],
  },
  {
    id: 6,
    name: "Stable export limit (SEL)",
    parentId: 0,
    datasets: ["SEL"],
  },
  {
    id: 7,
    name: "Stable import limit (SIL)",
    parentId: 0,
    datasets: ["SIL"],
  },
  {
    id: 8,
    name: "Dynamic data",
    parentId: null,
  },
  {
    id: 9,
    name: "Maximum delivery",
    parentId: 8,
    datasets: ["MDV", "MDP"],
  },
  {
    id: 10,
    name: "Minimum periods",
    parentId: 8,
    datasets: ["MZT", "MNZT"],
  },
  {
    id: 11,
    name: "Notices",
    parentId: 8,
    datasets: ["NDZ", "NTB", "NTO"],
  },
  {
    id: 12,
    name: "Rates",
    parentId: 8,
    datasets: ["RDRE", "RURE", "RDRI", "RURI"],
  },
  {
    id: 13,
    name: "Stable limits",
    parentId: 8,
    datasets: ["SIL", "SEL"],
  },
  {
    id: 14,
    name: "Bid offer data",
    parentId: null,
  },
  {
    id: 15,
    name: "Bid offer pairs",
    parentId: 14,
    datasets: ["BOD"],
  },
  {
    id: 16,
    name: "Final physical notification (FPN)",
    parentId: 14,
    datasets: ["PN"],
  },
  {
    id: 17,
    name: "Balancing services volume data",
    parentId: null,
    datasets: ["QAS"],
  },
];
