import { Point } from "@nivo/line";
import { getSystemPricesData } from "api/dataVisualisation/systemSellAndBuyPrices/systemSellAndBuyPrices";
import { SystemPricesMultiChartTitle } from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesChartContainer/style";
import useSystemSellBuyPricesChartContainerData from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesChartContainer/useSystemSellBuyPricesChartContainerData";
import NivChart from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesCharts/NivChart";
import SellBuyPricesChart from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesCharts/SellBuyPricesChart";
import SellBuyPricesTooltip from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesTooltip/SystemSellBuyPricesTooltip";
import MultiChartLineContainer from "components/dataVisualization/chartComponents/MultiChartLineContainer/MultiChartLineContainer";
import {
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import { useDataExport } from "contexts/DataExportContext";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import { SystemPricesDataKey } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import React, { useEffect, useMemo, useState } from "react";
import {
  transformNivData,
  transformSystemSellPriceData,
} from "utils/ChartDataUtils/systemSellAndBuyPrices/systemSellAndBuyPrices";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  formatNumberToFixedWithoutTrailingZeroes,
  getXTimeValuesExtrema,
} from "utils/chartHelpers";
import {
  addDaysToDate,
  ceilToHalfHour,
  DAYS_IN_ONE_WEEK,
} from "utils/dateHelpers";

const dateSelectorTabs = [DateSelectorTabs.OneWeek];

const SystemSellBuyPricesChartContainer: React.FC = () => {
  const defaultEndDate = ceilToHalfHour(new Date());
  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.OneWeek
  );

  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addDaysToDate(defaultEndDate, -7), defaultEndDate)
  );

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    setDateFilter(newDateFilter.restrictToMaxOneWeek());
    setActiveDateSelectorTab(newActiveTab);
  };

  const {
    data: rawData,
    status: status,
    fetchData: fetchData,
  } = useSystemSellBuyPricesChartContainerData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const sellBuyData = useMemo(
    () => (rawData ? transformSystemSellPriceData(rawData) : []),
    [rawData]
  );
  const nivData = useMemo(
    () => (rawData ? transformNivData(rawData) : []),
    [rawData]
  );

  const { dispatch: dataExportCountDispatch } = useDataExport();

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getSystemPricesData(dateFilter, fileFormat),
      `SystemSellAndBuyPrices-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const allData = [...sellBuyData, ...nivData];
  const { maxXAxisValue, minXAxisValue } = getXTimeValuesExtrema(allData);

  // The following calculates the chart indent value by multiplying a fixed factor
  // by the maximum number of decimal places present in the y-axis tick values
  const chartIndent = useMemo(() => {
    const maxchartIndent = 30;
    const maxPossibleDecimalPlaces = 9; // based on precision in database
    let maxActualDecimalPlaces = 0;

    for (const dataset of nivData) {
      for (const datapoint of dataset.data) {
        if (typeof datapoint.y === "number") {
          const significantDigitsAfterDecimalPoint =
            formatNumberToFixedWithoutTrailingZeroes(datapoint.y).replace(
              ".",
              ""
            ).length;

          maxActualDecimalPlaces = Math.max(
            maxActualDecimalPlaces,
            significantDigitsAfterDecimalPoint
          );
        }
      }
    }

    return (
      Math.ceil(maxchartIndent / maxPossibleDecimalPlaces) *
      maxActualDecimalPlaces
    );
  }, [nivData]);
  const tooltip = (point: Point): JSX.Element => (
    <SellBuyPricesTooltip
      allChartData={allData}
      minXAxisValue={minXAxisValue}
      maxXAxisValue={maxXAxisValue}
      highlightedPoint={point}
    />
  );

  return (
    <>
      <ChartHeader>
        <ChartFilter
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={DAYS_IN_ONE_WEEK}
        />
      </ChartHeader>
      <MultiChartLineContainer chartIndent={chartIndent}>
        <>
          <SystemPricesMultiChartTitle chartIndent={chartIndent}>
            System Buy / Sell Prices
          </SystemPricesMultiChartTitle>
          <SellBuyPricesChart
            series={sellBuyData.filter(
              (x) => x.id == SystemPricesDataKey.systemSellPrice
            )}
            dataFetchStatus={status}
            rerequestData={fetchData}
            tooltip={tooltip}
            chartIndent={chartIndent}
          />
          <SystemPricesMultiChartTitle chartIndent={chartIndent}>
            Net Imbalance Volume
          </SystemPricesMultiChartTitle>
          <NivChart
            series={nivData.filter(
              (x) => x.id == SystemPricesDataKey.netImbalanceVolume
            )}
            dataFetchStatus={status}
            rerequestData={fetchData}
            tooltip={tooltip}
            chartIndent={chartIndent}
          />
        </>
      </MultiChartLineContainer>
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFile}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
        />
      </ChartFooter>
    </>
  );
};

export default SystemSellBuyPricesChartContainer;
