import { addMarginsToFullWidth } from "pages/WhatsNew/style";
import styled, { css } from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const PreviousUpdatesListContainer = styled.div`
  ${addMarginsToFullWidth};
  padding: 0 20px;
  margin-right: 33%;
`;

export const PreviousUpdatesTitle = styled.h2`
  color: ${colours.elexonPurple} !important;
  margin-bottom: 1em !important;
  font-weight: bold;
`;

export const SubtleBox = styled.div`
  box-shadow: 0 5px 4px ${colours.elexonBlack}60;
  border-radius: 6px;
  padding: 1em 3em;
  margin-bottom: 1.5em;

  p {
    font-size: 14px;
  }
`;

const buttonStyle = css`
  background-color: transparent;
  color: ${colours.elexonBlack};
  text-decoration: underline;
  padding: 0.3em 0.7em;
  border: none;
  border-radius: 5px;

  :hover {
    text-decoration: none;
    cursor: pointer;
  }

  :active {
    color: ${colours.disabledGrey};
  }
`;

export const AllUpdatesLoaded = styled.p`
  margin-bottom: 1em;
  align-self: center;
`;

export const LoadMore = styled.button`
  ${buttonStyle};
  font-size: 18px;
  margin-bottom: 1em;
  align-self: center;
`;

export const HorizontalLayout = styled.div`
  display: flex;
  justify-content: space-between;

  .previous-update-title {
    font-size: ${fonts.mediumSmaller};
    margin-bottom: 0;
  }
`;

export const ToggleShowDetails = styled.button`
  ${buttonStyle};
  font-size: ${fonts.small};

  .details-icon {
    font-family: elexon-icons;
    display: inline-block;
    font-size: 12px;
    padding-left: 5px;
  }
`;
