import PageMeta from "components/components/Meta/PageMeta";
import CreditDefaultNoticeTableContainer from "components/dataVisualization/creditDefaultNotice/CreditDefaultNoticeTableContainer/CreditDefaultNoticeTableContainer";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import { Spacer } from "components/dataVisualization/dataPageComponents/FullWidthChartSection/style";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import React from "react";

const CreditDefaultNotices: React.FC = () => (
  <>
    <PageMeta title="Credit default notices" />
    <PageHeader
      title="Credit default notices"
      pageDescription={
        <>
          <p>
            Credit Default Notices are generated by the ECVAA system when a
            trading party enters or clears a Credit Default level. There are 2
            levels of Credit Default, level 1 and level 2.
          </p>
          <p>
            The Credit Default notice contains the Credit Default level, the
            Settlement Date and Settlement Period in which the trading party
            entered the Credit Default. An optional text field may give more
            details on the Credit Default notice.
          </p>
        </>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>
            Credit Default levels are defined in Balancing and Settlement Code,
            Section M, 3.2 and 3.3.
          </p>
          <p>
            There are two Credit Default thresholds that apply after a
            party&apos;s Query Period has ended:
          </p>
          <ul>
            <li>
              <b>Level 1 Default</b> - Initiated when a party&apos;s Credit
              Cover Percentage (CCP) exceeds 75%
            </li>
            <li>
              <b>Level 2 Default</b> - Initiated when a party&apos;s CCP exceeds
              90%
            </li>
          </ul>
          <p>
            A party can enter either Level depending on their circumstances and
            can be deemed as no longer being in Credit Default when their CCP
            has lowered to less than 75% for at least one Settlement Period.
          </p>
        </>
      }
    />
    <Spacer className={"full-width"} />
    <CreditDefaultNoticeTableContainer />
    <AdditionalInfoSection
      documentationInformation={{
        table: ["balancing/settlement/default-notices"],
      }}
    />
  </>
);

export default CreditDefaultNotices;
