import { faFilter } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import Button from "components/components/Button/Button";
import ForecastFilterSelector from "components/components/ForecastFilterSelector/ForecastFilterSelector";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import React from "react";

import { ChartFilterButtonContainer } from "./style";

interface Props {
  forecastFilter: ForecastFilterOption;
  setForecastFilter: (newForecastFilter: ForecastFilterOption) => void;
  forecastFilterTabs?: ForecastFilterOption[];
  toggleChartFilterDrawer?: () => void;
  showChartFilterDrawer?: boolean;
}

const ForecastFilter: React.FC<Props> = ({
  forecastFilter,
  setForecastFilter,
  forecastFilterTabs,
  toggleChartFilterDrawer,
  showChartFilterDrawer,
}: Props) => (
  <>
    <ChartFilterButtonContainer data-test-id="chart-filter-button-container">
      {toggleChartFilterDrawer && (
        <Button
          onClick={toggleChartFilterDrawer}
          className={classnames("toggle", { active: showChartFilterDrawer })}
          buttonText="Filter"
          iconName={faFilter}
        />
      )}
    </ChartFilterButtonContainer>
    <ForecastFilterSelector
      forecastFilter={forecastFilter}
      handleChangeToForecastFilter={setForecastFilter}
      filterTabs={forecastFilterTabs}
    />
  </>
);

export default ForecastFilter;
