import Tabs, {
  BalancingMechanismTabName,
  TabDetails,
} from "components/components/Tabs/Tabs/Tabs";
import { useBmuViewContext } from "contexts/BmuViewContext";

interface Props {
  tabs: TabDetails<BalancingMechanismTabName>[];
  activeTabTitle: BalancingMechanismTabName;
  setActiveTabTitle: React.Dispatch<
    React.SetStateAction<BalancingMechanismTabName>
  >;
}

const BmuViewTabs: React.FC<Props> = ({
  tabs,
  activeTabTitle,
  setActiveTabTitle,
}) => {
  const { selectedBmu } = useBmuViewContext();
  return (
    <Tabs
      tabs={tabs}
      showDefault={selectedBmu === null}
      activeTabTitle={activeTabTitle}
      setActiveTabTitle={setActiveTabTitle}
      defaultMessage="No BMU selected. Use the search bar above to find a BMU to view."
    />
  );
};

export default BmuViewTabs;
