import { CustomLayerProps } from "@nivo/line";
import { ScaleLinear, ScaleTime } from "@nivo/scales";
import ArrowheadMarker from "components/dataVisualization/chartComponents/Arrow/ArrowheadMarker";
import React from "react";

type Props = {
  layerProps: CustomLayerProps;
  verticalPositionPercentage: number;
  colour: string;
  start: Date;
  end: Date;
  label?: string;
  identifier: string;
};

export const HorizontalArrowAtFixedVerticalPosition: React.FC<Props> = ({
  verticalPositionPercentage,
  colour,
  start,
  end,
  label,
  layerProps: { lineGenerator, xScale, yScale },
  identifier,
}) => {
  const [chartYMax, chartYMin] = (yScale as ScaleLinear<number>).range();
  const yPosition =
    ((chartYMax - chartYMin) / 100) * (100 - verticalPositionPercentage);
  const xStart = (xScale as ScaleTime<Date>)(start);
  const xEnd = (xScale as ScaleTime<Date>)(end);

  const points = [
    {
      x: xStart,
      y: yPosition,
    },
    {
      x: xEnd,
      y: yPosition,
    },
  ];

  return (
    <>
      <ArrowheadMarker size={5} colour={colour} identifier={identifier} />
      <path
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        d={lineGenerator(points as any) ?? undefined} // any due to incorrect type definition in Nivo, https://github.com/plouc/nivo/issues/1604
        stroke={colour}
        markerEnd={`url(#arrowheadmarker-${identifier})`}
        data-test-id="svg-arrow"
      />
      {label && (
        <text
          y={yPosition - 7}
          x={Math.abs(xEnd - xStart) / 2 + Math.min(xStart, xEnd)}
          textAnchor="middle"
          fill={colour}
        >
          {label}
        </text>
      )}
    </>
  );
};
