import { appInsights } from "AppInsights";
import ReactGA from "react-ga4";
import hotjar from "static/scripts/hotjar";
import { deleteCookies } from "utils/cookieHelper";

import featureFlags from "./featureFlags";

const getGaMeasurementId = (): string => {
  if (process.env.REACT_APP_ENV_PREFIX == "dev") {
    return "G-M1P4L6PE8F";
  }

  if (process.env.REACT_APP_ENV_PREFIX == "test") {
    return "G-JNCQD4CTQC";
  }

  if (process.env.REACT_APP_ENV_PREFIX == "prod") {
    return "G-7Y75T7CD60";
  }

  return "";
};

const HOTJAR_SITE_ID = 2686849;
const HOTJAR_SNIPPET_VERSION = 6;
const GOOGLE_ANALYTICS_MEASUREMENT_ID = getGaMeasurementId();
const HOTJAR_RESOURCES_PATTERN = /^https?:\/\/(static|script)\.hotjar\.com.+$/;
const GOOGLE_ANALYTICS_PATTERN = /^https?:\/\/www.google-analytics.com\/.+$/;
const HOTJAR_COOKIES_PREFIX = "_hj";
const HOTJAR_SESSION_STORAGE_PREFIX = "hj";
const GOOGLE_ANALYTICS_COOKIES_PREFIXES = ["_gid", "_ga", "_utmv"];
const BASE_WEBSITE_DOMAIN = "elexon.co.uk";
const APPINSIGHTS_COOKIES = ["ai_user", "ai_session"];
const APPINSIGHTS_SESSION_STORAGE_ITEMS = ["AI_buffer", "AI_sentBuffer"];

const removeSessionStorageByPrefix = (prefix: string): void => {
  Object.keys(sessionStorage).forEach((key) => {
    if (key.startsWith(prefix)) {
      sessionStorage.removeItem(key);
    }
  });
};

const setAppInsightsSessionStorageDisabled = (
  disabled: boolean,
  callback = (): void => {}
): void => {
  if (appInsights.config.isStorageUseDisabled !== disabled) {
    appInsights.config.isStorageUseDisabled = disabled;
    appInsights.unload(true, () => {
      appInsights.loadAppInsights();
      callback();
    });
  }
};

export const deleteAnalyticsScripts = (
  analyticsResourcePattern: RegExp
): void => {
  const head = document.getElementsByTagName("head")[0];
  const scriptsArray = Array.from(head.getElementsByTagName("script"));

  scriptsArray.forEach((script) => {
    if (script.src.match(analyticsResourcePattern)) {
      head.removeChild(script);
    }
  });
};

const disableHotjarAnalytics = (): void => {
  deleteAnalyticsScripts(HOTJAR_RESOURCES_PATTERN);
  removeSessionStorageByPrefix(HOTJAR_SESSION_STORAGE_PREFIX);
  deleteCookies(HOTJAR_COOKIES_PREFIX, BASE_WEBSITE_DOMAIN);
};

const disableGoogleAnalytics = (): void => {
  deleteAnalyticsScripts(GOOGLE_ANALYTICS_PATTERN);
  GOOGLE_ANALYTICS_COOKIES_PREFIXES.forEach((prefix) =>
    deleteCookies(prefix, BASE_WEBSITE_DOMAIN)
  );
};

const disableAppInsights = (): void => {
  setAppInsightsSessionStorageDisabled(true, () => {
    APPINSIGHTS_SESSION_STORAGE_ITEMS.forEach((item) =>
      sessionStorage.removeItem(item)
    );
    APPINSIGHTS_COOKIES.forEach((cookie) => deleteCookies(cookie));
    appInsights.getCookieMgr().setEnabled(false);
  });
};

export const initialiseAnalytics = (): void => {
  if (featureFlags.hotjar_analytics) {
    hotjar(HOTJAR_SITE_ID, HOTJAR_SNIPPET_VERSION);
  } else {
    disableHotjarAnalytics();
  }
  if (featureFlags.google_analytics) {
    ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);
  } else {
    disableGoogleAnalytics();
  }
  setAppInsightsSessionStorageDisabled(false, () => {
    appInsights.getCookieMgr().setEnabled(true);
  });
};

export const disableAnalytics = (): void => {
  disableHotjarAnalytics();
  disableGoogleAnalytics();
  disableAppInsights();
};
