import classnames from "classnames";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import React from "react";

import {
  FilterContainer,
  PredefinedFilterContainer,
  PredefinedFilter,
  DayAndDayAheadFilter,
} from "./style";

interface Props {
  forecastFilter: ForecastFilterOption | null;
  handleChangeToForecastFilter: (
    newForecastFilter: ForecastFilterOption
  ) => void;
  filterTabs?: ForecastFilterOption[];
}

const defaultForecastFilterTabs = [
  ForecastFilterOption.twoToFourteenDay,
  ForecastFilterOption.twoToFiftyTwoWeek,
  ForecastFilterOption.twoToOneFiveSixWeek,
];

const ForecastFilterSelector: React.FC<Props> = ({
  forecastFilter,
  handleChangeToForecastFilter,
  filterTabs = defaultForecastFilterTabs,
}: Props) => (
  <FilterContainer>
    <PredefinedFilterContainer data-test-id="predefined-filters">
      {filterTabs.includes(ForecastFilterOption.dayAndDayAhead) && (
        <DayAndDayAheadFilter
          ariaLabel="Day and day ahead"
          className={classnames("filter", {
            active: forecastFilter === ForecastFilterOption.dayAndDayAhead,
          })}
          onClick={(): void =>
            handleChangeToForecastFilter(ForecastFilterOption.dayAndDayAhead)
          }
          buttonText={ForecastFilterOption.dayAndDayAhead}
        />
      )}
      {filterTabs.includes(ForecastFilterOption.weekAhead) && (
        <PredefinedFilter
          ariaLabel="1 week ahead"
          className={classnames("filter", {
            active: forecastFilter === ForecastFilterOption.weekAhead,
          })}
          onClick={(): void =>
            handleChangeToForecastFilter(ForecastFilterOption.weekAhead)
          }
          buttonText={ForecastFilterOption.weekAhead}
        />
      )}
      {filterTabs.includes(ForecastFilterOption.twoToFourteenDay) && (
        <PredefinedFilter
          ariaLabel="2 to 14 days"
          className={classnames("filter", {
            active: forecastFilter === ForecastFilterOption.twoToFourteenDay,
          })}
          onClick={(): void =>
            handleChangeToForecastFilter(ForecastFilterOption.twoToFourteenDay)
          }
          buttonText={ForecastFilterOption.twoToFourteenDay}
        />
      )}
      {filterTabs.includes(ForecastFilterOption.twoToFiftyTwoWeek) && (
        <PredefinedFilter
          ariaLabel="2 to 52 weeks"
          className={classnames("filter", {
            active: forecastFilter === ForecastFilterOption.twoToFiftyTwoWeek,
          })}
          onClick={(): void =>
            handleChangeToForecastFilter(ForecastFilterOption.twoToFiftyTwoWeek)
          }
          buttonText={ForecastFilterOption.twoToFiftyTwoWeek}
        />
      )}
      {filterTabs.includes(ForecastFilterOption.twoToOneFiveSixWeek) && (
        <PredefinedFilter
          ariaLabel="2 to 156 weeks"
          className={classnames("filter", {
            active: forecastFilter === ForecastFilterOption.twoToOneFiveSixWeek,
          })}
          onClick={(): void =>
            handleChangeToForecastFilter(
              ForecastFilterOption.twoToOneFiveSixWeek
            )
          }
          buttonText={ForecastFilterOption.twoToOneFiveSixWeek}
        />
      )}
    </PredefinedFilterContainer>
  </FilterContainer>
);

export default ForecastFilterSelector;
