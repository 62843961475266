import TooltipValuesTable from "components/dataVisualization/chartComponents/ChartTooltip/TooltipValuesTable/TooltipValuesTable";
import {
  StyledTooltip,
  StyledTooltipTotal,
  StyledTooltipTitle,
  StyledTooltipSubtitle,
  StyledTooltipSettlementPeriod,
  TooltipDescription,
} from "components/dataVisualization/chartComponents/ChartTooltip/style";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import { TabulatedTooltipValue } from "models/chartConfiguration/tooltipModels";
import React from "react";

interface Props {
  config: ChartConfiguration;
  title: string;
  subtitle?: string;
  total?: number;
  totalFormatter?: (t: number) => string;
  settlementPeriod?: number;
  showValues: boolean;
  tooltipValues?: TabulatedTooltipValue[];
  selectedDataset?: string;
  description?: string;
}

export const TabulatedChartTooltipContent: React.FC<Props> = ({
  config,
  title,
  subtitle,
  total,
  totalFormatter,
  settlementPeriod,
  showValues,
  tooltipValues,
  selectedDataset,
  description,
}) => (
  <StyledTooltip data-test-id="chart-tooltip">
    <StyledTooltipTitle>{title}</StyledTooltipTitle>
    {subtitle && (
      <StyledTooltipSubtitle data-test-id="chart-tooltip-subtitle">
        <span>{subtitle}</span>
      </StyledTooltipSubtitle>
    )}
    {total !== undefined && totalFormatter && (
      <StyledTooltipTotal data-test-id="chart-tooltip-total">
        <span>TOTAL</span>
        <span>{totalFormatter(total)}</span>
      </StyledTooltipTotal>
    )}
    {settlementPeriod !== undefined && (
      <StyledTooltipSettlementPeriod data-test-id="chart-tooltip-settlement-period">
        <span>Settlement Period</span>
        <span>{settlementPeriod}</span>
      </StyledTooltipSettlementPeriod>
    )}
    {showValues && (
      <TooltipValuesTable
        config={config}
        tooltipValues={tooltipValues}
        selectedDataset={selectedDataset}
      />
    )}
    {description && (
      <TooltipDescription data-test-id="chart-tooltip-description">
        {description}
      </TooltipDescription>
    )}
  </StyledTooltip>
);

export default TabulatedChartTooltipContent;
