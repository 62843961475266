import { getDagData } from "api/dataVisualisation/dayAheadAggregatedGeneration/dayAheadAggregatedGeneration";
import DatasetLineChart from "components/dataVisualization/DatasetLineChart/DatasetLineChart";
import useDayAheadAggregatedGenerationChartData from "components/dataVisualization/DayAheadAggregatedGeneration/useDayAheadAggregatedGenerationChartData";
import {
  datasetCategories,
  transformDagDataToSerie,
} from "components/dataVisualization/DayAheadAggregatedGeneration/utils";
import {
  ChartBody,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import { useDataExport } from "contexts/DataExportContext";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  DAYS_IN_ONE_WEEK,
  addDaysToDate,
  ceilToHalfHour,
} from "utils/dateHelpers";

const DayAheadAggregatedGeneration: React.FC = () => {
  const { dispatch: dataExportCountDispatch } = useDataExport();
  const defaultEndDate = ceilToHalfHour(new Date());
  const dateSelectorTabs = [
    DateSelectorTabs.TwentyFourHours,
    DateSelectorTabs.OneWeek,
  ];
  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addDaysToDate(defaultEndDate, -7), defaultEndDate)
  );
  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.OneWeek
  );

  const { data, fetchData, status } =
    useDayAheadAggregatedGenerationChartData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const handleChangeToDateSelection = useCallback(
    (newDateFilter: DateFilterModel, newActiveTab: DateSelectorTabs): void => {
      setDateFilter(newDateFilter.restrictToMaxOneWeek());
      setActiveDateSelectorTab(newActiveTab);
    },
    [setDateFilter, setActiveDateSelectorTab]
  );

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getDagData(dateFilter, fileFormat),
      `DayAheadAggregatedGeneration-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const chartData = useMemo(
    () => (data ? transformDagDataToSerie(data) : []),
    [data]
  );

  return (
    <>
      <ChartHeader>
        <ChartFilter
          dateFilter={dateFilter}
          defaultEndDate={defaultEndDate}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={DAYS_IN_ONE_WEEK}
        />
      </ChartHeader>
      <ChartBody>
        <DatasetLineChart
          series={chartData}
          datasetCategories={datasetCategories}
          dataFetchStatus={status}
          rerequestData={fetchData}
          showPublishTime={false}
          tooltipText={"Start Time"}
        />
      </ChartBody>
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFile}
          dateFilter={dateFilter}
          defaultEndDate={defaultEndDate}
          handleChangeToDateSelection={handleChangeToDateSelection}
          activeDateSelectorTab={activeDateSelectorTab}
          dateSelectorTabs={dateSelectorTabs}
          maxRange={DAYS_IN_ONE_WEEK}
        />
      </ChartFooter>
    </>
  );
};

export default DayAheadAggregatedGeneration;
