// This model is the basis for all the multi-line (or multi-area) charts,
// and is used by the category filters to allow users to select precisely
// which lines (or areas) are visible on the graph they are viewing.
// It is effectively a decorated Category, i.e. a Fuel Type or
// Interconnector associated with a display name and a colour.

import { PointShape } from "utils/ChartDrawUtils/pointShapes";

export interface ChartDatasetModel {
  displayName: string;
  dataKey: string;
  colour: string | null;
  unitPrefix?: string;
  unitSuffix?: string;
  isHidden?: boolean;
  dashed?: boolean;
  swatchShape?: PointShape;
}

export interface DatasetCategory<T extends ChartDatasetModel> {
  datasets: T[];
  id: string;
  title?: string;
  disabled?: boolean;
  disabledText?: string;
  showTitle?: boolean;
}

export const combineDatasetCategories = <T extends ChartDatasetModel>(
  datasetCategories: DatasetCategory<T>[]
): T[] => datasetCategories.flatMap((x) => x.datasets);
