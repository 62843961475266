import classnames from "classnames";
import React from "react";
import colours from "styles/colours";

import { StyledSwitch, SwitchContainer, SwitchLabel } from "./style";

interface Props {
  name: string;
  onColor: string | null;
  offColor?: string;
  onClick: () => void;
  switchedOn: boolean;
  leftLabel: string;
  rightLabel?: string;
  headerLabel?: boolean;
  disabled?: boolean;
}

const Switch: React.FC<Props> = ({
  name,
  onColor,
  offColor,
  onClick,
  switchedOn,
  leftLabel,
  rightLabel,
  headerLabel = false,
  disabled,
}: Props) => (
  <SwitchContainer className={classnames({ reverse: headerLabel })}>
    {rightLabel && (
      <SwitchLabel className={classnames({ header: headerLabel })}>
        {rightLabel}
      </SwitchLabel>
    )}
    <StyledSwitch
      colour={(switchedOn ? onColor : offColor) || colours.disabledGrey}
    >
      <label htmlFor={`${name}--switch`} className="switch">
        <input
          id={`${name}--switch`}
          type="checkbox"
          checked={switchedOn}
          onClick={onClick}
          readOnly
          disabled={disabled}
          aria-label={leftLabel}
          aria-checked={switchedOn}
        />
        <span className="slider" />
      </label>
    </StyledSwitch>
    <SwitchLabel className={classnames({ header: headerLabel })}>
      {leftLabel}
    </SwitchLabel>
  </SwitchContainer>
);

export default Switch;
