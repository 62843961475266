import styled from "styled-components";
import colours from "styles/colours";

export const TilesContainer = styled.div`
  padding-top: 3em;

  .is-parent:first-child {
    padding-left: 0;
    padding-right: 3em;
  }

  .is-parent:last-child {
    padding-left: 3em;
    padding-right: 0;
  }
`;

export const TileContent = styled.div`
  padding: 0.75em 0.75em 1.5em;
  color: ${colours.elexonBlack};
`;

export const TileDataTable = styled.table`
  tr > td {
    border: 0;
    padding: 0;
    vertical-align: middle;
  }
`;

export const SubtitleRow = styled.tr`
  td {
    padding-top: 1.25em !important;
    font-size: 1em;
  }
`;

export const DataRow = styled.tr`
  td:first-child {
    font-size: 0.875em;
    color: ${colours.darkGrey};
    padding: 0;
  }

  td:nth-child(2) {
    font-size: 2em;
    line-height: 1em;
    font-weight: bolder;
    height: 0;
    text-align: right;
    padding-right: 0.3em;
    color: ${colours.dynamicPink};
  }
`;
