import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import { WindGenerationForecast } from "models/windGeneration/windGenerationForecast";
import { WindGenerationOutturnModel } from "models/windGeneration/windGenerationOutturnModel";
import { chartColours } from "styles/colours";
import { PointShape } from "utils/ChartDrawUtils/pointShapes";

export enum WindGenSeriesIds {
  Outturn = "windOutturn",
  LatestForecast = "windLatestForecast",
  InitialForecast = "windInitialForecast",
}

export interface WindGenerationModel {
  outturnData: WindGenerationOutturnModel[];
  latestForecastData: WindGenerationForecast[];
  initialForecastData: WindGenerationForecast[];
}

const outturnDataset = {
  colour: chartColours.windGenerationOutturn,
  dataKey: WindGenSeriesIds.Outturn,
  displayName: "Outturn",
  unitSuffix: "MW",
  visible: true,
};

const initialForecastDataset = {
  colour: chartColours.windGenerationLatestForecast,
  dataKey: WindGenSeriesIds.LatestForecast,
  displayName: "Latest Forecast",
  unitSuffix: "MW",
  visible: true,
  swatchShape: PointShape.Square,
};

const latestForecastDataset = {
  colour: chartColours.windGenerationInitialForecast,
  dataKey: WindGenSeriesIds.InitialForecast,
  displayName: "Initial Forecast",
  unitSuffix: "MW",
  visible: true,
  swatchShape: PointShape.Diamond,
};

export const datasets: DatasetCategory<ChartDatasetModel>[] = [
  {
    datasets: [outturnDataset, initialForecastDataset, latestForecastDataset],
    id: "datasets",
    title: "Wind generation",
  },
];
