import styled from "styled-components";
import colours from "styles/colours";

export const ModalInfo = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
`;

export const FieldValue = styled.span`
  color: ${colours.elexonBlack};
  & + & {
    padding-left: 11px;
  }
`;

export const ModalContent = styled.div`
  margin-top: 40px;

  div {
    margin-bottom: 14px;
  }
`;
