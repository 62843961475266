import { Point } from "@nivo/line";
import { getLolpdrmData } from "api/dataVisualisation/lolpdrm/lolpdrm";
import MultiChartLineContainer from "components/dataVisualization/chartComponents/MultiChartLineContainer/MultiChartLineContainer";
import {
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import { LolpdrmMultiChartTitle } from "components/dataVisualization/lolpdrm/LolpdrmChartContainer/style";
import useLolpdrmChartContainerData from "components/dataVisualization/lolpdrm/LolpdrmChartContainer/useLolpdrmChartContainerData";
import DrmChart from "components/dataVisualization/lolpdrm/LolpdrmCharts/DrmChart";
import LolpChart from "components/dataVisualization/lolpdrm/LolpdrmCharts/LolpChart";
import LolpdrmTooltip from "components/dataVisualization/lolpdrm/LolpdrmTooltip/LolpdrmTooltip";
import { useDataExport } from "contexts/DataExportContext";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useEffect, useMemo, useState } from "react";
import {
  filterSerieToDisplay,
  transformDrmData,
  transformLolpData,
} from "utils/ChartDataUtils/lolpdrm/lolpdrm";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  formatNumberToFixedWithoutTrailingZeroes,
  getXTimeValuesExtrema,
} from "utils/chartHelpers";
import {
  addTimeToDate,
  DAYS_IN_ONE_WEEK,
  endOfDay,
  floorToHalfHour,
  startOfDay,
} from "utils/dateHelpers";

const dateSelectorTabs = [
  DateSelectorTabs.TwentyFourHours,
  DateSelectorTabs.OneWeek,
  DateSelectorTabs.Date,
];

const LolpdrmChartContainer: React.FC = () => {
  const now = floorToHalfHour(new Date());
  const endOfToday = endOfDay(new Date());
  const startOfToday = startOfDay(new Date());
  const threeThirtyDayAfterTomorrow = addTimeToDate(startOfToday, 2, 3, 30);
  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.Date
  );

  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(startOfToday, threeThirtyDayAfterTomorrow)
  );

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    setDateFilter(newDateFilter.restrictToMaxOneWeek());
    setActiveDateSelectorTab(newActiveTab);
  };

  const {
    data: rawData,
    fetchData,
    status,
  } = useLolpdrmChartContainerData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const drmData = useMemo(
    () => (rawData ? transformDrmData(rawData) : []),
    [rawData]
  );
  const lolpData = useMemo(
    () => (rawData ? transformLolpData(rawData) : []),
    [rawData]
  );

  const { dispatch: dataExportCountDispatch } = useDataExport();

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getLolpdrmData(dateFilter, fileFormat),
      `LossOfLoadProbability-DeratedMargin-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const allData = [...drmData, ...lolpData];
  const { maxXAxisValue, minXAxisValue } = getXTimeValuesExtrema(allData);

  // The following calculates the chart indent value by multiplying a fixed factor
  // by the maximum number of decimal places present in the y-axis tick values
  const lolpChartIndent = useMemo(() => {
    const maxLolpChartIndent = 30;
    const maxPossibleDecimalPlaces = 9; // based on precision in database
    let maxActualDecimalPlaces = 0;

    for (const lolpDataset of lolpData) {
      for (const lolpDatapoint of lolpDataset.data) {
        if (typeof lolpDatapoint.y === "number") {
          const significantDigitsAfterDecimalPoint =
            formatNumberToFixedWithoutTrailingZeroes(lolpDatapoint.y).replace(
              ".",
              ""
            ).length;

          maxActualDecimalPlaces = Math.max(
            maxActualDecimalPlaces,
            significantDigitsAfterDecimalPoint
          );
        }
      }
    }

    return (
      Math.ceil(maxLolpChartIndent / maxPossibleDecimalPlaces) *
      maxActualDecimalPlaces
    );
  }, [lolpData]);

  const tooltip = (point: Point): JSX.Element => (
    <LolpdrmTooltip
      allChartData={allData}
      minXAxisValue={minXAxisValue}
      maxXAxisValue={maxXAxisValue}
      highlightedPoint={point}
    />
  );

  return (
    <>
      <ChartHeader>
        <ChartFilter
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={DAYS_IN_ONE_WEEK}
          defaultEndDate={threeThirtyDayAfterTomorrow}
          twentyForHourEndDate={endOfToday}
        />
      </ChartHeader>
      <MultiChartLineContainer chartIndent={lolpChartIndent}>
        <>
          <LolpdrmMultiChartTitle chartIndent={lolpChartIndent}>
            DRM
          </LolpdrmMultiChartTitle>
          <DrmChart
            series={filterSerieToDisplay(drmData, now)}
            dataFetchStatus={status}
            rerequestData={fetchData}
            tooltip={tooltip}
            lolpChartIndent={lolpChartIndent}
          />
          <LolpdrmMultiChartTitle chartIndent={lolpChartIndent}>
            LoLP
          </LolpdrmMultiChartTitle>
          <LolpChart
            series={filterSerieToDisplay(lolpData, now)}
            dataFetchStatus={status}
            rerequestData={fetchData}
            tooltip={tooltip}
            lolpChartIndent={lolpChartIndent}
          />
        </>
      </MultiChartLineContainer>
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFile}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
          defaultEndDate={threeThirtyDayAfterTomorrow}
        />
      </ChartFooter>
    </>
  );
};

export default LolpdrmChartContainer;
