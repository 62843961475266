import Button from "components/components/Button/Button";
import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const PageTitle = styled.h1`
  margin-top: -0.3em;

  && {
    font-size: ${fonts.xLarge};
  }
`;

export const SubsectionHeader = styled.h2`
  color: ${colours.darkGrey};
  margin-top: 2em;

  && {
    margin-bottom: 1em;
  }
`;

export const TallButton = styled(Button)`
  && {
    height: 3em;
    padding: 0 35px;
  }
`;

export const LoadingSpinnerContainer = styled.div`
  height: 8em;
  text-align: center;
  margin-top: 4em;
  margin-bottom: 6em;
`;
