import { ModalSubHeader } from "components/components/Modal/commonStyle";
import { WizardStep } from "components/components/ModalExportWizard/ModalExportWizard";
import {
  filterBmuIds,
  hasAnyFilters,
} from "components/components/balancingMechanism/marketView/MarketViewFilters/helpers";
import MultiDatasetExporter, {
  DownloadCallback,
} from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/MultiDatasetExporter";
import {
  FieldValue,
  ModalContent,
} from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/style";
import { useMarketViewContext } from "contexts/MarketViewContext";
import React from "react";
import { toSettlementPeriodRangeString } from "utils/dateHelpers";

import {
  marketViewCheckboxDataGroups,
  transformMarketWideDownloadCallbacks,
} from "./utils";

interface Props {
  date: Date;
}

const MarketViewDataExporter: React.FC<Props> = ({ date }) => {
  const { bmuFilters, bmuDetails } = useMarketViewContext();

  const bmUnits =
    hasAnyFilters(bmuFilters) && bmuDetails.data
      ? filterBmuIds(bmuDetails.data, bmuFilters)
      : undefined;

  const bmuFilterDetails = hasAnyFilters(bmuFilters) ? (
    <>
      {bmuFilters.leadParty && (
        <div>
          <span>Lead Party Name: </span>
          <FieldValue>{bmuFilters.leadParty}</FieldValue>
        </div>
      )}
      {bmuFilters.bmuType && (
        <div>
          <span>BMU Type: </span>
          <FieldValue>{bmuFilters.bmuType}</FieldValue>
        </div>
      )}
      {bmuFilters.fuelType && (
        <div>
          <span>Fuel Type: </span>
          <FieldValue>{bmuFilters.fuelType}</FieldValue>
        </div>
      )}
    </>
  ) : (
    <div>No BMU filters applied</div>
  );

  const infoStep: WizardStep = {
    title: "Confirm the settlement period and BMU filters",
    content: (
      <>
        <ModalContent>
          <ModalSubHeader>Settlement date</ModalSubHeader>
          <FieldValue>{toSettlementPeriodRangeString(date)}</FieldValue>
        </ModalContent>
        <ModalContent>
          <ModalSubHeader>Selected BMU filters:</ModalSubHeader>
          {bmuFilterDetails}
        </ModalContent>
      </>
    ),
  };

  return (
    <MultiDatasetExporter
      fileSuffix={date.toISOString()}
      checkboxList={marketViewCheckboxDataGroups}
      infoStep={infoStep}
      transformToDownloadCallbacks={(datasets: string[]): DownloadCallback[] =>
        transformMarketWideDownloadCallbacks(datasets, date, bmUnits)
      }
    />
  );
};
export default MarketViewDataExporter;
