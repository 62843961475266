import { Point, ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { lolpConfig } from "components/dataVisualization/lolpdrm/lolpdrmChartConfig";
import { RequestStatus } from "hooks/useRequest";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartTheme,
  chartXTimeScale,
  commonChartProps,
  modifyChartMargin,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { getUnlabelledVerticalLineMarkerAtTimeNow } from "utils/ChartDrawUtils";
import {
  formatAsTimeWithDatesMarked,
  formatNumberToFixedWithoutTrailingZeroes,
} from "utils/chartHelpers";

interface Props {
  series: Serie[];
  rerequestData: () => void;
  dataFetchStatus: RequestStatus;
  tooltip: (input: Point) => JSX.Element;
  lolpChartIndent?: number;
}

const LolpChart: React.FC<Props> = ({
  series,
  rerequestData,
  dataFetchStatus,
  tooltip,
  lolpChartIndent = 0,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    const lolpChartAxisLeft = chartAxisLeft("Probability", isChartLoaded, 4);
    lolpChartAxisLeft.format = formatNumberToFixedWithoutTrailingZeroes;
    lolpChartAxisLeft.legendOffset = -80 - lolpChartIndent;

    const yScaleDefault = nonStackedLineChartYScale(series);
    return (
      <ResponsiveLine
        {...commonChartProps(isChartLoaded, series)}
        enableSlices="x"
        axisBottom={chartAxisBottom(
          "Start Time (UTC)",
          series,
          isChartLoaded,
          undefined,
          formatAsTimeWithDatesMarked
        )}
        axisLeft={lolpChartAxisLeft}
        gridYValues={4}
        enableGridX={true}
        sliceTooltip={(input): JSX.Element => tooltip(input.slice.points[0])}
        xScale={chartXTimeScale()}
        yScale={{
          ...yScaleDefault,
          min: 0,
          max: yScaleDefault.max === 0 ? 0.01 : yScaleDefault.max,
        }}
        theme={{
          ...chartTheme,
          crosshair: {
            line: {
              strokeOpacity: 0,
              strokeDasharray: "999", // marker value for <StackedChartContainer> crosshair logic
            },
          },
        }}
        markers={getUnlabelledVerticalLineMarkerAtTimeNow(
          series,
          isChartLoaded
        )}
        margin={modifyChartMargin({ left: lolpChartIndent })}
      />
    );
  }, [dataFetchStatus, series, tooltip, lolpChartIndent]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={lolpConfig.datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
        containerHeight={330}
      >
        {memoisedChart}
      </ChartWithErrorHandling>
    ),
    [series, dataFetchStatus, memoisedChart, rerequestData]
  );
};

export default LolpChart;
