import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { StandardChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/StandardChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayers } from "styles/chartStyles/chartLayers";
import { chartColours } from "styles/colours";

interface Props {
  series: Serie[];
  dataFetchStatus: RequestStatus;
  rerequestData: () => void;
}

const datasetCategories: DatasetCategory<ChartDatasetModel>[] = [
  {
    datasets: [
      {
        colour: chartColours.nonBmStor,
        dataKey: "nonBmStor",
        displayName: "Volume",
        unitSuffix: "MWh",
      },
    ],
    id: "datasets",
  },
];

const ShortTermOperatingReservesChart: React.FC<Props> = ({
  series,
  dataFetchStatus,
  rerequestData,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;
    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        enableSlices="x"
        axisBottom={chartAxisBottom("Start Time (UTC)", series, isChartLoaded)}
        axisLeft={chartAxisLeft("Energy Volume (MWh)", isChartLoaded)}
        layers={chartLayers}
        sliceTooltip={(input): JSX.Element => (
          <StandardChartTooltip
            series={series}
            datasetCategories={datasetCategories}
            text={{ label: "Start Time" }}
            options={{
              interactionType: TooltipInteractionType.Slice,
            }}
            inputPoints={input.slice.points}
          />
        )}
        xScale={chartXTimeScale()}
        yScale={nonStackedLineChartYScale(series, { minimumMax: 10 })}
      />
    );
  }, [dataFetchStatus, series]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      >
        {memoisedChart}
      </ChartWithErrorHandling>
    ),
    [dataFetchStatus, memoisedChart, rerequestData, series]
  );
};
export default ShortTermOperatingReservesChart;
