import { WithoutId } from "components/components/SortableTable/SortableTable";

interface BaseTableModel {
  id: number;
  bmUnit: string;
  leadParty: string;
  bmUnitType: string;
  nationalGridBmUnit: string;
}

export interface PhysicalTableModel extends BaseTableModel {
  timeFrom: Date;
  timeTo: Date;
  levelTo: number;
  levelFrom: number;
}

export interface BidOfferAcceptanceTableModel extends PhysicalTableModel {
  acceptNumber: number;
  acceptTime: Date;
  deemedFlag: boolean;
  soFlag: boolean;
  storFlag: boolean;
  rrFlag: boolean | null;
}

export interface PhysicalTabDataStructure {
  PN: PhysicalTableModel[];
  QPN: PhysicalTableModel[];
  MILS: PhysicalTableModel[];
  MELS: PhysicalTableModel[];
  BOAL: BidOfferAcceptanceTableModel[];
}

export type PhysicalTabDataset = keyof PhysicalTabDataStructure;

export const PhysicalTableHeaders: Record<
  keyof WithoutId<PhysicalTableModel>,
  string
> = {
  bmUnit: "BMU ID",
  leadParty: "Lead party name",
  bmUnitType: "BMU type",
  nationalGridBmUnit: "NGC BMU name",
  timeFrom: "From time (UTC)",
  levelFrom: "From level (MW)",
  timeTo: "To time (UTC)",
  levelTo: "To level (MW)",
};

export const BidOfferAcceptanceTableHeaders: Record<
  keyof WithoutId<BidOfferAcceptanceTableModel>,
  string
> = {
  ...PhysicalTableHeaders,
  acceptNumber: "Accept ID",
  acceptTime: "Accept time (UTC)",
  deemedFlag: "Deemed",
  soFlag: "SO",
  storFlag: "STOR provider",
  rrFlag: "RR instruction",
};
