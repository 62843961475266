import { Link } from "react-router-dom";
import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const HorizontalLayout = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
`;

export const DateText = styled.p`
  color: ${colours.darkerDisabledGrey};
`;

export const StyledTitle = styled.span`
  display: block;
  font-size: 24px;
  font-weight: ${fonts.heavy};
  line-height: 27px;
  margin-bottom: 0.6666em;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: ${colours.elexonBlack};
  height: 100%;

  p {
    font-size: 14px;
  }

  ${StyledTitle}:not(:first-child) {
    margin: 0 0 0.3em 0;
  }
`;

export const FeaturedContentContainer = styled.div`
  padding: 1em 1em 1em 0;
  flex: 3;
`;

export const TextContentContainer = styled.div`
  padding: 1em;
  a {
    text-decoration: underline;
  }
`;

export const StyledLink = styled(Link)`
  margin-top: 0.5em;
  font-size: ${fonts.small};
  text-decoration: underline;
`;

export const FeatureImage = styled.img`
  height: 40%;
`;

export const FeatureImageContainer = styled.div`
  flex: 2;
  padding: 0.5em 0.5em 0.1em 0.5em;
`;

export const OtherImageContainer = styled.div`
  max-height: 150px;
  text-align: center;

  img {
    max-height: inherit;
  }
`;
