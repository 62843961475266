import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const StyledDiv = styled.div`
  > div {
    padding-bottom: 24px;
  }
`;

export const StyledFilterButton = styled.button`
  margin-left: 24px;
  border: none;
  border-bottom: 1px solid ${colours.elexonBlack};
  font-size: ${fonts.smallMedium};
  margin-bottom: 1px;
  padding-bottom: 2px;

  &:hover {
    border-bottom: 3px solid ${colours.elexonBlack};
    padding-bottom: 1px;
    margin-bottom: 0;

    cursor: pointer;
  }

  &:focus-visible {
    outline: 1px solid ${colours.focusBlue};
    border-radius: 2px;
  }

  &.active {
    border-bottom: 3px solid ${colours.elexonBlue};
    padding-bottom: 1px;
    margin-bottom: 0;

    font-weight: ${fonts.heavy};

    &:hover {
      text-decoration: underline;
    }
  }

  .icon {
    margin-left: 10px;
  }
`;
