import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const TableContainer = styled.div`
  position: relative;
`;

type StyledComponentProps = {
  scrollable: boolean;
};

export const ScrollableContainer = styled.div<StyledComponentProps>`
  overflow-x: ${(props): string => (props.scrollable ? "auto" : "visible")};
  white-space: ${(props): string => (props.scrollable ? "nowrap" : "initial")};
`;

interface ScrollButtonsContainerProps {
  shouldOverlapPreviousElement: boolean;
}

const OverlapStyles = `
  position: absolute;
  top: -60px;
  right: 0;
`;

export const ScrollButtonsContainer = styled.div<ScrollButtonsContainerProps>`
  ${(p): string => (p.shouldOverlapPreviousElement ? OverlapStyles : "")};
  display: flex;
  gap: 30px;
  justify-content: right;
  margin-bottom: 12px;
`;

export const ScrollButton = styled.button`
  width: 48px;
  height: 48px;
  background: ${colours.lightHighlightGrey};
  border-radius: 2px;
  border: none;
  box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.15));
  cursor: pointer;

  :disabled {
    cursor: default;
  }
`;

export const HeaderCategoryRow = styled.tr`
  && th.active {
    background-color: ${colours.periwinkle};
    text-align: center;

    + th.active {
      border-left: 1px solid ${colours.white};
    }
  }
`;

export const HeaderNameRow = styled.tr`
  background-color: ${colours.elexonBlue};
  border-bottom: 0;

  && > th {
    vertical-align: middle;
    color: ${colours.white};
    cursor: pointer;
    font-weight: ${fonts.normal};

    + th {
      border-left: 1px solid ${colours.white};
    }
  }
`;

export const LockIcon = styled.div`
  display: block;
`;

export const CrossIcon = styled.div`
  display: none;
`;

export const HeaderLockRow = styled.tr`
  background-color: ${colours.lightGrey};

  && th {
    padding: 2px;

    &.active {
      background-color: ${colours.focusBlue};
      color: ${colours.white};

      &:focus,
      &:hover {
        background-color: ${colours.elexonRed};
        cursor: pointer;

        ${LockIcon} {
          display: none;
        }

        ${CrossIcon} {
          display: block;
        }
      }
    }
  }
`;

export const TotalContainer = styled.td`
  color: white;
  font-weight: bold;
  background-color: ${colours.elexonPurple};
`;

export const TotalsLabel = styled.td`
  color: white;
  font-weight: bold;
  background: #64358ccc;
`;

export const BodyRow = styled.tr<{
  isClickable?: boolean;
}>`
  background-color: ${colours.white};

  && td {
    padding-top: 0.9em;
    padding-bottom: 0.9em;
  }

  &:nth-child(even) {
    background-color: ${colours.lightGrey};
  }

  ${({ isClickable }): false | FlattenSimpleInterpolation =>
    !!isClickable &&
    css`
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `}
`;

export const HeaderNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SortContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px -4px;
  justify-content: center;

  > * {
    margin: -4px 0;
  }
`;

export const LockContainer = styled.div`
  text-align: center;
  position: relative;
`;

export const LockOrder = styled.span`
  position: absolute;
  left: calc(50% + 7px);
  top: 2px;
  font-size: ${fonts.xSmall};
`;

// Common stylings for use in renderFns

export const StyledTrue = styled.span`
  border: 1px solid ${colours.validGreen};
  border-radius: 2px;
  color: ${colours.validGreen};
  padding: 2px 10px;
  font-weight: ${fonts.heavy};
`;

export const Centred = styled.div`
  text-align: center;
`;

export const NoWrapCell = styled.div`
  white-space: nowrap;
`;

export const NormalWrapCell = styled.div`
  white-space: normal;
`;

export const SwatchContainer = styled.span`
  margin-right: 10px;
`;
