import {
  getMarketWideDynamicJsonData,
  getMarketWideDynamicRatesJsonData,
} from "api/dataVisualisation/dynamic/dynamic";
import {
  MarketViewBmuFilters,
  filterBmuIds,
  hasAnyFilters,
} from "components/components/balancingMechanism/marketView/MarketViewFilters/helpers";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { BmuModel } from "models/bmuData/bmuModel";
import { DynamicDataModel } from "models/bmuData/dynamicDataModel";
import {
  DynamicRatesDataModel,
  isRatesDataset,
} from "models/bmuData/dynamicRatesDataModel";
import {
  DynamicTabDataStructure,
  DynamicTabDataset,
  DynamicTableModel,
  RatesTableModel,
} from "models/bmuData/marketViewDynamicTabModels";
import { JsonDataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { useCallback, useEffect, useState } from "react";
import { matchingBmUnitData } from "utils/marketViewTableUtils";

const mapRatesData = (
  rawData: JsonDataDownloadModel<DynamicRatesDataModel>,
  bmuData: BmuModel[]
): RatesTableModel[] =>
  rawData.data?.map((row, key) => {
    const { leadParty, bmUnitType } = matchingBmUnitData(
      row.nationalGridBmUnit,
      bmuData
    );
    return {
      id: key,
      bmUnit: row.bmUnit,
      leadParty: leadParty,
      bmUnitType: bmUnitType,
      nationalGridBmUnit: row.nationalGridBmUnit,
      settlementDate: new Date(row.settlementDate),
      settlementPeriod: row.settlementPeriod,
      time: new Date(row.time),
      rate1: row.rate1,
      elbow2: row.elbow2,
      rate2: row.rate2,
      elbow3: row.elbow3,
      rate3: row.rate3,
    };
  });

const mapDynamicData = (
  rawData: JsonDataDownloadModel<DynamicDataModel>,
  bmuData: BmuModel[]
): DynamicTableModel[] =>
  rawData.data?.map((row, key) => {
    const { leadParty, bmUnitType } = matchingBmUnitData(
      row.nationalGridBmUnit,
      bmuData
    );
    return {
      id: key,
      bmUnit: row.bmUnit,
      leadParty: leadParty,
      bmUnitType: bmUnitType,
      nationalGridBmUnit: row.nationalGridBmUnit,
      settlementDate: new Date(row.settlementDate),
      settlementPeriod: row.settlementPeriod,
      time: new Date(row.time),
      value: row.value,
    };
  });

const getData = async (
  dataset: DynamicTabDataset,
  settlementDate: Date,
  bmuData: BmuModel[],
  bmUnits?: string[]
): Promise<Partial<DynamicTabDataStructure>> => {
  if (isRatesDataset(dataset)) {
    const rawData = await getMarketWideDynamicRatesJsonData(
      dataset,
      settlementDate,
      bmUnits
    );
    return {
      [dataset]: mapRatesData(rawData, bmuData),
    };
  } else {
    const rawData = await getMarketWideDynamicJsonData(
      dataset,
      settlementDate,
      bmUnits
    );
    return {
      [dataset]: mapDynamicData(rawData, bmuData),
    };
  }
};

const filterBmusAndGetData = async (
  dataset: DynamicTabDataset,
  settlementDate: Date,
  bmuFilters: MarketViewBmuFilters,
  bmuData: BmuModel[]
): Promise<Partial<DynamicTabDataStructure>> => {
  if (hasAnyFilters(bmuFilters)) {
    const bmUnits = filterBmuIds(bmuData, bmuFilters);

    if (bmUnits.length === 0) {
      return {
        [dataset]: [],
      };
    }

    return getData(dataset, settlementDate, bmuData, bmUnits);
  }
  return getData(dataset, settlementDate, bmuData);
};

const useMarketViewDynamicData = (
  settlementDate: Date,
  bmuFilters: MarketViewBmuFilters,
  bmuData: BmuModel[] | null
): {
  data: Partial<DynamicTabDataStructure> | null;
  status: RequestStatus;
  request: (dataset: string) => void;
} => {
  const [allData, setAllData] = useState<Partial<DynamicTabDataStructure>>({});

  const {
    data: singleDatasetData,
    status,
    request,
  } = useRequest(filterBmusAndGetData);

  const fetchData = useCallback(
    (dataset: string) => {
      if (dataset && bmuData) {
        request(
          dataset as DynamicTabDataset,
          settlementDate,
          bmuFilters,
          bmuData
        );
      }
    },
    [bmuData, request, settlementDate, bmuFilters]
  );

  useEffect(() => {
    setAllData((curr) => {
      return { ...curr, ...singleDatasetData };
    });
  }, [singleDatasetData]);

  useEffect(() => {
    // Reset to ensure we re-run all queries
    setAllData({});
  }, [settlementDate, bmuFilters]);

  return { data: allData, status, request: fetchData };
};

export default useMarketViewDynamicData;
