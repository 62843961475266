import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

const StyledButton = styled.button`
  background-color: ${colours.elexonYellow};
  border: 0;
  border-radius: 200px;
  text-transform: uppercase;
  padding: 0 20px;
  font-weight: bold;
  letter-spacing: 1.85px;
  height: 35px;

  :active {
    box-shadow: inset 5px 5px 8px ${colours.elexonBlack}60;
  }

  &&:focus-visible {
    outline: 2px solid ${colours.elexonBlack};
    box-shadow: none;
  }

  :focus:not(:active) {
    box-shadow: none;
  }

  :disabled {
    background-color: ${colours.elexonYellow};

    &&:hover,
    &&:active {
      margin-bottom: 0;
      box-shadow: none;
      text-decoration: none;
    }
  }

  &&:hover {
    text-decoration: underline;
  }

  &.loading {
    opacity: 1;
    background-color: ${colours.elexonGrey}77 !important;

    :active {
      box-shadow: none;
    }
  }

  &.cancel {
    background-color: transparent;
    border: none;
    width: auto;
    font-size: ${fonts.small};
    font-weight: normal;
    text-transform: capitalize;

    :active {
      box-shadow: none;
    }
  }

  &.icon {
    background-color: transparent;
    border: none;
    height: auto;
    width: auto;
    padding: 0;
    border-radius: 0;

    :active {
      box-shadow: none;
    }

    && > .icon {
      margin-left: 0;
    }
  }

  &.filter {
    background: transparent;
    border-radius: 0;
    height: 28px;
    width: 55px;
    font-size: 18px;
    font-weight: normal;
    text-transform: none;
    white-space: nowrap;

    & + & {
      margin-left: 15px;
    }

    &:hover {
      font-weight: bold;
      text-decoration: none;
    }

    &.active {
      border-bottom: 3px solid ${colours.elexonBlue};
      font-weight: bold;
    }

    &:focus {
      box-shadow: 0 0 0;
    }

    &:disabled {
      cursor: unset;
      background: transparent;
      border-bottom: none;
      font-weight: normal;
      box-shadow: none;

      &:hover {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }

  &.link-inline,
  &.plain-inline {
    background: transparent;
    border-radius: 0;
    font-weight: normal;
    text-decoration: underline;
    white-space: nowrap;
    color: unset;
    text-transform: none;
    letter-spacing: unset;
    padding: unset;
    height: unset;

    :active {
      box-shadow: none;
    }
  }

  &.link-inline {
    color: ${colours.linkColor};
  }

  &.plain-inline {
    color: unset;

    &.no-underline {
      text-decoration: none;
    }
  }

  &.toggle {
    background-color: ${colours.lightGrey};
    color: ${colours.elexonBlack};
    font-size: 0.9em;
    padding: 0 30px;

    &.active {
      background-color: ${colours.darkGrey};
      color: ${colours.white};
    }
  }

  &.footer {
    background: ${colours.elexonBlue};
    color: ${colours.white};
    border: 1px solid ${colours.white};
    font-weight: normal;
  }
`;

export default StyledButton;
