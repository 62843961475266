import { Point, ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { drmConfig } from "components/dataVisualization/lolpdrm/lolpdrmChartConfig";
import { RequestStatus } from "hooks/useRequest";
import React, { useMemo } from "react";
import {
  chartAxisLeft,
  chartTheme,
  chartXTimeScale,
  commonChartProps,
  emptyChartAxisBottom,
  modifyChartMargin,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { getLabelledVerticalLineMarkerAtTimeNow } from "utils/ChartDrawUtils";

interface Props {
  series: Serie[];
  rerequestData: () => void;
  dataFetchStatus: RequestStatus;
  tooltip: (input: Point) => JSX.Element;
  lolpChartIndent?: number;
}

const DrmChart: React.FC<Props> = ({
  series,
  rerequestData,
  dataFetchStatus,
  tooltip,
  lolpChartIndent = 0,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    const drmChartAxisLeft = chartAxisLeft("MW", isChartLoaded, 4);
    drmChartAxisLeft.legendOffset = -80 - lolpChartIndent;

    return (
      <ResponsiveLine
        {...commonChartProps(isChartLoaded, series)}
        enableSlices="x"
        axisBottom={emptyChartAxisBottom}
        axisLeft={drmChartAxisLeft}
        gridYValues={4}
        sliceTooltip={(input): JSX.Element => tooltip(input.slice.points[0])}
        enableGridX={true}
        xScale={chartXTimeScale()}
        yScale={nonStackedLineChartYScale(series)}
        theme={{
          ...chartTheme,
          crosshair: {
            line: {
              strokeOpacity: 0,
              strokeDasharray: "999", // marker value for <StackedChartContainer> crosshair logic
            },
          },
        }}
        markers={getLabelledVerticalLineMarkerAtTimeNow(series, isChartLoaded)}
        margin={modifyChartMargin({ left: lolpChartIndent })}
      />
    );
  }, [series, dataFetchStatus, tooltip, lolpChartIndent]);

  return (
    <ChartWithErrorHandling
      series={series}
      datasetCategories={drmConfig.datasetCategories}
      dataFetchStatus={dataFetchStatus}
      rerequestData={rerequestData}
      containerHeight={330}
    >
      {memoisedChart}
    </ChartWithErrorHandling>
  );
};

export default DrmChart;
