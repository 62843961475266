import { ResponsiveLine, Serie } from "@nivo/line";
import { ComponentPopover } from "components/components/ComponentWithRequest/style";
import { StyledChartContainer } from "components/dataVisualization/chartComponents/ChartContainer/style";
import ChartTooltipContent from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipContent/ChartTooltipContent";
import {
  TooltipInteractionType,
  ChartTooltipPositionWrapper,
} from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import RectangleSliceProvider from "components/dataVisualization/chartComponents/RectangleSlice/RectangleSliceProvider";
import { RectangleSliceTooltipData } from "components/dataVisualization/chartComponents/RectangleSlice/RectangleSlicesLayer";
import { RequestStatus } from "hooks/useRequest";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import React, { useMemo, useState } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  modifyChartMargin,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { buildCustomShapePoint } from "utils/ChartDrawUtils/pointShapes";
import { getSettlementPeriodFromSettlementTime } from "utils/DateUtils";
import { addTooltipRangeValuesToDatasets } from "utils/chartTooltipUtils";
import { formatDateTimeString, DateFormat } from "utils/dateHelpers";

interface Props {
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  series: Serie[];
  dataFetchStatus: RequestStatus;
  dateFilter: DateFilterModel;
  onSliceClick: (settlementPeriodStartTime: Date) => void;
}

const BidOfferChart: React.FC<Props> = ({
  datasetCategories,
  series,
  dataFetchStatus,
  dateFilter,
  onSliceClick,
}) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        enablePoints
        pointSize={7}
        pointLabelYOffset={0}
        pointSymbol={buildCustomShapePoint}
        axisBottom={chartAxisBottom(
          "Start Time (UTC)",
          series,
          isChartLoaded,
          series.length === 0 ? undefined : 8
        )}
        axisLeft={chartAxisLeft("MW", isChartLoaded)}
        xScale={chartXTimeScale(dateFilter.startDate, dateFilter.endDate)}
        yScale={nonStackedLineChartYScale(series)}
        margin={modifyChartMargin({ top: 20 })}
      />
    );
  }, [dataFetchStatus, series, dateFilter]);

  const [rectangleSliceData, setRectangleSliceData] =
    useState<RectangleSliceTooltipData>();

  return (
    <StyledChartContainer data-test-id="chart-container">
      {series.length === 0 && (
        <ComponentPopover>
          <h4 data-test-id="gen-chart-no-data-msg">No data to show</h4>
        </ComponentPopover>
      )}
      <RectangleSliceProvider
        onSliceClick={onSliceClick}
        showSlices={series.length > 0}
        rectangleSliceData={rectangleSliceData}
        setRectangleSliceData={setRectangleSliceData}
        idPrefix="bidoffer"
        tooltip={(): JSX.Element =>
          rectangleSliceData ? (
            <ChartTooltipPositionWrapper
              pointIsInLeftHalf={rectangleSliceData.sliceInLeftHalf}
              interactionType={TooltipInteractionType.RectangleSlice}
            >
              <ChartTooltipContent
                datasetCategories={addTooltipRangeValuesToDatasets(
                  series,
                  datasetCategories,
                  rectangleSliceData.sliceStartValue,
                  rectangleSliceData.sliceEndValue
                )}
                title={formatDateTimeString(
                  rectangleSliceData.sliceStartValue,
                  DateFormat.DateTime
                )}
                settlementPeriod={getSettlementPeriodFromSettlementTime(
                  rectangleSliceData.sliceStartValue
                )}
                showCategoryTitles
                showValues={false}
                description="Click on a settlement period segment to see the values of each dataset in the tables below the graph."
              />
            </ChartTooltipPositionWrapper>
          ) : (
            <></>
          )
        }
      >
        {memoisedChart}
      </RectangleSliceProvider>
    </StyledChartContainer>
  );
};

export default BidOfferChart;
