import { IrisSummary } from "api/account/client";
import CopyToClipboard from "components/components/CopyToClipboard/CopyToClipboard";
import React from "react";

import { ClientDetailsContainer, ClientDetailsRow } from "./style";

type Props = {
  data: IrisSummary;
};

type PairProps = {
  label: string;
  value: string;
};

const tenantId = "1a235385-5d29-40e1-96fd-bc5ec2706361";

const LabelValuePairWithCopyButton: React.FC<PairProps> = ({
  label,
  value,
}) => (
  <ClientDetailsRow className="tile is-parent">
    <div className="tile is-child is-4">
      <span>
        <b>{label}</b>
      </span>
    </div>
    <div className="tile is-child is-6">
      <span>{value}</span>
    </div>
    <div className="tile is-child is-2">
      <CopyToClipboard value={value} />
    </div>
  </ClientDetailsRow>
);

const ClientDetails: React.FC<Props> = ({ data }) => (
  <ClientDetailsContainer className="tile is-ancestor is-vertical">
    <LabelValuePairWithCopyButton
      label="Client ID"
      value={data.applicationId}
    />
    <LabelValuePairWithCopyButton label="Queue name" value={data.queueName} />
    <LabelValuePairWithCopyButton
      label="Service Bus namespace"
      value={data.serviceBusNamespace}
    />
    <LabelValuePairWithCopyButton label="Tenant ID" value={tenantId} />
    <LabelValuePairWithCopyButton label="Queue URL" value={data.queueUrl} />
  </ClientDetailsContainer>
);

export default ClientDetails;
