import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const ErrorMessageWrapper = styled.div`
  padding-top: 2.7em;
  padding-bottom: 9em;
`;

export const StyledErrorText = styled.h1`
  &&& {
    padding: 0 2em 0 2em;
    margin: 0.8em 0 1em 0;
    line-height: 1.1em;
    color: ${colours.elexonBlue};
    font-size: ${fonts.huge};
    font-family: ${fonts.SourceSansPro};
    font-weight: ${fonts.light};
    text-align: center;
  }
`;

export const StyledErrorCode = styled.p`
  color: ${colours.darkGrey};
  font-size: ${fonts.large};
  text-align: center;
`;

export const StyledRefreshText = styled.p`
  font-size: ${fonts.medium};
`;
