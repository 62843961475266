import styled from "styled-components";
import colours from "styles/colours";

export const StyledButton = styled.button`
  border: none;
  border-bottom: 1px solid ${colours.elexonGrey};
  margin-bottom: 2px;
  background-color: transparent;
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
  width: 180px;

  &:disabled {
    border-bottom: none;

    &:hover {
      cursor: unset;
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  &:hover {
    cursor: pointer;
    border-bottom: 2px solid ${colours.elexonBlack};
    margin-bottom: 1px;
  }

  &:focus-visible {
    outline: 1px solid ${colours.focusBlue};
    border-radius: 2px;
  }
`;
