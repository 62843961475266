import { Datum, Serie } from "@nivo/line";
import { TemperatureSeriesIds } from "components/dataVisualization/Temperature/TemperatureChart/TemperatureDatasets";
import { TemperatureModel } from "models/temperature/temperatureModel";
import { chartColours } from "styles/colours";
import { sortByMeasurementDate } from "utils/ChartDataUtils/index";

export const transformTemperatureModelData = (
  rawData: TemperatureModel[]
): Serie[] => {
  const sortedData = sortByMeasurementDate(rawData);
  const dataSeries = sortedData.reduce(
    (prev, curr) => ({
      outturn: [
        ...prev.outturn,
        {
          x: new Date(curr.measurementDate),
          y: curr.temperature,
        },
      ],
      normal: [
        ...prev.normal,
        {
          x: new Date(curr.measurementDate),
          y: curr.temperatureReferenceAverage,
        },
      ],
      high: [
        ...prev.high,
        {
          x: new Date(curr.measurementDate),
          y: curr.temperatureReferenceHigh,
        },
      ],
      low: [
        ...prev.low,
        {
          x: new Date(curr.measurementDate),
          y: curr.temperatureReferenceLow,
        },
      ],
    }),
    {
      outturn: [] as Datum[],
      normal: [] as Datum[],
      high: [] as Datum[],
      low: [] as Datum[],
    }
  );
  return [
    {
      id: TemperatureSeriesIds.Outturn,
      colour: chartColours.temperatureData,
      data: dataSeries.outturn,
    },
    {
      id: TemperatureSeriesIds.Normal,
      colour: chartColours.temperatureReferenceLighter,
      data: dataSeries.normal,
    },
    {
      id: TemperatureSeriesIds.High,
      colour: chartColours.temperatureReferenceLighter,
      data: dataSeries.high,
    },
    {
      id: TemperatureSeriesIds.Low,
      colour: chartColours.temperatureReferenceLighter,
      data: dataSeries.low,
    },
  ];
};
