import { getData } from "api/dataVisualisation/client";
import { TotalLoadWeekAheadModel } from "models/TotalLoad/totalLoadModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { toDateOnlyString } from "utils/dateHelpers";

const baseQuery = "/forecast/demand/total/week-ahead";

export const getTotalLoadWeekAheadData = async (
  dateFilter: DateFilterModel,
  format: DataDownloadFormat
): Promise<DataDownloadModel<TotalLoadWeekAheadModel>> => {
  const { data } = await getData(
    baseQuery,
    {
      from: toDateOnlyString(dateFilter.normalisedStartDate),
      to: toDateOnlyString(dateFilter.normalisedEndDate),
    },
    format
  );

  return data as Promise<DataDownloadModel<TotalLoadWeekAheadModel>>;
};

export const getTotalLoadWeekAheadChartData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<TotalLoadWeekAheadModel>> =>
  getTotalLoadWeekAheadData(dateFilter, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<TotalLoadWeekAheadModel>
  >;

export const getTotalLoadWeekAheadLatestData = async (
  format: DataDownloadFormat
): Promise<DataDownloadModel<TotalLoadWeekAheadModel>> => {
  const { data } = await getData(`${baseQuery}/latest`, {}, format);

  return data as Promise<DataDownloadModel<TotalLoadWeekAheadModel>>;
};

export const getTotalLoadWeekAheadLatestChartData = async (): Promise<
  JsonDataDownloadModel<TotalLoadWeekAheadModel>
> =>
  getTotalLoadWeekAheadLatestData(DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<TotalLoadWeekAheadModel>
  >;
