import { getBidOfferJsonData } from "api/dataVisualisation/bidOffer/bidOffer";
import { getPerBmuPhysicalJsonData } from "api/dataVisualisation/physical/physical";
import useRequest, { RequestStatus } from "hooks/useRequest";
import {
  BidOfferChartData,
  BidOfferDataset,
} from "models/bidOffer/bidOfferChartData";
import { BidOfferPairModel } from "models/bidOffer/bidOfferPairModel";
import { PhysicalDataModel } from "models/bmuData/physicalDataModel";
import { JsonDataDownloadModel } from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useCallback } from "react";

export const getBidOfferDatasetFromPairId = (pairId: number): BidOfferDataset =>
  (pairId < 0
    ? `pairMinus${Math.abs(pairId)}`
    : `pair${pairId}`) as BidOfferDataset;

const bidOfferPairToBidOfferChartData = ({
  settlementDate,
  settlementPeriod,
  nationalGridBmUnit,
  timeFrom,
  timeTo,
  levelTo,
  levelFrom,
  bmUnit,
  pairId,
}: BidOfferPairModel): BidOfferChartData => ({
  settlementDate: new Date(settlementDate),
  settlementPeriod,
  nationalGridBmUnit,
  bmUnit,
  timeFrom: new Date(timeFrom),
  timeTo: new Date(timeTo),
  levelTo,
  levelFrom,
  dataset: getBidOfferDatasetFromPairId(pairId),
});

const physicalNotificationToBidOfferChartData = ({
  settlementDate,
  settlementPeriod,
  nationalGridBmUnit,
  timeFrom,
  timeTo,
  levelTo,
  levelFrom,
  bmUnit,
}: PhysicalDataModel): BidOfferChartData => ({
  settlementDate: new Date(settlementDate),
  settlementPeriod,
  nationalGridBmUnit,
  bmUnit,
  timeFrom: new Date(timeFrom),
  timeTo: new Date(timeTo),
  levelTo,
  levelFrom,
  dataset: "finalPhysicalNotification",
});

export const mapDownloadedDataToChartData = (
  rawPairData: JsonDataDownloadModel<BidOfferPairModel>,
  rawPhysicalNotificationData: JsonDataDownloadModel<PhysicalDataModel>
): BidOfferChartData[] => {
  const mappedPairData = rawPairData?.data
    ? rawPairData.data?.map(bidOfferPairToBidOfferChartData)
    : [];

  const mappedPhysicalNotificationData = rawPhysicalNotificationData?.data
    ? rawPhysicalNotificationData?.data.map(
        physicalNotificationToBidOfferChartData
      )
    : [];

  return [...mappedPairData, ...mappedPhysicalNotificationData];
};

export type BidOfferTabData = {
  chartData: BidOfferChartData[] | null;
  physicalNotificationData: PhysicalDataModel[] | null;
  bidOfferPairData: BidOfferPairModel[] | null;
};

export const getBidOfferTabData = async (
  dateFilter: DateFilterModel,
  bmUnit: string
): Promise<BidOfferTabData> => {
  const rawPairDataRequest = getBidOfferJsonData(dateFilter, bmUnit);

  const rawPhysicalNotificationDataRequest = getPerBmuPhysicalJsonData(
    dateFilter,
    bmUnit,
    ["PN"]
  );

  const [rawPairData, rawPhysicalNotificationData] = await Promise.all([
    rawPairDataRequest,
    rawPhysicalNotificationDataRequest,
  ]);

  return {
    chartData: mapDownloadedDataToChartData(
      rawPairData,
      rawPhysicalNotificationData
    ),
    physicalNotificationData: rawPhysicalNotificationData?.data,
    bidOfferPairData: rawPairData?.data,
  };
};

const useBidOfferData = (
  dateFilter: DateFilterModel | null,
  bmUnit: string | null
): {
  request: () => void;
  data: BidOfferTabData | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchBidOfferData,
  } = useRequest(getBidOfferTabData);

  const request = useCallback(() => {
    if (dateFilter && bmUnit) {
      fetchBidOfferData(dateFilter, bmUnit);
    }
  }, [bmUnit, dateFilter, fetchBidOfferData]);

  return {
    data,
    status,
    request,
  };
};

export default useBidOfferData;
