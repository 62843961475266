import React from "react";

import { MiniStyledTileChild, StyledTileChild } from "./style";

interface Props {
  children: JSX.Element;
  isMiniTile?: boolean;
}

const TileChild: React.FC<Props> = ({ children, isMiniTile }) =>
  isMiniTile ? (
    <MiniStyledTileChild className="tile is-child">
      {children}
    </MiniStyledTileChild>
  ) : (
    <StyledTileChild className="tile is-child">{children}</StyledTileChild>
  );

export default TileChild;
