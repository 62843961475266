import React from "react";
import { Helmet } from "react-helmet-async";

interface Props {
  title: string;
  description?: string;
}

const PageMeta: React.FC<Props> = ({ title, description }: Props) => (
  <Helmet>
    <title>{`${title} | Insights Solution`}</title>
    <meta name="description" content={description ?? `${title} - Elexon BSC`} />
  </Helmet>
);

export default PageMeta;
