export const downloadInBrowser = (fileName: string, blob: Blob): void => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const buildCsvBlobForDownload = (
  data: string,
  fileName: string
): void => {
  const blob = new Blob([data], { type: "text/csv" });

  downloadInBrowser(`${fileName}.csv`, blob);
};

export const buildJsonBlobForDownload = (
  data: unknown[],
  fileName: string
): void => {
  const blob = new Blob([JSON.stringify(data)], { type: "application/json" });

  downloadInBrowser(`${fileName}.json`, blob);
};

export const buildXmlBlobForDownload = (
  data: string,
  fileName: string
): void => {
  const blob = new Blob([data], { type: "application/xml" });

  downloadInBrowser(`${fileName}.xml`, blob);
};
