import TabSelector from "components/components/Tabs/TabSelector/TabSelector";
import {
  TabListContainer,
  TabList,
} from "components/components/Tabs/TabSelectors/style";
import { WidthType } from "components/components/Tabs/Tabs/Tabs";
import { TabDetails, TabName } from "components/components/Tabs/Tabs/Tabs";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

interface Props<T> {
  tabs: TabDetails<T>[];
  activeTabTitle: T;
  setActiveTabTitle: Dispatch<SetStateAction<T>>;
  widthTypeTab?: WidthType;
}

const TabSelectors = <T extends TabName>({
  tabs,
  activeTabTitle,
  setActiveTabTitle,
  widthTypeTab = WidthType.SemiWide,
}: Props<T>): JSX.Element => {
  const selectedTabNumber = useMemo((): number => {
    const activeTab = tabs.find((t) => t.title === activeTabTitle);
    return tabs.indexOf(activeTab!);
  }, [tabs, activeTabTitle]);
  const [isFocused, setIsFocused] = useState(false);

  const handleChangeToTabSelection = (newActiveTab: TabDetails<T>): void => {
    setActiveTabTitle(newActiveTab.title);

    if (!isFocused) {
      setIsFocused(true);
    }
  };

  const handleNextTab = (
    firstTabNumberInRound: number,
    nextTabNumber: number,
    lastTabNumberInRound: number
  ): void => {
    const tabToSelect =
      selectedTabNumber === lastTabNumberInRound
        ? firstTabNumberInRound
        : nextTabNumber;

    const tab = tabs[tabToSelect];
    handleChangeToTabSelection(tab);
  };

  const handleKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === "ArrowLeft") {
      const last = tabs.length - 1;
      const next = selectedTabNumber - 1;
      handleNextTab(last, next, 0);
    }
    if (event.key === "ArrowRight") {
      const first = 0;
      const next = selectedTabNumber + 1;
      handleNextTab(first, next, tabs.length - 1);
    }
  };

  return (
    <div className="container px-6">
      <TabListContainer
        widthType={widthTypeTab}
        data-test-id="tab-list-container"
      >
        <TabList role="tablist" aria-label="Tabs list">
          {tabs.map((tab) => (
            <TabSelector
              tab={tab}
              key={tab.title}
              onClick={(): void => handleChangeToTabSelection(tab)}
              isActiveTab={activeTabTitle === tab.title}
              handleKeyPress={handleKeyPress}
              isFocused={isFocused}
            />
          ))}
        </TabList>
      </TabListContainer>
    </div>
  );
};

export default TabSelectors;
