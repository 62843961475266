import { getBalancingServicesVolumeJsonData } from "api/dataVisualisation/balancingServicesVolume/balancingServicesVolume";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { BalancingServicesVolumeModel } from "models/bmuData/balancingServicesVolumeModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useCallback } from "react";

const useBalancingServicesVolumeData = (
  dateFilter: DateFilterModel | null,
  bmUnit?: string
): {
  data: BalancingServicesVolumeModel[] | null;
  status: RequestStatus;
  request: () => void;
} => {
  const {
    data,
    status,
    request: fetchBalancingServicesVolumeData,
  } = useRequest(getBalancingServicesVolumeJsonData);

  const request = useCallback(() => {
    if (dateFilter && bmUnit) {
      fetchBalancingServicesVolumeData(dateFilter, [bmUnit]);
    }
  }, [bmUnit, dateFilter, fetchBalancingServicesVolumeData]);

  return {
    data: data?.data ?? null,
    status,
    request,
  };
};

export default useBalancingServicesVolumeData;
