import { getDemandControlInstructionsJsonData } from "api/dataVisualisation/demandControlInstructions/demandControlInstructions";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { DemandControlInstructionsModel } from "models/demandControlInstructions/demandControlInstructionsModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useCallback } from "react";

const useDemandControlInstructionsData = (
  dateFilter: DateFilterModel
): {
  fetchData: () => void;
  data: DemandControlInstructionsModel[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchDemandControlInstructionsData,
  } = useRequest(getDemandControlInstructionsJsonData);

  const fetchData = useCallback(() => {
    fetchDemandControlInstructionsData(dateFilter);
  }, [dateFilter, fetchDemandControlInstructionsData]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useDemandControlInstructionsData;
