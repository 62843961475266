import BmuViewDataExporter from "components/dataVisualization/balancingMechanismTabs/DataExporter/bmuView/BmuViewDataExporter";
import { ChartFooter } from "components/dataVisualization/chartComponents/style";
import { useBmuViewContext } from "contexts/BmuViewContext";

const BmuViewFooter: React.FC = () => {
  const { selectedBmu, dateFilter } = useBmuViewContext();
  return (
    <>
      {selectedBmu && dateFilter && (
        <ChartFooter rightAligned>
          <BmuViewDataExporter
            selectedBmu={selectedBmu}
            dateFilter={dateFilter}
          />
        </ChartFooter>
      )}
    </>
  );
};

export default BmuViewFooter;
