import BalancingServicesVolume from "components/dataVisualization/balancingMechanismTabs/BalancingServicesVolume/BalancingServicesVolume";
import BalancingServicesVolumeTable from "components/dataVisualization/balancingMechanismTabs/BalancingServicesVolume/marketView/BalancingServicesVolumeTable/BalancingServicesVolumeTable";
import { mapToBalancingServicesVolumeRow } from "components/dataVisualization/balancingMechanismTabs/BalancingServicesVolume/utils";
import { NoDataAvailable } from "components/dataVisualization/balancingMechanismTabs/style";
import { useMarketViewContext } from "contexts/MarketViewContext";
import React from "react";

const MarketViewBalancingServicesVolume: React.FC = () => {
  const {
    balancingServicesVolumeDetails: { data, status, request },
    bmuDetails: { data: bmuData },
  } = useMarketViewContext();

  const mappedData = data ? mapToBalancingServicesVolumeRow(data, bmuData) : [];

  return (
    <BalancingServicesVolume status={status} fetchData={request}>
      {mappedData.length === 0 ? (
        <NoDataAvailable>No data available based on filter</NoDataAvailable>
      ) : (
        <BalancingServicesVolumeTable data={mappedData} />
      )}
    </BalancingServicesVolume>
  );
};

export default MarketViewBalancingServicesVolume;
