import { ClientSecret, IrisSummary } from "api/account/client";
import ClientDetails from "components/components/Iris/AuthenticatedView/IrisDetailsPage/IrisDetails/ClientDetails/ClientDetails";
import SecretsTable from "components/components/Iris/AuthenticatedView/IrisDetailsPage/IrisDetails/SecretsTable/SecretsTable";
import DeleteSecretModal from "components/components/Iris/DeleteSecretModal/DeleteSecretModal";
import { RequestStatus } from "hooks/useRequest";
import React, { useMemo, useState } from "react";

import {
  ClientDetailsContainer,
  ContentDiv,
  RequestSecretButton,
} from "./style";

type Props = {
  data: IrisSummary;
  fetchIrisDetails: () => void;
  fetchIrisDetailsStatus: RequestStatus;
  requestNewSecret: () => void;
};

const IrisDetails: React.FC<Props> = ({
  data,
  fetchIrisDetails,
  fetchIrisDetailsStatus,
  requestNewSecret,
}) => {
  const [secretToDelete, setSecretToDelete] = useState<ClientSecret | null>(
    null
  );
  const buttonsDisabled = useMemo(
    () => fetchIrisDetailsStatus === RequestStatus.IN_PROGRESS,
    [fetchIrisDetailsStatus]
  );

  const noSecrets = data.applicationSecrets.length === 0;

  return (
    <>
      <ClientDetailsContainer>
        <ClientDetails data={data} />
        <SecretsTable
          data={data}
          deleteDisabled={buttonsDisabled}
          setSecretToDelete={setSecretToDelete}
        />
        <ContentDiv aria-live="polite">
          {noSecrets ? (
            <p>
              There are no secrets associated with your Client ID. To receive
              IRIS messages, please{" "}
              <RequestSecretButton
                type="button"
                onClick={requestNewSecret}
                disabled={buttonsDisabled}
              >
                <b>request a secret</b>
              </RequestSecretButton>
              .
            </p>
          ) : (
            <p>
              You can request a new secret at any time and each secret is valid
              for 2 years. Once generated, a secret cannot be retrieved. If you
              have lost your secret or require a new one, please{" "}
              <RequestSecretButton
                type="button"
                onClick={requestNewSecret}
                disabled={buttonsDisabled}
              >
                <b>request a new secret</b>
              </RequestSecretButton>
              .
            </p>
          )}
        </ContentDiv>
      </ClientDetailsContainer>
      <DeleteSecretModal
        secret={secretToDelete}
        close={(): void => {
          setSecretToDelete(null);
          fetchIrisDetails();
        }}
      />
    </>
  );
};

export default IrisDetails;
