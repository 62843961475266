import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { TabulatedChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/TabulatedChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import { useMemo } from "react";
import {
  commonChartProps,
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayers } from "styles/chartStyles/chartLayers";
import { defaultColumnFormatter, priceFormatter } from "utils/ChartDataUtils";

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  rerequestData: () => void;
  dataFetchStatus: RequestStatus;
}

const MarketIndexPricesChart: React.FC<Props> = ({
  series,
  datasetCategories,
  rerequestData,
  dataFetchStatus,
}) => {
  const config: ChartConfiguration = useMemo(
    () => ({
      datasetCategories,
      tooltipColumns: [
        {
          id: "price",
          title: "Price",
          dataKey: "y",
          formatter: priceFormatter,
        },
        {
          id: "volume",
          title: "Volume",
          dataKey: "volume",
          formatter: defaultColumnFormatter({ suffix: "MWh" }),
        },
      ],
    }),
    [datasetCategories]
  );

  return useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;
    return (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      >
        <ResponsiveLine
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...commonChartProps(isChartLoaded, series)}
          enableSlices="x"
          axisBottom={chartAxisBottom(
            "Start Time (UTC)",
            series,
            isChartLoaded
          )}
          axisLeft={chartAxisLeft("Price (£)", isChartLoaded)}
          layers={chartLayers}
          sliceTooltip={(input): JSX.Element => (
            <TabulatedChartTooltip
              series={series}
              config={config}
              text={{
                label: "Start Time",
              }}
              options={{
                interactionType: TooltipInteractionType.Slice,
              }}
              inputPoints={input.slice.points}
            />
          )}
          xScale={chartXTimeScale()}
          yScale={nonStackedLineChartYScale(series)}
        />
      </ChartWithErrorHandling>
    );
  }, [config, dataFetchStatus, datasetCategories, rerequestData, series]);
};
export default MarketIndexPricesChart;
