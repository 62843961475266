import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import React from "react";

import { Box, IconContainer, Title } from "./styles";

interface Props {
  title: string;
  description: string;
}

const InfoBox: React.FC<Props> = ({ title, description }: Props) => (
  <Box>
    <IconContainer>
      <Icon iconName={faExclamationCircle} size={IconSize.small} aria-hidden />
    </IconContainer>
    <div data-test-id="info-box">
      <Title>{title}</Title>
      <span>{description}</span>
    </div>
  </Box>
);

export default InfoBox;
