import { getNetBalancingServicesAdjustmentTimeSeriesJsonData } from "api/dataVisualisation/netBalancingServicesAdjustment/netBalancingServicesAdjustment";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { NetBalancingServicesAdjustmentModel } from "models/netBalancingServicesAdjustment/netBalancingServicesAdjustmentModel";
import { useCallback } from "react";

const useAdjustmentDataNetbsadChartContainerData = (
  dateFilter: DateFilterModel
): {
  data: NetBalancingServicesAdjustmentModel[] | null;
  status: RequestStatus;
  fetchData: () => void;
} => {
  const { data, status, request } = useRequest(
    getNetBalancingServicesAdjustmentTimeSeriesJsonData
  );

  const fetchData = useCallback(() => {
    request(dateFilter);
  }, [dateFilter, request]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useAdjustmentDataNetbsadChartContainerData;
