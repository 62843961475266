import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { TabulatedChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/TabulatedChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayers } from "styles/chartStyles/chartLayers";
import { defaultColumnFormatter } from "utils/ChartDataUtils";

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  rerequestData: () => void;
  dataFetchStatus: RequestStatus;
}

const PriceAdjustmentChart: React.FC<Props> = ({
  series,
  datasetCategories,
  rerequestData,
  dataFetchStatus,
}: Props) => {
  const config: ChartConfiguration = useMemo(
    () => ({
      datasetCategories,
      tooltipColumns: [
        {
          id: "price",
          dataKey: "y",
          formatter: defaultColumnFormatter({ suffix: "£/MWh" }),
        },
      ],
    }),
    [datasetCategories]
  );

  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;
    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        axisBottom={chartAxisBottom("Start Time (UTC)", series, isChartLoaded)}
        axisLeft={chartAxisLeft("£/MWh", isChartLoaded)}
        layers={chartLayers}
        sliceTooltip={(input): JSX.Element => (
          <TabulatedChartTooltip
            series={series}
            config={config}
            text={{
              label: "Start Time",
            }}
            options={{
              interactionType: TooltipInteractionType.Slice,
            }}
            inputPoints={input.slice.points}
          />
        )}
        enableSlices="x"
        xScale={chartXTimeScale()}
        yScale={nonStackedLineChartYScale(series, { minimumMax: 8 })}
      />
    );
  }, [dataFetchStatus, series, config]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      >
        {memoisedChart}
      </ChartWithErrorHandling>
    ),
    [series, datasetCategories, dataFetchStatus, rerequestData, memoisedChart]
  );
};

export default PriceAdjustmentChart;
