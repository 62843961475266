import {
  featuredUpdateData,
  otherUpdatesData,
  previousUpdatesData,
} from "assets/updatesData/whatsNewPageData";
import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import HeaderNav from "components/components/HeaderNav/HeaderNav";
import PageMeta from "components/components/Meta/PageMeta";
import { FeaturedUpdate } from "components/components/Updates/FeaturedUpdate";
import PreviousUpdatesList from "components/components/Updates/PreviousUpdates/PreviousUpdatesList";
import RecentUpdates from "components/components/Updates/RecentUpdates";
import { DivWithMargins, NavContainer, Title } from "pages/WhatsNew/style";
import React from "react";
import { checkInput } from "utils/inputHelpers";

const title = "What's new";

const WhatsNew: React.FC = () => {
  document.addEventListener("keypress", (e) => {
    e.stopImmediatePropagation();
    checkInput(e.key);
  });
  return (
    <>
      <PageMeta title={title} />
      <NavContainer>
        <HeaderNav title={title} />
      </NavContainer>
      <FullWidthSection className="is-grey-section">
        <DivWithMargins>
          <Title data-test-id="whats-new-main-title">{title}</Title>
          <FeaturedUpdate update={featuredUpdateData} />
          <RecentUpdates data={otherUpdatesData} showNewBubbles />
        </DivWithMargins>
      </FullWidthSection>

      <PreviousUpdatesList updates={previousUpdatesData} />
    </>
  );
};

export default WhatsNew;
