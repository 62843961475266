import React, { useState } from "react";

interface Props {
  children: JSX.Element;
}

export interface ChartFocussedDate {
  focussedDate?: Date;
  setFocussedDate: (newFocussedDate?: Date) => void;
}

export const ChartFocussedDateContext = React.createContext<
  ChartFocussedDate | undefined
>(undefined);

export const ChartFocussedDateProvider: React.FC<Props> = ({ children }) => {
  const [focussedDate, setFocussedDate] = useState<Date>();

  return (
    <ChartFocussedDateContext.Provider
      value={{
        focussedDate,
        setFocussedDate,
      }}
    >
      {children}
    </ChartFocussedDateContext.Provider>
  );
};

export const useChartFocussedDateContext = (): ChartFocussedDate => {
  const context = React.useContext(ChartFocussedDateContext);

  if (context === undefined) {
    throw new Error(
      "useChartFocussedDateContext must be used within ChartFocussedDateContext's Provider"
    );
  }

  return context;
};
