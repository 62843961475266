import TabContentWithErrorHandling from "components/components/Tabs/TabContentWithErrorHandling/TabContentWithErrorHandling";
import { DescriptionText } from "components/components/Tabs/TabDescription/style";
import { NoDataAvailable } from "components/dataVisualization/balancingMechanismTabs/style";
import { useMarketViewContext } from "contexts/MarketViewContext";
import React from "react";

import BidOfferTable from "./BidOfferTable/BidOfferTable";
import { mapToBidOfferRow } from "./utils";

const MarketViewBidOffer: React.FC = () => {
  const {
    bidOfferDetails: { data, status, request },
    bmuDetails: { data: bmuData },
  } = useMarketViewContext();

  const mappedData = data ? mapToBidOfferRow(data, bmuData) : [];

  const description = `
          Bid-Offer Data (BOD) for a BM Unit and Settlement Period is made up of
          a series of Bid-Offer pairs.`;

  return (
    <>
      <DescriptionText>{description}</DescriptionText>
      <TabContentWithErrorHandling status={status} fetchData={request}>
        {mappedData.length === 0 ? (
          <NoDataAvailable>No data available based on filter</NoDataAvailable>
        ) : (
          <BidOfferTable data={mappedData} />
        )}
      </TabContentWithErrorHandling>
    </>
  );
};

export default MarketViewBidOffer;
