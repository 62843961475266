import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { SystemWarningsModel } from "models/systemWarnings/systemWarningsModel";

export const getSystemWarningsData = async (
  dateFilter: DateFilterModel | null,
  warningType: string | null,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<SystemWarningsModel>> => {
  const { data } = await getData(
    "/system/warnings",
    {
      warningType: warningType?.toUpperCase(),
      publishDateTimeFrom: dateFilter
        ? dateFilter.normalisedStartDate.toISOString()
        : null,
      publishDateTimeTo: dateFilter
        ? dateFilter.normalisedEndDate.toISOString()
        : null,
    },
    format
  );
  return data;
};

export const getSystemWarningsJsonData = (
  dateFilter: DateFilterModel | null,
  warningType: string | null
): Promise<JsonDataDownloadModel<SystemWarningsModel>> =>
  getSystemWarningsData(
    dateFilter,
    warningType,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<SystemWarningsModel>>;
