import { Layer } from "@nivo/line";
import RectangleSlicesLayerForTimeSeries, {
  RectangleSliceTooltipData,
} from "components/dataVisualization/chartComponents/RectangleSlice/RectangleSlicesLayer";
import React, { useMemo } from "react";
import { insertLayerAfterTargetLayer } from "styles/chartStyles/chartLayers";

import { CustomTooltipChart, CustomTooltipWrapper } from "./style";

type RectangleSliceProviderProps = {
  children: React.ReactElement<{ layers: Layer[] }>;
  tooltip?: () => React.ReactNode;
  rectangleSliceData?: RectangleSliceTooltipData;
  setRectangleSliceData: (
    newRectangleSliceTooltipData?: RectangleSliceTooltipData
  ) => void;
  onSliceClick?: (settlementPeriodStartTime: Date) => void;
  showSlices: boolean;
  idPrefix: string;
};

/**
 * Applies custom rectangle slices with a tooltip interface to the child chart component
 *
 * The child component must have a "layers" prop, if the layers are edited the 'slices' element
 * must be present in the modified layer array
 *
 * Currently only implemented for charts with time as the x axis
 */
const RectangleSliceProvider: React.FC<RectangleSliceProviderProps> = ({
  children,
  tooltip,
  rectangleSliceData,
  setRectangleSliceData,
  onSliceClick,
  showSlices,
  idPrefix,
}) => {
  const memoisedChart = useMemo(
    () =>
      children &&
      React.cloneElement(children, {
        layers: insertLayerAfterTargetLayer(
          "slices",
          (layerProps) => (
            <RectangleSlicesLayerForTimeSeries
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...layerProps}
              onSliceClick={onSliceClick}
              setRectangleSliceData={setRectangleSliceData}
              showSlices={showSlices}
              idPrefix={idPrefix}
            />
          ),
          {
            chartLayers: children.props.layers,
          }
        ),
      }),
    [children, onSliceClick, showSlices, setRectangleSliceData, idPrefix]
  );

  return (
    <CustomTooltipChart>
      {memoisedChart}
      {rectangleSliceData && tooltip && (
        <CustomTooltipWrapper
          offset={rectangleSliceData.sliceXCenterPosition}
          tooltipToRight={rectangleSliceData.sliceInLeftHalf}
        >
          {tooltip()}
        </CustomTooltipWrapper>
      )}
    </CustomTooltipChart>
  );
};
export default React.memo(RectangleSliceProvider);
