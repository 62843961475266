import { useDataExport } from "contexts/DataExportContext";
import { ToastDetails, ToastState } from "models/ToastDetails";
import React, { useEffect } from "react";

import { createOrUpdateDataExportToast } from "./DataExportToast";

const DEFAULT_TOAST_DURATION_MILLISECONDS = 60_000;

export const DataExportToastManager: React.FC = () => {
  const { state } = useDataExport();

  useEffect(() => {
    if (!state.toastVisible) {
      return;
    }

    const toastId = state.toastId.toString();

    let toast: ToastDetails;

    if (state.count) {
      toast = {
        id: toastId,
        title: `Exporting, ${state.count} remaining...`,
        message: "We are preparing your data for export",
        state: ToastState.InProgress,
      };
    } else if (state.errorCount) {
      toast = {
        id: toastId,
        title: `${state.errorCount} export${
          state.errorCount > 1 ? "s" : ""
        } failed`,
        message: "Please try again later.",
        state: ToastState.Error,
      };
    } else {
      toast = {
        id: toastId,
        title: "Export complete",
        state: ToastState.Complete,
      };
    }

    createOrUpdateDataExportToast(toast, DEFAULT_TOAST_DURATION_MILLISECONDS);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- updating on toastId causes an unnecessary render and it isn't changed elsewhere
  }, [state.count, state.errorCount, state.toastVisible]);

  return <></>;
};
