import { getNonBmStorTimeSeriesData } from "api/dataVisualisation/nonBmStor/nonBmStor";
import {
  ChartBody,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import ShortTermOperatingReservesChart from "components/dataVisualization/shortTermOperatingReserves/ShortTermOperatingReservesChart/ShortTermOperatingReservesChart";
import { useChartFocussedDateContext } from "contexts/ChartFocussedDateContext";
import { useDataExport } from "contexts/DataExportContext";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React, { useEffect, useMemo, useState } from "react";
import { transformNonBmStorModelData } from "utils/ChartDataUtils/nonBmStor/nonBmStor";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  addDaysToDate,
  addHoursToDate,
  ceilToHalfHour,
  DAYS_IN_ONE_WEEK,
  getEarlier,
} from "utils/dateHelpers";

import useShortTermOperatingReservesChartContainerData from "./useShortTermOperatingReservesChartContainerData";

const ShortTermOperatingReservesChartContainer: React.FC = () => {
  const defaultEndDate = ceilToHalfHour(new Date());

  const dateSelectorTabs = [
    DateSelectorTabs.TwentyFourHours,
    DateSelectorTabs.OneWeek,
  ];

  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(
      addDaysToDate(defaultEndDate, -7),
      new Date(defaultEndDate)
    )
  );

  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.OneWeek
  );

  const { dispatch: dataExportCountDispatch } = useDataExport();
  const { focussedDate } = useChartFocussedDateContext();

  const {
    data: rawData,
    fetchData,
    status,
  } = useShortTermOperatingReservesChartContainerData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const chartData = useMemo(
    () => (rawData?.length ? transformNonBmStorModelData(rawData) : []),
    [rawData]
  );

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getNonBmStorTimeSeriesData(dateFilter, fileFormat),
      `ShortTermOperatingReserves-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    setDateFilter(newDateFilter.restrictToMaxOneWeek());
    setActiveDateSelectorTab(newActiveTab);
  };

  // handle summary tile event views
  useEffect(() => {
    if (focussedDate === undefined) {
      return;
    }

    // center chart on focussedDate up to current time
    setDateFilter(
      new DateFilterModel(
        addHoursToDate(addDaysToDate(focussedDate, -3), -12),
        getEarlier(
          addHoursToDate(addDaysToDate(focussedDate, 3), 12),
          ceilToHalfHour(new Date())
        )
      )
    );

    setActiveDateSelectorTab(DateSelectorTabs.Date);

    // scroll chart into view
    document
      .getElementById("chart-footer")!
      .scrollIntoView({ block: "end", behavior: "smooth" });
  }, [focussedDate]);

  return (
    <>
      <ChartHeader>
        <ChartFilter
          dateSelectorTabs={dateSelectorTabs}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={DAYS_IN_ONE_WEEK}
        />
      </ChartHeader>
      <ChartBody>
        <ShortTermOperatingReservesChart
          series={chartData}
          dataFetchStatus={status}
          rerequestData={fetchData}
        />
      </ChartBody>
      <ChartFooter rightAligned id="chart-footer">
        <DataExporter
          downloadData={downloadFile}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
        />
      </ChartFooter>
    </>
  );
};

export default ShortTermOperatingReservesChartContainer;
