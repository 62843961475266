import { getData } from "api/dataVisualisation/client";
import { BidOffer } from "components/dataVisualization/DerivedData/useAcceptanceVolumeData";
import { BmuModel } from "models/bmuData/bmuModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { DataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { IndicativeCashflowModel } from "models/derivedData/indicativeCashflow";
import { IndicativeVolumeModel } from "models/derivedData/indicativeVolumeModel";
import { getSettlementDateAndPeriodFromSettlementTime } from "utils/DateUtils";
import { batchBmuIds, stitchDataResults } from "utils/dataStitchHelpers";
import { toDateOnlyString } from "utils/dateHelpers";

export const getIndicativeDerivedData = async <
  T extends IndicativeCashflowModel | IndicativeVolumeModel
>(
  baseUrl: string,
  settlementTime: Date,
  bidOffer: BidOffer,
  bmUnits?: BmuModel[],
  format?: DataDownloadFormat
): Promise<DataDownloadModel<T>> => {
  const { settlementDate, settlementPeriod } =
    getSettlementDateAndPeriodFromSettlementTime(settlementTime);

  if (bmUnits) {
    const bmuIds = bmUnits?.map((unit) => unit.elexonBmUnit);
    const bmuBatches = batchBmuIds(bmuIds ?? []);
    const requests = bmuBatches.map(async (batch) => {
      const { data } = await getData(
        `${baseUrl}/${bidOffer}/${toDateOnlyString(
          settlementDate
        )}/${settlementPeriod}`,
        { bmUnit: batch },
        format
      );
      return data;
    });

    const results = await Promise.all(requests);
    return stitchDataResults<T>(results, format ?? DataDownloadFormat.Json);
  } else {
    const { data } = await getData(
      `${baseUrl}/${bidOffer}/${toDateOnlyString(
        settlementDate
      )}/${settlementPeriod}`,
      {},
      format
    );

    return data;
  }
};
