import { PropsWithChildren } from "react";

import { StyledSection } from "./style";

interface TableSectionProps {
  className?: string;
  id?: string;
  rightAligned?: boolean;
}

const TableSection: React.FC<PropsWithChildren<TableSectionProps>> = ({
  className,
  id,
  rightAligned,
  children,
}) => (
  <StyledSection
    data-test-id="table-section"
    rightAligned={rightAligned}
    id={id}
    className={className}
  >
    {children}
  </StyledSection>
);

export default TableSection;
