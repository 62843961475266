import { getNetBalancingServicesAdjustmentTimeSeriesData } from "api/dataVisualisation/netBalancingServicesAdjustment/netBalancingServicesAdjustment";
import PriceAdjustmentChart from "components/dataVisualization/AdjustmentDataNetbsad/PriceAdjustmentChart/PriceAdjustmentChart";
import {
  ChartBody,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import { useChartFocussedDateContext } from "contexts/ChartFocussedDateContext";
import { useDataExport } from "contexts/DataExportContext";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import { NetBalancingServicesAdjustmentIds } from "models/netBalancingServicesAdjustment/netBalancingServicesAdjustmentModel";
import React, { useEffect, useMemo, useState } from "react";
import { netbsadDatasetColours } from "styles/colours";
import { transformNetBalancingServicesAdjustmentModelPriceAdjustmentData } from "utils/ChartDataUtils/adjustmentDataNetbsad/adjustmentDataNetbsad";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  addDaysToDate,
  addHoursToDate,
  ceilToHalfHour,
  DAYS_IN_ONE_WEEK,
  getEarlier,
} from "utils/dateHelpers";

import useAdjustmentDataNetbsadChartContainerData from "./useAdjustmentDataNetbsadChartContainerData";

const datasetCategories: DatasetCategory<ChartDatasetModel>[] = [
  {
    datasets: [
      {
        colour:
          netbsadDatasetColours[
            NetBalancingServicesAdjustmentIds.BuyPriceAdjustment
          ],
        dataKey: NetBalancingServicesAdjustmentIds.BuyPriceAdjustment,
        displayName: "Buy Price Adjustment",
      },
      {
        colour:
          netbsadDatasetColours[
            NetBalancingServicesAdjustmentIds.SellPriceAdjustment
          ],
        dataKey: NetBalancingServicesAdjustmentIds.SellPriceAdjustment,
        displayName: "Sell Price Adjustment",
      },
    ],
    id: "priceAdjustment",
  },
];

const AdjustmentDataNetbsadChartContainer: React.FC = () => {
  const defaultEndDate = ceilToHalfHour(new Date());

  const dateSelectorTabs = [
    DateSelectorTabs.TwentyFourHours,
    DateSelectorTabs.OneWeek,
  ];

  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(
      addDaysToDate(defaultEndDate, -7),
      new Date(defaultEndDate)
    )
  );

  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.OneWeek
  );

  const { dispatch: dataExportCountDispatch } = useDataExport();
  const { focussedDate } = useChartFocussedDateContext();

  const {
    data: rawData,
    fetchData,
    status,
  } = useAdjustmentDataNetbsadChartContainerData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const chartData = useMemo(
    () =>
      rawData?.length
        ? transformNetBalancingServicesAdjustmentModelPriceAdjustmentData(
            rawData
          )
        : [],
    [rawData]
  );

  const downloadFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () =>
        getNetBalancingServicesAdjustmentTimeSeriesData(dateFilter, fileFormat),
      `AdjustmentDataNetbsad-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const handleChangeToDateSelection = (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ): void => {
    setDateFilter(newDateFilter.restrictToMaxOneWeek());
    setActiveDateSelectorTab(newActiveTab);
  };

  // handle summary tile event views
  useEffect(() => {
    if (focussedDate === undefined) {
      return;
    }

    // center chart on focussedDate up to current time
    setDateFilter(
      new DateFilterModel(
        addHoursToDate(addDaysToDate(focussedDate, -3), -12),
        getEarlier(
          addHoursToDate(addDaysToDate(focussedDate, 3), 12),
          ceilToHalfHour(new Date())
        )
      )
    );

    setActiveDateSelectorTab(DateSelectorTabs.Date);

    // scroll chart into view
    document
      .getElementById("chart-footer")!
      .scrollIntoView({ block: "end", behavior: "smooth" });
  }, [focussedDate]);

  return (
    <>
      <ChartHeader>
        <ChartFilter
          dateSelectorTabs={dateSelectorTabs}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={DAYS_IN_ONE_WEEK}
        />
      </ChartHeader>
      <ChartBody>
        <PriceAdjustmentChart
          series={chartData}
          datasetCategories={datasetCategories}
          dataFetchStatus={status}
          rerequestData={fetchData}
        />
      </ChartBody>
      <ChartFooter rightAligned id="chart-footer">
        <DataExporter
          downloadData={downloadFile}
          dateFilter={dateFilter}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
        />
      </ChartFooter>
    </>
  );
};

export default AdjustmentDataNetbsadChartContainer;
