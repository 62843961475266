import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import { DagData } from "models/dayAheadAggregatedGeneration/dayAheadAggregatedGeneration";
import DateFilterModel from "models/filterModels/dateFilterModel";

export const getDagData = async (
  dateFilter: DateFilterModel,
  format: DataDownloadFormat
): Promise<DataDownloadModel<DagData>> => {
  const { data } = await getData("/forecast/generation/day-ahead", {
    from: dateFilter.normalisedStartDate.toISOString(),
    to: dateFilter.normalisedEndDate.toISOString(),
    format,
  });
  return data;
};

export const getDagChartData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<DagData>> =>
  getDagData(dateFilter, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<DagData>
  >;
