import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Button from "components/components/Button/Button";
import Modal, { ModalSize } from "components/components/Modal/Modal";
import {
  ModalButtonContainer,
  ModalPadding,
  ModalSection,
} from "components/components/Modal/commonStyle";
import React, { PropsWithChildren, useState } from "react";

interface Props extends PropsWithChildren {
  onExportClick: () => void;
  exportDisabled?: boolean;
}

const DataExporterContainer: React.FC<Props> = ({
  onExportClick,
  exportDisabled,
  children,
}: Props) => {
  const [isModalActive, setIsModalActive] = useState(false);

  const handleExportClick = (): void => {
    onExportClick();
    setIsModalActive(false);
  };

  return (
    <>
      <div data-test-id="export-data-open-button-container">
        <Button
          ariaLabel="Export"
          buttonText="export"
          iconName={faDownload}
          onClick={(): void => setIsModalActive(true)}
        />
      </div>
      <Modal
        size={ModalSize.large}
        isActive={isModalActive}
        onClose={(): void => setIsModalActive(false)}
        closeOnEsc={false} // TODO: enable this to be escaped see ticket #52645
        headingId="export-modal-header"
        allowOverflow
      >
        <ModalPadding data-test-id="export-data-modal">
          {children}
          <hr />
          <ModalSection className="centre">
            <ModalButtonContainer data-test-id="export-data-button-container">
              <Button
                ariaLabel="Cancel"
                buttonText="cancel"
                className="cancel"
                onClick={(): void => setIsModalActive(false)}
              />
              <Button
                ariaLabel="Export"
                buttonText="export"
                onClick={handleExportClick}
                disabled={exportDisabled}
              />
            </ModalButtonContainer>
          </ModalSection>
        </ModalPadding>
      </Modal>
    </>
  );
};

export default DataExporterContainer;
