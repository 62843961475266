import ComponentWithRequest from "components/components/ComponentWithRequest/ComponentWithRequest";
import AdjustmentActionsDisbsadDetailsTable, {
  DisbsadDetailsTableRow,
} from "components/dataVisualization/AdjustmentActionsDisbsad/AdjustmentActionsDisbsadDetailsTable/AdjustmentActionsDisbsadDetailsTable";
import useDisbsadDetailsData from "components/dataVisualization/AdjustmentActionsDisbsad/AdjustmentActionsDisbsadDetailsTableContainer/useDisbsadDetailsData";
import { RequestStatus } from "hooks/useRequest";
import { DisaggregatedBalancingServicesAdjustmentDetailsModel } from "models/disaggregatedBalancingServicesAdjustment/disaggregatedBalancingServicesAdjustmentDetailsModel";
import React, { useEffect } from "react";
import { toSettlementPeriodRangeString } from "utils/dateHelpers";

import { TableLoadingWrapper } from "./style";

const mapDisbsadDetailsToTableRows = (
  data: DisaggregatedBalancingServicesAdjustmentDetailsModel[]
): DisbsadDetailsTableRow[] =>
  data?.map(
    ({
      partyId,
      id,
      assetId,
      cost,
      volume,
      price,
      service,
      isTendered,
      soFlag,
      storFlag,
    }) => ({
      id,
      actionId: id,
      partyId,
      assetId,
      cost,
      volume,
      price,
      service,
      isTendered,
      soFlag,
      storFlag,
    })
  );

const AdjustmentActionsDisbsadDetailsTableContainer: React.FC<{
  startTime?: Date;
}> = ({ startTime }) => {
  const { data, fetchData, status } = useDisbsadDetailsData(startTime);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const tableData = mapDisbsadDetailsToTableRows(data ?? []);

  return (
    <>
      {startTime ? (
        <div data-test-id="disbsad-table-data">
          <h3>{toSettlementPeriodRangeString(startTime)}</h3>
          <TableLoadingWrapper>
            <ComponentWithRequest
              dataFetchStatus={status}
              rerequestData={fetchData}
            >
              {status === RequestStatus.SUCCESSFUL_OR_NOT_STARTED && (
                <AdjustmentActionsDisbsadDetailsTable tableData={tableData} />
              )}
            </ComponentWithRequest>
          </TableLoadingWrapper>
        </div>
      ) : (
        "Select a settlement period on the graph above to see the values of each dataset."
      )}
    </>
  );
};

export default React.memo(AdjustmentActionsDisbsadDetailsTableContainer);
