import { IPublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import { authContext as appAuthContext, loginRequest } from "utils/authUtils";

const graphApiBaseUrl = "https://graph.microsoft.com/v1.0";

export interface UserData {
  id: string;
  displayName: string;
  givenName: string;
  surname: string;
  userPrincipalName: string;
}

const getUserDataInternal = async (
  authContext: IPublicClientApplication
): Promise<UserData> => {
  const account = authContext.getActiveAccount();
  if (!account) {
    throw new Error(
      "No active account - verify a user has been signed in and setActiveAccount has been called"
    );
  }

  const response = await authContext.acquireTokenSilent({
    ...loginRequest,
    account,
  });

  const { data } = await axios.get<UserData>(`${graphApiBaseUrl}/me`, {
    headers: { Authorization: `Bearer ${response.accessToken}` },
  });
  return data;
};

// Note: once authorisation is no longer hidden behind a feature flag we can remove
// this additional wrapper method - we only need it to protect against the authContext
// being undefined
export const getUserData = (): Promise<UserData> => {
  if (!appAuthContext) {
    throw new Error(
      "Authorisation context not initialised - is the account area feature flag set?"
    );
  } else {
    return getUserDataInternal(appAuthContext);
  }
};
