import styled from "styled-components";
import fonts from "styles/fonts";

export const ModalHeader = styled.h2``;

export const ModalSubHeader = styled(ModalHeader).attrs({
  as: "h3",
})``;

export const ModalInformation = styled.div`
  font-size: ${fonts.small};
  margin-top: 5px;
`;

export const ModalPadding = styled.div`
  padding: 40px 30px;
`;

export const ModalSection = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 40px;
  }

  &.centre {
    justify-content: center;
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;

  > * + * {
    margin-left: 20px;
  }
`;

export const FlexContainerColumn = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    > label {
      margin-bottom: 20px;
    }
  }
`;
