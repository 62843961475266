import { ResponsiveLine, Serie, SliceTooltipProps } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { TabulatedChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/TabulatedChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import { TooltipColumn } from "models/chartConfiguration/tooltipModels";
import { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayers } from "styles/chartStyles/chartLayers";
import { dateFormatter, defaultColumnFormatter } from "utils/ChartDataUtils";
import { DateFormat } from "utils/dateHelpers";

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  rerequestData: () => void;
  dataFetchStatus: RequestStatus;
  xAxisTitle?: string;
  yAxisTitle?: string;
  showPublishTime?: boolean;
  tooltipText?: string;
  dateOnlyFormat?: boolean;
}

/** Commonised line chart for transparency dataset pages, you can specify
 * xAxisTitle - title for the x-axis
 * yAxisTitle - title for the y-axis
 * showPublishTime - whether to show publish time in the tooltip
 * tooltipText - to override the default tooltip title (Start Time)
 * dateOnlyFormat - whether to format the tooltip header dates as date only
 * */

const DatasetLineChart: React.FC<Props> = ({
  series,
  datasetCategories,
  dataFetchStatus,
  rerequestData,
  xAxisTitle = "Start Time (UTC)",
  yAxisTitle = "MW",
  showPublishTime = true,
  tooltipText,
  dateOnlyFormat = false,
}: Props) => {
  const tooltipColumns = useMemo(() => {
    const columns: TooltipColumn[] = [
      {
        id: "quantity",
        dataKey: "y",
        formatter: defaultColumnFormatter({ suffix: yAxisTitle }),
      },
    ];

    if (showPublishTime) {
      columns.push({
        id: "publishTime",
        title: "Publish Time",
        dataKey: "publishTime",
        formatter: dateFormatter(DateFormat.DateTime),
      });
    }

    return columns;
  }, [showPublishTime, yAxisTitle]);

  const memoisedChart = useMemo(() => {
    const config: ChartConfiguration = {
      datasetCategories,
      tooltipColumns: tooltipColumns,
    };

    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    const tooltip = (input: SliceTooltipProps): JSX.Element => {
      const options = {
        interactionType: TooltipInteractionType.Slice,
        ...(dateOnlyFormat ? { dateFormat: DateFormat.DateOnly } : {}),
      };

      return (
        <TabulatedChartTooltip
          series={series}
          config={config}
          text={{
            label: tooltipText,
          }}
          options={options}
          inputPoints={input.slice.points}
        />
      );
    };
    return (
      <ResponsiveLine
        {...commonChartProps(isChartLoaded, series)}
        enableSlices="x"
        axisBottom={chartAxisBottom(xAxisTitle, series, isChartLoaded)}
        axisLeft={chartAxisLeft(yAxisTitle, isChartLoaded)}
        layers={chartLayers}
        sliceTooltip={tooltip}
        xScale={chartXTimeScale()}
        yScale={nonStackedLineChartYScale(series)}
      />
    );
  }, [
    dataFetchStatus,
    datasetCategories,
    dateOnlyFormat,
    series,
    tooltipColumns,
    tooltipText,
    xAxisTitle,
    yAxisTitle,
  ]);

  return (
    <ChartWithErrorHandling
      series={series}
      datasetCategories={datasetCategories}
      dataFetchStatus={dataFetchStatus}
      rerequestData={rerequestData}
    >
      {memoisedChart}
    </ChartWithErrorHandling>
  );
};

export default DatasetLineChart;
