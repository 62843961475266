import { getData, getStitchedBmusData } from "api/dataVisualisation/client";
import { BidOfferAcceptanceModel } from "models/bidOffer/bidOfferAcceptanceModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { getSettlementDateAndPeriodFromSettlementTime } from "utils/DateUtils";
import { toDateOnlyString } from "utils/dateHelpers";

export const getPerBmuBidOfferAcceptancesData = async (
  dateFilter: DateFilterModel,
  bmUnit: string,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<BidOfferAcceptanceModel>> => {
  const { data } = await getData(
    "/balancing/acceptances",
    {
      from: dateFilter.normalisedStartDate.toISOString(),
      to: dateFilter.normalisedEndDate.toISOString(),
      bmUnit,
    },
    format
  );
  return data;
};

export const getMarketWideBidOfferAcceptancesData = async (
  date: Date,
  bmUnits?: string[],
  format?: DataDownloadFormat
): Promise<DataDownloadModel<BidOfferAcceptanceModel>> => {
  const { settlementDate, settlementPeriod } =
    getSettlementDateAndPeriodFromSettlementTime(date);
  return getStitchedBmusData(
    "/balancing/acceptances/all",
    {
      settlementDate: toDateOnlyString(settlementDate),
      settlementPeriod: settlementPeriod,
    },
    bmUnits,
    format
  );
};

export const getPerBmuBidOfferAcceptancesJsonData = (
  dateFilter: DateFilterModel,
  bmUnits: string
): Promise<JsonDataDownloadModel<BidOfferAcceptanceModel>> =>
  getPerBmuBidOfferAcceptancesData(dateFilter, bmUnits) as Promise<
    JsonDataDownloadModel<BidOfferAcceptanceModel>
  >;

export const getMarketWideBidOfferAcceptancesJsonData = (
  settlementDate: Date,
  bmUnits?: string[]
): Promise<JsonDataDownloadModel<BidOfferAcceptanceModel>> =>
  getMarketWideBidOfferAcceptancesData(settlementDate, bmUnits) as Promise<
    JsonDataDownloadModel<BidOfferAcceptanceModel>
  >;
