import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import { useCallback, useEffect, useState } from "react";

export const useDatasetCategoriesWithVisibilities = (
  initialDatasetCategories: DatasetCategory<ChartDatasetModel>[] = []
): {
  allDatasetCategories: DatasetCategory<ChartDatasetModel>[];
  datasetCategoriesToDisplay: DatasetCategory<ChartDatasetModel>[];
  setDatasetCategories: (value: DatasetCategory<ChartDatasetModel>[]) => void;
  setMatchingDatasetsVisibility: (
    visible: boolean,
    dataset?: ChartDatasetModel,
    categoryId?: string
  ) => void;
  setMatchingDatasetsDisabled: (disabled: boolean, categoryId: string) => void;
} => {
  const [datasetCategories, setDatasetCategories] = useState<
    DatasetCategory<ChartDatasetModel>[]
  >(initialDatasetCategories);

  const [datasetsToDisplay, setDatasetsToDisplay] = useState<
    DatasetCategory<ChartDatasetModel>[]
  >([]);

  const setVisibilities = useCallback(
    (
      visible: boolean,
      dataset?: ChartDatasetModel,
      categoryId?: string
    ): void => {
      setDatasetCategories((categories) =>
        categories.map((category) => {
          if (categoryId !== undefined && categoryId !== category.id) {
            return category;
          }

          return {
            ...category,
            datasets: category.datasets.map((d) =>
              (dataset?.dataKey ?? d.dataKey) === d.dataKey
                ? {
                    ...d,
                    isHidden: !visible,
                  }
                : d
            ),
          };
        })
      );
    },
    []
  );

  const setDisableds = useCallback(
    (disabled: boolean, categoryId: string): void => {
      setDatasetCategories((categories) =>
        categories.map((category) => {
          if (categoryId !== undefined && categoryId !== category.id) {
            return category;
          }

          return {
            ...category,
            disabled,
          };
        })
      );
    },
    []
  );

  useEffect(
    () =>
      setDatasetsToDisplay(
        datasetCategories.map((category) => ({
          ...category,
          datasets: category.datasets.filter((dataset) => !dataset.isHidden),
        }))
      ),
    [datasetCategories]
  );

  return {
    allDatasetCategories: datasetCategories,
    datasetCategoriesToDisplay: datasetsToDisplay,
    setDatasetCategories,
    setMatchingDatasetsVisibility: setVisibilities,
    setMatchingDatasetsDisabled: setDisableds,
  };
};
