import { getData } from "api/dataVisualisation/client";
import { GenerationForecastDataModel } from "models/generationForecast/generationForecastDataModel";
import {
  addForecastDatesToWeeklyData,
  parseForecastDate,
} from "utils/generationForecastModelHelpers";

export const getGenerationForecast14DayData = async (): Promise<
  GenerationForecastDataModel[]
> => {
  const { data } = await getData("/forecast/availability/daily", {
    level: "fuelType",
  });
  if (data?.data) {
    data.data = parseForecastDate(data.data);
  }
  return data?.data;
};

export const getGenerationForecast3YearData = async (): Promise<
  GenerationForecastDataModel[]
> => {
  const { data } = await getData("/forecast/availability/weekly", {
    level: "fuelType",
  });
  if (data?.data) {
    data.data = addForecastDatesToWeeklyData(data.data);
  }
  return data?.data;
};
