import styled from "styled-components";
import colours from "styles/colours";

export const NoDataAvailable = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin: 3em 0;
  color: ${colours.darkGrey};
`;
