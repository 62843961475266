import styled from "styled-components";
import colours from "styles/colours";

export const NavGroupContainer = styled.div`
  justify-content: space-around;
  flex-grow: 1;
`;

export const FlexDiv = styled.div`
  display: flex;
`;

export const StyledNav = styled.nav`
  border-bottom: 1px solid ${colours.elexonBlack};
`;
