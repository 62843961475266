import {
  getDemandForecastDailyJsonData,
  getDemandForecastDayAheadLatestNationalJsonData,
  getDemandForecastWeeklyJsonData,
} from "api/dataVisualisation/demandForecast/demandForecast";
import { getInitialDemandOutturnJsonData } from "api/dataVisualisation/initialDemand/initialDemand";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { JsonDataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { DemandForecastDaily } from "models/demandForecast/demandForecastDaily";
import { DemandForecastDayAhead } from "models/demandForecast/demandForecastDayAhead";
import { DemandForecastWeekly } from "models/demandForecast/demandForecastWeekly";
import { InitialDemandOutturnModel } from "models/demandOutturn/InitialDemandOutturnModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { ForecastFilterOption } from "models/filterModels/forecastFilterOption";
import { useCallback } from "react";
import { addDaysToDate, endOfDay, startOfDay } from "utils/dateHelpers";
import { combineRequestStatuses } from "utils/requestHelpers";

const useDemandForecastChartContainerData = (
  forecastFilter: ForecastFilterOption
): {
  fetchData: () => void;
  data: {
    demandForecastData:
      | DemandForecastDayAhead[]
      | DemandForecastDaily[]
      | DemandForecastWeekly[];
    initialDemandOutturnData: InitialDemandOutturnModel[];
  } | null;
  status: RequestStatus;
} => {
  const getDemandForecastRequest = useCallback(
    (
      newForecastFilter: ForecastFilterOption
    ): Promise<
      | JsonDataDownloadModel<DemandForecastDayAhead>
      | JsonDataDownloadModel<DemandForecastDaily>
      | JsonDataDownloadModel<DemandForecastWeekly>
    > => {
      switch (newForecastFilter) {
        case ForecastFilterOption.dayAndDayAhead:
          return getDemandForecastDayAheadLatestNationalJsonData();
        case ForecastFilterOption.twoToFourteenDay:
          return getDemandForecastDailyJsonData();
        case ForecastFilterOption.twoToFiftyTwoWeek:
          return getDemandForecastWeeklyJsonData();
        default:
          throw new Error("Unexpected forecast filter option");
      }
    },
    []
  );

  const {
    data: demandForecastData,
    status: demandForecastStatus,
    request: fetchDemandForecastData,
  } = useRequest(getDemandForecastRequest);

  const getIntialDemandOutturnRequest = useCallback(
    (
      newForecastFilter: ForecastFilterOption
    ): Promise<JsonDataDownloadModel<InitialDemandOutturnModel>> => {
      if (newForecastFilter === ForecastFilterOption.dayAndDayAhead) {
        const startOfToday = startOfDay();
        const endOfOvermorrow = endOfDay(addDaysToDate(new Date(), 2));

        const dateFilter = new DateFilterModel(startOfToday, endOfOvermorrow);

        return getInitialDemandOutturnJsonData(dateFilter);
      }

      // Initial Demand Outturn is historical and therefore shouldn't be requested for non- Day and Day Ahead filters.
      return Promise.resolve({
        data: [],
      });
    },
    []
  );

  const {
    data: initialDemandOutturnData,
    status: initialDemandOutturnStatus,
    request: fetchInitialDemandOutturnData,
  } = useRequest(getIntialDemandOutturnRequest);

  const fetchData = useCallback(() => {
    fetchDemandForecastData(forecastFilter);
    fetchInitialDemandOutturnData(forecastFilter);
  }, [forecastFilter, fetchDemandForecastData, fetchInitialDemandOutturnData]);

  const data =
    demandForecastData && initialDemandOutturnData
      ? {
          demandForecastData: demandForecastData.data,
          initialDemandOutturnData: initialDemandOutturnData.data,
        }
      : null;

  const status = combineRequestStatuses(
    demandForecastStatus,
    initialDemandOutturnStatus
  );

  return {
    data,
    status,
    fetchData,
  };
};

export default useDemandForecastChartContainerData;
