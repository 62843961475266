import React from "react";

import Radio, { RadioProps } from "./Radio";
import { ToggleControlRow } from "./style";

type RadioButtonsRowProps = {
  radioButtons: RadioProps[];
  setSelected: (value: string) => void;
  selected: string;
};

const RadioButtonsRow: React.FC<RadioButtonsRowProps> = ({
  radioButtons,
  setSelected,
  selected,
}) => (
  <ToggleControlRow>
    {radioButtons.map((button: RadioProps) => (
      <Radio
        key={button.inputId}
        inputId={button.inputId}
        isChecked={selected === button.inputId}
        isInline
        onChange={(): void => setSelected(button.inputId)}
        inlineLabelContent={button.inlineLabelContent}
      />
    ))}
  </ToggleControlRow>
);

export default RadioButtonsRow;
