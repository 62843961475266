import TableWithErrorHandling from "components/components/TableWithErrorHandling/TableWithErrorHandling";
import DetailedSystemPricesTable from "components/dataVisualization/DetailedSystemPrices/DetailedSystemPricesTable/DetailedSystemPricesTable";
import { ConsolidatedData } from "components/dataVisualization/DetailedSystemPrices/SystemPricesHooks/useSystemPricesConsolidatedData";
import {
  createDetailedSystemPricesTableHeaders,
  mapSettlementDataToTableRow,
  StackTypeHeader,
} from "components/dataVisualization/DetailedSystemPrices/utils";
import { ActionType } from "pages/DetailedSystemPrices/utils";
import React, { useMemo } from "react";

interface Props {
  request: ConsolidatedData;
  actionType: ActionType;
}

const SellStackTab: React.FC<Props> = ({
  request: { data, fetch, status },
  actionType,
}) => {
  const mappedData = useMemo(
    () => (data.bid ? mapSettlementDataToTableRow(data.bid, actionType) : []),
    [actionType, data.bid]
  );
  const detailedSystemPricesSellTableHeaders =
    createDetailedSystemPricesTableHeaders(StackTypeHeader.BID);

  return (
    <TableWithErrorHandling
      data={mappedData}
      dataFetchStatus={status}
      rerequestData={fetch.offer}
      aria-label="sell-stack"
    >
      <DetailedSystemPricesTable
        data={mappedData}
        DetailedSystemPricesTableHeaders={detailedSystemPricesSellTableHeaders}
      />
    </TableWithErrorHandling>
  );
};

export default SellStackTab;
