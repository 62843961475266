import {
  getTotalLoadActualChartData,
  getTotalLoadDayAheadChartData,
} from "api/dataVisualisation/totalLoadActualAndDayAhead/totalLoadActualAndDayAhead";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { TotalLoadActualAndDayAheadChartModel } from "models/TotalLoad/totalLoadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { useCallback } from "react";
import { combineRequestStatuses } from "utils/requestHelpers";

const useTotalLoadActualAndDayAheadChartData = (
  dateFilter: DateFilterModel
): {
  fetchData: () => void;
  data: TotalLoadActualAndDayAheadChartModel | null;
  status: RequestStatus;
} => {
  const {
    data: atlData,
    status: atlStatus,
    request: fetchAtlChartData,
  } = useRequest(getTotalLoadActualChartData);

  const {
    data: datlData,
    status: datlStatus,
    request: fetchDatlChartData,
  } = useRequest(getTotalLoadDayAheadChartData);

  const fetchData = useCallback(() => {
    fetchAtlChartData(dateFilter);
    fetchDatlChartData(dateFilter);
  }, [dateFilter, fetchAtlChartData, fetchDatlChartData]);

  const data = {
    actual: atlData?.data,
    dayAhead: datlData?.data,
  };

  const status = combineRequestStatuses(atlStatus, datlStatus);

  return {
    data,
    status,
    fetchData,
  };
};

export default useTotalLoadActualAndDayAheadChartData;
