import styled from "styled-components";

export const Header = styled.td`
  font-size: 1.4em;
  font-weight: bold;
`;

export const NoEmphasisNoWrapCell = styled.td`
  white-space: nowrap;
`;

export const SwatchCell = styled.td`
  && {
    padding: 0;
  }
`;

export const ValueCell = styled.td`
  font-size: 1.2em;
  font-weight: bold;
`;

export const ValueCellNoWrap = styled(ValueCell)`
  white-space: nowrap;
`;

export const MajorValueCell = styled(ValueCell)`
  font-size: 1.6em;
`;

export const ValuesHeaderRow = styled.tr`
  text-align: center;
`;

export const SummaryTable = styled.table<{ tableLayout: "auto" | "fixed" }>`
  table-layout: ${({ tableLayout }): string => tableLayout};

  > thead > tr > td,
  > tbody > tr > td {
    border: 0;
    padding: 5px 10px;
    vertical-align: middle;
  }

  > tbody > tr > td {
    &${ValueCell} {
      text-align: center;
    }

    &.left-align {
      text-align: left;
    }

    &.right-align {
      text-align: right;
    }
  }
`;
