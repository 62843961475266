import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { StandardChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/StandardChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScaleWithDumbbellPadding,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayersWithWindGenerationDumbbells } from "styles/chartStyles/chartLayers";
import { formatAsTimeWithDatesAndTodayMarked } from "utils/chartHelpers";

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  dataFetchStatus: RequestStatus;
  rerequestData: () => void;
  dateRange: DateFilterModel;
}

const WindGenerationChart: React.FC<Props> = ({
  series,
  datasetCategories,
  dataFetchStatus,
  rerequestData,
  dateRange,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        axisBottom={chartAxisBottom(
          "Start Time (UTC)",
          series,
          isChartLoaded,
          isChartLoaded ? "every 8 hours" : [],
          formatAsTimeWithDatesAndTodayMarked
        )}
        axisLeft={chartAxisLeft("MW", isChartLoaded)}
        layers={chartLayersWithWindGenerationDumbbells}
        enableSlices="x"
        sliceTooltip={(input): JSX.Element => (
          <StandardChartTooltip
            series={series}
            datasetCategories={datasetCategories}
            text={{
              label: "Start Time",
            }}
            options={{
              interactionType: TooltipInteractionType.Slice,
            }}
            inputPoints={input.slice.points}
          />
        )}
        xScale={chartXTimeScaleWithDumbbellPadding(dateRange)}
        yScale={nonStackedLineChartYScale(series)}
      />
    );
  }, [dataFetchStatus, dateRange, series, datasetCategories]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      >
        <>{memoisedChart}</>
      </ChartWithErrorHandling>
    ),
    [dataFetchStatus, datasetCategories, memoisedChart, rerequestData, series]
  );
};

export default WindGenerationChart;
