import PageMeta from "components/components/Meta/PageMeta";
import ActualOrEstimatedWindAndSolarPowerGenerationChartContainer from "components/dataVisualization/ActualOrEstimatedWindAndSolarPowerGeneration/ActualOrEstimatedWindAndSolarPowerGenerationChartContainer";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";

const ActualOrEstimatedWindAndSolarPowerGeneration: React.FC = () => (
  <>
    <PageMeta title="Actual or estimated wind and solar power generation" />
    <PageHeader
      title="Actual or estimated wind and solar power generation"
      pageDescription={
        <p>
          Actual or estimated net wind and solar power generation (MW) in each
          bidding zone per Settlement Period.
        </p>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <p>
          The information shall be published no later than one hour after the
          end of each Settlement Period and be updated on the basis of measured
          values as soon as they become available.
        </p>
      }
    />
    <FullWidthChartSection>
      <ActualOrEstimatedWindAndSolarPowerGenerationChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection
      documentationInformation={{
        chart: ["generation/actual/per-type/wind-and-solar"],
      }}
    />
  </>
);

export default ActualOrEstimatedWindAndSolarPowerGeneration;
