import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import React from "react";
import routeConstants from "utils/routeConstants";

import { IconWrapper, StyledButtonAnchor, StyledButtonLink } from "./style";

interface Props {
  to: string; // This can be an insights page pathname or an external URL
  dataTestId?: string;
  className?: string;
  children: React.ReactNode;
}

const ELEXON_DOMAIN_REGEX =
  /^(https?:\/\/)?bmrs\.(test\.|dev\.)?elexon\.co\.uk/;

const ButtonLink: React.FC<Props> = ({
  className,
  to,
  children,
  dataTestId,
}: Props) => {
  const isInternalDestination =
    Object.values(routeConstants).includes(to) || to.match(ELEXON_DOMAIN_REGEX);

  if (isInternalDestination) {
    return (
      <StyledButtonLink className={className} to={to} data-test-id={dataTestId}>
        {children}
      </StyledButtonLink>
    );
  }

  return (
    <StyledButtonAnchor
      className={className}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      data-test-id={dataTestId}
    >
      {children}
      <IconWrapper>
        <Icon iconName={faExternalLinkAlt} size={IconSize.xSmall} />
      </IconWrapper>
    </StyledButtonAnchor>
  );
};

export default ButtonLink;
