import { getIndicativeDemandPeakJsonDataByTriadYear } from "api/dataVisualisation/indicativeDemandPeak/indicativeDemandPeak";
import { getIndicativeDemandPeakTriadJsonDataByTriadYear } from "api/dataVisualisation/indicativeDemandPeak/indicativeDemandPeakTriad";
import { MeteringDataType } from "components/dataVisualization/indicativeDemandPeak/IndicativeDemandPeakContainer/IndicativeDemandPeakChartContainer";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { IndicativeDemandPeakModel } from "models/indicativeDemandPeak/indicativeDemandPeak";
import { useCallback } from "react";

export type IndicativeDemandPeakChartData = {
  timeSeries: IndicativeDemandPeakModel[];
  triadPeaks: IndicativeDemandPeakModel[];
};

const getIndicativeDemandPeakData = async (
  showOperationalData: boolean,
  year: number
): Promise<IndicativeDemandPeakChartData> => {
  const timeSeriesDataRequest = getIndicativeDemandPeakJsonDataByTriadYear(
    showOperationalData
      ? MeteringDataType.Operational
      : MeteringDataType.Settlement,
    year
  );
  const triadPeakDataRequest = getIndicativeDemandPeakTriadJsonDataByTriadYear(
    showOperationalData
      ? MeteringDataType.Operational
      : MeteringDataType.Settlement,
    year
  );

  const [timeSeriesRawData, triadPeakRawData] = await Promise.all([
    timeSeriesDataRequest,
    triadPeakDataRequest,
  ]);

  return {
    timeSeries: timeSeriesRawData?.data,
    triadPeaks: triadPeakRawData?.data,
  };
};

const useIndicativeDemandPeakChartContainerData = (
  showOperationalData: boolean,
  year: number
): {
  fetchData: () => void;
  data: IndicativeDemandPeakChartData | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchChartData,
  } = useRequest(getIndicativeDemandPeakData);

  const fetchData = useCallback(() => {
    fetchChartData(showOperationalData, year);
  }, [fetchChartData, showOperationalData, year]);

  return {
    data,
    status,
    fetchData,
  };
};

export default useIndicativeDemandPeakChartContainerData;
