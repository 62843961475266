import DateSelector, {
  ReadOnlyDates,
} from "components/components/DateSelector/DateSelector";
import ChartFilterButton from "components/dataVisualization/chartComponents/ChartFilterButton/ChartFilterButton";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React from "react";

interface Props {
  dateFilter: DateFilterModel;
  handleChangeToDateSelection: (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ) => void;
  dateSelectorTabs?: DateSelectorTabs[];
  activeDateSelectorTab: DateSelectorTabs | null;
  toggleChartFilterDrawer?: () => void;
  showChartFilterDrawer?: boolean;
  maxRange?: number;
  readOnlyDates?: ReadOnlyDates;
  showTimeInput?: boolean;
  defaultEndDate?: Date;
  twentyForHourEndDate?: Date;
}

const defaultDateSelectorTabs = [
  DateSelectorTabs.TwentyFourHours,
  DateSelectorTabs.OneWeek,
  DateSelectorTabs.OneMonth,
  DateSelectorTabs.OneYear,
  DateSelectorTabs.All,
];

const ChartFilter: React.FC<Props> = ({
  dateFilter,
  handleChangeToDateSelection,
  dateSelectorTabs = defaultDateSelectorTabs,
  activeDateSelectorTab,
  toggleChartFilterDrawer,
  showChartFilterDrawer,
  maxRange,
  readOnlyDates,
  showTimeInput,
  defaultEndDate,
  twentyForHourEndDate,
}: Props) => (
  <>
    <ChartFilterButton
      toggleChartFilterDrawer={toggleChartFilterDrawer}
      showChartFilterDrawer={showChartFilterDrawer}
    />
    <div>
      <DateSelector
        dateFilter={dateFilter}
        handleChangeToDateSelection={handleChangeToDateSelection}
        dateTabs={dateSelectorTabs}
        activeTab={activeDateSelectorTab}
        maxRange={maxRange}
        readOnlyDates={readOnlyDates}
        showTimeInput={showTimeInput}
        defaultEndDate={defaultEndDate}
        twentyForHourEndDate={twentyForHourEndDate}
      />
    </div>
  </>
);

export default React.memo(ChartFilter);
