import React from "react";
import { Helmet } from "react-helmet-async";
import featureFlags from "utils/featureFlags";

const cookieBannerScript = (
  <script
    type="text/javascript"
    src="//cdn.cookie-script.com/s/7f07d68cc3a1cdc2eebcab29b7d59a08.js"
  />
);

const GlobalMeta: React.FC = () => {
  const doNotIndex = process.env.REACT_APP_ENV_PREFIX !== "prod";
  return (
    <Helmet>
      {featureFlags.cookieBanner && <>{cookieBannerScript}</>}
      {doNotIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

export default GlobalMeta;
