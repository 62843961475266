import classnames from "classnames";
import Button from "components/components/Button/Button";
import { DateInputLabel } from "components/dataVisualization/dataPageComponents/ChartFilter/DateInput/DateInput";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import React from "react";
import {
  addDaysToDate,
  addHoursToDate,
  addMinsToDate,
  addMonthsToDate,
  addYearsToDate,
  ceilToHalfHour,
  EARLIEST_DATE,
} from "utils/dateHelpers";
import { parseTimeOnGivenDate } from "utils/datePickerHelpers";

import DatePickerWrapper from "./DatePickerWrapper";
import { ButtonFilterContainer, FilterContainer, ToText } from "./style";

const getAriaLabelForDateSelectorTab = (tab: DateSelectorTabs): string => {
  switch (tab) {
    case DateSelectorTabs.OneHour:
      return "1 hour";
    case DateSelectorTabs.TwentyFourHours:
      return "24 hours";
    case DateSelectorTabs.ThreeDays:
      return "3 days";
    case DateSelectorTabs.OneWeek:
      return "1 week";
    case DateSelectorTabs.OneMonth:
      return "1 month";
    case DateSelectorTabs.OneYear:
      return "1 year";
    case DateSelectorTabs.All:
      return "All";
    case DateSelectorTabs.Date:
      return "Date";
    default:
      return "";
  }
};

interface Props {
  dateFilter: DateFilterModel;
  handleChangeToDateSelection: (
    newDateFilter: DateFilterModel,
    newActiveTab: DateSelectorTabs
  ) => void;
  dateTabs: DateSelectorTabs[];
  activeTab: DateSelectorTabs | null;
  /** Styles the custom date pickers on a new line under the date tabs. */
  customDateNewLine?: boolean;
  maxRange?: number;
  readOnlyDates?: ReadOnlyDates;
  showTimeInput?: boolean;
  defaultEndDate?: Date;
  twentyForHourEndDate?: Date;
  maximumDate?: Date;
}

interface FilterButtonProps {
  tab: DateSelectorTabs;
  startDate: Date;
  endDate?: Date;
}

// flags, used for bitwise operation
export enum ReadOnlyDates {
  None = 0,
  Start = 1,
  End = 2,
  Both = 3, // Combination of Start | End
}

const DateSelector: React.FC<Props> = ({
  dateFilter,
  handleChangeToDateSelection,
  dateTabs,
  activeTab,
  customDateNewLine,
  maxRange,
  readOnlyDates = ReadOnlyDates.None,
  showTimeInput,
  defaultEndDate = ceilToHalfHour(new Date()),
  twentyForHourEndDate = defaultEndDate,
  maximumDate,
}: Props) => {
  const datePickerStartDate =
    dateFilter.startDate >= EARLIEST_DATE
      ? ceilToHalfHour(dateFilter.startDate)
      : EARLIEST_DATE;
  const datePickerEndDate = ceilToHalfHour(dateFilter.endDate);

  /* eslint-disable no-bitwise */
  const readOnlyStartDate = Boolean(readOnlyDates & ReadOnlyDates.Start);
  const readOnlyEndDate = Boolean(readOnlyDates & ReadOnlyDates.End);
  const readOnlyStartAndEnd = readOnlyStartDate && readOnlyEndDate;
  /* eslint-enable no-bitwise */

  const handleChange = (
    startDate: Date,
    endDate: Date,
    newActiveTab: DateSelectorTabs
  ): void => {
    let newEndDate = endDate;

    // End date can equal start date here if there is a time difference.
    // In this case, add 30 minutes to end date as the user is likely intending to have a short time range.
    if (startDate.valueOf() === endDate.valueOf()) {
      newEndDate = addMinsToDate(endDate, 30);
    }
    // End date can be earlier than start date here if user is in the process of selecting a date range later than previously selected range.
    // In this case, set end date to the latest known valid value, allowing them to refine from there.
    else if (startDate > endDate) {
      if (maximumDate) {
        newEndDate =
          maximumDate.valueOf() === startDate.valueOf()
            ? startDate
            : addDaysToDate(startDate, 1);
      } else if (maxRange) {
        newEndDate = addDaysToDate(startDate, maxRange);
      } else {
        newEndDate = defaultEndDate;
      }
    }

    handleChangeToDateSelection(
      new DateFilterModel(startDate, newEndDate),
      newActiveTab
    );
  };

  const isValidStartTime = (hourString: string, minString: string): boolean => {
    const timeToCheck = parseTimeOnGivenDate(
      datePickerStartDate,
      hourString,
      minString
    );
    const latestPermittedStartDate = maximumDate ?? defaultEndDate;
    return timeToCheck < latestPermittedStartDate;
  };

  const isValidEndTime = (hourString: string, minString: string): boolean => {
    const timeToCheck = parseTimeOnGivenDate(
      datePickerEndDate,
      hourString,
      minString
    );
    const latestPermittedEndDate = maxRange
      ? addDaysToDate(datePickerStartDate, maxRange)
      : maximumDate ?? defaultEndDate;

    return (
      timeToCheck > datePickerStartDate && timeToCheck <= latestPermittedEndDate
    );
  };

  const FilterButton: React.FC<FilterButtonProps> = ({
    tab,
    startDate,
    endDate = defaultEndDate,
  }: FilterButtonProps) => (
    <Button
      ariaLabel={getAriaLabelForDateSelectorTab(tab)}
      className={classnames("filter", { active: activeTab === tab })}
      onClick={(): void => handleChange(startDate, endDate, tab)}
      buttonText={tab}
    />
  );

  const getMaxEndDateWhenMaxRangeSet = (range: number): Date => {
    return maximumDate &&
      addDaysToDate(datePickerStartDate, range) > maximumDate
      ? maximumDate
      : addDaysToDate(datePickerStartDate, range);
  };

  const getMaximumEndDate = (): Date =>
    maxRange
      ? getMaxEndDateWhenMaxRangeSet(maxRange)
      : maximumDate ?? defaultEndDate;

  return (
    <FilterContainer
      className={classnames({ "multi-line": customDateNewLine })}
    >
      {dateTabs.length !== 0 && (
        <ButtonFilterContainer data-test-id="predefined-filters">
          {dateTabs.includes(DateSelectorTabs.OneHour) && (
            <FilterButton
              tab={DateSelectorTabs.OneHour}
              startDate={addHoursToDate(defaultEndDate, -1)}
            />
          )}

          {dateTabs.includes(DateSelectorTabs.TwentyFourHours) && (
            <FilterButton
              tab={DateSelectorTabs.TwentyFourHours}
              startDate={addDaysToDate(twentyForHourEndDate, -1)}
              endDate={twentyForHourEndDate}
            />
          )}

          {dateTabs.includes(DateSelectorTabs.ThreeDays) && (
            <FilterButton
              tab={DateSelectorTabs.ThreeDays}
              startDate={addDaysToDate(defaultEndDate, -3)}
            />
          )}

          {dateTabs.includes(DateSelectorTabs.OneWeek) && (
            <FilterButton
              tab={DateSelectorTabs.OneWeek}
              startDate={addDaysToDate(defaultEndDate, -7)}
            />
          )}

          {dateTabs.includes(DateSelectorTabs.OneMonth) && (
            <FilterButton
              tab={DateSelectorTabs.OneMonth}
              startDate={addMonthsToDate(defaultEndDate, -1)}
            />
          )}

          {dateTabs.includes(DateSelectorTabs.ThreeMonths) && (
            <FilterButton
              tab={DateSelectorTabs.ThreeMonths}
              startDate={addMonthsToDate(defaultEndDate, -3)}
            />
          )}

          {dateTabs.includes(DateSelectorTabs.OneYear) && (
            <FilterButton
              tab={DateSelectorTabs.OneYear}
              startDate={addYearsToDate(defaultEndDate, -1)}
            />
          )}

          {dateTabs.includes(DateSelectorTabs.All) && (
            <FilterButton
              tab={DateSelectorTabs.All}
              startDate={EARLIEST_DATE}
            />
          )}
        </ButtonFilterContainer>
      )}

      <div>
        <DatePickerWrapper
          selectedDate={datePickerStartDate}
          onChange={(date: Date): void =>
            handleChange(date, datePickerEndDate, DateSelectorTabs.Date)
          }
          minDate={EARLIEST_DATE}
          maxDate={maximumDate ?? addMinsToDate(defaultEndDate, -30)}
          isValidTime={isValidStartTime}
          isActiveTab={activeTab === DateSelectorTabs.Date}
          readOnly={readOnlyStartDate}
          showTimeInput={showTimeInput}
          dateInputLabel={DateInputLabel.StartDate}
        />
        <ToText isGrey={readOnlyStartAndEnd}>&nbsp;to&nbsp;</ToText>
        <DatePickerWrapper
          selectedDate={datePickerEndDate}
          onChange={(date: Date): void =>
            handleChange(datePickerStartDate, date, DateSelectorTabs.Date)
          }
          minDate={datePickerStartDate}
          maxDate={getMaximumEndDate()}
          isValidTime={isValidEndTime}
          isActiveTab={activeTab === DateSelectorTabs.Date}
          readOnly={readOnlyEndDate}
          showTimeInput={showTimeInput}
          dateInputLabel={DateInputLabel.EndDate}
        />
      </div>
    </FilterContainer>
  );
};

export default DateSelector;
