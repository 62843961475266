import Button from "components/components/Button/Button";
import Expander, {
  ExpanderIconType,
} from "components/components/Expander/Expander";
import { getDisplayNameFromJsonKey } from "components/remit/remitMessagePageHelpers";
import { RevisionHistoryModel } from "models/remit/revisionHistoryModel";
import { SelectedMessageRevision } from "pages/RemitMessageDetails/RemitMessageDetails";
import {
  OverflowContainer,
  SidebarContainer,
  SidebarContent,
  SidebarHeader,
} from "pages/RemitMessageDetails/style";
import React from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

interface Props {
  revisionHistory: RevisionHistoryModel[];
  selectedMessageRevision: SelectedMessageRevision;
  setUrlParams: (params: { messageId: string }) => void;
  latestRevisionId: number;
}
const RemitSidebar: React.FC<Props> = ({
  revisionHistory,
  selectedMessageRevision,
  setUrlParams,
  latestRevisionId,
}) => {
  return (
    <SidebarContainer
      className={
        selectedMessageRevision.messageId === latestRevisionId
          ? ""
          : "warning-box-active"
      }
    >
      <OverflowContainer>
        <SidebarHeader>{`Revision history (${revisionHistory?.length})`}</SidebarHeader>
        {revisionHistory &&
          revisionHistory.map((revision, i) => (
            <Expander
              key={revision.id}
              title={`Revision ${revision.revisionNumber}`}
              subtitle={formatDateTimeString(
                new Date(revision.publishTime),
                DateFormat.DateTimeWithSeconds
              )}
              content={
                <SidebarContent>
                  {i < revisionHistory.length - 1 &&
                    revisionHistory[i].changesFromPreviousRevision &&
                    revisionHistory[i].changesFromPreviousRevision.length >
                      0 && (
                      <>
                        <p>Changes from previous revision:</p>
                        <ul>
                          {revisionHistory[i].changesFromPreviousRevision.map(
                            (difference) => (
                              <li key={difference}>
                                {getDisplayNameFromJsonKey(difference)}
                              </li>
                            )
                          )}
                        </ul>
                      </>
                    )}
                  {selectedMessageRevision.messageId === revision.id ? (
                    <Button buttonText="Viewing" disabled />
                  ) : (
                    <Button
                      buttonText="View Revision"
                      onClick={(): void =>
                        setUrlParams({
                          messageId: revision.id.toString(),
                        })
                      }
                    />
                  )}
                </SidebarContent>
              }
              classModifier={
                revision.id === selectedMessageRevision.messageId
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
              expanderIconType={ExpanderIconType.Arrow}
            />
          ))}
      </OverflowContainer>
    </SidebarContainer>
  );
};

export default RemitSidebar;
