import { getNetBalancingServicesAdjustmentEventsJsonData } from "api/dataVisualisation/netBalancingServicesAdjustment/netBalancingServicesAdjustment";
import Button from "components/components/Button/Button";
import SummaryTile from "components/dataVisualization/dataPageComponents/SummaryTile/SummaryTile";
import {
  Header,
  SummaryTable,
  ValueCell,
} from "components/dataVisualization/dataPageComponents/SummaryTile/commonStyle";
import { useChartFocussedDateContext } from "contexts/ChartFocussedDateContext";
import { NetBalancingServicesAdjustmentModel } from "models/netBalancingServicesAdjustment/netBalancingServicesAdjustmentModel";
import React, { useEffect, useState } from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

const NUMBER_OF_EVENTS = 3;

const AdjustmentDataNetbsadSummaryTile: React.FC = () => {
  const [summaryTileData, setSummaryTileData] =
    useState<NetBalancingServicesAdjustmentModel[]>();

  useEffect(() => {
    getNetBalancingServicesAdjustmentEventsJsonData(NUMBER_OF_EVENTS)
      .then((response) => {
        if (response && response.data.length === 0) {
          return;
        }

        setSummaryTileData(response.data);
      })
      // TODO 36876: Add error handling for failed calls to the API and empty data returned
      // eslint-disable-next-line no-console
      .catch(console.log);
  }, []);

  const { setFocussedDate: setFocus } = useChartFocussedDateContext();

  return (
    <SummaryTile>
      <SummaryTable tableLayout="fixed">
        <thead>
          <tr>
            <Header colSpan={4}>Latest non-zero instances (UTC)</Header>
          </tr>
        </thead>
        <tbody>
          {summaryTileData ? (
            summaryTileData.map((event, index) => (
              <tr key={event.startTime.toString()}>
                <ValueCell
                  colSpan={2}
                  className="left-align"
                  data-test-id={`non-zero-event-date-${index}`}
                >
                  {formatDateTimeString(
                    new Date(event.startTime),
                    DateFormat.DateOnly
                  )}
                </ValueCell>
                <td colSpan={1} data-test-id={`non-zero-event-time-${index}`}>
                  {formatDateTimeString(
                    new Date(event.startTime),
                    DateFormat.TimeOnly
                  )}
                </td>
                <td
                  colSpan={1}
                  className="right-align"
                  data-test-id={`non-zero-event-view-${index}`}
                >
                  <Button
                    onClick={(): void => setFocus(new Date(event.startTime))}
                    buttonText="View"
                    className="link-inline"
                  />
                </td>
              </tr>
            ))
          ) : (
            <>
              <tr>
                <td data-test-id="non-zero-event-placeholder-0">—</td>
              </tr>
              <tr>
                <td data-test-id="non-zero-event-placeholder-1">—</td>
              </tr>
              <tr>
                <td data-test-id="non-zero-event-placeholder-2">—</td>
              </tr>
            </>
          )}
        </tbody>
      </SummaryTable>
    </SummaryTile>
  );
};

export default AdjustmentDataNetbsadSummaryTile;
