import { getNonBmStorTimeSeriesJsonData } from "api/dataVisualisation/nonBmStor/nonBmStor";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { NonBmStorModel } from "models/nonBmStor/nonBmStorModel";
import { useCallback } from "react";

const useShortTermOperatingReservesChartContainerData = (
  dateFilter: DateFilterModel
): {
  data: NonBmStorModel[] | null;
  status: RequestStatus;
  fetchData: () => void;
} => {
  const { data, status, request } = useRequest(getNonBmStorTimeSeriesJsonData);

  const fetchData = useCallback(() => {
    request(dateFilter);
  }, [dateFilter, request]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useShortTermOperatingReservesChartContainerData;
