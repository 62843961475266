import PageMeta from "components/components/Meta/PageMeta";
import AdditionalInfoSection from "components/dataVisualization/dataPageComponents/AdditionalInfoSection/AdditionalInfoSection";
import FullWidthChartSection from "components/dataVisualization/dataPageComponents/FullWidthChartSection/FullWidthChartSection";
import PageHeader from "components/dataVisualization/dataPageComponents/PageHeader/PageHeader";
import TotalLoadWeekAheadChartContainer from "components/dataVisualization/totalLoadWeekAhead/TotalLoadWeekAheadChartContainer/TotalLoadWeekAheadChartContainer";
import React from "react";

const TotalLoadWeekAhead: React.FC = () => (
  <>
    <PageMeta title="Total load: week-ahead forecast" />
    <PageHeader
      title="Total load: week-ahead forecast"
      pageDescription={
        <>
          <p>
            Transmission System Operators shall calculate and submit a
            week-ahead forecast of the total load for every day of the following
            week, which shall for each day include a maximum and a minimum load
            value for each bidding zone.
          </p>
          <p>
            Month and Year ahead submitted forecasts are available via the API
            documentation.
          </p>
        </>
      }
      expanderHeading="What you need to know while using this data"
      expanderContent={
        <>
          <p>
            The information shall be published each Friday no later than two
            hours before the gate closure of the day-ahead market in the bidding
            zone and be updated when significant changes occur.
          </p>
          <p>
            A week starts on Monday 00:00 hrs and ends on Sunday at 24:00 hrs
            (in accordance with ISO 8601). Week-ahead estimated loads shall be
            updated, if there are major changes. A major change represents a
            change of at least 10% of the total load forecast per Settlement
            Period. The primary owners of the data are Transmission System
            Operators and Distribution System Operators.
          </p>
          <p>
            Nevertheless, the forecast of load of the following week is given:
          </p>
          <ul>
            <li>For information purposes only.</li>
            <li>
              It is drawn up, among other things, on the basis of weather
              forecast ahead of time.
            </li>
          </ul>
          <p>
            It is therefore likely to change considerably in shape and level.
          </p>
          <p>
            Note: The week-ahead forecast is calculated (estimated) on the
            historic load profile on similar days, taking into account the
            variables that affect electricity demand, such as weather
            conditions, climate and socioeconomic factors.
          </p>
          <p>
            Note 2: Higher resolution is allowed. Highest resolution is MTU. In
            that case, the maximum and minimum load values for each day will be
            published.
          </p>
        </>
      }
    />
    <FullWidthChartSection>
      <TotalLoadWeekAheadChartContainer />
    </FullWidthChartSection>
    <AdditionalInfoSection
      documentationInformation={{
        chart: ["forecast/demand/total/week-ahead/latest"],
      }}
    />
  </>
);

export default TotalLoadWeekAhead;
