import { getAcceptanceVolumeJsonData } from "api/dataVisualisation/derivedData/acceptanceVolume";
import useRequest, { RequestStatus } from "hooks/useRequest";
import { BmuModel } from "models/bmuData/bmuModel";
import { AcceptanceVolumeModel } from "models/derivedData/acceptanceVolumeModel";
import { useCallback } from "react";

export type BidOffer = "bid" | "offer";

const useAcceptanceVolumeData = (
  settlementTime: Date,
  bidOffer: BidOffer,
  bmus?: BmuModel[]
): {
  fetchData: () => void;
  data: AcceptanceVolumeModel[] | null;
  status: RequestStatus;
} => {
  const {
    data,
    status,
    request: fetchAcceptanceVolumeData,
  } = useRequest(getAcceptanceVolumeJsonData);
  const fetchData = useCallback(() => {
    fetchAcceptanceVolumeData(settlementTime, bidOffer, bmus);
  }, [bidOffer, bmus, fetchAcceptanceVolumeData, settlementTime]);

  return {
    data: data?.data ?? null,
    status,
    fetchData,
  };
};

export default useAcceptanceVolumeData;
