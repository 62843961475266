import styled, { css, FlattenSimpleInterpolation } from "styled-components";

export const StyledDiv = styled.div<{
  specifyMinHeight?: boolean;
}>`
  position: relative;
  ${({ specifyMinHeight }): false | FlattenSimpleInterpolation =>
    !!specifyMinHeight &&
    css`
      min-height: 20rem;
    `}
`;
