import { HttpStatusCode } from "axios";
import ErrorMessage from "components/components/ErrorMessage/ErrorMessage";
import PageMeta from "components/components/Meta/PageMeta";
import React from "react";

const NotFoundPage: React.FC = () => (
  <>
    <PageMeta title="Not Found" />
    <ErrorMessage
      errorText="Sorry, we can&#8217;t find the page you&#8217;re looking for."
      errorCode={HttpStatusCode.NotFound}
    />
  </>
);
export default NotFoundPage;
