import Fuse from "fuse.js";

export interface FilterOption {
  value: string;
  label: string;
}

const keys: (keyof FilterOption)[] = ["value"];

// TODO 56455: refactor fuzzy search with ApiSearchBar and BmuFuzzySearchBar
export const fuzzyFilter = (
  allOptions: FilterOption[],
  filterString: string
): FilterOption[] => {
  if (!filterString) {
    return allOptions;
  }

  const config: Fuse.IFuseOptions<FilterOption> = {
    includeMatches: true, // This will allow for future highlighting of matches within the data (e.g. in bold in the Figma designs)
    threshold: 0.4, // Controls how lenient the fuzzy search is
    keys,
  };

  const fuse = new Fuse(allOptions, config);
  const results = fuse.search(filterString);
  return results.map((r) => r.item);
};

export const convertToOption = (s: string): FilterOption => ({
  value: s,
  label: s,
});

export const mapToOptions = (values: string[]): FilterOption[] =>
  values.map(convertToOption);
