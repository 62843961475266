import { getAllBidOfferJsonData } from "api/dataVisualisation/bidOffer/bidOffer";
import {
  MarketViewBmuFilters,
  filterBmuIds,
  hasAnyFilters,
} from "components/components/balancingMechanism/marketView/MarketViewFilters/helpers";
import useRequest, { RequestStatus } from "hooks/useRequest";
import BidOfferData from "models/bidOffer/bidOfferData";
import { BmuModel } from "models/bmuData/bmuModel";
import { useCallback } from "react";

const useMarketViewBidOfferData = (
  selectedDate: Date,
  bmuFilters: MarketViewBmuFilters,
  bmuData: BmuModel[] | null
): {
  data: BidOfferData[] | null;
  status: RequestStatus;
  request: () => void;
} => {
  const {
    data,
    status,
    request: fetchBidOfferData,
  } = useRequest(getAllBidOfferJsonData);

  const fetchData = useCallback(() => {
    if (!selectedDate) {
      return;
    }

    if (bmuData && hasAnyFilters(bmuFilters)) {
      const bmUnits = filterBmuIds(bmuData, bmuFilters);
      fetchBidOfferData(selectedDate, bmUnits);
    } else {
      fetchBidOfferData(selectedDate);
    }
  }, [bmuData, bmuFilters, selectedDate, fetchBidOfferData]);

  return {
    data: data?.data ?? null,
    status,
    request: fetchData,
  };
};

export default useMarketViewBidOfferData;
