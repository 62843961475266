import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { TemperatureModel } from "models/temperature/temperatureModel";
import { toDateOnlyString } from "utils/dateHelpers";

export const getTemperatureData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<TemperatureModel>> => {
  const { data } = await getData(
    "/temperature",
    {
      from: toDateOnlyString(dateFilter.normalisedStartDate),
      to: toDateOnlyString(dateFilter.normalisedEndDate),
    },
    format
  );
  return data;
};

export const getTemperatureJsonData = (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<TemperatureModel>> =>
  getTemperatureData(dateFilter, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<TemperatureModel>
  >;
