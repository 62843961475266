import styled from "styled-components";
import colours from "styles/colours";

export const StyledDiv = styled.div`
  > div {
    padding-bottom: 1.5rem;
  }
`;

export const StyledForm = styled.form`
  display: block;
`;

export const FlexRow = styled.div`
  display: flex;
  gap: 36px;
  width: 100%;
  align-items: center;
`;

export const TimeRangeDropdownsDiv = styled.div`
  gap: 0.55rem;
  display: flex;

  > div > * {
    width: 7.8rem;
  }
`;

export const AdditionalDropdownsDiv = styled.div`
  gap: 21px;
  display: flex;
  width: 100%;

  > div > * {
    max-width: 15.6rem;
  }
`;

export const AdditionalInputsDiv = styled.div`
  gap: 1.3rem;
  display: flex;
  width: 100%;

  > div:nth-child(1) {
    width: 32.6rem;
  }

  > div:nth-child(2) {
    width: 19.4rem;
  }
`;

export const TimeframeDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdditionalFiltersDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.9rem;
`;

export const Divider = styled.div`
  max-width: 66.4rem;
  height: 1px;
  background: ${colours.mediumGrey};
`;

export const LabelAndFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 15.6rem;
`;
