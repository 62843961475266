import { getBidOfferDatasetFromPairId } from "components/dataVisualization/balancingMechanismTabs/BidOffer/bmuView/useBidOfferData";
import {
  BidOfferDataset,
  bidOfferDatasetDisplayName,
} from "models/bidOffer/bidOfferChartData";
import BidOfferData from "models/bidOffer/bidOfferData";
import MarketViewBidOfferTableModel from "models/bidOffer/marketViewBidOfferTableModel";
import { BmuModel } from "models/bmuData/bmuModel";

export function mapToBidOfferRow(
  bidOfferData: BidOfferData[],
  bmuData: BmuModel[] | null
): MarketViewBidOfferTableModel[] {
  return bidOfferData.map((row, key) => {
    const matchingBmu = bmuData?.find((bmu) => bmu.elexonBmUnit === row.bmUnit);
    return {
      id: key,
      bmUnit: row.bmUnit,
      leadParty: matchingBmu?.leadPartyName ?? "—",
      bmuType: matchingBmu?.bmUnitType ?? "—",
      nationalGridBmUnit: row.nationalGridBmUnit,
      pairName:
        bidOfferDatasetDisplayName[
          getBidOfferDatasetFromPairId(row.pairId) as BidOfferDataset
        ],
      timeFrom: new Date(row.timeFrom),
      levelFrom: row.levelFrom,
      timeTo: new Date(row.timeTo),
      levelTo: row.levelTo,
      bid: row.bid,
      offer: row.offer,
    };
  });
}
