import Button from "components/components/Button/Button";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  background-color: transparent;
  padding: 0 0.7em 0 0.7em;
  display: inline-block;

  :active {
    box-shadow: none;
  }

  :focus {
    box-shadow: none;
  }
`;
