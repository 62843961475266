import CreditDefaultNoticeTableModel from "components/dataVisualization/creditDefaultNotice/CreditDefaultNoticeTableModel";
import { CreditDefaultNoticeModel } from "models/creditDefaultNotice/creditDefaultNoticeModel";

export function mapToCreditDefaultNoticeRow(
  creditDefaultNoticeData: CreditDefaultNoticeModel[]
): CreditDefaultNoticeTableModel[] {
  return creditDefaultNoticeData.map((row, key) => ({
    id: key,
    participantName: row.participantName,
    participantId: row.participantId,
    level: row.creditDefaultLevel,
    enteredDate: new Date(row.enteredDefaultSettlementDate),
    enteredPeriod: row.enteredDefaultSettlementPeriod,
    clearedDate: row.clearedDefaultSettlementDate
      ? new Date(row.clearedDefaultSettlementDate)
      : null,
    clearedPeriod: row.clearedDefaultSettlementPeriod,
    text: row.clearedDefaultText,
  }));
}
