import { ResponsiveLine, Serie } from "@nivo/line";
import ChartWithErrorHandling from "components/dataVisualization/chartComponents/ChartContainer/ChartWithErrorHandling";
import { TooltipInteractionType } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipPositionWrapper";
import { TabulatedChartTooltip } from "components/dataVisualization/chartComponents/ChartTooltip/TabulatedChartTooltip";
import { RequestStatus } from "hooks/useRequest";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import {
  DatasetCategory,
  ChartDatasetModel,
  combineDatasetCategories,
} from "models/chartConfiguration/chartDatasetModel";
import { DemandIndicatedForecastDataKeys } from "models/demandForecast/demandForecastDayAhead";
import { IndicatedForecastDataKeys } from "models/indicatedForecast/indicatedForecastDayAhead";
import React, { useMemo } from "react";
import {
  chartAxisBottom,
  chartAxisLeft,
  chartXTimeScale,
  commonChartProps,
  nonStackedLineChartYScale,
} from "styles/chartStyles";
import { chartLayers } from "styles/chartStyles/chartLayers";
import { dateFormatter, defaultColumnFormatter } from "utils/ChartDataUtils";
import { DateFormat } from "utils/dateHelpers";

interface Props {
  series: Serie[];
  datasetCategories: DatasetCategory<ChartDatasetModel>[];
  sendHighlightedDataset: (newSelectedDataset: string) => void;
  dataFetchStatus: RequestStatus;
  rerequestData: () => void;
}

const IndicatedForecastsChart: React.FC<Props> = ({
  series,
  datasetCategories,
  sendHighlightedDataset,
  dataFetchStatus,
  rerequestData,
}: Props) => {
  const memoisedChart = useMemo(() => {
    const isChartLoaded =
      dataFetchStatus === RequestStatus.SUCCESSFUL_OR_NOT_STARTED;

    const config: ChartConfiguration = {
      datasetCategories,
      tooltipColumns: [
        {
          id: "value",
          dataKey: "y",
          formatter: defaultColumnFormatter({ suffix: "MW" }),
        },
        {
          id: "publishTime",
          title: "Publish Time",
          dataKey: "publishTime",
          formatter: dateFormatter(DateFormat.DateTime),
        },
      ],
    };

    // centre on 0 if:
    // indicatedDemand is visible (not hidden)
    // and
    // one of national demand, indicated margin and indicated generation are visible
    const yAxisCentre =
      combineDatasetCategories(datasetCategories).find(
        (x) =>
          !x.isHidden &&
          (x.dataKey === DemandIndicatedForecastDataKeys.NationalDemand ||
            x.dataKey === IndicatedForecastDataKeys.IndicatedMargin ||
            x.dataKey === IndicatedForecastDataKeys.IndicatedGeneration)
      ) &&
      combineDatasetCategories(datasetCategories).find(
        (x) => x.dataKey === IndicatedForecastDataKeys.IndicatedDemand
      )?.isHidden
        ? undefined
        : 0;

    return (
      <ResponsiveLine
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...commonChartProps(isChartLoaded, series)}
        axisBottom={chartAxisBottom("Start Time (UTC)", series, isChartLoaded)}
        axisLeft={chartAxisLeft("MW", isChartLoaded)}
        layers={chartLayers}
        onMouseLeave={(): void => sendHighlightedDataset("")}
        onMouseMove={(point): void =>
          sendHighlightedDataset(point.id.split(".")[0])
        }
        tooltip={(input): JSX.Element => (
          <TabulatedChartTooltip
            series={series}
            config={config}
            text={{
              label: "Start Time",
            }}
            options={{
              interactionType: TooltipInteractionType.Voronoi,
              highlightSelectedDataset: true,
            }}
            inputPoints={[input.point]}
          />
        )}
        xScale={chartXTimeScale()}
        yScale={nonStackedLineChartYScale(series, { centre: yAxisCentre })}
        useMesh
      />
    );
  }, [dataFetchStatus, datasetCategories, series, sendHighlightedDataset]);

  return useMemo(
    () => (
      <ChartWithErrorHandling
        series={series}
        datasetCategories={datasetCategories}
        dataFetchStatus={dataFetchStatus}
        rerequestData={rerequestData}
      >
        <>{memoisedChart}</>
      </ChartWithErrorHandling>
    ),
    [dataFetchStatus, datasetCategories, memoisedChart, rerequestData, series]
  );
};
export default IndicatedForecastsChart;
