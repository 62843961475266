import DateFilterModel from "models/filterModels/dateFilterModel";
import {
  addDaysToDate,
  endOfDay,
  floorToHalfHour,
  startOfDay,
} from "utils/dateHelpers";

interface DateOnlyFilter {
  readonly startDateOnly: Date;
  readonly lastHalfHourOfDay: Date;
  readonly startOfFollowingDay: Date;
}

export default class DateOnlyFilterModel implements DateOnlyFilter {
  startDateOnly: Date;

  lastHalfHourOfDay: Date;

  /**
   * A model that represents a range of dates, ignoring time
   * @param startDateInclusive The date of the start of the range (will be rounded to the start of that day i.e. 00:00)
   * @param endDateInclusive The date of the end of the range (will be rounded to the last half-hour of that day i.e. 23:30)
   */
  constructor(startDateInclusive: Date, endDateInclusive: Date) {
    this.startDateOnly = startOfDay(startDateInclusive);
    this.lastHalfHourOfDay = floorToHalfHour(endOfDay(endDateInclusive));
  }

  get startOfFollowingDay(): Date {
    return startOfDay(addDaysToDate(this.lastHalfHourOfDay, 1));
  }

  toDateFilterModelInclusive(): DateFilterModel {
    // The date selector rounds to the nearest half hour
    // So this is the last point in the day that wouldn't get rounded to the next date
    return new DateFilterModel(this.startDateOnly, this.lastHalfHourOfDay);
  }

  toDateFilterModelExclusive(): DateFilterModel {
    return new DateFilterModel(this.startDateOnly, this.startOfFollowingDay);
  }
}
