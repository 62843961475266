import styled from "styled-components";
import colours from "styles/colours";

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid transparent;

  &.reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

export const StyledSwitch = styled.div`
  padding: 3px;
  margin-right: 5px;
  border-radius: 34px;
  height: 26px;

  :focus-within {
    outline: 1px solid ${colours.elexonBlue};
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    border-radius: 34px;
    cursor: pointer;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked {
        + .slider {
          &:before {
            border: 2px solid black;
            -webkit-transform: translateX(24px);
            -ms-transform: translateX(24px);
            transform: translateX(24px);
          }
        }
      }

      &:focus {
        + .switch {
          border: 2px solid ${colours.elexonLightBlue};
        }
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 5px;
      left: 0;
      right: 0;
      bottom: 0;
      height: 10px;
      background-color: ${(props: { colour: string }): string =>
        props.colour ? props.colour : colours.disabledGrey};
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 3px;
        bottom: -3px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
  }
`;

export const SwitchLabel = styled.span`
  vertical-align: 2px;
  margin-right: 10px;

  &.header {
    font-weight: bold;
    text-transform: uppercase;
  }
`;
