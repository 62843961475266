import { getData } from "api/dataVisualisation/client";
import { BmuModel } from "models/bmuData/bmuModel";

export const getAllFuelTypes = async (): Promise<string[]> => {
  const { data } = await getData("/reference/fueltypes/all");
  return data;
};

export const getAllBmUnits = async (): Promise<BmuModel[]> => {
  const { data } = await getData("/reference/bmunits/all");
  return data;
};

export const getAllParticipants = async (): Promise<string[]> => {
  const { data } = await getData("/reference/remit/participants/all");
  return data;
};

export const getAllAssets = async (): Promise<string[]> => {
  const { data } = await getData("/reference/remit/assets/all");
  return data;
};

export const getAllRemitFuelTypes = async (): Promise<string[]> => {
  const { data } = await getData("/reference/remit/fueltypes/all");
  return data;
};
