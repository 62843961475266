import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { WindGenerationForecast } from "models/windGeneration/windGenerationForecast";
import { WindGenerationPeakRawDataModel } from "models/windGeneration/windGenerationPeakRawDataModel";
import { toDateOnlyString } from "utils/dateHelpers";

export const getWindGenerationPeakForecastData = async (
  fromDate: Date,
  toDate: Date
): Promise<WindGenerationPeakRawDataModel> => {
  const { data } = await getData("/forecast/generation/wind/peak", {
    from: toDateOnlyString(fromDate),
    to: toDateOnlyString(toDate),
  });
  return data;
};

export const getWindGenerationLatestForecastData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<WindGenerationForecast>> => {
  const { data } = await getData(
    "/forecast/generation/wind/latest",
    {
      from: dateFilter.startDate.toISOString(),
      to: dateFilter.endDate.toISOString(),
    },
    format
  );
  return data;
};

export const getWindGenerationLatestForecastJsonData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<WindGenerationForecast>> =>
  getWindGenerationLatestForecastData(
    dateFilter,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<WindGenerationForecast>>;

export const getWindGenerationInitialForecastData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<WindGenerationForecast>> => {
  const { data } = await getData(
    "/forecast/generation/wind/earliest",
    {
      from: dateFilter.startDate.toISOString(),
      to: dateFilter.endDate.toISOString(),
    },
    format
  );
  return data;
};

export const getWindGenerationInitialForecastJsonData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<WindGenerationForecast>> =>
  getWindGenerationInitialForecastData(
    dateFilter,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<WindGenerationForecast>>;
