import {
  getTotalLoadActualData,
  getTotalLoadDayAheadData,
} from "api/dataVisualisation/totalLoadActualAndDayAhead/totalLoadActualAndDayAhead";
import DatasetLineChart from "components/dataVisualization/DatasetLineChart/DatasetLineChart";
import useTotalLoadActualAndDayAheadChartData from "components/dataVisualization/TotalLoadActualAndDayAhead/useTotalLoadActualAndDayAheadChartData";
import { transformTotalLoadActualAndDayAheadTypeDataToSerie } from "components/dataVisualization/TotalLoadActualAndDayAhead/utils";
import {
  ChartBody,
  ChartFooter,
  ChartHeader,
} from "components/dataVisualization/chartComponents/style";
import DataExporter from "components/dataVisualization/dataExporterComponents/DataExporter/DataExporter";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import { useDataExport } from "contexts/DataExportContext";
import {
  ChartDatasetModel,
  DatasetCategory,
} from "models/chartConfiguration/chartDatasetModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { DateSelectorTabs } from "models/filterModels/dateSelectorTabs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { chartColours } from "styles/colours";
import { downloadDataToFile } from "utils/DataDownloads/FetchDataForFileExport/DownloadDataToFile";
import {
  DAYS_IN_ONE_WEEK,
  addDaysToDate,
  ceilToHalfHour,
} from "utils/dateHelpers";

const TotalLoadActualAndDayAheadChartContainer: React.FC = () => {
  const { dispatch: dataExportCountDispatch } = useDataExport();
  const defaultEndDate = addDaysToDate(ceilToHalfHour(new Date()), 1);
  const dateSelectorTabs = [
    DateSelectorTabs.ThreeDays,
    DateSelectorTabs.OneWeek,
  ];
  const [dateFilter, setDateFilter] = useState<DateFilterModel>(
    new DateFilterModel(addDaysToDate(defaultEndDate, -7), defaultEndDate)
  );
  const [activeDateSelectorTab, setActiveDateSelectorTab] = useState(
    DateSelectorTabs.OneWeek
  );

  const datasetCategories: DatasetCategory<ChartDatasetModel>[] = [
    {
      datasets: [
        {
          colour: chartColours.atl,
          dataKey: "ATL",
          displayName: "Actual load",
        },
        {
          colour: chartColours.datl,
          dataKey: "DATL",
          displayName: "Day-ahead forecast",
        },
      ],
      id: "Total Load",
    },
  ];

  const { data, fetchData, status } =
    useTotalLoadActualAndDayAheadChartData(dateFilter);

  useEffect(fetchData, [fetchData]);

  const handleChangeToDateSelection = useCallback(
    (newDateFilter: DateFilterModel, newActiveTab: DateSelectorTabs): void => {
      setDateFilter(newDateFilter.restrictToMaxOneWeek());
      setActiveDateSelectorTab(newActiveTab);
    },
    [setDateFilter, setActiveDateSelectorTab]
  );

  const atlChartData = useMemo(() => (data?.actual ? data.actual : []), [data]);

  const datlChartData = useMemo(
    () => (data?.dayAhead ? data.dayAhead : []),
    [data]
  );

  const chartData = [
    transformTotalLoadActualAndDayAheadTypeDataToSerie(
      atlChartData,
      "ATL",
      chartColours.atl
    ),
    transformTotalLoadActualAndDayAheadTypeDataToSerie(
      datlChartData,
      "DATL",
      chartColours.datl
    ),
  ];

  const downloadAtlFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getTotalLoadActualData(dateFilter, fileFormat),
      `ActualTotalLoadPerBiddingZone-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const downloadDatlFile = (fileFormat: DataDownloadFormat): void => {
    downloadDataToFile(
      () => getTotalLoadDayAheadData(dateFilter, fileFormat),
      `DayAheadTotalLoadPerBiddingZone-${dateFilter.startDate.toISOString()}-${dateFilter.endDate.toISOString()}`,
      fileFormat,
      dataExportCountDispatch
    );
  };

  const downloadFiles = (fileFormat: DataDownloadFormat): void => {
    downloadAtlFile(fileFormat);
    downloadDatlFile(fileFormat);
  };

  return (
    <>
      <ChartHeader>
        <ChartFilter
          dateFilter={dateFilter}
          defaultEndDate={defaultEndDate}
          handleChangeToDateSelection={handleChangeToDateSelection}
          dateSelectorTabs={dateSelectorTabs}
          activeDateSelectorTab={activeDateSelectorTab}
          maxRange={DAYS_IN_ONE_WEEK}
        />
      </ChartHeader>
      <ChartBody>
        <DatasetLineChart
          series={chartData}
          datasetCategories={datasetCategories}
          dataFetchStatus={status}
          rerequestData={fetchData}
          tooltipText={"Start Time"}
        />
      </ChartBody>
      <ChartFooter rightAligned>
        <DataExporter
          downloadData={downloadFiles}
          dateFilter={dateFilter}
          defaultEndDate={defaultEndDate}
          handleChangeToDateSelection={handleChangeToDateSelection}
          activeDateSelectorTab={activeDateSelectorTab}
          dateSelectorTabs={dateSelectorTabs}
          maxRange={DAYS_IN_ONE_WEEK}
        />
      </ChartFooter>
    </>
  );
};

export default TotalLoadActualAndDayAheadChartContainer;
