import { getAcceptanceVolumeData } from "api/dataVisualisation/derivedData/acceptanceVolume";
import { getIndicativeCashflowData } from "api/dataVisualisation/derivedData/indicativeCashflow";
import { getIndicativeVolumeData } from "api/dataVisualisation/derivedData/indicativeVolume";
import { BidOfferQueryIndicator } from "components/dataVisualization/DetailedSystemPrices/utils";
import { DownloadCallback } from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/MultiDatasetExporter";
import { BmuModel } from "models/bmuData/bmuModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import { DataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { IndicativeCashflowModel } from "models/derivedData/indicativeCashflow";
import { IndicativeVolumeModel } from "models/derivedData/indicativeVolumeModel";
import { stitchDataResults } from "utils/dataStitchHelpers";

export const DerivedDataCheckboxGroups = {
  BidAcceptanceVolume: {
    id: "bid-acceptance-volume",
    name: "Bid Acceptance Volume",
  },
  OfferAcceptanceVolume: {
    id: "offer-acceptance-volume",
    name: "Offer Acceptance Volume",
  },
  IndicativeVolume: {
    id: "indicative-volume",
    name: "Indicative Period BM Unit Acceptance Volume",
  },
  IndicativeCashflow: {
    id: "indicative-cashflow",
    name: "Indicative Period BM Unit Cashflows",
  },
};

export const getIndicativeCashflowCallbacks = (
  selectedDate: Date,
  bmus: BmuModel[] | undefined
): DownloadCallback => ({
  download: async (
    format: DataDownloadFormat
  ): Promise<DataDownloadModel<IndicativeCashflowModel>> =>
    stitchDataResults<IndicativeCashflowModel>(
      [
        await getIndicativeCashflowData(
          selectedDate,
          BidOfferQueryIndicator.BID,
          bmus,
          format
        ),
        await getIndicativeCashflowData(
          selectedDate,
          BidOfferQueryIndicator.OFFER,
          bmus,
          format
        ),
      ],
      format
    ),
  filePrefix: "IndicativeCashflow",
});

export const getIndicativeVolumeCallbacks = (
  selectedDate: Date,
  bmus: BmuModel[] | undefined
): DownloadCallback => ({
  download: async (
    format: DataDownloadFormat
  ): Promise<DataDownloadModel<IndicativeVolumeModel>> =>
    stitchDataResults<IndicativeVolumeModel>(
      [
        await getIndicativeVolumeData(
          selectedDate,
          BidOfferQueryIndicator.BID,
          bmus,
          format
        ),
        await getIndicativeVolumeData(
          selectedDate,
          BidOfferQueryIndicator.OFFER,
          bmus,
          format
        ),
      ],
      format
    ),
  filePrefix: "IndicativeVolume",
});

export const transformPerDerivedDataDownloadCallbacks = (
  selectedDatasets: string[],
  selectedDate: Date,
  bmus?: BmuModel[]
): DownloadCallback[] => {
  const downloadCallbacks: DownloadCallback[] = [];
  if (selectedDatasets.includes("bid-acceptance-volume")) {
    downloadCallbacks.push({
      download: (format: DataDownloadFormat) =>
        getAcceptanceVolumeData(
          selectedDate,
          BidOfferQueryIndicator.BID,
          bmus,
          format
        ),
      filePrefix: "BidAcceptanceVolume",
    });
  }

  if (selectedDatasets.includes("offer-acceptance-volume")) {
    downloadCallbacks.push({
      download: (format: DataDownloadFormat) =>
        getAcceptanceVolumeData(
          selectedDate,
          BidOfferQueryIndicator.OFFER,
          bmus,
          format
        ),
      filePrefix: "OfferAcceptanceVolume",
    });
  }

  if (selectedDatasets.includes("indicative-cashflow")) {
    downloadCallbacks.push(getIndicativeCashflowCallbacks(selectedDate, bmus));
  }

  if (selectedDatasets.includes("indicative-volume")) {
    downloadCallbacks.push(getIndicativeVolumeCallbacks(selectedDate, bmus));
  }

  return downloadCallbacks;
};
