import {
  HorizontalLayout,
  SubtleBox,
  ToggleShowDetails,
} from "components/components/Updates/PreviousUpdates/style";
import { DateText, StyledLink } from "components/components/Updates/style";
import useToggle from "hooks/useToggle";
import { Update } from "models/updateModel";
import React from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";

type PreviousUpdateProps = {
  update: Update;
};

const PreviousUpdate: React.FC<PreviousUpdateProps> = ({
  update: { title, body, link, date },
}) => {
  const [showingDetails, toggleShowingDetails] = useToggle(false);
  const buttonText = showingDetails ? "Hide details" : "Show details";
  const linkText = `Visit the ${title.toLowerCase()} page`;
  return (
    <SubtleBox>
      <HorizontalLayout>
        <h3 className="previous-update-title">{title}</h3>
        <ToggleShowDetails
          onClick={toggleShowingDetails}
          role="button"
          aria-label={buttonText}
        >
          {buttonText}
          <span className="details-icon" aria-hidden="true">
            {showingDetails ? "3" : "o"}
          </span>
        </ToggleShowDetails>
      </HorizontalLayout>
      <DateText>{formatDateTimeString(date, DateFormat.DateOnly)}</DateText>
      {showingDetails && (
        <>
          {body}
          {link && <StyledLink to={link}>{linkText}</StyledLink>}
        </>
      )}
    </SubtleBox>
  );
};

export default PreviousUpdate;
