import { RequestStatus } from "hooks/useRequest";

export const combineRequestStatuses = (
  ...statuses: RequestStatus[]
): RequestStatus => {
  if (statuses.some((s) => s === RequestStatus.ERRORED)) {
    return RequestStatus.ERRORED;
  }
  if (statuses.some((s) => s === RequestStatus.IN_PROGRESS)) {
    return RequestStatus.IN_PROGRESS;
  }
  return RequestStatus.SUCCESSFUL_OR_NOT_STARTED;
};
