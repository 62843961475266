import styled from "styled-components";
import colours from "styles/colours";

export const Header = styled.span`
  font-weight: bold;
  text-transform: uppercase;
`;

export const ColourBlob = styled.div`
  display: inline-block;
  border: 1px solid ${colours.elexonBlack};
  border-radius: 50%;
  height: 14px;
  width: 14px;
`;

export const Label = styled.span`
  margin-left: 10px;
`;
