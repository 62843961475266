import { ReadOnlyDates } from "components/components/DateSelector/DateSelector";
import Switch from "components/components/Switch/Switch";
import CheckboxDataExporter from "components/dataVisualization/CheckboxDataExporter/CheckboxDataExporter";
import {
  ChartHeader,
  ChartBody,
  ChartFooter,
} from "components/dataVisualization/chartComponents/style";
import ChartFilter from "components/dataVisualization/dataPageComponents/ChartFilter/ChartFilter";
import { DownloadCallback } from "components/dataVisualization/dataPageComponents/MultiDatasetExporter/MultiDatasetExporter";
import IndicativeDemandPeakChart from "components/dataVisualization/indicativeDemandPeak/IndicativeDemandPeakChart/IndicativeDemandPeakChart";
import { StyledDiv } from "components/dataVisualization/indicativeDemandPeak/IndicativeDemandPeakContainer/style";
import { IndicativeDemandPeakChartData } from "components/dataVisualization/indicativeDemandPeak/IndicativeDemandPeakContainer/useIndicativePeakDemandData";
import {
  datasets,
  transformTriadDownloadCallbacks,
} from "components/dataVisualization/indicativeDemandPeak/IndicativeDemandPeakContainer/utils";
import { RequestStatus } from "hooks/useRequest";
import React, { useMemo, useEffect } from "react";
import colours from "styles/colours";
import { transformIndicativeDemandPeakModelData } from "utils/ChartDataUtils/indicativeDemandPeak/indicativeDemandPeak";
type Props = {
  fetchData: () => void;
  data: IndicativeDemandPeakChartData | null;
  status: RequestStatus;
  showOperationalData: boolean;
  toggleHandler: () => void;
};

enum SwitchLabels {
  SettlementData = "Settlement Data",
  OperationalData = "Operational Data",
}

export enum MeteringDataType {
  Settlement = "settlement",
  Operational = "operational",
}

const IndicativeDemandPeakChartContainer: React.FC<Props> = ({
  fetchData,
  data: rawData,
  status,
  showOperationalData,
  toggleHandler,
}) => {
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const chartData = useMemo(
    () => (rawData ? transformIndicativeDemandPeakModelData(rawData) : null),
    [rawData]
  );

  return (
    <>
      <ChartHeader>
        <StyledDiv>
          <Switch
            name="reference-data"
            onColor={colours.jungleGreen}
            offColor={colours.elexonPurple}
            onClick={toggleHandler}
            switchedOn={showOperationalData}
            leftLabel={SwitchLabels.SettlementData}
            rightLabel={SwitchLabels.OperationalData}
            headerLabel
          />
        </StyledDiv>
        {chartData && (
          <ChartFilter
            dateFilter={chartData.dateRange}
            handleChangeToDateSelection={(): void => {
              /* Do nothing since chart has fixed time period */
            }}
            dateSelectorTabs={[]}
            activeDateSelectorTab={null}
            showTimeInput={false}
            readOnlyDates={ReadOnlyDates.Both}
          />
        )}
      </ChartHeader>
      <ChartBody>
        <IndicativeDemandPeakChart
          series={chartData?.series ?? []}
          dataFetchStatus={status}
          rerequestData={fetchData}
          triadPeaks={chartData?.triadPeaks ?? []}
          isSettlement={!showOperationalData}
        />
      </ChartBody>
      <ChartFooter rightAligned>
        {chartData && (
          <CheckboxDataExporter
            dateFilter={chartData?.dateRange}
            datasets={datasets}
            transformToDownloadCallbacks={(
              datasetsToDownload: string[]
            ): DownloadCallback[] =>
              transformTriadDownloadCallbacks(
                datasetsToDownload,
                chartData?.dateRange.startDate
              )
            }
            showTimeInput={false}
            showSingleSettlementPeriod={false}
          />
        )}
      </ChartFooter>
    </>
  );
};

export default React.memo(IndicativeDemandPeakChartContainer);
