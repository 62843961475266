export const generationRoutes = {
  generationByFuelType: "/generation-by-fuel-type",
  generationForecast: "/generation-forecast",
  generationForecastForWindAndSolar: "/generation-forecast-for-wind-and-solar",
  interconnectorFlows: "/interconnector-flows",
  windGeneration: "/wind-generation",
  actualGenerationPerType: "/actual-aggregated-generation-per-type",
  actualOrEstimatedWindAndSolarPowerGeneration:
    "/actual-or-estimated-wind-and-solar-power-generation",
  dayAheadAggregatedGeneration: "/day-ahead-aggregated-generation",
};

export const demandRoutes = {
  rollingSystemDemand: "/rolling-system-demand",
  surplusAndMargin: "/surplus-forecast-and-margin",
  demandForecast: "/demand-forecast",
  demandOutturn: "/demand-outturn",
  dailyEnergyTransmitted: "/daily-energy-transmitted",
  indicativeDemandPeak: "/indicative-peak-demand",
  totalLoadActualAndDayAhead: "/total-load-actual-and-day-ahead",
  totalLoadWeekAhead: "/total-load-week-ahead",
  indicatedForecasts: "/indicated-forecasts",
};

export const balancingRoutes = {
  shortTermOperatingReserves: "/short-term-operating-reserves",
  bmuView: "/balancing-mechanism-bmu-view",
  marketView: "/balancing-mechanism-market-view",
  adjustmentDataNetbsad: "/adjustment-data-netbsad",
  adjustmentActionsDisbsad: "/adjustment-actions-disbsad",
  marketIndexPrices: "/market-index-prices",
  derivedData: "/derived-data",
  detailedSystemPrices: "/detailed-system-prices",
  systemPrices: "/system-prices",
};

export const noticesRoutes = {
  creditDefaultNotices: "/credit-default-notices",
  events: "/events",
  settlementMessages: "/settlement-messages",
  sosoTradePrices: "/soso-trade-prices",
};

export const remitRoutes = {
  remit: "/remit",
  remitMessageDetails: "/remit/details",
};

export const transmissionRoutes = {
  rollingSystemFrequency: "/rolling-system-frequency",
  temperature: "/temperature",
  lolpdrm: "/loss-of-load-probability-and-derated-margin",
};

export const aboutRoutes = {
  whatsNew: "/whats-new",
};

export const accountRoutes = {
  profile: "/profile",
};

const apiDocs = "/api-documentation";

export const apiDocsRouteSuffixes = {
  introduction: "/introduction",
  guidance: "/guidance",
  endpoint: "/endpoint",
  searchResults: "/search-results",
};

export const dataServicesRoutes = {
  iris: "/iris",
  apiDocs,
  apiDocsIntroduction: apiDocs + apiDocsRouteSuffixes.introduction,
  apiDocsGuidance: apiDocs + apiDocsRouteSuffixes.guidance,
};

const routeConstants = {
  home: "/",
  ...generationRoutes,
  ...demandRoutes,
  ...balancingRoutes,
  ...noticesRoutes,
  ...remitRoutes,
  ...transmissionRoutes,
  ...aboutRoutes,
  ...accountRoutes,
  ...dataServicesRoutes,
};

export const irisGitHubUrl = "https://github.com/elexon-data/iris-clients";

export const azureStorageAccountUrl =
  "https://azure.microsoft.com/en-gb/products/storage/storage-explorer";

export default routeConstants;
