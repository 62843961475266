import { DataExportAction } from "contexts/DataExportContext";
import { DataDownloadModel } from "models/dataDownload/dataDownloadModel";
import { exportToFile } from "utils/DataDownloads/FetchDataForFileExport/ExportToFile";

export const downloadDataToFile = async (
  download: () => Promise<DataDownloadModel<unknown>>,
  filename: string,
  format: string,
  updateDataExportCount: (action: DataExportAction) => void
): Promise<void> => {
  updateDataExportCount(DataExportAction.IncrementCountAndShowToast);

  try {
    const results = await download();
    exportToFile(filename, format, results);
  } catch {
    updateDataExportCount(DataExportAction.ReportError);
  } finally {
    updateDataExportCount(DataExportAction.DecrementCount);
  }
};
