import { getData } from "api/dataVisualisation/client";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { LolpdrmModel } from "models/lolpdrm/lolpdrmModel";

export const getLolpdrmData = async (
  dateFilter: DateFilterModel,
  format?: DataDownloadFormat
): Promise<DataDownloadModel<LolpdrmModel>> => {
  const { data } = await getData(
    "/forecast/system/loss-of-load",
    {
      from: dateFilter.normalisedStartDate.toISOString(),
      to: dateFilter.normalisedEndDate.toISOString(),
    },
    format
  );
  return data;
};

export const getLolpdrmJsonData = async (
  dateFilter: DateFilterModel
): Promise<JsonDataDownloadModel<LolpdrmModel>> =>
  getLolpdrmData(dateFilter, DataDownloadFormat.Json) as Promise<
    JsonDataDownloadModel<LolpdrmModel>
  >;
