import { Datum, Serie } from "@nivo/line";
import { RollingSystemFrequencyModel } from "models/rollingSystemFrequency/rollingSystemFrequencyModel";
import { chartColours } from "styles/colours";
import { sortByMeasurementTime } from "utils/ChartDataUtils/index";

export const transformRollingSystemFrequencyModelData = (
  rawData: RollingSystemFrequencyModel[]
): Serie[] => {
  const sortedData = sortByMeasurementTime(rawData);

  const data = sortedData.reduce(
    (prev, curr) => [
      ...prev,
      {
        x: new Date(curr.measurementTime),
        y: curr.frequency,
      },
    ],
    [] as Datum[]
  );

  return [
    {
      id: "frequency",
      colour: chartColours.frequencyData,
      data,
    },
  ];
};
