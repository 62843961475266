import { Datum, Point, Serie } from "@nivo/line";
import {
  DatasetCategory,
  ChartDatasetModel,
} from "models/chartConfiguration/chartDatasetModel";
import { ChartDatasetTooltipModel } from "models/chartConfiguration/tooltipModels";

type TooltipValue = {
  id: string | number;
  value?: number;
  settlementPeriod?: number;
  publishTime?: Date;
  valueNullish?: boolean;
};

const toChartDatasetTooltipModel = (
  dataset: ChartDatasetModel,
  tooltipValues: TooltipValue[]
): ChartDatasetTooltipModel => {
  const { value, settlementPeriod, publishTime, valueNullish } =
    tooltipValues.find((entry) => entry.id === dataset.dataKey) ?? {};

  return {
    ...dataset,
    value,
    settlementPeriod,
    publishTime,
    valueNullish,
  };
};

const datumToTooltipValue = (
  serieId: string | number,
  datum: Datum | undefined
): TooltipValue => ({
  id: serieId,
  value: (datum?.y ?? undefined) as number | undefined,
  settlementPeriod: (datum?.settlementPeriod ?? undefined) as
    | number
    | undefined,
  publishTime: (datum?.publishTime ?? undefined) as Date | undefined,
  valueNullish: (datum?.valueNullish ?? undefined) as boolean | undefined,
});

const getTooltipPointValuesFromData = (
  inputPointData: Point["data"],
  series: Serie[]
): TooltipValue[] =>
  series.flatMap((entry) => {
    const matchingDatum = entry.data.find(
      (datum) =>
        (datum.x as Date).getTime() === (inputPointData.x as Date).getTime()
    );
    return datumToTooltipValue(entry.id, matchingDatum);
  });

export const addTooltipPointValuesToDatasets = (
  inputPoint: Point["data"],
  series: Serie[],
  datasetCategories: DatasetCategory<ChartDatasetModel>[]
): DatasetCategory<ChartDatasetTooltipModel>[] => {
  const values = getTooltipPointValuesFromData(inputPoint, series);

  return datasetCategories.map((datasetCategory) => ({
    ...datasetCategory,
    datasets: datasetCategory.datasets.map((dataset) =>
      toChartDatasetTooltipModel(dataset, values)
    ),
  }));
};

export const addTooltipRangeValuesToDatasets = (
  series: Serie[],
  datasetCategories: DatasetCategory<ChartDatasetModel>[],
  dateFrom: Date,
  dateTo: Date
): DatasetCategory<ChartDatasetTooltipModel>[] => {
  const values = series
    .map((serie): TooltipValue => {
      const validData = serie.data.find(
        (datum) => datum.x && datum.x >= dateFrom && datum.x < dateTo
      );
      return datumToTooltipValue(serie.id, validData);
    })
    .filter((datum) => datum.value !== undefined);
  return datasetCategories.map((datasetCategory) => ({
    ...datasetCategory,
    datasets: datasetCategory.datasets.map((dataset) =>
      toChartDatasetTooltipModel(dataset, values)
    ),
  }));
};

export const addTooltipRangeValuesToDatasetsWithAlwaysShownDatasets = (
  series: Serie[],
  datasetCategories: DatasetCategory<ChartDatasetModel>[],
  dateFrom: Date,
  dateTo: Date,
  alwaysShownDatasets: string[]
): DatasetCategory<ChartDatasetTooltipModel>[] => {
  const result = addTooltipRangeValuesToDatasets(
    series,
    datasetCategories,
    dateFrom,
    dateTo
  );
  return result.map((category) => ({
    ...category,
    datasets: category.datasets.map((dataset) =>
      alwaysShownDatasets.includes(dataset.dataKey)
        ? {
            ...dataset,
            alwaysShown: true,
          }
        : dataset
    ),
  }));
};
