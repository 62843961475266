import Button from "components/components/Button/Button";
import {
  AgptSummaryData,
  AgptSummaryModel,
} from "models/actualGenerationModel/actualGeneration";
import React, { useCallback, useEffect, useState } from "react";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";
import { formatNumberToGBLocale } from "utils/summaryHelpers";

import {
  CloseButtonContainer,
  SectionRow,
  LastUpdatedContainer,
  SeparatorRow,
  Subtitle,
  SummaryTable,
  TotalsRow,
  SubtotalsRow,
} from "./style";

export const inAlphabeticalOrder = (
  a: AgptSummaryModel,
  b: AgptSummaryModel
): number => {
  return a.psrType.localeCompare(b.psrType);
};

interface Props {
  data: AgptSummaryData;
  summaryTime: Date;
  onClose: () => void;
}

const AgptSummaryTable: React.FC<Props> = ({
  data,
  summaryTime,
  onClose,
}: Props) => {
  const [lastHalfHourTime, setLastHalfHourTime] = useState<string>("");
  const [lastTwentyFourHourTime, setLastTwentyFourHourTime] =
    useState<string>("");

  const buildSummaryTimeStrings = useCallback((): [string, string] => {
    const minutesSinceHalfHour = summaryTime.getMinutes() % 30;

    const halfHour = new Date(summaryTime);
    halfHour.setMinutes(summaryTime.getMinutes() - minutesSinceHalfHour);

    const previousHalfHour = new Date(halfHour);
    previousHalfHour.setMinutes(halfHour.getMinutes() - 30);

    const previousHalfHourString = formatDateTimeString(
      previousHalfHour,
      DateFormat.TimeOnly
    );

    const halfHourString = formatDateTimeString(halfHour, DateFormat.TimeOnly);

    return [previousHalfHourString, halfHourString];
  }, [summaryTime]);

  useEffect(() => {
    const [previousHalfHourString, halfHourString] = buildSummaryTimeStrings();

    setLastHalfHourTime(`(${previousHalfHourString}-${halfHourString})`);
    setLastTwentyFourHourTime(`(${halfHourString}-${halfHourString})`);
  }, [buildSummaryTimeStrings, summaryTime]);

  // Sort the data alphabetically by psrType
  data.lowCarbon.sort(inAlphabeticalOrder);
  data.fossilFuel.sort(inAlphabeticalOrder);

  return (
    <div data-test-id="summary-table-modal">
      <CloseButtonContainer data-test-id="summary-table-close-button-row">
        <Button
          ariaLabel="Close"
          buttonText="Close"
          aria-label="close"
          onClick={onClose}
          data-test-id="close-button"
        />
      </CloseButtonContainer>
      <section className="section pt-5">
        <div className="container">
          <div className="columns">
            <div className="column is-two-thirds">
              <h1 className="title" id="summary-modal-header">
                Actual Aggregated Generation Per Type
              </h1>
              <Subtitle className="subtitle" id="summary-modal-description">
                Current summary
              </Subtitle>
            </div>
            <div className="column is-one-third">
              <LastUpdatedContainer data-test-id="summary-table-data-last-updated">
                Data Last Updated:&nbsp;
                {formatDateTimeString(
                  summaryTime,
                  DateFormat.DateTimeWithSeconds
                )}
              </LastUpdatedContainer>
            </div>
          </div>
          <SummaryTable>
            <thead>
              <tr data-test-id="summary-table-data-table-header">
                <td>Generation Type</td>
                <td colSpan={2}>
                  Last half hour&nbsp;
                  {lastHalfHourTime}
                </td>
                <td colSpan={2}>
                  Last 24 hours&nbsp;
                  {lastTwentyFourHourTime}
                </td>
              </tr>
            </thead>
            <tbody>
              <SectionRow>
                <td>Low Carbon Sources</td>
                <td>MW</td>
                <td>%</td>
                <td>MWh</td>
                <td>%</td>
              </SectionRow>
              {data.lowCarbon.map((value, idx) => (
                <tr
                  key={value.psrType}
                  data-test-id={`summary-table-low-carbon-${idx}`}
                >
                  <td>{value.psrType}</td>
                  <td>{formatNumberToGBLocale(value.halfHourUsage, 3)}</td>
                  <td>{`${value.halfHourPercentage.toFixed(1)}%`}</td>
                  <td>
                    {formatNumberToGBLocale(value.twentyFourHourUsage, 3)}
                  </td>
                  <td>{`${value.twentyFourHourPercentage.toFixed(1)}%`}</td>
                </tr>
              ))}
              <SubtotalsRow data-test-id="summary-table-low-carbon-subtotals">
                <td>Subtotals</td>
                <td>
                  {formatNumberToGBLocale(
                    data.lowCarbon.reduce<number>(
                      (accumulator, value) => accumulator + value.halfHourUsage,
                      0
                    ),
                    3
                  )}
                </td>
                <td>
                  {data.lowCarbon
                    .reduce<number>(
                      (accumulator, value) =>
                        accumulator + value.halfHourPercentage,
                      0
                    )
                    .toFixed(1)}
                  %
                </td>
                <td>
                  {formatNumberToGBLocale(
                    data.lowCarbon.reduce<number>(
                      (accumulator, value) =>
                        accumulator + value.twentyFourHourUsage,
                      0
                    ),
                    3
                  )}
                </td>
                <td>
                  {data.lowCarbon
                    .reduce<number>(
                      (accumulator, value) =>
                        accumulator + value.twentyFourHourPercentage,
                      0
                    )
                    .toFixed(1)}
                  %
                </td>
              </SubtotalsRow>
              <SeparatorRow>
                <td colSpan={7}>&nbsp;</td>
              </SeparatorRow>
              <SectionRow>
                <td>Fossil Fuels</td>
                <td>MW</td>
                <td>%</td>
                <td>MWh</td>
                <td>%</td>
              </SectionRow>
              {data.fossilFuel.map((value, idx) => (
                <tr
                  key={value.psrType}
                  data-test-id={`summary-table-fossil-fuel-${idx}`}
                >
                  <td>{value.psrType}</td>
                  <td>{formatNumberToGBLocale(value.halfHourUsage, 3)}</td>
                  <td>{`${value.halfHourPercentage.toFixed(1)}%`}</td>
                  <td>
                    {formatNumberToGBLocale(value.twentyFourHourUsage, 3)}
                  </td>
                  <td>{`${value.twentyFourHourPercentage.toFixed(1)}%`}</td>
                </tr>
              ))}
              <SubtotalsRow data-test-id="summary-table-fossil-fuel-subtotals">
                <td>Subtotals</td>
                <td>
                  {formatNumberToGBLocale(
                    data.fossilFuel.reduce<number>(
                      (accumulator, value) => accumulator + value.halfHourUsage,
                      0
                    ),
                    3
                  )}
                </td>
                <td>
                  {data.fossilFuel
                    .reduce<number>(
                      (accumulator, value) =>
                        accumulator + value.halfHourPercentage,
                      0
                    )
                    .toFixed(1)}
                  %
                </td>
                <td>
                  {formatNumberToGBLocale(
                    data.fossilFuel.reduce<number>(
                      (accumulator, value) =>
                        accumulator + value.twentyFourHourUsage,
                      0
                    ),
                    3
                  )}
                </td>
                <td>
                  {data.fossilFuel
                    .reduce<number>(
                      (accumulator, value) =>
                        accumulator + value.twentyFourHourPercentage,
                      0
                    )
                    .toFixed(1)}
                  %
                </td>
              </SubtotalsRow>
              <SeparatorRow>
                <td colSpan={7}>&nbsp;</td>
              </SeparatorRow>
              <TotalsRow data-test-id="summary-table-totals">
                <td>Totals</td>
                <td>
                  {formatNumberToGBLocale(
                    data.all.reduce<number>(
                      (accumulator, value) => accumulator + value.halfHourUsage,
                      0
                    ),
                    3
                  )}
                </td>
                <td>100%</td>
                <td>
                  {formatNumberToGBLocale(
                    data.all.reduce<number>(
                      (accumulator, value) =>
                        accumulator + value.twentyFourHourUsage,
                      0
                    ),
                    3
                  )}
                </td>
                <td>100%</td>
              </TotalsRow>
            </tbody>
          </SummaryTable>
        </div>
      </section>
    </div>
  );
};

export default AgptSummaryTable;
