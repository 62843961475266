import { getInterconnectorFlowsChartData } from "api/dataVisualisation/interconnectorFlows/interconnectorFlows";
import { getAllFuelTypes } from "api/dataVisualisation/reference/reference";
import useRequest, { RequestStatus } from "hooks/useRequest";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { OutturnSummaryModel } from "models/outturnSummaryModel";
import { useCallback } from "react";
import { combineRequestStatuses } from "utils/requestHelpers";

const useInterconnectorFlowsChartContainerData = (
  dateFilter: DateFilterModel
): {
  data: {
    fuelTypes: string[];
    interconnectorFlowsData: OutturnSummaryModel[];
  } | null;
  status: RequestStatus;
  fetchData: () => void;
} => {
  const {
    data: fuelTypes,
    status: fuelTypesStatus,
    request: fetchFuelTypes,
  } = useRequest(getAllFuelTypes);

  const {
    data: interconnectorFlowsData,
    status: interconnectorFlowsStatus,
    request: fetchInterconnectorFlowsData,
  } = useRequest(getInterconnectorFlowsChartData);

  const fetchData = useCallback(() => {
    if (!fuelTypes?.length) {
      fetchFuelTypes();
    }
    fetchInterconnectorFlowsData(dateFilter);
  }, [
    dateFilter,
    fetchFuelTypes,
    fetchInterconnectorFlowsData,
    fuelTypes?.length,
  ]);

  const data =
    fuelTypes && interconnectorFlowsData
      ? {
          fuelTypes,
          interconnectorFlowsData,
        }
      : null;

  const status = combineRequestStatuses(
    fuelTypesStatus,
    interconnectorFlowsStatus
  );

  return {
    data,
    status,
    fetchData,
  };
};

export default useInterconnectorFlowsChartContainerData;
