import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";
import { chartColours } from "styles/colours";

const initialNationalDemandDataset: ChartDatasetModel = {
  colour: chartColours.ndf,
  dataKey: "nationalDemand",
  displayName: "National Demand Forecast",
  unitSuffix: "MW",
};

const initialTransmissionDemandDataset: ChartDatasetModel = {
  colour: chartColours.tsdf,
  dataKey: "transmissionSystemDemand",
  displayName: "Transmission System Demand Forecast",
  unitSuffix: "MW",
};

const initialDemandOutturnDataset: ChartDatasetModel = {
  colour: chartColours.indo,
  dataKey: "initialDemandOutturn",
  displayName: "Initial Demand Outturn",
  unitSuffix: "MW",
  dashed: true,
};

const initialTransmissionSystemDemandOutturnDataset: ChartDatasetModel = {
  colour: chartColours.itsdo,
  dataKey: "initialTransmissionSystemDemandOutturn",
  displayName: "Initial Transmission System Demand Outturn",
  unitSuffix: "MW",
  dashed: true,
};

const initialForecastDatasets = [
  initialNationalDemandDataset,
  initialTransmissionDemandDataset,
];

const initialOutturnDatasets = [
  initialDemandOutturnDataset,
  initialTransmissionSystemDemandOutturnDataset,
];

export {
  initialNationalDemandDataset,
  initialTransmissionDemandDataset,
  initialDemandOutturnDataset,
  initialTransmissionSystemDemandOutturnDataset,
  initialForecastDatasets,
  initialOutturnDatasets,
};
