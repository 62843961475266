import ResultsCount from "components/components/ResultsCount/ResultsCount";
import { StyledDateTime } from "components/components/SortableTable/CommonRenderFns";
import SortableTable, {
  TableCellRender,
  WithoutId,
} from "components/components/SortableTable/SortableTable";
import TruncatedText from "components/dataVisualization/TruncatedText/TruncatedText";
import { SystemWarningsModel } from "models/systemWarnings/systemWarningsModel";
import { SystemWarningsTableModel } from "models/systemWarnings/systemWarningsTableModel";
import React from "react";

const SystemWarningHeaders: Record<
  keyof WithoutId<SystemWarningsModel>,
  string
> = {
  publishTime: "Warning date",
  warningType: "Warning type",
  warningText: "Message text",
};

interface Props {
  data: SystemWarningsTableModel[];
}

const SystemWarningsTable: React.FC<Props> = ({ data }) => {
  return (
    <>
      <ResultsCount count={data.length} />
      <SortableTable
        headers={SystemWarningHeaders}
        items={data}
        dataTestId="system-warnings-table"
        renderFns={{
          publishTime: ({ publishTime }): TableCellRender => ({
            content: StyledDateTime(publishTime),
          }),
          warningText: ({ warningText }): TableCellRender => ({
            content: <TruncatedText text={warningText} />,
          }),
        }}
        scrollable={false}
      />
    </>
  );
};

export default SystemWarningsTable;
