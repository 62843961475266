import classNames from "classnames";
import { ListDescriptionContent } from "components/dataVisualization/chartComponents/ChartTooltip/ChartTooltipSubList";
import { StyledTooltipValuesTable } from "components/dataVisualization/chartComponents/ChartTooltip/style";
import { ChartConfiguration } from "models/chartConfiguration/chartConfigurationModel";
import { TabulatedTooltipValue } from "models/chartConfiguration/tooltipModels";
import React from "react";

interface Props {
  config: ChartConfiguration;
  tooltipValues?: TabulatedTooltipValue[];
  selectedDataset?: string;
}

const TooltipValuesTable: React.FC<Props> = ({
  config,
  tooltipValues,
  selectedDataset,
}) => (
  <>
    <StyledTooltipValuesTable key={`tooltip-table`}>
      {config.datasetCategories
        .filter((c) => c.datasets.some((d) => !d.isHidden))
        .map((category, index) => (
          <>
            <thead>
              {/* Padding between the "separate tables" */}
              {index !== 0 && <tr style={{ height: "20px" }}></tr>}
              <tr>
                <th key={`cell-heading-title`} style={{ textAlign: "left" }}>
                  {category.showTitle ? category.title : ""}
                </th>
                {config.tooltipColumns.map((col) => (
                  <th key={`cell-heading-${col.id}`}>{col.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {category.datasets.map((dataset) => {
                const tooltipValue = tooltipValues?.find(
                  (value) => value.datasetId === dataset.dataKey
                );
                return (
                  !dataset.isHidden && (
                    <tr
                      key={`row-${dataset.dataKey}`}
                      className={classNames({
                        selected: selectedDataset === dataset.dataKey,
                      })}
                    >
                      <th>
                        <ListDescriptionContent dataset={dataset} />
                      </th>

                      {config.tooltipColumns.map((col) => (
                        <td key={`cell-${dataset.dataKey}-${col.id}`}>
                          {tooltipValue &&
                            col.formatter(
                              tooltipValue[
                                col.dataKey as keyof TabulatedTooltipValue
                              ],
                              tooltipValue
                            )}
                        </td>
                      ))}
                    </tr>
                  )
                );
              })}
            </tbody>
          </>
        ))}
    </StyledTooltipValuesTable>
  </>
);
export default TooltipValuesTable;
