import styled, { css, FlattenSimpleInterpolation } from "styled-components";

export const StyledIcon = styled.span`
  &.x-small {
    font-size: 0.7em;
    height: 1rem;
    width: 1rem;
  }

  &.medium-small {
    font-size: 1.5em;
    height: 2rem;
    width: 2rem;
  }
  &.medium {
    font-size: 2em;
    height: 2rem;
    width: 2rem;
  }
  &.large {
    font-size: 3em;
    height: 3rem;
    width: 3rem;
  }
  &.x-large {
    font-size: 4em;
    height: 4rem;
    width: 4rem;
  }

  ${({ onClick }): false | FlattenSimpleInterpolation =>
    !!onClick &&
    css`
      & {
        padding-left: 0.5em;
        padding-top: 0.12em;
        cursor: pointer;
      }
    `}
`;
