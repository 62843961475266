import { ChartDatasetModel } from "models/chartConfiguration/chartDatasetModel";
import {
  ForecastHorizon,
  forecastHorizonMap,
} from "models/lolpdrm/lolpdrmModel";
import { lolpdrmColours } from "styles/colours";
import {
  defaultColumnFormatter,
  probabilityFormatter,
} from "utils/ChartDataUtils";

interface LolpdrmChartDatasetModel extends ChartDatasetModel {
  forecastHorizon: number;
}

export const lolpChartDatasetModel: LolpdrmChartDatasetModel[] = Object.entries(
  forecastHorizonMap
).map(([key, val]) => ({
  colour: lolpdrmColours[key as ForecastHorizon],
  dataKey: `lolp-${key}`,
  displayName: `LoLP ${val}h ${val === 12 ? "data" : "forecast"}`,
  forecastHorizon: val,
}));

export const drmChartDatasetModel: LolpdrmChartDatasetModel[] = Object.entries(
  forecastHorizonMap
).map(([key, val]) => ({
  colour: lolpdrmColours[key as ForecastHorizon],
  dataKey: `drm-${key}`,
  displayName: `DRM ${val}h ${val === 12 ? "data" : "forecast"}`,
  forecastHorizon: val,
}));

export const drmConfig = {
  datasetCategories: [
    {
      datasets: drmChartDatasetModel,
      id: "drm-datasets",
      title: "DRM",
    },
  ],
  tooltipColumns: [
    {
      id: "drm",
      dataKey: "y",
      formatter: defaultColumnFormatter({ suffix: "MW" }),
    },
  ],
};

export const lolpConfig = {
  datasetCategories: [
    {
      datasets: lolpChartDatasetModel,
      id: "lolp-datasets",
      title: "LoLP",
    },
  ],
  tooltipColumns: [
    {
      id: "lolp",
      dataKey: "y",
      formatter: probabilityFormatter(9),
    },
  ],
};
