import { BidOffer } from "components/dataVisualization/DerivedData/useAcceptanceVolumeData";
import { BmuModel } from "models/bmuData/bmuModel";
import { DataDownloadFormat } from "models/dataDownload/dataDownloadFormat";
import {
  DataDownloadModel,
  JsonDataDownloadModel,
} from "models/dataDownload/dataDownloadModel";
import { IndicativeVolumeModel } from "models/derivedData/indicativeVolumeModel";

import { getIndicativeDerivedData } from "./utils";

export const getIndicativeVolumeData = async (
  settlementTime: Date,
  bidOffer: BidOffer,
  bmUnits?: BmuModel[],
  format?: DataDownloadFormat
): Promise<DataDownloadModel<IndicativeVolumeModel>> => {
  const baseUrl = "/balancing/settlement/indicative/volumes/all";
  return getIndicativeDerivedData<IndicativeVolumeModel>(
    baseUrl,
    settlementTime,
    bidOffer,
    bmUnits,
    format
  );
};

export const getIndicativeVolumeJsonData = (
  settlementTime: Date,
  bidOffer: BidOffer,
  bmUnits?: BmuModel[]
): Promise<JsonDataDownloadModel<IndicativeVolumeModel>> =>
  getIndicativeVolumeData(
    settlementTime,
    bidOffer,
    bmUnits,
    DataDownloadFormat.Json
  ) as Promise<JsonDataDownloadModel<IndicativeVolumeModel>>;
